import React from "react";
import { Result } from "antd";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
const App: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist. Still under Development"
      extra={
        <LoadingButton
          variant="contained"
          onClick={() => navigate("/dashboard")}
        >
          Back Home
        </LoadingButton>
      }
      style={{ background: "white", minHeight: "100vh" }}
    />
  );
};

export default App;
