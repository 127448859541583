import { Avatar } from "antd";
import { HiDotsVertical } from "react-icons/hi"

const PaymentSatatus = () => {
  const ProgressBar = ({ percentage }) => {
    const greenWidth = Math.min(percentage, 33);
    const orangeWidth = Math.min(Math.max(percentage - 33, 0), 33);
    const redWidth = Math.max(percentage - 66, 0);

    return (
      <div className="w-full bg-gray-200 rounded-full h-4 flex">
        <div
          className="bg-[#12B76A] h-4 rounded-full"
          style={{ width: `${greenWidth}%` }}
        ></div>
        <div
          className={`bg-[#F5A36B] h-4 ${percentage > 33 ? '' : 'rounded-r-full'}`}
          style={{ width: `${orangeWidth}%` }}
        ></div>
        <div
          className={`bg-[#F1595C] h-4 ${percentage > 66 ? 'rounded-r-full' : ''}`}
          style={{ width: `${redWidth}%` }}
        ></div>
      </div>
    );
  };
  return (
    <>
      <div>
        <div className="flex justify-between">
          <h1 className="text-[#030229] text-lg ">Payment Status</h1>
          <HiDotsVertical />
        </div>
        <div className="my-3">
         <div className="flex gap-5"> <p className="text-[#030229]">500</p> <span className="text-[#030229B2] text-[12px] pt-1">Employees</span></div>
          <div className="flex items-center space-x-2">
            <ProgressBar percentage={70} />

          </div>
          <div className="my-4 flex flex-row items-center gap-6">
            <div className=" text-[#030229] gap-3">
              <div className="flex items-center">

                <div className='bg-[#12B76A] w-4 h-4 rounded-sm'></div><span className="pl-2 text-[12px]">68%</span>
              </div>

              <p className="text-[#030229B2] pl-4 text-[10px]">Successfully Paid</p>
            </div>
            <div className=" text-[#030229] gap-3">
              <div className="flex items-center">

                <div className='bg-[#F5A36B] w-4 h-4 rounded-sm'></div><span className="pl-2 text-[12px]">17%</span>
              </div>

              <p className="text-[#030229B2] pl-4 text-[10px]">Pending</p>
            </div>
            <div className=" text-[#030229] gap-3">
              <div className="flex items-center">

                <div className='bg-[#F1595C] w-4 h-4 rounded-sm'></div><span className="pl-2 text-[12px]">6%</span>
              </div>

              <p className="text-[#030229B2] pl-4 text-[10px]">Unpaid</p>
            </div>
          </div>
        </div>
        <div className="mt-7">
          <div className="flex justify-between mb-5">
            <p className="text-[#030229] text-lg ">Employee Time-off</p>
            <button className="text-[#030229B2] text-[10px] pt-1">View All</button>
          </div>
          <div className="divide-y">
          <div className='flex justify-between p-2 items-center'>
            <div className='flex gap-3'>
              <div className='w-[50px] h-40px] bg-white rounded-md px-2 py-1'>
                <Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=1" />
              </div>
              <div>
                <h1 className='text-[#333333] text-sm'>Marvin Ishimwe</h1>
                <p className='text-[#8F8F8F] text-sm'>Sick Leave</p>
              </div>

            </div>
            <p className="text-[#F1595C] text-[12px] pt-5">10 - 11 July 2024</p>

          </div>
          <div className='flex justify-between p-2 items-center'>
            <div className='flex gap-3'>
              <div className='w-[50px] h-40px] bg-white rounded-md px-2 py-1'>
                <Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=1" />
              </div>
              <div>
                <h1 className='text-[#333333] text-sm'>Marvin Ishimwe</h1>
                <p className='text-[#8F8F8F] text-sm'>Sick Leave</p>
              </div>

            </div>
            <p className="text-[#F1595C] text-[12px] pt-5">10 - 11 July 2024</p>

          </div>
          <div className='flex justify-between p-2 items-center'>
            <div className='flex gap-3'>
              <div className='w-[50px] h-40px] bg-white rounded-md px-2 py-1'>
                <Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=1" />
              </div>
              <div>
                <h1 className='text-[#333333] text-sm'>Marvin Ishimwe</h1>
                <p className='text-[#8F8F8F] text-sm'>Sick Leave</p>
              </div>

            </div>
            <p className="text-[#F1595C] text-[12px] pt-5">10 - 11 July 2024</p>

          </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default PaymentSatatus