import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Table, Typography, Space, Button } from "antd";
import { Stack, Switch } from "@mui/material";
import type { ColumnsType } from "antd/es/table";
import { useDispatch, useSelector } from "react-redux";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import { getOneAccountAction } from "../../../../store/account/actions";
import {
  getAllSubdeliverynotesItemAction,
  updateSubsDeliveryNoteItemAction,
} from "../../../../store/subscription/deliverynote/actions";

interface DataType {
  key: string;
  type: string;
  brand: string;
  model: any;
  specs: any;
  imei: number;
  tags: string[];
}

function ControlledSwitches(props: any) {
  const { auth } = useSelector((state: any) => state);
  const { accountId } = useParams();
  const [checked, setChecked] = React.useState(props?.isLocked);
  const dispatch = useDispatch();
  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    auth?.token &&
      (await updateSubsDeliveryNoteItemAction(
        props?.id,
        { isLocked: !event.target.checked },
        auth?.token
      )(dispatch));
    auth?.token &&
      (await getOneAccountAction(accountId as string, auth.token)(dispatch));
  };

  return (
    <Stack direction="row" spacing={2}>
      <Typography style={{ fontWeight: 600, fontSize: 14, color: "red" }}>
        Lock{checked ? "" : "ed"}
      </Typography>
      <Switch
        color="success"
        checked={checked}
        onChange={handleChange}
        inputProps={{ "aria-label": "controlled" }}
      />
      <Typography style={{ fontWeight: 600, fontSize: 14, color: "green" }}>
        Unlock{checked ? "ed" : ""}
      </Typography>
    </Stack>
  );
}

const DeviceTable: React.FC = () => {
  const dispatch = useDispatch();
  const columns: ColumnsType<DataType> = [
    {
      dataIndex: "type",
      title: "Type",
      key: "type",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      dataIndex: "model",
      title: "Model",
      key: "model",
    },
    {
      dataIndex: "specs",
      title: "Specs",
      key: "specs",
      width: 200,
    },
    {
      dataIndex: "imei",
      title: "IMEI",
      key: "imei",
    },

    {
      title: "Lock Actions",
      key: "View",
      render: (_, record: any) => {
        return (
          <ControlledSwitches
            isLocked={!record?.data?.isLocked}
            id={record?.data?._id}
          />
        );
      },
    },
  ];
  const { auth, account, deliverynote } = useSelector((state: any) => state);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);

  const dataToDisplay = deliverynote?.subDeliveryNoteItem?.data?.map(
    (el: any) => {
      return {
        key: el._id,
        type: el?.wareHouseItem?.product?.type,
        brand: el?.wareHouseItem?.product?.brand,
        model: el?.wareHouseItem?.product?.model,
        specs: el?.wareHouseItem?.quotationItem?.specification
          ?.slice(2, 6)
          ?.map((d: any) => d[1] && `${d[0]}:${d[1]}`)
          ?.join(", "),
        imei: el?.wareHouseItem?.serialNumber,
        status: el?.wareHouseItem?.product?.status,
      };
    }
  );

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    if (account?.selected?.deliveryNote?._id && auth?.token) {
      getAllSubdeliverynotesItemAction(
        auth?.token,
        `?deliveryNote=${
          account?.selected?.deliveryNote?._id
        }&limit=${limit}&page=${page - 1}`
      )(dispatch);
    }
  }, [
    auth?.token,
    account?.selected?.deliveryNote?._id,
    dispatch,
    limit,
    page,
  ]);

  return (
    <div className="w-full bg-[#F4F6FA]">
      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={deliverynote?.subDeliveryNoteItem?.total}
        count={Math.ceil(deliverynote?.subDeliveryNoteItem?.total / limit)}
      >
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={dataToDisplay}
          pagination={false}
          loading={deliverynote?.deliveryIsFetching}
        />
      </ScrollableFrame>

      <Space style={{ marginTop: 14 }}>
        <Button
          style={{
            background: "#F1595C",
            width: "8rem",
            height: "3rem",
            color: "#fff",
          }}
          disabled={true}
        >
          Lock All
        </Button>
        <Button
          style={{
            background: "#20DD8E",
            width: "8rem",
            height: "3rem",
            color: "#fff",
          }}
          disabled={true}
        >
          Unlock All
        </Button>
      </Space>
    </div>
  );
};
export default DeviceTable;
