import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateOneAccountAction } from "../../../../../store/account/actions";
import {
  getAllSubscriptionOrdersAction,
  submitSubOrdersAction,
} from "../../../../../store/subscription/order/actions";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

const AssigneeUser = (props: any) => {
  const { setting, orders, auth, account } = useSelector((state: any) => state);

  const assignedAssignee = orders?.selected?.account?.assignees?.map(
    (el: any) => el?._id
  );
  const [isAssignUser, setIsAssigUser] = useState(false);
  const [assignee, setAssignee] = useState<any>([]);
  const initialState = {
    comments: orders?.selected?.account?.notes?.at(0)?.details || "",
  };

  const [assignData, setAssignData] = useState(initialState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orderId = orders?.new?.data?._id ?? orders?.selected?._id;

  const handleAssignUser = () => {
    setIsAssigUser(true);
  };

  const handleChangeAssignee = (value: string[]) => {
    setAssignee(value);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setAssignData({
      ...assignData,
      [name]: value,
    });
  };

  const handlerSaveAssignee = async () => {
    const currentNotes = [
      {
        title: "Initialize account",
        createdBy: auth?.user?._id,
        details: assignData?.comments,
      },
    ];
    const payload = {
      assignees: assignee,
      notes: [...currentNotes],
    };

    if (orders?.selected?.account?._id && auth?.token) {
      const res = await updateOneAccountAction(
        auth?.token,
        orders?.selected?.account?._id,
        payload
      )(dispatch);
      if (res) {
        const response = await submitSubOrdersAction(
          orderId,
          { status: "created" },
          auth?.token
        )(dispatch);
        if (response) {
          navigate("/subscription/order");
          await getAllSubscriptionOrdersAction(
            auth?.token,
            `?status=created&status=pending&status=draft`
          )(dispatch);
        }
      }
    }
  };

  useEffect(() => {
    if (orders?.selected?.account?.assignees?.length > 0) {
      setAssignee(assignedAssignee);
    }
  }, [orders?.selected?.account?.assignees]);

  return (
    <div>
      <h1 className="font-semibold text-blue-400 text-lg mt-6">Assignee</h1>
      <div className="py-5">
        <div className="flex items-center mb-4 gap-x-4">
          <span
            className="border p-2 h-10 w-10 rounded-full flex items-center justify-center text-center"
            onClick={handleAssignUser}
          >
            +
          </span>
          {isAssignUser ? (
            <Select
              mode="multiple"
              style={{ width: "100%", height: 50 }}
              defaultValue={assignedAssignee || []}
              onChange={handleChangeAssignee}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.children as unknown as string)
                  ?.toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {setting?.all?.data?.map((el: any, index: any) => (
                <Select.Option key={index} value={el?._id}>
                  {el?.names}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <>
              {orders?.selected?.account?.assignees?.length > 0 ? (
                <div className="flex items-center  border w-60 px-2 py-1 rounded-full border-blue-200">
                  {orders?.selected?.account?.assignees?.map((el: any) => (
                    <>
                      <img
                        src={el?.picture}
                        alt="Profile"
                        className="w-10 h-10 object-cover"
                      />
                      <span className="ml-3 font-normal text-blue-400">
                        {el?.names}
                      </span>
                    </>
                  ))}
                </div>
              ) : (
                ""
              )}
            </>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2 text-sm">
            Comment
          </label>
          <textarea
            id="comment"
            className="w-full bg-white h-16 px-3 border border-gray-300 rounded-lg text-gray-950"
            name="comments"
            value={assignData.comments}
            onChange={handleChange}
          />
        </div>

        <div className="flex items-end justify-end pt-6">
          <LoadingButton
            type="submit"
            variant="contained"
            className="w-32 h-10"
            onClick={handlerSaveAssignee}
            loading={
              orders?.isFetching ||
              account?.isFetching ||
              orders?.createOderIsFetching
            }
          >
            Submit
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};

export default AssigneeUser;
