import { notification } from "antd";
import { myChannelActions } from ".";
import {
  createChannel,
  getAllChannel,
  getOneChannel,
  updateChannel,
  createShop,
  getAllShop,
  deleteShop,
  updateShop,
  createAgent,
  getAllAgent,
  deleteAgentService,
  updateServiceAgent,
  getPriceListItem,
  addPriceListItem,
  deletePriceListItem,
  updatePriceListItem,
  getPriceLists,
  createServiceRequest,
  getAllServiceRequest,
  addOneItemServiceRequest,
  getAllRegionShop,
  sendRequestService,
  updateIsDeleteService,
  assignAgentToShop,
  getProductsByReqItem,
  deleteStockRequestService,
  searchByAgentService,
  // getCommissionListsSearchService,
  gettemOnePriceListsIService,
  updateVariantPriceListItem,
  getSinglePriceListItem,
  deletePlanService,
  createCommussionProfile,
  getCommussionProfile,
  getDeviceWithCommission,
  getShopOverView,
  getSalesStatistics,
  deleteCommissionProfileService,
  updateCommussionProfile,
  createCommission,
  getCommussion,
  getCommissionTransaction,
  getCommissionTransactionByShop,
  getAllCommissionTransactionByShop,
  updateAllCommussionTransaction,
  updateCommussion,
  // getCommussioservices,
  getAllCommissionByChannel,
  getCommussionservices,
  getPriceListsSearchService,
  getCommissionListsSearchService,
  getSubscriptionPriceListService,
  getSubscriptionPlans,
  createRegions,
  getAllRegions,
  updateRegion,
  deleteRegion,
  getOneRegion,
  getShopTypeServices,
  createShopTypeServices,
} from "./services";

export const createChannelAction = (token: string, data: any) => {
  // console.log(">>",data)
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await createChannel(data, token);
      const resAll = await getAllChannel(token);

      // console.log(">>>>>>>:: Channel registered", res);
      if (res?.status === 201) {
        dispatch(myChannelActions.setNew(res));
        dispatch(myChannelActions.setAll(resAll));
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "Channel created" });
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const createShopAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await createShop(data, token);
      if (res?.status === 201) {
        const resAll = await getAllShop(token, `channel=${res?.data?.channel}`);
        dispatch(myChannelActions.setNew(res));
        dispatch(myChannelActions.setShops(resAll));
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "Shop created" });
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const createAgentAction = (token: string, data: any) => {
  // console.log(">>",data)
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await createAgent(data, token);

      // console.log("Agent registered", res);
      if (res?.status === 201) {
        dispatch(myChannelActions.setNew(res));
        const resAll = await getAllAgent(res?.data?.channel, token);
        dispatch(myChannelActions.setAgents(resAll));
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "Agent Created" });
        return true;
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const createRegionAction = (token: string, data: any, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await createRegions(data, token);
      if (res?.status === 201) {
        const resAll = await getAllRegions(token, query);
        dispatch(myChannelActions.setNew(res));
        dispatch(myChannelActions.setRegion(resAll));
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "Region Created" });
        return true;
      } else {
        if (res?.status === 409) {
          return notification.error({
            message: "Error",
            description: res?.error,
          });
        }

        notification.error({
          message: "Oops ...",
          description: "Region already exist !",
        });
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const createCommussionProfileAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await createCommussionProfile(data, token);
      if (res?.status === 201) {
        dispatch(myChannelActions.setNewProfile(res));
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "Commission Created" });
        return true;
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const createCommissionAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await createCommission(data, token);
      if (res?.status === 201) {
        dispatch(myChannelActions.setNewCommission(res));
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "Commission Assigned" });
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getCommissionAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getCommussion(token, query);
      if (res?.status === 200) {
        dispatch(myChannelActions.setAllCommission(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getCommissionBychannelAction = (
  token: string,
  channelId: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res: any = await getAllCommissionByChannel(token, channelId);
      if (res?.status === 200) {
        dispatch(myChannelActions.setAllCommissionByChannel(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getOneCommissionByChannelAction = (
  token: string,
  itemId: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getCommussionservices(itemId, token);
      if (res?.status === 200) {
        dispatch(myChannelActions.setOneCommussionChannel(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getCommissionProfilelAction = (token: string, query: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getCommussionProfile(token, query);
      if (res?.status === 200) {
        dispatch(myChannelActions.setAllCommissionByChannel(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const deleteCommussionProfileActions = (
  itemId: string,
  token: string,
  query?: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await deleteCommissionProfileService(itemId, token);
      if (res?.status === 200) {
        const resAll = await getCommussionProfile(token, query);
        dispatch(myChannelActions.setAllCommissionProfile(resAll));
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "deleted Succesfully" });
        return true;
      }
      dispatch(myChannelActions.setIsFetching(false));
      return true;
    } catch (err) {
      console.log(err);
    }
  };
};

export const getDeviceWithCommissionAction = (token: string, query: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getDeviceWithCommission(token, query);
      if (res?.status === 200) {
        dispatch(myChannelActions.setDeviceWithCommission(res));
        dispatch(myChannelActions.setIsFetching(false));
      } else {
        console.log("rfailed");
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getCommissionTransactionAction = (token: string, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getCommissionTransaction(token, query);
      if (res?.status === 200) {
        dispatch(myChannelActions.setCommissionTransaction(res));
        dispatch(myChannelActions.setIsFetching(false));
      } else {
        console.log("rfailed");
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getCommissionTransactionByShopAction = (
  token: string,
  query?: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getCommissionTransactionByShop(token, query);
      if (res?.status === 200) {
        dispatch(myChannelActions.setCommissionTransactionByShop(res));
        dispatch(myChannelActions.setIsFetching(false));
      } else {
        console.log("rfailed");
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getAllCommissionTransactionAction = (
  token: string,
  query?: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getAllCommissionTransactionByShop(token, query);
      if (res?.status === 200) {
        dispatch(myChannelActions.setAllCommissionTransaction(res));
        dispatch(myChannelActions.setIsFetching(false));
      } else {
        console.log("rfailed");
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const updateAllCommissionTransactionAction = (
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await updateAllCommussionTransaction(data, token);
      if (res?.status === 200) {
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "Succesfully" });
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const updateCommissionAction = (
  itemId: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      console.log(data, "for testing purposeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
      dispatch(myChannelActions.setIsFetching(true));
      const res = await updateCommussion(itemId, data, token);
      if (res?.status === 200) {
        dispatch(myChannelActions.setIsFetching(true));
        notification.success({ message: "updated Succesfully" });
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateCommissionProfileAction = (
  itemId: string,
  data: any,
  token: string,
  query?: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res: any = await updateCommussionProfile(itemId, data, token);
      if (res?.status === 200) {
        const resAll = await getCommussionProfile(token, query);
        dispatch(myChannelActions.setAllCommissionProfile(resAll));
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "updated Succesfully" });
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getAllChannelAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getAllChannel(token, query);
      if (res?.status === 200) {
        dispatch(myChannelActions.setAll(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllShopAction = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getAllShop(token, query);
      // console.log(">>>>>>>:: shops", res);
      if (res?.status === 200) {
        dispatch(myChannelActions.setShops(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getShopTypeAction = (token: string, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getShopTypeServices(token, query);
      if (res?.status === 200) {
        dispatch(myChannelActions.setShopType(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const createShopTypeAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await createShopTypeServices(data, token);
      if (res?.status === 201) {
        dispatch(myChannelActions.setNewShopType(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllAgentAction = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getAllAgent(token, query);
      if (res?.status === 200) {
        const resShop = await getAllShop(token, query);
        dispatch(myChannelActions.setShops(resShop));
        dispatch(myChannelActions.setAgents(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getAllRegionAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getAllRegions(token, query);
      if (res?.status === 200) {
        dispatch(myChannelActions.setRegion(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getOneRegionAction = (itemId: string, token: string) => {
  return async (dispatch: any) => {
    try {
      const res = await getOneRegion(itemId, token);
      if (res?.status === 200) {
        dispatch(myChannelActions.setSelectedRegion(res?.data));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getOneChanneltAction = (itemId: string, token: string) => {
  return async (dispatch: any) => {
    try {
      const res = await getOneChannel(itemId, token);
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myChannelActions.setSelected(res?.data));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getShopOverViewAction = (itemId: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getShopOverView(itemId, token);
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myChannelActions.setShopOverView(res?.data));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getSalesStatisticsAction = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getSalesStatistics(token, query);
      if (res?.status === 200) {
        // const resShop = await getAllShop(token, query);
        // dispatch(myChannelActions.setShops(resShop));
        dispatch(myChannelActions.setSalesStatiscs(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getOneChanneltByNameAction = (name: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getAllChannel(token, `name=${name}`);
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myChannelActions.setSelected(res?.data[0]));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteAgentActions = (itemId: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await deleteAgentService(itemId, token);
      // console.log(" delete agent.....?", res)
      if (res?.status === 200) {
        const resAll = await getAllAgent(res?.data?.channel, token);
        dispatch(myChannelActions.setAgents(resAll));
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "deleted Succesfully" });
        return true;
      }
      dispatch(myChannelActions.setIsFetching(false));
      return true;
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteRegionAction = (
  itemId: string,
  token: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await deleteRegion(itemId, data, token);
      if (res?.status === 200) {
        const resAll = await getAllRegions(
          token,
          `channel=${res?.data?.channel?._id}`
        );
        dispatch(myChannelActions.setRegion(resAll));
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "deleted Succesfully" });
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteShopAction = (itemId: string, token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await deleteShop(itemId, data, token);
      // console.log(" delete res.......>", res)
      if (res?.status === 200) {
        const resAll = await getAllShop(
          token,
          `channel=${res?.data?.channel?._id}`
        );
        dispatch(myChannelActions.setShops(resAll));
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "deleted Succesfully" });
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateChannelAction = (
  itemId: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await updateChannel(itemId, data, token);
      if (res?.status === 200) {
        const resAll = await getAllChannel(token, "");
        dispatch(myChannelActions.setUpdated(res));
        dispatch(myChannelActions.setAll(resAll));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateShopAction = (itemId: string, data: any, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await updateShop(itemId, data, token);
      if (res?.status === 200) {
        const resAll = await getAllShop(
          token,
          `channel=${res?.data?.channel?._id}`
        );
        dispatch(myChannelActions.setUpdated(res));
        dispatch(myChannelActions.setShops(resAll));
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "updated Succesfully" });
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const updateRegionAction = (
  itemId: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await updateRegion(itemId, data, token);
      if (res?.status === 200) {
        const resAll = await getAllRegions(
          token,
          `channel=${res?.data?.channel?._id}`
        );
        dispatch(myChannelActions.setUpdated(res));
        dispatch(myChannelActions.setRegion(resAll));
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "updated Succesfully" });
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateAgentActions = (
  itemId: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await updateServiceAgent(itemId, data, token);
      // console.log("<<<<updated data>>>>", res)
      if (res?.status === 200) {
        const resAll = await getAllAgent(res?.data?.channel, token);
        dispatch(myChannelActions.setUpdated(res));
        dispatch(myChannelActions.setAgents(resAll));
        dispatch(myChannelActions.setIsFetching(false));
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "Updated Succesfully" });
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const reAssignAgentToShopAction = (
  itemId: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await assignAgentToShop(itemId, data, token);
      // console.log("<<<<Assigned Agent>>>>", res);
      if (res?.status === 200) {
        const resAll = await getAllAgent(token, "");
        dispatch(myChannelActions.setAgents(resAll));
        dispatch(myChannelActions.setUpdated(res));
        dispatch(myChannelActions.setIsFetching(false));
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "Updated Succesfully" });
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// Prices

export const getPricelistAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getPriceLists(token, query);
      // console.log(">>>>>>>:: channels", res);
      if (res?.status === 200) {
        dispatch(myChannelActions.setPriceList(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getPricelistItemAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getPriceListItem(token, query);
      // console.log(">>>>>>>:: channels", res);
      if (res?.status === 200) {
        dispatch(myChannelActions.setPriceListItems(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
//
export const getSubscriptionPricelistItemAction = (
  token: string,
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getPriceListItem(token, query);
      if (res?.status === 200) {
        dispatch(myChannelActions.setSubPriceListItems(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getSubscriptionPlansAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getSubscriptionPlans(token, query);
      if (res?.status === 200) {
        dispatch(myChannelActions.setSubsPlans(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
// getSubscriptionPlans
export const getSinglePricelistItemsAction = (
  token: string,
  itemId: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getSinglePriceListItem(token, itemId);
      // console.log(">>>>>>>:: channels", res);
      if (res?.status === 200) {
        dispatch(myChannelActions.setSelectedPriceListItems(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getSinglePricelistItemAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      // dispatch(myChannelActions.setIsFetching(true));
      const res = await getPriceListItem(token, query);
      // console.log(">>>>>>>:: channels", res);
      if (res?.status === 200) {
        dispatch(myChannelActions.setPriceListItemToUpdate(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const addPriceListItemAction = (
  token: string,
  priceListId: string,
  data: any,
  query: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await addPriceListItem(token, priceListId, data);
      // const resAll = await getPriceListItem(token, query);

      // console.log(">>>>>>>:: Channel registered", res);
      if (res?.status === 200) {
        // dispatch(myChannelActions.setNew(res));
        const resP = await getPriceListItem(token, `?channel=${data?.channel}`);
        dispatch(myChannelActions.setPriceListItems(resP));
        // dispatch(myChannelActions.setPriceList(resAll));
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "Price list item added" });
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      notification.error({ message: "Adding item to price list failed" });
    }
  };
};

export const deletePriceListItemAction = (
  token: string,
  priceListItemId: string,
  query: string
) => {
  // console.log(">>",data)
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await deletePriceListItem(token, priceListItemId);
      const resAll = await getPriceListItem(token, "?type=pos");
      if (res?.status === 200) {
        dispatch(myChannelActions.setPriceListItems(resAll));
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "Price list item Deleted" });
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      notification.error({ message: "Deleting item from price list failed" });
    }
  };
};

export const updatePriceListItemAction = (
  token: string,
  priceListItemId: string,
  data: any,
  query: string
) => {
  // console.log(">>",data)
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsPriceListUpdating(true));
      const res = await updatePriceListItem(token, priceListItemId, data);
      const resAll = await getPriceListItem(token, "?type=pos");

      // console.log(">>>>>>>:: Channel registered", res);
      if (res?.status === 200) {
        // dispatch(myChannelActions.setNew(res));
        dispatch(myChannelActions.setPriceList(resAll));
        dispatch(myChannelActions.setPriceListItems(resAll));
        dispatch(myChannelActions.setIsPriceListUpdating(false));
        notification.success({ message: "Price updated" });
      }
      dispatch(myChannelActions.setIsPriceListUpdating(false));
    } catch (err) {
      console.log(err);
      notification.error({ message: "Failed to update price" });
    }
  };
};

export const createRequestStockAction = (
  token: string,
  data: {},
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await createServiceRequest(data, token);
      // const resPrice = await getPriceLists(token, query);

      // console.log(">>>>>>>:: ", res);
      if (res?.status === 201) {
        dispatch(myChannelActions.setNew(res));
        // dispatch(myChannelActions.setPriceList(resPrice));
        dispatch(myChannelActions.setIsFetching(false));
        return res.data;
      }
      dispatch(myChannelActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteRequestStockAction = (
  token: string,
  stockReqId: string,
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await deleteStockRequestService(stockReqId, token);
      const selectedRfs = await getAllServiceRequest(token, query);
      // const resPrice = await getPriceLists(token, query);

      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myChannelActions.setNew(res));
        dispatch(myChannelActions.setRfsSelected(selectedRfs));
        dispatch(myChannelActions.setIsFetching(false));
        return res.data;
      }
      dispatch(myChannelActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllStockReqAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getAllServiceRequest(token, query);
      const resPrice = await getPriceLists(token, query);
      if (res?.status === 200) {
        dispatch(myChannelActions.setRequestedStock(res));
        dispatch(myChannelActions.setPriceList(resPrice));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getAllStockSentAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      dispatch(myChannelActions.setGettingStockReq(true));
      const res = await getAllServiceRequest(token, query);
      const resPrice = await getPriceLists(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myChannelActions.setRfsSelected(res));
        dispatch(myChannelActions.setPriceList(resPrice));
        dispatch(myChannelActions.setIsFetching(false));
        dispatch(myChannelActions.setGettingStockReq(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
      dispatch(myChannelActions.setGettingStockReq(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getSelectedRequestAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getAllServiceRequest(token, query);
      const resPrice = await getPriceLists(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200 && res?.data.length === 1) {
        dispatch(myChannelActions.setRfsSelected(res?.data[0]));
        dispatch(myChannelActions.setPriceList(resPrice));
        dispatch(myChannelActions.setIsFetching(false));
      } else if (res?.status === 200 && res?.data.length !== 1) {
        console.log(res);
        notification.error({ message: "RFS ID Is Not Exist!" });
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const addItemToSelectedRequestAction = (
  id: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await addOneItemServiceRequest(id, data, token);

      console.log(">>>>>>>:: >>", res);
      if (res?.status === 200) {
        const resSelected = await getAllServiceRequest(
          token,
          `?requestId=${res?.data.requestId}`
        );
        dispatch(myChannelActions.setRfsSelected(resSelected?.data[0]));
        dispatch(myChannelActions.setIsFetching(false));
      } else if (res?.status === 200 && res?.data.length !== 1) {
        console.log(res);
        notification.error({ message: "RFS ID Is Not Exist!" });
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const sendRequestAction = (id: string, data: {}, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await sendRequestService(id, data, token);

      if (res?.status === 200) {
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "Request Sent Successfully!" });
        return true;
      }
      dispatch(myChannelActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteChannelsAction = (
  itemId: string,
  token: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await updateIsDeleteService(itemId, data, token);
      if (res?.status === 200) {
        dispatch(myChannelActions.setSelected(res?.data));
        const resRegions = await getAllRegionShop;
        const resAll = await getAllChannel(token, "");
        dispatch(myChannelActions.setAll(resAll));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log("an error occured......");
      console.log(err);
    }
  };
};

export const getProductsByReqItemAction = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getProductsByReqItem(token, query);
      if (res?.status === 200) {
        dispatch(myChannelActions.setProducts(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getPricelistSearchAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const resSearch = await getPriceListsSearchService(token, query);
      // console.log(">>>>>>>:: channels", res);
      if (resSearch?.status === 200) {
        dispatch(myChannelActions.setPriceListItems(resSearch));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

//getCommissionListsSearchService

export const getCommissionlistSearchAction = (
  token: string,
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const resSearch = await getCommissionListsSearchService(token, query);
      if (resSearch?.status === 200) {
        dispatch(myChannelActions.setAllCommissionByChannel(resSearch));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOnePriceListItemAction = (token: string, itemId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsOneFetching(true));
      const res = await gettemOnePriceListsIService(token, itemId);
      // console.log(">>>>>>>:: channels", res);
      if (res?.status === 200) {
        dispatch(myChannelActions.setOnePriceListItem(res));
        dispatch(myChannelActions.setIsOneFetching(false));
      }
      dispatch(myChannelActions.setIsOneFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// export const getCommissionlistSearchAction = (token: string, query?: string) => {
//   return async (dispatch: any) => {
//     try {
//       dispatch(myChannelActions.setIsFetching(true));
//       const resSearch = await (token, query);
//       // console.log(">>>>>>>:: channels", res);
//       if (resSearch?.status === 200) {
//         dispatch(myChannelActions.setPriceListItems(resSearch));
//         dispatch(myChannelActions.setIsFetching(false));
//       }
//       dispatch(myChannelActions.setIsFetching(false));
//     } catch (err) {
//       console.log(err);
//     }
//   };
// };

export const updatePriceListVariantAction = (
  priceListItemId: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await updateVariantPriceListItem(
        priceListItemId,
        data,
        token
      );
      if (res?.status === 200) {
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "Updated Succesfully" });
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const deletePlanAction = (
  token: string,
  planId: string,
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await deletePlanService(planId, token);
      if (res?.status === 200) {
        notification.success({ message: "Plan deleted successfully" });
        const resAll = await getSubscriptionPriceListService(token, query);
        dispatch(myChannelActions.setSubPriceList(resAll));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getSubscriptionPricelistAction = (
  token: string,
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getSubscriptionPriceListService(token, query);
      if (res?.status === 200) {
        dispatch(myChannelActions.setSubPriceList(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
