import { LoadingButton } from "@mui/lab";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import DetailsTabs from "../../../../components/cards/DetailsPage/DetailsTabs";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ShopSalesTable from "../../../../components/tables/ShopSalesTable";
import {
  createRequestStockAction,
  getAllStockSentAction,
} from "../../../../store/channel/actions";
import { getOneShopAction } from "../../../../store/shop/actions";
import RequestTransView from "./RequestedTransferView";
import { Box } from "@mui/material";

const Transfer = () => {
  const { channel, auth, shop } = useSelector((state: any) => state);
  const { shopId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   auth?.token && getOneShopAction(shopId as string, auth?.token)(dispatch);
  //   auth?.token &&
  //     shopId &&
  //     getAllStockSentAction(auth?.token, `?shopId=${shopId}`)(dispatch);
  // }, [auth?.token, channel.query, dispatch, shopId]);

  return (
    <div className="mt-4 text-black h-full overflow-y-auto scrollbar-hide">
      <DetailsHeaderActionBar
        pageName="Transfer"
        title={" "}
        buttonsComponent={
          <LoadingButton
            variant="contained"
            startIcon={<AddCircleIcon />}
            onClick={async () => {
              // if (auth?.token) {
              //   const res: any = await createRequestStockAction(auth?.token, {
              //     shopId: shopId,
              //     channel: channel?.selected?._id,
              //   })(dispatch);
              //   if (res) {
              //     navigate(
              //       `/channel/${channel?.selected?.name}/pos/shops/${shopId}/${res?.requestId}`
              //     );
              //   }
              // }

              navigate(
                `/channel/${channel?.selected?.name}/pos/transfer/request`
              );
            }}
            loading={channel?.isFetching}
          >
            Request Transfer
          </LoadingButton>
        }
      />
      <Box className="bg-white w-full rounded-lg p-4">
        <RequestTransView />
      </Box>
    </div>
  );
};

export default Transfer;
