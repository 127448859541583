import React, { useEffect, useState } from "react";
import { Segmented } from "antd";
import FilesCards from "./filesCard";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import UploadDocs from "../../../../components/forms/UploadFile";
import {
  getOneAccountAction,
  updateOneAccountAction,
} from "../../../../store/account/actions";
import { useParams } from "react-router-dom";
import { handlerSelectTabs } from "../../../../store/layout/actions";

const AccountFiles: React.FC = () => {
  const { auth, account } = useSelector((state: any) => state);
  const [pictures, setPictures] = useState<any[]>([]);
  const { accountId } = useParams();
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState("All");

  const handleOptionChange = (option: any) => {
    setSelectedOption(option);
  };

  const contents: Record<string, JSX.Element> = {
    All: (
      <div>
        <FilesCards
          filesdata={account?.selected?.docX?.map((el: any) => ({
            title: el?.name ?? "",
            description: `Uploaded by ${el?.createdBy?.names} on ${
              el?.date?.split("T")[0]
            }`,
            image: el?.docLink,
          }))}
        />
      </div>
    ),
    // Bank: <div>This is files</div>,
    // "P.O": <div>This is PO</div>,
  };

  const handlerSaveFile = async () => {
    const payload = [...(account?.selected?.documents ?? [])];
    const newData = pictures?.map((el: any) => {
      return {
        createdBy: auth?.user?._id,
        name: "docs",
        tag: "docs",
        docLink: el?.response?.data?.secure_url,
      };
    });

    Array.prototype.push.apply(payload, newData);

    // const data = [...payload, ...newData];

    const response = await updateOneAccountAction(
      auth?.token,
      accountId as string,
      {
        documents: payload,
      }
    )(dispatch);
    if (response) {
      setPictures([]);
      await handlerSelectTabs(5)(dispatch);
      await getOneAccountAction(accountId as string, auth?.token)(dispatch);
    }
  };

  useEffect(() => {
    handlerSelectTabs(4)(dispatch);
  }, []);

  return (
    <div className=" w-full bg-[#F4F6FA] pt-12">
      <div>
        <UploadDocs isCard="picture-card" setPictures={setPictures} />
        {pictures?.length > 0 && (
          <Button
            loading={account?.isFetching}
            icon={<UploadOutlined />}
            onClick={handlerSaveFile}
          >
            Save Files
          </Button>
        )}
      </div>

      <Segmented
        size="large"
        block
        options={["All"]}
        onChange={handleOptionChange}
      />
      {contents[selectedOption]}
    </div>
  );
};
export default AccountFiles;
