import { Steps } from "antd";
import React from "react";
import { CiClock2 } from "react-icons/ci";
import { MdSocialDistance } from "react-icons/md";
import "./delivery.css";
import { useSelector } from "react-redux";
import ItemTable from "./ItemTable";
import { TbPointFilled } from "react-icons/tb";
import { FaLocationDot } from "react-icons/fa6";
const { Step } = Steps;
const PreviewData = () => {
  const { tracking } = useSelector((state: any) => state);
  const statuses = ["ReadyForPickup", "Pickup", "In-Transit", "Delivered"];
  const currentStep = statuses.findIndex(
    (status) => status === tracking?.checkParckage?.trackingStatus
  );
  const Transit = "In-Transit";
  const statusMap: { [key: string]: "wait" | "process" | "finish" | "error" } =
    {
      ReadyForPickup: "finish",
      PickUp: "process",
      Transit: "wait",
      delivered: "wait",
    };
  const item = tracking?.checkParckage?.trackingType?.steps?.map((el: any) => {
    return {
      title: el?.title,
      status: statusMap[el?.title],
    };
  });
  return (
    <div className="text-black px-4">
      <h1 className="text-[#030229] text-lg font-medium py-3 pb-6 text-center">
        Order Preview
      </h1>
      <div className="border border-[#0302291A] h-full w-full p-3 rounded-md">
        <p className="text-[#030229] text-lg  uppercase">
          <span className="text-[#B0B0B0] text-base ">Order id: </span>{" "}
          {tracking?.checkParckage?.packageId}
        </p>
        <div className=" flex justify-between mt-3">
          <div className="mt-1">
            <p className="text-[#030229] text-lg ">Est. Delivery Time</p>
            <p className="text-[#030229B2] text-base flex gap-2">
              {" "}
              <CiClock2 color="#605BFF" className="mt-0.5" size={20} />
              N/A
            </p>{" "}
          </div>
          <div className="mt-1">
            <p className="text-[#030229] text-lg">Total Distance</p>
            <p className="text-[#030229B2] text-base flex gap-2">
              {" "}
              <MdSocialDistance
                color="#030229B2"
                className="mt-0.5"
                size={20}
              />{" "}
              {tracking?.checkParckage?.distance} Km
            </p>{" "}
          </div>
        </div>
        <div className="deliveryContianer mt-5">
          <Steps current={currentStep} labelPlacement="vertical" items={item} />
        </div>
      </div>
      <div className="flex justify-between w-full">
        <div className="border border-[#0302291A] h-full w-full md:w-[65%] p-5 rounded-md mt-5 ">
          <h1 className="text-[#030229] text-lg font-medium py-3 pb-6">
            Review summary
          </h1>

          <Steps current={1} direction="horizontal">
            <Step
              icon={
                <div className="flex items-center justify-center w-6 h-6 rounded-full border border-gray-200">
                  <TbPointFilled className="text-green-500" />
                </div>
              }
            />
            <Step
              icon={
                <div className="flex items-center justify-center w-6 h-6 rounded-full border border-gray-300">
                  <FaLocationDot className="h-4 w-3" />
                </div>
              }
            />
          </Steps>
          <div className=" sm:flex justify-between mt-3">
            <div className="mt-1">
              <p className="text-[#030229B2] text-base flex gap-2">From</p>
              <p className="text-[#030229] text-lg">
                {tracking?.checkParckage?.locationFrom?.name}
              </p>
              <div className="font-poppins text-md text-gray-400 pb-2">
                Deliverer:
              </div>
              <p className="pb-2">
                {(tracking?.selectedDriver && tracking?.selectedDriver?.name) ||
                  "N/A"}
              </p>
              <div className="font-poppins text-md text-gray-400 pb-2">
                Phone Number:{" "}
              </div>
              <p className="pb-2">
                {(tracking?.selectedDriver &&
                  tracking?.selectedDriver?.phone) ||
                  "N/A"}
              </p>
              <div className="font-poppins text-md text-gray-400">Email: </div>
              <p className="pb-2">
                {(tracking?.selectedDriver &&
                  tracking?.selectedDriver?.email) ||
                  "N/A"}
              </p>
            </div>
            <div className="mt-5 sm:mt-1">
              <p className="text-[#030229B2] text-base flex gap-2">To</p>
              <p className="text-[#030229] text-lg">
                {tracking?.checkParckage?.locationTo?.name}
              </p>
              <p className="text-[#030229B2] text-base flex gap-2 pt-2">
                Reciever:
              </p>
              <p className="text-[#030229] text-lg">
                {tracking?.checkParckage?.receiver?.name ?? "N/A"}
              </p>
              <p className="text-[#030229B2] text-base flex gap-2 pt-2">
                Phone Number:
              </p>
              <p className="text-[#030229] text-lg ">
                {tracking?.checkParckage?.receiver?.phone ?? "N/A"}
              </p>
              <p className="text-[#030229B2] text-base flex gap-2 pt-2">
                Email:
              </p>
              <p className="text-[#030229] text-lg">
                {tracking?.checkParckage?.receiver?.email ?? "N/A"}
              </p>
            </div>
          </div>
        </div>
        <div className="border border-[#0302291A] p-5 rounded-md w-[33%] mt-5 hidden md:block">
          <h1 className="text-[#030229] text-base font-medium py-3 pb-6">
            Item List
          </h1>
          <div>
            <ItemTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewData;
