import React, { useState } from "react";
import { Button, Drawer, message, notification, Spin, Steps, theme } from "antd";
import DeliverDetails from "./deliverDetails";
import QRCode from "./QRCode";
import PreviewData from "./PreviewData";
import RecieverDetails from "./ReceiverDetails";
import ConfirmPage from "./ConfirmPage";
import { LoadingOutlined } from '@ant-design/icons';
import type { DrawerProps, RadioChangeEvent } from "antd";
import { useDispatch, useSelector } from "react-redux";
import SignatureCanvas from "react-signature-canvas";
import { LoadingButton } from "@mui/lab";
import {
  getCheckParkageAction,
  receiverSignatureAction,
  updateParkageOutAction,
} from "../../../store/delivery/actions";
const steps = [
  {
    title: "First",
    content: <DeliverDetails />,
  },
  {
    title: "Second",
    content: <QRCode />,
  },
  {
    title: "Third",
    content: <PreviewData />,
  },
  {
    title: "Fourth",
    content: <RecieverDetails />,
  },
  {
    title: "Last",
    content: <ConfirmPage />,
  },
];

const DeliverySteps = (props: any) => {
  const { tracking } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);
  const [sign, setSign] = useState<SignatureCanvas | null>(null);
  const [RecieverSignature, setRecieverSignature] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] =
    useState<DrawerProps["placement"]>("bottom");

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onChange = (e: RadioChangeEvent) => {
    setPlacement(e.target.value);
  };

  const next = () => {
    setCurrent(current + 1);
    props?.setCurrentStep(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const handleClear = () => {
    sign?.clear();
    setRecieverSignature("");
  };
  const handleDownloadPDF = () => {
    // toPDF({
    //   filename: "Signature"
    //     ?.split(" ")
    //     ?.join("_"),
    //   page: { margin: Margin.MEDIUM },
    // });
  };
  const openNotification = () => {
    notification.open({
      message: "Reciever signature is required",
      description:
        "please provide Reciever signature to accept Terms and conditions",
    });
  };
  const handleSignature = async () => {
    const trimmedCanvas = sign?.getTrimmedCanvas();
    if (trimmedCanvas) {
      const dataUrl = trimmedCanvas.toDataURL("image/png");
      const response = await getCheckParkageAction(
        `?trackingId=${tracking?.checkParckage?.packageId}&delivererId=${tracking?.selectedDriver?._id}`
      )(dispatch);
      if (dataUrl && response) {
        const res = await receiverSignatureAction({
          image: dataUrl,
        })(dispatch);
        if (res) {
            await updateParkageOutAction(tracking?.checkParckage?._id, {
              receiver: {
                name: tracking?.checkParckage?.receiver?.name,
                phone: tracking?.checkParckage?.receiver?.phone,
                email: tracking?.checkParckage?.receiver?.email,
                picture: tracking?.checkParckage?.receiver?.picture,
                signature: tracking?.recieverSignature?.data?.secure_url,
              },
            })(dispatch);
            await getCheckParkageAction(
              `?trackingId=${tracking?.checkParckage?.packageId}&delivererId=${tracking?.selectedDriver?._id}`
            )(dispatch);
          }
        
      }
    }
  };
  const checkSignature = async () => {
    if (sign?.isEmpty()) {
      openNotification();
    } else {
      await handleSignature();
      //  await handleDownloadPDF();
      onClose();
      handleClear();
    }
  };

  const handleCurrentStep = () => {
    if (current === 0 && !tracking?.selectedDriver) {
      return true;
    } else if (current === 1 && !tracking?.checkParckage) {
      return true;
    } else if (
      current === 2 &&
      !tracking?.checkParckage &&
      !tracking?.selectedDriver
    ) {
      return true;
    } else if (current === 4 && (!tracking?.updated?.data?.receiver || !tracking?.checkParckage?.receiver)) {
      return true;
    } else if (current === 5) {
      return true;
    }
  };
  return (
    <>
      <Drawer
        title={
          <h1 className="text-[#030229] text-lg font-medium py-3 pb-6 uppercase text-center">
            Confirm by signature
          </h1>
        }
        placement={placement}
        closable={false}
        onClose={onClose}
        height={700}
        open={open}
        key={placement}
      >
        <div className="min-h-52 w-[22rem] m-auto">
          <SignatureCanvas
            penColor="black"
            ref={(data: any) => setSign(data)}
            canvasProps={{
              className:
                "sigCanvas mt-4 h-[153px] w-[352px] border-dotted border-2 border-[#E0E0E0] px-3 py-2 cursor-pointer rounded-md",
              width: 500,
              height: 200,
            }}
          />
          <div className=" float-right mt-4 printBtn ">
            <Button
              style={{ margin: "0 8px" }}
              onClick={() => onClose()}
              className="text-center bg-red-400 text-white w-1/4 py-4 m-auto rounded-md"
            >
              Close
            </Button>
            <LoadingButton
              sx={{
                backgroundColor: "rgb(229 231 235)",
                color: "black",
                padding: "5px 25px",
                marginRight: "10px",
              }}
              onClick={handleClear}
              className="px-8 py-1 rounded-md bg-gray-200 m-2 w-1/4"
            >
              Clear
            </LoadingButton>
            <LoadingButton
              style={{ backgroundColor: "blue", color: "white" }}
              className="px-8 py-1 rounded-md m-2 w-1/4"
              sx={{
                color: "white",
                padding: "5px 25px",
              }}
              onClick={() => {
                checkSignature();
              }}
              loading={tracking?.signatureLoading}
            >
              {tracking?.signatureLoading ?  <Spin indicator={<LoadingOutlined spin color="white"/>} size="large" /> : "Save"}
            </LoadingButton>
          </div>
        </div>
      </Drawer>
      <div className="bg-white text-black h-[80vh] w-full overflow-y-auto">
        {steps[current].content}
      </div>
      <div style={{ marginTop: 24 }} className="w-full flex">
        {current > 0 && (
          <Button
            style={{ margin: "0 8px" }}
            disabled={
              current === 4 && tracking?.recieverSignature?.data?.secure_url
                ? true
                : false
            }
            onClick={() => prev()}
            className="text-center bg-[#C8CACC] text-white w-1/2 py-4 m-auto rounded-md"
          >
            Previous
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button
            type="primary"
            disabled={handleCurrentStep()}
            onClick={() => next()}
            className="text-center bg-[#605BFF] text-white w-full md:w-1/2 py-4 m-auto rounded-md"
          >
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            type="primary"
            disabled={
              tracking?.recieverSignature?.data?.secure_url ? true : false
            }
            onClick={() => showDrawer()}
            className="text-center bg-[#605BFF] text-white w-1/2 py-4 m-auto rounded-md"
          >
            Complete Delivery
          </Button>
        )}
      </div>
    </>
  );
};

export default DeliverySteps;
