import React, { useEffect, useState } from "react";
import { Table, InputNumber, Form, Modal, Input, Select, Divider } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Stack, Paper } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import { createDeliveryNoteAction } from "../../store/product/actions";
import Result from "../../components/Results/SuccessfullySent";
import {
  getDelivererAction,
  getTrackingTypeAction,
} from "../../store/delivery/actions";
import CreateDelivererForm from "../../pages/dashboard/inventory/warehouseOut/createDelivererForm";

const App = (props: any) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { product, tracking } = useSelector((state: any) => state);
  const { TextArea } = Input;
  const [shipingType, setShipingType] = useState("");
  const [qSent, setQSent] = React.useState<any>("");
  const [deliverer, setDeliverer] = React.useState<any>("");

  const dataMapper: any[] = [];
  const value = product?.selected?.listOfProducts;
  const [isOpenDeliverer, setIsOpenDeliverer] = useState(false);
  const [data, setData] = React.useState(dataMapper);
  const [termsAndConditions, setTermsAndConditions] = React.useState("");

  const showNewDelivererModal = () => {
    setIsOpenDeliverer(true);
  };

  const handleOk = () => {
    setIsOpenDeliverer(false);
  };

  const handleCancel = () => {
    setIsOpenDeliverer(false);
  };

  const columns: ColumnsType<any> = [
    {
      title: "Purchase Order",
      children: [
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
        },
        {
          title: "Brand",
          dataIndex: "brand",
          key: "brand",
        },
        {
          title: "Models",
          dataIndex: "model",
          key: "model",
        },
        {
          title: "Description",
          dataIndex: "specification",
          key: "specification",
        },
        {
          title: "Quantity",
          dataIndex: "qty",
          key: "qty",
        },
        {
          title: `Price(RWF)`,
          dataIndex: "data",
          key: "data",
          render: (text, record) => (
            <p> {record?.data?.priceUnit?.toLocaleString()}</p>
          ),
        },
        {
          title: "QTY Left To Be Delivered",
          dataIndex: "quantityLeft",
          key: "quantityLeft",
          render: (text, record) => (
            <span
              style={{
                color: `${record.quantityLeft === 0 ? "green" : "red"}`,
              }}
            >
              {" "}
              {record.quantityLeft}
            </span>
          ),
        },
      ],
    },
    {
      title: "To Be Delivered",
      children: [
        {
          title: "Quantity",
          dataIndex: "qtySent",
          key: "qtySent",
          render: (text, record) => (
            <InputNumber
              type="number"
              min={0}
              disabled={record?.status === "Fully-In-Transit"}
              max={record.quantityLeft}
              defaultValue={parseInt(text)}
              onChange={(value: any) => {
                record.qtySent = value;
                setQSent(value);
              }}
            />
          ),
        },
        {
          title: `Amount(RWF)`,
          dataIndex: "total",
          key: "total",
          render: (text, record) => (
            <>
              <p>
                {" "}
                {record?.qtySent &&
                  (record.total =
                    record?.qtySent *
                    record?.data?.priceUnit)?.toLocaleString()}
              </p>
            </>
          ),
        },
      ],
    },
  ];

  useEffect(() => {
    getTrackingTypeAction("")(dispatch);
  }, [dispatch]);
  useEffect(() => {
    getDelivererAction("")(dispatch);
  }, [dispatch]);

  useEffect(() => {
    value &&
      value.forEach((el: any) => {
        el &&
          dataMapper.push({
            type: el.quotationItem.product.type,
            brand: el.quotationItem.product.brand,
            model: el.quotationItem.product.model,
            specification: `${el.quotationItem?.specification
              ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
              ?.join(", ")}`,
            qty: el.quantity,
            quantityLeft: el.quantityLeft,
            qtySent: "",
            total: "",
            status: product?.selected?.status,
            purchaseItem: el._id,
            data: el,
          });
      });

    setData(dataMapper);
  }, [product.selected]);

  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  useEffect(() => {
    forceUpdate({});
  }, []);
  const handleModal = () => {
    setIsModalOpen(true);
  };

  const onChangeDeliverer = (value: string, option: any) => {
    setDeliverer(option);
  };

  const onChangeType = (value: string) => {
    setShipingType(value);
  };
const onSearch=()=>{
  getDelivererAction("")(dispatch)
}
  const delivererOptions = tracking?.allDeliverer?.data?.map((el: any) => ({
    value: el?._id,
    label: el?.name,
    data: el,
  }));

  const renderFooter = () => {
    return (
      <p className="text-end font-bold text-lg">
        {" "}
        Total:
        <span className="ml-2 font-normal">
          {data
            .reduce((total: number, current: any) => total + current?.total, 0)
            .toLocaleString()}{" "}
          {"RWF"}
        </span>
      </p>
    );
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        style={{ width: "100%" }}
        pagination={false}
        footer={renderFooter}
      />
      <Stack>
        <TextArea
          rows={4}
          style={{ width: "500px" }}
          placeholder="Terms & Conditions"
          onChange={(e) => setTermsAndConditions(e.target.value)}
        />
      </Stack>
      <p className="text-[#030229] text-lg font-semibold pt-2">
        Deliverer Details
      </p>
      <Form form={form} className="w-full" layout="vertical">
        <div className="w-full flex gap-20">
          <div className="w-1/3">
            <Form.Item
              name="deliverer"
              label={
                <h1 className="text-[#030229B2] text-base">Select Deliverer</h1>
              }
              rules={[
                {
                  required: true,
                  message: "tracking type is required!",
                  whitespace: true,
                },
              ]}
            >
              <Select
              onSearch={onSearch}
                showSearch
                placeholder="Select Deliverer"
                optionFilterProp="label"
                filterSort={(optionA, optionB) =>
                  (
                    optionA?.label?.toString().toLowerCase() ?? ""
                  ).localeCompare(
                    optionB?.label?.toString().toLowerCase() ?? ""
                  )
                }
                loading={tracking?.isFetching}
                onChange={onChangeDeliverer}
                options={delivererOptions}
                style={{ height: 45 }}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider />
                    <button
                      onClick={showNewDelivererModal}
                      className="bg-[#605BFF] h-10 text-base text-center w-full rounded-md text-white"
                    >
                      + Create New Deliverer
                    </button>
                  </div>
                )}
              />
            </Form.Item>
            <Form.Item name="trackingType" label={<h1 className="text-[#030229B2] text-base">Shipping Type</h1>}>
              <Select
                style={{ height: 45 }}
                showSearch
                placeholder="Select a tracking type"
                optionFilterProp="label"
                onChange={onChangeType}
              >
                <Select.Option selected>Choose a Type</Select.Option>
                <Select.Option value="Road">Ground (road)</Select.Option>
                <Select.Option value="Rail">Rail</Select.Option>
                <Select.Option value="Ocean">Ocean</Select.Option>
                <Select.Option value="Air">Air</Select.Option>
              </Select>
            </Form.Item>
          </div>
        </div>
      </Form>

      <Stack alignItems="end" spacing={2} sx={{ width: "100%" }}>
        <LoadingButton
          onClick={async () => {
            await createDeliveryNoteAction("", {
              pOrderID: product.selected._id,
              termsAndConditions: termsAndConditions,
              listOfItems: data,
              shippingDetails: {
                shiptype: shipingType,
                trackingId: "",
                name: `${deliverer?.data?.company} / ${deliverer?.data?.name}`,
                contact: deliverer?.data?.phone,
              },
              isNotify: true,
              notification: {
                action: `Delivery Note from ${product?.selected?.supplier?.name}`,
                role: ["admin", "finance", "finance-manager", "inventory"],
                message: `Shipping Type: ${shipingType}, Telephone: ${deliverer?.data?.phone}, 
                Name: ${deliverer?.data?.name}, Email: ${deliverer?.data?.email} Company: ${deliverer?.data?.company}`,
                title: "Delivery Note",
              },
            })(dispatch);
            handleModal();
          }}
          sx={{ width: "8rem", height: "2.5rem" }}
          variant="contained"
          disabled={product?.selected?.status === "Fully-In-Transit"}
          endIcon={<SendIcon />}
          loading={product.isFetching}
        >
          Send
        </LoadingButton>

        <Modal
          title={""}
          open={isModalOpen}
          footer={null}
          maskClosable={false}
          className="min-w-min"
          style={{ marginTop: "200px" }}
          bodyStyle={{ height: 350 }}
          // icon={props?.icon || <HomeIcon />}
        >
          <div>
            <Paper
              style={{ width: 1200, marginTop: "20px", boxShadow: "none" }}
            >
              <Result title={"Delivery Note Sent successfully"} />
            </Paper>
          </div>
        </Modal>
      </Stack>
      <Modal
        title={
          <h1 className="text-[#030229] text-lg py-2">Create Deliverer</h1>
        }
        width={800}
        open={isOpenDeliverer}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <CreateDelivererForm onOk={handleOk} />
      </Modal>
    </>
  );
};

export default App;
function setQSent(value: any) {
  throw new Error("Function not implemented.");
}
