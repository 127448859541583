import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
}

const DetailsTabs = (props: any) => {
  return (
    <div className="bg-white w-full  rounded-lg ">
      <div className="flex justify-center ">
        <Tabs value={props?.activeTab} onChange={props?.handleChange}>
          {props?.tabs?.map((tab: any, index: number) => (
            <Tab label={tab?.title} />
          ))}
        </Tabs>
      </div>
      {props?.tabs?.map((tab: any, index: number) => (
        <TabPanel value={props?.activeTab} index={index}>
          {tab?.component}
        </TabPanel>
      ))}
    </div>
  );
};

export default DetailsTabs;
