import React, { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Form, Row, Col, notification, Input, Checkbox } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { adjustStockAction } from "../../store/shop/actions";
import { Stack } from "@mui/material";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { getAllStoclAction } from "../../store/pos/actions";
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const AdjustStockForm = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { shopId } = useParams();
  const { auth, shop } = useSelector((state: any) => state);
  const [isRevert, setIsRevert] = useState(false);

  const onFinish = async (values: any) => {
    const payLoad = {
      productPriceListItem: props?.productData?.productSpecs?._id,
      product: props?.productData?.productSpecs?.product?._id,
      shop: shopId,
      list: values?.serialNumber?.trim().split(/\s+/),
      isRevert,
    };

    if (
      new Set(values?.serialNumber?.trim()?.split(" ")).size !==
      values?.serialNumber?.trim()?.split(" ").length
    ) {
      return notification.warning({
        message: "IMEI has been duplicated",
        description: `${values?.serialNumber
          ?.split(" ")
          .filter(
            (value: any, index: any) =>
              values?.serialNumber?.trim()?.split(" ").indexOf(value) !== index
          )
          .join(" , ")}`,
      });
    }

    if (values?.serialNumber?.trim()?.split(" ").length > 100) {
      return notification.warning({
        message: "IMEI limits is 100",
        description: `Remove ${
          values?.serialNumber?.trim()?.split(" ").length - 100
        } IMEI `,
      });
    }
    await adjustStockAction(payLoad, auth?.token)(dispatch);
    if (auth?.token && shopId) {
      await getAllStoclAction(auth?.token, `?shop=${shopId}`)(dispatch);
    }

    props?.onCancel();
    form.resetFields();
  };

  return (
    <>
      <div className="w-4/5">
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={onFinish}
          style={{ minWidth: 900 }}
          scrollToFirstError
        >
          <div className="">
            <Row style={{ display: "flex", flexDirection: "column" }}>
              <h1 className="font-bold text-2xl text-gray-900 pb-4 pl-16">
                ADJUST STOCK
              </h1>

              <Checkbox
                onChange={(e: CheckboxChangeEvent) => {
                  console.log("****>>><<>>: ", e.target.checked);
                  setIsRevert(e.target.checked);
                }}
              >
                Do you want to revert ?
              </Checkbox>
              <Col span={12}>
                <Form.Item
                  name="serialNumber"
                  style={{ width: 700 }}
                  rules={[
                    {
                      required: true,
                      message: "IMEI is required",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col
                span={24}
                className="w-2/5 flex items-center justify-between mx-20"
              >
                <LoadingButton
                  type="submit"
                  variant="contained"
                  sx={{ minWidth: 300 }}
                  loading={shop?.isFetching}
                >
                  Submit
                </LoadingButton>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AdjustStockForm;
