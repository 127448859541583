import React from "react";
import { Divider, Table } from "antd";
import type { TableColumnsType } from "antd";
import { useSelector } from "react-redux";

interface DataType {
  key: React.Key;
  items: string;
  qty: number;
  weight: string;
}

const ItemTable = () => {
  const { tracking } = useSelector((state: any) => state);
  const columns: TableColumnsType<DataType> = [
    {
      title: "Items",
      dataIndex: "data",
      render: (record: any) => {
        return (
          <>
            <div>
              <p className="text-[#03022980] text-[10px]">
                {record?.product?.product?.brand}
              </p>
              <p className="text-[#030229] text-[12px]">
                {record?.product?.product?.model}
              </p>
            </div>
          </>
        );
      },
    },
    {
      title: "Qty",
      dataIndex: "qty",
    },
    {
      title: "Weight",
      dataIndex: "weight",
    },
  ];
  const dataSource = tracking?.checkParckage?.productList?.map((index:any,el: any) => {
    return {
      key: index,
      items: el,
      qty: el?.quantity,
      weight: "N/A",
      data: el,
    };
  });
  return (
    <>
      <Table<DataType>
        columns={columns}
        dataSource={dataSource}
        size="middle"
        loading={tracking?.isFetching}
      />
    </>
  );
};

export default ItemTable;
