import React, { useEffect, useState } from "react";
// import LoadingButton from "@mui/lab/LoadingButton";
import { Form, Select, Row, Col, notification } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllChannelAction,
  getAllShopAction,
} from "../../store/channel/actions";
import {
  getAllChannelTotransferAction,
  getAllShopTotransferAction,
} from "../../store/transfer/actions";

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const App = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { channel, auth, transfer } = useSelector((state: any) => state);
  const [selectedChannelId, setSelectedChannelId] = useState("");
  const [selectedChannelToTransId, setSelectedChannelToTransId] = useState("");

  //from channel action
  useEffect(() => {
    auth?.token && getAllChannelAction(auth?.token)(dispatch);
  }, [auth?.token, dispatch]);
  //from channel action
  React.useEffect(() => {
    auth?.token &&
      selectedChannelId &&
      getAllShopAction(auth?.token, `channel=${selectedChannelId}`)(dispatch);
  }, [auth.token, dispatch, selectedChannelId]);

  //get all Channel toTransfer from Transfer action
  useEffect(() => {
    auth?.token && getAllChannelTotransferAction(auth?.token)(dispatch);
  }, [auth?.token, dispatch]);

  //get all shop toTransfer from Transfer action
  React.useEffect(() => {
    auth?.token &&
      selectedChannelToTransId &&
      getAllShopTotransferAction(
        auth?.token,
        `channel=${selectedChannelToTransId}`
      )(dispatch);
  }, [auth.token, dispatch, selectedChannelToTransId]);

  const handleSelectFrom = (value: any) => {
    setSelectedChannelId(value);
  };
  const handleSelectTo = (value: any) => {
    setSelectedChannelToTransId(value);
  };

  const onFinish = async (values: any) => {
    // console.log(">>>>>>>", values);
    if (
      values.fromchannel === values.tochannel &&
      values.fromshop === values.toshop
    ) {
      notification.warning({
        message: "You can't Transfer Within the same Shop",
      });
    }
  };
  return (
    <>
      <div className="w-4/5">
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={{}}
          style={{ minWidth: 900 }}
          scrollToFirstError
        >
          <div className="flex items-center justify-between">
            <Row
              gutter={[16, 16]}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <h1 className="font-bold text-2xl text-gray-900 pb-4 pl-10">
                From
              </h1>
              <Col span={12}>
                <Form.Item
                  name="fromchannel"
                  label="Channel"
                  style={{ width: 300 }}
                  rules={[
                    {
                      required: true,
                      message: "Channel is required!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Select onChange={handleSelectFrom}>
                    {channel?.all?.data?.map((el: any) => (
                      <Option value={el?._id}>{el?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="fromshop"
                  label="Shop"
                  style={{ width: 300 }}
                  rules={[
                    {
                      required: true,
                      message: "Shop is required!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Select onChange={(value: any) => props.setShopFrmId(value)}>
                    {channel?.allShop?.data?.map((el: any) => (
                      <Option value={el?._id}>{el?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row
              gutter={[12, 12]}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <h1 className="font-bold text-2xl text-gray-900 pb-4 pl-10">
                To
              </h1>
              <Col span={12}>
                <Form.Item
                  name="tochannel"
                  label="Channel"
                  style={{ width: 300 }}
                  rules={[
                    {
                      required: true,
                      message: "Channel is required!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Select onChange={handleSelectTo}>
                    {transfer?.all?.data?.map((el: any) => (
                      <Option value={el?._id}>{el?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="toshop"
                  label="Shop"
                  style={{ width: 300 }}
                  rules={[
                    {
                      required: true,
                      message: "Shop is required!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Select onChange={(value: any) => props.setShopToId(value)}>
                    {transfer?.transferShop?.data?.map((el: any) => (
                      <Option value={el?._id}>{el?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
          {/* <div className="pl-10">
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ minWidth: 150 }}
              loading={channel.isFetching}
            >
              Save
            </LoadingButton>
          </div> */}
        </Form>
      </div>
    </>
  );
};

export default App;
