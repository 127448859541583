import { notification } from "antd";
import { myPosActions } from ".";
import { myWareHouseActions } from "../wareHouse";
import {
  getAllServiceByTransactionId,
  checkoutCart,
  getAllCarts,
  getAllServiceStock,
  getAllServiceTransaction,
  deleteCart,
  getPriceLists,
  getAllServiceStockByStatus,
  getRecentPosService,
  createNosaleService,
  getAllNosaleService,
  deleteNosales,
  updateReceivedProductStatus,
  getOnePriceListItem,
  deletePendingTransactionService,
  getAllShopsAgentsService,
  getAllTransactionInRangeService,
  updatePaidOnService,
  updatePaymentAndAmountService,
  revertSalesService,
  getOneShopStockService,
  getshopstockitemService
} from "./services";

export const getAllTransactionAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await getAllServiceTransaction(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myPosActions.setTransation(res));
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllTransactionInRangeAction = (
  token: string,
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await getAllTransactionInRangeService(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myPosActions.setTransation(res));
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getSalesReportTrnsactionAction = (
  token: string,
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await getAllServiceTransaction(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myPosActions.setSalesReport(res));
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllStoclAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await getAllServiceStock(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myPosActions.setStock(res));
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllStockActionByStatus = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await getAllServiceStockByStatus(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myPosActions.setStockByStatus(res));
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllByTransactionIdAction = (token: string, itemId: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await getAllServiceByTransactionId(itemId, token);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myPosActions.setSelectedTransation(res));
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getAllCartsAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await getAllCarts(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myPosActions.setCarts(res));
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const checkoutCartAction = (
  token: string,
  cartId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await checkoutCart(token, cartId, data);
      console.log(">>>>>>>:: ", res?.response);
      if (res?.status === 200) {
        dispatch(myPosActions.setIsFetching(false));
        dispatch(myPosActions.setIsCartok(true));
        dispatch(myWareHouseActions.setCreatedCart({}));
        notification.success({ message: "Sold successfully!" });
        return true;
      } else if (res?.response?.status === 400) {
        dispatch(myPosActions.setIsCartok(false));
        dispatch(myPosActions.setIsFetching(false));
        notification.error({ message: res?.response?.data?.error });
        return false;
      } else if (res?.response?.status === 409) {
        console.log("here.........");
        dispatch(myPosActions.setIsCartok(false));
        dispatch(myPosActions.setIsFetching(false));
        notification.warning({ message: res?.response?.data?.error });
        return false;
      }
      dispatch(myPosActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
      notification.error({
        message: "There was error while checking out the cart. Try again later",
      });
      return false;
    }
  };
};

export const deleteCartAction = (token: string, cartId: any, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await deleteCart(token, cartId);
      if (res?.status === 200) {
        const resCart = await getAllCarts(token, query);
        dispatch(myPosActions.setCarts(resCart));
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getPricelistAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await getPriceLists(token, query);
      // console.log(">>>>>>>:: channels", res);
      if (res?.status === 200) {
        dispatch(myPosActions.setPriceList(res));
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getRecentPosOrdersAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await getRecentPosService(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myPosActions.setRecent(res));
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      notification.error({
        message: "something went wrong whle fetching recent activities",
      });
    }
  };
};

export const createNosaleAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await createNosaleService(data, token);

      // console.log(">>>>>>>:: ", res);
      if (res?.status === 201) {
        dispatch(myPosActions.setNew(res));
        dispatch(myPosActions.setIsFetching(false));
        notification.success({ message: "Sent Successfully" });
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getAllNosaleAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await getAllNosaleService(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myPosActions.setNosale(res));
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteNosaleAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await deleteNosales(token, itemId, data);
      // console.log(" delete res.......>", res)
      if (res?.status === 200) {
        dispatch(myPosActions.setIsFetching(false));
        notification.success({ message: "deleted Succesfully" });
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateReceivedProductAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await updateReceivedProductStatus(token, itemId, data);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myPosActions.setIsFetching(false));
        notification.success({ message: "well confirmed!" });
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneShopStockAction = (token: string, itemId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await getOneShopStockService(token, itemId);
      // console.log(">>>>>>>:: ", res);

      if (res?.status === 200) {
        dispatch(myPosActions.setShopStock(res));
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};


export const getShopStockItemAction = (token: string, itemId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await getshopstockitemService(token, itemId);
      // console.log(">>>>>>>:: ", res);

      if (res?.status === 200) {
        dispatch(myPosActions.setShopStockItem(res));
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getOnePriceListItemAction = (token: string, itemId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await getOnePriceListItem(token, itemId);

      if (res?.status === 200) {
        dispatch(myPosActions.setOnePriceListItem(res));
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const deletePendingTransactionAction = (token: string, itemId: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await deletePendingTransactionService(token, itemId);
      if (res?.status === 200) {
        const resAll = await getAllServiceTransaction(token, "?limit=40");
        dispatch(myPosActions.setTransation(resAll));
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllShopsAgentAction = (token: string, itemId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await getAllShopsAgentsService(itemId, token);

      if (res?.status === 200) {
        dispatch(myPosActions.setShopAgent(res));
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updatePaidOnAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await updatePaidOnService(token, itemId, data);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myPosActions.setIsFetching(false));
        notification.success({ message: "Updated Successfully!" });
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updatePaymentAndAmountAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await updatePaymentAndAmountService(token, itemId, data);
      if (res?.status === 200) {
        dispatch(myPosActions.setIsFetching(false));
        notification.success({ message: "Updated Successfully!" });
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const revertSalesAction = (token: string, query: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await revertSalesService(token, query, data);
      if (res?.status === 200) {
        dispatch(myPosActions.setIsFetching(false));
        notification.success({ message: "Done Successfully!" });
        return true;
      }
      dispatch(myPosActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};
