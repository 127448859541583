import React, { useState, useEffect } from "react";
import HeaderComponent from "../../../components/HeaderChannel";
import { Paper, Stack, Box } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import StreamGrid from "../../../components/grids/StreamGrid";
import { useDispatch, useSelector } from "react-redux";
import { getAllChannelAction } from "../../../store/channel/actions";
import NewStreamForm from "../../../components/forms/newStreamForm";

interface Contact {
  name: string;
  position: string;
  phone: string;
}

interface posAndSub {
  name: string;
  link: string;
  contact: Contact[];
  isEnabled: boolean;
}

const ChannelPosAndSub = (props: any) => {
  const { auth, channel,layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    auth?.token && getAllChannelAction(auth?.token)(dispatch);
  }, [auth?.token, dispatch]);

  const datas: posAndSub[] = [
    {
      name: "POS",
      contact: [{ name: "Jackson", position: "manager", phone: "0788181818" }],
      link: "pos/overview",
      isEnabled: channel?.selected?.isPOSActive,
    },
    {
      name: "SUBSCRIPTIONS",
      contact: [{ name: "Jackson", position: "manager", phone: "0788181818" }],
      link: "sub/overview",
      isEnabled: channel?.selected?.isSubscriptionActive,
    },
  ];

  return (
    <Stack spacing={1} sx={{ mt: 3 }}>
      {!["sales-supervisor"]?.includes(auth?.user?.role) && (
        <HeaderComponent
          item=""
          modelTitle="Add New"
          isNotCollapse={true}
          ModelComponent={<NewStreamForm onCancel={handleCancel} />}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleCancel={handleCancel}
        />
      )}
      <Box sx={{ pt: 2 }}>
        <div className={`grid ${layout?.isSideNavOpen? "sm:grid-cols-1 lg:grid-cols-2":"sm:grid-cols-2 lg:grid-cols-3"} xl:grid-cols-3 2xl:grid-cols-4 gap-4`}>
          <StreamGrid data={datas} />
        </div>
      </Box>
    </Stack>
  );
};

export default ChannelPosAndSub;
