import React, { useEffect, useState } from "react";
import CommissionDetailsTable from "../../../../components/tables/commissionDetailsTable";
import { useDispatch, useSelector } from "react-redux";
import { getAllCommissionTransactionAction } from "../../../../store/channel/actions";

const CommissionDetail = (props: any) => {
  const { auth, channel } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  useEffect(() => {
    auth?.token &&
      getAllCommissionTransactionAction(
        auth?.token,
        `?shop=${props?.data?._id}&commissionProfile=${
          props?.data?.key
        }&status=pending&page=${page - 1}&limit=${limit}`
      )(dispatch);
  }, [auth?.token, props?.data?._id, dispatch]);
  return (
    <div className="ml-2 text-black">
      <div className=" w-full rounded-md">
        <div className="flex w-full  gap-10 mt-2">
          <div className="w-full mr-20">
            <div className="flex w-full gap-10">
              <div className="w-[20%]">
                <p className="text-[#030229] pt-1 font-medium text-base">
                  Shop
                </p>
                <p className="text-[#030229] pt-1 font-medium text-base">
                  Total Commission
                </p>
                <p className="text-[#030229] pt-1 font-medium text-base">
                  Paid Commission
                </p>
                <p className="text-[#030229] pt-1 font-medium text-base">
                  Balance
                </p>
                <p className="text-[#030229] pt-1 font-medium text-base">
                  Shop Owner
                </p>
                <p className="text-[#030229] pt-1 font-medium text-base">
                  Account Number
                </p>
                <p className="text-[#030229] pt-1 font-medium text-base">
                  Address
                </p>
               
              </div>
              <div>
                <p className="text-[#03022980] pt-1 text-base">
                  {channel?.isFetching ? "...." : props?.data?.shop}
                </p>
                <p className="text-[#03022980] pt-1 text-base">
                  {channel?.isFetching
                    ? "...."
                    : props?.data?.TotalAmount?.toLocaleString()}
                </p>
                <p className="text-[#03022980] pt-1 text-base">
                  {channel?.isFetching
                    ? "...."
                    : props?.data?.AmountPaid?.toLocaleString()}
                </p>
                <p className="text-[#03022980] pt-1 text-base text-red-400">
                  {channel?.isFetching
                    ? "...."
                    : props.data?.owed?.toLocaleString()}
                </p>
                <p className="text-[#03022980] pt-1 text-base">
                  {channel?.isFetching
                    ? "...."
                    : channel?.allCommissionTransaction?.data[0]
                        ?.commissionProfile?.accountName}
                </p>
                <p className="text-[#03022980] pt-1 text-base">
                  {channel?.isFetching
                    ? "...."
                    : channel?.allCommissionTransaction?.data[0]
                        ?.commissionProfile?.account}
                </p>
                <p className="text-[#03022980] pt-1 text-base">
                  {channel?.isFetching
                    ? "...."
                    : channel?.allCommissionTransaction?.data[0]
                        ?.commissionProfile?.address}
                </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-4">
        <CommissionDetailsTable
          limit={limit}
          setLimit={setLimit}
          setPage={setPage}
          data={props}
        />
      </div>
    </div>
  );
};

export default CommissionDetail;
