export interface Department {
  name: string;
  count: number;
}

export interface Employee {
  name: string;
  department: string;
  email: string;
  status: string;
  startingDate: string;
  equipment: string;
  active: boolean;
}

export const departments: Department[] = [
  { name: "Finance", count: 7 },
  { name: "Dev Team", count: 10 },
  { name: "Sales", count: 30 },
  { name: "Agents", count: 100 },
  { name: "Inventory", count: 5 },
];

export const stats = {
  totalEmployees: 152,
  active: 360,
  inactive: 30,
  male: 112,
  female: 60,
  permanent: 60,
  contract: 100,
  interns: 2,
  withEquipments: 72,
};

export const employees: Employee[] = [
  { name: "Alice Mutuyimana", department: "Dev", email: "Alice@samphone.co", status: "Full Time", startingDate: "29 July 2023", equipment: "N/A", active: true },
  { name: "Jackson Mugiraneza", department: "Dev", email: "Jackson@samphone.co", status: "Full Time", startingDate: "29 July 2023", equipment: "N/A", active: true },
  { name: "Bernadine Bazubagira", department: "Dev", email: "Bernadine@samphone.co", status: "Full Time", startingDate: "29 July 2023", equipment: "N/A", active: true },
  { name: "Clever Umuhuza", department: "Dev", email: "Clever@samphone.co", status: "Freelancer", startingDate: "29 July 2023", equipment: "Smartphone", active: true },
  { name: "Irene Murindahabi", department: "Dev", email: "Irene@samphone.co", status: "Freelancer", startingDate: "29 July 2023", equipment: "N/A", active: true },
  { name: "Jean Damascene", department: "Dev", email: "Jean@samphone.co", status: "Contract", startingDate: "29 July 2023", equipment: "N/A", active: true },
  { name: "Boaz Khalifa", department: "Dev", email: "Boaz@samphone.co", status: "Remote", startingDate: "29 July 2023", equipment: "Laptop", active: true },
];
