import React from "react";
import { Paper } from "@mui/material";
import { Tag, Modal } from "antd";
import { searchValue } from "../../utils/setColor";
import { generatePath, useNavigate } from "react-router-dom";
import ProfileAvatal from "../buttons/ProfileAvatal";
import ReAssignShopForm from "../forms/Re_AssignShopForm";
import { useSelector } from "react-redux";
import mtnLogo from "../../assets/MTN Logo.svg"
import ForceLogo from "../../assets/icons/dashboard/Samphone_Logo.svg";
const ShopCard = (props: any) => {
  const { auth } = useSelector((state: any) => state);
  const [isModalAssigShop, setisModalAssigShop] = React.useState(false);
  const [assignShopData, setAssignShopData] = React.useState<any>({});
  const navigate = useNavigate();

  const style = {
    width: "100vh",
    color: "black",
    p: 2,
  };

  const handleCancelAssignShop = () => {
    setisModalAssigShop(false);
  };

  const AssignShopModelAgent = (value: any) => {
    setisModalAssigShop(true);
    setAssignShopData(value);
  };

  const showUserDetails = (items: any) => {
    const Id = items.id;
    const path = generatePath(`${items?.id}`, { Id });
    navigate(path);
  };

  return (
    <>
      {props?.data?.fname && (
        <>
          <div className="relative bg-white mb-4 p-2 border border-white text-black min-h-[126px] rounded-md hover:border-gray-300 duration-100">
            <div
              className="p-1 pl-2 space-y-6"
              onClick={() => showUserDetails(props?.data)}
            >
              <div>
                <div className="flex justify-between w-full">
                  <div className="flex gap-3" style={{ width: "100%" }}>
                    <ProfileAvatal
                      picture={props?.data?.picture}
                      name={props?.data?.name}
                    />
                    <div>
                      <h1 className="font-semibold text-sm capitalize">
                        {props?.data?.fname}
                      </h1>
                      <p className="text-xs" style={{ color: "blue" }}>
                        {props?.data?.shop && props?.data?.shop}
                      </p>
                    </div>
                  </div>
                  <div>
                    <Tag
                      color={searchValue(props?.data.status)}
                      style={{ minWidth: "4rem", textAlign: "center" }}
                    >
                      {props?.data.status}
                    </Tag>
                   {props?.data.company && <Tag
                      color={searchValue(props?.data.status)}
                      style={{ minWidth: "4rem", textAlign: "center",marginTop:"3px" }}
                    >
                      {/* {props?.data.company} */}
                      {props?.data.company==="MTN" &&<img src={mtnLogo} alt="MTN logo" className="my-[1px]"/>}{props?.data.company==="samphone" &&<img src={ForceLogo} width={70} alt="Samphone logo" className="my-2"/>}
                    </Tag>}
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex flex-col">
                  <p className="text-xs">{props.data?.email}</p>
                  <p className="text-xs">{props.data?.phone}</p>
                  {/* <p className="text-xs">{props.data?.company}</p> */}
                </div>
              </div>
            </div>
            {["admin", "finance-manager", "dev",  "freelancer","team-lead","sales-agent",]?.includes(
              auth?.user?.role?.toLowerCase()
            ) && (
              <div className="w-fit flex absolute right-2 bottom-1 hover:text-white ">
                <button
                  className="text-xs flex items-center justify-center bg-transparent border-2 py-1.5 px-2 mt-4 rounded-md hover:bg-black"
                  onClick={() => AssignShopModelAgent(props.data)}
                >
                  Re-Assign Shop
                </button>
              </div>
            )}
          </div>
        </>
      )}
      <Modal
        title={`Assign Shop To ${assignShopData?.fname}`}
        open={isModalAssigShop}
        onCancel={handleCancelAssignShop}
        footer={null}
        className="min-w-min"
      >
        <div>
          <Paper sx={style}>
            <ReAssignShopForm data={assignShopData?.id} />
          </Paper>
        </div>
      </Modal>
    </>
  );
};

export default ShopCard;
