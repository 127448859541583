import React from "react";
import { useSelector } from "react-redux";
import { convertResultsRevenueToObject } from "../../../utils/converter";
import { LineChart } from "react-chartkick";
import { getDefaultCurrencyCode } from "../../../utils/helper";

export default function CustomChart() {
  const { shop, company } = useSelector((state: any) => state);
  const dataByRange = [
    {
      name: "Revenue",
      data: convertResultsRevenueToObject(
        shop?.shopStat?.data?.revenue?.currentRevenue?.results
      ),
      color: "#2943D6",
    },
  ];

  return (
    <div>
      <LineChart
        data={dataByRange}
        prefix={`${getDefaultCurrencyCode(company)}`}
        thousands=","
        xtitle="Days"
        // height={"100%"}
        loading="Loading..."
        // options={lineOptions}
        legend={false}
      />
    </div>
  );
}
