import React, { useState } from "react";
import SettingButton from "../SettingButton";
import { useSelector } from "react-redux";
import { HiOutlinePlus } from "react-icons/hi";
import { ReactComponent as EditSvg } from "../../../../assets/icons/editicon.svg";
import { Modal, Space, Tooltip, Typography } from "antd";
import { Button, Stack } from "@mui/material";
import BusinessProductModal from "../../../../components/Modals/NewBussiProductModal";
import AddCompanyWarehouse from "../../../../components/forms/NewCompanyWarehouse";
import { CiUnlock } from "react-icons/ci";
import DeactivateCompanyForm from "../../../../components/forms/DeactivateCompany";

const CompanyWarehouse = () => {
  const { auth, layout, company } = useSelector((state: any) => state);
  const [warehouseModal, setWarehouseModal] = useState(false);
  const [updateWarehouse, setUpdateWarehouse] = useState(false);
  const { Paragraph, Text } = Typography;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lock, setLock] = useState(false)

  const showModal = (value: any) => {
    setIsModalOpen(true);
    setLock(value)
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const openWarehouseModal = (value: any) => {
    setWarehouseModal(true);
    setUpdateWarehouse(value);
  };
  const closeWarehouseModal = () => {
    setWarehouseModal(false);
  };
  return (
    <div>
      <div className="bg-white mt-6 rounded-md text-[#0F0F47]">
        <div className="flex justify-between mb-6">
          <h1 className="text-lg font-[600] py-4 px-2"> Warehouses</h1>
          <div className=" my-4 float-right mr-6">
            <SettingButton
              btnName="New Warehouse"
              btnBgColor="[#605BFF]"
              textColor="white"
              icon={<HiOutlinePlus size={20} />}
              onClick={openWarehouseModal}
            />
          </div>
        </div>
        <div
          className={`flex flex-wrap ${layout?.isSideNavOpen ? 'gap-3' : 'gap-8'}`}
        >
          {company?.selected?.warehouse?.map((data: any, _index: any) => (
            <div className={`${layout?.isSideNavOpen ? 'w-[22rem]' : 'w-[24rem]'} h-[10rem]  border border-[#030229] border-opacity-10 rounded-[10px] p-4 relative `}>
              <h1 className="text-[16px] font-medium">{data?.name}</h1>
              <div className="flex gap-4 absolute top-4 right-4">

                {data?.isActive && (
                  <>
                    <EditSvg
                      onClick={() => openWarehouseModal(data)}
                      className="cursor-pointer"
                    />
                    <Tooltip title="Deactivate">
                      <div className="opacity-70 mb-1">
                        <CiUnlock size={20} onClick={() => showModal(data?._id)} />
                      </div>
                    </Tooltip></>
                )}
                {!data?.isActive && (<Tooltip title="Deactivated">
                  <div className="opacity-60">
                    <Button sx={{ textTransform: 'none' }} variant="outlined" color="error" size="small"> Deactivated</Button>
                  </div>
                </Tooltip>)}
              </div>
              <div className=" mt-4 flex justify-betwen">
                <Stack direction="column" sx={{ width: "100%" }}>
                  <Stack
                    direction="row"
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Space>
                      <p className="text-base text-[#030229] opacity-80 font-medium py-2">
                        Province :{" "}
                      </p>
                      <p className="text-sm text-[#03022980]">
                        {data?.shopLocation?.province}
                      </p>
                    </Space>
                    <Space>
                      <p className="text-base text-[#030229] opacity-80 font-medium py-2">
                        District :{" "}
                      </p>
                      <p className="text-sm text-[#03022980]">
                        {data?.shopLocation?.district}
                      </p>
                    </Space>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Paragraph
                      copyable={{
                        text: async () =>
                          new Promise((resolve) => {
                            setTimeout(() => {
                              resolve(data?.shopLocation?.coordinate?.lng);
                            }, 500);
                          }),
                      }}
                    >
                      Longitude
                    </Paragraph>

                    <Paragraph
                      copyable={{
                        text: async () =>
                          new Promise((resolve) => {
                            setTimeout(() => {
                              resolve(data?.shopLocation?.coordinate?.lat);
                            }, 500);
                          }),
                      }}
                    >
                      Latitude
                    </Paragraph>
                  </Stack>
                </Stack>
              </div>
            </div>
          ))}
        </div>
      </div>
      <BusinessProductModal
        cancel={closeWarehouseModal}
        isModalOpen={warehouseModal}
        content={
          <AddCompanyWarehouse
            updateWarehouse={updateWarehouse}
            onCancel={closeWarehouseModal}
          />
        }
      />
      <Modal
        width={600}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <DeactivateCompanyForm lock={lock} onCancel={handleCancel} />
      </Modal>
    </div >
  );
};
export default CompanyWarehouse;
