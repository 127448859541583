import React from "react";
import { Spin } from "antd";
import { useSelector } from "react-redux";

const DashboardIkosoraMainCard: React.FC<{
  currentTotalQty: number;
  title: string;
  height?: string;
}> = ({ title, height, currentTotalQty }) => {
  const { dashboard } = useSelector((state: any) => state);
  return (
    <div
      className={`relative flex flex-col ${
        height ? height : "max-h-[256px]"
      } p-4 bg-white rounded-md`}
    >
      <p className="text-gray-400 capitalize mb-4">{title}</p>
      <p className="text-2xl font-semibold mb-2">
        {!dashboard?.isFetchingIkosoraStock ? (
          <span className="text-sm">{`${currentTotalQty || 0} Pieces`}</span>
        ) : (
          <Spin />
        )}
      </p>
    </div>
  );
};

export default DashboardIkosoraMainCard;
