import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getAllSaveTheChildrenService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/invoice/payment${query}`, token);
};

export const getAllStockReportService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/stats/shopStock${query}`, token);
};

export const getAllTransferReportService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(
    `${SERVER_URL}/shopstockitem/transferDetails${query}`,
    token
  );
};

export const getAllInventoryReportService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(
    `${SERVER_URL}/shopstockitem/dailyReport${query}`,
    token
  );
};
