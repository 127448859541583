import { notification } from "antd";
import { myDiscountActions } from ".";
import {
  AddNewDiscountService,
  getAllDiscountServices,
  getAllModelServices,
  updateDiscountService,
} from "./services";

export const addNewDiscountAction = (token: string, data: any, query?: any) => {
  return async (dispatch: any) => {

    try {
      dispatch(myDiscountActions.setIsFetching(true));
      const res = await AddNewDiscountService(data, token);

      if (res?.status === 201) {
        dispatch(myDiscountActions.setNew(res));
        dispatch(myDiscountActions.setIsFetching(false));
        notification.success({ message: "Added Succesfully" });
        return true;
      }
      dispatch(myDiscountActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllDiscountAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDiscountActions.setIsFetching(true));
      const res = await getAllDiscountServices(token, query);
      if (res?.status === 200) {
        dispatch(myDiscountActions.setSelected(res));
        dispatch(myDiscountActions.setIsFetching(false));
      }
      dispatch(myDiscountActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllModelsAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDiscountActions.setIsFetching(true));
      const res = await getAllModelServices(token, query);
      if (res?.status === 200) {
        dispatch(myDiscountActions.setAllModels(res));
        dispatch(myDiscountActions.setIsFetching(false));
      }
      dispatch(myDiscountActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateDiscountAction = (
  itemId: string,
  token: string,
  data: any,
  query?: any
) => {
  return async (dispatch: any) => {
    try {

      dispatch(myDiscountActions.setIsFetching(true));
      const res = await updateDiscountService(itemId, token, data);
      if (res?.status === 200) {
        dispatch(myDiscountActions.setUpdated(res));
        const resAll = await getAllDiscountServices(token, query);
        dispatch(myDiscountActions.setSelected(resAll));
        dispatch(myDiscountActions.setIsFetching(false));
        return true;
      }
      dispatch(myDiscountActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};
