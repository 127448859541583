import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import React, { useRef, useState } from "react";

const ExpandableButton: React.FC<{
  title: string;
  children: any;
}> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const contentRef = useRef<any>(null);

  return (
    <div>
      <h4
        className="flex font-semibold text-blue-400 text-sm justify-between items-center py-2 cursor-pointer border-b border-blue-400"
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
        <span className="text-blue-500">
          {isOpen ? <CaretUpOutlined /> : <CaretDownOutlined />}
        </span>
      </h4>

      <div
        ref={contentRef}
        style={{
          maxHeight: isOpen ? `${contentRef?.current?.scrollHeight}px` : "0px",
        }}
        className={`overflow-hidden text-gray-950 transition-max-height duration-300 ease-in-out`}
      >
        <div className="pb-2 mb-4">{children}</div>
      </div>
    </div>
  );
};

export default ExpandableButton;
