import { Steps } from "antd";
import React from "react";
import "./delivery.css";
const DeliverySideNav = (props:any) => {
  const styles = {
    customShadow: {
      boxShadow: "0px 2px 24px 0px rgba(15, 15, 71, 0.07)",
    },
  };
  return (
        <div
          className="w-full h-full p-5 "
          style={styles.customShadow}
        >
          <h1 className="uppercase text-[#030229] text-lg font-semibold py-3 pb-6">
            Steps To complete delivery
          </h1>
          <Steps
            className="text-green-500"
            direction="vertical"
            current={props?.currentStep}
            items={[
              {
                title: (
                  <h1 className="text-[#030229] font-semibold text-base">
                    Your Phone Number
                  </h1>
                ),
                description: (
                  <p className="text-[#030229B2] py-3">
                    Deliverer phone number
                  </p>
                ),
              },
              {
                title: (
                  <h1 className="text-[#030229] font-semibold text-base">
                    Scan QR Code
                  </h1>
                ),
                description: (
                  <p className="text-[#030229B2] py-3">
                    Or verify buy putting 4 last of order Id
                  </p>
                ),
              },
              {
                title: (
                  <h1 className="text-[#030229] font-semibold text-base">
                    Order Preview
                  </h1>
                ),
                description: (
                  <p className="text-[#030229B2] py-3">
                    Check Order information
                  </p>
                ),
              },
              {
                title: (
                  <h1 className="text-[#030229] font-semibold text-base">
                    Insert receiver details
                  </h1>
                ),
                description: (
                  <p className="text-[#030229B2] py-3">
                    And picture of the package delivered
                  </p>
                ),
              },
              {
                title: (
                  <h1 className="text-[#030229] font-semibold text-base">
                    Confirm delivery completed
                  </h1>
                ),
              },
            ]}
          />
        </div>
  );
};

export default DeliverySideNav;
