import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";


export const getAlltransactionRangesService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/cartItem/transactionRange${query}`, token);
};






