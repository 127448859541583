import React, { useEffect, useState } from "react";
import { TEChart } from "tw-elements-react";

export default function ChartBar(): JSX.Element {
  const [backgroundColors, setBackgroundColors] = useState<string[]>([]);

  useEffect(() => {
    const currentDay = new Date().getDay();
    const colors = Array.from({ length: 7 }, (_, i) =>
      i === currentDay ? "#605BFF" : "#E7E6FF"
    );
    setBackgroundColors(colors);
  }, []);

  return (
    <TEChart
      type="bar"
       
      data={{
        labels: ["Agents", "Sales", "Dev", "Finance", "Inventory"],
        datasets: [
          {
            label: "",
            data: [0, 20, 100, 40, 80],
            backgroundColor: backgroundColors,
            borderRadius: 8,
            barPercentage: 0.5,
            barThickness: 40,
          },
        ]
       
      }}
      options={{
        scales: {
          y: {
            ticks: {
              callback: function (value: string) {
                return value + '%'; 
              }
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context: { dataset: { label: string; }; parsed: { y: string; }; }) {
                let label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                label += context.parsed.y + '%'; 
                return label;
              }
            }
          }
        }
      }}
    />
  );
}


