import React from 'react';
import { Space, Table, Tag } from 'antd';
import type { TableProps } from 'antd';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import userImage from "../assets/images/Ellipse 2354.png"
interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

const columns= [
  {
    title: 'Employee Names',
    dataIndex: 'name',
    key: 'name',
    render: (text:any) => <div className='flex gap-2'>
      <img src={userImage} alt="user" className="h-12 w-12 rounded-full"/>
      <div>
      <span>{text}</span>
      <p>Alice@samphone.co</p>
      </div>
    </div>,
  },
  {
    title: 'Gross',
    dataIndex: 'gross',
    key: 'gross',
  },
  {
    title: 'Taxes',
    dataIndex: 'tax',
    key: 'tax',
    render: (_:any,text:any) => {
      return(
     <span className='text-[#FA3659]'>{text?.tax}</span>
    )},
  },
  {
    title: 'Net Salary',
    dataIndex: 'net',
    key: 'net',
  },
  {
    title: 'Status',
    key: 'status',
    render: (text:any) => {
      return(
     <p  className={`${
      text?.status === "paid"
        ? "text-[#12B76A] border border-[#12B76A40] bg-[#0FA91E0D]"
        : text?.status === "unpaid"
        ? "text-[#F1595C] border border-[#F1595C40] bg-[#0302290D]"
        : text?.status === "pending"
        ? "text-[#F5A36B] border border-[#F5A36B40] bg-[#EB46120D]":""
    } rounded-full px-3 py-2 font-normal text-[12px] capitalize w-[7rem] text-center`}>{text?.status}</p>
    )},
  },
  {
    title: ' ',
    key: 'action',
    render: (record:any) => (
      <HiOutlineDotsVertical size={24}/>
    ),
  },
];

const data = [
  {
    key: '1',
    name: 'Alice Mutuyimana',
    gross: "BWP 10,000",
    tax: '-BWP 25,000',
    net: 'BWP 800,000',
    status: 'paid', 
  },
  {
    key: '2',
    name: 'Alice Mutuyimana',
    gross: "BWP 10,000",
    tax: '-BWP 25,000',
    net: 'BWP 800,000',
    status: 'unpaid', 
  },
  {
    key: '3',
    name: 'Alice Mutuyimana',
    gross: "BWP 10,000",
    tax: '-BWP 25,000',
    net: 'BWP 800,000',
    status: 'pending', 
  },
 
];

const PayrollSummaryTable = () => <Table columns={columns} dataSource={data} />;

export default PayrollSummaryTable;