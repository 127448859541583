import React from "react";
import { Table, Checkbox } from "antd";
import type { ColumnsType } from "antd/es/table";
import type { TableColumnsType } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAllStockActionByStatus } from "../../store/pos/actions";

interface DataType {
  key: React.Key;
  data: any;
  address: string;
}

interface ExpandedDataType {
  key: string;
  specification: any;
  data: any;
}

const WarehouseStockTable = (props: any) => {
  const { auth, product, inventory, pos } = useSelector((state: any) => state);
  const [expandedRowKey, setExpandedRowKey] = React.useState<any>(null);
  const [selectedRowStock, setSelectedRowStock] = React.useState<any>(" ");
  const [selectedRowSold, setSelectedRowSold] = React.useState<any>(" ");
  const [selectedRowTransfer, setSelectedRowTransfer] =
    React.useState<any>(" ");
  const [selectedRowIdle, setSelectedRowIdle] = React.useState<any>(" ");
  const [shopId, setShopId] = React.useState<any>("");
  const [checkedStatus, setCheckedStatus] = React.useState<any>("");
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (auth?.token && shopId && checkedStatus) {
      getAllStockActionByStatus(
        auth?.token,
        `?shop=${shopId}&product=${product?.selected?._id}&status=${checkedStatus}`
      )(dispatch);
    }
  }, [auth?.token, checkedStatus, dispatch, shopId]);

  const handleRowExpand = (expanded: any, record: any) => {
    if (expanded) {
      setExpandedRowKey(record.key);
    } else {
      setExpandedRowKey(null);
    }
  };

  const handleCheckboxChange = (selectedType: string, selectedData: any) => {
    switch (selectedType) {
      case "pending":
        setSelectedRowStock(selectedData);
        setSelectedRowIdle("");
        setSelectedRowSold("");
        setSelectedRowTransfer("");
        setCheckedStatus("pending");
        setShopId(selectedData);
        break;
      case "sold":
        setSelectedRowSold(selectedData);
        setSelectedRowIdle("");
        setSelectedRowStock("");
        setSelectedRowTransfer("");
        setCheckedStatus("sold");
        setShopId(selectedData);
        break;
      case "transfer":
        setSelectedRowTransfer(selectedData);
        setSelectedRowIdle("");
        setSelectedRowStock("");
        setSelectedRowSold("");
        setCheckedStatus("transfer");
        setShopId(selectedData);
        break;
      case "idle":
        setSelectedRowIdle(selectedData);
        setSelectedRowTransfer("");
        setSelectedRowStock("");
        setSelectedRowSold("");
        setCheckedStatus("idle");
        setShopId(selectedData);
        break;
      default:
        break;
    }
    expandedRowKey &&
      expandedRowKey !== selectedData &&
      setExpandedRowKey(null);
  };

  const expandedRowRender = () => {
    const columns: TableColumnsType<ExpandedDataType> = [
      {
        title: "Model",
        dataIndex: "data",
        key: "data",
        render: (_, record: any) => (
          <p>{record?.data?.requestedItem?.product?.product?.model}</p>
        ),
      },
      {
        title: "Description",
        dataIndex: "specification",
        key: "specification",
      },
      {
        title: "Serial Number",
        dataIndex: "data",
        key: "data",
        render: (_, record: any) => (
          <p>{record?.data?.wareHouseItem?.serialNumber}</p>
        ),
      },
      {
        title: "Status",
        key: "state",
        render: (_, record: any) => <p>{record?.data?.status}</p>,
      },
    ];

    const data: ExpandedDataType[] = [];
    pos?.stockByStatus?.data?.forEach((el: any) => {
      data.push({
        key: el?._id,
        data: el,
        specification: `${el?.requestedItem?.product?.specification
          ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
          ?.join(", ")}`,
      });
    });
    if (
      !selectedRowStock ||
      !selectedRowSold ||
      !selectedRowTransfer ||
      !selectedRowIdle
    ) {
      return <Table columns={columns} dataSource={data} pagination={false} />;
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Location",
      dataIndex: "location",
      render: (text, record) => (
        <>
          <p>{record.data?.shop?.name}</p>
          <p>{record.data?.shop?.channel?.name}</p>
        </>
      ),
    },
    {
      title: "Idle",
      dataIndex: "idle",
      render: (text, record) => (
        <div className="flex items-center gap-4">
          <Checkbox
            checked={selectedRowIdle.includes(record.key)}
            onChange={() => handleCheckboxChange("idle", record.key)}
          />
          <p>
            {record?.data?.statusCounts?.map(
              (d: any) => d.status?.toLowerCase() === "idle" && d?.count
            )}
          </p>
        </div>
      ),
    },
    {
      title: "In Stock",
      dataIndex: "instock",
      render: (text, record) => (
        <div className="flex items-center gap-4">
          <Checkbox
            checked={selectedRowStock.includes(record.key)}
            onChange={() => handleCheckboxChange("pending", record.key)}
          />
          <p>
            {record?.data?.statusCounts?.map(
              (d: any) => d.status?.toLowerCase() === "pending" && d?.count
            )}
          </p>
        </div>
      ),
    },
    {
      title: "Sold",
      dataIndex: "sold",
      render: (text, record) => (
        <div className="flex items-center gap-4">
          <Checkbox
            checked={selectedRowSold.includes(record.key)}
            onChange={() => handleCheckboxChange("sold", record.key)}
          />
          <p>
            {record?.data?.statusCounts?.map(
              (d: any) => d.status?.toLowerCase() === "sold" && d?.count
            )}
          </p>
        </div>
      ),
    },
    {
      title: "Transfer",
      dataIndex: "transfer",
      render: (text, record) => (
        <div className="flex items-center gap-4">
          <Checkbox
            checked={selectedRowTransfer.includes(record.key)}
            onChange={() => handleCheckboxChange("transfer", record.key)}
          />
          <p>
            {record?.data?.statusCounts?.map(
              (d: any) => d.status?.toLowerCase() === "transfer" && d?.count
            )}
          </p>
        </div>
      ),
    },
  ];

  const [pagination, setPagination] = React.useState({
    pageSize: 15,
    current: 1,
    total: props?.data.length,
    showSizeChanger: true,
  });

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };

  return (
    <Table
      columns={columns}
      dataSource={props?.data}
      expandable={{
        expandedRowRender,
        defaultExpandedRowKeys: ["0"],
        onExpand: handleRowExpand,
        expandedRowKeys: [expandedRowKey],
      }}
      pagination={pagination}
      onChange={handleTableChange}
      loading={inventory?.isFetching || pos?.isFetching}
    />
  );
};

export default WarehouseStockTable;
