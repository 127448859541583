import { useState } from "react";

const Vault = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleEmbedLoad = () => {
    setIsLoaded(true);
  };

  return (
    <div className="relative overflow-hidden h-full">
      <div
        className={`absolute top-0 h-12 ${
          isLoaded ? "bg-[#001529]" : "bg-white"
        } w-96 transition-all duration-500 ease-in-out`}
      ></div>
      <div
        className={`absolute top-0 right-2 h-10 ${
          isLoaded ? "bg-[#001529]" : "bg-white"
        } w-56 transition-all duration-700 ease-in-out`}
      ></div>
      <embed
        src="https://portal.cloud.trustonic.com/"
        height={700}
        className="w-full h-[95vh] overflow-hidden -mb-14"
        onLoad={handleEmbedLoad}
      />
    </div>
  );
};

export default Vault;
