import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DetailsHeaderActionBar from "../../../../components/details/supplier/DetailsPage/DetailsHeaderActionBar";
import { myLayoutActions } from "../../../../store/layout";
import { Tabs, TabsProps } from "antd";
import ChannelProfilePage from "./ChannelProfile";
import ShopProfile from "./ShopProfile";
import BusinessProoducts from "./BusinessProoduct";
import ChannelRegion from "./ChannelRegions";
import PaymentProduct from "./PaymentProduct";
import Discount from "./discount";

const ChannelDetailsPage = (props: any) => {
  const { auth, layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const goBack = () => {
    dispatch(myLayoutActions.setViewChannel(false));
  };

  const onChangeTabs = (key: string) => {
    console.log(key);
  };
  const styles = {
    boxShadow: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)",
    custom: {
      boxShadow: "0px 2px 2px 0px rgba(15, 15, 71, 0.04)",
      border: "1px solid rgba(15, 15, 71, 0.10)",
    },
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <h1 className="text-sm font-medium text-[#030229] opacity-70">
          Channel Profile
        </h1>
      ),
      children: <ChannelProfilePage />,
    },
    {
      key: "2",
      label: (
        <h1 className="text-sm font-medium text-[#030229] opacity-70">Shops</h1>
      ),
      children: <ShopProfile />,
    },
    {
      key: "3",
      label: (
        <h1 className="text-sm font-medium text-[#030229] opacity-70">
          Business Products
        </h1>
      ),
      children: <BusinessProoducts />,
    },
    {
      key: "4",
      label: (
          <h1 className="text-sm font-medium text-[#030229] opacity-70">
          Regions
        </h1>
      ),
      children: <ChannelRegion />,
    },
    {
      key: "5",
      label: (
          <h1 className="text-sm font-medium text-[#030229] opacity-70">
            Payment Methods
        </h1>
      ),
      children: <PaymentProduct />,
    },
      {
        key: "6",
      label: (
        <h1 className="text-sm font-medium text-[#030229] opacity-70">
          Discount
        </h1>
      ),
      children: <Discount />,
    },
  ];

  return (
    <div className="text-[#030229] p-1 mt-3 space-y-2">
      <DetailsHeaderActionBar
        handleClickDelete={props?.handleClickDelete}
        handleClickEdit={props?.handleClickEdit}
        goBack={goBack}
        pageName={"Channel"}
        title={layout?.channelInfo?.name}
        data={""}
      />
      <div className="bg-white p-2 rounded-md w-full h-[85vh] " style={styles}>
        <Tabs defaultActiveKey="1" items={items} onChange={onChangeTabs} />
      </div>
    </div>
  );
};
export default ChannelDetailsPage;


