import { notification } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import React, { useState, useRef, useEffect } from "react";
import {
  Stack,
  Button,
  Box,
  AccordionSummary,
  AccordionDetails,
  Accordion,
} from "@mui/material";
import { Form, Input, Typography } from "antd";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllNotesAction,
  submitUpdateNotesAction,
} from "../../../../store/account/actions";
import { useParams } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HistoryNote from "./historynoteMenu";
import TextArea from "antd/es/input/TextArea";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import { getOneAccountAction } from "../../../../store/account/actions";

const App = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const [pictures, setPictures] = useState([]);
  const { product, auth, account } = useSelector((state: any) => state);
  const onFinish = async (values: any) => {
    console.log("+++++++++++++++++++++++");
    if (auth.token) {
      await submitUpdateNotesAction(
        accountId as string,
        { notes: values },
        auth.token
      )(dispatch);
      await getOneAccountAction(accountId as string, auth.token)(dispatch);
      await getAllNotesAction(auth.token, `?account=${accountId}&limit=${props.limit}&page=${props.page}`)(dispatch);
      form.resetFields();
    }
  };

  return (
    <div className="bg-[#F4F6FA]">
      <ScrollableFrame hidePagination>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="w-38 shadow-none p-4"
          >
            <Typography>Add Note</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="p-2 ">
              <Form form={form} onFinish={onFinish} style={{ maxWidth: 800 }}>
                <div className="flex flex-col ">
                  <Form.Item
                    name="title"
                    label="Title"
                    rules={[
                      {
                        required: true,
                        message: "Title is required",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input className="w-[97.2%] ml-[1rem]" />
                  </Form.Item>

                  <Form.Item name="tag" label="tag">
                    <Input className="w-[95.5%] ml-[2rem]" />
                  </Form.Item>
                  <Form.Item
                    name="details"
                    label="Details"
                    rules={[
                      {
                        required: true,
                        message: "Details are required",
                        whitespace: true,
                      },
                    ]}
                  >
                    <TextArea maxLength={120} style={{ height: 140 }} />
                  </Form.Item>
                </div>
                <div className="float-right">
                  <Stack direction="row" spacing={3}>
                    <Form.Item>
                      <Button
                        variant="outlined"
                        onClick={() => form.resetFields()}
                        sx={{ textTransform: "none", width: "7rem" }}
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <LoadingButton
                        sx={{ textTransform: "none", width: "7rem" }}
                        type="submit"
                        variant="contained"
                        loading={account?.isFetching}
                      >
                        Save
                      </LoadingButton>
                    </Form.Item>
                  </Stack>
                </div>
              </Form>
            </div>
          </AccordionDetails>
        </Accordion>
        <br />
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="w-38 shadow-none p-4"
          >
            <Typography>History Notes</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="p-2 ">
              <HistoryNote
                limit={props.limit}
                setPage={props.setPage}
                setLimit={props.setLimit}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      </ScrollableFrame>
    </div>
  );
};
export default App;
