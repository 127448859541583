export const createExportData = (
  _id: String,
  type: String,
  brand: String,
  model: String,
  storage: any,
  colors: any,
  status: String,
  images?: [String],
  specs?: any,
  isrcAplcbYn?: any,
  itemClsCd?: any,
  itemTyCd?: any,
  pkgUnitCd?: any,
  qtyUnitCd?: any,
  taxTyCd?: any,
  useYn?: any
) => {
  return {
    _id,
    type,
    brand,
    model,
    storage,
    colors,
    status,
    images,
    specs,
    isrcAplcbYn,
    itemClsCd,
    itemTyCd,
    pkgUnitCd,
    qtyUnitCd,
    taxTyCd,
    useYn,
  };
};
export const createExportSupplierData = (
  type: String,
  name: String,
  phone: String,
  email: String,
  tin: String,
  isActive: Boolean,
  employees: any,
  data?: any
) => {
  return { type, name, phone, email, tin, isActive, employees, data };
};

export const createExportUserData = (
  names: String,
  phone: String,
  email: String,
  password: String,
  role: String,
  nid: Boolean,
  bio: any,
  picture?: any,
  data?: any
) => {
  return { bio, names, phone, email, password, role, nid, data, picture };
};

export const createExportChannelData = (
  name: String,
  address: String,
  email: String,
  isActive: Boolean,
  contact: any,
  isEnabled: Boolean,
  data?: any,
  role?: any
) => {
  return { name, address, isEnabled, email, isActive, contact, role, data };
};

export const createExportQuotationData = (
  quotationId: String,
  createdAt: String,
  listOfProducts: [],
  listOfSuppliers: [],
  status: String,
  createdBy: String
) => {
  return {
    quotationId,
    createdAt,
    listOfProducts,
    listOfSuppliers,
    status,
    createdBy,
  };
};

export const createExportPurchaseOrderData = (
  _id: string,
  supplier: String,
  poId: String,
  poStatus: String,
  deliveryDeadline: String,
  totalAmount: String,
  data?: any
) => {
  return { _id, supplier, poId, poStatus, deliveryDeadline, totalAmount, data };
};
