import * as React from "react";
import { Box, Stack } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import InfoIcon from "@mui/icons-material/Info";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import profilePic from "../../assets/images/profile.jpeg";
import { authActions } from "../../store/auth";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import { getActiveShop, handleClearAllState } from "../../utils/converter";
import profile from "../../assets/images/profile.jpeg";
import { myShopActions } from "../../store/shop";
import Notification from "../../components/notification/Notification";
// import notificationDummy from "../../assets/data/notificationDummy";
import {
  getAllNotificationAction,
  getAllUnreadNotificationAction,
  updateNotificationAction,
} from "../../store/notification/actions";
import { useNavigate } from "react-router-dom";
import { myLayoutActions } from "../../store/layout";
import { viewUserDetails } from "../../store/layout/actions";
import { mySupplierActions } from "../../store/supplier";
import { myWareHouseOutActions } from "../../store/wareHouseOut";
import { myWareHouseActions } from "../../store/wareHouse";
import { myTransferActions } from "../../store/transfer";
import { myEmployeeActions } from "../../store/setting";
import { myRepairsActions } from "../../store/repair";
import { myProductActions } from "../../store/product";
import { myPosActions } from "../../store/pos";
import { myChannelActions } from "../../store/channel";
import { myCustomerActions } from "../../store/customer";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const propsObj = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

const notification = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

const bookmarksList = [
  {
    title: "Add Employee",
    icon: <PersonAdd fontSize="small" />,
    path: "/setting/employee",
    role: ["dev", "admin"],
  },
  {
    title: "Add Supplier",
    icon: <ManageAccountsOutlinedIcon fontSize="small" />,
    path: "/purchase/suppliers",
    role: ["dev", "admin"],
  },
  {
    title: "Add Product",
    icon: <CategoryOutlinedIcon fontSize="small" />,
    path: "/inventory/pr",
    role: ["dev", "admin", "inventory"],
  },
  {
    title: "Price List",
    icon: <AttachMoneyIcon fontSize="small" />,
    path: "/pos/prices",
    role: ["dev", "admin", "sales-agent", "*"],
  },
  {
    title: "No Sale Product",
    icon: <AttachMoneyIcon fontSize="small" />,
    path: "pos/nosale",
    role: ["dev", "admin", "sales-agent", "*"],
  },
];
const knowledgeList = [
  {
    title: "Knowledge",
    icon: <InfoIcon fontSize="small" />,
    path: "/knowledge",
    role: [
      "admin",
      "finance",
      "finance-manager",
      "inventory",
      "technician",
      "sales-manager",
      "sales-supervisor",
      "sales-agent",
      "hr",
      "dev",
      "b2b-relation",
    ],
  },
  {
    title: "Knowledge - Managers",
    icon: <AccountTreeIcon fontSize="small" />,
    path: "/knowledge/manager",
    role: ["dev", "admin"],
  },
  {
    title: "Knowledge - Agents",
    icon: <AccountTreeIcon fontSize="small" />,
    path: "/knowledge/agents",
    role: ["dev", "admin"],
  },
];

export default function AccountMenu(props: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { auth, shop, notifications, layout } = useSelector(
    (state: any) => state
  );
  const shopInfo = getActiveShop(auth?.user?.shop?.assigned)[0] || shop?.myShop;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElNotification, setAnchorElNotification] =
    React.useState<null | HTMLElement>(null);
  const [anchorElSettings, setAnchorElSettings] =
    React.useState<null | HTMLElement>(null);
  const [anchorElKnowledge, setAnchorElKnowledge] =
    React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openSettings = Boolean(anchorElSettings);
  const openKnowledge = Boolean(anchorElKnowledge);
  const openNotification = Boolean(anchorElNotification);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  React.useEffect(() => {
    if (["sales-agent", "mtn-sales-agent"]?.includes(auth?.user?.role)) {
      dispatch(authActions.logout());
    }
  }, [auth?.user?.role, dispatch]);

  const handleClickSettings = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSettings(event.currentTarget);
  };

  const handleClickNotification = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotification(event.currentTarget);
  };

  const handleClickKnowledge = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElKnowledge(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElSettings(null);
    setAnchorElNotification(null);
    setAnchorElKnowledge(null);
  };

  React.useEffect(() => {
    auth.token && getAllUnreadNotificationAction(auth.token, "?")(dispatch);
  }, [auth.token, dispatch]);

  // React.useEffect(() => {
  //   getAllNotificationAction(auth.token, "?")(dispatch);
  // }, [auth.token, dispatch]);

  const updateNotification = async () => {
    await updateNotificationAction(auth.token, {})(dispatch);
  };

  const [initialItemsCount, setInitialItemsCount] = React.useState(5);
  const [additionalItemsCount, setAdditionalItemsCount] = React.useState(5);
  const [isOpen, setIsOpen] = React.useState(false);
  const [loadedItemsCount, setLoadedItemsCount] =
    React.useState(initialItemsCount);
  React.useEffect(() => {
    auth.token &&
      getAllNotificationAction(
        auth.token,
        `?limit=${loadedItemsCount}`
      )(dispatch);
  }, [auth.token, dispatch, loadedItemsCount]);
  const handleSideNav = () => {
    dispatch(myLayoutActions.setIsSideNavOpen(!layout.isSideNavOpen));
  };
  React.useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight
      ) {
        setLoadedItemsCount((prevCount) => prevCount + additionalItemsCount);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [additionalItemsCount]);

  React.useEffect(() => {
    if (pathname !== "/setting") {
      localStorage.removeItem("activeTab");
    }
  }, [pathname]);

  const handlerUserAccount = async () => {
    await viewUserDetails(true, auth?.user)(dispatch);
    navigate("/setting#users");
  };

  const clearActions = [
    mySupplierActions.setAll,
    mySupplierActions.setAttributes,
    mySupplierActions.setQuery,
    mySupplierActions.setNew,
    mySupplierActions.setUpdated,
    mySupplierActions.setSelected,
    myWareHouseOutActions.setAll,
    myWareHouseOutActions.setAttributes,
    myWareHouseOutActions.setQuery,
    myWareHouseOutActions.setNew,
    myWareHouseOutActions.setSelected,
    myWareHouseOutActions.setSelectedDeliveryNote,
    myWareHouseOutActions.setFilters,
    myWareHouseOutActions.setAllStockOut,
    myWareHouseOutActions.setSelectedRequest,
    myWareHouseOutActions.setOne,
    myWareHouseOutActions.setWarehouseItem,
    myWareHouseOutActions.setWarehouseOut,
    myWareHouseOutActions.setWarehouseOutItem,
    myWareHouseOutActions.setWarehouseOutSelected,
    myWareHouseOutActions.setWarehouseSelected,
    myWareHouseActions.setAll,
    myWareHouseActions.setAttributes,
    myWareHouseActions.setQuery,
    myWareHouseActions.setNew,
    myWareHouseActions.setSelected,
    myWareHouseActions.setSelectedDeliveryNote,
    myWareHouseActions.setFilters,
    myWareHouseActions.setAllStockStats,
    myWareHouseActions.setAllStockProductStats,
    myWareHouseActions.setSearchResult,
    myWareHouseActions.setSearchError,
    myWareHouseActions.setCreatedCart,
    myTransferActions.setAll,
    myTransferActions.setAttributes,
    myTransferActions.setQuery,
    myTransferActions.setNew,
    myTransferActions.setUpdated,
    myTransferActions.setSelected,
    myTransferActions.setShopTo,
    myTransferActions.setShopProducts,
    myTransferActions.setShopStock,
    myShopActions.setAll,
    myShopActions.setSelected,
    myShopActions.setAgents,
    myShopActions.setAllSales,
    myShopActions.setSelectedRequestedStock,
    myShopActions.setMyShop,
    myEmployeeActions.setAll,
    myEmployeeActions.setAttributes,
    myEmployeeActions.setQuery,
    myEmployeeActions.setNew,
    myEmployeeActions.setUpdated,
    myEmployeeActions.setSelected,
    myEmployeeActions.setAgentTransaction,
    myEmployeeActions.setAgent,
    myProductActions.setAll,
    myProductActions.setAttributes,
    myProductActions.setQuery,
    myProductActions.setNew,
    myProductActions.setSelected,
    myProductActions.setSelectedProduct,
    myProductActions.setProductStore,
    myPosActions.setAll,
    myPosActions.setAttributes,
    myPosActions.setQuery,
    myPosActions.setNew,
    myPosActions.setUpdated,
    myPosActions.setSelected,
    myPosActions.setStock,
    myPosActions.setStockByStatus,
    myPosActions.setTransation,
    myPosActions.setSelectedTransation,
    myPosActions.setCarts,
    myPosActions.setPriceList,
    myPosActions.setRecent,
    myPosActions.setNosale,
    myPosActions.setOnePriceListItem,
    myChannelActions.setAll,
    myChannelActions.setAttributes,
    myChannelActions.setQuery,
    myChannelActions.setNew,
    myChannelActions.setUpdated,
    myChannelActions.setSelected,
    myChannelActions.setAgents,
    myChannelActions.setShops,
    myChannelActions.setPriceList,
    myChannelActions.setPriceListItems,
    myChannelActions.setRequestedStock,
    myChannelActions.setRfsSelected,
    myChannelActions.setProducts,
    myChannelActions.setPriceListItemToUpdate,
    myChannelActions.setPriceListSearch,
    myCustomerActions.setAll,
    myCustomerActions.setAttributes,
    myCustomerActions.setQuery,
    myCustomerActions.setNew,
    myCustomerActions.setUpdated,
    myCustomerActions.setSelected,
    myCustomerActions.setSearchResult,
    myCustomerActions.setPurchased,
    myCustomerActions.setCustomerRepair,
  ];

  return (
    <React.Fragment>
      <div className={``}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            width: "fit",
          }}
        >
          <div
            className={`flex justify-between items-center gap-2  lg:float-right   xl:ml-0 ${
              layout.isSideNavOpen ? "lg:-ml-[20%]" : "lg:-ml-[10%]"
            }`}
          >
            {!["partner"]?.includes(auth?.user?.role) && (
              <Stack
                direction={"row"}
                spacing={1}
                alignItems="center"
                justifyContent={"center"}
              >
                <Tooltip title="Knowledge">
                  <IconButton
                    color="primary"
                    aria-label="Go to knowledge"
                    component="label"
                    onClick={handleClickKnowledge}
                  >
                    <HelpIcon className="text-black" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Quick Access">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    onClick={handleClickSettings}
                  >
                    <BookmarksIcon className="text-black text-sm" />
                  </IconButton>
                </Tooltip>{" "}
                <Tooltip title="Notifications">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    onClick={handleClickNotification}
                  >
                    <Badge
                      badgeContent={
                        notifications?.unReadNotification?.data?.length > 0 &&
                        notifications?.unReadNotification?.total
                      }
                      color={
                        notifications?.unReadNotification?.data?.length > 0
                          ? "error"
                          : "default"
                      }
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: "2px",
                      }}
                      onClick={updateNotification}
                    >
                      <NotificationsIcon sx={{ color: "black" }} />
                    </Badge>
                  </IconButton>
                </Tooltip>
              </Stack>
            )}

            <Tooltip title="Account settings">
              <div className="flex items-center">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    variant={navigator.onLine ? "dot" : "standard"}
                  >
                    <img
                      className="w-7 h-7 md:w-12 md:h-12 object-cover rounded-full mr-1"
                      alt="Remy Sharp"
                      src={auth?.user?.picture || profilePic}
                    />
                  </StyledBadge>
                </IconButton>

                <div className="flex flex-col justify-start items-start w-full py-1.5">
                  <span className="text-[11px] md:text-sm text-left text-slate-900 font-bold leading-[14px] capitalize">
                    {auth?.user?.names}
                  </span>

                  <span className=" text-[9px] md:text-xs text-left text-blue-800 font-normal capitalize min-w-[5rem] pt-1">
                    {auth?.user?.role === "partner"
                      ? auth?.user?.channel && auth?.user?.channel?.at(0)?.name
                      : auth?.user?.role?.split("-")?.join(" ")?.toString()}
                  </span>
                </div>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      {!["partner"]?.includes(auth?.user?.role) && (
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={propsObj}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={() => navigate("/setting#profile")}>
            <Avatar /> Profile
          </MenuItem>
          <MenuItem onClick={handlerUserAccount}>
            <Avatar /> My account
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <PersonAdd fontSize="small" />
            </ListItemIcon>
            Add another account
          </MenuItem>
          <MenuItem onClick={() => navigate("/setting#profile")}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
          <MenuItem
            onClick={() => {
              dispatch(authActions.logout());
              dispatch(myShopActions.setMyShop(null));
              handleClearAllState(dispatch, clearActions);
            }}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      )}
      <Menu
        anchorEl={anchorElSettings}
        id="account-menu"
        open={openSettings}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={propsObj}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {bookmarksList.map(
          (bookmark: any) =>
            bookmark?.role?.includes(auth?.user?.role?.toLowerCase()) && (
              <MenuItem>
                <Link to={bookmark?.path} onClick={handleClose}>
                  <ListItemIcon>{bookmark?.icon}</ListItemIcon>
                  {bookmark?.title}
                </Link>
              </MenuItem>
            )
        )}
      </Menu>

      {/* knowledge */}

      <Menu
        anchorEl={anchorElKnowledge}
        id="account-menu"
        open={openKnowledge}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={propsObj}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {knowledgeList.map(
          (data: any) =>
            data?.role?.includes(auth?.user?.role?.toLowerCase()) && (
              <MenuItem>
                <Link to={data?.path} onClick={handleClose}>
                  <ListItemIcon>{data?.icon}</ListItemIcon>
                  {data?.title}
                </Link>
              </MenuItem>
            )
        )}
      </Menu>

      {notifications?.all?.data?.length > 0 && (
        <Menu
          anchorEl={anchorElNotification}
          id="account-menu"
          open={openNotification}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={notification}
          defaultValue={3}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <h1 className="pl-4 text-xl font-bold">Notifications</h1>
          <Divider />
          <div className="h-[35rem] overflow-y-auto">
            {notifications?.all?.data?.map((notification: any, index: any) => (
              <Notification data={notification} />
            ))}
          </div>
        </Menu>
      )}
    </React.Fragment>
  );
}
