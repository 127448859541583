import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, Drawer, Skeleton, Tag } from "antd";
import { TbGridDots } from "react-icons/tb";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { searchValue } from "../../../../../utils/setColor";
import DetailsHeaderActionBar from "../../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import ScrollableFrame from "../../../../../components/layout/ScrollableFrame";
import { getAllAccountInvoiceAction } from "../../../../../store/invoice/actions";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import SubscriptiondeliveryNoteFilter from "../../../../../components/filters/SubscriptionDeliveryNoteFilter";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import RemoveRedEye from "@mui/icons-material/RemoveRedEye";
import Invoice from "../../Accounts/Invoice";
import { mySubscritionActions } from "../../../../../store/subscription/deliverynote";
import { handleClearAllState } from "../../../../../utils/converter";
import CurstomeCardSkeleton from "../../../../../components/skeleton/CurstomeCardSkeleton";

const SubscriptionBillings = (props: any) => {
  const { auth, invoice } = useSelector((state: any) => state);
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const dataMapper: any[] = [];
  const [formData, setformData] = useState(dataMapper);
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);
  const [activeInvoice, setActiveInvoice] = useState("Invoice as grid");
  const [openInvoiceView, setOpenInvoiceView] = useState(false);
  const [dataToDisplay, setDataToDisplay] = useState("");
  const clearActions = [mySubscritionActions.setInvoiceResult];

  useEffect(() => {
    auth?.token &&
      getAllAccountInvoiceAction(auth?.token, `?status=pending`)(dispatch);
  }, [auth.token, dispatch]);
  const value = invoice?.all?.data;

  interface DataType {
    key: React.Key;
    name: string;
    age: number;
    address: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "Names",
      dataIndex: "Names",
      width: 150,
    },
    {
      title: "Transaction",
      dataIndex: "transaction",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "DueDate",
      dataIndex: "dueDate",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        return (
          <>
            <Tag
              color={searchValue(status)}
              className="text-md rounded-md w-18 py-[2px] px-6"
            >
              {status}
            </Tag>
          </>
        );
      },
    },
  ];
  useEffect(() => {
    value &&
      value.forEach((el: any) => {
        dataMapper.push({
          dataIndex: el._id,
          Names: el?.account?.customerDetails?.name,
          transaction: el?.transaction,
          date: el?.date,
          dueDate: el?.dueDate,
          status: el?.status,
        });
      });

    setformData(dataMapper);
  }, [value]);

  const handleIconClick = (iconType: string) => {
    setActiveInvoice(iconType);
  };

  const onOpenViewModel = (value: any) => {
    setDataToDisplay(value);
    setOpenInvoiceView(true);
  };
  const onCancelViewModel = () => {
    setDataToDisplay("");
    setOpenInvoiceView(false);
    handleClearAllState(dispatch, clearActions);
  };

  return (
    <>
      <div className=" text-[#0F0F47] my-4">
        <DetailsHeaderActionBar
          pageName="Subscription"
          title={`${"Billings"}`}
        />
      </div>
      <div className="text-black scrollbar-hide h-full w-full bg-white pb-2 ">
        <ScrollableFrame
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
          total={invoice?.all?.total}
          count={Math.ceil(invoice?.all?.total / limit)}
        >
          <div className="flex flex-wrap px-1 justify-between">
            <div className="flex flex-row items-center">
              <SubscriptiondeliveryNoteFilter
                setSelectedStatus={setSelectedStatus}
              />
            </div>
            <div className="flex flex-row items-center gap-4">
              <DatePicker className={"w-48"} />
              <div className="text-gray-500 flex flex-row px-4">
                <div
                  style={{
                    color:
                      activeInvoice === "Invoice as grid" ? "#3b82f6" : "gray",
                    cursor: "pointer",
                  }}
                >
                  <TbGridDots
                    size={30}
                    onClick={() => handleIconClick("Invoice as grid")}
                  />
                </div>

                <AiOutlineUnorderedList
                  size={30}
                  onClick={() => handleIconClick("Invoice as table")}
                  className={`cursor-pointer ${
                    activeInvoice === "Invoice as table"
                      ? "fill-blue-500"
                      : "fill-gray-500"
                  }`}
                />
              </div>
            </div>
          </div>
          {activeInvoice === "Invoice as grid" && (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                pl: 2,
                gap: 6,
              }}
            >
              {invoice?.isFetching ? (
                <div className="flex flex-wrap">
                  {[1, 2, 3, 4, 1, 2, 3, 4]?.map((el: any) => (
                    <CurstomeCardSkeleton />
                  ))}
                </div>
              ) : (
                <>
                  {invoice?.all?.data?.map((item: any) => (
                    <div
                      key={item?._id}
                      className="bg-white rounded-lg h-48 min-w-[30%] w-auto p-[14px] shadow relative"
                    >
                      <div className="flex flex-row justify-between">
                        <div>
                          <h2 className="text-xl text-[#0F0F47] font-semibold font-poppins mb-2">
                            {item?.account?.customerDetails?.name}
                          </h2>
                          <div className="text-[#0F0F47]">
                            {item?.account?.customerDetails?.tel}
                          </div>
                          <p className="text-[#0F0F47]">{item.transaction}</p>
                        </div>

                        <Tag
                          color={searchValue(item?.status)}
                          className="rounded-md text-white  h-[26px] w-18 py-1"
                        >
                          {item?.status}
                        </Tag>
                      </div>
                      <div className="text-[#0F0F47] pb-4">
                        {/* {item.monthlyPayment} */}
                      </div>
                      <div className="flex flex-row justify-between px-2">
                        <p className="text-sm text-[#0F0F47] font-poppins mb-2">
                          {dayjs(item.createdAt).format("YYYY-MM-DD ")}
                        </p>
                        <p className="text-sm text-[#0F0F47] font-poppins mb-2">
                          {item.qid}
                        </p>
                      </div>
                      <div className="absolute bottom-2 space-x-2 left-2">
                        <Link
                          to={`/subscription/account/${item?.account?._id}`}
                        >
                          <OpenInNewIcon
                            fontSize="small"
                            style={{ color: "gray" }}
                          />
                        </Link>
                        <span
                          onClick={() => onOpenViewModel(item)}
                          className="cursor-pointer"
                        >
                          <RemoveRedEye
                            fontSize="medium"
                            className="h-2"
                            style={{ color: "gray" }}
                          />
                        </span>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </Box>
          )}

          {activeInvoice === "Invoice as table" && (
            <Table
              columns={columns}
              dataSource={formData}
              pagination={{ pageSize: 50 }}
              loading={invoice?.isFetching}
            />
          )}
        </ScrollableFrame>
      </div>

      <Drawer
        placement="right"
        width={"50%"}
        onClose={onCancelViewModel}
        open={openInvoiceView}
      >
        <Invoice
          dataToDisplay={dataToDisplay}
          openInvoiceView={openInvoiceView}
          fromBilling={true}
        />
      </Drawer>
    </>
  );
};

export default SubscriptionBillings;
