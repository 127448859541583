import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import { SERVER_URL } from "../../utils/constants";

const UploadSubLogo = (props: any) => {
  const [fileList, setFileList] = useState<UploadFile[]>(props?.default || []);

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    console.log(">>>>>>", newFileList);
    props.setSubLogo(newFileList);
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <>
      {/* <ImgCrop rotationSlider minZoom={0} showReset> */}
        <Upload
          name="images"
          action={`${SERVER_URL}/product/upload`}
          listType={props?.isCard ? "picture-card" : "picture-circle"}
          fileList={fileList}
          onPreview={onPreview}
          onChange={handleChange}
        >
          {fileList.length >= props.limit ? null : uploadButton}
        </Upload>
      {/* </ImgCrop> */}
    </>
  );
};

export default UploadSubLogo;
