import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "dashboard",
  initialState: {
    isFetching: false,
    productStatsIsFetching: false,
    shopStatsIsFetching: false,
    agentStatsIsFetching: false,
    qtySoldStatsIsFetching: false,
    all: { data: [] },
    salesStats: {},
    revenueAndQtyStats: null,
    revenueStats: {},
    selected: null,
    hourRevenueStats: null,
    paymentMethodStats: null,
    revenueByShopStats: null,
    revenueByAgentStats: null,
    regionStats: null,
    quantitySoldByBrandStats: null,
    quantitySoldByModelStats: null,
    productsByBrand: null,
    dataActivetion: null,
    macyeMacyePaymentStats: null,
    corporatePaymentStats: null,
    samphonePaymentStats: null,
    mtnPaymentStats: null,
    productStasts: null,
    pieceSoldStats: null,
    quantitySoldStats: null,
    shopStats: null,
    agentStats: null,
    noSaleStats: null,
    kpiStats: null,
    yelloPaymentStats: null,
    vubavubaPaymentStats: null,
    intelligraInsurancePaymentStats: null,
    ikosoraStats: null,
    currentStock: null,
    nosaleSpecificBrandStats: null,
    brandedBrandStat: null,
    brandedBrandStockIsFetching: false,
    brandedBrandStatistics: null,
    commissionStatistics: null,
    franchiseeCommission: null,
    ikosoraStockStats: null,
    isFetchingIkosoraStock: false,
    isFranchaseeFetching: false,
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setCurrentStock(state, action) {
      state.currentStock = action.payload;
    },
    setSalesStats(state, action) {
      state.salesStats = action.payload;
    },
    setRevenueAndQtyStats(state, action) {
      state.revenueAndQtyStats = action.payload;
    },
    setAllRevenueStats(state, action) {
      state.revenueStats = action.payload;
    },
    setDaySalesStats(state, action) {
      state.hourRevenueStats = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setPaymentMethodStats(state, action) {
      state.paymentMethodStats = action.payload;
    },
    setRevenueStats(state, action) {
      state.revenueByShopStats = action.payload;
    },
    setRevenueAgentStats(state, action) {
      state.revenueByAgentStats = action.payload;
    },
    setRegionStats(state, action) {
      state.regionStats = action.payload;
    },
    setQntyByBrandStats(state, action) {
      state.quantitySoldByBrandStats = action.payload;
    },
    setQntyByModelStats(state, action) {
      state.quantitySoldByModelStats = action.payload;
    },
    setProductsByBrand(state, action) {
      state.productsByBrand = action.payload;
    },
    setDataActivetion(state, action) {
      state.dataActivetion = action.payload;
    },
    setCorporatePaymentStats(state, action) {
      state.corporatePaymentStats = action.payload;
    },
    setMacyeMacyePaymentStats(state, action) {
      state.macyeMacyePaymentStats = action.payload;
    },
    setYelloPaymentStats(state, action) {
      state.yelloPaymentStats = action.payload;
    },
    setVubaVubaStats(state, action) {
      state.vubavubaPaymentStats = action.payload;
    },
    setIntelligraInsuranceStats(state, action) {
      state.intelligraInsurancePaymentStats = action.payload;
    },
    setSamphonePaymentStats(state, action) {
      state.samphonePaymentStats = action.payload;
    },
    setMTNPaymentStats(state, action) {
      state.mtnPaymentStats = action.payload;
    },
    setProductStats(state, action) {
      state.productStasts = action.payload;
    },
    setPieceSoldStats(state, action) {
      state.pieceSoldStats = action.payload;
    },
    setQuantitySoldStats(state, action) {
      state.quantitySoldStats = action.payload;
    },
    setShopStats(state, action) {
      state.shopStats = action.payload;
    },
    setAgentStats(state, action) {
      state.agentStats = action.payload;
    },
    setProductStatsIsFetching(state, action) {
      state.productStatsIsFetching = action.payload;
    },
    setShopStatsIsFetching(state, action) {
      state.shopStatsIsFetching = action.payload;
    },
    setAgentStatsIsFetching(state, action) {
      state.agentStatsIsFetching = action.payload;
    },
    setQtySoldStatsIsFetching(state, action) {
      state.qtySoldStatsIsFetching = action.payload;
    },
    setNosaleStats(state, action) {
      state.noSaleStats = action.payload;
    },
    setKpiStats(state, action) {
      state.kpiStats = action.payload;
    },
    setIkosoraStats(state, action) {
      state.ikosoraStats = action.payload;
    },
    setNosaleSpecificBrandStats(state, action) {
      state.nosaleSpecificBrandStats = action.payload;
    },
    setBrandedStockStats(state, action) {
      state.brandedBrandStat = action.payload;
    },
    setBrandedStockIsFetchingStats(state, action) {
      state.brandedBrandStockIsFetching = action.payload;
    },
    setBrandedStockStatistics(state, action) {
      state.brandedBrandStatistics = action.payload;
    },
    setCommissionStatistics(state, action) {
      state.commissionStatistics = action.payload;
    },
    setFranchiseeCommission(state, action) {
      state.franchiseeCommission = action.payload;
    },
    setIkosoraStockStats(state, action) {
      state.ikosoraStockStats = action.payload;
    },
    setIsFetchingIkosoraStock(state, action) {
      state.isFetchingIkosoraStock = action.payload;
    },
    setIsFranchaseeFetching(state, action) {
      state.isFranchaseeFetching = action.payload;
    },
  },
});

export const myDashboardActions = mySlice.actions;

export default mySlice.reducer;
