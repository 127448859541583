import { useMediaQuery } from "@mui/material";
import { DatePicker, Select } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllChannelAction } from "../../../store/channel/actions";
import dayjs from "dayjs";
import type { TimeRangePickerProps } from "antd";
import type { Dayjs } from "dayjs";

const { RangePicker } = DatePicker;

const { Option } = Select;

const ChannelFilter = (props: any) => {
  const { dashboard, channel, auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const smallScreen = useMediaQuery("(max-width:400px)");

  useEffect(() => {
    auth?.token && getAllChannelAction(auth?.token)(dispatch);
  }, [auth?.token, dispatch]);

  return (
    <>
      <Select
        showSearch
        style={{ width: 200 }}
        allowClear
        placeholder="channels"
        className={`mb-5 sm:mb-0  ${smallScreen ? "w-full h-9" : "w-36"}`}
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label ?? "").toString().includes(input)
        }
        loading={dashboard.isFetching}
        disabled={dashboard.isFetching}
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")

            .toString()
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toString().toLowerCase())
        }
        onChange={(value: any) => {
          !value && props?.setSelectedChannel("");
          value && props?.setSelectedChannel(value);
        }}
      >
        {channel?.all?.data?.map((d: any, index: any) => (
          <Option Key={index} value={d?._id} className="capitalize">
            {d?.name}
          </Option>
        ))}
      </Select>
    </>
  );
};

interface dataType {
  hasIkosora: boolean;
  setSelectedOverView: any;
  setEnteredDate: any;
  setCompareDate: any;
  selectedOverView: any;
  enteredDate: any;
  compareDate: any;
  onChangeDate: any;
  onChangePreviousDate: any;
  setSelectedChannel?: any;
  from?: string;
}

const DateSelection: React.FC<dataType> = ({
  setSelectedChannel,
  hasIkosora,
  setSelectedOverView,
  setEnteredDate,
  setCompareDate,
  selectedOverView,
  enteredDate,
  compareDate,
  onChangeDate,
  onChangePreviousDate,
  from,
}) => {
  const formattedDate = new Date().toISOString().slice(0, 10);
  let today = moment();
  let previousDate = today.subtract(1, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");
  const { auth } = useSelector((state: any) => state);

  const disabledDate = (current: any) => {
    if (current && current > moment().endOf("day")) {
      return true;
    }
    return false;
  };
  const smallScreen = useMediaQuery("(max-width:400px)");

  const dateFormat = "YYYY/MM/DD";
  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      setEnteredDate(dateStrings[0]);
      setCompareDate(dateStrings[1]);
    }
  };

  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
    { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
    { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
    { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
  ];

  return (
    <div
      className={` sm:space-x-2 sm:flex items-center ${
        !["franchisee"]?.includes(auth?.user?.role)
          ? "justify-between"
          : "justify-end"
      } pb-4`}
    >
      {["partner"]?.includes(auth?.user?.role) ? (
        <h1 className="font-medium"> RWANDA's SALES KPI DASHBOARD</h1>
      ) : (
        <>
          {!["franchisee"]?.includes(auth?.user?.role) &&
            from !== "channel" && (
              <ChannelFilter setSelectedChannel={setSelectedChannel} />
            )}
        </>
      )}
      <div className={`${smallScreen ? "block" : "flex space-x-6 "}`}>
        <div className="flex items-center space-x-4">
          {!hasIkosora && (
            <Select
              showSearch
              allowClear
              defaultValue={"Day"}
              className={`mb-5 sm:mb-0  ${smallScreen ? "w-full h-9" : "w-36"}`}
              optionFilterProp="children"
              onChange={(value) => {
                if (value === "day") {
                  setSelectedOverView(value);
                  setEnteredDate(formattedDate);
                  setCompareDate(formattedPreviousDate);
                }
                if (value === "range") {
                  setSelectedOverView(value);
                  setEnteredDate(formattedPreviousDate);
                  setCompareDate(formattedDate);
                }
              }}
            >
              {[
                { label: "Day", values: "day" },
                { label: "Date Range", values: "range" },
              ].map((d: any, index: any) => (
                <Option Key={index} value={d?.values} className="capitalize">
                  {d?.label}
                </Option>
              ))}
            </Select>
          )}
          {selectedOverView === "day" ? (
            <div className="flex items-center space-x-2">
              <DatePicker
                onChange={onChangeDate}
                defaultValue={dayjs(enteredDate, dateFormat)}
                className={`${smallScreen ? "w-full h-9" : "w-[140px]"}`}
                // style={{ width: 120 }}
                disabledDate={disabledDate}
              />
              <div
                className={`${
                  smallScreen ? "block" : "flex items-center space-x-2"
                }`}
              >
                <span
                  className={`${
                    smallScreen ? "block py-4 text-center text-sm" : ""
                  } text-sm sm:text-base`}
                >
                  {" "}
                  Compared To
                </span>
                <DatePicker
                  onChange={onChangePreviousDate}
                  defaultValue={dayjs(compareDate, dateFormat)}
                  className={`${smallScreen ? "w-full h-9" : "w-[140px]"}`}
                  // style={{ width: 120 }}
                  disabledDate={disabledDate}
                />
              </div>
            </div>
          ) : (
            <div className={`${smallScreen ? "block" : "flex space-x-6 "}`}>
              <RangePicker
                presets={rangePresets}
                onChange={onRangeChange}
                defaultValue={[
                  dayjs(enteredDate, dateFormat),
                  dayjs(compareDate, dateFormat),
                ]}
                disabledDate={disabledDate}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DateSelection;
