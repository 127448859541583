import HttpRequest from "../../../../utils/HttpRequest";
import { SERVER_URL } from "../../../../utils/constants";

export const getEbmPurchasedServices = async (token: string, query: any) => {
    return HttpRequest.get(`${SERVER_URL}/ebm/purchased${query}`, token);
  };
  export const addEbmPurchaseService = async (data: any, token: string) => {
    return HttpRequest.post(`${SERVER_URL}/ebm/addPurchaseToEBM`,data, token);
  };
  export const getPurchaseStatService = async (token: string, query: any) => {
    return HttpRequest.get(`${SERVER_URL}/ebm/purchaseStats${query}`, token);
  };