import { Navigate, Outlet, useNavigate } from "react-router-dom";
import DashLayout from "../components/layout/Layout";

import TopNav from "../components/navitems/TopNavItems";
import { listManages } from "../assets/data/pages";
import { getAllSuppliersAction } from "../store/supplier/actions";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "store";

import SelectShopPosModal from "../components/Modals/SelectShopPosModal";

const POSRoutes = () => {
  const { auth, supplier } = useSelector((state: any) => state);

  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const handleClose = () => navigateTo("/dashboard");
  useEffect(() => {
    auth?.token &&
      getAllSuppliersAction(auth?.token, supplier?.query || "?")(dispatch);
  }, [auth?.token, dispatch, supplier.new, supplier?.query]);
  const pagesArray = [
    {
      caption: "Overview",
      path: "/pos",
      onClick: () => {
        console.log("Overview:00");
      },
    },
    {
      caption: "Sale",
      path: "/pos/sale",
      onClick: () => {
        console.log("sale:00");
      },
    },
    // {
    //   caption: "Transactions",
    //   path: "/pos/transactions",
    //   onClick: () => {
    //     console.log("transaction:00");
    //   },
    // },
    {
      caption: "Stock",
      path: "/pos/stock",
      onClick: () => {
        console.log("stock:00");
      },
    },
    {
      caption: " Stock Request",
      path: "/pos/request",
      onClick: () => {
        console.log("request:00");
      },
    },
  ];

  if (
    ["admin", "finance", "finance-manager", "dev"].includes(
      auth?.user?.role?.toLowerCase()
    )
  ) {
    const transactioPage = {
      caption: "Transactions",
      path: "/pos/transactions",
      onClick: () => {
        console.log("Overview:00");
      },
    };

    pagesArray.splice(2, 0, transactioPage);
  }
  const token = store.get("authToken");

  return auth.token || token ? (
    <DashLayout
      allowSearch={true}
      nav={
        <TopNav
          nav={listManages?.find(
            (item) => item.title.toLowerCase() === "POS".toLowerCase()
          )}
          pages={pagesArray}
        />
      }
      selectedNav={3}
    >
      {auth?.user?.shop ? (
        <Outlet />
      ) : (
        <SelectShopPosModal handleClose={handleClose} showModal={true} />
      )}
    </DashLayout>
  ) : (
    <Navigate to="/dashboard" />
  );
};

export default POSRoutes;
