import React, { useState } from "react";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import ManageCommissionTable from "../../../../components/tables/ManageCommissionTable";
import { Select } from "antd";
import ManageCommissionDetailsTable from "../../../../components/tables/ManageCommissionDetailTable";

const Commission = () => {
  const [viewMore, setViewMore] = useState(false);
  const [commissionData, setCommissionData] = useState<any>("");
  const onChange = (value: string) => {};
  const handleViewMore = (data: any) => {
    setViewMore(true);
    setCommissionData(data);
  };
  const cancelViewMore = () => {
    setViewMore(false);
  };
  const onSearch = (value: string) => {
    console.log("search:", value);
  };
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  return (
    <div>
      {viewMore ? (
        <div className="text-black pt-4">
          <DetailsHeaderActionBar
            pageName="Finace"
            title={`Commission / ${commissionData?.owner}`}
            goBack={cancelViewMore}
          />
          <div className="pt-5">
            <ManageCommissionDetailsTable data={commissionData} />
          </div>
        </div>
      ) : (
        <div className="mt-4 text-black">
          <DetailsHeaderActionBar pageName="Finace" title="Commission" />
          <Select
            showSearch
            placeholder="Select"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={filterOption}
            options={[
              {
                value: "shop",
                label: "Shop"
              },
              {
                value: "franshise",
                label: "Franchise"
              }
            ]}
            className="w-32 my-3"
          />
          <div>
            <ManageCommissionTable onClick={handleViewMore} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Commission;
