import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DetailsTabs from "../../../../../src/components/cards/DetailsPage/DetailsTabs";
import DeviceTable from "./deviceTable";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import BillingTable from "./billingTable";
import VaultsTable from "./vaultsTable";
import NotesPage from "./notes";
import AccountFiles from "./files";
import {
  getAllNotesAction,
  getOneAccountAction,
} from "../../../../store/account/actions";
import { useDispatch, useSelector } from "react-redux";
import { getOneSubsOrderAction } from "../../../../store/subscription/order/actions";
import { getAllAccountInvoiceAction } from "../../../../store/invoice/actions";
import AccountTopButton from "./accountTopButton/AccountTopButton";
import CurrentPlanSummary from "./CurrentPlanSummary";
import {
  getAllSubdeliverynotesItemAction,
  getOnesubDeliveryNoteAction,
} from "../../../../store/subscription/deliverynote/actions";
import ComponentToPrint from "../picklist/ComponentToPrint ";

type TAB = {
  title: any;
  component: any;
};

const AccountInformation = (_props: any) => {
  const { auth, account, orders, invoice, deliverynote, layout } = useSelector(
    (state: any) => state
  );
  const value = orders?.selected?.account?.deliveryNote?.list;
  const [activeTab, setActiveTab] = useState(0);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  const { accountId } = useParams();

  useEffect(() => {
    setActiveTab(layout?.activeTab);
  }, [layout?.activeTab]);

  useEffect(() => {
    auth?.token &&
      getAllNotesAction(
        auth?.token,
        `?account=${accountId}&limit=${limit}&page=${page - 1}`
      )(dispatch);
  }, [auth?.token, limit, page, dispatch]);

  useEffect(() => {
    if (account?.selected?.deliveryNote && auth?.token) {
      getAllSubdeliverynotesItemAction(
        auth?.token,
        `?deliveryNote=${account?.selected?.deliveryNote?._id}&limit=1&page=0`
      )(dispatch);
    }
  }, [auth?.token, account?.selected?.deliveryNote, dispatch]);

  useEffect(() => {
    if (account?.selected?.deliveryNote?._id && auth?.token) {
      getOnesubDeliveryNoteAction(
        auth?.token,
        account?.selected?.deliveryNote?._id
      )(dispatch);
    }
  }, [
    account?.selected?.deliveryNote,
    account?.selected?.order,
    auth?.token,
    dispatch,
  ]);

  const tabs: TAB[] = [
    {
      title: `Billings (${invoice?.all?.total ?? "0"})`,
      component: (
        <BillingTable limit={limit} setLimit={setLimit} setPage={setPage} />
      ),
    },

    {
      title: `Purchase Order`,
      component: <ComponentToPrint hasAccount={true} />,
    },

    {
      title: `Devices (${deliverynote?.subDeliveryNoteItem?.total ?? "0"})`,
      component: <DeviceTable />,
    },
    {
      title: `Vaults (${value?.length ?? "0"})`,
      component: <VaultsTable />,
    },
    {
      title: `Files (${account?.selected?.docX?.length ?? "0"})`,
      component: <AccountFiles />,
    },
    {
      title: `Notes  (${account?.note?.total})`,
      component: (
        <NotesPage limit={limit} page={page} setPage={setPage} setLimit={setLimit} />
      ),
    },
    // {
    //   title: `Request  (${account?.selected?.requests?.length ?? "0"})`,
    //   component: <SingleSubscriptionRequestTable />,
    // },
  ];

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (auth.token && accountId) {
      getOneAccountAction(accountId as string, auth.token)(dispatch);
    }
  }, [accountId, auth.token, dispatch]);

  useEffect(() => {
    auth?.token &&
      account?.selected?.order &&
      getOneSubsOrderAction(auth?.token, account?.selected?.order)(dispatch);
    auth?.token &&
      getAllAccountInvoiceAction(
        auth?.token,
        `?account=${accountId}&limit=${limit}&page=${page - 1}`
      )(dispatch);
  }, [account?.selected?.order, auth.token, dispatch, limit, page]);

  return (
    <>
      <div className="my-3 text-black">
        <div className="flex items-center justify-between">
          <DetailsHeaderActionBar
            pageName="Subscription"
            title={`${"Account"} - ${account?.selected?.customerDetails?.name}`}
            isLoading={account?.isFetching}
          />
          <AccountTopButton />
        </div>
      </div>

      <div className=" xbg-[#F4F6FA] flex flex-col w-full gap-4 h-full scrollbar-hide space-x-2">
        <CurrentPlanSummary />
        <DetailsTabs
          tabs={tabs}
          activeTab={activeTab}
          handleChange={handleChange}
        />
      </div>
    </>
  );
};
export default AccountInformation;
