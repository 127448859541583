import React from "react";
import OverViewCard from "./OverViewCard";
import { HiOutlineUsers } from "react-icons/hi";
import { FaLongArrowAltDown } from "react-icons/fa";
import CustomButton from "../../../../components/buttons/CustomButton";

export const ReceivedApplication = () => {
  return (
    <OverViewCard
      title={"Total Applications Received"}
      amount={5000}
      icon={<HiOutlineUsers color="605BFF" size={24} />}
      comparedAmount={4500}
      parcent={<p className="text-[#F1595C]">2.47%</p>}
      textColor="[#57C38A]"
      icon2={<FaLongArrowAltDown color="#F1595C" size={15} />}
      compareText={"Decreased"}
      description={"Total number of loan application received"}
      btn={
        <CustomButton
          btnName={<p className="text-[10px] font-normal"> View Reports</p>}
          textColor={"[#605BFF]"}
          borderColor={"[#605BFF40]"}
        />
      }
    />
  );
};
export const DraftedApplication = () => {
  return (
    <OverViewCard
      title={"Total Applications Drafted"}
      amount={5000}
      icon={<HiOutlineUsers color="605BFF" size={24} />}
      comparedAmount={4500}
      parcent={<p className="text-[#F1595C]">2.47%</p>}
      textColor="[#57C38A]"
      icon2={<FaLongArrowAltDown color="#F1595C" size={15} />}
      compareText={"Decreased"}
      description={
        "Applications where customers have selected a device in the USSD loan application flow."
      }
      btn={
        <CustomButton
          btnName={<p className="text-[10px] font-normal"> View Reports</p>}
          textColor={"[#605BFF]"}
          borderColor={"[#605BFF40]"}
        />
      }
    />
  );
};
export const PendingApplication = () =>{
    return (
        <OverViewCard
        title={"Total Applications Pending"}
        amount={5500}
        icon={<HiOutlineUsers color="605BFF" size={24} />}
        comparedAmount={4500}
        parcent={<p className="text-[#F1595C]">2.47%</p>}
        textColor="[#57C38A]"
        icon2={<FaLongArrowAltDown color="#F1595C" size={15} />}
        compareText={"Decreased"}
        description={"Applications where customers have confirmed their selection by paying."}
        btn={
          <CustomButton
            btnName={<p className="text-[10px] font-normal"> View Reports</p>}
            textColor={"[#605BFF]"}
            borderColor={"[#605BFF40]"}
          />
        }
      />
    )
}
export const ApprovedApplication =()=>{
    return(
        <OverViewCard
        title={"Total Applications Approved"}
        amount={5000}
        icon={<HiOutlineUsers color="605BFF" size={24} />}
        comparedAmount={4500}
        parcent={<p className="text-[#F1595C]">2.47%</p>}
        textColor="[#57C38A]"
        icon2={<FaLongArrowAltDown color="#F1595C" size={15} />}
        compareText={"Decreased"}
        description={"Applications where payments have been approved by EWP, and customers are ready for device pickup."}
        btn={
          <CustomButton
            btnName={<p className="text-[10px] font-normal"> View Reports</p>}
            textColor={"[#605BFF]"}
            borderColor={"[#605BFF40]"}
          />
        }
      />
    )
}
export const InProgressApplication =()=>{
    return(
        <OverViewCard
        title={"Total Applications In Progress"}
        amount={5000}
        icon={<HiOutlineUsers color="605BFF" size={24} />}
        comparedAmount={4500}
        parcent={<p className="text-[#F1595C]">2.47%</p>}
        textColor="[#57C38A]"
        icon2={<FaLongArrowAltDown color="#F1595C" size={15} />}
        compareText={"Decreased"}
        description={"Applications that MASCOM agents are currently processing."}
        btn={
          <CustomButton
            btnName={<p className="text-[10px] font-normal"> View Reports</p>}
            textColor={"[#605BFF]"}
            borderColor={"[#605BFF40]"}
          />
        }
      />   
    )
}
export const CompletedApplication =()=>{
    return(
        <OverViewCard
        title={"Total Applications Completed"}
        amount={500}
        icon={<HiOutlineUsers color="605BFF" size={24} />}
        comparedAmount={400}
        parcent={<p className="text-[#F1595C]">2.47%</p>}
        textColor="[#57C38A]"
        icon2={<FaLongArrowAltDown color="#F1595C" size={15} />}
        compareText={"Decreased"}
        description={"Applications that have been completed, with customers having active loan accounts."}
        btn={
          <CustomButton
            btnName={<p className="text-[10px] font-normal"> View Reports</p>}
            textColor={"[#605BFF]"}
            borderColor={"[#605BFF40]"}
          />
        }
      />  
    )
}
export const CancelledApplication =()=>{
    return(
        <OverViewCard
        title={"Total Applications Cancelled"}
        amount={500}
        icon={<HiOutlineUsers color="605BFF" size={24} />}
        comparedAmount={400}
        parcent={<p className="text-[#F1595C]">2.47%</p>}
        textColor="[#57C38A]"
        icon2={<FaLongArrowAltDown color="#F1595C" size={15} />}
        compareText={"Decreased"}
        description={"Applications canceled for specific reasons."}
        btn={
          <CustomButton
            btnName={<p className="text-[10px] font-normal"> View Reports</p>}
            textColor={"[#605BFF]"}
            borderColor={"[#605BFF40]"}
          />

        }
      />    
    )
}
export const FailedApplication =()=>{
    return(
        <OverViewCard
        title={"Total Applications Failed By Payments"}
        amount={500}
        icon={<HiOutlineUsers color="605BFF" size={24} />}
        comparedAmount={400}
        parcent={<p className="text-[#F1595C]">2.47%</p>}
        textColor="[#57C38A]"
        icon2={<FaLongArrowAltDown color="#F1595C" size={15} />}
        compareText={"Decreased"}
        description={"Applications that failed due to payment issues."}
        btn={
          <CustomButton
            btnName={<p className="text-[10px] font-normal"> View Reports</p>}
            textColor={"[#605BFF]"}
            borderColor={"[#605BFF40]"}
          />

        }
      />    
    )
}
export const CheckRequest =()=>{
    return(
        <OverViewCard
        title={"Total Eligibility Checks Requests"}
        amount={100}
        icon={<HiOutlineUsers color="605BFF" size={24} />}
        comparedAmount={400}
        parcent={<p className="text-[#F1595C]">2.47%</p>}
        textColor="[#57C38A]"
        icon2={<FaLongArrowAltDown color="#F1595C" size={15} />}
        compareText={"Decreased"}
        description={"Number of requests made to check customer eligibility for financing."}
        btn={
          <CustomButton
            btnName={<p className="text-[10px] font-normal"> View Reports</p>}
            textColor={"[#605BFF]"}
            borderColor={"[#605BFF40]"}
          />
        }
      />    
    )
}
