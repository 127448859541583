import React from "react";
import { Button, Form, Space } from "antd";
import TextArea from "antd/es/input/TextArea";

const CancelSubRequest = ({ onCancel, onReasonSubmit, data }: any) => {
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        onReasonSubmit(values.reason);
        form.resetFields();
    };
    return (
        <Form layout="vertical" form={form} onFinish={onFinish}>
            <Form.Item
                label="Reason"
                name="reason"
                rules={[{ required: true, message: "Please enter a reason why rejected !" }]}
            >
                <TextArea rows={4} />
            </Form.Item>
            <Form.Item>
                <Space>
                    <Button type="default" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    );
};
export default CancelSubRequest;
