import React from "react";
import imgs from "../../assets/Ellipse2323.png";
import { useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { RiEdit2Fill } from "react-icons/ri";
const CustomerCard = (props: any) => {
  const { layout } = useSelector((state: any) => state);
  const styles = {
    customShadow: {
      boxShadow: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)",
    },
    border: {
      border: "0px 1px 0px 0px solid rgba(3, 2, 41, 0.04)",
    },
  };
  return (
    <div
      className={`bordedr borderd-[#0302290A] bg-white p-5  ${
        layout?.isSideNavOpen ? "lg:w-[480px]" : "lg:w-[550px]"
      } w-full rounded-md px-7`}
      style={styles.customShadow}
    >
      <div className="md:flex justify-between">
        <h1 className="text-[#030229] text-base font-medium">
          Customer details
        </h1>
        {/* <button className="text-[#605BFF] text-base">See more</button> */}
      </div>
      <div className="sm:flex gap-5 mb-5 mt-5">
        <div className="w-[52px] h-[52px] ">
          <img src={props?.data?.picture} alt="" className="w-full h-full" />
        </div>
        <div>
          <h1 className="text-[#030229] text-base font-medium">
            {props?.data?.name}
          </h1>
          <button className="text-white bg-[#0FA958] text-base px-5 mt-2 py-1 rounded-md">
            {props?.data?.status}
          </button>
        </div>
      </div>
      <div className="flex w-full gap-10 mt-3">
        <div>
          <p className="text-[#03022980]  text-sm">Customer Id</p>
          <p className="text-[#03022980]  text-sm pt-2">Email</p>
          <p className="text-[#03022980]  text-sm pt-2">Phone Number</p>
          <p className="text-[#03022980]  text-sm pt-2">Starting Date</p>
          <p className="text-[#03022980]  text-sm pt-2">Customer Type</p>
        </div>
        <div>
          <p className="text-[#605BFF] text-sm">
            {props?.data?.customerId ?? "N/A"}
          </p>
          <p className="text-[#030229] text-sm pt-2">{props?.data?.email}</p>
          <p className="text-[#030229] text-sm pt-2">{props?.data?.phone}</p>
          <p className="text-[#030229] text-sm pt-2">
            {props?.data?.createdAt?.substring(0, 10)}
          </p>
          <p className="text-[#030229] text-sm pt-2">
            {props?.data?.subscriptionInfo?.customerType}
          </p>
        </div>
      </div>
      <div className="float-right flex gap-5">
        <RiEdit2Fill
          color="#57C38A"
          onClick={props?.onEdit}
          className="cursor-pointer"
        />
        {/* <MdDelete color="red" onClick={props?.onClick} className="cursor-pointer"/> */}
      </div>
    </div>
  );
};

export default CustomerCard;
