import React, { useEffect } from "react";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomFormAction,
  getOneBusinessAction,
  getOneCustomFormAction,
} from "../../../../store/setting/company/actions";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import QuickAction from "./components/QuickAction";
import CustomForm from "./components/CustomerCustomForm";
import CurstomeCardSkeleton from "../../../../components/skeleton/CurstomeCardSkeleton";
import CustomSkeleton from "../../../../components/skeleton/CustomSkeleton";
import CustomFormPreview from "./components/CustomFormPreview";
import { LoadingButton } from "@mui/lab";
import { myCompanyActions } from "../../../../store/setting/company";

const BusinessProductDetails = () => {
  const { auth, company, layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { businessProductId, formId } = useParams();
  const navigate = useNavigate();
  const bProductName = company?.selectedBusinessProduct?.name;

  const goBack = () => {
    navigate("/setting#company");
  };
  useEffect(() => {
    if (auth?.token && businessProductId) {
      getOneBusinessAction(businessProductId as string, auth?.token)(dispatch);
    }
  }, [auth?.token, businessProductId, dispatch]);

  useEffect(() => {
    if (auth?.token && businessProductId)
      getCustomFormAction(
        `?businessProduct=${businessProductId}`,
        auth?.token
      )(dispatch);
  }, [auth?.token, dispatch, businessProductId]);

  useEffect(() => {
    if (auth?.token && formId)
      getOneCustomFormAction(formId as string, auth?.token)(dispatch);
  }, [auth?.token, dispatch, formId]);

  useEffect(() => {
    dispatch(myCompanyActions.setSelectedCustomForm(null));
  }, [dispatch]);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Quick Actions",
      children: (
        <>
          {company?.isGettingSelectedOne ? (
            <div className="grid grid-cols-3 gap-3">
              {[1, 2, 3]?.map((el: any) => (
                <CurstomeCardSkeleton />
              ))}
            </div>
          ) : (
            <div className="grid grid-cols-3 gap-y-8 mt-4">
              <QuickAction
                name={"Allowed in POS"}
                description={`Enable ${company?.selectedBusinessProduct?.name} to be used in the POS`}
                label={"isPosEnabled"}
                isChecked={company?.selectedBusinessProduct?.isPosEnabled}
              />
              <QuickAction
                name={"Request finance approval"}
                description={`Confirm if ${company?.selectedBusinessProduct?.name} require finance decission`}
                label={"finance_approval"}
                isChecked={
                  company?.selectedBusinessProduct?.requestFinanceReview
                }
              />
            </div>
          )}
        </>
      ),
    },
    {
      key: "2",
      label: "Customer Profile Form",
      // children: (
      //   <>
      //     {company?.customForm?.length > 0 && formId === undefined ? (
      //       <div className="max-w-5xl mx-auto relative">
      //         <CustomFormPreview data={company?.customForm?.at(0)?.fields} />
      //         <LoadingButton
      //           variant="contained"
      //           sx={{ width: 150 }}
      //           onClick={() =>
      //             navigate(
      //               `/setting/bunsiness-product/${businessProductId}/${
      //                 company?.customForm?.at(0)?._id
      //               }`
      //             )
      //           }
      //         >
      //           Update
      //         </LoadingButton>
      //       </div>
      //     ) : (
      //       <CustomForm />
      //     )}
      //   </>
      // ),
    },
  ];

  return (
    <div
      className={`ml-[16rem] ${
        layout?.isSettingSidebarHovered ? "xl:ml-[20rem]" : "xl:ml-[6rem]"
      } py-8 px-2 transition-all ease-in-out duration-700`}
    >
      <div className="bg-white h-[88vh] overflow-y-auto shadow py-4 rounded-md text-gray-800 px-8">
        <DetailsHeaderActionBar
          pageName={
            company?.isGettingSelectedOne ? <CustomSkeleton /> : bProductName
          }
          title=" "
          goBack={goBack}
        />
        <Tabs defaultActiveKey="1" items={items} />
      </div>
    </div>
  );
};

export default BusinessProductDetails;
