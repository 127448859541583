import React, { useEffect } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import type { TableColumnsType } from "antd";
import { getAllWarehouseOutItemsAction } from "../../../store/wareHouseOut/actions";
import LoadingButton from "@mui/lab/LoadingButton";
import { updateReceivedProductAction } from "../../../store/pos/actions";
import { Button } from "@mui/material";
import { getDefaultCurrencyCode } from "../../../utils/helper";
interface ExpandedDataType {
  _id: any;
  key: any;
  no: number;
  shop: any;
  address: any;
  model: any;
  specification: any;
  snumber: any;
  imei1: any;
  imei2: any;
  price: any;
  extendedWarranty: any;
  color: any;
  isSelected: any;
  data: any;
}

const PosTableView = (props: any) => {
  const { auth, wareHouseOut, pos, company } = useSelector(
    (state: any) => state
  );
  const [expandedRowKey, setExpandedRowKey] = React.useState<any>(null);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [prodId, setProdId] = useState();
  const dispatch = useDispatch();
  const deliverer = wareHouseOut?.warehouseOut?.data[0]?.deliverer;

  useEffect(() => {
    if (auth?.token && prodId) {
      getAllWarehouseOutItemsAction(
        auth?.token,
        `requestedItem=${prodId}`
      )(dispatch);
    }
  }, [auth?.token, prodId, dispatch]);

  const expandedRowRender = () => {
    const onSelectChange = (selectedRowKeys: any, selectedRows: any) => {
      setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
      onChange: onSelectChange,
      selectedRowKeys,
    };
    const columns: TableColumnsType<ExpandedDataType> = [
      {
        title: "No",
        dataIndex: "no",
        key: "no",
      },
      {
        title: "Shop",
        dataIndex: "shop",
        key: "shop",
        render: (text: string, record: any) => (
          <>
            <p>{text}</p>
            <p>{record?.data?.shop?.channel?.name}</p>
          </>
        ),
      },
      {
        title: "Address",
        dataIndex: "address",
        key: "address",
        render: (text: string) => <a>{text}</a>,
      },
      {
        title: "SN/BarCode",
        dataIndex: "snumber",
        key: "snumber",
      },
      {
        title: "IMEI1",
        dataIndex: "imei1",
        key: "imei1",
        render: (text: string) => <p>{text || "N/A"}</p>,
      },
    ];

    const productData: ExpandedDataType[] = [];
    wareHouseOut?.warehouseOutItem?.data?.forEach((el: any, index: any) => {
      productData.push({
        key: el._id,
        _id: el?._id,
        no: index + 1,
        shop: el?.shop?.name,
        address: el?.shop?.address,
        model: el?.requestedItem?.product?.product?.model,
        specification: el?.requestedItem?.product?.specification,
        price: el?.requestedItem?.product?.prices,
        extendedWarranty: el?.requestedItem?.product?.extendedWarranty,
        snumber: el?.wareHouseItem?.serialNumber,
        imei1: el?.wareHouseItem?.imei1,
        imei2: el?.wareHouseItem?.imei2,
        color: "",
        isSelected: el?.isSelected,
        data: el,
      });
    });
    return (
      <Table
        columns={columns}
        dataSource={productData}
        pagination={false}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
      />
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: "Date",
      dataIndex: "data",
      key: "data",
      render: (text: string, record: any) => (
        <p>{record?.data?.updatedAt.split("T")[0]}</p>
      ),
    },
    {
      title: "Models",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Descriptions",
      dataIndex: "specification",
      key: "specification",
    },
    {
      title: `Price(${getDefaultCurrencyCode(company)})`,
      dataIndex: "price",
      key: "price",
      render: (text: string, record: any) => (
        <p>
          {record?.data?.product?.prices
            ?.find((obj: any) => obj?.isActive === true)
            ?.value.toLocaleString() || "N/A"}
        </p>
      ),
    },
    {
      title: "Extended Warranty",
      dataIndex: "data",
      key: "data",
      render: (text: string, record: any) => (
        <p>{record?.data?.product?.extendedWarranty ?? "0"} %</p>
      ),
    },
    {
      title: "Products",
      dataIndex: "data",
      key: "data",
      render: (text: string, record: any) => <p>{record?.data?.qtyApproved}</p>,
    },
    {
      title: " ",
      dataIndex: "data",
      key: "data",
      render: (text: string, record: any) => (
        <Button
          variant="contained"
          color="primary"
          // onClick={}
          // disabled={}
        >
          Scan
        </Button>
      ),
    },
  ];

  const data: {
    _id: any;
    key: any;
    no: number;
    model: any;
    specification: any;
    data: any;
  }[] = [];

  wareHouseOut?.selectedWarehouseOut?.stockRequest?.list?.forEach(
    (el: any, index: number) => {
      data?.push({
        key: el._id,
        _id: el?._id,
        no: index + 1,
        model: el?.product?.product?.model + "~" + el?.product?.product?.type,
        specification: el?.product?.specification,
        data: el,
      });
    }
  );

  const handleRowExpand = (expanded: any, record: any) => {
    if (expanded) {
      setProdId(record.key);
      setExpandedRowKey(record.key);
    } else {
      setExpandedRowKey(null);
    }
  };

  const handleValidate = async () => {
    if (auth.token) {
      await updateReceivedProductAction(
        auth.token,
        wareHouseOut?.selectedWarehouseOut?._id,
        {
          status: "received",
        }
      )(dispatch);
    }
  };

  return (
    <>
      <div className="flex px-6 justify-between gap-2 mb-6 text-gray-900">
        <div>
          <div>
            <p className="font-semibold">
              Channel:
              <span className="text-gray-900 font-medium capitalize">
                {" "}
                {
                  wareHouseOut?.warehouseOut?.data[0]?.stockRequest?.shopId
                    ?.channel?.name
                }
              </span>
            </p>
            <p className="font-semibold">
              Shop:
              <span className="text-gray-900 font-medium capitalize">
                {" "}
                {
                  wareHouseOut?.warehouseOut?.data[0]?.stockRequest?.shopId
                    ?.name
                }
              </span>
            </p>
            <p className="font-semibold">
              Approved By:
              <span className="text-gray-900 font-medium capitalize">
                {" "}
                {
                  wareHouseOut?.selectedWarehouseOut?.stockRequest?.approvedBy
                    ?.names
                }
              </span>
            </p>
          </div>
        </div>
        <div>
          <div>
            <h1 className="font-bold mb-1">Deliverer</h1>
            <p className="font-semibold">
              Name:
              <span className="text-gray-900 font-medium capitalize">
                {" "}
                {deliverer?.name}
              </span>
            </p>
            <p className="font-semibold">
              Comapny:
              <span className="text-gray-900 font-medium">
                {" "}
                {deliverer?.company}
              </span>
            </p>
            <p className="font-semibold">
              {" "}
              Telephone:
              <span className="text-gray-900 font-medium">
                {" "}
                {deliverer?.phone}
              </span>
            </p>
            <p className="font-semibold">
              Email:
              <span className="text-gray-900 font-medium">
                {" "}
                {deliverer?.email}
              </span>
            </p>
          </div>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys: ["0"],
          onExpand: handleRowExpand,
          expandedRowKeys: [expandedRowKey],
        }}
        style={{ width: "100%" }}
        loading={wareHouseOut?.isFetching}
      />

      {wareHouseOut?.selectedWarehouseOut?.status?.toLowerCase() ===
        "in-transit" && (
        <LoadingButton
          variant="contained"
          sx={{ minWidth: 100 }}
          onClick={() => handleValidate()}
          loading={pos.isFetching}
        >
          Validate
        </LoadingButton>
      )}
    </>
  );
};

export default PosTableView;
