import { Button, Form, Space } from "antd";
import { InputNumber } from "antd/lib";
import { unLockDeviceAction } from "../../../../store/vault/actions";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const UnlockDevice = (props: any) => {
  const { auth, vault } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onUnlock = async (values: any) => {
    auth?.token &&
      (await unLockDeviceAction(auth?.token, {
        deviceUid: [props?.deviceData],
        validityDays: values?.validityDays,
        vaultTenant: values?.vaultTenant,
      })(dispatch));
    props.onClose();
    form.resetFields();
  };
  return (
    <>
      <div>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onUnlock}
          autoComplete="off"
        >
          <h1 className="text-[#030229] text-sm text-center font-medium">
            Device will move to Active state
          </h1>
          <div className="text-[#030229] pl-1 text-sm font-medium mb-2">
            Days
          </div>
          <Form.Item
            name="validityDays"
            className="w-full "
            rules={[{ required: true, message: "Please enter days" }]}
          >
            <InputNumber
              defaultValue={30}
              className="capitalize rounded-md h-[52px] w-[100%] "
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Space>
              <Button
                htmlType="submit"
                className="px-4 py-1 border border-red-400 hover:border-red-400 "
                onClick={props?.onClose}
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                className="px-4 py-1  border border-[#605BFF] "
              >
                {vault?.isFetching ? (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 24 }} spin />
                    }
                  />
                ) : (
                  "Unlock"
                )}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
export default UnlockDevice;
