import { Checkbox } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Badge, Divider } from "antd";
import { searchValue } from "../../../utils/setColor";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Component = (props: any) => {
  const navigate = useNavigate();
  return (
    <>
      <Badge.Ribbon
        text={
          <span
            style={{
              fontSize: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              fontWeight: 400,
              paddingTop: "1.5px",
            }}
          >
            {props?.data?.status}
          </span>
        }
        color={searchValue(props?.data?.status)}
        style={{ padding: "4px 4px" }}
      >
        {props?.route === "channel" && (
          <div className="absolute top-0 left-0 z-10">
            <Checkbox
              id={props?.data._id}
              onChange={props?.handleSelected}
              {...label}
              color="success"
            />
          </div>
        )}
        <div
          className="min-h-[195px] bg-white p-2 rounded-md hover:border-black relative shadow-[1px_3px_16px_0px_rgba(0,0,0,0.07)] text-[#030229] "
          onClick={() =>
            navigate(`/inventory/ws/out/${props.data.data.warehouseOutId}`)
          }
        >
          <div className="py-2 px-1">
            <div className="text-base space-y-2">
              <h4 className="font-normal text-[14px] leading-3 text-[#030229] font-poppins">
                {props?.data?.warehouseOutId}
              </h4>
              <p className="text-[13px] font-normal text-blue-400">
                {`${props?.data?.data?.stockRequest?.shopId?.name || "N/A"}`}
              </p>
            </div>
            <Divider />
            <div className="flex justify-between items-end w-full">
              <div>
                <p className="opacity-80 text-[12px] font-normal leading-normal">
                  Approved By:{" "}
                  <span className="font-semibold text-xs pl-2">
                    {props?.data?.data?.stockRequest?.approvedBy?.names}
                  </span>
                </p>
                <p className="opacity-80 text-[12px] font-normal leading-normal">
                  Approved On:{" "}
                  <span className="font-semibold text-xs pl-2">
                    {props?.data?.data?.updatedAt?.split("T")[0]}
                  </span>
                </p>
                <p className="opacity-80 text-[12px] font-normal leading-normal">
                  Created On:
                  <span className="font-semibold text-xs pl-2">
                    {props?.data?.date}
                  </span>
                </p>
                {props?.data?.data?.deliverer && (
                  <p className="opacity-80 text-[12px] font-normal leading-normal">
                    Deliverer:
                    <span className="font-semibold text-xs pl-2">
                      {props?.data?.data?.deliverer?.name}
                    </span>
                  </p>
                )}
              </div>
              <p className="text-[14px] font-medium leading-normal pr-3">
                {props?.data?.data?.list?.length} items
              </p>
            </div>
          </div>
        </div>
      </Badge.Ribbon>
    </>
  );
};

export default Component;
