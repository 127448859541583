import React, { useState } from "react";
import CustomFilter from "../../../../components/filters/CustomFilter";
import CustomerDevicesTable from "../../../../components/tables/CustomerDevicesTable";
import CustomButton from "../../../../components/buttons/CustomButton";
import { Input } from "antd";
import { useSelector } from "react-redux";

const CustomerDevices = () => {
  const { customer, deliverynoteItem } = useSelector((state: any) => state);
  const [selectedStatus, setSelectedStatus] = useState("");
  const { Search } = Input;
  const onSearch = (value: any, _e: any, info: any) =>
    console.log(info?.source, value);
  const dataStatus = [
    {
      value: "",
      label: "All Devices(4)",
    },
    {
      value: "Locked(1)",
      label: "locked",
    },
    {
      value: "UnLocked(3)",
      label: "B2C Prepaid",
    },
  ];
  return (
    <div>
      <div className="flex justify-between">
        <CustomFilter setSelectedStatus={setSelectedStatus} data={dataStatus} />
        <div className="flex gap-5 h-8">
          <Search
            placeholder="Search Devices By IMEI"
            onSearch={onSearch}
            style={{ width: "250px" }}
          />
          <CustomButton
            btnName="Unlock All"
            textColor={"white"}
            btnBgColor={"[#0FA958]"}
          />
          <CustomButton
            btnName="Lock All"
            textColor={"white"}
            btnBgColor={"[#F1595C]"}
          />
        </div>
      </div>
      <CustomerDevicesTable data={customer?.customerDevices?.data} />
    </div>
  );
};

export default CustomerDevices;
