import React from 'react';
import GaugeComponent from 'react-gauge-component';
import "./style.css"
const GradientChart = () => {
  const value = 900;  // Adjust this based on your desired value
  const maxValue = 1000;  // Maximum value for the gauge

  return (
    <div style={{ textAlign: 'center', position: 'relative' }} className='gaugeGraph'>
      <GaugeComponent
        id="gauge-component4"
        arc={{
          width: 0.2,
          padding: 0.03,
          gradient: true,
          colorArray: ['#FD8C90', '#66BCF5', '#66BCF5', '#87EA8A'],  
          subArcs: [
            {
              limit: 250,
              color: '#FD8C90',  
              showTick: true
            },
            {
              limit: 500,
              color: '#66BCF5', 
              showTick: true
            },
            {
              limit: 750,
              color: '#66BCF5',  
              showTick: true
            },
            {
              limit: maxValue,
              color: '#87EA8A', 
              showTick: true
            },
          ]
        }}
        value={value}
        minValue={0}
        maxValue={maxValue}
        pointer={{ type: "arrow", elastic: true }}
      />
      <div style={{ fontSize: '20px', color: '#001F3F' }} className=' left-28 absolute'>
        Excellent
      </div>
    </div>
  );
};

export default GradientChart;
