import React from "react";
import ProductCardGridStock from "./ProductCardGridStock";
import { useSelector } from "react-redux";

const App = (props: any) => {
  const { product } = useSelector((state: any) => state);

  return props?.data?.map((d: any) => (
    <ProductCardGridStock
      data={d}
      handleSelected={props?.handleSelected}
      types={product?.attributes?.types}
    ></ProductCardGridStock>
  ));
};

export default App;
