import React, { useEffect, useState } from "react";
import {
  Avatar,
  List,
  Card,
  Button,
  Progress,
  DatePicker,
  Divider,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Applicants from "./applicants";
import ScheduleList from "./ScheduleList";
import StatisticsGraph from "./StatisticsGraph";
import { ReactComponent as JobsIcons } from "../../assets/hr.svg";
import { ReactComponent as UserIcons } from "../../assets/hr.svg";
import { ReactComponent as HRImage } from "../../assets/hr.svg";
import { IoMenuOutline } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import "../../components/style.css"
import { useMediaQuery } from "@mui/material";

const data = [
  { month: "Jan", type: "Applicants", value: 80 },
  { month: "Jan", type: "Shortlisted", value: 60 },
  { month: "Jan", type: "Rejected", value: 40 },
  { month: "Feb", type: "Applicants", value: 90 },
  { month: "Feb", type: "Shortlisted", value: 70 },
  { month: "Feb", type: "Rejected", value: 50 },
];

const Careers = (props: any) => {
  const CustomScreen = useMediaQuery("(min-width: 1024px)");
  const [showContent, setShowContent] = useState(true);
//   useEffect(()=>{
// if(CustomScreen){
//   setShowContent(true)
// }else{
//   setShowContent(false)
// }
//   },[showContent])
  return (
    <div className="capitalize flex flex-row items-stretch mb-4 gap-3 pt-70px] min-h-screen">
      <div className="flex flex-col  w-[100%] md:w-[95%] 2xl:w-[80%] h-auto pt-[35px] mt-30">
        <div className="mb-4">
          <div className="flex justify-between">

          <div className="flex w-full flex-col md:flex-row justify-between items-center pb-3">
            <DatePicker />
            <Button type="primary" icon={<PlusOutlined />} className="mt-5 md:mt-0">
              New Opening Job
            </Button>
          </div>
            <IoMenuOutline
        className="block md:w-10 w-8 h-8 md:h-10 lg:hidden mts-10 right-0 cursor-pointer"
        color="black"
        onClick={() => setShowContent(true)}
      />
          </div>
          <div className="grid  sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
            <div className="text-center h-[122px] flex flex-row  justify-around items-center bg-white rounded-md">
              <div className="flex  flex-col items-baseline">
                <h1 className="text-xl font-semibold text-[#030229] m-0">31</h1>
                <p className="text-[#030229] m-0 ml-2 text-md">
                  Total Applicants
                </p>
              </div>
              <div>
                {" "}
                <Progress
                  type="circle"
                  percent={70}
                  width={50}
                  strokeColor="#57C362"
                />
              </div>
            </div>
            <div className="text-center h-[122px] flex flex-row  justify-around items-center bg-white rounded-md">
              <div className="flex  flex-col items-baseline">
                <h3
                  className="text-xl font-semibold"
                  style={{ color: "#030229" }}
                >
                  8
                </h3>
                <p className="text-[#030229] m-0 ml-2 text-md">Shortlisted</p>
              </div>
              <div>
                {" "}
                <Progress
                  type="circle"
                  percent={56}
                  width={50}
                  strokeColor="#F17B2C"
                />
              </div>
            </div>
            <div className="text-center h-[122px] flex flex-row  justify-around items-center bg-white rounded-md">
              <div className="flex  flex-col items-baseline">
                <h3
                  className="text-xl font-semibold"
                  style={{ color: "#030229" }}
                >
                  10
                </h3>
                <p className="text-[#030229] m-0 ml-2 text-md">Rejected</p>
              </div>
              <div>
                {" "}
                <Progress
                  type="circle"
                  percent={62}
                  width={50}
                  strokeColor="#AFADFF"
                />
              </div>
            </div>
            <div className="text-center h-[122px] flex flex-row  justify-around items-center bg-white rounded-md">
              <div className="flex  flex-col items-baseline">
                <h3
                  className="text-xl font-semibold"
                  style={{ color: "#030229" }}
                >
                  10
                </h3>
                <p className="text-[#030229] m-0 ml-2 text-md">In-Review</p>
              </div>
              <div>
                {" "}
                <Progress
                  type="circle"
                  percent={62}
                  width={50}
                  strokeColor="#82B1FF"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-auto  bg-white rounded-md">
          <StatisticsGraph />
        </div>
        <div className="flex flex-col lg:flex-row gap-3 px-2 py-2">
          <Applicants />
          <ScheduleList />
        </div>
      </div>
      <IoMenuOutline
        className=" hidden lg:block md:w-10 w-8 h-8 md:h-10 2xl:hidden mt-8 right-0 cursor-pointer"
        color="black"
        onClick={() => setShowContent(true)}
      />
      <div
        className={`w-[100%]  md:w-[350px] 
          ${
          (showContent) ? "block aside-transitionsVisible" : " block aside-transitions"
        }  
         p-4 shadow-lg overflow-y-auto bg-white absolute right-0 h-screen`}
      >
          <IoMdClose
            size={20}
            className="block fixed 2xl:hidden mt-2 cursor-pointer"
            color="black"
            onClick={() => setShowContent(false)}
          />
        <div className="flex flex-col items-center mb-4">
          <HRImage />
          <h2 className="text-md font-poppins mt-2 text-[#0d0d1f]">
            Constantin MUCO
          </h2>
          <h3 className="text-sm text-[#03022980] opacity-[50%]">
            Head of Recruitment
          </h3>
        </div>
        <div>
          <Divider />
        </div>
        <div className="mb-4">
          <div className="flex justify-between items-center mb-2">
            <h2 className="text-md font-poppins font-normal text-left text-[#0d0d1f]">
              messages
            </h2>
            <a href="#" className="text-blue-500 text-sm">
              View All
            </a>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex items-center space-x-3 pb-6 bg-[#605BFF08] opacity-3 rounded-md py-4 px-3">
              <UserIcons />
              <div>
                <h3 className="text-md font-medium text-[#030229]">
                  Kate Kyansimire
                </h3>
                <p className="text-[#03022980] opacity-[50%] font-Poppins text-xs">
                  Greetings HR, I hope this message...{" "}
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-3 pb-6 bg-[#605BFF08] opacity-3 rounded-md py-4 px-3">
              <UserIcons />
              <div>
                <h3 className="text-md font-medium text-[#030229]">
                  Kate Kyansimire
                </h3>
                <p className="text-[#03022980] opacity-[50%] font-Poppins text-xs">
                  Greetings HR, I hope this message...{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="flex justify-between items-center mb-2 px-3">
            <h2 className="text-md font-poppins font-normal text-left text-[#0d0d1f]">
              Recent Opportunities
            </h2>
            <a href="#" className="text-blue-500 text-sm">
              View All
            </a>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex items-center space-x-3 pb-6 bg-[#605BFF08] opacity-3 rounded-md py-4 px-3">
              <JobsIcons />{" "}
              <div>
                <h3 className="text-md font-medium text-[#030229]">
                  Back-end Developer
                </h3>
                <p className="text-[#03022980] opacity-[50%] font-Poppins text-xs">
                  1 Position, Management Skills
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-3 pb-6 bg-[#605BFF08] opacity-3 rounded-md py-4 px-3">
              <JobsIcons />{" "}
              <div>
                <h3 className="text-md font-medium text-[#030229]">
                  Logistic Manager
                </h3>
                <p className="text-[#03022980] opacity-[50%] font-Poppins text-xs">
                  1 Position, Management Skills
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-3 pb-6 bg-[#605BFF08] opacity-3 rounded-md py-4 px-3">
              <JobsIcons />{" "}
              <div>
                <h3 className="text-md font-medium text-[#030229]">
                  UI/UX designer
                </h3>
                <p className="text-[#03022980] opacity-[50%] font-Poppins text-xs">
                  1 Position, Figma, Sketch, Prototyping,...
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-3 pb-6 bg-[#605BFF08] opacity-3 rounded-md py-4 px-3">
              <JobsIcons />
              <div>
                <h3 className="text-md font-medium text-[#030229]">
                  Secretary
                </h3>
                <p className="text-[#03022980] opacity-[50%] font-Poppins text-xs">
                  1 Position, Management Skills
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Careers;
