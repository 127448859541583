import React, { useEffect, useState } from "react";
import DeviceCommissionTable from "../../../components/tables/shop/DeviceCommissionTable";
import DetailsHeaderActionBar from "../../../components/details/supplier/DetailsPage/DetailsHeaderActionBar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDeviceWithCommissionAction,
  getPricelistItemAction
} from "../../../store/channel/actions";
import SettingButton from "../setting/SettingButton";
import VaultButton from "../../../components/buttons/vaultButton";
import { Modal } from "antd";
import AssignDeviceCommissionForm from "../../../components/forms/assignDeviceCommissionForm";

const DeviceWithCommssion = (props: any) => {
  const { shopId } = useParams();
  const { channel, auth, shop } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const getDeviceWithCommission = async () => {
    auth?.token &&
      (await getDeviceWithCommissionAction(
        auth?.token,
        `channelId=${channel?.selected?._id}&shopId=${shopId}`
      )(dispatch));
  };
  useEffect(() => {
    getDeviceWithCommission();
  }, [auth?.token, dispatch, shopId]);
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const openModal = () => {
    setIsModalOpen(true);
    getPricelistItemAction(
      auth?.token,
      `?channel=${channel?.selected?._id}&type=pos`
    )(dispatch);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleOkModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <DetailsHeaderActionBar
        pageName="Shop"
        title={`${shop?.selected?.name} ~ ${
          shop?.selected?.type === "service-center"
            ? "SC"
            : shop?.selected?.type === "connect-shop"
            ? "CS"
            : shop?.selected?.type
        } / Profile / View Devices`}
        goBack={goBack}
      />
      <div className="flex justify-between">
        <h1 className="text-[#030229] text-lg py-4">
          Devices Supported Commission
        </h1>
        {["admin", "dev", "finance", "finance-manager"]?.includes(
          auth?.user?.role
        ) && (
          <button
            className=" bg-[#605BFF] text-white px-3 rounded-md"
            onClick={openModal}
          >
            Create Commission
          </button>
        )}
      </div>
      <div className="mt-4 h-[75vh] overflow-y-auto">
        <DeviceCommissionTable />
      </div>
      <Modal
        title="Assign Commission Price to Device"
        width={600}
        open={isModalOpen}
        footer={null}
        onOk={handleOkModal}
        onCancel={handleCloseModal}
      >
        <AssignDeviceCommissionForm
          data={channel?.priceListItems?.data}
          onCancel={handleCloseModal}
        />
      </Modal>
    </div>
  );
};

export default DeviceWithCommssion;
