import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { authActions } from "../store/auth";
import store from "store";
import { setRectirectPathAction } from "../store/layout/actions";

const DashRoutes = () => {
  const dispatch = useDispatch();
  const token = store.get("authToken");
  const { auth } = useSelector((state: any) => state);
  const prevLocation = useLocation();
  localStorage.setItem("prevPath", prevLocation.pathname);
  const fromStorage = localStorage.getItem("prevPath");

  useEffect(() => {
    setRectirectPathAction(fromStorage)(dispatch);
  }, [fromStorage, dispatch]);
  useEffect(() => {
    if (token) {
      dispatch(authActions.setToken({ token }));
    }
    // auth?.token && profileAction(token)(dispatch);
  }, [auth.token, dispatch, token]);
  return (auth?.token || (token && !auth?.profileIsFetching)) &&
    !["sales-agent", "mtn-sales-agent"]?.includes(auth?.user?.role) ? (
    <>
      <Outlet />
    </>
  ) : prevLocation.pathname.split("/")[2] === "reset_password" ? (
    <Navigate to={`/login?redirectTo=/dashboard`} />
  ) : (
    <Navigate to={`/login?redirectTo=${prevLocation.pathname}`} />
  );
};

export default DashRoutes;
