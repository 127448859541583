import React, { useEffect, useState } from "react";
import { Button, Table, Tag, Tooltip, Popconfirm, Drawer } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import {
  deletePendingTransactionAction,
  getAllTransactionAction,
  getAllTransactionInRangeAction,
} from "../../store/pos/actions";
import { searchValue } from "../../utils/setColor";
import ScrollableFrame from "../layout/ScrollableFrame";
import { TransactionsHeaders } from "../csvHeaders/Headers";
import { useMediaQuery } from "@mui/material";
import POSTransactionDetails from "../../pages/dashboard/POS/POSTransactionDetails";
import FilesCard from "../cards/NoSale/FilesCard";
import CSVExportButton from "../cards/NoSale/CSVExportButton";
import { VscFilePdf } from "react-icons/vsc";
import { getFinanceTransaction_CSVAction } from "../../store/csvDownload/actions";
import { getDefaultCurrencyCode } from "../../utils/helper";
interface DataType {
  [x: string]: any;
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const POSTTranctionsTable = (props: any) => {
  const isTablet = useMediaQuery("(max-width: 960px)");
  const { pos, auth, layout, CSV, company } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  const [openDrower, setOpenDrower] = useState(false);
  const [showData, setShowData] = useState("");

  const showDrawer = (value: any) => {
    setOpenDrower(true);
    setShowData(value);
  };

  const onCloseDrower = () => {
    setOpenDrower(false);
    setShowData("");
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "paidOn",
      key: "paidOn",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      render: (text, record) => <p>{text || "N/A"}</p>,
    },
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
      render: (text, record) => (
        <>
          <p>{text}</p>
        </>
      ),
    },
    {
      title: "Shop",
      dataIndex: "data",
      key: "data",
      render: (text, record) => (
        <p className="font-bold">
          {record?.data?.shopStockItem?.shop?.name ?? "N/A"}
        </p>
      ),
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      render: (text, record) => (
        <Tooltip title={`${record.specification}`}>
          <p>{text}</p>
        </Tooltip>
      ),
    },
    {
      title: "SN/Bar Code",
      dataIndex: "barCode",
      key: "barCode",
    },
    {
      title: `Price (${getDefaultCurrencyCode(company)})`,
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <p>{record?.data?.payment?.amount?.toLocaleString()} </p>
      ),
    },
    {
      title: "Ex-Wty",
      dataIndex: "data",
      key: "data",
      render: (text, record) => (
        <p>{record?.data?.payment?.extendedWarranty} %</p>
      ),
    },
    {
      title: "Disc",
      dataIndex: "data",
      key: "data",
      render: (text, record) => (
        <p>{record?.data?.cart?.discount?.discountPerc} %</p>
      ),
    },
    {
      title: "Amount Paid",
      dataIndex: "data",
      key: "data",
      render: (text, record) => (
        <Tag
          className="font-bold"
          color={
            record?.data?.payment?.amount +
              (record?.data?.payment?.amount *
                record?.data?.payment?.extendedWarranty) /
                100 -
              (record?.data?.payment?.amount *
                record?.data?.cart?.discount?.discountPerc) /
                100 ===
            record?.data?.cart?.payment?.reduce(
              (sum: number, a: any) => (sum = sum + a?.amount),
              0
            )
              ? "success"
              : record?.data?.payment?.amount +
                  (record?.data?.payment?.amount *
                    record?.data?.payment?.extendedWarranty) /
                    100 -
                  (record?.data?.payment?.amount *
                    record?.data?.cart?.discount?.discountPerc) /
                    100 >
                record?.data?.cart?.payment?.reduce(
                  (sum: number, a: any) => (sum = sum + a?.amount),
                  0
                )
              ? "error"
              : "cyan-inverse"
          }
        >
          {record?.data?.cart?.payment
            ?.reduce((sum: number, a: any) => (sum = sum + a?.amount), 0)
            ?.toLocaleString()}
        </Tag>
      ),
    },
    {
      title: "Transaction Id",
      dataIndex: "transactionId",
      key: "transactionId",
      render: (text, record: any) => <p className="text-sm">{text}</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Tag
          color={searchValue(record?.data?.status)}
          className="capitalize min-w-[50px] text-center"
        >
          {record?.data?.status}{" "}
        </Tag>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, record) => {
        const confirm = () => {
          deletePendingTransactionAction(
            record?.auth?.token,
            record?.key
          )(record?.dispatch);
        };
        return (
          <div className="flex space-x-2">
            {record?.data?.status === "pending" && (
              <Popconfirm
                title="Delete the Transaction"
                description="Are you sure to delete this Transaction?"
                okText="Yes"
                okButtonProps={{
                  loading: pos?.isFetching,
                  className: "bg-blue-500",
                }}
                onConfirm={confirm}
                cancelText="No"
              >
                <Button danger>Delete</Button>
              </Popconfirm>
            )}

            {record?.data?.status === "paid" && (
              <Button onClick={() => showDrawer(record)}>View</Button>
            )}
          </div>
        );
      },
    },
  ];

  React.useEffect(() => {
    if (props?.selectedEndDate || props?.selectedDate) {
      auth?.token &&
        getAllTransactionInRangeAction(
          auth?.token,
          `?startDate=${props?.selectedDate}&endDate=${
            props?.selectedEndDate
          }&limit=${limit}&page=${page - 1}&channel=${props?.selectedChannel}`
        )(dispatch);
    } else {
      auth?.token &&
        getAllTransactionAction(
          auth?.token,
          `?limit=${limit}&page=${page - 1}&channel=${props?.selectedChannel}`
        )(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth?.token,
    dispatch,
    limit,
    page,
    props?.selectedStatus,
    props?.selectedDate,
    props?.selectedEndDate,
  ]);

  useEffect(() => {
    if (layout?.openCSVButton)
      auth?.token &&
        getFinanceTransaction_CSVAction(
          auth?.token,
          `?startDate=${props?.selectedDate}&endDate=${
            props?.selectedEndDate
          }&limit=${pos?.transaction?.total}&page=0&channel=${
            props?.selectedChannel
          }${layout?.selectedShop ? `&shop=${layout?.selectedShop}` : ""}`
        )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  const value = pos?.transaction?.data;

  const dataToDisplayInTable = value?.map((el: any) => {
    return {
      key: el?._id,
      date_time: el?.createdAt?.slice(0, 10),
      paidOn: el?.paidOn?.slice(0, 10),
      agent: el?.createdBy?.names,
      model:
        el?.shopStockItem?.requestedItem?.product?.product?.model +
        "~" +
        el?.shopStockItem?.requestedItem?.product?.product?.type,
      specification: `${el?.shopStockItem?.requestedItem?.product?.specification
        ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
        ?.join(", ")}`,
      amount: el?.payment?.amount,
      status: el?.status,
      barCode: el?.warehouseProduct?.serialNumber,
      transactionId: el?.cart?.cartId,
      customer: el?.cart?.customer?.name,
      auth: auth,
      dispatch: dispatch,
      data: el,
    };
  });

  const csvData = CSV?.csvDownloadedData?.data?.map((el: any) => ({
    date_time: el?.createdAt?.slice(0, 10),
    paidOn: el?.paidOn?.slice(0, 10),
    customer: el?.cart?.customer?.name || "N/A",
    customerPhone: el?.cart?.customer?.phone || "N/A",
    agent: el?.createdBy?.names,
    shop_name:
      el?.shopStockItem?.shop?.type !== "service-center"
        ? el?.shopStockItem?.shop?.name +
          "-" +
          el?.shopStockItem?.shop?.type?.split("-")?.join(" ")
        : el?.shopStockItem?.shop?.name,
    model: el?.shopStockItem?.requestedItem?.product?.product?.model,
    type: el?.shopStockItem?.requestedItem?.product?.product?.type,
    brand: el?.shopStockItem?.requestedItem?.product?.product?.brand,
    barCode: el?.warehouseProduct?.serialNumber,
    imei2: el?.warehouseProduct?.imei2 ?? "N/A",
    dataActivated: el?.warehouseProduct?.dataActivated ? "YES" : "NO",
    newSimCard: el?.warehouseProduct?.isNewSimcard ? "YES" : "NO",
    storage: el?.shopStockItem?.requestedItem?.product?.specification
      .filter((item: any) => item[0]?.toLowerCase() === "capacity (rom)")
      .map((item: any) => item[1]),
    memory: el?.shopStockItem?.requestedItem?.product?.specification
      .filter((item: any) => item[0]?.toLowerCase() === "memory (ram)")
      .map((item: any) => item[1]),
    rraReceiptCode: el?.receiptCode ?? "N/A",
    amount: el?.payment.amount?.toLocaleString(),
    methodOfPayment: el?.cart?.payment?.map((d: any) => d?.mode),
    momoTransactionId: el?.cart?.payment?.map((d: any) =>
      d?.mode === "MoMo_Pay" ? d?.momoTransactionId : "-"
    ),
    macyemacyeType: el?.cart?.payment
      ?.filter((d: any) => d?.mode === "Macye_Macye")
      ?.map((d: any) => d?.macyemacyeType?.split("_")?.join(" ") ?? "-"),
    discount: `${el?.cart?.discount?.discountPerc} %`,
    documents:
      el?.cart?.discount?.proof?.[0] ??
      el?.cart?.payment?.map((d: any) => d?.reference[0]),
    status: el?.status,
    puchaseCost: parseInt(
      el?.warehouseProduct?.warehouseItem?.purchaseItem?.priceUnit
    )?.toLocaleString(),
    totalAmount:
      el?.successPayment?.length > 0
        ? el?.successPayment
            ?.map((d: any) => d?.amount?.toLocaleString())
            ?.join(" + ")
        : el?.cart?.payment
            ?.map((d: any) => d?.amount?.toLocaleString())
            ?.join(" + "),
  }));

  return (
    <>
      <div className="relative">
        <div className="flex items-end justify-end mr-4 mt-2">
          {["admin", "finance", "finance-manager", "dev"].includes(
            auth?.user?.role?.toLowerCase()
          ) && (
            <div className="flex gap-4 mt-4">
              <FilesCard
                Icon={VscFilePdf}
                tailwindTextColor="text-white"
                tailwindBackgroundColor="bg-orange-400"
                // tailwindBorderColor="border-orange-400"
                disabled
              />
              <CSVExportButton
                csvHeaders={TransactionsHeaders}
                csvData={csvData}
                filename={`Sales Report/Transactions.csv`}
              />
            </div>
          )}
        </div>
        <ScrollableFrame
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
          total={pos?.transaction?.total}
          count={Math.ceil(pos?.transaction?.total / limit)}
        >
          <Table
            columns={columns}
            dataSource={dataToDisplayInTable}
            pagination={false}
            style={{ backgroundColor: "white" }}
            scroll={isTablet ? { x: 1300 } : undefined}
            loading={pos?.isFetching}
          />
        </ScrollableFrame>
      </div>

      <Drawer
        title={`Transaction Details`}
        placement="right"
        size={"large"}
        onClose={onCloseDrower}
        open={openDrower}
      >
        <POSTransactionDetails dataToDisplay={showData} />
      </Drawer>
    </>
  );
};

export default POSTTranctionsTable;
