import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Avatar, Divider, List, Skeleton } from "antd";
import { useSelector } from "react-redux";

interface DataType {
  gender: string;
  name: {
    title: string;
    first: string;
    last: string;
  };
  email: string;
  picture: {
    large: string;
    medium: string;
    thumbnail: string;
  };
  nat: string;
}

const Component = (props: any) => {
  const { account } = useSelector((stat: any) => stat);
  return (
    <div
      id="scrollableDiv"
      style={{
        height: 400,
        overflow: "auto",
        padding: "0 16px",
      }}
    >
      <ul className=" ml-6">
        {account?.note?.data?.map((el: any, index: any) => {
          return (
            <>
              <li className="flex">
                <div className=" mt-3  h-[auto]">
                  <div>
                    <Avatar src={el?.createdBy?.picture} />
                  </div>
                  {index !== account?.note?.data?.length - 1 && (
                    <div className="h-[80%] w-[1px] bg-[#0f0f4711] mt-[0.6px] ml-3.5 mr-3"></div>
                  )}
                </div>
                <div className={` mb-3 px-6 py-2 w-full h-full rounded-md`}>
                  <div className="w-[90%]">
                    <div className=" mb-2 font- normal">
                      <p className="text-[#0f0f47] flex gap-2 font-medium text-sm">
                        {el.title}
                      </p>
                      <p className="text-[#0f0f475e] text-[12px] pt-2">
                        {el.details}
                      </p>
                    </div>
                    <div className=" mt-4">
                      <p className="text-[#0f0f475e] text-[12px] pt-2">
                        {el?.date?.split(".")[0].split("T").join(" ")}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </>
          );
        })}
      </ul>
    </div>
  );
};

export default Component;
