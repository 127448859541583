import React from "react";
import { Modal } from "antd";

const BusinessProductModal = (props: any) => {
  return (
    <Modal
      width={650}
      title={props.title}
      keyboard={false}
      onCancel={props.cancel}
      footer={null}
      open={props.isModalOpen}
      closable={false}
    >
      <div style={{ margin: 0 }}>{props.content}</div>
    </Modal>
  );
};
export default BusinessProductModal;
