import * as React from "react";
import { Box, Stack } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

export const TableHeaderSkeleton = () => {
  return (
    <Stack spacing={10}>
      <Stack
        spacing={5}
        alignItems={"center"}
        justifyContent="space-between"
        direction={"row"}
      >
        <Box sx={{ width: 500 }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>

        <Box sx={{ width: 500 }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
      </Stack>

      <Box sx={{ width: 1000 }}>
        <Skeleton />
        <Skeleton animation="wave" />
        <Skeleton animation={false} />
      </Box>
    </Stack>
  );
};
