import React, { ReactNode } from "react";
interface cardProps {
    total?: any;
    name?: any;
    percent?: any;
    icon?: ReactNode;
    customeIcon?: ReactNode;
}
const Card = ({ total, name, percent, icon, customeIcon }: cardProps) => {
    const styles = {
        boxShadow: {
            boxShadow: "1px 4px 24px 0px rgba(3, 2, 41, 0.06)",
        },
    };
    return (
        <>
            <div
                className="w-[280px] h-[120px] flex  justify-between bg-white rounded-md p-3 py-6 cursor-pointer"
                style={styles.boxShadow}
            >
                <div className="flex gap-4">
                    <div className="border mt-2 border-[#2943d622]  rounded-full w-[45px] h-[45px] content-center pl-2">
                        {customeIcon}
                    </div>
                    <div className="flex flex-col gap-2">
                        <h1 className="text-[#030229] font-semibold text-[22px] opacity-80">
                            {total}
                        </h1>
                        <p className="text-[#030229] opacity-80 font-normal text-[13px]">
                            {name}
                        </p>
                    </div>
                </div>
                <button className=" border h-7 text-black border-[#0302291b] rounded-lg  px-3 flex gap-2 text-[12px]">
                    {" "}
                    {icon}
                    {percent}
                </button>
            </div>
        </>
    );
};
export default Card;
