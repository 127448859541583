import React, { useState } from "react";
import { Modal, Form, Input, Button, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { createpaymentcategoryAction } from "../../store/setting/payments/actions";

const { Option } = Select;

const CreatePaymentCategoryModal = ({
  isModalCategory,
  closeCategory,
  // channelName,
}: any) => {
  const [form] = Form.useForm();
  const { auth, channel } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const handleSubmit = (values: any) => {
    if (auth.token && channel?.selected?._id) {

      createpaymentcategoryAction(auth.token, { 
        name: values?.name, 
        channelId: channel?.selected?._id 
      })(dispatch);
    }
    
    closeCategory();
    form.resetFields();
  };
  

 
  return (
    <Modal
      title="Create New Payment Category"
      visible={isModalCategory}
      onCancel={closeCategory}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="name"
          label="Payment  Name"
          rules={[{ required: true, message: "Please enter a name" }]}
        >
          <Input />
        </Form.Item>
      
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create Payment Category
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreatePaymentCategoryModal;
