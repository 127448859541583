import { useState } from "react";
import { Link } from "react-router-dom";
import { Carousel, Space, Tag } from "antd";
import { getColorByValue, searchValue } from "../../../utils/setColor";
import ProductDetailsModal from "../../Modals/ProductDetailsModal";
import UpdateProduct from "../../forms/NewProductForm";
import DeleteModal from "../../Modals/DeleteModal";
import { useSelector } from "react-redux";
import defaultPic from "../../../assets/nophoto.png";
import ThreeDotDropdown from "../../buttons/ThreeDotDropdown";

const Component = (props: any) => {
  const { channel } = useSelector((state: any) => state);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [visible, setVisible] = useState(false);
  const [showDropdownMenu, setShowDropdownMenu] = useState<boolean>(false);
  // const dispatch = useDispatch();

  const handleOnClickViewIcon = (e: any) => {
    e.preventDefault();
    // setisEdit(false);
    setIsModalOpen(true);
  };

  const handleDeleteModalCancels = () => {
    setVisible(false);
  };
  const handleOnCancel = () => {
    setIsModalOpen(false);
  };

  const deleteProduct = async () => {
    console.log("deleting....");
  };
  console.log(
    props?.data?.prices?.filter((price: any) => price.isActive === true)?.value
  );

  return (
    <div className="relative">
      <ThreeDotDropdown
        setShowDropdownMenu={setShowDropdownMenu}
        showDropdownMenu={showDropdownMenu}
        cardId={props?.data?._id}
      />
      <div className="relative w-[360px] h-[190px] p-2 pt-4 px-6 bg-white text-[#030229] rounded-[10px] border-[#D9DBE960] border-[.1px] shadow-[1px_3px_16px_0px_rgba(0,0,0,0.07)]">
        <Link to={`/inventory/ws/stock/${props?.data?._id}`}>
          <div className="flex w-fit gap-4">
            <div className="w-[98px] h-[110px] bg-[#F4F6FA] rounded-[10px] p-3 border-[.1px] border-[#D9DBE9] border-opacity-20">
              {props?.data?.image?.length === 0 && (
                <img src={defaultPic} width={"100%"} alt="" />
              )}
              {props?.data?.image?.length > 0 && (
                <Carousel autoplay>
                  {props?.data?.image?.map((im: any) => (
                    <div className="block w-[59px] h-[87px]">
                      <img
                        src={im}
                        alt=""
                        className="w-full h-full object-cover"
                      />
                    </div>
                  ))}
                </Carousel>
              )}
            </div>
            <div className="flex flex-col">
              <h1 className="font-medium text-[14px]">
                {props?.data?.model?.toUpperCase()}
              </h1>
              <h1 className="font-normal  text-[12px] text-[#03022970] mb-2">
                Brand: {props?.data?.brand}
              </h1>
              {props?.data?.statuses?.map((item: any, _index: number) => (
                <p
                  className={`text-[${searchValue(
                    item?.status
                  )}] my-[1px] text-[13px] font-medium`}
                >
                  <Space>{`${item?.status?.toLowerCase() === "in"
                      ? "In Warehouse "
                      : item?.status?.toLowerCase() === "out"
                        ? "In Shops"
                        : item?.status?.toLowerCase() === "sold"
                          ? "Sold"
                          : "Idle"
                    } :`}</Space>
                  {item?.count}
                </p>
              ))}
            </div>
          </div>
          <div className="flex justify-center py-4 gap-x-4"></div>
        </Link>
        <div className="w-fit h-fit rounded-[5px] border-[.1px] px-3 font-medium absolute bottom-3 right-4">
          <Space>
            <div
              className="w-[10px] h-[10px] rounded-sm capitalize"
              style={{
                background: getColorByValue(props?.types, props?.data?.type),
              }}
            ></div>
            <p className="w-[50px text-[.7rem] capitalize truncate">
              {props?.data.type}
            </p>
          </Space>
        </div>
      </div>

      {/* product modal here */}

      <ProductDetailsModal
        component={
          <UpdateProduct
            dataToUpdate={props?.data}
            action={"update"}
            onCancel={handleOnCancel}
          />
        }
        isModalOpen={isModalOpen}
        handleOnClickViewIcon={handleOnClickViewIcon}
        handleOnCancel={handleOnCancel}
      />

      <DeleteModal
        visible={visible}
        onOk={deleteProduct}
        isLoading={channel.isFetching}
        onCancel={handleDeleteModalCancels}
        itemName="Product"
      />
    </div>
  );
};
export default Component;
