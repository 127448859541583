import { LoadingButton } from "@mui/lab";
import { Form, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAllOptionsAction } from "../../store/ebm/actions";
import { useEffect, useState } from "react";
import { createItemsAction } from "../../store/ebm/actions";
import { getAllEbmItemsAction } from "../../store/product/actions";

const { Option } = Select;

const UpdateOriginForm: React.FC<{
  priceListItemId: string;
  handleOpenUpdate?: any;
  productId: any;
}> = ({ priceListItemId, handleOpenUpdate, productId }) => {
  const [originFilter, setOriginFilter] = useState([]);
  const { auth, ebm } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const onFinish = async (values: any) => {
    auth?.token &&
      (await createItemsAction(auth?.token, {
        orgnNatCd: values.orgnNatCd.split("-")[0].trim(),
        country: values.orgnNatCd.split("-")[1].trim(),
        priceListItemId: priceListItemId,
      })(dispatch));
    auth?.token &&
      (await getAllEbmItemsAction(
        auth?.token,
        `?product=${productId}`
      )(dispatch));
    handleOpenUpdate(false);
    form.resetFields();
  };
  const [form] = Form.useForm();

  useEffect(() => {
    if (auth?.token) {
      getAllOptionsAction(auth?.token, "?")(dispatch);
    }
  }, [auth?.token, dispatch]);

  type Option = {
    product?: {
      model?: string;
    };
    label?: string;
  };

  type SetFilteredOptions = React.Dispatch<
    React.SetStateAction<Option[] | any>
  >;

  const handleSearch = (
    value: string,
    data: any,
    filterFunction: (option: Option, value: string) => boolean,
    setFilteredOptions: SetFilteredOptions
  ) => {
    const filtered = data?.filter((option: any) =>
      filterFunction(option, value)
    );
    setFilteredOptions(filtered);
  };

  return (
    <Form
      form={form}
      name="register"
      onFinish={onFinish}
      style={{ maxWidth: "100%" }}
      scrollToFirstError
    >
      <Form.Item
        name="orgnNatCd"
        label="Origin"
        hasFeedback
        style={{ width: 200 }}
      >
        <Select
          onSearch={(value: any) =>
            handleSearch(
              value,
              ebm?.options?.data?.Cuntry,
              (option: any, val) =>
                option?.label?.toLowerCase().includes(val.toLowerCase()),
              setOriginFilter
            )
          }
          showSearch
          filterOption={false}
          className="capitalize"
          loading={ebm?.isFetching}
        >
          {originFilter?.length > 0
            ? originFilter?.map((option: any) => (
                <Select.Option
                  key={option.value}
                  value={option.value + "-" + option?.label}
                >
                  {option?.label}
                </Select.Option>
              ))
            : ebm?.options?.data?.Cuntry?.map((d: any) => (
                <Option key={d?.value} value={d?.value + "-" + d?.label}>
                  {d?.label}
                </Option>
              ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <LoadingButton
          type="submit"
          variant="contained"
          sx={{
            minWidth: "80%",
            alignItems: "center",
            justifyItems: "center",
            display: "flex",
            marginLeft: "1.5rem",
          }}
          loading={ebm?.isFetching}
        >
          {"Save"}
        </LoadingButton>
      </Form.Item>
    </Form>
  );
};

export default UpdateOriginForm;
