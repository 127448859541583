import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Form, Input, notification } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { updateSubRequestAction } from "../../store/subscription/subscriptionRequest/actions";
import { useParams } from "react-router-dom";
import {
  getOneAccountAction,
  submitUpdateNotesAction,
} from "../../store/account/actions";

const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const RequestSubscriptionInfoForm = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const { auth, subscriptionRequest } = useSelector((state: any) => state);

  const onFinish = async (values: any) => {
    props?.inquiryId &&
      accountId && auth.token &&
      (await submitUpdateNotesAction(
        accountId as string,
        {
          notes: {
            title: "Missing Information",
            details: values?.comment,
          },
        },
        auth.token
      )(dispatch));
      auth.token && (await updateSubRequestAction(auth?.token, props?.inquiryId, {
      ...values,
      status: "inquiry",
      isSubscriptionSubmitted: false,
    })(dispatch));
    notification.success({ message: "Request Sent Successfully" });
    form.resetFields();
    props?.onCancel();
    auth.token && (await getOneAccountAction(accountId as string, auth.token)(dispatch));
  };

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        initialValues={props?.data || {}}
        style={{ minWidth: 600 }}
        scrollToFirstError
      >
        <h1 className="font-bold text-xl text-gray-900 pb-4 pl-16">
          Missing Information
        </h1>
        <div className="ml-12">
          <Form.Item
            name="comment"
            rules={[
              {
                required: true,
                message: "Field is required!",
                whitespace: true,
              },
            ]}
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ minWidth: "60%" }}
              loading={subscriptionRequest?.isFetching}
            >
              Submit
            </LoadingButton>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default RequestSubscriptionInfoForm;
