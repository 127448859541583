import ProductFilter from "../../../components/filters/POSTransactionsFiltering";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import POSStockTable from "../../../components/tables/POSStockTable";

const POSStock = () => {
  return (
    <div className="text-black py-4 space-y-4">
      <DetailsHeaderActionBar pageName="POS" title="Stock" />
      <ProductFilter />
      <POSStockTable />
    </div>
  );
};

export default POSStock;
