import React from "react";
import { FaAnchor, FaTruck } from "react-icons/fa"; 
import { Avatar } from "antd";

const ShippingCard = (props: any) => {
  return (
    <div className="flex items-center justify-between p-4 bg-[#E3E5F1] rounded-lg shadow-md w-full px-4 border border-gray-200">
      {/* Courier Section */}
      <div className="flex items-center space-x-3">
        <Avatar
          size={40}
          src="https://via.placeholder.com/40" 
          alt="Aboubakar"
        />
        <div className="flex flex-col">
          <span className="text-xs text-indigo-400 uppercase pt-2">
            Courier
          </span>
          <span className=" text-gray-800 pt-1">
            {props?.trackingActivityForOneistory[0]?.trackId?.deliverer?.name}
          </span>
        </div>
      </div>

      <div className="flex items-center space-x-2 pt-2">
        <div className="flex flex-col">
          <span className="text-xs text-indigo-400 uppercase flex flex-row gap-2">
            <span>
              <FaAnchor className="text-indigo-400" />
            </span>
            <span className="text-xs pt-0.5">Weight</span>
          </span>
          <span className="text-gray-800 pt-2">N/A</span>
        </div>
      </div>

      <div className="flex items-center space-x-2 pt-2">
        <div className="flex flex-col">
          <span className="text-xs text-indigo-400 uppercase flex flex-row gap-2">
            <span>
              <FaTruck className="text-indigo-400" />
            </span>
            <span className="text-xs">Shipping Mode</span>
          </span>
          <span className="text-gray-800 pt-2">N/A</span>
        </div>
      </div>
    </div>
  );
};

export default ShippingCard;
