import React from "react";

const StepsBar = (props: any) => {
  const { stepsData, currentStep } = props;

  const currentStepIndex = stepsData.findIndex(
    (step: any) => step.title === currentStep
  );

  return (
    <div className="flex items-center ">
      {stepsData?.map((step: any, index: number) => (
        <React.Fragment key={index}>
          <div className="flex flex-col items-center">
            <div
              className={`mt-5 rounded-full ${
                index === currentStepIndex
                  ? "border-2 border-gray-400 px-1.5 py-1.5"
                  : ""
              }`}
            >
              <img src={step?.icon} className="w-8 h-8" alt={step?.title} />
            </div>
            <span
              className={`mt-4 text-xs  w-20  text-center ${
                index === currentStepIndex
                  ? "text-black"
                  : index < currentStepIndex
                  ? "text-gray-400"
                  : "text-gray-300"
              }`}
            >
              {step?.title}
            </span>
          </div>
          {index < stepsData?.length - 1 && (
            <div className="flex items-center w-full">
              <div
                className={`flex-1 h-0.5 mx-2 ${
                  index >= currentStepIndex
                    ? "border-t-2 border-dashed border-gray-500"
                    : "bg-gray-500"
                }`}
              ></div>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default StepsBar;
