import { useSelector } from "react-redux";
// import DonutChart from "../DonutChart";
import PiecesSoldPieChart from "../PiecesSoldPieChart";
// import WarehouseMetrics from "../WarehouseMetrics"
import { calculatePercentageChange } from "../../../utils/converter";
import ProductsStats from "../ProductsStats";

const StockAndWarehouseInfo: React.FC<{
  setSelecteKeyValue: any;
  selectedKeyValue: any;
  isFrachisee: boolean;
}> = ({ setSelecteKeyValue, selectedKeyValue, isFrachisee }) => {
  const { dashboard } = useSelector((state: any) => state);

  const totalCurrentQty = dashboard?.pieceSoldStats?.data?.reduce(
    (total: any, item: any) => total + item.currentQuantity,
    0
  );

  const totalComparedQty = dashboard?.pieceSoldStats?.data?.reduce(
    (total: any, item: any) => total + item.comparedQuantity,
    0
  );

  const result = dashboard?.pieceSoldStats?.data?.map((item: any) => {
    return {
      name: item?.name,
      today: parseInt(item?.currentQuantity),
      yesterday: parseInt(item?.comparedQuantity),
      value:
        totalCurrentQty !== 0 &&
        parseInt(
          (
            (parseFloat(item?.currentQuantity) / totalCurrentQty) *
            100
          ).toString()
        ),
      percentage: calculatePercentageChange(
        parseInt(item?.currentQuantity),
        parseInt(item?.comparedQuantity)
      ),
    };
  });
  const percentageChange = calculatePercentageChange(
    totalCurrentQty,
    totalComparedQty
  );

  return (
    <div className="lg:flex w-full xlg:justify-between gap-x-5 mx-auto">
      {/* Piece sold statistics */}
      <PiecesSoldPieChart
        data={result}
        title="Pieces Sold"
        percentageChange={percentageChange}
      />
      {/* product statistics */}
      <ProductsStats
        setSelecteKeyValue={setSelecteKeyValue}
        selectedKeyValue={selectedKeyValue}
        isFrachisee={isFrachisee}
      />
    </div>
  );
};

export default StockAndWarehouseInfo;
