import React from "react";
import { Table, Tag, Button, Dropdown, Modal } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllStockActionByStatus,
  getAllStoclAction,
} from "../../../store/pos/actions";
import { searchValue } from "../../../utils/setColor";
import type { TableColumnsType } from "antd";
import { myPosActions } from "../../../store/pos";
import { useParams } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import type { MenuProps } from "antd";
import ExportCSVFile from "../../buttons/ExportCSVFile";
import { ShopDetailsHeaders } from "../../csvHeaders/Headers";
import AdjustStockForm from "../../forms/AdjustStockForm";
import { getDefaultCurrencyCode } from "../../../utils/helper";

interface DataType {
  [x: string]: any;
  key: React.Key;
  name: string;
  age: number;
  address: string;
  checked: boolean;
}

interface ExpandedDataType {
  key: React.Key;
  specification: string;
  sn: string;
  imei: string;
  status: any;
  data: any;
  price: any;
}

const POSStockTable = (props: any) => {
  const dispatch = useDispatch();
  const { pos, auth, shop, company } = useSelector((state: any) => state);
  const { shopId } = useParams();
  const dataMapper: any[] = [];
  const [openModel, setOpenModel] = React.useState(false);
  const [formData, setformData] = React.useState(dataMapper);
  const [isCheckedStock, setIsCheckedStock] = React.useState<any>({});
  const [isChecked, setIsChecked] = React.useState<any>({});
  const [isCheckedTransfer, setIsCheckedTransfer] = React.useState<any>({});
  const [selectedRowIdStock, setSelectedRowIdStock] =
    React.useState<DataType | null>(null);
  const [selectedRowIdIdle, setSelectedRowIdIdle] =
    React.useState<DataType | null>(null);
  const [selectedRowId, setSelectedRowId] = React.useState<DataType | null>(
    null
  );
  const [selectedRowIdTransfer, setSelectedRowIdTransfer] =
    React.useState<DataType | null>(null);
  const [rowId, setRowId] = React.useState<any>("");
  const [checkedStatus, setCheckedStatus] = React.useState<any>("");
  const [expandedRowKey, setExpandedRowKey] = React.useState<any>(null);
  const [productData, setProductData] = React.useState<any>("");

  const value = pos?.stock?.data;

  const handleCheckboxChangeTransfer = (event: any, record: DataType) => {
    const checked = event.target.checked;
    setIsCheckedTransfer((prevState: any) => ({
      ...prevState,
      [record.id]: checked,
    }));
    if (checked) {
      setIsChecked(false);
      setIsCheckedStock(false);
      setSelectedRowIdTransfer(record);
      setSelectedRowId(null);
      setSelectedRowIdStock(null);
      setSelectedRowIdIdle(null);
      setRowId(record?.id);
      setCheckedStatus("transfer");
      if (expandedRowKey && expandedRowKey !== record.key) {
        setExpandedRowKey(null);
      }
    } else {
      setRowId("");
      setCheckedStatus("");
      setSelectedRowIdTransfer(null);
    }
  };
  const handleCheckboxChangeSold = (event: any, record: DataType) => {
    const checked = event.target.checked;
    setIsChecked((prevState: any) => ({
      ...prevState,
      [record.id]: checked,
    }));
    if (checked) {
      setIsCheckedTransfer(false);
      setIsCheckedStock(false);
      setSelectedRowIdTransfer(null);
      setSelectedRowIdStock(null);
      setSelectedRowIdIdle(null);
      setSelectedRowId(record);
      setRowId(record?.id);
      setCheckedStatus("sold");
      if (expandedRowKey && expandedRowKey !== record.key) {
        setExpandedRowKey(null);
      }
    } else {
      setRowId("");
      setCheckedStatus("");
      setSelectedRowId(null);
    }
  };
  const handleCheckboxChangeStock = (event: any, record: DataType) => {
    const checked = event.target.checked;
    setIsCheckedStock((prevState: any) => ({
      ...prevState,
      [record.id]: checked,
    }));
    if (checked) {
      setIsChecked(false);
      setIsCheckedTransfer(false);
      setSelectedRowIdTransfer(null);
      setSelectedRowId(null);
      setSelectedRowIdIdle(null);
      setSelectedRowIdStock(record);
      setSelectedRowId(null);
      setRowId(record?.id);
      setCheckedStatus("pending");
      if (expandedRowKey && expandedRowKey !== record.key) {
        setExpandedRowKey(null);
      }
    } else {
      setRowId("");
      setCheckedStatus("");
      setSelectedRowIdStock(null);
    }
  };

  const handleCheckboxChangeIdle = (event: any, record: DataType) => {
    const checked = event.target.checked;
    setIsCheckedStock((prevState: any) => ({
      ...prevState,
      [record.id]: checked,
    }));
    if (checked) {
      setIsChecked(false);
      setIsCheckedTransfer(false);
      setSelectedRowIdTransfer(null);
      setSelectedRowId(null);
      setSelectedRowIdStock(null);
      setSelectedRowIdIdle(record);
      setSelectedRowId(null);
      setRowId(record?.id);
      setCheckedStatus("idle");
      if (expandedRowKey && expandedRowKey !== record.key) {
        setExpandedRowKey(null);
      }
    } else {
      setRowId("");
      setCheckedStatus("");
      setSelectedRowIdIdle(null);
    }
  };

  const expandedRowRender = () => {
    const columns: TableColumnsType<ExpandedDataType> = [
      {
        title: "Shop",
        dataIndex: "data",
        key: "data",
        render: (text, record) => (
          <>
            <p>{record?.data?.shop?.name}</p>
            <p>{record?.data?.shop?.channel?.name}</p>
          </>
        ),
      },
      {
        title: "Model",
        dataIndex: "data",
        key: "data",
        render: (text, record) => (
          <>
            <p>
              {record?.data?.requestedItem?.product?.product?.model +
                "~" +
                record?.data?.requestedItem?.product?.product?.type}
            </p>
          </>
        ),
      },
      {
        title: "Descriptions",
        dataIndex: "specification",
        key: "specification",
      },
      { title: "Serial Number", dataIndex: "sn", key: "sn" },
      {
        title: "IMEI",
        dataIndex: "imei",
        key: "imei",
        render: (text, record) => (
          <>
            <p>{record?.data?.wareHouseItem?.imei1 || "N/A"}</p>
            <p>{record?.data?.wareHouseItem?.imei2}</p>
          </>
        ),
      },
      {
        title: `Price(${getDefaultCurrencyCode(company)})`,
        dataIndex: "price",
        key: "price",
      },
      {
        title: "Extended Warranty",
        dataIndex: "data",
        key: "data",
        render: (text, record) => (
          <>
            <p>
              {record?.data?.requestedItem.product?.extendedWarranty || "0"}%
            </p>
          </>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text, record) => (
          <Tag
            color={searchValue(record?.data?.status)}
            className="capitalize min-w-[50px] text-center"
          >
            {record?.data?.status}{" "}
          </Tag>
        ),
      },
    ];

    const productData: ExpandedDataType[] = [];
    pos?.stockByStatus?.data?.forEach((el: any) => {
      productData.push({
        key: el._id,
        sn: el?.wareHouseItem?.serialNumber,
        imei: el?.wareHouseItem?.imei,
        price: el?.requestedItem?.product?.prices
          ?.filter((d: any) => d.isActive === true)
          ?.map((d: any) => d.value)
          ?.toLocaleString(),
        specification: el?.wareHouseItem?.quotationItem?.specification
          ?.map((el: any) => el[1] && `${el[0]}: ${el[1]}`)
          .join(", "),
        status: el?.status,
        data: el,
      });
    });
    if (
      selectedRowIdStock ||
      selectedRowId ||
      selectedRowIdTransfer ||
      selectedRowIdIdle
    ) {
      return (
        <Table columns={columns} dataSource={productData} pagination={false} />
      );
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Model",
      dataIndex: "data",
      render: (text, record) => (
        <p>
          {record?.data?.productSpecs.product.model +
            "~" +
            record?.data?.productSpecs.product.type}
        </p>
      ),
    },
    {
      title: "Descriptions",
      dataIndex: "specification",
    },
    {
      title: "Idle",
      dataIndex: "data",
      key: "select",
      render: (text, record) => (
        <div className="flex items-center gap-4">
          <input
            type="checkbox"
            checked={record === selectedRowIdIdle}
            onChange={(event: any) => handleCheckboxChangeIdle(event, record)}
          />
          <p>
            {record?.data?.countsByStatus?.map(
              (d: any) => d.status?.toLowerCase() === "idle" && d?.count
            )}
          </p>
        </div>
      ),
    },
    {
      title: "Stock In",
      dataIndex: "data",
      key: "select",
      render: (text, record) => (
        <div className="flex items-center gap-4">
          <input
            type="checkbox"
            checked={record === selectedRowIdStock}
            onChange={(event: any) => handleCheckboxChangeStock(event, record)}
          />
          <p>
            {record?.data?.countsByStatus?.map(
              (d: any) => d.status?.toLowerCase() === "pending" && d?.count
            )}
          </p>
        </div>
      ),
    },
    {
      title: "Sold",
      dataIndex: "data",
      render: (text, record) => (
        <div className="flex items-center gap-4">
          <input
            type="checkbox"
            checked={record === selectedRowId}
            onChange={(event: any) => handleCheckboxChangeSold(event, record)}
          />
          <p>
            {record?.data?.countsByStatus?.map(
              (d: any) => d?.status?.toLowerCase() === "sold" && d?.count
            )}
          </p>
        </div>
      ),
    },
    {
      title: "Transfer",
      dataIndex: "data",
      render: (text, record) => (
        <div className="flex items-center gap-4">
          <input
            type="checkbox"
            checked={record === selectedRowIdTransfer}
            onChange={(event: any) =>
              handleCheckboxChangeTransfer(event, record)
            }
          />
          <p>
            {record?.data?.countsByStatus?.map(
              (d: any) => d.status?.toLowerCase() === "transfer" && d.count
            )}
          </p>
        </div>
      ),
    },

    {
      title: "",
      dataIndex: "data",
      render: (text, record) => (
        <>
          <Button onClick={() => showModal(record?.data)}>Adjust Stock</Button>
        </>
      ),
    },
  ];

  React.useEffect(() => {
    if (auth?.token && shopId) {
      getAllStoclAction(auth?.token, `?shop=${shopId}`)(dispatch);
    }
  }, [auth?.token, dispatch, shopId]);

  React.useEffect(() => {
    if (auth?.token && rowId && checkedStatus && shopId) {
      getAllStockActionByStatus(
        auth?.token,
        `?shop=${shopId}&productPriceList=${rowId}&status=${checkedStatus}`
      )(dispatch);
    }
  }, [auth?.token, checkedStatus, dispatch, rowId, shopId]);

  React.useEffect(() => {
    value &&
      value.forEach((el: any) => {
        dataMapper.push({
          id: el._id,
          key: el._id,
          model: el?.productSpecs.product.model,
          specification: `${el?.productSpecs?.specification
            ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
            ?.join(", ")}`,
          price: el?.productSpecs?.prices
            ?.filter((item: any) => item.isActive)
            .map((d: any) => d?.value?.toLocaleString()),
          data: el,
        });
      });

    // Sort dataMapper by model in alphabetical order
    dataMapper.sort((a, b) => a.model.localeCompare(b.model));
    setformData(dataMapper);
  }, [pos?.stock]);

  React.useEffect(() => {
    rowId === "" && dispatch(myPosActions.setStockByStatus(null));
  }, [dispatch, rowId]);

  const handleRowExpand = (expanded: any, record: any) => {
    if (expanded) {
      setExpandedRowKey(record.key);
    } else {
      setExpandedRowKey(null);
    }
  };

  const csvData = pos?.stock?.data?.map((record: any) => ({
    shop: shop?.selected?.name || "N/A",
    type: record.productSpecs?.product?.type || "N/A",
    brand: record.productSpecs?.product?.brand || "N/A",
    model: record.productSpecs?.product?.model || "N/A",
    specification:
      record?.productSpecs?.specification
        ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
        ?.join(", ") || "N/A",
    stock: record?.countsByStatus
      ?.filter((status: any) => status.status === "pending")
      ?.map((status: any) => status.count),
    transfer: record?.countsByStatus
      ?.filter((status: any) => status.status === "transfer")
      ?.map((status: any) => status.count),
    sold: record?.countsByStatus
      ?.filter((status: any) => status.status === "sold")
      ?.map((status: any) => status.count),
  }));

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <ExportCSVFile
          csvHeaders={ShopDetailsHeaders}
          csvData={csvData || []}
          filename={`${shop?.selected?.name}-Details.csv`}
        />
      ),
    },
  ];

  const handleCancelModal = () => {
    setOpenModel(false);
    setProductData("");
  };

  const showModal = (d: any) => {
    setOpenModel(true);
    setProductData(d);
  };

  const [pagination, setPagination] = React.useState({
    pageSize: 15,
    current: 1,
    total: formData.length,
    showSizeChanger: true,
  });

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };

  return (
    <div className="relative">
      <div className="absolute right-0 -top-14">
        <Dropdown menu={{ items }} placement="bottomLeft" arrow>
          <SettingsIcon />
        </Dropdown>
      </div>
      <Table
        columns={columns}
        dataSource={formData}
        loading={pos?.isFetching}
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys: ["0"],
          onExpand: handleRowExpand,
          expandedRowKeys: [expandedRowKey],
        }}
        pagination={pagination}
        onChange={handleTableChange}
      />
      <Modal
        open={openModel}
        onCancel={handleCancelModal}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <AdjustStockForm
          onCancel={handleCancelModal}
          productData={productData}
        />
      </Modal>
    </div>
  );
};

export default POSStockTable;
