import React, { useEffect } from "react";
import { Table, DatePicker, DatePickerProps } from "antd";
import { useSelector, useDispatch } from "react-redux";
import ScrollableFrame from "../../layout/ScrollableFrame";
import { PurchasedHeaders } from "../../csvHeaders/Headers";
import { useMediaQuery } from "@mui/material";
import { getPurchasedProductActions } from "../../../store/inventory/actions";
import dayjs from "dayjs";
import DetailsHeaderActionBar from "../../cards/DetailsPage/DetailsHeaderActionBar";
import moment from "moment";
import FilesCard from "../../cards/NoSale/FilesCard";
import CSVExportButton from "../../cards/NoSale/CSVExportButton";
import { VscFilePdf } from "react-icons/vsc";
import { getAllPurchased_CSVAction } from "../../../store/csvDownload/actions";
import { getDefaultCurrencyCode } from "../../../utils/helper";

const PurchasedTable = (props: any) => {
  const isTablet = useMediaQuery("(max-width: 960px)");
  const { auth, inventory, layout, CSV, company } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);

  let today = moment();
  const formattedDate = new Date().toISOString().slice(0, 10);
  let previousDate = today.subtract(7, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");
  const [startDate, setStartDate] = React.useState<any>(formattedPreviousDate);
  const [endDate, setEndDate] = React.useState<any>(formattedDate);

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
      key: "supplier",
      render: (record: any) => <p>{"N/A"}</p>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Specs",
      dataIndex: "specs",
      key: "specs",
    },
    {
      title: "Quantity Received",
      dataIndex: "quantity",
      key: "quantity",
      render: (text: any, record: any) => (
        <p>{parseInt(text)?.toLocaleString()} </p>
      ),
    },
    {
      title: `Unit Price (${getDefaultCurrencyCode(company)})`,
      dataIndex: "amount",
      key: "amount",
      render: (text: any, record: any) => (
        <p>{parseInt(text)?.toLocaleString()} </p>
      ),
    },
  ];

  useEffect(() => {
    getPurchasedProductActions(
      auth.token,
      `startDate=${startDate}&endDate=${endDate}&limit=${limit}&page=${
        page - 1
      }`
    )(dispatch);
  }, [auth.token, limit, page, dispatch, startDate, endDate]);

  useEffect(() => {
    if (layout?.openCSVButton)
      auth?.token &&
        getAllPurchased_CSVAction(
          auth?.token,
          `startDate=${startDate}&endDate=${endDate}&limit=${inventory?.purchase?.total}&page=0`
        )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  const dataToMap = inventory?.purchase?.data?.map((d: any) => {
    return {
      date: d?.createdAt?.slice(0, 10),
      supplier: d?.po?.supplier?.name,
      type: d?.purchaseItem?.quotationItem?.product?.type,
      brand: d?.purchaseItem?.quotationItem?.product?.brand,
      model: d?.purchaseItem?.quotationItem?.product?.model,
      specs: d?.purchaseItem?.quotationItem?.specification
        ?.slice(2.6)
        ?.map((spec: any) => spec[1] && `${spec[0]}:${spec[1]}`),
      amount: d?.purchaseItem?.priceUnit,
      quantity: d?.qtyReceived,
      status: d?.status,
      data: d,
    };
  });

  const dataToDownload = CSV?.csvDownloadedData?.data?.map((d: any) => {
    return {
      date: d?.createdAt?.slice(0, 10),
      supplier: d?.po?.supplier?.name,
      type: d?.purchaseItem?.quotationItem?.product?.type,
      brand: d?.purchaseItem?.quotationItem?.product?.brand,
      model: d?.purchaseItem?.quotationItem?.product?.model,
      specs: d?.purchaseItem?.quotationItem?.specification
        ?.slice(2.6)
        ?.map((spec: any) => spec[1] && `${spec[0]}:${spec[1]}`),
      amount: d?.purchaseItem?.priceUnit,
      quantity: d?.qtyReceived,
      status: d?.status,
      data: d,
    };
  });

  const csvData = dataToDownload?.map((record: any) => ({
    date: record.date,
    supplier: record.supplier || "N/A",
    type: record.type || "N/A",
    brand: record.brand,
    model: record.model,
    amount: parseInt(record.amount)?.toLocaleString(),
    quantity: parseInt(record.quantity),
    total: (
      parseInt(record.amount) * parseInt(record.quantity)
    )?.toLocaleString(),
    storage: record?.data?.purchaseItem?.quotationItem?.specification
      .filter((item: any) => item[0]?.toLowerCase() === "capacity (rom)")
      .map((item: any) => item[1]),
    memory: record?.data?.purchaseItem?.quotationItem?.specification
      .filter((item: any) => item[0]?.toLowerCase() === "memory (ram)")
      .map((item: any) => item[1]),
  }));

  const onChangeDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setStartDate(dateString);
  };

  const onChangeEndDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setEndDate(dateString);
  };

  const disabledDate = (current: any) => {
    if (current && current > moment().endOf("day")) {
      return true;
    }
    return false;
  };

  return (
    <div className="relative text-gray-900">
      <div className="flex items-center justify-between mt-4">
        <DetailsHeaderActionBar pageName="Finace" title="Purchase" />
        <div className="space-x-6 flex">
          <div className="flex items-center space-x-2">
            <span>From</span>
            <DatePicker
              onChange={onChangeDate}
              defaultValue={dayjs().subtract(7, "day")}
              style={{ width: 120 }}
              disabledDate={disabledDate}
            />
          </div>
          <div className="flex items-center space-x-2">
            <span>To</span>
            <DatePicker
              onChange={onChangeEndDate}
              defaultValue={dayjs()}
              style={{ width: 120 }}
              disabledDate={disabledDate}
            />
          </div>
        </div>
      </div>
      <div className="flex items-end justify-end mr-4 mt-2 mb-2">
        {["admin", "finance", "finance-manager", "dev"].includes(
          auth?.user?.role?.toLowerCase()
        ) && (
          <div className="flex justify-end gap-4 p-4">
            <FilesCard
              Icon={VscFilePdf}
              tailwindTextColor="text-white"
              tailwindBackgroundColor="bg-orange-400"
              disabled
            />
            <CSVExportButton
              csvHeaders={PurchasedHeaders}
              csvData={csvData ? csvData : ""}
              filename={`Purchase-from-${startDate + "-to-" + endDate}.csv`}
            />
          </div>
        )}
      </div>
      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={inventory?.purchase?.total}
        count={Math.ceil(inventory?.purchase?.total / limit)}
      >
        <Table
          columns={columns}
          dataSource={dataToMap}
          pagination={false}
          style={{ backgroundColor: "white" }}
          scroll={isTablet ? { x: 1300 } : undefined}
          loading={inventory?.isFetching}
        />
      </ScrollableFrame>
    </div>
  );
};

export default PurchasedTable;
