import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Table, Progress } from "antd";
import { Select } from "antd";
import { calculatePercentageChange } from "../../utils/converter";
import { getDefaultCurrencyCode } from "../../utils/helper";
import { getAllRegionAction } from "../../store/channel/actions";

const { Option } = Select;

const Shops: React.FC<{
  title: string;
  setSelecteShopRegionKey: any;
  selectedShopRegionKey: any;
  enteredDate: any;
  compareDate: any;
}> = ({ title, setSelecteShopRegionKey, enteredDate, compareDate }) => {
  const { dashboard, company, auth, channel } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const data =
    dashboard?.shopStats &&
    dashboard?.shopStats?.data?.map((d: any) => {
      return {
        name: d?.shopName,
        revenue: d?.currentDateRevenue,
        comparedRevenue: d?.comparedDateRevenue,
        quantity: d?.currentDateQuantity,
        comparedQuantity: d?.comparedDateQuantity,
        percentage: calculatePercentageChange(
          parseInt(d?.currentDateRevenue),
          parseInt(d?.comparedDateRevenue)
        ),
        perc:
          (d?.currentDateRevenue * 100) /
          (d?.currentDateRevenue + d?.comparedDateRevenue),
      };
    });

  const totalCurrentRevenue = dashboard?.shopStats?.data?.reduce(
    (total: any, item: any) => total + item.currentDateRevenue,
    0
  );

  const totalComparedRevenue = dashboard?.shopStats?.data?.reduce(
    (total: any, item: any) => total + item?.comparedDateRevenue,
    0
  );

  const columns = [
    {
      title: "",
      dataIndex: "perc",
      key: "perc",
      width: 50,
      render: (text: any) => (
        <div className="">
          <Progress type="dashboard" percent={text.toFixed(1)} size={20} />
        </div>
      ),
    },
    {
      title: "Shop",
      dataIndex: "name",
      key: "name",
      render: (text: string) => <div className="">{text}</div>,
    },
    {
      title: `Quantity Sold On ${compareDate}`,
      dataIndex: "comparedQuantity",
      key: "comparedQuantity",
    },
    {
      title: `Quantity Sold On ${enteredDate}`,
      dataIndex: "quantity",
      key: "quantity",
      render: (text: any) => <div className="font-bold">{text}</div>,
    },
    {
      title: `Revenue(${getDefaultCurrencyCode(company)}) ${compareDate}`,
      dataIndex: "comparedRevenue",
      key: "comparedRevenue",
      render: (text: string) => (
        <div className=" w-full truncate">{text?.toLocaleString()}</div>
      ),
    },
    {
      title: `Revenue(${getDefaultCurrencyCode(company)}) ${enteredDate}`,
      dataIndex: "revenue",
      key: "revenue",
      render: (text: string) => (
        <div className=" w-full truncate font-bold">
          {text?.toLocaleString()}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "percentage",
      key: "percentage",
      render: (text: string, record: any) => {
        return (
          <span
            className={`flex ${record?.percentage?.type === "increase"
              ? "text-green-500"
              : record?.percentage?.type === "decrease"
                ? "text-red-500"
                : "text-blue-900"
              } text-sm`}
          >
            {record?.percentage?.type === "increase" ? (
              <ArrowUpwardIcon />
            ) : record?.percentage?.type === "decrease" ? (
              <ArrowDownwardIcon />
            ) : (
              ""
            )}{" "}
            {`${record?.percentage?.percentage}%`}
          </span>
        );
      },
    },
  ];
  const handleSelectShopRegion = (value: any) => {
    setSelecteShopRegionKey(value);
  };

  const percentageChange = calculatePercentageChange(
    totalCurrentRevenue,
    totalComparedRevenue
  );
  useEffect(() => {
    auth?.token && getAllRegionAction(auth?.token, ``)(dispatch);
  }, [dispatch, auth?.token]);

  return (
    <div className="bg-white mt-5 mr-4 p-4 xl:h-[400px] rounded-lg border border-gray-300">
      <div className="sm:flex justify-between items-center">
        <div className="flex items-center justify-between sm:space-x-2">
          <h2 className="text-xl capitalize">{title}</h2>
          <Select
            placeholder="Filter by Region"
            allowClear
            style={{ width: 150 }}
            onChange={(value) => {
              if (value === undefined) {
                handleSelectShopRegion("");
              } else {
                handleSelectShopRegion(value);
              }
            }}
          >
            {channel?.allRegion?.data?.map((d: any, index: any) => (
              <Option value={d?.name} key={index}>
                {d?.name}
              </Option>
            ))}
          </Select>
        </div>
        <div
          className={`${percentageChange?.type === "increase"
            ? "bg-green-200"
            : percentageChange?.type === "decrease"
              ? "bg-red-200"
              : "bg-blue-200"
            } rounded-lg px-3 py-1 sm:float-left float-right sm:mt-0 mt-4`}
        >
          <span
            className={`${percentageChange?.type === "increase"
              ? "text-green-500"
              : percentageChange?.type === "decrease"
                ? "text-red-500"
                : "text-blue-900"
              }
             flex items-center py-0.5`}
          >
            {percentageChange?.type === "increase" ? (
              <ArrowUpwardIcon />
            ) : percentageChange?.type === "decrease" ? (
              <ArrowDownwardIcon />
            ) : (
              ""
            )}

            {`${percentageChange?.percentage}%`}
          </span>
        </div>
      </div>
      <div className="mt-2 sm:flex items-center justify-between">
        <div className="">
          <span className="mr-4 ">SC: Service Center</span>
          <span className="block mt-2 sm:inline-block sm:mt-0">
            CS: Connect Shop
          </span>
        </div>
        <div className="flex sm:mt-0 mt-4 items-center">
          <div className="h-3 w-3 bg-blue-500 mr-1.5"></div>
          <span className="mr-4">Today</span>
          <div className="h-3 w-3 bg-gray-400 mr-1.5"></div>{" "}
          <span>Yesterday</span>
        </div>
      </div>

      <div className="flex-1 mt-4">
        <div className="h-fit overflow-y-auto">
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            loading={dashboard?.shopStatsIsFetching}
            className="table-auto"
            sticky
            scroll={{ y: 250 }}
          />
        </div>
      </div>
    </div>
  );
};

export default Shops;
