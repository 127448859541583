import React from 'react';
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays,
} from 'date-fns';

const DefinedRanges = () => {
  const startOfToday = startOfDay(new Date());
  const endOfToday = endOfDay(new Date());
  const startOfYesterday = startOfDay(addDays(new Date(), -1));
  const endOfYesterday = endOfDay(addDays(new Date(), -1));
  const startOfWeekDate = startOfWeek(new Date());
  const endOfWeekDate = endOfWeek(new Date());
  const startOfLastWeek = startOfWeek(addDays(new Date(), -7));
  const endOfLastWeek = endOfWeek(addDays(new Date(), -7));
  const startOfMonthDate = startOfMonth(new Date());
  const endOfMonthDate = endOfMonth(new Date());
  const startOfLastMonth = startOfMonth(addMonths(new Date(), -1));
  const endOfLastMonth = endOfMonth(addMonths(new Date(), -1));

  return {
    startOfToday,
    endOfToday,
    startOfYesterday,
    endOfYesterday,
    startOfWeekDate,
    endOfWeekDate,
    startOfLastWeek,
    endOfLastWeek,
    startOfMonthDate,
    endOfMonthDate,
    startOfLastMonth,
    endOfLastMonth,
  };
};

const StaticRangeHandler = {
  isSelected(range:any) {
    const definedRange = DefinedRanges();
    return (
      isSameDay(range.startDate, definedRange.startOfToday) &&
      isSameDay(range.endDate, definedRange.endOfToday)
    );
  },
};

const createStaticRanges = (ranges:any) => {
  return ranges.map((range:any) => ({ ...StaticRangeHandler, ...range }));
};

export const DefaultStaticRanges = createStaticRanges([
  {
    label: 'Today',
    range: () => ({
      startDate: DefinedRanges().startOfToday,
      endDate: DefinedRanges().endOfToday,
    }),
  },
  {
    label: 'Yesterday',
    range: () => ({
      startDate: DefinedRanges().startOfYesterday,
      endDate: DefinedRanges().endOfYesterday,
    }),
  },
  {
    label: 'This Week',
    range: () => ({
      startDate: DefinedRanges().startOfWeekDate,
      endDate: DefinedRanges().endOfWeekDate,
    }),
  },
  {
    label: 'Last Week',
    range: () => ({
      startDate: DefinedRanges().startOfLastWeek,
      endDate: DefinedRanges().endOfLastWeek,
    }),
  },
  {
    label: 'This Month',
    range: () => ({
      startDate: DefinedRanges().startOfMonthDate,
      endDate: DefinedRanges().endOfMonthDate,
    }),
  },
  {
    label: 'Last Month',
    range: () => ({
      startDate: DefinedRanges().startOfLastMonth,
      endDate: DefinedRanges().endOfLastMonth,
    }),
  },
]);
