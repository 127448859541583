import React, { useState, useEffect } from "react";
import { Skeleton } from "antd";
import { getAllPackageTrackAction } from "../../store/delivery/actions";
import { useDispatch, useSelector } from "react-redux";
import ShipmentTrackingList from "../ShipmentTrackingList";
import { SearchIcon } from "lucide-react";

interface PendingDeliveriesProps {
  onSelectDelivery: (packageId: string) => void;
  onSelectTrackingId: (trackingId: string) => void;
  isFromMe: any;
  setIsFromMe: any;
}

const PendingDeliveries: React.FC<PendingDeliveriesProps> = ({
  onSelectDelivery,
  onSelectTrackingId,
  isFromMe,
  setIsFromMe,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { auth, tracking } = useSelector((state: any) => state);
  const [activeTab, setActiveTab] = useState("All");
  const dispatch = useDispatch();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (auth.token) {
      setIsLoading(true);

      getAllPackageTrackAction(
        auth.token,
        "?"
      )(dispatch).finally(() => {
        setIsLoading(false);
      });
    }
  }, [auth.token, dispatch]);

  const trackingStatuses = Array.from(
    new Set(
      tracking?.all?.data.map(
        (tab: { trackingStatus: any }) => tab?.trackingStatus
      )
    )
  );

  return (
    <div className="p-4 mx-auto text-black">
      <div className="mb-6 relative items-center flex flex-row gap-2  max-w-md mx-auto">
        <input
          type="text"
          className="border border-gray-300 rounded-md py-2 pl-10 pr-4 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white text-black"
          placeholder="Search..."
          onChange={handleSearch}
          value={searchTerm}
        />
        <SearchIcon className="w-5 h-5 text-gray-400 absolute right-3" />
      </div>
      <div className="mb-6 relative items-center flex flex-row gap-2  max-w-md mx-auto text-black">
        <div className="flex justify-between mb-4  w-[100%]">
          <span
            className={`cursor-pointer font-poppins ${
              isFromMe ? "text-indigo-600 underline" : "text-gray-400"
            }`}
            onClick={() => setIsFromMe(true)}
          >
            From Me
          </span>
          <span
            className={`cursor-pointer font-poppins ${
              !isFromMe ? "text-indigo-600 underline" : "text-gray-400"
            }`}
            onClick={() => setIsFromMe(false)}
          >
            To Me
          </span>
        </div>
      </div>
      <div className="mb-6 relative flex flex-wrap gap-2  max-w-md mx-auto p-2">
        {["All", ...trackingStatuses].map((status: any) => (
          <button
            key={status}
            className={`px-4 py-2 rounded-md border-2 border-[#605BFF] text-xs w-30 font-poppins ${
              activeTab === status
                ? "bg-indigo-600 text-white"
                : "bg-white text-indigo-600"
            }`}
            onClick={() => setActiveTab(status)}
          >
            {status}
          </button>
        ))}
      </div>
      {isLoading ? (
        <Skeleton active />
      ) : (
      <div className='h-screen overflow-y-scroll'>
        <ShipmentTrackingList
          searchTerm={searchTerm}
          onSelectTrackingId={onSelectTrackingId}
          isFromMe={isFromMe}
          activeTab={activeTab}
        /></div>
      )}
    </div>
  );
};

export default PendingDeliveries;

