import { Link } from "react-router-dom";
import { Carousel, Space } from "antd";
import { searchValue } from "../../../utils/setColor";
import defaultPic from "../../../assets/nophoto.png";

const ProductModelCard = (props: any) => {
  return (
    <>
      <div className="relative w-[530px] h-min p-4 pt-6 bg-white text-[#030229] font-normal rounded-[10px] border-l-2 border-[#605BFF] shadow-[1px_3px_16px_0px_rgba(0,0,0,0.07)]">
        {/* <Link to={`/inventory/ws/stock/${props?.product?._id}/list`}> */}
        <div className="flex w-fit h-max">
          <div className="absolute top-1 right-0 bg-[#49CB56] px-5 py-1 rounded-l-lg font-medium text-white">
            Available
          </div>
          <div className="w-[140px] h-[170px] bg-[#F4F6FA] rounded-[10px] p-3 border-[.1px] border-[#D9DBE9] border-opacity-20">
            {props?.product?.images?.length === 0 && (
              <img src={defaultPic} width={"100%"} alt="" />
            )}
            {props?.product?.images?.length > 0 && (
              <Carousel autoplay>
                {props?.product?.images?.map((im: any) => (
                  <div className="block w-[70px] h-[140px]">
                    <img
                      src={im}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  </div>
                ))}
              </Carousel>
            )}
          </div>
          <div className="flex flex-col pl-2 ml-3">
            <h1 className="font-medium mb-2">{props?.product?.model?.toUpperCase()}</h1>
            <div className=" gap-1">
              <div className="grid grid-cols-2 gap-2">
                {props?.data &&
                  props?.data?._id
                    // ?.slice(0, 2)
                    ?.map(
                      (data: any, _index: number) =>
                        data[1] && (
                          <>
                            <p className="text-xs">
                              <p className="font-medium">{data[0]} : </p>
                            </p>
                            <p className="text-xs">{data[1]}</p>
                          </>
                        )
                    )}
              </div>
            </div>
            <div className="flex justify-center py-4 gap-x-4 font-medium">
              {props?.data?.statuses?.map((item: any, _index: number) => (
                <p className={`text-[${searchValue(item?.status)}]`}>
                  <Space className="capitalize">{`${item?.status?.toLowerCase() === "in"
                    ? "In Warehouse "
                    : item?.status?.toLowerCase() === "out"
                      ? "In Shops"
                      : "Sold"
                    } : `}</Space>

                  {item?.count}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProductModelCard;
