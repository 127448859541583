import React, { useState } from "react";
import { Select, Button, Space } from "antd";
import { IoFilterOutline } from "react-icons/io5";

const VaultFilter = (props: any) => {
  const [selectedFilters, setSelectedFilters] = useState(false);
  const [serviceDetails, setServiceDetails] = useState("");

  const resetFilter = () => {
    if (selectedFilters) {
      setServiceDetails("");
    }
  };
  return (
    <>
      <div>
        <Space>
          <Button
            className={`${selectedFilters ? "text-[#605BFF] bg-blue-200" : ""}`}
            icon={<IoFilterOutline />}
            onClick={() => {
              setSelectedFilters(!selectedFilters);
              resetFilter();
            }}
          >
            filter
          </Button>

          {selectedFilters && (
            <Select
              defaultValue="Filter By Service"
              allowClear
              style={{ width: 180 }}
              filterOption={(input, option) =>
                (option?.label ?? "").toString().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")

                  .toString()
                  .toLowerCase()
                  .localeCompare(
                    (optionB?.label ?? "").toString().toLowerCase()
                  )
              }
              options={[
                { value: " ", label: "All Devices" },
                { value: "PREPAID", label: "Prepaid Devices" },
                { value: "POSTPAID", label: "Postpaid Devices" },
              ]}
              onChange={(value: any) => {
                !value && setServiceDetails("");
                value && props?.setServiceDetails(value);
              }}
            />
          )}
        </Space>
      </div>
    </>
  );
};
export default VaultFilter;
