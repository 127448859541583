import React from "react";

const ProfileSkeleton = () => {
  return (
    <div className="h-52 mt-6 ml-2 rounded-md p-4 w-full mx-auto">
      <div className="animate-pulse flex justify-between space-x-10 mx-auto items-center h-full">
        <div className="rounded-full bg-gray-200 h-32 w-32"></div>
        <div className="flex-1 space-y-6 py-1">
          <div className="h-4 w-72 bg-gray-200 rounded"></div>
          <div className="space-y-3">
            <div className="h-4 w-64 bg-gray-200 rounded"></div>
            <div className="h-4 w-72 bg-gray-200 rounded"></div>
            <div className="h-4 w-52 bg-gray-200 rounded"></div>
            <div className="h-4 w-44 bg-gray-200 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSkeleton;
