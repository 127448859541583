import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { DefaultStaticRanges } from "../channels/defaultRanges";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useDispatch, useSelector } from "react-redux";
import "../channels/styles.css";
import { getCustomersByDateAction } from "../../../store/customer/actions";
import { enUS } from 'date-fns/locale';


interface DateRange {
  startDate: Date;
  endDate: Date;
}

interface DateRangeSelectorProps {
  page: any;
  limit: any;
  ranges?: any[];
  onSubmit?: (selectedRange: DateRange) => void;
}

const DateRangeCustomer: React.FC<DateRangeSelectorProps> = ({
  page,
  limit,
  ranges = DefaultStaticRanges,
  onSubmit,
}) => {
  const { auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const startDates = new Date(selectedDateRange?.startDate)
    .toISOString()
    .split("T")[0];
  const endDates = new Date(selectedDateRange?.endDate)
    .toISOString()
    .split("T")[0];

  const handleSelect = (ranges: any) => {
    setSelectedDateRange(ranges.selection);
  };

  useEffect(() => {
    auth?.token &&
      getCustomersByDateAction(
        auth?.token,
        `?page=${
          page - 1
        }&limit=${limit}&startDate=${startDates}&endDate=${endDates}`
      )(dispatch);
  }, [startDates, endDates, auth?.token, dispatch, limit, page]);

  return (
    <>
      <div className="shadow">
        <DateRangePicker
          onChange={handleSelect}
          showPreview
          moveRangeOnFirstSelection={false}
          months={1}
          ranges={[selectedDateRange]}
          direction="vertical"
          locale={enUS}
        />
      </div>
    </>
  );
};
export default DateRangeCustomer;
