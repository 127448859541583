import React from "react";
import GradientChart from "../graphy/GradientChart";
import { useSelector } from "react-redux";
const CreditReportCard = () => {
  const { layout} = useSelector((state: any) => state);
  const styles = {
    customShadow: {
      boxShadow: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)",
    },
    border: {
      border: "0px 1px 0px 0px solid rgba(3, 2, 41, 0.04)",
    },
  };
  return (
    <div
      className={`bordedr borderd-[#0302290A] bg-white p-5 w-full ${layout?.isSideNavOpen ? " 2xl:w-[640px]":" 2xl:w-[700px]"} rounded-md px-7 h-full`}
      style={styles.customShadow}
    >
      <div className="md:flex justify-between">
        <h1 className="text-[#030229] text-base font-medium">Credit Report</h1>
        <button className="text-[#605BFF] text-base">See more</button>
      </div>
      <div className="sm:flex gap-5 mb-5 mt-5">
        <div className="w-[400px]">
          <GradientChart />
        </div>
        <div className="w-full mt-3">
          <p className="text-[#030229]  text-xl font-medium">RWF 1,000,000</p>
          <p className="text-[#030229] text-base pt-1">Eligible Amount</p>
          <p className="text-[#030229]  text-xl font-medium pt-2">12 Months</p>
          <p className="text-[#030229] text-base pt-1">Plans</p>
          <p className="text-[#030229]  text-xl font-medium pt-2">2024-9-16</p>
          <p className="text-[#030229] text-base pt-1">
            Last credit score date
          </p>
        </div>
      </div>
    </div>
  );
};

export default CreditReportCard;
