import React, { ReactNode } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useSelector } from "react-redux";
type btnProps = {
  cardIcon?: any;
  value?: any;
  status?: any;
  percentage?: any;
  border?:any;
  customeIcon?:ReactNode;
};
const DevicesSummary = ({ cardIcon, value, status, percentage,customeIcon ,border}: btnProps) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const { invoice } = useSelector((state: any) => state);
  return (
    <div>
        <div className="flex gap-8 mx-8 my-4">
           <div className={`border mt-2 border-${border} pl-2 pt-2 rounded-full  w-[42px] h-[42px]`}>
                    {customeIcon}
                </div>
          <span className=" flex flex-col justify-center content-center mt-2 ">
            <div className="text-[#000] font-bold text-xl">
              {invoice.isFetching ? <Spin indicator={antIcon} /> : value}
            </div>
            <div className="text-[#030229] text-ellipsis font-thin text-sm opacity-70">
              {status}
            </div>
          </span>
        </div>
      </div>
  );
};
export default DevicesSummary;
