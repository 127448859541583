import React, { useState } from "react";
import { useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { Select, Spin, Button, Space } from "antd";
import { IoFilterOutline } from "react-icons/io5";

const SubAccountFilter = (props: any) => {
  const { account } = useSelector((state: any) => state);
  const [selectedFilters, setSelectedFilters] = useState(false);
  const { Option } = Select;

  const resetFilter = () => {
    if (selectedFilters) {
      props?.setSelectedStatus("");
    }
  };

  return (
    <div className="pl-4 pr-4 pt-2 pb-2 text-[#030229] opacity-90">
      <div className="flex justify-between items-center space-x-2">
        <Button
          className="font-medium "
          style={{ color: selectedFilters ? "#605BFF" : "", borderColor: selectedFilters ? "#605BFF" : "" }}
          onClick={() => {
            setSelectedFilters(!selectedFilters);
            resetFilter();
          }}
        >
          <Space>
            <IoFilterOutline size={18} /> <p>Filter</p>
          </Space>
        </Button>

        {selectedFilters && (
          <div className="flex justify-between items-center space-x-2">
            <Select
              size="middle"
              showSearch
              style={{ width: 200, fontSize: "4px" }}
              allowClear
              placeholder="Filter by status"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").toString().includes(input)
              }
              loading={account.isFetching}
              disabled={account.isFetching}
              onClear={() => resetFilter()}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")

                  .toString()
                  .toLowerCase()
                  .localeCompare(
                    (optionB?.label ?? "").toString().toLowerCase()
                  )
              }
              onChange={(value: any) => {
                !value && props?.setSelectedStatus("");
                value && props?.setSelectedStatus(value);
              }}
            >
              {["active", "inactive"].map((data: any) => (
                <Option value={data} label={data}>
                  {data}
                </Option>
              ))}
            </Select>
          </div>
        )}
        {/* 
        <p className="text-[#030229] opacity-70 text-sm">
          Result:{" "}
          {account?.isFetching ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          ) : (
            account?.all?.total
          )}
        </p> */}
      </div>
    </div>
  );
};
export default SubAccountFilter;
