import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Typography } from "antd";
import { Select } from "antd";
import { getAllRegionAction } from "../../store/channel/actions";
const { Option } = Select;

const { Text } = Typography;
const IkosoraStock: React.FC<{
  title: string;
  setSelecteShopRegionKey: any;
  setSelecteBrandedRegionKey: any;
  enteredDate: any;
  compareDate: any;
}> = ({ title, setSelecteBrandedRegionKey }) => {
  const { dashboard, auth, channel } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const data: [
    { tReceived: number; soldOut: number; inShop: number; name: string }
  ] =
    dashboard?.brandedBrandStat &&
    dashboard?.brandedBrandStat?.data?.shops?.map((d: any) => {
      return {
        name: d?.shopName,
        tReceived: d?.totalReceived,
        soldOut: d?.soldCount,
        inShop: d?.pendingCount,
      };
    });

  const columns = [
    {
      title: "Shop",
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (text: string) => <div className="">{text}</div>,
    },
    {
      title: "Total Received",
      dataIndex: "tReceived",
      key: "tReceived",
    },
    {
      title: "Sold Out",
      dataIndex: "soldOut",
      key: "soldOut",
    },
    {
      title: "In Shops",
      dataIndex: "inShop",
      key: "inShop",
    },
  ];

  const handleSelectShopRegion = (value: any) => {
    setSelecteBrandedRegionKey(value);
  };
  useEffect(() => {
    auth?.token && getAllRegionAction(auth?.token, ``)(dispatch);
  }, [dispatch, auth?.token]);

  return (
    <div className="bg-white my-4 mr-4 p-4 xl:h-[380px] rounded-lg border border-gray-300">
      <div className="sm:flex justify-between items-center w-full">
        <div className="flex items-center justify-between sm:space-x-2">
          <h2 className="text-xl capitalize">{title}</h2>
          <Select
            placeholder="Filter by Region"
            allowClear
            style={{ width: 150 }}
            onChange={(value) => {
              if (value === undefined) {
                handleSelectShopRegion("");
              } else {
                handleSelectShopRegion(value);
              }
            }}
          >
            {channel?.allRegion?.data?.map((d: any, index: any) => (
              <Option value={d?.name} key={index}>
                {d?.name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="mt-2 sm:flex items-center justify-between">
        <div className="pt-2">
          <span className="mr-4 ">SC: Service Center</span>
          <span className="block mt-2 sm:inline-block sm:mt-0">
            CS: Connect Shop
          </span>
        </div>
      </div>

      <div className="flex-1 mt-4">
        <div className="h-fit overflow-y-auto">
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            loading={dashboard?.brandedBrandStockIsFetching}
            className="table-auto"
            sticky
            scroll={{ y: 250 }}
            summary={(pageData) => {
              let totalReceived = 0;
              let totalSoldOut = 0;
              let totalInshop = 0;

              pageData.forEach((dd) => {
                totalReceived += dd?.tReceived;
                totalSoldOut += dd?.soldOut;
                totalInshop += dd?.inShop;
              });

              return (
                <>
                  <Table.Summary.Row className="!text-[#2E6C8E] bg-white sticky bottom-0">
                    <Table.Summary.Cell index={0}>
                      <Text style={{ fontWeight: "bolder" }}>Total</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={0}>
                      <Text style={{ fontWeight: "bolder" }}>
                        {totalReceived.toLocaleString()}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={0}>
                      <Text style={{ fontWeight: "bolder" }}>
                        {totalSoldOut.toLocaleString()}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={0}>
                      <Text style={{ fontWeight: 300 }}>
                        {totalInshop.toLocaleString()}
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default IkosoraStock;
