import React, { useEffect, useState } from "react";
import {
  Drawer,
  Form,
  Popconfirm,
  Table,
  notification,
  Button,
  Steps,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import AddItem from "./AddItems";
import {
  createOrderAction,
  updateOneAccountAction,
} from "../../../../store/account/actions";
import { getPricelistItemAction } from "../../../../store/channel/actions";
import {
  deleteSubsorderItemAction,
  getAllSubscriptionOrdersAction,
  getOneSubsOrderAction,
  submitSubOrdersAction,
} from "../../../../store/subscription/order/actions";
import { IconButton } from "@mui/material";
import { myChannelActions } from "../../../../store/channel";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  handleFoundCustomerByPhone,
  handleNewCustomer,
  handlerUpdateAccountAction,
  handleSetSubscriptionDealSteps,
} from "../../../../store/layout/actions";
import CustomerProfile from "./component/CustomerProfile";
import { getActivePrice } from "../../../../utils/converter";
import AssigneeUser from "./component/AssigneeUser";
import ExpandableButton from "../../../../components/buttons/ExpandableButton";
import CustomerPreview from "./component/CustomerPreview";
import { getAllUsersAction } from "../../../../store/setting/actions";
import FormSkeleton from "../../../../components/skeleton/FormSkeleton";
import { getDefaultCurrencyCode } from "../../../../utils/helper";

interface FormData {
  name: string;
  url: string;
  owner: string;
  type: string;
  approver: string;
  // dateTime: Moment | null;
  description: string;
}

const CreateOrder = (props: any) => {
  const [form] = Form.useForm<FormData>();
  const dispatch = useDispatch();
  const { orders, auth, layout, order, account, company } = useSelector(
    (state: any) => state
  );
  const [channelId, setChannelId] = useState("");
  const [assignee, setAssignee] = useState([]);

  const initialState = {
    comments: "",
    notes: "",
  };
  const [assignData, setAssignData] = useState(initialState);

  const orderId = orders?.new?.data?._id ?? orders?.selected?._id;

  useEffect(() => {
    if (orders?.selected?.account?.channel?._id) {
      auth?.token &&
        getPricelistItemAction(
          auth?.token,
          `?channel=${orders?.selected?.account?.channel?._id}&hasSubs=true`
        )(dispatch);
    } else {
      dispatch(myChannelActions.setPriceListItems(null));
    }
  }, [auth?.token, dispatch, orders?.selected?.account?.channel?._id]);

  useEffect(() => {
    if (auth?.token) {
      getAllUsersAction(auth?.token, `?page=0&limit=100`)(dispatch);
    }
  }, [auth?.token, dispatch]);

  const columns = [
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Specification",
      dataIndex: "specification",
      key: "specification",
      width: 200,
    },
    {
      title: "Plan Details",
      dataIndex: "plan",
      key: "plan",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: `Unit Price (${getDefaultCurrencyCode(company)})`,
      dataIndex: "price",
      key: "price",
    },
    {
      title: `Amount (${getDefaultCurrencyCode(company)})`,
      dataIndex: "amount",
      key: "amount",
      render: (text: number, _record: any) => <p>{text?.toLocaleString()}</p>,
    },
    {
      title: "",
      width: 30,
      render: (text: any, record: any) => {
        return (
          <Popconfirm
            placement="topRight"
            title={`Do you really want to remove`}
            description={`${record?.model} from the list?`}
            okText="Yes"
            cancelText="No"
            onConfirm={async () =>
              await deleteSubsorderItemAction(
                auth.token,
                record.key,
                orderId
              )(dispatch)
            }
          >
            <IconButton aria-label="delete" size="small">
              <DeleteIcon style={{ color: "red" }} fontSize="inherit" />
            </IconButton>
          </Popconfirm>
        );
      },
    },
  ];

  const formData = orders?.selected?.list?.map((el: any) => {
    return {
      key: el?._id,
      model: el?.product?.product?.model,
      price: el?.amount,
      specification: `${el?.product?.specification
        ?.slice(2, 6)
        ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
        ?.join(", ")}`,
      quantity: el?.quantity,
      amount: el?.quantity * el?.amount,
      data: el,
    };
  });

  const totalAmount = formData?.reduce(
    (curr: any, item: any) => curr + item?.amount,
    0
  );

  const handleSubmit = async () => {
    if (!orders?.selected?.account?.duration) {
      return notification.error({ message: "Please Select Duration" });
    }

    if (!orders?.selected?.account?.planType) {
      return notification.error({ message: "Please Select Plan Type" });
    }

    if (orderId && auth?.token) {
      const response = await submitSubOrdersAction(
        orderId,
        { status: "created" },
        auth?.token
      )(dispatch);
      if (response) {
        props?.onClose();
        await getAllSubscriptionOrdersAction(
          auth?.token,
          `?status=created&status=pending&status=draft`
        )(dispatch);
      }
    }
  };

  useEffect(() => {
    if (orders?.selected?.account) {
      form.setFieldsValue(orders?.selected?.account?.customerDetails);
    } else {
      form.resetFields();
    }
  }, [form, orders?.selected?.account]);

  // const handlerSaveAssignee = async () => {
  //   const payload = {
  //     assignees: assignee,
  //     ...assignData,
  //   };
  //   props?.onClose();
  // };

  const handlerSaveAssignee = async () => {
    const payload = {
      assignees: assignee,
      // ...assignData,
    };

    if (orders?.selected?.account?._id && auth?.token) {
      const res = await updateOneAccountAction(
        auth?.token,
        orders?.selected?.account?._id,
        payload
      )(dispatch);
      if (res) {
        const response = await submitSubOrdersAction(
          orderId,
          { status: "created" },
          auth?.token
        )(dispatch);
        if (response) {
          props?.onClose();
          await getAllSubscriptionOrdersAction(
            auth?.token,
            `?status=created&status=pending&status=draft`
          )(dispatch);
        }
      }
    }
  };

  const handleNextStep = () => {
    handleSetSubscriptionDealSteps(layout?.subscriptionDealSteps + 1)(dispatch);
  };

  const handlePreviousStep = () => {
    handleSetSubscriptionDealSteps(layout?.subscriptionDealSteps - 1)(dispatch);
  };

  const steps = [
    {
      content: orders?.newOrderIsFetching ? (
        <FormSkeleton />
      ) : (
        <CustomerProfile />
      ),
    },
    {
      content: (
        <>
          <AddItem
            orderId={orderId}
            channelId={channelId}
            setChannelId={setChannelId}
          />
          <div className="mt-2">
            <Table
              columns={columns}
              dataSource={formData}
              loading={orders?.newOrderIsFetching}
              pagination={false}
            />
          </div>
          {formData?.length > 0 && (
            <div className="w-full flex justify-end mt-6">
              <div className="xmax-w-sm bg-white border border-gray-200 rounded-lg shadow p-8">
                <div className="text-right grid grid-cols-2 gap-x-20 gap-y-2 mb-2">
                  <span className="text-gray-600 font-semibold">Subtotal</span>
                  <span className="text-gray-600 font-semibold block">
                    {`${totalAmount?.toLocaleString()} ${getDefaultCurrencyCode(
                      company
                    )}`}
                  </span>
                  <span className="text-gray-500">Discount (10%)</span>
                  <span className="text-gray-600 font-medium block">
                    {`${(
                      (totalAmount * 10) /
                      100
                    )?.toLocaleString()} ${getDefaultCurrencyCode(company)}`}
                  </span>
                  <span className="text-gray-500">Taxes</span>
                  <span className="text-gray-600 font-medium block">
                    {`0.00 ${getDefaultCurrencyCode(company)}`}
                  </span>
                  <span className="text-gray-900 font-semibold">Total</span>
                  <span className="text-gray-900 font-semibold block">
                    {`${(
                      totalAmount -
                      (totalAmount * 10) / 100
                    )?.toLocaleString()} ${getDefaultCurrencyCode(company)}`}
                  </span>
                </div>
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      content: (
        <>
          <ExpandableButton title="Customer Profile">
            <CustomerPreview />
          </ExpandableButton>
          <ExpandableButton title="Customer Quotation Order">
            <div className="mt-2">
              <Table
                columns={columns}
                dataSource={formData}
                loading={orders?.newOrderIsFetching}
                pagination={false}
              />
            </div>
          </ExpandableButton>
          <AssigneeUser
            assignData={assignData}
            setAssignData={setAssignData}
            setAssignee={setAssignee}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    if (orders?.selected?.account?.customerDetails) {
      handleNewCustomer(true)(dispatch);
    }
  }, [dispatch, orders?.selected?.account?.customerDetails]);

  return (
    <div>
      <Drawer
        onClose={props?.onClose}
        open={props?.open}
        title="Create Deal"
        width={800}
        className="text-[#2943D6]"
        bodyStyle={{ paddingBottom: "80px" }}
        footerStyle={{ textAlign: "right" }}
        footer={
          <>
            {layout?.subscriptionDealSteps > 0 && (
              <div
                className={`${
                  layout?.subscriptionDealSteps > 0
                    ? "justify-between"
                    : "justify-end"
                } flex items-center`}
              >
                {layout?.subscriptionDealSteps > 0 && (
                  <button
                    onClick={() => handlePreviousStep()}
                    className="border border-[#6F57FF] text-[#6F57FF] text-sm font-medium py-1.5 px-6 rounded-md outline-none"
                  >
                    Previous
                  </button>
                )}
                {layout?.subscriptionDealSteps < steps.length - 1 && (
                  <button
                    onClick={() => handleNextStep()}
                    className="bg-[#6F57FF] text-white text-sm font-medium py-1.5 px-6 rounded-md border-2 outline-none"
                  >
                    {"Next"}
                  </button>
                )}
                {layout?.subscriptionDealSteps === steps.length - 1 && (
                  <Button
                    type="primary"
                    onClick={handlerSaveAssignee}
                    loading={
                      orders?.newOrderIsFetching ||
                      account?.setIsFetching ||
                      orders?.createOderIsFetching
                    }
                  >
                    Submit
                  </Button>
                )}
              </div>
            )}
          </>
        }
      >
        <Steps current={layout?.subscriptionDealSteps} />
        <div>{steps[layout?.subscriptionDealSteps].content}</div>
      </Drawer>
    </div>
  );
};

export default CreateOrder;
