import React, { useEffect, useState } from "react";
import { Form, Input, Select, Row, Col } from "antd";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import CustomButton from "../buttons/CustomButton";
import { ReactComponent as SaveIcon } from "../../assets/images/icons/save.svg";
import { addItemAction, getEbmSelectOptionAction } from "../../store/action";
import { useDispatch, useSelector } from "react-redux";
import { getNameByCode } from "../../utils/ConvertCodeToName";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
dayjs.extend(customParseFormat);

const dateFormat = "YYYY-MM-DD";
const AddItemForm = (props: any) => {
  const { auth, ebmGeneral, ebmItem } = useSelector((state: any) => state);
  const [orginCode, setOrginCode] = useState<any>(null);
  const [orginName, setOrginName] = useState<any>(null);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  useEffect(() => {
    getEbmSelectOptionAction(auth?.token, "")(dispatch);
  }, []);

  // const onChange=()=>{
  //   getEbmSelectOptionAction(auth?.token,"")(dispatch)
  // }
  const onOrgingChange = (value: any) => {
    setOrginCode(value);
    setOrginName(
      ebmGeneral?.selectOption &&
      getNameByCode(ebmGeneral?.selectOption?.Cuntry, value)
    );
  };
  const Options =
    ebmGeneral?.selectOption &&
    ebmGeneral?.selectOption.Cuntry?.map((el: any) => ({
      value: el?.value,
      label: el?.label,
    }));
  const QtyOptions =
    ebmGeneral?.selectOption &&
    ebmGeneral?.selectOption["Quantity Unit"]?.map((el: any) => ({
      value: el?.value,
      label: el?.label,
    }));
  const PkgOptions =
    ebmGeneral?.selectOption &&
    ebmGeneral?.selectOption["Packing Unit"]?.map((el: any) => ({
      value: el?.value,
      label: el?.label,
    }));
  const TaxOptions =
    ebmGeneral?.selectOption &&
    ebmGeneral?.selectOption["Taxation Type"]?.map((el: any) => ({
      value: el?.value,
      label: el?.label,
    }));
  const ItemOptions =
    ebmGeneral?.selectOption &&
    ebmGeneral?.selectOption["Item Type"]?.map((el: any) => ({
      value: el?.value,
      label: el?.label,
    }));

  useEffect(() => {
    form.setFieldsValue({
      // ...ebmItem?.selectedPriceListItems?.data?.product?.ebm
      itemTyCd:
        ebmGeneral?.selectOption &&
        getNameByCode(
          ebmGeneral?.selectOption["Item Type"],
          ebmItem?.selectedPriceListItems?.data?.product?.ebm.itemTyCd
        ),
      itemClsCd:
        ebmGeneral?.selectOption &&
        getNameByCode(
          ebmGeneral?.selectOption?.itemClassList,
          ebmItem?.selectedPriceListItems?.data?.product?.ebm.itemClsCd
        ),
      qtyUnitCode:
        ebmGeneral?.selectOption &&
        getNameByCode(
          ebmGeneral?.selectOption["Quantity Unit"],
          ebmItem?.selectedPriceListItems?.data?.product?.ebm.qtyUnitCode
        ),
      itemName: ebmItem?.selectedPriceListItems?.data?.product?.model,
    });
  }, [ebmItem?.selectedPriceListItems?.data?.product]);
  const qtyUnitCode =
    ebmGeneral?.selectOption &&
    getNameByCode(
      ebmGeneral?.selectOption["Quantity Unit"],
      ebmItem?.selectedPriceListItems?.data?.product?.ebm.qtyUnitCode
    );
  const addDevice = async (values: any) => {
    auth?.token &&
      (await addItemAction(auth?.token, {
        priceListItemId: ebmItem?.selectedPriceListItems?.data?._id,
        orgnNatCd: orginCode,
        country: orginName,
      })(dispatch));
    form.resetFields();
    props?.onClose();
  };
  return (
    <Form
      name="wrap"
      labelCol={{ flex: "110px" }}
      form={form}
      initialValues={ebmItem?.selectedPriceListItems?.data?.product?.ebm}
      labelAlign="left"
      onFinish={addDevice}
      colon={false}
      style={{ width: "100%" }}
    >
      <Row gutter={[32, 8]}>
        <Col className="gutter-row" xs={{ flex: '100%' }} sm={{ flex: '50%' }}>
          <div>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
                Item Code:
              </span>
              name="itemTyCd"
            >
              <Input className="h-10" />
            </Form.Item>

            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
                Item Name:
              </span>
              name="itemName"
              rules={[{ required: true }]}
            >
              <Input className="h-10" />
            </Form.Item>
            <Row gutter={[32, 8]}>
              <Col span={12}>
                <div>
                  <Form.Item
                    label=<span className="text-[12px] text-[#030229] pt-2 ">
                      Batch Number:
                    </span>
                    name="code"
                  >
                    <Input className="h-10" />
                  </Form.Item>
                  <Form.Item name="orgnNatCd">
                    <div className="flex gap-[2.4rem]">
                      <span className="text-[12px] text-[#030229] pt-3 w-[7rem]">
                        Item Type:
                      </span>
                      <Select
                        showSearch
                        style={{ height: 40 }}
                        placeholder="Item Type"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toString()
                            .includes(input.toString())
                        }
                        // filterSort={(optionA, optionB) =>
                        //   (optionA?.label ?? "")
                        //     .toLowerCase()
                        //     .localeCompare((optionB?.label ?? "").toLowerCase())
                        // }
                        options={ItemOptions}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item
                    label=<span className="text-[12px] text-[#030229] pt-2 ">
                      Purchase Price:
                    </span>
                    name="code"
                  >
                    <Input className="h-10" type="number" />
                  </Form.Item>
                  <Form.Item
                    label=<span className="text-[12px] text-[#030229] pt-2 ">
                      Beginning Stock:
                    </span>
                    name="code"
                  >
                    <Input className="h-10" />
                  </Form.Item>
                  <Form.Item name="orgnNatCd">
                    <div className="flex gap-[.5rem]">
                      <span className="text-[12px] text-[#030229] pt-3 w-[10rem]">
                        Use Expiration:
                      </span>
                      <Select
                        showSearch
                        style={{ height: 40 }}
                        placeholder="No Use"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "").includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                        options={[
                          {
                            value: "1",
                            label: "No User",
                          },
                          {
                            value: "2",
                            label: "users",
                          },
                        ]}
                      />
                    </div>
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <Form.Item name="orgnNatCd">
                    <div className="flex gap-7 ">
                      <span className="text-[12px] text-[#030229] pt-3 w-[13rem]">
                        Origin:
                      </span>
                      <Select
                        showSearch
                        style={{ height: 40 }}
                        placeholder="Select Orgin country"
                        onChange={onOrgingChange}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toString()
                            .includes(input.toString())
                        }
                        // filterSort={(optionA, optionB) =>
                        //   optionA?.label?.toString().toLowerCase().localeCompare(optionB?.label?.toString().toLowerCase())
                        // }
                        options={Options}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item name="orgnNatCd">
                    <div className="flex gap-5">
                      <span className="text-[12px] text-[#030229] pt-3 w-[8.7rem]">
                        Pkg unit:
                      </span>
                      <Select
                        showSearch
                        style={{
                          height: 40,
                        }}
                        placeholder="Select Packing Unit"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toString()
                            .includes(input.toString())
                        }
                        // filterSort={(optionA, optionB) =>
                        //   (optionA?.label ?? "")
                        //     .toLowerCase()
                        //     .localeCompare((optionB?.label ?? "").toLowerCase())
                        // }
                        options={PkgOptions}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item
                    label=<span className="text-[12px] text-[#030229] pt-2">
                      Sale price:
                    </span>
                    name="name"
                  >
                    <Input className="h-10" type="number" />
                  </Form.Item>
                  <Form.Item
                    label=<span className="text-[12px] text-[#030229] pt-3">
                      Current Stock:
                    </span>
                    name="name"
                  >
                    <Input className="h-10" />
                  </Form.Item>
                  <Form.Item name="orgnNatCd">
                    <div className="flex gap-2">
                      <span className="text-[12px] text-[#030229] pt-3 w-[10rem]">
                        Expiration Date:
                      </span>
                      <DatePicker
                        style={{
                          height: 40,
                          width: "100%",
                        }}
                        defaultValue={dayjs("2019-09-03", dateFormat)}
                        minDate={dayjs("2019-08-01", dateFormat)}
                        maxDate={dayjs("2020-10-31", dateFormat)}
                      />
                    </div>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col className="gutter-row" xs={{ flex: '100%' }} sm={{ flex: '50%' }}>
          <div>
            <Form.Item
              name="itemClsCd"
              label=<span className="text-[12px] text-[#030229] pt-2">
                Class Code:
              </span>
              rules={[{ required: true }]}
            >
              <Input className="h-10" />
            </Form.Item>
            <Row gutter={[32, 8]}>
              <Col span={12}>
                <div>
                  <Form.Item name="orgnNatCd">
                    <div className="flex gap-2">
                      <span className="text-[12px] text-[#030229] pt-3 w-[9rem]">
                        Use Barcode:
                      </span>
                      <Select
                        showSearch
                        style={{ height: 40 }}
                        placeholder="No Use"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "").includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                        options={[
                          {
                            value: "1",
                            label: "No User",
                          },
                          {
                            value: "2",
                            label: "users",
                          },
                        ]}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item name="orgnNatCd">
                    <div className="flex gap-1">
                      <span className="text-[12px] text-[#030229] pt-2 w-[9rem]">
                        Insurance Y/N:
                      </span>
                      <Select
                        showSearch
                        style={{ height: 40 }}
                        placeholder="Y/N"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "").includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                        options={[
                          {
                            value: "yes",
                            label: "yes",
                          },
                          {
                            value: "no",
                            label: "no",
                          },
                        ]}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item name="orgnNatCd">
                    <div className="flex gap-3">
                      <span className="text-[12px] text-[#030229] pt-3 w-[8rem]">
                        Qty Unit:
                      </span>
                      <Select
                        showSearch
                        style={{ height: 40 }}
                        placeholder="Select quantity Unit"
                        // value={qtyUnitCode}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toString()
                            .includes(input.toString())
                        }
                        // filterSort={(optionA, optionB) =>
                        //   (optionA?.label ?? "")
                        //     .toLowerCase()
                        //     .localeCompare((optionB?.label ?? "").toLowerCase())
                        // }
                        options={QtyOptions}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item name="orgnNatCd">
                    <div className="flex gap-3">
                      <span className="text-[12px] text-[#030229] pt-3 w-[7.6rem]">
                        Tax Type:
                      </span>
                      <Select
                        showSearch
                        style={{ height: 40 }}
                        placeholder="Tax Type"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toString()
                            .includes(input.toString())
                        }
                        // filterSort={(optionA, optionB) =>
                        //   (optionA?.label ?? "")
                        //     .toLowerCase()
                        //     .localeCompare((optionB?.label ?? "").toLowerCase())
                        // }
                        options={TaxOptions}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item name="orgnNatCd">
                    <div className="flex gap-3">
                      <span className="text-[12px] text-[#030229] pt-2 w-[7.6rem]">
                        Use:
                      </span>
                      <Select
                        showSearch
                        style={{ height: 40 }}
                        placeholder="No Use"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "").includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                        options={[
                          {
                            value: "1",
                            label: "No User",
                          },
                          {
                            value: "2",
                            label: "users",
                          },
                        ]}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item
                    label=<span className="text-[12px] text-[#030229] pt-2">
                      Safety Stock:
                    </span>
                    name="name"
                  >
                    <Input className="h-10" type="number" />
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <Form.Item
                    label=<span className="text-[12px] text-[#030229] pt-2">
                      Bar code:
                    </span>
                    name="name"
                  >
                    <Input className="h-10 " />
                  </Form.Item>
                  <Form.Item
                    label=<span className="text-[12px] text-[#030229] pt-2">
                      Sale Price:
                    </span>
                    name="name"
                  >
                    <Input className="h-10 " type="number" />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {" "}
          <Form.Item
            label=<span className="text-[12px] text-[#030229] pt-2 w-[6rem]">
              Description:
            </span>
            name="description"
            className="mt-3"
          >
            <Input className="h-10" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label=" " className="w-1/2 m-auto">
        <CustomButton
          title={
            ebmGeneral?.isFetching ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "white" }}
                    spin
                  />
                }
              />
            ) : (
              "Save"
            )
          }
          bgColor="[#605BFF]"
          width="full"
          textColor="white"
          icon={<SaveIcon />}
        />
      </Form.Item>
    </Form>
  );
};
export default AddItemForm;
