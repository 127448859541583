import React from "react";
import { Radio } from "antd";
import { myWareHouseActions } from "../../store/wareHouse";
import { useDispatch } from "react-redux";

const WarehouseFilter = (props: any) => {
  const dispatch = useDispatch();
  return (
    <div className="flex items-center space-x-2 pt-4">
      <Radio.Group
        defaultValue="all"
        buttonStyle="solid"
        onChange={(e: any) => {
          props.setSelecteStataus(e.target.value);
          dispatch(myWareHouseActions.setAll(null));
        }}
      >
        {props?.total && (
          <Radio.Button
            value="all"
            className="capitalize text-[12px] font-normal pt-1 h-10"
          >{`ALL (${props?.total ?? 0})`}</Radio.Button>
        )}
        {props?.data?.map((el: any) => (
          <Radio.Button
            value={el?.status}
            className="capitalize text-[12px] pt-1 h-10 font-normal"
          >{`${el?.status} ${el?.count ? `(${el?.count})` : ""}`}</Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
};

export default WarehouseFilter;
