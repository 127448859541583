import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "invoice",
  initialState: {
    isFetching: false,
    all: null,
    selected: null,
    attributes: null,
    new: null,
    query: null,

    paymentInitiationStatus: null,
    paymentDoneStatus: null,
    checkPaymentAgainHelper: null,
    statusResult: null,
    selectedForPayment: null,

    isPaymentModalOpen: false,
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setAttributes(state, action) {
      state.attributes = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },

    // momo
    setPaymentInitiationStatus(state, action) {
      state.paymentInitiationStatus = action.payload;
    },
    setPaymentDoneStatus(state, action) {
      state.paymentDoneStatus = action.payload;
    },
    setCheckPaymentAgainHelper(state, action) {
      state.checkPaymentAgainHelper = action.payload;
    },
    resetPaymentRelatedStatesService(state) {
      state.paymentInitiationStatus = null;
      state.paymentDoneStatus = null;
      state.checkPaymentAgainHelper = null;
    },
    setStatusResult(state, action) {
      state.statusResult = action.payload;
    },
    setSelectedForPayment(state, action) {
      state.selectedForPayment = action.payload;
    },
    setIsPaymentModalOpen(state, action) {
      state.isPaymentModalOpen = action.payload;
    },
  },
});

export const myInvoices = mySlice.actions;

export default mySlice.reducer;
