import React, { useState, useMemo, useEffect } from "react";
import { Card, Tag, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom"; // Import useNavigate
import { getAllDeliveryNoteAction } from "../store/wareHouse/actions";
import { getOnePackageAction } from "../store/delivery/actions";
import { searchValue } from "../utils/setColor";

interface ShipmentTrackingListProps {
  searchTerm: string;
  onSelectTrackingId: (
    trackingId: string,
    deliveryNoteLink: string | null
  ) => void;
  isFromMe: any;
  activeTab: any;
}

const ShipmentTrackingList: React.FC<ShipmentTrackingListProps> = ({
  searchTerm,
  onSelectTrackingId,
  isFromMe,
  activeTab,
}) => {
  const [clickedShipmentId, setClickedShipmentId] = useState<string | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(true);
  const { auth, tracking } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (auth?.token) {
      setIsLoading(true);
      getAllDeliveryNoteAction(
        auth?.token,
        "?"
      )(dispatch).finally(() => {
        setIsLoading(false);
      });
    }
  }, [auth?.token, dispatch]);

  const filteredShipments = useMemo(() => {
    let shipments = tracking?.all?.data || [];

    if (activeTab !== "All") {
      shipments = shipments.filter(
        (shipment: any) => shipment?.trackingStatus === activeTab
      );
    }

    if (searchTerm) {
      shipments = shipments?.filter(
        (shipment: any) =>
          shipment?.packageId
            .toLowerCase()
            .includes(searchTerm?.toLowerCase()) ||
          shipment?.packageId?.toLowerCase().includes(searchTerm?.toLowerCase())
      );
    }

    return shipments;
  }, [activeTab, searchTerm, tracking?.all?.data]);

  useEffect(() => {
    if (auth.token && id) {
      getOnePackageAction(auth.token, id)(dispatch);
    }
  }, [auth.token, id, dispatch]);
  console.log("display id from params",id)
  return (
    <div className="max-w-md mx-auto p-2 overflow-y-auto h-auto">
      <div className="space-y-4 h-auto ">
        {isLoading ? (
          <Skeleton active paragraph={{ rows: 4 }} />
        ) : (
          filteredShipments?.map((shipment: any, index: any) => {
            const deliveryNoteLink = shipment?.deliveryNote?.getLink || null;
            return (
              <Card
                key={index}
                className={`rounded-lg cursor-pointer ${
                  clickedShipmentId === index ? "border-2 border-[#7470ee]" : ""
                }`}
                onClick={() => {
                  setClickedShipmentId(index);
                  onSelectTrackingId(shipment.packageId, deliveryNoteLink);
                  navigate(`/track/${shipment._id}`);
                }}
              >
                <div className="flex justify-between items-start mb-2">
                  <span className="font-semibold text-[#030229] text-base">
                    {isFromMe
                      ? shipment?.locationTo?.name
                      : shipment?.locationFrom?.name}
                  </span>
                  <Tag
                    color={searchValue(shipment.trackingStatus)}
                    className="rounded-full px-3 py-1"
                  >
                    {shipment?.trackingStatus}
                  </Tag>
                  {/* <p className={`text-[${shipment.trackingStatus}]`}>{shipment?.trackingStatus}</p> */}
                </div>
                <div>
                  Tracking ID:{" "}
                  <span className="text-[#605BFF] text-sm">
                    {shipment?.packageId}
                  </span>
                </div>
                <div className="pt-4 text-sm">
                  Type:{" "}
                  <span className="text-gray-500 text-sm ">
                    {shipment?.trackingType?.name || "N/A"}
                  </span>
                </div>
                <div className="pt-4">
                  Deliverer:{" "}
                  <span className="text-gray-500 text-sm ">
                    {shipment?.deliverer?.name || "N/A"}
                  </span>
                </div>
              </Card>
            );
          })
        )}
      </div>
    </div>
  );
};

export default ShipmentTrackingList;
