import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "orderLibrary",
  initialState: {
    isFetching: false,
    newOrderIsFetching: false,
    orderItemIsFetching: false,
    createOderIsFetching: false,
    all: { data: [] },
    selected: false,
    attributes: null,
    new: null,
    subOrderItem: null,
    updated: null,
    query: null,
    selectedProduct: null,
    productStore: null,
    singleOrder: null,
    ebmitem: [],
    orderStatus: null,
    orderDeleted: null,
    businessProduct: null,
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    seEbmItem(state, action) {
      state.ebmitem = action.payload;
    },
    setAttributes(state, action) {
      state.attributes = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setNewOrderIsFetching(state, action) {
      state.newOrderIsFetching = action.payload;
    },
    setOrderItemIsFetching(state, action) {
      state.orderItemIsFetching = action.payload;
    },

    setCreateOderIsFetching(state, action) {
      state.createOderIsFetching = action.payload;
    },
    setSelectedProduct(state, action) {
      state.selectedProduct = action.payload;
    },
    setProductStore(state, action) {
      state.productStore = action.payload;
    },
    setSubOrderItem(state, action) {
      state.subOrderItem = action.payload;
    },
    setUpdateSubOrderItem(state, action) {
      state.updated = action.payload;
    },
    setSingleOrder(state, action) {
      state.singleOrder = action.payload;
    },
    setOrderStatus(state, action) {
      state.orderStatus = action.payload;
    },
    setOrderDeleted(state, action) {
      state.orderDeleted = action.payload;
    },
    setBusinessProduct(state, action) {
      state.businessProduct = action.payload;
    },
  },
});

export const mySubscritionActions = mySlice.actions;

export default mySlice.reducer;
