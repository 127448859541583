import { notification } from "antd";
import { myPaymentActions } from ".";
import {
  createpaymentcategoryService,
  createpaymentmethodService,
  getAllpaymentcategoryService,
  getAllpaymentsService,
  updateOnepaymentsService,
  getOnepaymentsService,
} from "./services";

export const createpaymentcategoryAction = (token: string, values: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPaymentActions.setIsFetching(true));
      const res = await createpaymentcategoryService(values, token);
      if (res?.status === 201) {
        dispatch(myPaymentActions.setNewPayment(res));
        dispatch(myPaymentActions.setIsFetching(false));
      }
      dispatch(myPaymentActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getallpaymentcategoryAction = (token: string, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPaymentActions.setIsFetching(true));
      const res = await getAllpaymentcategoryService(token, query);

      if (res?.status === 200) {
        dispatch(myPaymentActions.setAllPaymentCategories(res));
        dispatch(myPaymentActions.setIsFetching(false));
      }
      dispatch(myPaymentActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getallpaymentsAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPaymentActions.setIsFetching(true));
      const res = await getAllpaymentsService(token, query);

      if (res?.status === 200) {
        dispatch(myPaymentActions.setAllPayments(res));
        dispatch(myPaymentActions.setIsFetching(false));
      }
      dispatch(myPaymentActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const createpaymentmethodAction = (token: string, values: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPaymentActions.setIsFetching(true));
      const res = await createpaymentmethodService(values, token);
      if (res?.status === 201) {
        dispatch(myPaymentActions.setNewPaymentMethod(res));
        dispatch(myPaymentActions.setIsFetching(false));
        notification.success({ message: "Created Succesfully" });
      }
      dispatch(myPaymentActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOnepaymentsAction = (id: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPaymentActions.setIsFetching(true));
      const res = await getOnepaymentsService(id, token);
      if (res?.status === 200) {
        dispatch(myPaymentActions.setNewPayment(res?.data));
        dispatch(myPaymentActions.setIsFetching(false));
      }
      dispatch(myPaymentActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateOnepaymentsActions = (
  itemId: any,
  data: any,
  token: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPaymentActions.setIsFetching(true));
      const res: any = await updateOnepaymentsService(itemId, data, token);
      if (res?.status === 200) {
        const resAll = await getAllpaymentsService(token);
        dispatch(myPaymentActions.setUpdateType(res?.data));
        dispatch(myPaymentActions.setAllPayments(resAll));
        dispatch(myPaymentActions.setIsFetching(false));
        notification.success({ message: "Updated Succesfully" });
      }
      dispatch(myPaymentActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
