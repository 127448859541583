import react, { useState } from "react";
import SearchBar from "@mkyy/mui-search-bar";
import * as cgIcons from "react-icons/cg";
import * as ioIcons from "react-icons/io";
import * as riIcons from "react-icons/ri";
import * as tbIcons from "react-icons/tb";
import * as siIcons from "react-icons/si";
import { ReactComponent as peapleIcon } from "../../../assets/icons/pepicons-pencil_people.svg";
import { ReactComponent as securityIcon } from "../../../assets/icons/carbon_security.svg";
import { ReactComponent as channelIcon } from "../../../assets/icons/Channel icon 1.svg";
import { ReactComponent as PreferencesIcon } from "../../../assets/icons/Group.svg";
import SideBar from "./SideBar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { myLayoutActions } from "../../../store/layout";

const SettingSideBar = () => {
  const { layout } = useSelector((state: any) => state);
  const [textFieldValue, setTextFieldValue] = useState("");
  const handleSearch = (labelOptionValue: any) => {
    //...
    console.log(labelOptionValue);
  };
  const styles = {
    customShadow: {
      boxShadow: "0px 2px 24px 0px rgba(15, 15, 71, 0.07)",
    },
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const activeTab = layout?.settingActiveSidebarTab;

  const handleSelectTabs = (tabs: string) => {
    dispatch(myLayoutActions.setSettingActiveSidebarTab(tabs));
    navigate(`/setting#${tabs}`);
  };

  return (
    <>
      {layout?.isSettingSidebarHovered && (
        <div className="p-5">
          <SearchBar
            style={styles.customShadow}
            value={textFieldValue}
            onChange={(newValue) => setTextFieldValue(newValue)}
            onSearch={handleSearch}
            className="xl:block hidden"
          />
        </div>
      )}
      <SideBar
        icon={cgIcons.CgProfile}
        iconColor={`${activeTab === "profile" ? "#605BFF" : "#0F0F47"} `}
        tittle="Profile"
        tittleColor={`${activeTab === "profile" ? "[#605BFF]" : "[#0F0F47]"} `}
        content="Details about your personal information"
        contentColor="[#0F0F47B2]"
        onClick={() => handleSelectTabs("profile")}
      />
      <SideBar
        icon={riIcons.RiShieldLine}
        iconFill={`${activeTab === "company" ? "#605BFF" : "#0F0F47"} `}
        tittle="Your Company"
        tittleColor={`${activeTab === "company" ? "[#605BFF]" : "[#0F0F47]"} `}
        content="Details about your company information"
        contentColor="[#0F0F47B2]"
        onClick={() => handleSelectTabs("company")}
      />
      <SideBar
        icon={tbIcons.TbPlugConnected}
        iconFill="none"
        iconColor={`${activeTab === "integration" ? "#605BFF" : "#0F0F47"} `}
        tittle="Integrations"
        tittleColor={`${
          activeTab === "integration" ? "[#605BFF]" : "[#0F0F47]"
        } `}
        content="Supercharge your workflow"
        contentColor="[#0F0F47B2]"
        onClick={() => handleSelectTabs("integration")}
      />
      <SideBar
        icon={peapleIcon}
        iconFill={`${activeTab === "users" ? "#605BFF" : "#0F0F47"} `}
        tittle="User Management"
        tittleColor={`${activeTab === "users" ? "[#605BFF]" : "[#0F0F47]"} `}
        content="Manage your team members"
        contentColor="[#0F0F47B2]"
        onClick={() => handleSelectTabs("users")}
      />
      <SideBar
        icon={securityIcon}
        iconFill={`${activeTab === "channel" ? "#605BFF" : "#0F0F47"} `}
        tittle="Channels"
        tittleColor={`${activeTab === "channel" ? "[#605BFF]" : "[#0F0F47]"} `}
        content="Customize your channel"
        contentColor="[#0F0F47B2]"
        onClick={() => handleSelectTabs("channel")}
      />
      <SideBar
        icon={channelIcon}
        iconFill={`${activeTab === "security" ? "#605BFF" : "#0F0F47"} `}
        tittle="Privacy and Security"
        tittleColor={`${activeTab === "security" ? "[#605BFF]" : "[#0F0F47]"} `}
        content="Customize your channel"
        contentColor="[#0F0F47B2]"
        onClick={() => handleSelectTabs("security")}
      />
      <SideBar
        icon={PreferencesIcon}
        iconFill={`${activeTab === "preferences" ? "#605BFF" : "#0F0F47"} `}
        tittle="Preferences"
        tittleColor={`${
          activeTab === "preferences" ? "[#605BFF]" : "[#0F0F47]"
        } `}
        content="Customize your color"
        contentColor="[#0F0F47B2]"
        onClick={() => handleSelectTabs("preferences")}
      />
      <SideBar
        icon={ioIcons.IoIosNotificationsOutline}
        iconFill={`${activeTab === "notification" ? "#605BFF" : "#0F0F47"} `}
        tittle="Notifications"
        tittleColor={`${
          activeTab === "notification" ? "[#605BFF]" : "[#0F0F47]"
        } `}
        content="Customize your notifications"
        contentColor="[#0F0F47B2]"
        onClick={() => handleSelectTabs("notification")}
      />
      <SideBar
        icon={siIcons.SiLogseq}
        iconFill={`${activeTab === "systemLogs" ? "#605BFF" : "#0F0F47"} `}
        tittle="System Logs"
        tittleColor={`${
          activeTab === "systemLogs" ? "[#605BFF]" : "[#0F0F47]"
        } `}
        content="Details about perfomed Activities"
        contentColor="[#0F0F47B2]"
        onClick={() => handleSelectTabs("systemLogs")}
      />
    </>
  );
};
export default SettingSideBar;
