import React from "react";
import { Popover } from "antd";
import { Button } from "@mui/material";

const App: React.FC<{
  title?: string;
  open?: any;
  handleOpen?: any;
  content?: any;
  icon?: any;
}> = ({ title, open, handleOpen, content, icon }) => {
  return (
    <Popover
      content={content}
      title={title}
      trigger="click"
      open={open}
      onOpenChange={handleOpen}
    >
      <Button color="primary">{icon ?? "Button"}</Button>
    </Popover>
  );
};

export default App;
