import React, { ReactNode, useEffect } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { openCSVButton } from "../../../store/layout/actions";
import { myCSVAction } from "../../../store/csvDownload";
import { Spin, Space } from "antd";
interface CSVTableDownloadProps {
    btnName?: any;
    btnBgColor?: any;
    btnWidth?: string;
    textColor?: string;
    icon?: ReactNode;
    columns?: any[];
    csvHeaders: { label: string; key: string }[];
    csvData: any[];
    filename: any;
}
const EbmExportButton: React.FC<CSVTableDownloadProps> = ({
    btnName,
    btnBgColor,
    btnWidth,
    textColor,
    icon,
    csvHeaders,
    csvData,
    filename,
}) => {
    const styles = {
        border: {
            border: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)",
        },
        shadow: {
            boxShadow: "1px 4px 24px 0px rgba(3, 2, 41, 0.06)",
        },
    };

    const { CSV } = useSelector((state: any) => state);
    const csvLinkRef = React.useRef<any>(null);
    const dispatch = useDispatch();

    const handleButtonClick = (fileName: string) => {
        openCSVButton(true)(dispatch);
        dispatch(myCSVAction.setFileToExport(fileName));
    };

    useEffect(() => {
        if (CSV?.allowcsvDownload && csvLinkRef && csvLinkRef.current) {
            csvLinkRef.current.link.click();
        }
        openCSVButton(false)(dispatch);
        dispatch(myCSVAction.setCSVDownloadedData(null));
        dispatch(myCSVAction.setAllowCSVDownload(false));
        dispatch(myCSVAction.setIsFileDownload(false));
        dispatch(myCSVAction.setFileToExport("csv"));
    }, [CSV?.allowcsvDownload]);

    return (
        <>
            <button
                className={`bg-${btnBgColor}  text-${textColor} w-${btnWidth} font-medium text-sm  rounded-md py-2 px-6 flex justify-center items-center text-black`}
                onClick={() => handleButtonClick("csv")}
                style={styles.shadow}
            >
                {CSV?.isFetching ? (
                    <div className="w-20">
                        <Spin />
                    </div>
                ) : (
                    <Space>
                        {icon}
                        {btnName}
                    </Space>
                )}
                <CSVLink
                    data={csvData ?? []}
                    headers={csvHeaders}
                    ref={csvLinkRef}
                    filename={filename}
                />
            </button>
        </>
    );
};
export default EbmExportButton;
