import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const createService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/condition`, data, token);
};
export const getAllService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/condition${query}`, token);
};


export const getOneService = async (id: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/condition/one/${id}`, token);
};

export const updateService = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/condition/one/${itemId}`,
    data,
    token
  );
};

export const updateIsDeleteService = async (data: any, token: string,itemId: string) => {
  return await HttpRequest.update(`${SERVER_URL}/condition/one/${itemId}`, data, token);
};


