import { MenuUnfoldOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";
import "./style.css";

const Knowledge = () => {
  const randomQueryParam = `nocache=${Math.random()}`;

  const embedUrl = `https://knowledge.samphone.africa/?${randomQueryParam}`;

  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      sessionStorage.clear();
    });

    return () => {
      window.removeEventListener("beforeunload", () => {
        sessionStorage.clear();
      });
    };
  }, []);

  return (
    <>
      {/* <div className="relative h-full">
        <embed
          src={embedUrl}
          width="100%"
          height={700}
          className="w-full h-[95vh] -mb-14"
        />
      </div> */}
      <div className="text-gray-900 custom-bg bg-cover bg-no-repeat max-h-[calc(100vh-70px)]">
        <header className="bg-black text-white py-4">
          <div className="flex container mx-auto justify-between items-center">
            <h1 className="text-xl font-bold">SAMPHONE</h1>
            <nav className="hidden lg:flex items-center space-x-8">
              <a href="" className="border border-white rounded p-2">
                Home
              </a>
              <a href="">Departments</a>
              <a href="">Policies and Procedures</a>
              <a href="">Employee Resources</a>
            </nav>
            <div className="block lg:hidden">
              <MenuUnfoldOutlined style={{ fontSize: "24px" }} />
            </div>
          </div>
        </header>

        <main className="container mx-auto">
          <div className="flex flex-col lg:flex-row lg:space-x-2">
            <section className="mt-8 w-full lg:w-1/2">
              <div className="bg-white rounded shadow-md h-[73vh] opacity-85 pt-20 px-10">
                <h2 className="text-2xl font-bold mb-4">Announcements</h2>
                <p>
                  Your comprehensive resource for all things related to Samphone
                  Rwanda. This wiki is designed to be the central hub where
                  employees can easily access and share information on internal
                  processes, policies, and guidelines. Whether you are a new
                  hire or a seasoned employee, the Samphone Rwanda Wiki is here
                  to support you in your day-to-day tasks and help you stay
                  informed about company standards and procedures.
                </p>
                <button className="mt-4 bg-black text-white py-2 px-4 rounded-sm">
                  Learn More
                </button>
              </div>
            </section>
            <div className="w-full lg:w-1/2 ">
              <section className="mt-8">
                <div className="bg-white rounded shadow-md h-[35vh] opacity-85 p-4 lg:pt-20 lg:px-10">
                  <h2 className="text-2xl font-bold mb-4">
                    Joined Samphone recently!
                  </h2>
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img
                        src="https://cdn.pixabay.com/photo/2018/01/06/09/25/hijab-3064633_1280.jpg"
                        alt="Malakia Nyirabaruta Muvunyi"
                        className="w-24 h-24 rounded-full mr-4 object-cover"
                      />
                    </div>
                    <div>
                      <h3 className="text-lg font-bold">
                        Malakia Nyirabaruta Muvunyi
                      </h3>
                      <p>Sales Agent</p>
                      <p>Region: Kigali 1</p>
                      <p>Service Center: Kabuga</p>
                    </div>
                  </div>
                </div>
              </section>
              <section className="mt-2 grid grid-cols-1 sm:grid-cols-2 gap-2 text-gray-900">
                <div className="bg-white rounded shadow-md h-[37vh] opacity-85 p-5 lg:pt-20 lg:px-10">
                  <h2 className="text-2xl font-bold mb-4">Announcements</h2>
                  <p>
                    A new announcement about an updated procedure or process
                    will be displayed here and can be accessed via the button
                    below.
                  </p>
                  <button className="mt-4 bg-black text-white py-2 px-4 rounded-sm">
                    More Details
                  </button>
                </div>
                <div className="bg-white rounded shadow-md h-[37vh] opacity-85 p-5 lg:pt-20 lg:px-10">
                  <h2 className="text-2xl font-bold mb-4">Announcements</h2>
                  <p>
                    A new announcement about an updated procedure or process
                    will be displayed here and can be accessed via the button
                    below.
                  </p>
                  <button className="mt-4 bg-black text-white py-2 px-4 rounded-sm">
                    More Details
                  </button>
                </div>
              </section>
            </div>
          </div>
        </main>

        <footer className="bg-black text-white p-5 mt-14">
          <div className="container flex justify-between mx-auto text-center">
            <span className="text-xs">About Wiki | Directory</span>
            <span className="text-xs">SAMPHONE</span>
            <span className="text-xs">Legal Notice | Privacy Policy</span>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Knowledge;
