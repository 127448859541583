import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Select, Form, Input, notification } from "antd";
import AddIcon from "@mui/icons-material/Add";
import { getOneProductAction } from "../../store/product/actions";
import { myProductActions } from "../../store/product";
import {
  getPricelistItemAction,
  updatePriceListVariantAction,
} from "../../store/channel/actions";
import { convertSpec, updatePriceListSpecs } from "../../utils/converter";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const UpdateVariantForm = (props: any) => {
  const [form] = Form.useForm();
  const { product, auth, channel } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const initialSpecs = props?.priceListData?.specification
    ?.slice(2)
    ?.map((item: any) => item);

  const convertedSpec = convertSpec(initialSpecs);

  const onFinish = async (values: any) => {
    const val = {
      specification: [
        ["Type", product?.selected?.type],
        ["Brand", product?.selected?.brand],
        ...initialSpecs,
        ...Object.entries(values.specification),
      ],
    };

    if (props?.priceListData?._id && auth?.token) {
      await updatePriceListVariantAction(
        props?.priceListData?._id,
        val,
        auth?.token
      )(dispatch);

      await getPricelistItemAction(
        auth?.token,
        `?channel=${channel?.selected?._id}&page=0&limit=15&type=pos`
      )(dispatch);
    } else {
      notification.error({ message: "Add product variant" });
    }
    props?.handleOnCancel();
    form.resetFields();
  };

  useEffect(() => {
    dispatch(myProductActions.setSelected(null));
    props?.prodDataId &&
      auth?.token &&
      getOneProductAction(auth?.token, props?.prodDataId)(dispatch);
  }, [auth?.token, dispatch, props?.prodDataId]);

  const filteredData = updatePriceListSpecs(
    product?.selected?.specs,
    convertedSpec
  );

  return (
    <Stack spacing={0}>
      <Paper elevation={0}>
        <Stack spacing={0}>
          <Paper elevation={0} className="pl-4 pr-4 pt-2 pb-2">
            <Form
              {...formItemLayout}
              form={form}
              name="register"
              onFinish={onFinish}
              initialValues={convertedSpec}
              style={{ maxWidth: "100%" }}
              scrollToFirstError
            >
              <Stack
                spacing={0}
                direction="row"
                justifyContent={"space-between"}
                alignItems={"flex-end"}
              >
                <Stack direction={"column"} spacing={0} alignItems="start">
                  <Form.Item
                    name="product"
                    label="Product Model"
                    tooltip="Please select modal of the product!"
                  >
                    <Input
                      disabled
                      placeholder={props?.priceListData?.product?.model}
                      style={{ width: "220px" }}
                    />
                  </Form.Item>
                  <div className=" flex flex-col gap-1 ml-[7.5rem]">
                    {product?.selected &&
                      filteredData?.map(
                        (spec: any) =>
                          spec.value.length > 0 &&
                          !spec?.label?.toLowerCase()?.includes("color") && (
                            <Form.Item name={["specification", spec?.label]}>
                              <Select
                                showSearch
                                allowClear
                                loading={product.isFetching}
                                disabled={product.isFetching}
                                value={spec?.value}
                                style={{ width: 200 }}
                                placeholder={`Select  ${spec?.label}`}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toString()
                                    .includes(input)
                                }
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? "")
                                    .toString()
                                    .toLowerCase()
                                    .localeCompare(
                                      (optionB?.label ?? "")
                                        .toString()
                                        .toLowerCase()
                                    )
                                }
                                options={spec?.value?.map((pro: any) => ({
                                  label: pro,
                                  value: pro,
                                }))}
                              />
                            </Form.Item>
                          )
                      )}
                  </div>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    startIcon={<AddIcon />}
                    sx={{ Width: "100%", margin: "0 auto" }}
                    loading={channel?.isFetching}
                  >
                    Update
                  </LoadingButton>
                </Stack>
                <Stack direction={"column"} spacing={0}></Stack>
              </Stack>
            </Form>
          </Paper>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default UpdateVariantForm;
