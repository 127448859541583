import React, { useEffect, useState } from "react";
import SettingButton from "../../../pages/dashboard/setting/SettingButton";
import { BiPlus } from "react-icons/bi";
import { ReactComponent as ImportIcons } from "../assets/images/icons/vscode-icons_file-type-excel2.svg";
import EbmButton from "../components/EbmButton";
import { Input } from "antd";
import type { SearchProps } from "antd/es/input/Search";
import GenearalModal from "../components/modals/GeneralModal";
import CustomButton from "../components/buttons/CustomButton";
import GeneralDrawer from "../components/GeneralDrawer";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import TwoIconsCard from "../components/cards/TwoIconsCard";
import { ReactComponent as AmountIcons } from "../assets/images/icons/Group 1000002860.svg";
import { ReactComponent as CustomerAmountIcons } from "../assets/images/icons/Group 1000002860_1.svg";
import type { SelectProps } from "antd";
import PurchaseDetails from "../components/PuchaseDetails";
import PurchaseTable from "../components/tables/PurchaseTable";
import AddPurchaseForm from "../components/forms/AddPurchaseForm";
import { useDispatch, useSelector } from "react-redux";
import { getEbmPurchasedAction, getPurchaseStatsAction } from "../store/PurchaseManagement/action";
import { getAllItemsAction, getEbmSelectOptionAction } from "../store/action";
import EbmFilter from "../components/filter/ebmFilter";
import { getNameByCode } from "../utils/ConvertCodeToName";
import { CSVLink } from "react-csv";
import { getDefaultCurrencyCode } from "../../../utils/helper";
import LoadingCard from "../components/LoadingCard";
dayjs.extend(customParseFormat);

const dateFormat = "YYYY-MM-DD";
type SelectCommonPlacement = SelectProps["placement"];
const PurchaseManagement = () => {
  const { auth, ebmPurchase, ebmGeneral, company } = useSelector(
    (state: any) => state
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedData, setSelectedData] = useState<any>(null);
  const dispatch = useDispatch();

  const showModal = (data?: any) => {
    setSelectedData(data);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };
  const { Search } = Input;
  const onSearch: SearchProps["onSearch"] = (value, _e, info) =>
    console.log(info?.source, value);
  useEffect(() => {
    auth?.token && getEbmSelectOptionAction(auth?.token, "")(dispatch);
  }, [dispatch, auth]);

  useEffect(() => {
    auth?.token &&
      getEbmPurchasedAction(auth?.token, `?status=${selectedStatus}`)(dispatch);
    auth?.token && getAllItemsAction(auth?.token, "")(dispatch);
  }, [auth?.token, dispatch, selectedStatus]);

  useEffect(() => {
    auth?.token && getPurchaseStatsAction(auth?.token, "")(dispatch);
  }, [dispatch, auth]);

  const csvLinkRef = React.useRef<any>(null);

  const handleButtonClick = () => {
    if (csvLinkRef && csvLinkRef.current) {
      csvLinkRef.current.link.click();
    }
  };
  const csvHeaders = [
    { label: "Supplier", key: "name" },
    { label: "Supply Date", key: "date" },
    { label: `Confirm Date `, key: "acceptDate" },
    // { label: `Quantity `, key: "qauntity" },
    { label: `Purchase Code `, key: "tin" },
    { label: `Purchase Amount `, key: "price" },
    { label: `Status`, key: "status" },
  ];
  const csvData =
    ebmPurchase?.all &&
    ebmPurchase?.all?.map((el: any) => {
      return {
        key: el?._id,
        id: el?.deliveryID?.slice(3, 9)?.replace("-", ""),
        name: el?.listOfItems?.map((d: any) => d?.po?.supplier?.name),
        date: el?.listOfItems?.map((d: any) =>
          d?.po?.supplier?.createdAt?.slice(0, 10)
        ),
        acceptDate: el?.pOrderID?.quotation?.confirmationDate?.slice(0, 10),
        tin: el?.listOfItems?.map((d: any) => d?.po?.supplier?.tin),
        price: el?.listOfItems?.reduce(
          (accumulator: any, item: any) =>
            accumulator + item?.purchaseItem?.priceUnit,
          ""
        ),
        vat: 0,
        status: el?.ebmPurchaseStatus,
        data: el,
      };
    });
  return (
    <div>
      <GeneralDrawer
        openDrawer={openDrawer}
        closeDrawer={onClose}
        width={650}
        title={
          <h1 className="text-base font-medium text-[#030229]">
            Purchase Information Details
          </h1>
        }
        component={
          <div>
            <div className="flex justify-between ">
              <div>
                <div className="bg-[#F4F6FA] flex justify-center items-center gap-2 px-[.25rem] py-[.25rem] rounded-md">
                  {" "}
                  <h1 className="text-[.9rem] text-black pl-2">
                    Current status:
                  </h1>
                  <CustomButton
                    title={
                      ebmGeneral?.selectOption &&
                      getNameByCode(
                        ebmGeneral?.selectOption["Purchase Status"],
                        selectedData?.ebmPurchaseStatus
                      )
                    }
                    textColor="white"
                    bgColor="green-500"
                    textSize="base"
                  />
                </div>
              </div>
              <EbmButton
                textColor="black"
                btnName={"Export"}
                icon={<ImportIcons />}
                btnBgColor="gray-50"
                onClick={handleButtonClick}
              />
              <CSVLink
                data={csvData}
                headers={csvHeaders}
                ref={csvLinkRef}
                filename={"Purchase.csv"}
              />
            </div>
            <div className="mt-5">
              <PurchaseDetails />
            </div>
          </div>
        }
      />
      <div className="flex justify-between">
        <h1 className="text-[#030229] text-lg">Purchase Management</h1>
        <div className="flex gap-5">
          <EbmButton
            btnName={"Import"}
            icon={<ImportIcons />}
            btnBgColor="white"
          />
          <EbmButton
            btnName={"Export"}
            icon={<ImportIcons />}
            btnBgColor="white"
            onClick={handleButtonClick}
          />
          <SettingButton
            btnName={"New"}
            icon={<BiPlus color="white" size={20} />}
            btnBgColor="[#605BFF]"
            textColor="white"
            onClick={showModal}
          />
        </div>
      </div>
      <div className="flex justify-between mt-5">
        <div></div>
        {/* <div className="flex gap-5">
          <h1 className="pt-1">From</h1>
          <DatePicker
            defaultValue={dayjs("2019-09-03", dateFormat)}
            minDate={dayjs("2019-08-01", dateFormat)}
            maxDate={dayjs("2020-10-31", dateFormat)}
          />
          <h1 className="pt-1">To</h1>
          <DatePicker
            defaultValue={dayjs("2019-09-03", dateFormat)}
            minDate={dayjs("2019-08-01", dateFormat)}
            maxDate={dayjs("2020-10-31", dateFormat)}
          />
        </div> */}
      </div>
      <div className="grid grid-cols-3 gap-5 mt-7">
        {/* <TwoIconsCard
          amount={`${getDefaultCurrencyCode(company)} 303,406.78`}
          title={"Total VAT Amount"}
          // name={"Increased than last month"}
          // icon={<GoPlus color="#43900C" />}
          iconTwo={<NewIcons />}
        /> */}
        {ebmPurchase?.isFetching ? (
          <LoadingCard />
        ) : (
          <TwoIconsCard
            amount={`${getDefaultCurrencyCode(company)} ${ebmPurchase?.purchaseStats?.data?.totalRevenue?.toLocaleString() ?? 0}`}
            title={"Total Purchase Amount"}
            // name={"Increased than last month"}
            // icon={<GoPlus color="#43900C" />}
            iconTwo={<AmountIcons />}
          />
        )}
        {ebmPurchase?.isFetching ? (
          <LoadingCard />
        ) : (
          <TwoIconsCard
            amount={ebmPurchase?.purchaseStats?.data?.totalSupplier ?? 0}
            title={"Total Supplier"}
            // name={"20 new customers added!"}
            // icon={<GoPlus color="#43900C" />}
            iconTwo={<CustomerAmountIcons />}
          />
        )}

      </div>
      <div className="bg-white p-3 rounded-md mt-5">
        <div className="flex justify-between">
          <EbmFilter
            setSelectedStatus={setSelectedStatus}
            data={
              ebmGeneral?.selectOption &&
              ebmGeneral?.selectOption["Purchase Status"]
            }
          />
          <Search
            placeholder="Search by invoice ID"
            onSearch={onSearch}
            style={{ width: "200px" }}
          />
        </div>
        <div className="flex justify-between">
          <GenearalModal
            openModal={isModalOpen}
            closeModal={handleOk}
            title={
              <h1 className="text-[#030229] font-semibold text-lg text-center py-2">
                Purchase Registration
              </h1>
            }
            component={
              <div className="px-4 h-full w-full">
                <div className="mt-10 w-full">
                  <AddPurchaseForm
                    selectedData={selectedData}
                    onClose={handleOk}
                  />
                </div>
              </div>
            }
            width={1200}
          />
        </div>
        <div className="mt-5">
          <PurchaseTable open={showDrawer} modalOpen={showModal} />
        </div>
      </div>
    </div>
  );
};

export default PurchaseManagement;
