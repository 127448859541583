import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "SupplierLibrary",
  initialState: {
    isFetching: false,
    all: { data: [] },
    selected: null,
    attributes: null,
    new: null,
    updated: null,
    query: null,
    quotations: null,
    purchaseOrders: null,
    invoices: null,
    suppliersByBrand: null,
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setSuppliersByBrand(state, action) {
      state.suppliersByBrand = action.payload;
    },
    setAttributes(state, action) {
      state.attributes = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setUpdated(state, action) {
      state.updated = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setQuotations(state, action) {
      state.quotations = action.payload;
    },
    setPurchaseOrders(state, action) {
      state.purchaseOrders = action.payload;
    },
    setInvoices(state, action) {
      state.invoices = action.payload;
    },
    // setDeleted(state, action) {
    //   state.updated = action.payload;
    // },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
  },
});

export const mySupplierActions = mySlice.actions;

export default mySlice.reducer;
