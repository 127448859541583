import React from "react";
import { Select, Space, Table, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";

interface DataType {
    key: any;
    shop: any;
    received: any;
    sold: any;
    inshop: any;
}
interface DataTypes {
    key: any;
    warehouse: any;
    warehousein: any;
    warehouseout: any;
    totalwarehouse: any;
}
interface DataTypee {
    key: any;
    shop: any;
    device: any;
    quantity: any;
    issues: any;
}

export const ShopStock = () => {
    const columns: ColumnsType<DataType> = [
        {
            title: "Shops",
            dataIndex: "shop",
            key: "shop",
        },
        {
            title: "Total Received",
            dataIndex: "received",
            key: "received",
        },
        {
            title: "Total Sold",
            dataIndex: "sold",
            key: "sold",
        },
        {
            title: "Total in Shops",
            dataIndex: "inshop",
            key: "inshop",
        },
    ];
    const { Text } = Typography;
    const data: DataType[] = [
        {
            key: "1",
            shop: "Nyamirambo_CS",
            received: "343",
            sold: "56",
            inshop: "10",
        },
        {
            key: "2",
            shop: "Nyabugogo_CS",
            received: "200",
            sold: "10",
            inshop: "10",
        },
        {
            key: "3",
            shop: "Huye_CS",
            received: "134",
            sold: "132",
            inshop: "2",
        },
    ];
    const handleChange = (value: string) => {
        console.log(`selected ${value}`);
    };
    return (
        <div className="w-full bg-[#fff] rounded-md h-[50vh] overflow-y-auto p-8">
            <div className="text-[#030229] text-lg flex justify-between">
                <div>
                    <Space>
                        <p className="text-[#030229] text-lg mr-4">Live Stock At Shop</p>
                        <Select
                            defaultValue="By Team Lead"
                            style={{ width: 160 }}
                            onChange={handleChange}
                            options={[
                                { value: "alex", label: "Kagame Alex" },
                                { value: "alice", label: "Kabarebe Alice" },
                            ]}
                        />
                    </Space>
                </div>
                <Select
                    defaultValue="By Region"
                    style={{ width: 120 }}
                    onChange={handleChange}
                    options={[{ value: "shop", label: "Shop" }]}
                />
            </div>
            <div className="flex gap-10 my-3 text-sm">
                <p className="text-[#03022980] font-light text-base pt-3">
                    SC:Service Center
                </p>

                <p className="text-[#03022980] font-light text-base pt-3">
                    CS:Connect Shop
                </p>
            </div>
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                summary={(pageData) => {
                    let totalInShops = 0;
                    let totalInWarehouse = 0;

                    // pageData.forEach(({ revenue:any, quantity }) => {
                    //   totalInShops += revenue;
                    //   totalInWarehouse += quantity;
                    // });

                    return (
                        <>
                            <Table.Summary.Row className="!text-[#2E6C8E] bg-white sticky bottom-0">
                                <Table.Summary.Cell index={0} colSpan={2}>
                                    <Text style={{ fontWeight: "bolder" }}>TOTAL</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={0}>
                                    <Text style={{ fontWeight: "bolder" }}>
                                        {totalInShops?.toLocaleString()}
                                    </Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={0}>
                                    <Text style={{ fontWeight: "bolder" }}>
                                        {totalInWarehouse?.toLocaleString()}
                                    </Text>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );
                }}
            />
        </div>
    );
};

export const DefaultDevices = () => {
    const columns: ColumnsType<DataTypee> = [
        {
            title: "Shops",
            dataIndex: "shop",
            key: "shop",
        },
        {
            title: "Device Modal",
            dataIndex: "device",
            key: "device",
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
        },
        {
            title: "Default Issues",
            dataIndex: "issues",
            key: "issues",
        },
    ];
    const data: DataTypee[] = [
        {
            key: "1",
            shop: "Nyamirambo_CS",
            device: "Galaxy S24",
            quantity: 3,
            issues: "Screen issue",
        },
        {
            key: "2",
            shop: "Nyabugogo_CS",
            device: "Iphone 14",
            quantity: 1,
            issues: "Screen issue",
        },
        {
            key: "3",
            shop: "Huye_CS",
            device: "Galaxy S24",
            quantity: 6,
            issues: "Network issue",
        },
    ];
    const handleChange = (value: string) => {
        console.log(`selected ${value}`);
    };
    return (
        <div className="w-full bg-[#fff] rounded-md h-[50vh] overflow-y-auto p-8">
            <div className="text-[#030229] text-lg flex justify-between">
                <div>
                    <p className="text-[#030229] text-lg mr-4">Default Devices</p>
                </div>
                <Select
                    defaultValue="By Region"
                    style={{ width: 120 }}
                    onChange={handleChange}
                    options={[{ value: "shop", label: "Shop" }]}
                />
            </div>
            <div className="flex gap-10 my-3 text-sm">
                <p className="text-[#03022980] font-light text-base pt-3">
                    SC:Service Center
                </p>

                <p className="text-[#03022980] font-light text-base pt-3">
                    CS:Connect Shop
                </p>
            </div>
            <Table columns={columns} dataSource={data} pagination={false} />
        </div>
    );
};

export const WarehouseStock = () => {
    const columns: ColumnsType<DataTypes> = [
        {
            title: "Warehouse",
            dataIndex: "warehouse",
            key: "warehouse",
        },
        {
            title: "Warehouse In",
            dataIndex: "warehousein",
            key: "warehousein",
        },
        {
            title: "Warehouse Out",
            dataIndex: "warehouseout",
            key: "warehouseout",
        },
        {
            title: "Total Warehouse",
            dataIndex: "totalwarehouse",
            key: "totalwarehouse",
        },
    ];
    const { Text } = Typography;
    const data: DataTypes[] = [
        {
            key: "1",
            warehouse: "Nyamirambo_CS",
            warehousein: "343",
            warehouseout: "56",
            totalwarehouse: "10",
        },
        {
            key: "2",
            warehouse: "Nyamirambo_CS",
            warehousein: "343",
            warehouseout: "56",
            totalwarehouse: "10",
        },
        {
            key: "3",
            warehouse: "Nyamirambo_CS",
            warehousein: "343",
            warehouseout: "56",
            totalwarehouse: "10",
        },
    ];
    const handleChange = (value: string) => {
        console.log(`selected ${value}`);
    };
    return (
        <div className="w-full bg-[#fff] rounded-md h-[50vh] overflow-y-auto p-8">
            <div className="text-[#030229] text-lg flex justify-between">
                <div>
                    <p className="text-[#030229] text-lg mr-4">Live Stock At Warehouse</p>
                </div>
                <Select
                    defaultValue="By Region"
                    style={{ width: 120 }}
                    onChange={handleChange}
                    options={[{ value: "shop", label: "Shop" }]}
                />
            </div>
            <div className="flex gap-10 my-3 text-sm">
                <p className="text-[#03022980] font-light text-base pt-3">
                    SC:Service Center
                </p>

                <p className="text-[#03022980] font-light text-base pt-3">
                    CS:Connect Shop
                </p>
            </div>
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                summary={(pageData) => {
                    let totalInShops = 0;
                    let totalInWarehouse = 0;

                    // pageData.forEach(({ revenue:any, quantity }) => {
                    //   totalInShops += revenue;
                    //   totalInWarehouse += quantity;
                    // });

                    return (
                        <>
                            <Table.Summary.Row className="!text-[#2E6C8E] bg-white sticky bottom-0">
                                <Table.Summary.Cell index={0} colSpan={2}>
                                    <Text style={{ fontWeight: "bolder" }}>TOTAL</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={0}>
                                    <Text style={{ fontWeight: "bolder" }}>
                                        {totalInShops?.toLocaleString()}
                                    </Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={0}>
                                    <Text style={{ fontWeight: "bolder" }}>
                                        {totalInWarehouse?.toLocaleString()}
                                    </Text>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );
                }}
            />
        </div>
    );
};
