import React, { useState } from "react";
import { Button, message } from "antd";
import { DownloadOutlined, PrinterOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

interface MTNRwandacellHeaderProps {
  trackingActivityForOneistory: Array<{
    trackId?: {
      locationTo?: {
        name?: string;
      };
      locationFrom?: {
        name?: string;
      };
    };
  }>;
  totalDistance: number;
  filterTrackingId: string;
  isFromMe: boolean;
}

const MTNRwandacellHeader: React.FC<MTNRwandacellHeaderProps> = ({
  trackingActivityForOneistory = [],
  totalDistance,
  filterTrackingId,
  isFromMe,
}) => {
  const dispatch = useDispatch<any>();
  const { tracking, auth } = useSelector((state: any) => state);
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadDeliveryPdfAction =
    (token: any, id: any) => async (dispatch: any) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/delivNote/deliverypdf/${id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

      

        if (!response.ok) {
          throw new Error("Failed to fetch the PDF");
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `delivery_note_${id}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        console.error("Error downloading the PDF:", error);
        throw error;
      }
    };

  const handleDownload = async () => {
    if (!auth.token) {
      message.error("You need to be authenticated to download the PDF.");
      return;
    }

    if (!tracking?.selected?.data?._id) {
      message.error(
        "No tracking data selected. Please select a tracking item first."
      );
      return;
    }

    setIsDownloading(true);

    try {
      await dispatch(
        downloadDeliveryPdfAction(auth.token, tracking.selected.data._id)
      );
      message.success(
        "PDF downloaded successfully. Check your downloads folder."
      );
    } catch (error) {
      console.error("Failed to download the PDF:", error);
      if (error instanceof Error) {
        message.error(`Failed to download the PDF: ${error.message}`);
      } else {
        message.error("An unknown error occurred while downloading the PDF");
      }
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <div className="flex items-center justify-between bg-white px-4 py-2 shadow-sm">
      <div className="text-xl text-[#030229]]">
        {isFromMe
          ? tracking?.selected?.data?.locationTo?.name
          : tracking?.selected?.data?.locationFrom?.name}
      </div>
      <div className="flex space-x-2">
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          className="bg-green-500 hover:bg-green-600 border-none"
          onClick={handleDownload}
          loading={isDownloading}
        >
          Download
        </Button>
        <Button
          icon={<PrinterOutlined />}
          className="bg-indigo-500 hover:bg-indigo-600 border-none text-white"
        >
          Print
        </Button>
      </div>
    </div>
  );
};

export default MTNRwandacellHeader;
