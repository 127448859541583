import { Navigate, Outlet } from "react-router-dom";
import DashLayout from "../components/layout/Layout";

import TopNav from "../components/navitems/TopNavItems";
import { listManages } from "../assets/data/pages";
import React from "react";
import { useSelector } from "react-redux";
import store from "store";
export const customerDropdownRoutes = [
  {
    caption: "Overview",
    path: "/customers/overview",
  },
  {
    caption: "Customers",
    path: "/customers",
  },
];

export const pagesArray = [
  {
    caption: "Overview",
    path: "/customers/overview",
    onClick: () => {
      console.log("Overview:00");
    },
  },
  {
    caption: "Customers",
    path: "/customers",
    onClick: () => {
      console.log("sale:00");
    },
  },
];
const DashRoutes = () => {
  const { auth, customer } = useSelector((state: any) => state);
  const token = store.get("authToken");

  const allowedRoles = [
    "admin",
    "finance",
    "finance-manager",
    "inventory",
    "sales-manager",
    "sales-supervisor",
    "hr",
    "dev",
    "b2b-relation",
  ];
  const isAuthenticated = auth.token || token;
  const userRole = auth.user?.role;

  let authent = { token: true };

  if (isAuthenticated && allowedRoles.includes(userRole)) {
    return (
      <DashLayout
        allowSearch={true}
        nav={
          <TopNav
            nav={listManages?.find(
              (item) => item.title.toLowerCase() === "Customers".toLowerCase()
            )}
            pages={pagesArray}
          />
        }
        selectedNav={4}
      >
        <Outlet />
      </DashLayout>
    );
  } else if (["sales-agent"].includes(userRole)) {
    return <Navigate to="/login" />;
  } else {
    return <Navigate to="/dashboard" />;
  }
};

export default DashRoutes;
