import { useSelector } from "react-redux";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import { Space, Tag } from "antd";

const VaultNotes = (props: any) => {
  const { vault } = useSelector((state: any) => state);
  const allNotes = vault?.notes?.data;
  
  return (
    <div className="w-full h-[60vh] overflow-y-auto">
      <ScrollableFrame
        setPage={props?.setPage}
        setLimit={props?.setLimit}
        limit={props?.limit}
        total={vault?.notes?.total}
        count={Math.ceil(vault?.notes?.total / props?.limit)}
      >
        <ul className="ml-6 bg-white text-[#030229]">
          {allNotes?.map((el: any, index: any) => {
            return (
              <li className="flex" key={index}>
                <div className="mt-3 h-[auto]">
                  <div className="h-3 w-3 bg-[#0f0f4716] border-[0.1px] border-[#0f0f4746] rounded-full"></div>
                  {index !== allNotes?.length - 1 && (
                    <hr className="border-[0.1px] border-[#0f0f4711] mt-[0.6px] ml-[.3rem] mr-3 h-full cursor-pointer  duration-500" />
                  )}
                </div>
                <div
                  className={`mb-3 px-6 py-2 w-full h-full rounded-md `}
                >
                  <div className="w-[90%] ">
                    {el?.type === "REQUESTED" && (
                      <span>
                        <p className="text-base font-normal">
                          {el?.email} requested to {el?.actionName}
                        </p>
                      </span>
                    )}
                    {el?.type === "TRANSITION_FROM" && (
                      <span>
                        <p className="text-base font-semibold">
                          {el?.actionName} sent . transition from{" "}
                          <Tag color={el?.color}>{el?.policyName}</Tag> to{" "}
                          <Tag color={el?.nextColor}>{el?.nextPolicyName}</Tag>
                        </p>
                      </span>
                    )}
                    <div>
                      <p className="text-[#0f0f475e] text-[12px] pt-2">
                        <Space>
                          <div>{el?.createdAt?.slice(0, 10)}</div>{" "}
                          <div>{el?.createdAt?.slice(11, 16)}</div>
                        </Space>
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </ScrollableFrame>
    </div>
  );
};
export default VaultNotes;
