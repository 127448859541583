import React from 'react';
import { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import { BiDotsVerticalRounded } from "react-icons/bi";

interface HoverDropDownProps {
    items: MenuProps['items'];
    data?:any
}

const DropDown: React.FC<HoverDropDownProps> = ({ items,data }) => {
    return (
        <Dropdown menu={{ items }}>
            <a onClick={(e) => e.preventDefault()}>
                <BiDotsVerticalRounded size={25} />
            </a>
        </Dropdown>
    );
};

export default DropDown;
