import React, { useState } from 'react';
import { Select, Radio, Switch } from 'antd';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const CustomBar = (props: any) => {
  const { x, y, width, height, fill } = props;
  
  const radius = 5;

  return (
    <g>
      <rect x={x} y={y} width={width} height={height} fill={fill} rx={radius} ry={radius} />
    </g>
  );
};

const { Option } = Select;

const data = [
  { month: 'Jan', applicants: 90, shortlisted: 60, rejected: 30 },
  { month: 'Feb', applicants: 92, shortlisted: 62, rejected: 32 },
  { month: 'Mar', applicants: 85, shortlisted: 55, rejected: 25 },
  { month: 'Apr', applicants: 88, shortlisted: 58, rejected: 28 },
  { month: 'May', applicants: 90, shortlisted: 60, rejected: 30 },
  { month: 'Jun', applicants: 87, shortlisted: 57, rejected: 27 },
  { month: 'Jul', applicants: 91, shortlisted: 61, rejected: 31 },
  { month: 'Aug', applicants: 89, shortlisted: 59, rejected: 29 },
  { month: 'Sep', applicants: 86, shortlisted: 56, rejected: 26 },
  { month: 'Oct', applicants: 92, shortlisted: 62, rejected: 32 },
  { month: 'Nov', applicants: 93, shortlisted: 63, rejected: 33 },
  { month: 'Dec', applicants: 90, shortlisted: 60, rejected: 30 },
];

const PayrollStatisticsGraph: React.FC = () => {
  const [value, setValue] = useState('applicants');

  const handleRadioChange = (e: any) => {
    setValue(e.target.value);
  };

  const formatYAxis = (tickItem: number) => {
    return `${tickItem}%`;
  };

  return (
    <div className="p-4 rounded">
      <div className="flex justify-between items-center">
      <div className="mb-4">
        <h2 className="text-lg font-poppins text-[#030229]">PayRoll Summary</h2>
        <div className="my-4 flex flex-row items-center gap-3">
          <div className="flex items-center text-[#030229] gap-3">
           <div className='bg-[#AFADFF] w-4 h-4 rounded-md'></div><span>Gross Salary</span>
          </div>
          <div className="flex items-center text-[#030229] gap-3">
          <div className='bg-[#F17B2C] w-4 h-4 rounded-md'></div><span>Taxes</span>
          </div>
          <div className="flex items-center text-[#030229] gap-3">
          <div className='bg-[#FC778F] w-4 h-4 rounded-md'></div><span>Net Salary</span>
          </div>
        </div>
      </div>
      <Select defaultValue="This Year" style={{ width: 120 }}>
          <Option value="this-year">This Year</Option>
          <Option value="last-year">Last Year</Option>
        </Select>
      </div>
      

      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          barSize={10}
        >
          <XAxis dataKey="month" />
          <YAxis tickFormatter={formatYAxis} />
          <Tooltip />
          <Bar dataKey="applicants" stackId="a" fill="#FC768E" shape={<CustomBar />} />
          <Bar dataKey="shortlisted" stackId="a" fill="#F17B2C" shape={<CustomBar />} />
          <Bar dataKey="rejected" stackId="a" fill="#AFADFF" shape={<CustomBar />} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PayrollStatisticsGraph;
