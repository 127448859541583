import { useState } from "react";
import { Paper, Box, Stack, IconButton } from "@mui/material";
import { generatePath, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import MtnLog from "../../assets/icons/mtn.svg";
import { Modal } from "antd";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { myChannelActions } from "./../../store/channel";
import store from "store";
import ChannelForm from "../../components/forms/newChannelForm";
import DeleteModal from "./../../components/Modals/DeleteModal";
import { deleteChannelsAction } from "../../store/channel/actions";

const Component = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [channelId, setChannelId] = useState(false);
  const [deleteId, setDeleteId] = useState("false");
  const [data, setData] = useState<any>("");
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const { auth, channel } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const handleCancels = () => {
    setVisible(false);
  };

  const style = {
    width: "100vh",
    color: "black",
    p: 2,
  };

  const updateModelOpen = (data: any, id: any) => {
    setIsModalOpen(true);
    setData(data);
    setChannelId(id);
  };
  const deleteModal = (itemId: any) => {
    setDeleteId(itemId);
    setVisible(true);
  };

  const deleteChannel = async () => {
    auth?.token &&
      (await deleteChannelsAction(deleteId, auth?.token, {
        isDeleted: "true",
      })(dispatch));
    setVisible(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showChannelId = (items: any) => {
    store.set("channelId", items?.data?._id);
    dispatch(myChannelActions.setSelected(items.data));
    const Id = items?.data?._id;
    const path = generatePath(
      `${props?.routes === "channel"
        ? `/channel/${items?.name}`
        : `/subscription/devices/${items?.name}/${Id}`
      }`,
      { Id }
    );
    navigate(path);
  };
  return (
    <>
      <div className=" w-s[23%] min-h-[125px] bg-white border-gray-100 text-black border rounded-md p-2 duration-200 hover:border-gray-300">
        <Stack spacing={1} className="relative">
          <div
            className="pl-2 pb-1.5 min-h-[12rem]"
            onClick={() => showChannelId(props.data)}
          >
            <Stack
              direction="row"
              justifyContent={"space-between"}
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Stack spacing={0}>
                <h1 className="font-semibold capitalize">
                  {props?.data?.data?.name}
                </h1>
                {/* <p className="text-xs" style={{ color: "blue" }}>
                {props?.data?.data?.name}
                </p> */}
              </Stack>

              <img
                className="w-24 h-20  rounded-sm"
                src={props?.data?.data?.logo}
                alt=""
              />
            </Stack>
            <Box sx={{ width: "100%", marginTop: "1.5rem" }}>
              <Stack
                direction="row"
                justifyContent={"space-around"}
                alignItems="flex-end"
                sx={{ width: "100%" }}
              >
                {props?.data?.contact?.map(
                  (el: any, index: any) =>
                    index < 2 && (
                      <Stack spacing={0.2} key={index}>
                        <p className="text-xs text-gray-900 capitalize">
                          {el?.name}
                        </p>
                        <p className="text-xs text-gray-900 capitalize">
                          {el?.position}
                        </p>
                        <p className="text-xs text-gray-900">{el?.phone}</p>
                      </Stack>
                    )
                )}
              </Stack>
            </Box>
          </div>

          {props?.routes === "channel" &&
            !["sales-supervisor"]?.includes(auth?.user?.role) && (
              <Stack
                direction="row"
                alignItems="flex-end"
                spacing={2}
                className="absolute right-2 bottom-0"
              >
                <IconButton aria-label="delete" size="small" color="secondary">
                  <RemoveRedEyeIcon
                    fontSize="inherit"
                    onClick={() =>
                      updateModelOpen(props.data.data, props.data.data._id)
                    }
                  />
                </IconButton>
                <IconButton
                  aria-label="edit"
                  size="small"
                  onClick={() =>
                    updateModelOpen(props.data.data, props.data.data._id)
                  }
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>

                <IconButton aria-label="delete" size="small" color="error">
                  <DeleteIcon
                    fontSize="inherit"
                    onClick={() => deleteModal(props.data.data._id)}
                  />
                </IconButton>
              </Stack>
            )}
        </Stack>
      </div>
      <DeleteModal
        visible={visible}
        onOk={deleteChannel}
        isLoading={channel.isFetching}
        onCancel={handleCancels}
        itemName="Channel"
      />
      <Modal
        title={"Update Channel"}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        className="min-w-min"
      >
        <div style={style}>
          <ChannelForm
            data={data}
            channelId={channelId}
            onCancel={handleCancel}
          />
        </div>
      </Modal>
    </>
  );
};

export default Component;
