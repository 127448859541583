import { LoadingButton } from "@mui/lab";
import { Input, notification } from "antd";
import React, { useState } from "react";
import { createsubsDeliveryNoteItemAction } from "../../store/subscription/deliverynote/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getOneSubsOrderAction } from "../../store/subscription/order/actions";
import { mySubscritionActions } from "../../store/subscription/deliverynote";

const ScanPickListForm = (props: any) => {
  const { auth, orders, deliverynoteItem } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const [serialNumber, setSerialNumber] = useState<any>([]);
  const [duplicateItems, setDuplicateItems] = React.useState<any>([]);
  const deliveryNote = orders?.selected?.account?.deliveryNote?._id;

  const handleVerify = async () => {
    if (
      new Set(serialNumber?.trim().split(" ")).size !==
      serialNumber?.trim().split(" ")?.length
    ) {
      setDuplicateItems(
        serialNumber
          .trim()
          ?.split(" ")
          .filter(
            (value: any, index: any) =>
              serialNumber.trim()?.split(" ").indexOf(value) !== index
          )
      );
      return notification.warning({
        message: "IMEI has been duplicated",
        description: `${serialNumber
          ?.split(" ")
          .filter(
            (value: any, index: any) =>
              serialNumber?.toString()?.trim().split(" ").indexOf(value) !==
              index
          )
          .join(" , ")}`,
      });
    }

    if (
      serialNumber?.trim()?.split(" ").length >
      props?.dataToScan?.quantity - props?.dataToScan?.deliveryNoteItem?.length
    ) {
      return notification.warning({
        message: `Required IMEI ${
          props?.dataToScan?.quantity -
          props?.dataToScan?.deliveryNoteItem?.length
        }`,
        description: `Remove ${Math.abs(
          props?.dataToScan?.quantity -
            props?.dataToScan?.deliveryNoteItem?.length -
            serialNumber?.trim()?.split(" ")?.length
        )} IMEI `,
      });
    }

    await createsubsDeliveryNoteItemAction(auth.token, {
      deliveryNote: deliveryNote,
      wareHouseItem: serialNumber?.toString()?.trim().split(" "),
      orderItem: props?.dataToScan?._id,
    })(dispatch);
    props?.closeModal();
    dispatch(mySubscritionActions.setSelected(null));
    auth?.token &&
      getOneSubsOrderAction(auth?.token, orderId as string)(dispatch);
    setSerialNumber("");
  };

  return (
    <div className="relative align-middle">
      <div className="relative align-middle h-60">
        <div>
          <b>Add product Item</b>
          <div className="flex items-center space-x-2 pb-2">
            <b>Total Number Of Product:</b>
            <p>{props?.dataToScan?.quantity}</p>
          </div>
          <div className="h-32 w-full rounded-md bg-gray-200 px-2 py-2">
            <div className="flex flex-row">
              <b>Type:</b>
              <p>{props?.dataToScan?.product?.product?.type}</p>
            </div>
            <div className="flex flex-row">
              <b>Brand:</b> <p>{props?.dataToScan?.product?.product?.brand}</p>
            </div>
            <div className="flex flex-row">
              <b>Model:</b> <p>{props?.dataToScan?.product?.product?.model}</p>
            </div>
            <div className="grid grid-cols-2 w-60">
              {props?.dataToScan?.product?.specification
                ?.slice(2, 6)
                ?.map((el: any) => (
                  <>
                    {el[1] && (
                      <>
                        {" "}
                        <p>{`${el[0]} :`}</p>
                        <p>{`${el[1]}`}</p>
                      </>
                    )}
                  </>
                ))}
            </div>
          </div>
          <div>
            <div>IMEI</div>
            <div className="flex flex-row gap-2">
              <Input
                required
                value={serialNumber}
                onChange={(e) => setSerialNumber(e.target.value)}
              />
              <LoadingButton
                onClick={handleVerify}
                style={{
                  color: "white",
                  backgroundColor: deliverynoteItem?.isFetching
                    ? "gray"
                    : "blue",
                }}
                loading={deliverynoteItem?.isFetching}
                disabled={deliverynoteItem?.isFetching}
              >
                Save
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScanPickListForm;
