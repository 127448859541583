import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Switch } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateBusinesAction } from "../../../../../store/setting/company/actions";
import { useParams } from "react-router-dom";
interface dataType {
  name: string;
  label: string;
  description: string;
  isChecked: boolean;
}

const QuickAction = ({ label, name, description, isChecked }: dataType) => {
  const { company, auth } = useSelector((state: any) => state);
  const { businessProductId } = useParams();
  const dispatch = useDispatch();

  const handleConfirm = async (value: boolean) => {
    const updateData =
      label === "isPosEnabled"
        ? { isPosEnabled: value }
        : { requestFinanceReview: value };
    await updateBusinesAction(
      businessProductId as string,
      updateData,
      auth?.token
    )(dispatch);
  };

  return (
    <div className="w-full">
      <h1 className="text-base font-semibold mb-2">{name}</h1>
      <div className="flex justify-between mr-20">
        <p className="text-sm ">{description}</p>
        <Switch
          onChange={(checked: boolean) => handleConfirm(checked)}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={isChecked}
          className="pt-1.5"
          disabled={company?.isUpdatingFetching}
          loading={company?.isUpdatingFetching}
        />
      </div>
    </div>
  );
};

export default QuickAction;
