import React, { useState } from "react";
import ScanQRCode from "./ScanQRCode";
import { Input, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getCheckParkageAction } from "../../../store/delivery/actions";
import { LoadingOutlined } from "@ant-design/icons";

const QRCode = () => {
  const { tracking } = useSelector((state: any) => state);
  const [scannedCode, setScannedCode] = useState("");
  const dispatch = useDispatch();
  const onFinish = async (values: any) => {
    getCheckParkageAction(
      `?trackingId=${values?.query}&delivererId=${tracking?.selectedDriver?._id}`
    )(dispatch);
  };
  const getCode = (code: string) => {
    onFinish({ query: code });
    setScannedCode(code);
  };
  const onChange = (e: any) => {
    if (e.target.value?.length === 4) {
      getCheckParkageAction(
        `?trackingId=${e.target.value}&delivererId=${tracking?.selectedDriver?._id}`
      )(dispatch);
    }
  };
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <div className="w-full xl:w-1/2 m-auto">
      <div className="mt-5 w-full">
        <h1 className="text-[#030229] text-lg font-semibold py-3 pb-6 text-center">
          Scan QR Code
        </h1>
        <p className="text-[#030229B2] text-lg text-center">
          Scan QR Code on delivery note to verify order
        </p>
        <div className="w-[50%] m-auto cursor-pointer mt-10 flex justify-center items-center">
          <ScanQRCode getCode={getCode} />
        </div>
      </div>
      <div className="text-[#030229B2] text-lg text-center flex-wrap flex gap-4 pl-s20 pt-10 py-5 ">
        <hr className="h-2 w-20 mt-4" />
        Enter the code manually
        <hr className="h-2 w-20 mt-4" />
      </div>
      <div className="relative flex items-center">
        <Input
          className="h-12 pr-10"
          placeholder="Enter 4 last digits"
          onChange={onChange}
          maxLength={4}
        />

        {tracking?.isFetching && (
          <div className="absolute right-3">
            <Spin indicator={antIcon} />
          </div>
        )}
      </div>
    </div>
  );
};

export default QRCode;
