import React, { useState } from "react";

import Specifications from "./DetailsPage/Specifications";
import SimpleProductDetailsCard from "./DetailsPage/SimpleProductDetailsCard";
import DetailsHeaderActionBar from "./DetailsPage/DetailsHeaderActionBar";
import DetailsTabs from "./DetailsPage/DetailsTabs";
import { useNavigate } from "react-router-dom";
import SidePanelDetails from "./DetailsPage/SidePanelDetails";

const DetailsPage = (props: any) => {
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <div className="text-[#030229] p-1 mt-3 space-y-2">
      <DetailsHeaderActionBar
        handleClickDelete={props?.handleClickDelete}
        handleClickEdit={props?.handleClickEdit}
        goBack={goBack}
        pageName={props?.pageName}
        title={props?.title}
      />
      <div className="flex flex-row-reverse gap-2 pt-12">
        <SidePanelDetails
          UpdateForm={props?.UpdateForm}
          handleClickDelete={props?.handleClickDelete}
          handleClickEdit={props?.handleClickEdit}
          simpleDetails={props?.simpleDetails}
          specsData={props?.specsData}
        />
        {/* Tabs */}
        <DetailsTabs
          tabs={props?.tabs}
          activeTab={activeTab}
          handleChange={handleChange}
        />
      </div>
    </div>
  );
};

export default DetailsPage;
