import { Button, Form } from "antd";
import { InputNumber } from "antd/lib";
import React from "react";
import {
  lockDeviceAction,
  unLockDeviceAction,
} from "../../../../store/vault/actions";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
const LockDevice = (props: any) => {
  const { auth, vault } = useSelector((state: any) => state);
  console.log();
  const dispatch = useDispatch();


  const onFinish = async (values: any) => {
    auth?.token &&
      (await lockDeviceAction(auth?.token, {
        deviceUid: [props?.deviceData],
        lockType: "LOCK",
        lockTitle: "Payment Due",
        lockMessage: "Payment is Due",
      })(dispatch));
    props.onClose();
  };
  return (
    <>
      <div>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <h1 className="text-[#030229] text-sm py-2 text-center">
            You are going to lock this device
          </h1>
          <Form.Item>
            <div className="flex gap-3 ml-[5rem] mt-10">
              <Button
                htmlType="submit"
                className="w-32 border border-red-400 hover:border-red-400"
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                className="w-32  border border-[#605BFF]"
              >
                {vault?.isFetching ? (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 24 }} spin />
                    }
                  />
                ) : (
                  "Lock"
                )}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
export default LockDevice;
