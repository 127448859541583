import React from "react";
import { Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { UploadChangeParam } from "antd/es/upload";
import { RcFile, UploadFile } from "antd/es/upload/interface";

interface VaultUploadComponentProps {
  value?: UploadFile[];
  onChange?: (file: UploadFile) => void;
}

const VaultUploadComponent: React.FC<VaultUploadComponentProps> = ({ value = [], onChange }) => {
  const handleChange = ({ file }: UploadChangeParam<UploadFile>) => {
    if (onChange) {
      onChange(file);
    }
  };

  return (
    <Upload
      beforeUpload={() => false}
      onChange={handleChange}
    >
      <Button icon={<UploadOutlined />}>Click to Upload</Button>
    </Upload>
  );
};

export default VaultUploadComponent;
