import { LoadingOutlined } from "@ant-design/icons";
import { Form, Input, Spin } from "antd";
import React, { useEffect, useState } from "react";
import UploadComponent from "../../components/forms/Upload";
import { PlusOutlined } from "@ant-design/icons";
import { Button } from "@mui/material";
import { getIntegrationAction } from "../../store/setting/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  createIntegrationAction,
  updateIntegrationAction,
} from "../../store/setting/integrations/actions";
import TextArea from "antd/es/input/TextArea";
import { CiCircleMinus } from "react-icons/ci";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const CreateIntegrationsForm = (props: any) => {
  const { auth, company } = useSelector((state: any) => state);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [pictures, setPictures] = useState([]);

  const initialData = {
    ...(props?.companyToUpdate || {}),
  };
  console.log(initialData, "initialDatainitialData");
  const onFinish = async (values: any) => {
    const images = pictures.map((pic: any) => pic?.response?.data?.secure_url);
    auth?.token &&
      !props?.channelId &&
      !props?.companyToUpdate?._id &&
      (await createIntegrationAction(auth?.token, {
        ...values,
        logo: images[0],
        api: [{ url: values.url }],
      })(dispatch));
    auth?.token &&
      props?.companyToUpdate?._id &&
      (await updateIntegrationAction(auth?.token, props?.companyToUpdate?._id, {
        ...values,
        logo: images[0],
      })(dispatch));
    await getIntegrationAction(auth?.token, "?")(dispatch);
    form.resetFields();
    props?.onCancel();
  };
  useEffect(() => {
    form.setFieldsValue(props?.companyToUpdate);
  }, [props?.companyToUpdate]);
  return (
    <>
      <Form
        // {...formItemLayout}
        form={form}
        name="validateOnly"
        layout="vertical"
        autoComplete="off"
        style={{ maxWidth: "100%", paddingLeft: "4rem", paddingRight: "4rem" }}
        onFinish={onFinish}
        initialValues={initialData}
      >
        <div className="flex flex-col justify-center items-center mt-5 w-[30rem]">
          <Form.Item>
            <UploadComponent
              className="bg-blue-500"
              setPictures={setPictures}
              limit={1}
              defaultValue={props?.data && props?.data?.logo}
            />
          </Form.Item>
        </div>
        <Form.Item
          name="name"
          label={
            <span className="text-[#030229]  text-sm font-normal">Name</span>
          }
          className="w-full"
        >
          <Input className="h-[42px] w-[100%] text-base" />
        </Form.Item>
        <Form.Item
          name="url"
          label={
            <span className="text-[#030229]  text-sm font-normal">
              url link
            </span>
          }
          className="w-full"
        >
          <Input
            placeholder="Please enter your old PIN"
            className=" h-[42px] w-[100%] "
          />
        </Form.Item>
        <Form.Item
          name="description"
          label={
            <span className="text-[#030229] text-sm font-normal">
              Descriptions
            </span>
          }
          className="w-full"
          rules={[{ required: true, message: "Please input new PIN!" }]}
        // initialValue={""}
        >
          <TextArea
            placeholder="Description"
            autoSize={{ minRows: 2, maxRows: 3 }}
            className="w-full"
          />
        </Form.Item>
        <h1 className="text-base font-medium my-3">Add Users</h1>
        <div>
          <Form.List name="users" {...formItemLayout}>
            {(
              fields: { [x: string]: any; key: any; name: any }[],
              { add, remove }: any
            ) => (
              <div>
                {fields.map(({ key, name, ...restField }) => (
                  <div className="flex flex-row gap-6 border border-grey-400 p-3 rounded-md mt-2">
                    <div>
                      <Form.Item
                        {...restField}
                        name={[name, "name"]}
                        rules={[
                          {
                            required: true,
                            message: "Please input user names",
                          },
                        ]}
                      >
                        <Input className="w-[25rem]" placeholder="Names" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "description"]}
                        rules={[
                          {
                            required: true,
                            message: "Please input user description",
                          },
                        ]}
                      >
                        <TextArea
                          placeholder="Description"
                          autoSize={{ minRows: 2, maxRows: 4 }}
                          className="w-full"
                        />
                      </Form.Item>
                    </div>
                    <div className="my-[auto]">
                      <Form.Item>
                        <CiCircleMinus size={22} onClick={() => remove(name)} />
                      </Form.Item>
                    </div>
                  </div>
                ))}
                <div className="text-left my-6">
                  <Button
                    variant="outlined"
                    onClick={() => add()}
                    startIcon={<PlusOutlined />}
                  >
                    Add User
                  </Button>
                </div>
              </div>
            )}
          </Form.List>
        </div>
        <div className="ml-24">
          <button
            type="submit"
            className={` border w-full bg-[#605BFF] py-2 text-white text-center font-medium text-base items-center rounded-md `}
          >
            {company?.isFetching ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "white" }}
                    color="white"
                    spin
                  />
                }
              />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </Form>
    </>
  );
};
export default CreateIntegrationsForm;
