import React, { useState, useEffect } from "react";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { ReactComponent as OverviewIcon } from "../../../../assets/icons/dashboard/Icon.svg";
import { DatePickerProps, Select } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import OverviewChart from "./overviewChart";
import OrdersHistory from "./ordersHistory";
import {
  getAllNoSaleAction,
  getAllPaymentHistoryAction,
  getRevenueQuantityAction,
  getTotalRevenueAction,
} from "../../../../store/subscription/overview/actions";
import OverViewCard from "./OverViewCard";
import { FaArrowUpLong } from "react-icons/fa6";
import CustomButton from "../../../../components/buttons/CustomButton";
import { FaCaretDown, FaLongArrowAltDown } from "react-icons/fa";
import FinancingLevel from "./financingLevel";
import NoSaleSubscriptionTable from "../../../../components/tables/subscription/NoSaleSubscriptionTable";
import PaymentHistorySubscriptionTable from "../../../../components/tables/subscription/PaymenHistorySubscriptionTable";
import BarChart from "./BarChart";
import CustomerView from "./CustomersView";
import DevicesView from "./DevicesView";
import TopSellingPlans from "./TopSellingPlans";
import CommissionOverview from "./CommissionOverView";
import CustomizedPieChart from "./CustomizedPieChart";
import DatePickerCustom from "../../../../components/buttons/DatePicker";
import { FiPlus } from "react-icons/fi";
import { getAllChannelAction } from "../../../../store/channel/actions";
import { handleSelectedChannelAction } from "../../../../store/layout/actions";
import { getDefaultCurrencyCode } from "../../../../utils/helper";
import { HiOutlineUsers } from "react-icons/hi2";
import {
  ExpectedPaymentAmount,
  TotalAmountPaid,
  TotalDevices,
} from "./OverviewCardData";
import ChannelFilter from "./FilterChannels";
import {
  CustomerScored,
  EligibleCustomer,
  NotEligibleCustomer,
} from "./CustomerEligibility";
import {
  ApprovedApplication,
  CancelledApplication,
  CompletedApplication,
  DraftedApplication,
  FailedApplication,
  InProgressApplication,
  PendingApplication,
  ReceivedApplication,
  CheckRequest,
} from "./LoanEligibility";
import { PlansOverview, SoldDevices } from "./DeviceSold";
import { DefaultDevices, ShopStock, WarehouseStock } from "./InventoryOverview";
interface secProp {
  name?: any;
  value?: any;
  bgColor?: any;
}
export const DeviceSecurity = ({ name, value, bgColor }: secProp) => {
  return (
    <>
      <div className="flex gap-3">
        <div
          className={`bg-${bgColor} w-[16px] h-[12px] rounded-sm mt-1`}
        ></div>
        <div>
          <p className="text-[#605BFF] text-[14px] font-medium">{name}</p>
          <p className="text-[#030229] text-[12px] pl-1">{value}</p>
        </div>
      </div>
    </>
  );
};
const PageView = () => {
  const { auth, overView, company } = useSelector((state: any) => state);
  const [periodValue, setPeriodValue] = useState("range");
  const formattedDate = new Date().toISOString().slice(0, 10);
  const dispatch = useDispatch();
  const channels = auth?.user?.channel;
  const initialChannel = channels && channels?.at(0)?._id;
  const [selectedChannel, setSelectedChannel] = useState("");
  let todayDate = moment();
  let previousDate = todayDate.subtract(1, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");
  const [enteredDate, setEnteredDate] = useState(formattedPreviousDate);
  const [enteredComparedDate, setComparedDate] = useState(formattedDate);
  const today = moment().format("YYYY-MM-DD");
  const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
  const firstDayOfMonth = moment().startOf("month").format("YYYY-MM-DD");
  const mondayOfWeek = moment().startOf("isoWeek").format("YYYY-MM-DD");
  const currentYear = moment().format("YYYY");

  const periodOptions = [
    {
      label: (
        <p className="py-1 px-2  rounded-md text-center text-base">Daily</p>
      ),
      value: "daily",
    },
    {
      label: (
        <p className="py-1 px-2  rounded-md text-center text-base">Weekly</p>
      ),
      value: "weekly",
    },
    {
      label: (
        <p className="py-1 px-2  rounded-md text-center text-base">Monthly</p>
      ),
      value: "month",
    },
    {
      label: (
        <p className="py-1 px-2  rounded-md text-center text-base">Yearly</p>
      ),
      value: "year",
    },
    {
      label: (
        <p className="py-2 px-2  rounded-md text-center text-base">Range</p>
      ),
      value: "range",
    },
  ];
  const businessProductptions = [
    { label: "All Bussiness Products", value: "all" },
    { label: "B2B Cooperate", value: "cooperate" },
    { label: "B2C", value: "b2c" },
    { label: "B2B Product", value: "b2bproduct" },
    { label: "B2B2C", value: "b2b2c" },
  ];
  const data = [
    { name: "Idle", value: 80 },
    { name: "Ready for use", value: 45 },
    { name: "Locked", value: 25 },
    { name: "Released", value: 25 },
    { name: "Active", value: 25 },
  ];
  const COLORS = [
    "#5F00FFB2",
    "#E25668B2",
    "#E256AEB2",
    "#E28956B2",
    "#CF56E2B2",
    "#8A56E2B2",
  ];
  const onChange = (value: string) => {
    setPeriodValue(value);
  };

  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  const onChangeDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setEnteredDate(dateString);
  };
  const onChangeComparedDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setComparedDate(dateString);
  };
  // Channel
  useEffect(() => {
    auth?.token && getAllChannelAction(auth?.token)(dispatch);
  }, [auth?.token, dispatch]);
  useEffect(() => {
    initialChannel && setSelectedChannel(initialChannel);
    (initialChannel || selectedChannel) &&
      handleSelectedChannelAction(initialChannel ?? selectedChannel)(dispatch);
  }, [dispatch, initialChannel, selectedChannel]);

  useEffect(() => {
    if (auth.token) {
      getAllPaymentHistoryAction(auth.token, "?")(dispatch);
    }
  }, [dispatch, auth.token]);

  // Total Revenue
  useEffect(() => {
    if (auth.token) {
      if (periodValue === "daily") {
        getTotalRevenueAction(
          auth?.token,
          `?day=daily&date1=${yesterday}&date2=${today}&channel=${
            initialChannel ?? selectedChannel
          }`
        )(dispatch);
      }
      if (periodValue === "weekly") {
        getTotalRevenueAction(
          auth?.token,
          `?date1=${mondayOfWeek}&date2=${today}&channel=${
            initialChannel ?? selectedChannel
          }`
        )(dispatch);
      }
      if (periodValue === "month") {
        getTotalRevenueAction(
          auth?.token,
          `?date1=${firstDayOfMonth}&date2=${today}&channel=${
            initialChannel ?? selectedChannel
          }`
        )(dispatch);
      }
      if (periodValue === "year") {
        getTotalRevenueAction(
          auth?.token,
          `?year=${currentYear}&channel=${initialChannel ?? selectedChannel}`
        )(dispatch);
      }
      if (periodValue === "range") {
        getTotalRevenueAction(
          auth?.token,
          `?date1=${enteredDate}&date2=${enteredComparedDate}&channel=${
            initialChannel ?? selectedChannel
          }`
        )(dispatch);
      }
    }
  }, [
    dispatch,
    auth.token,
    periodValue,
    yesterday,
    today,
    currentYear,
    mondayOfWeek,
    firstDayOfMonth,
    enteredDate,
    enteredComparedDate,
    initialChannel,
    selectedChannel,
  ]);

  // No Sales
  useEffect(() => {
    if (auth?.token) {
      if (periodValue === "daily") {
        getAllNoSaleAction(
          auth?.token,
          `?day=daily&date2=${yesterday}&date1=${today}&channel=${
            initialChannel ?? selectedChannel
          }`
        )(dispatch);
      }
      if (periodValue === "weekly") {
        getAllNoSaleAction(
          auth?.token,
          `?date2=${mondayOfWeek}&date1=${today}&channel=${
            initialChannel ?? selectedChannel
          }`
        )(dispatch);
      }
      if (periodValue === "month") {
        getAllNoSaleAction(
          auth?.token,
          `?date2=${firstDayOfMonth}&date1=${today}&channel=${
            initialChannel ?? selectedChannel
          }`
        )(dispatch);
      }
      if (periodValue === "year") {
        getAllNoSaleAction(
          auth?.token,
          `?year=${currentYear}&channel=${initialChannel ?? selectedChannel}`
        )(dispatch);
      }
      if (periodValue === "range") {
        getAllNoSaleAction(
          auth?.token,
          `?date1=${enteredDate}&date2=${enteredComparedDate}&channel=${
            initialChannel ?? selectedChannel
          }`
        )(dispatch);
      }
    }
  }, [
    enteredDate,
    dispatch,
    auth?.token,
    periodValue,
    yesterday,
    currentYear,
    firstDayOfMonth,
    today,
    mondayOfWeek,
    enteredComparedDate,
    initialChannel,
    selectedChannel,
  ]);

  // Quantity financed & Amount
  useEffect(() => {
    if (auth.token) {
      if (periodValue === "daily") {
        getRevenueQuantityAction(
          auth.token,
          `?date1=${yesterday}&date2=${today}&channel=${
            initialChannel ?? selectedChannel
          }`
        )(dispatch);
      }
      if (periodValue === "range") {
        getRevenueQuantityAction(
          auth.token,
          `?date1=${enteredDate}&date2=${enteredComparedDate}&channel=${
            initialChannel ?? selectedChannel
          }`
        )(dispatch);
      }
    }
  }, [
    dispatch,
    auth.token,
    periodValue,
    enteredDate,
    enteredComparedDate,
    initialChannel,
    selectedChannel,
    yesterday,
    today,
  ]);

  return (
    <div className="w-full min-h-screen mt-6 mb-10 text-[#030229]">
      <div className="text-[#0F0F47] mb-4 flex flex-row justify-between">
        <DetailsHeaderActionBar pageName="Subscription" title={"Overview"} />
        <div>
          <CustomButton
            btnName={
              <div className="flex gap-3 py-1">
                <FiPlus color={"white"} size={20} />
                <p>Create Deal</p>{" "}
              </div>
            }
            btnBgColor={"[#605BFF]"}
            textColor={"white"}
            borderColor={"[#605BFF0A]"}
          />
        </div>
      </div>
      <div className={`flex space-x-2 items-center justify-between`}>
        {["partner"]?.includes(auth?.user?.role) ? (
          <h1 className="font-medium"> RWANDA's SALES KPI DASHBOARD</h1>
        ) : (
          <div className="sm:flex gap-5">
            <ChannelFilter setSelectedChannel={setSelectedChannel} />
            <Select
              size="middle"
              placeholder="Select Bussiness product"
              style={{ width: 220 }}
              defaultValue="all"
              onChange={onChange}
              onSearch={onSearch}
              options={businessProductptions}
            />
          </div>
        )}

        <div className="space-x-6 flex">
          {periodValue === "range" ? (
            <div className="flex gap-3 border border-[#0302291A] p-1 rounded-md">
              <DatePickerCustom
                onChangeDate={onChangeDate}
                enteredDate={enteredDate}
              />
              <DatePickerCustom
                onChangeDate={onChangeComparedDate}
                enteredDate={enteredComparedDate}
              />
              <FaCaretDown
                onClick={() => setPeriodValue("")}
                color="#03022980"
                className="mt-1 cursor-pointer"
                size={20}
              />
            </div>
          ) : (
            <Select
              placeholder="Select Period"
              style={{ width: 200 }}
              defaultValue="range"
              optionFilterProp="label"
              onChange={onChange}
              options={periodOptions}
            />
          )}
        </div>
      </div>
      <div className={`w-full xl:flex gap-8 mt-5`}>
        <div className={`w-full xl:w-[60%]`}>
          <OverviewChart
            data={overView?.totalRevenue?.data}
            periodValue={periodValue}
          />
          <div className={`hidden xl:grid grid-cols-2 gap-8 mt-8`}>
            <TotalDevices />
            <ExpectedPaymentAmount />
          </div>
        </div>
        <div className={`w-full xl:w-[40%] mt-5 xl:mt-0`}>
          <div
            className={`w-full bg-white rounded-md h-[90vh] 2xl:h-[80vh] overflow-y-auto`}
          >
            <OrdersHistory
              data={overView?.noSale?.data}
              periodValue={periodValue}
            />
            <div className={` overflow-y-auto px-3 mt-5 h-[25vh]}`}>
              <NoSaleSubscriptionTable
                data={overView?.noSale?.data?.date1noSales}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6">
        <TotalAmountPaid />
        <OverViewCard
          title={"Total OutStanding Balance"}
          amount={`${getDefaultCurrencyCode(company)} ${500}`}
          icon={<OverviewIcon />}
          comparedAmount={`${getDefaultCurrencyCode(company)} ${400}`}
          parcent={<p className="text-[#F1595C]">2.47%</p>}
          textColor="[#57C38A]"
          icon2={<FaLongArrowAltDown color="#F1595C" size={15} />}
          compareText={"Decreased"}
          description={"Across all customers"}
          btn={
            <CustomButton
              btnName={<p className="text-[10px] font-normal"> View Reports</p>}
              textColor={"[#605BFF]"}
              borderColor={"[#605BFF40]"}
            />
          }
        />
        <OverViewCard
          title={"Upcoming Payments"}
          amount={`${getDefaultCurrencyCode(company)} ${500}`}
          comparedAmount={`${getDefaultCurrencyCode(company)} ${200}`}
          icon={<OverviewIcon />}
          icon2={<FaArrowUpLong color="#57C38A" size={15} />}
          description={"Across all customers"}
          textColor="[#57C38A]"
          parcent={<p className="text-[#57C38A]">2.47%</p>}
          compareText={"Increased"}
          btn={
            <CustomButton
              btnName={<p className="text-[10px] font-normal"> View Reports</p>}
              textColor={"[#605BFF]"}
              borderColor={"[#605BFF40]"}
            />
          }
        />
        <OverViewCard
          title={"Total Overdue Payments"}
          amount={`${getDefaultCurrencyCode(company)} ${500}`}
          icon={<HiOutlineUsers color="605BFF" size={24} />}
          comparedAmount={`${getDefaultCurrencyCode(company)} ${400}`}
          textColor="[#57C38A]"
          parcent={<p className="text-[#57C38A]">2.47%</p>}
          icon2={<FaArrowUpLong color="#57C38A" size={15} />}
          description={"Across all customers"}
          compareText={"Increased"}
          btn={
            <CustomButton
              btnName={<p className="text-[10px] font-normal"> View Reports</p>}
              textColor={"[#605BFF]"}
              borderColor={"[#605BFF40]"}
            />
          }
        />
      </div>
      <div className="w-full mt-[2.5rem]">
        <div className="grid md:grid-cols-2 gap-4">
          <div className="bg-white rounded-md p-5 text-black w-d[504px]">
            <h1 className="text-[#030229] text-base pb-2">
              Devices Financed Under Channels
            </h1>
            <FinancingLevel name={"MTN Rwanda"} width={"45%"} amount={250} />
            <FinancingLevel
              name={"Samphone Rwanda"}
              width={"35%"}
              amount={250}
            />
            <FinancingLevel
              name={"Everyday Phone"}
              width={"20%"}
              amount={250}
            />
            <FinancingLevel
              name={"Save The Children"}
              width={"70%"}
              amount={250}
            />
          </div>
          <div className="bg-white rounded-md p-5 text-black w-[50d4px]">
            <h1 className="text-[#030229] text-base pb-2">
              Devices Financed Under Business Products
            </h1>
            <FinancingLevel name={"B2B Telecom"} width={"45%"} amount={250} />
            <FinancingLevel
              name={"B2C Direct Prepaid"}
              width={"35%"}
              amount={250}
            />
            <FinancingLevel
              name={"B2C Indirect Prepaid"}
              width={"20%"}
              amount={250}
            />
            <FinancingLevel name={"B2C PostPaid"} width={"70%"} amount={250} />
          </div>
        </div>
      </div>
      <div className="text-[20px] font-medium ml-4 opacity-90 my-8">
        Customer Eligibility Overview
      </div>
      <div className="mt-5 grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-6">
        <CustomerScored />
        <EligibleCustomer />
        <NotEligibleCustomer />
      </div>
      <div className="text-[20px] font-medium ml-4 opacity-90 my-8">
        Loan Application Overview
      </div>
      <div className="mt-5 grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-6">
        <ReceivedApplication />
        <DraftedApplication />
        <PendingApplication />
      </div>
      <div className="mt-5 grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-6">
        <ApprovedApplication />
        <InProgressApplication />
        <CompletedApplication />
      </div>
      <div className="mt-5 grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-6">
        <CancelledApplication />
        <FailedApplication />
        <CheckRequest />
      </div>
      <div className="text-[20px] font-medium ml-4 opacity-90 my-8">
        Inventory Overview
      </div>
      <div className=" xl:flex gap-5 w-full mt-5">
        <div className="bg-white rounded-md p-5 w-full xl:w-2/4 mt-5 xl:mt-0">
          <h1 className="text-[#030229] text-lg">Vault Status</h1>
          <CustomizedPieChart
            data={data}
            colors={COLORS}
            totalLabel={<h1 className="text-[12px]">Total Devices</h1>}
            innerRad={43}
            height={"180px"}
            contentWidth={"w-full"}
            top={"50%"}
            left={"37%"}
            outerRad={60}
            totalValue={50}
            pieHeight={200}
            pieWidth={300}
          />
        </div>
        <ShopStock />
        {/* <div className="bg-white rounded-md p-5 w-full xl:w-[60%] mt-5 xl:mt-0">
          <h1 className="text-[#030229] text-lg py-3">Device Last Seen</h1>
          <BarChart />
        </div> */}
        {/* <div className="bg-white rounded-md p-5 w-full xl:w-1/4 mt-5 xl:mt-0">
          <h1 className="text-[#030229] text-lg">Device Security</h1>
          <div className="grid grid-cols-2 gap-5 mt-8">
            <DeviceSecurity
              name={`Hight Risk`}
              value={"0|0.00%"}
              bgColor={"[#DA291C]"}
            />
            <DeviceSecurity
              name={`Medium Risk`}
              value={"0|0.00%"}
              bgColor={"[#ED8B00]"}
            />
            <DeviceSecurity
              name={`Low Risk`}
              value={"0|0.00%"}
              bgColor={"[#FEDB00]"}
            />
            <DeviceSecurity
              name={`No Risk`}
              value={"0|0.00%"}
              bgColor={"[#57C38A]"}
            />
            <DeviceSecurity
              name={`Undetermined Risk`}
              value={"0|0.00%"}
              bgColor={"[#BDBDBD]"}
            />
          </div>
        </div> */}
      </div>
      <div className=" xl:flex gap-5 w-full mt-5">
        <div className="bg-white rounded-md p-5 w-full xl:w-2/4 mt-5 xl:mt-0">
          <DefaultDevices />
        </div>
        <WarehouseStock />
      </div>
      <div className="text-[20px] font-medium ml-4 opacity-90 my-8">
        Shop Perfomance and Plan Overview
      </div>
      <div className=" xl:flex gap-5 w-full mt-5">
        <div className="bg-white rounded-md p-5 w-full xl:w-2/4 mt-5 xl:mt-0">
          <PlansOverview />
        </div>
        <SoldDevices />
      </div>
      {/* <div className="w-full xl:flex gap-5 mt-5">
        <div className="w-full xl:w-[70%] p-s5 xl:flex gap-5">
          <div className="w-full xl:w-1/2 bg-white p-5 mt-5 xl:mt-0">
            <DevicesView />
          </div>
          <div className="w-full xl:w-1/2 bg-white p-5 mt-5 xl:mt-0">
            <CustomerView />
          </div>
        </div>
        <div className="w-full xl:w-[30%] xl:ml-3 mt-5 xl:mt-0">
          <TopSellingPlans />
        </div>
      </div> */}
      <div>
        <div className="mt-5 grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-3">
          <OverViewCard
            title={"Total Commission"}
            amount={"500"}
            icon={<OverviewIcon />}
            comparedAmount={"400"}
            textColor={"[#F1595C]"}
            parcent={<p className="text-[#F1595C]">2.47%</p>}
            icon2={<FaLongArrowAltDown color="#F1595C" size={15} />}
            compareText={"Decreased"}
            description={
              <div>
                <p>
                  $2,000{" "}
                  <span className="text-[#03022980]">(Upfront Payment)</span>
                </p>
                <p>
                  $7,000{" "}
                  <span className="text-[#03022980]">(Other Intallment)</span>
                </p>
              </div>
            }
            btn={
              <CustomButton
                btnName={
                  <p className="text-[12px] font-normal"> View Reports</p>
                }
                textColor={"[#605BFF]"}
                borderColor={"[#605BFF40]"}
              />
            }
          />
          <OverViewCard
            title={"Total Commission For Managers"}
            amount={"500"}
            icon={<OverviewIcon />}
            comparedAmount={"400"}
            parcent={<p className="text-[#F1595C]">2.47%</p>}
            textColor="[#57C38A]"
            icon2={<FaLongArrowAltDown color="#F1595C" size={15} />}
            compareText={"Decreased"}
            description={"Across all customers"}
            btn={
              <CustomButton
                btnName={
                  <p className="text-[12px] font-normal"> View Reports</p>
                }
                textColor={"[#605BFF]"}
                borderColor={"[#605BFF40]"}
              />
            }
          />
          <OverViewCard
            title={"Total Commission For Team Leads"}
            amount={"$500"}
            comparedAmount={"200"}
            icon={<OverviewIcon />}
            icon2={<FaArrowUpLong color="#57C38A" size={15} />}
            description={"Across all customers"}
            textColor="[#57C38A]"
            parcent={<p className="text-[#57C38A]">2.47%</p>}
            compareText={"Increased"}
            btn={
              <CustomButton
                btnName={
                  <p className="text-[12px] font-normal"> View Reports</p>
                }
                textColor={"[#605BFF]"}
                borderColor={"[#605BFF40]"}
              />
            }
          />
          <OverViewCard
            title={"Total Commission For Feelancers"}
            amount={"$500"}
            comparedAmount={"200"}
            icon={<OverviewIcon />}
            icon2={<FaArrowUpLong color="#57C38A" size={15} />}
            description={"Across all customers"}
            textColor="[#57C38A]"
            parcent={<p className="text-[#57C38A]">2.47%</p>}
            compareText={"Increased"}
            btn={
              <CustomButton
                btnName={
                  <p className="text-[12px] font-normal"> View Reports</p>
                }
                textColor={"[#605BFF]"}
                borderColor={"[#605BFF40]"}
              />
            }
          />
        </div>
      </div>
      <div className="mt-5 bg-white rounded-md">
        <CommissionOverview />
      </div>
      <div className="flex min-w-full gap-4 mt-6">
        <div className="w-full mt-5 text-black bg-white rounded-md p-5 h-[80vh] overflow-y-auto">
          <div className="flex justify-between py-3">
            <h1 className="text-[#030229] text-xl font-light">
              Recent Payment
            </h1>
            <CustomButton btnName={"See More"} textColor={"[#605BFF]"} />
          </div>
          <PaymentHistorySubscriptionTable />
        </div>
      </div>
    </div>
  );
};
export default PageView;
