import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";

const TableSkeleton = () => {
  return (
    <div className="h-[calc(100vh-300px)]">
      <Stack>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Skeleton variant="text" sx={{ width: 100 }} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" sx={{ width: 100 }} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" sx={{ width: 100 }} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" sx={{ width: 100 }} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" sx={{ width: 100 }} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" sx={{ width: 100 }} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" sx={{ width: 100 }} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" sx={{ width: 100 }} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...Array(6)].map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box className="flex gap-3 mt-10">
          <Skeleton variant="text" sx={{ width: 70, height: 50 }} />
          <Skeleton variant="text" sx={{ width: 70 }} />
        </Box>
      </Stack>
    </div>
  );
};

export default TableSkeleton;
