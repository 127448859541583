import * as React from "react";
import Tab from "@mui/material/Tab";
import { Link, useLocation } from "react-router-dom";

const linkStyle = {
  textDecoration: "none", // Remove the default underline
};

export default function DisabledTabs(props: any) {
  const location = useLocation();

  // Function to check if a link is active
  const isLinkActive = (to: string) => {
    return location.pathname === to;
  };

  return (
    <div>
      <div>
        <Link
          to="/subscription/invoice/"
          style={linkStyle}
          className={`${
            isLinkActive("/subscription/invoice/")
              ? "text-[#605BFF] underline"
              : "text-black"
          }`}
        >
          <Tab label="OverView" />
        </Link>
        <Link
          to="/subscription/invoice/billings"
          style={linkStyle}
          className={`${
            isLinkActive("/subscription/invoice/billings")
              ? "text-[#605BFF] underline"
              : "text-black"
          }`}
        >
          <Tab label="Billing" />
        </Link>
      </div>
    </div>
  );
}
