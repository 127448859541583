import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const createPermissionService = async (data: any, token: string) => {
  return await HttpRequest.post(
    `${SERVER_URL}/settings/permission`,
    data,
    token
  );
};

export const getPermissionService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/settings/permission${query}`, token);
};

export const updatePermissionService = async (
  token: string,
  itemId: string,
  data: any
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/settings/permission/one/${itemId}`,
    data,
    token
  );
};

export const createRoleService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/settings/role`, data, token);
};

export const updateRoleService = async (
  token: string,
  itemId: string,
  data: any
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/settings/role/one/${itemId}`,
    data,
    token
  );
};
