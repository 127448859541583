import React, { useEffect, useState } from "react";
import { useJwt } from "react-jwt";
import { useNavigate } from "react-router-dom";
import { authActions } from "../../store/auth";
import { useDispatch } from "react-redux";

interface TokenPayload {
  exp: number;
}

const AutoLogout: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { decodedToken, isExpired } = useJwt<TokenPayload>(
    localStorage.getItem("authToken") as string
  );
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const startTimer = () => {
    const expirationTime = decodedToken?.exp ? decodedToken.exp * 1000 : 0; // Convert expiration time to milliseconds
    const currentTime = new Date().getTime();
    const timeRemaining = expirationTime - currentTime;

    if (decodedToken) {
      if (timeRemaining > 0) {
        setTimer(setTimeout(handleLogout, timeRemaining));
      } else {
        console.log("logging out...", timeRemaining);
        handleLogout();
      }
    } else {
      console.log("expired....");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("authToken");
    dispatch(authActions.logout());

    navigate("/login");
  };
  useEffect(() => {
    const checkToken = () => {
      startTimer(); // Start the timer if the token is valid

      return () => {
        if (timer) clearTimeout(timer); // Clean up the timer when the component unmounts
      };
    };

    setTimeout(() => {
      checkToken();
    }, 7000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpired, timer]);

  return null;
};

export default AutoLogout;
