import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: " Company Library",
  initialState: {
    isFetching: false,
    isUpdatingFetching: false,
    isGettingSelectedOne: false,
    customFormIsFetching: false,
    isCustomFormCreated: false,
    all: { data: [] },
    selected: null,
    updated: null,
    attributes: null,
    new: null,
    newBusiness: null,
    allBusiness: null,
    sizedLog: null,
    trackingType: null,
    selectedBusinessProduct: null,
    newCustomForm: null,
    selectedCustomForm: null,
    customForm: null,
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setAllBusiness(state, action) {
      state.allBusiness = action.payload;
    },
    setAttributes(state, action) {
      state.attributes = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setNewBusiness(state, action) {
      state.newBusiness = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setSizedLog(state, action) {
      state.sizedLog = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setUpdated(state, action) {
      state.updated = action.payload;
    },
    setTrackingType(state, action) {
      state.trackingType = action.payload;
    },
    setIsUpdatingFetching(state, action) {
      state.isUpdatingFetching = action.payload;
    },
    setSelectedBusinessProduct(state, action) {
      state.selectedBusinessProduct = action.payload;
    },
    setIsGettingSelectedOne(state, action) {
      state.isGettingSelectedOne = action.payload;
    },
    setNewCustomForm(state, action) {
      state.newCustomForm = action.payload;
    },
    setCustomFormIsFetching(state, action) {
      state.customFormIsFetching = action.payload;
    },
    setIsCustomFormCreated(state, action) {
      state.isCustomFormCreated = action.payload;
    },
    setSelectedCustomForm(state, action) {
      state.selectedCustomForm = action.payload;
    },
    setCustomForm(state, action) {
      state.customForm = action.payload;
    },
  },
});

export const myCompanyActions = mySlice.actions;

export default mySlice.reducer;
