import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getAllSubdeliverynote = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/subsDeliveryNote/${query}`, token);
};

export const getOneSubdeliveryNoteService = async (
  id: string,
  token: string
) => {
  return HttpRequest.get(`${SERVER_URL}/subsDeliveryNote/one/${id}`, token);
};

export const getAllSubdeliverynoteItem = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/subsDeliveryNoteItem${query}`, token);
};

export const createsubsDeliveryNoteItemService = async (
  data: any,
  token: string
) => {
  try {
    const response = await HttpRequest.post(
      `${SERVER_URL}/subsDeliveryNoteItem`,
      data,
      token
    );
    return response;
  } catch (error) {
    // Handle the error here
    console.error("An error occurred:", error);
    throw error; // You can choose to rethrow the error or handle it differently
  }
};

export const updateSubsDeliveryNoteService = async (
  deliveryNoteId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/subsDeliveryNote/one/${deliveryNoteId}`,
    data,
    token
  );
};

// Update Deliverynote Item ID
export const updateSubsDeliveryNoteItemService = async (
  deliveryNoteId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/subsDeliveryNoteItem/one/${deliveryNoteId}`,
    data,
    token
  );
};

export const generateB2bInvoiceService = async (
  orderId: any,
  token: string
) => {
  return await HttpRequest.post(
    `${SERVER_URL}/invoice/b2b/cart/${orderId}`,
    orderId,
    token
  );
};

export const printPDFService = async (id: string, token: string) => {
  return HttpRequest.get(
    `${SERVER_URL}/subsDeliveryNote/file/one/${id}`,
    token
  );
};

export const deleteSubsDeliveryItemService = async (
  id: string,
  token: string
) => {
  return HttpRequest.delete(
    `${SERVER_URL}/subsDeliveryNoteItem/one/${id}`,
    token
  );
};
