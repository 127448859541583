import { notification } from "antd";
import { myPermissionActions } from ".";
import {
  createPermissionService,
  createRoleService,
  getPermissionService,
  updatePermissionService,
  updateRoleService,
} from "./services";
import { getRoleService } from "../services";
import { myEmployeeActions } from "..";

export const getPermissionAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPermissionActions.setIsFetching(true));
      const res = await getPermissionService(token, query);
      // console.log(">>>>!!!>>>:herrreee:)) ", res);
      if (res?.status === 200) {
        dispatch(myPermissionActions.setAll(res));
        dispatch(myPermissionActions.setIsFetching(false));
      }
      dispatch(myPermissionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const createPermissionAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPermissionActions.setIsFetching(true));
      const res = await createPermissionService(data, token);

      if (res?.status === 201) {
        const resAll = await getPermissionService(token, "?");
        dispatch(myPermissionActions.setNew(res));
        dispatch(myPermissionActions.setAll(resAll));
        dispatch(myPermissionActions.setIsFetching(false));
        notification.success({ message: "Created Succesfully" });
      }
      dispatch(myPermissionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updatePermissionAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPermissionActions.setIsFetching(true));
      const res = await updatePermissionService(token, itemId, data);
      if (res?.status === 200) {
        const resAll = await getPermissionService(token, "?");
        dispatch(myPermissionActions.setAll(resAll));
        dispatch(myPermissionActions.setIsFetching(false));
        notification.success({ message: "Updated Successfully" });
      }
      dispatch(myPermissionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateRoleAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPermissionActions.setIsFetching(true));
      const res = await updateRoleService(token, itemId, data);
      console.log(res , 'response for update')
      if (res?.status === 200) {
        const resAll = await getPermissionService(token, "?");
        dispatch(myPermissionActions.setAll(resAll));
        dispatch(myPermissionActions.setIsFetching(false));
        notification.success({ message: "Updated Successfully" });
      }
      dispatch(myPermissionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const deletePermissionAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPermissionActions.setIsFetching(true));
      const res = await updatePermissionService(token, itemId, data);
      if (res?.status === 200) {
        const resAll = await getPermissionService(token, "?");
        dispatch(myPermissionActions.setAll(resAll));
        dispatch(myPermissionActions.setIsFetching(false));
        notification.success({ message: "Removed Successfully" });
      }
      dispatch(myPermissionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const createRoleAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPermissionActions.setIsFetching(true));
      const res = await createRoleService(data, token);

      if (res?.status === 201) {
        const resAll = await getRoleService(token, "?");
        dispatch(myPermissionActions.setNewRole(res));
        dispatch(myEmployeeActions.setRole(resAll));
        dispatch(myPermissionActions.setIsFetching(false));
        notification.success({ message: "Created Succesfully" });
      }
      dispatch(myPermissionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteRoleAction = (token: string, itemId: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPermissionActions.setIsFetching(true));
      const res = await updateRoleService(token, itemId, data);
      if (res?.status === 200) {
        const resAll = await getRoleService(token, "?");
        dispatch(myEmployeeActions.setRole(resAll));
        dispatch(myPermissionActions.setIsFetching(false));
        notification.success({ message: "Removed Successfully" });
      }
      dispatch(myPermissionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
