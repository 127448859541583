import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DetailsHeaderActionBar from "../../../../components/details/supplier/DetailsPage/DetailsHeaderActionBar";
import { myLayoutActions } from "../../../../store/layout";
import TopUserDetails from "./topSideUserDetails";
import UserDetailsTabs from "./userDetailsTab";
import { getOneUserAction } from "../../../../store/setting/actions";

const UserDetailsPage = (props: any) => {
  const { auth, setting,layout } = useSelector((state: any) => state);
  const [activeTab, setActiveTab] = useState(0);
const dispatch=useDispatch();
  const goBack = () => {
    dispatch(myLayoutActions.setViewUser(false));
    };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  useEffect(() => {
    layout?.viewUser && auth?.token && getOneUserAction(layout?.userInfo?._id as string, auth?.token)(dispatch);
  }, [layout?.viewUser, auth?.token, dispatch, layout?.userInfo?._id]);
  return (
    <div className="text-black p-1 mt-3 space-y-2">
      <DetailsHeaderActionBar
        handleClickDelete={props?.handleClickDelete}
        handleClickEdit={props?.handleClickEdit}
        goBack={goBack}
        pageName={"Employee"}
        title={layout?.userInfo?.names}
        data={""}
      />
      <div >
        <TopUserDetails />
{/* {setting?.selected?.role?.toLowerCase() === "sales-agent" && <UserDetailsTabs/>} */}
<UserDetailsTabs/>
       
      </div>
    </div>
  );
};

export default UserDetailsPage;
