import React from "react";
import { CSVLink } from "react-csv";
import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";

interface CSVTableDownloadProps {
  columns?: any[];
  csvHeaders: { label: string; key: string }[];
  csvData: any[];
  filename: any;
}

const ExportCSVFile: React.FC<CSVTableDownloadProps> = ({
  csvHeaders,
  csvData,
  filename,
}) => {
  const csvLinkRef = React.useRef<any>(null);

  const handleButtonClick = () => {
    if (csvLinkRef && csvLinkRef.current) {
      csvLinkRef.current.link.click();
    }
  };
  return (
    <>
      <Button
        onClick={handleButtonClick}
        className="border-none cursor-pointer"
      >
        Export CSV
        <DownloadOutlined className="mr-1" />
      </Button>
      <CSVLink
        data={csvData}
        headers={csvHeaders}
        ref={csvLinkRef}
        filename={filename}
      />
    </>
  );
};

export default ExportCSVFile;
