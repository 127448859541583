import React, { useEffect, useState } from "react";
import SettingButton from "../../../pages/dashboard/setting/SettingButton";
import { BiPlus } from "react-icons/bi";
import { ReactComponent as ImportIcons } from "../assets/images/icons/vscode-icons_file-type-excel2.svg";
import EbmButton from "../components/EbmButton";
import { Input } from "antd";
import type { SearchProps } from "antd/es/input/Search";
import GenearalModal from "../components/modals/GeneralModal";
import GeneralDrawer from "../components/GeneralDrawer";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import TwoIconsCard from "../components/cards/TwoIconsCard";
import { ReactComponent as NewIcons } from "../assets/images/icons/Group 1000002857.svg";
import { ReactComponent as AmountIcons } from "../assets/images/icons/Group 1000002860.svg";
import { ReactComponent as CustomerAmountIcons } from "../assets/images/icons/Group 1000002860_1.svg";
import ManageSalesTable from "../components/tables/ManageSalesTable";
import type { DatePickerProps, SelectProps } from "antd";
import SalesDetails from "../components/SalesDetails";
import SalesRegistrationForm from "../components/forms/SalesRegistrationForm";
import FilterSales from "../components/filter/filterSales";
import LoadingCard from "../components/LoadingCard";
import { useDispatch, useSelector } from "react-redux";
import TwoBergeSalesCard from "../components/cards/TwoBargeSalesCard";
import {
  getAllEbmSalesAction,
  getEbmTotalSalesAction,
} from "../store/SalesManagement/actions";
import { getEbmSelectOptionAction } from "../store/action";
import EbmSalesFilter from "../components/filter/ebmSalesFilter";
import { getNameByCode } from "../utils/ConvertCodeToName";
import { getAllEbmSale_CSVAction } from "../../../store/csvDownload/actions";
import EbmExportButton from "../components/EbmExportButton";
import { EbmSalesHeaders } from "../../../components/csvHeaders/Headers";
import { getDefaultCurrencyCode } from "../../../utils/helper";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import moment from "moment";

dayjs.extend(customParseFormat);

const SalesManagement = (props: any) => {
  const { ebmSales, auth, ebmGeneral, CSV, layout, company } = useSelector(
    (state: any) => state
  );
  const formattedDate = new Date().toISOString().slice(0, 10);
  let today = moment();
  let previousDate = today.subtract(1, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [brandData, setBrandData] = useState<any>(null);
  const [itemForm, setItemForm] = useState(false);
  const [loading, setLoading] = useState(ebmSales?.isFetching);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [saleDetails, setSaleDetails] = useState("");
  const dispatch = useDispatch();
  const [limit, setLimit] = useState<any>(15);
  const [page, setPage] = useState<any>(1);
  const [enteredDate, setEnteredDate] = useState<any>(formattedPreviousDate);
  const [endDate, setEndDate] = useState<any>(formattedDate);
  const [searchKey, setSearchKey] = useState<string>("");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showDrawer = (data: any) => {
    setOpenDrawer(true);
    setSaleDetails(data);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };
  const showItemForm = () => {
    setItemForm(true);
  };
  const onChangeDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setEnteredDate(dateString);
  };
  const onChangeEndDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setEndDate(dateString);
  };
  const disabledDate = (current: any) => {
    if (current && current > moment().endOf("day")) {
      return true;
    }
    return false;
  };

  const { Search } = Input;

  useEffect(() => {
    auth?.token && getEbmSelectOptionAction(auth?.token, "?")(dispatch);
  }, [dispatch, auth]);
  useEffect(() => {
    if (auth?.token) {
      getAllEbmSalesAction(
        auth?.token,
        `?status=${selectedStatus}&limit=${limit}&page=${
          page - 1
        }&date1=${enteredDate}&date2=${endDate}`
      )(dispatch);
    }
  }, [
    auth?.token,
    endDate,
    dispatch,
    enteredDate,
    limit,
    page,
    selectedStatus,
  ]);

  useEffect(() => {
    auth?.token && getEbmTotalSalesAction(auth?.token, "?")(dispatch);
  }, [dispatch, auth]);

  const handleSearch = () => {
    if (searchKey && auth?.token) {
      getEbmSelectOptionAction(auth?.token, "?")(dispatch);
      getAllEbmSalesAction(auth?.token, `?sq=${searchKey}`)(dispatch);
    }
  };

  useEffect(() => {
    if (layout?.openCSVButton)
      auth?.token &&
        getAllEbmSale_CSVAction(
          auth?.token,
          `?limit=${ebmSales?.allSales?.total}`
        )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  const csvData = CSV?.csvDownloadedData?.data?.map((el: any) => {
    return {
      id: el?.cartId,
      name: el?.customer?.name,
      date: el?.paidOn?.slice(0, 10),
      item: el?.list?.map((d: any) => d?.warehouseProduct?.product?.model),
      vat: el?.list?.map(
        (d: any) =>
          ebmGeneral?.selectOption &&
          getNameByCode(
            ebmGeneral?.selectOption["Taxation Type"],
            d?.priceProduct?.product?.ebm?.taxTyCd
          )
      ),
      qauntity: el?.list?.length,
      price: el?.list?.map((d: any) => d?.payment?.amount),
      amount: el?.list?.reduce(
        (accumulator: any, item: any) => accumulator + item?.payment?.amount,
        0
      ),
      sales: el?.list?.reduce(
        (accumulator: any, item: any) => accumulator + item?.payment?.amount,
        0
      ),
      data: el,
    };
  });
  console.log(ebmSales, "ebmSalesebmSales");
  return (
    <div>
      <GeneralDrawer
        openDrawer={openDrawer}
        closeDrawer={onClose}
        width={650}
        title={
          <h1 className="text-base font-semibold text-[#030229]">
            Sale Information Details
          </h1>
        }
        component={
          <div>
            <div>
              <SalesDetails data={saleDetails} />
            </div>
          </div>
        }
      />
      <div className="flex justify-between">
        <h1 className="text-[#030229] text-lg">Sales Management</h1>
        <div className="flex gap-5">
          <EbmButton
            btnName={"Import"}
            icon={<ImportIcons />}
            btnBgColor="white"
          />
          <EbmExportButton
            btnName={"Export"}
            icon={<ImportIcons />}
            btnBgColor="white"
            csvHeaders={EbmSalesHeaders}
            csvData={csvData}
            filename={`Ebm Sales.csv`}
          />
          <SettingButton
            btnName={"New"}
            icon={<BiPlus color="white" size={20} />}
            btnBgColor="[#605BFF]"
            textColor="white"
            onClick={showModal}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-5 mt-7">
        {ebmSales?.isFetching ? (
          <LoadingCard />
        ) : (
          <TwoIconsCard
            amount={ebmSales?.totalSales?.data?.totalQuantity ?? 0}
            title={"Total Quantity"}
            iconTwo={<CustomerAmountIcons />}
          />
        )}
        {ebmSales?.isFetching ? (
          <LoadingCard />
        ) : (
          <TwoIconsCard
            amount={`${getDefaultCurrencyCode(company)} ${
              ebmSales?.totalSales?.data?.totalRevenue?.toLocaleString() ?? 0
            }`}
            title={"Total Sales Amount"}
            iconTwo={<AmountIcons />}
          />
        )}
        {ebmSales?.isFetching ? (
          <LoadingCard />
        ) : (
          <TwoIconsCard
            amount={`${getDefaultCurrencyCode(company)} ${
              ebmSales?.totalSales?.data?.totalVat?.toLocaleString() ?? 0
            }`}
            title={"Total VAT Amount"}
            iconTwo={<NewIcons />}
          />
        )}
      </div>
      <div className="flex justify-between items-center pt-4">
        {/* <CustomButton
          title={"Sales Report"}
          icon={<BsDownload color="#FB923C" />}
          bgColor="white"
        /> */}
        <div className="flex gap-5 items-center">
          <h1 className="">From</h1>
          <DatePicker
            onChange={onChangeDate}
            defaultValue={dayjs().subtract(1, "day")}
            disabledDate={disabledDate}
          />
          <h1 className="">To</h1>
          <DatePicker
            onChange={onChangeEndDate}
            defaultValue={dayjs()}
            disabledDate={disabledDate}
          />
        </div>
        <div className="flex">
          <div></div>
          <Search
            onSearch={handleSearch}
            onChange={(e: any) => {
              e.preventDefault();
              setSearchKey(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="bg-white p-3 rounded-md mt-5">
        <div className="flex justify-between">
          <EbmSalesFilter
            setSelectedStatus={setSelectedStatus}
            data={
              ebmGeneral?.selectOption &&
              ebmGeneral?.selectOption["Sale Status"]
            }
          />
        </div>
        <div className="flex justify-between">
          <GenearalModal
            openModal={isModalOpen}
            closeModal={handleOk}
            title={
              <h1 className="text-[#030229] font-semibold text-lg text-center py-2">
                Sales Registration
              </h1>
            }
            component={
              <div className="px-4 h-[78vh] w-full overflow-y-auto">
                <div className="mts-5 w-full">
                  <FilterSales
                    open={isModalOpen}
                    setBrandData={setBrandData}
                    setItemForm={setItemForm}
                  />
                  {loading ? (
                    <LoadingCard />
                  ) : (
                    <div className="mt-2 w-full">
                      {!itemForm && brandData && (
                        <div className="flex flex-wrap">
                          <TwoBergeSalesCard
                            data={ebmSales?.sale?.data}
                            open={showItemForm}
                          />
                        </div>
                      )}

                      {itemForm && brandData && (
                        <SalesRegistrationForm onClose={handleOk} />
                      )}
                    </div>
                  )}
                </div>
              </div>
            }
            width={1200}
          />
        </div>
        <ScrollableFrame
          setLimit={setLimit}
          setPage={setPage}
          limit={limit}
          total={ebmSales?.allSales?.total}
          count={Math.ceil(ebmSales?.allSales?.total / limit)}
        >
          <div className="mt-5">
            <ManageSalesTable open={showDrawer} />
          </div>
        </ScrollableFrame>
      </div>
    </div>
  );
};
export default SalesManagement;
