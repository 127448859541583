import React from "react";
import { Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Divider, Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  AddOneStockItemAction,
  getOneDeliveryNoteAction,
  updateOneDeliveryItemAction,
} from "../../store/wareHouse/actions";

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const App = (props: any) => {
  const { auth, wareHouse } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const snRef = React.useRef<any>(null);
  const imei1Ref = React.useRef<any>(null);
  const imei2Ref = React.useRef<any>(null);
  const nextButtonRef = React.useRef<any>(null);
  const [currentItem, setCurrentItem] = React.useState<number>(
    props?.data?.qtyStored + 1
  );
  const isFinalItem = props?.data?.qtyReceived === currentItem;

  React.useEffect(() => {
    snRef.current && snRef.current.focus();
  }, []);

  const handleOnKeyUp = (e: any, ref: any, nextRef: any) => {
    if (e.target.value?.length === 13) {
      ref.current && nextRef.current.focus();
    }
    if (e.target.value?.length !== 13) {
      ref.current && ref.current.focus();
    }
  };

  const onFinish = async (values: any) => {
    const payload = {
      ...values,
      quotationItem: props?.data?.quotationItemId,
      warehouseItem: props?.data?._id,
      status: "in",
    };

    auth?.token &&
      (await AddOneStockItemAction(
        auth?.token,
        props?.data?.deliveryNoteId,
        payload
      )(dispatch));
    if (isFinalItem) {
      auth?.token &&
        (await updateOneDeliveryItemAction(
          auth?.token,
          props?.data?._id,
          props?.data?.deliveryNoteId,
          { status: `Stored-${props?.data?.status}` }
        )(dispatch));
      // window.location.reload();
      setCurrentItem(1);
      props?.onCancel();
    }
    form.resetFields(["serialNumber", "imei1", "imei2"]);
    setCurrentItem(currentItem + 1);
  };

  React.useEffect(() => {
    auth?.token &&
      getOneDeliveryNoteAction(
        auth?.token,
        props?.data?.deliveryNoteId
      )(dispatch);
    snRef.current.focus();
  }, [auth?.token, dispatch, props?.data?.deliveryNoteId]);

  return (
    <>
      <div className="mb-10">
        <h1 className="font-bold">Add Item</h1>
        <p className="font-semibold">{`${currentItem} of ${props?.data?.qtyReceived}`}</p>
        <div className="flex justify-between mx-2 bg-[#F4F6FA] p-1.5 rounded">
          <p className="text-xs mr-0.5">
            <span className="text-xs mr-0.5 font-semibold">Type:</span>
            {props?.data?.type}
          </p>
          <p className="text-xs mr-0.5">
            <span className="text-xs mr-0.5 font-semibold">Brand:</span>
            {props?.data?.brand}
          </p>

          <p className="text-xs mr-0.5">
            <span className="text-xs mr-0.5 font-semibold">Model:</span>
            {props?.data?.model}
          </p>
          <p className="text-xs mr-0.5">
            <span className="text-xs mr-0.5 font-bold">Description:</span>
            {props?.data?.description.map(
              (item: any) =>
                item[1] && (
                  <div className="flex items-center gap-2">
                    <span className="font-semibold text-xs">{item[0]}: </span>
                    <span className=" text-sm">{item[1]}</span>
                  </div>
                )
            )}
          </p>
        </div>
      </div>
      <Form
        {...formItemLayout}
        form={form}
        name="Stock"
        onFinish={onFinish}
        initialValues={props.data || {}}
        scrollToFirstError
        labelCol={{
          flex: "60px",
        }}
        labelAlign="left"
        labelWrap
        wrapperCol={{
          flex: 1,
        }}
        colon={true}
        style={{
          maxWidth: 600,
        }}
      >
        <Form.Item
          name="serialNumber"
          label="serialNumber"
          tooltip="Please enter serialNumber?"
          rules={[
            {
              required: true,
              message: "serialNumber is required!",
              whitespace: true,
            },
          ]}
        >
          <Input
            ref={snRef}
            name="sn"
            onKeyUp={(e: any) => handleOnKeyUp(e, snRef, imei1Ref)}
          />
        </Form.Item>
        <Form.Item name="imei1" label="IMEI 1">
          <Input
            name="imei1"
            ref={imei1Ref}
            onKeyUp={(e: any) => handleOnKeyUp(e, imei1Ref, imei2Ref)}
          />
        </Form.Item>
        <Form.Item name="imei2" label="IMEI 2">
          <Input
            name="imei2"
            ref={imei2Ref}
            onKeyUp={(e: any) => handleOnKeyUp(e, imei2Ref, nextButtonRef)}
          />
        </Form.Item>

        <Divider orientation="left">Stocked IN</Divider>
        <Stack
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack direction={"row"}>
            <Form.Item
              name="stockShelf"
              rules={[{ required: true, message: "Missing names" }]}
            >
              <Select showSearch placeholder="Select Stock Shelf">
                {["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"].map(
                  (type: any) => (
                    <Option value={type.toLowerCase()}>{type}</Option>
                  )
                )}
              </Select>
            </Form.Item>
            <Form.Item
              style={{ marginLeft: "2px", marginRight: "2px" }}
              name="stockRow"
              rules={[{ required: true, message: "Missing position" }]}
            >
              <Select showSearch placeholder="Select Stock Row">
                {["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"].map(
                  (type: any) => (
                    <Option value={type.toLowerCase()}>{type}</Option>
                  )
                )}
              </Select>
            </Form.Item>
            <Form.Item
              name="stockColumn"
              rules={[{ required: true, message: "Missing phone contacts" }]}
            >
              <Select showSearch placeholder="Select Stock Column">
                {["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"].map(
                  (type: any) => (
                    <Option value={type.toLowerCase()}>{type}</Option>
                  )
                )}
              </Select>
            </Form.Item>
          </Stack>
        </Stack>

        <Form.Item {...tailFormItemLayout}>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ minWidth: "100%" }}
            ref={nextButtonRef}
            loading={wareHouse?.isFetching}
          >
            {isFinalItem ? "Finish" : "Next"}
          </LoadingButton>
        </Form.Item>
      </Form>
    </>
  );
};

export default App;
