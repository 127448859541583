import React from "react";
import StockOutGridCard from "./StockOutGridCard";

const App = (props: any) => {
  return props?.data?.map((d: any) => {
    return <StockOutGridCard route={props?.route} data={d} />;
  });
};

export default App;
