import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const createItemsService = async (token: string, data: any) => {
  return await HttpRequest.post(`${SERVER_URL}/ebm/addItem`, data, token);
};

export const getAllOptionsService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/ebm/getSelectOptions${query}`, token);
};

export const getAllItemsService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/ebm/getAllItems${query}`, token);
};



export const getebmopenAndclosingStockService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/ebm/stock${query}`, token);
};

export const createPurchaseItemsService = async (token: string, data: any) => {
  return await HttpRequest.post(
    `${SERVER_URL}/ebm/addPurchaseToEBM`,
    data,
    token
  );
};
export const getEbmWaitingImportServices = async (token: string, query: any) => {
  return HttpRequest.get(`${SERVER_URL}/ebm/getAllImportItems${query}`, token);
};
export const getEbmWaitingPurchaseServices = async (token: string, query: any) => {
  return HttpRequest.get(`${SERVER_URL}/ebm/waitingPurchase${query}`, token);
};
export const getEbmSelectOptionServices = async (
  token: string,
  query: string
) => {
  return HttpRequest.get(`${SERVER_URL}/ebm/getSelectOptions${query || ""}`, token);
};
export const creatEbmMergedService = async (token: string, data: any) => {
  return await HttpRequest.post(
    `${SERVER_URL}/ebm/merge`,
    data,
    token
  );
};
export const updateEbmImport = async (
  token: string,
  data: any,
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/ebm/approveImportItem`,
    data,
    token
  );
};
