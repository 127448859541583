import React from "react";
import { Radio } from "antd";

const EbmFilter = (props: any) => {
  return (
    <div className="flex items-center space-x-2 pt-4">
      <Radio.Group
        defaultValue=""
        buttonStyle="solid"
        onChange={(e: any) => props.setSelectedStatus(e.target.value)}
      >
        <Radio.Button value="">{`ALL Transactions`}</Radio.Button>
        {props?.data && props?.data?.map((el: any) => (
          <Radio.Button value={el?.value} className="capitalize">{`${
            el?.label
          } `}</Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
};
export default EbmFilter;

