import React from "react";

const FormSkeleton = () => {
  return (
    <div role="status" className="xmax-w-sm animate-pulse mt-8">
      <div className="h-3 bg-gray-200 rounded-full w-48 mb-4"></div>
      <div className="grid grid-cols-2 gap-x-6 gap-y-4">
        <div className="h-10 bg-gray-100 rounded"></div>
        <div className="h-10 bg-gray-100 rounded"></div>
        <div className="h-10 bg-gray-100 rounded"></div>
        <div className="h-10 bg-gray-100 rounded"></div>
        <div className="h-10 bg-gray-100 rounded"></div>
        <div className="h-10 bg-gray-100 rounded"></div>
        <div className="h-10 bg-gray-100 rounded"></div>
        <div className="h-10 bg-gray-100 rounded"></div>
      </div>
    </div>
  );
};

export default FormSkeleton;
