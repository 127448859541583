const PrimaryFilledButton = (props: any) => {
    return (
      <button
        {...props}
        type="button"
        disabled={props?.isDisabled || false}
        className={`text-white bg-blue-400 ${props?.isDisabled ? "":"hover:bg-blue-800"} focus:ring-2 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none`}
      >
        {props?.name}
      </button>
    );
  };
  
  export default PrimaryFilledButton;
  