import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select } from "antd";
import { DatePicker } from "antd";
import type { SearchProps } from "antd/es/input/Search";
// import dayjs from "dayjs";
// import customParseFormat from "dayjs/plugin/customParseFormat";
import CustomButton from "../buttons/CustomButton";
import { ReactComponent as SaveIcon } from "../../assets/images/icons/save.svg";
import type { DatePickerProps } from "antd";
import { getNameByCode } from "../../utils/ConvertCodeToName";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import {
  addEbmPurchaseAction,
  getEbmPurchasedAction,
} from "../../store/PurchaseManagement/action";
import { getAllSuppliersAction } from "../../../../store/supplier/actions";
// dayjs.extend(customParseFormat);

// const dateFormat = "YYYY-MM-DD";
const { Search } = Input;
const onSearch: SearchProps["onSearch"] = (value, _e, info) =>
  console.log(info?.source, value);
const layout = {
  labelCol: { span: 10 },
};
const onChange: DatePickerProps["onChange"] = (date, dateString) => {
  console.log(date, dateString);
};
const AddPurchaseForm = (props: any) => {
  const { auth, ebmGeneral, ebmPurchase, supplier } = useSelector(
    (state: any) => state
  );
  const [form] = Form.useForm();
  const [paymentTypeCode, setPaymentTypeCode] = useState("");
  const [receiptTypeCode, setReceiptTypeCode] = useState("");
  const dispatch = useDispatch();
  const PaymentType =
    ebmGeneral?.selectOption &&
    ebmGeneral?.selectOption["Payment Type"]?.map((el: any) => ({
      value: el?.value,
      label: el?.label,
    }));
  const ReceiptType =
    ebmGeneral?.selectOption &&
    ebmGeneral?.selectOption["Sales Receipt Type"]?.map((el: any) => ({
      value: el?.value,
      label: el?.label,
    }));
  useEffect(() => {
    form.setFieldsValue({
      // name:props?.selectedData?.pOrderID?.supplier?.name,
      // invoiceId: props?.selectedData?.deliveryID?.slice(3,9)?.replace("-",""),
    });
  }, [props?.selectedData]);
  // deliveryID?.slice(3,9)?.replace("-","")"
  useEffect(() => {
    auth?.token && getAllSuppliersAction(auth?.token, "")(dispatch);
  }, []);
  const SuppliersData = supplier?.all?.data?.map((el: any) => ({
    value: el?.tin,
    label: el?.name,
  }));
  const addPurchaseToEbm = async (values: any) => {
    auth?.token &&
      (await addEbmPurchaseAction(auth?.token, {
        deliveryNoteId: props?.selectedData?._id,
        // registrationTypeCode: "M",
        purchaseTypeCode: paymentTypeCode,
        receiptTypeCode: receiptTypeCode,
        // paymentTypeCode: "06",
        purchaseStatusCode: "02",
      })(dispatch));
    auth?.token && getEbmPurchasedAction(auth?.token, "")(dispatch);
    // form.resetFields();
    props?.onClose();
  };
  const cancelPurchaseFromEbm = async (values: any) => {
    auth?.token &&
      (await addEbmPurchaseAction(auth?.token, {
        deliveryNoteId: props?.selectedData?._id,
        purchaseStatusCode: "04",
      })(dispatch));
    auth?.token && getEbmPurchasedAction(auth?.token, "")(dispatch);
    // form.resetFields();
    props?.onClose();
  };
  return (
    <div>
      {props?.selectedData?.ebmPurchaseStatus && (
        <div className="flex justify-between">
          <div className="bg-[#F4F6FA] flex justify-center items-center gap-2 px-[.25rem] py-[.25rem] rounded-md">
            {" "}
            <h1 className="text-[.9rem] text-black pl-2">Current status:</h1>
            <CustomButton
              title={
                ebmGeneral?.selectOption &&
                getNameByCode(
                  ebmGeneral?.selectOption["Purchase Status"],
                  props?.selectedData?.ebmPurchaseStatus
                )
              }
              textColor="white"
              bgColor="green-500"
              textSize="base"
            />
          </div>
          {props?.selectedData?.ebmPurchaseStatus === "01" && (
            <div className="flex gap-5">
              <CustomButton
                title={ebmPurchase?.isFetching ? <LoadingOutlined /> : "Accept"}
                textColor="[#0FA91E]"
                bgColor="[#0FA91E0D]"
                textSize="base"
                onClick={addPurchaseToEbm}
              />
              <CustomButton
                title={ebmPurchase?.isFetching ? <LoadingOutlined /> : "Cancel"}
                textColor="[#F66659]"
                bgColor="[#F6665980]"
                textSize="base"
                onClick={cancelPurchaseFromEbm}
              />
            </div>
          )}
        </div>
      )}
      <Form
        name="wrap"
        form={form}
        initialValues={props?.selectedData}
        colon={false}
        style={{ width: "100%" }}
      >
        <div className=" flex  mt-4 ">
          <div className="w-1/2">
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[2.15rem]">
                Invoice ID:
              </span>
              name="invoiceId"
            >
              <Input className="h-10 w-[20rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[.41rem]">
                SupplierName:
              </span>
              name="name"
            >
              <Input className="h-10 w-[20rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[1.11rem]">
                Supplier Id:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[20rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[.4rem]">
                Reference ID:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[20rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
                Total Amount:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[20rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-14">
                VAT:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[20rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-8">
                Remark:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[20rem]" />
            </Form.Item>
          </div>
          <div className="w-1/2">
            <Form.Item name="orgnNatCd">
              <div className="flex gap-2">
                <span className="text-[12px] text-[#030229] pt-2 w-[5rem] mr-4">
                  item Code:
                </span>
                <Select
                  showSearch
                  style={{ width: 370, height: 40 }}
                  placeholder="Item Code"
                  optionFilterProp="children"
                  onChange={(value: any) => setPaymentTypeCode(value)}
                  filterOption={(input, option) =>
                    (option?.label ?? "").toString().includes(input.toString())
                  }
                  options={PaymentType}
                />
              </div>
            </Form.Item>
            <Form.Item name="orgnNatCd">
              <div className="flex gap-2">
                <span className="text-[12px] text-[#030229] pt-2 w-[6rem]">
                  Class Code:
                </span>
                <Select
                  showSearch
                  style={{ width: 370, height: 40 }}
                  placeholder="Class Code"
                  optionFilterProp="children"
                  onChange={(value: any) => setReceiptTypeCode(value)}
                  filterOption={(input, option) =>
                    (option?.label ?? "").toString().includes(input.toString())
                  }
                  options={ReceiptType}
                />
              </div>
            </Form.Item>
            <div className="flex gap-4">
              <div>
                <Form.Item></Form.Item>
                <Form.Item
                  label=<span className="text-[12px] text-[#030229] pt-2 mr-[2.1rem]">
                    Unit Price:
                  </span>
                  name="name"
                  rules={[{ required: true }]}
                >
                  <Input className="h-10 w-[8rem] " />
                </Form.Item>
                <Form.Item>
                  <div className="flex ">
                    <span className="text-[12px] text-[#030229] pt-2 w-20 mr-[2.1rem]">
                      Tax Type:
                    </span>
                    <Select style={{ width: 128, height: 40 }}>
                      <Select.Option value="demo">Demo</Select.Option>
                      <Select.Option value="new">new</Select.Option>
                    </Select>
                  </div>
                </Form.Item>
                <Form.Item
                  label=<span className="text-[12px] text-[#030229] pt-2 mr-[.92rem]">
                    D/C Rate(%):
                  </span>
                  name="name"
                  rules={[{ required: true }]}
                >
                  <Input className="h-10 w-[8rem] " />
                </Form.Item>
                <Form.Item
                  label=<span className="text-[12px] text-[#030229] pt-2">
                    Purchase Price:
                  </span>
                  name="name"
                  rules={[{ required: true }]}
                >
                  <Input className="h-10 w-[8rem]" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label=<span className="text-[12px] text-[#030229] pt-2 mr-[.8rem]">
                    Expire Date:
                  </span>
                  name="name"
                  rules={[{ required: true }]}
                >
                  <DatePicker onChange={onChange} className="h-10 w-[8rem]" />
                </Form.Item>
                <Form.Item
                  label=<span className="text-[12px] text-[#030229] pt-2">
                    Purchase Qty:
                  </span>
                  name="name"
                  rules={[{ required: true }]}
                >
                  <Input className="h-10 w-[8rem]" />
                </Form.Item>
                <Form.Item
                  label=<span className="text-[12px] text-[#030229] pt-2 mr-[3.4rem]">
                    VAT:
                  </span>
                  name="name"
                  rules={[{ required: true }]}
                >
                  <Input className="h-10 w-[8rem]" />
                </Form.Item>
                <Form.Item
                  label=<span className="text-[12px] text-[#030229] pt-2 mr-[.1rem]">
                    D/C Amount:
                  </span>
                  name="name"
                  rules={[{ required: true }]}
                >
                  <Input className="h-10 w-[8rem]" />
                </Form.Item>
                <Form.Item
                  label=<span className="text-[12px] text-[#030229] pt-2 mr-[.9rem]">
                    Total Price:
                  </span>
                  name="name"
                  rules={[{ required: true }]}
                >
                  <Input className="h-10 w-[8rem]" />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>

        <div className="flex float-right w-[43%] justify-between mr-[4.5rem]">
          <div className="flex gap-5">
            <Form.Item>
              <CustomButton
                title={"Clear"}
                textColor="red-500"
                bgColor="red-100"
              />
            </Form.Item>
            <Form.Item>
              <CustomButton
                title={"Confirm"}
                textColor="white"
                bgColor="[#605BFF]"
              />
            </Form.Item>
          </div>
          <Form.Item>
            <CustomButton
              title={"Remove"}
              textColor="[#030229B2]"
              bgColor="[#0302291A]"
            />
          </Form.Item>
        </div>
        <div className="w-1/2 m-auto mt-16">
          <Form.Item label=" ">
            <CustomButton
              title={"Save"}
              bgColor="[#605BFF]"
              width="full"
              textColor="white"
              icon={<SaveIcon />}
            />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default AddPurchaseForm;
