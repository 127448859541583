import HttpRequest from "../../utils/HttpRequest";
import { SERVER_URL } from "../../utils/constants";

export const getSalesStats = async (token: string, query: string) => {
  return HttpRequest.get(`${SERVER_URL}/stats/sales?${query || ""}`, token);
};

export const getRevenueAndQtyservices = async (
  token: string,
  query: string
) => {
  return HttpRequest.get(`${SERVER_URL}/stats/salesbyday${query}`, token);
};

export const getRevenueAndQtyByRangeservices = async (
  token: string,
  query: string
) => {
  return HttpRequest.get(`${SERVER_URL}/stats/allSale${query}`, token);
};

export const getKPIStatsService = async (query: string) => {
  return HttpRequest.get(`${SERVER_URL}/stats/allsales${query}`);
};

export const getRevenueStats = async (token: string, query: string) => {
  return HttpRequest.get(`${SERVER_URL}/stats/revenue?${query || ""}`, token);
};

export const getCurrentStockStats = async (token: string, query: string) => {
  return HttpRequest.get(
    `${SERVER_URL}/stats/warehouseBrands?${query || ""}`,
    token
  );
};

export const getDaySalesStats = async (token: string, day: string) => {
  return HttpRequest.get(`${SERVER_URL}/stats/salesbyday/${day}`, token);
};

export const getRegionStats = async (token: string, id: string) => {
  return HttpRequest.get(`${SERVER_URL}/stats/region/${id}`, token);
};

export const getAllProductsByBrandservice = async (
  token: string,
  brand: string
) => {
  return HttpRequest.get(`${SERVER_URL}/stats/result/${brand}`, token);
};

export const getDataActivationStats = async (token: string, query: string) => {
  return HttpRequest.get(
    `${SERVER_URL}/warehouse/dataActiveted${query}`,
    token
  );
};

export const getDataActivationByRangeStat = async (token: any, query: any) => {
  return HttpRequest.get(`${SERVER_URL}/stats/dataActivated${query}`, token);
};

export const getPaymentStats = async (
  data: any,
  token: string,
  query?: string
) => {
  return await HttpRequest.post(
    `${SERVER_URL}/stats/payments${query}`,
    data,
    token
  );
};

export const getPaymentRangeStats = async (
  data: any,
  token: string,
  query?: string
) => {
  return await HttpRequest.post(
    `${SERVER_URL}/stats/payments/range${query}`,
    data,
    token
  );
};

export const getProductStats = async (token: string, query: string) => {
  return HttpRequest.get(
    `${SERVER_URL}/stats/quantityByLocation${query}`,
    token
  );
};

export const getPieceSoldStats = async (token: string, query: string) => {
  return HttpRequest.get(
    `${SERVER_URL}/stats/salesQuantityRevenue${query}`,
    token
  );
};

export const getBrandedIkosoraStats = async (token: string, query: string) => {
  return HttpRequest.get(`${SERVER_URL}/stats/salesProduct${query}`, token);
};

export const getShopStats = async (token: string, query: string) => {
  return HttpRequest.get(`${SERVER_URL}/stats/revenueByShop${query}`, token);
};

export const getAgentStats = async (token: string, query: string) => {
  return HttpRequest.get(`${SERVER_URL}/stats/createdBy${query}`, token);
};

export const getAllNosaleForSpecificBrandStats = async (
  token: string,
  query: string
) => {
  return HttpRequest.get(`${SERVER_URL}/stats/NoSales/brand${query}`, token);
};

export const getAllNosaleStats = async (token: string, query: string) => {
  return HttpRequest.get(`${SERVER_URL}/stats/NoSales${query}`, token);
};

export const getBrandedStockStatsService = async (
  token: string,
  query: string
) => {
  return HttpRequest.get(`${SERVER_URL}/stats/quantityByBrand${query}`, token);
};

export const getBrandedStockStatisticsService = async (
  token: string,
  query: string
) => {
  return HttpRequest.get(`${SERVER_URL}/stats/stock${query}`, token);
};

export const getCommissionStatisticsService = async (
  token: string,
  query: string
) => {
  return HttpRequest.get(`${SERVER_URL}/stats/commission${query}`, token);
};

export const getFranchiseeCommissionService = async (
  token: string,
  query: string
) => {
  return HttpRequest.get(
    `${SERVER_URL}/cartItem/commission/transaction/byShop${query}`,
    token
  );
};

export const getIkosoraStockStatsService = async (
  token: string,
  query: string
) => {
  return HttpRequest.get(`${SERVER_URL}/stats/ikosoraStock${query}`, token);
};
