import { LoadingButton } from "@mui/lab";
import { Divider, Form, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createOtpActions,
  getDelivererAction,
  getOneDelivererAction,
} from "../../../../store/delivery/actions";
import CreateDelivererForm from "../../inventory/warehouseOut/createDelivererForm";
import { useParams } from "react-router-dom";
import {
  getOnesubDeliveryNoteAction,
  updateSubsDeliveryNoteAction,
} from "../../../../store/subscription/deliverynote/actions";
import { getOneSubsOrderAction } from "../../../../store/subscription/order/actions";

const DelivererDetails = (props: any) => {
  const { auth, tracking, orders, deliverynote } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { orderId } = useParams();

  const [delivererId, setDeliverer] = useState<any>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isLoading =
    tracking?.isFetching || orders?.isFetching || deliverynote?.isFetching;

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (auth?.token) {
      getDelivererAction(auth?.token)(dispatch);
    }
  }, [dispatch, auth?.token]);
  const deliveryNoteId = orders?.selected?.account?.deliveryNote?._id;

  const onFinish = async (values: any) => {
    const payload = {
      deliverer: {
        name: tracking?.selected?.name,
        phone: tracking?.selected?.phone,
        company: tracking?.selected?.company,
        email: tracking?.selected?.email,
        delivererId: delivererId,
      },
    };
    const res = await updateSubsDeliveryNoteAction(
      deliveryNoteId,
      payload,
      auth.token
    )(dispatch);
    if (res) {
      await createOtpActions(auth?.token, {
        email: tracking?.selected?.email,
        phoneNumber: tracking?.selected?.phone,
        name: tracking?.selected?.name,
      })(dispatch);

      props?.updateDeliverer(false);
      await getOneSubsOrderAction(auth?.token, orderId as string)(dispatch);
      await getOnesubDeliveryNoteAction(
        auth?.token,
        deliveryNoteId as string
      )(dispatch);
    }
  };

  const onChangeDeliverer = (value: any) => {
    setDeliverer(value);
    auth?.token && getOneDelivererAction(value, auth?.token)(dispatch);
  };

  const delivererOptions =
    tracking?.allDeliverer?.data?.map((el: any) => ({
      value: el?._id,
      label: el?.name,
    })) || [];

  useEffect(() => {
    if (orders?.selected?.account?.deliveryNote?.deliverer) {
      form.setFieldsValue({
        deliverer: orders?.selected?.account?.deliveryNote?.deliverer?.name,
      });
    } else {
      form.resetFields();
    }
  }, [orders?.selected?.account?.deliveryNote?.deliverer]);
const onSearch=()=>{
  getDelivererAction('')(dispatch)
}
  return (
    <>
      <div className="space-y-4">
        <p className="text-[#030229] text-lg">Deliverer Details</p>
        <Form
          onFinish={onFinish}
          form={form}
          className="w-full"
          layout="vertical"
        >
          <div className="w-[20%] flex gap-20">
            <div className="w-full">
              {/* <Form.Item
                name="trackingType"
                label={
                  <h1 className="text-[#030229B2] text-base">Tracking Type</h1>
                }
                rules={[
                  {
                    required: true,
                    message: "tracking type is required!",
                    whitespace: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a tracking type"
                  optionFilterProp="label"
                  style={{ height: 45 }}
                >
                  {["Locally", "International"]?.map(
                    (el: any, index: number) => (
                      <Select.Option key={index} value={el}>
                        {el}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item> */}
              <Form.Item
                name="deliverer"
                label={
                  <h1 className="text-[#030229B2] text-base">
                    Select deliverer
                  </h1>
                }
                rules={[
                  {
                    required: true,
                    message: "tracking type is required!",
                    whitespace: true,
                  },
                ]}
              >
                <Select
                onSearch={onSearch}
                  showSearch
                  placeholder="Select Deliverer"
                  optionFilterProp="label"
                  filterSort={(optionA, optionB) =>
                    (
                      optionA?.label?.toString().toLowerCase() ?? ""
                    ).localeCompare(
                      optionB?.label?.toString().toLowerCase() ?? ""
                    )
                  }
                  loading={tracking?.isFetching}
                  onChange={onChangeDeliverer}
                  options={delivererOptions}
                  style={{ height: 45 }}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider />
                      <button
                        onClick={showModal}
                        className="bg-[#605BFF] h-10 text-base text-center w-full rounded-md text-white"
                      >
                        + Create New Deliverer
                      </button>
                    </div>
                  )}
                />
              </Form.Item>
            </div>
          </div>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ width: "20%" }}
            loading={isLoading}
            disabled={isLoading}
          >
            Save
          </LoadingButton>
        </Form>
      </div>
      <Modal
        title={
          <h1 className="text-[#030229] text-lg py-2">Create Deliverer</h1>
        }
        width={800}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <CreateDelivererForm onOk={handleOk} />
      </Modal>
    </>
  );
};

export default DelivererDetails;
