import React from "react";
import { useSelector } from "react-redux";
import SamphonePayments from "../Payments/SamphonePayments";
import DashboardMacyeMacyeCard from "../DashboardMacyeMacyeCard";
import DashboardOtherPaymentCard from "../DashboardOtherPaymentCard";
import DashboardCorporateSalesCard from "../DashboardCorporateSalesCard";
import {
  calculatePaymentTotalQntyRange,
  calculatePaymentTotalQty,
  calculatePaymentTotalRevenue,
  calculatePaymentTotalRevenueRange,
  calculatePercentageChange,
} from "../../../utils/converter";
import DashboardIkosoraSalesCard from "../DashboardIkosoraSalesCard";
import DashboardCommissionSalesCard from "../DashboardCommissionSalesCard";

const PaymentsInfo = (props: any) => {
  const { dashboard, layout } = useSelector((state: any) => state);
  // Samphone Payment Method
  const currentTotalRevenue = calculatePaymentTotalRevenue(
    dashboard?.samphonePaymentStats?.data,
    "currentDate"
  );
  const comparedTotalRevenue = calculatePaymentTotalRevenue(
    dashboard?.samphonePaymentStats?.data,
    "comparedDate"
  );

  // const currentTotalQty = calculatePaymentTotalQty(
  //   dashboard?.samphonePaymentStats?.data,
  //   "currentDate"
  // );
  // const comparedTotalQty = calculatePaymentTotalQty(
  //   dashboard?.samphonePaymentStats?.data,
  //   "comparedDate"
  // );
  const currentTotalQty = dashboard?.samphonePaymentStats?.data?.reduce(
    (curr: any, item: any) => curr + item?.quantity?.currentDate?.quantityTotal,
    0
  );

  const comparedTotalQty = dashboard?.samphonePaymentStats?.data?.reduce(
    (curr: any, item: any) =>
      curr + item?.quantity?.comparedDate?.quantityTotal,
    0
  );

  // Samphone Payment Method Range
  const currentTotalRevenueRange = calculatePaymentTotalRevenueRange(
    dashboard?.samphonePaymentStats?.data
  );
  const currentTotalQtyRange = calculatePaymentTotalQntyRange(
    dashboard?.samphonePaymentStats?.data
  );

  // MTN Payment Method
  // const mtnCurrentTotalQty = calculatePaymentTotalQty(
  //   dashboard?.mtnPaymentStats?.data,
  //   "currentDate"
  // );

  const mtnCurrentTotalQty = dashboard?.mtnPaymentStats?.data?.reduce(
    (curr: any, item: any) => curr + item?.quantity?.currentDate?.quantityTotal,
    0
  );

  const mtnComparedTotalQty = dashboard?.mtnPaymentStats?.data?.reduce(
    (curr: any, item: any) =>
      curr + item?.quantity?.comparedDate?.quantityTotal,
    0
  );

  const mtnCurrentTotalRevenue = calculatePaymentTotalRevenue(
    dashboard?.mtnPaymentStats?.data,
    "currentDate"
  );

  const mtnComparedTotalRevenue = calculatePaymentTotalRevenue(
    dashboard?.mtnPaymentStats?.data,
    "comparedDate"
  );

  // MTN Payment Method Range
  const mtnCurrentTotalRevenueRange = calculatePaymentTotalRevenueRange(
    dashboard?.mtnPaymentStats?.data
  );
  const mtnCurrentTotalQtyRange = calculatePaymentTotalQntyRange(
    dashboard?.mtnPaymentStats?.data
  );

  const samphonePayment = dashboard?.samphonePaymentStats?.data?.map(
    (item: any) => {
      return {
        name:
          item?.mode === "Samphone_POS"
            ? "POS"
            : item?.mode === "Samphone_Bank_Deposit"
            ? "Bank Deposit"
            : item?.mode?.split("_")?.join(" "),
        today: parseInt(item?.revenue?.currentDate?.revenueTotal),
        yesterday: parseInt(item?.revenue?.comparedDate?.revenueTotal),
        value:
          currentTotalRevenue !== 0 &&
          (parseFloat(item?.revenue?.currentDate?.revenueTotal) /
            currentTotalRevenue) *
            100,
        percentage: calculatePercentageChange(
          item?.revenue?.currentDate?.revenueTotal,
          item?.revenue?.comparedDate?.revenueTotal
        ),
        layout: "day",
      };
    }
  );

  const samphonePaymentRange = dashboard?.samphonePaymentStats?.data?.map(
    (item: any) => {
      return {
        name:
          item?.paymentMode === "Samphone_POS"
            ? "POS"
            : item?.paymentMode === "Samphone_Bank_Deposit"
            ? "Bank Deposit"
            : item?.paymentMode?.split("_")?.join(" "),
        today: parseInt(item?.result?.allTotalRevenue),
        layout: "range",
      };
    }
  );

  const samphonePercentageChange = calculatePercentageChange(
    currentTotalRevenue,
    comparedTotalRevenue
  );

  const mtnPayment = dashboard?.mtnPaymentStats?.data?.map((item: any) => {
    return {
      name:
        item?.mode === "MTN_POS"
          ? "POS"
          : item?.mode === "MTN_Bank_Deposit"
          ? "Bank Deposit"
          : "Requisition",
      today: parseInt(item?.revenue?.currentDate?.revenueTotal),
      yesterday: parseInt(item?.revenue?.comparedDate?.revenueTotal),
      value:
        currentTotalRevenue !== 0 &&
        (parseFloat(item?.revenue?.currentDate?.revenueTotal) /
          currentTotalRevenue) *
          100,
      percentage: calculatePercentageChange(
        item?.revenue?.currentDate?.revenueTotal,
        item?.revenue?.comparedDate?.revenueTotal
      ),
      layout: "day",
    };
  });
  const mtnPaymentRange = dashboard?.mtnPaymentStats?.data?.map((item: any) => {
    return {
      name:
        item?.paymentMode === "MTN_POS"
          ? "POS"
          : item?.paymentMode === "MTN_Bank_Deposit"
          ? "Bank Deposit"
          : "Requisition",
      today: parseInt(item?.result?.allTotalRevenue),
      layout: "range",
    };
  });

  const mtnPercentageChange = calculatePercentageChange(
    mtnCurrentTotalRevenue,
    mtnComparedTotalRevenue
  );
  //
  return (
    <div className="py-4 flex flex-col space-y-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
        <DashboardMacyeMacyeCard title="Macye Macye Payments" />
        <DashboardCorporateSalesCard title="Corporate Sales" />
        {/* other payment */}
        <DashboardOtherPaymentCard
          title="Vuba Vuba Payments"
          paymentMode="Vuba_Vuba"
          stateData={dashboard?.vubavubaPaymentStats?.data}
        />
        <DashboardOtherPaymentCard
          title="Intelligra Insurance"
          paymentMode="Intelligra_Insurance"
          stateData={dashboard?.intelligraInsurancePaymentStats?.data}
        />
        <DashboardCommissionSalesCard title="Commission" />
        <DashboardIkosoraSalesCard title="IKOSORA +" />
        <DashboardOtherPaymentCard
          title="Yello Payments"
          paymentMode="Yello_Payment"
          stateData={dashboard?.yelloPaymentStats?.data}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 xh-full xbg-white ">
        <SamphonePayments
          title="Samphone payments"
          OverAllPercentage={samphonePercentageChange}
          amount={
            layout?.dashboardPreview === "day"
              ? currentTotalRevenue
              : currentTotalRevenueRange
          }
          yesterdayAmount={comparedTotalRevenue}
          data={
            layout?.dashboardPreview === "day"
              ? samphonePayment
              : samphonePaymentRange
          }
          pieces={
            layout?.dashboardPreview === "day"
              ? currentTotalQty
              : currentTotalQtyRange
          }
          yesterdayPieces={comparedTotalQty && comparedTotalQty}
        />

        <SamphonePayments
          title="MTN Payments"
          OverAllPercentage={mtnPercentageChange}
          amount={
            layout?.dashboardPreview === "day"
              ? mtnCurrentTotalRevenue
              : mtnCurrentTotalRevenueRange
          }
          yesterdayAmount={mtnComparedTotalRevenue}
          data={
            layout?.dashboardPreview === "day" ? mtnPayment : mtnPaymentRange
          }
          pieces={
            layout?.dashboardPreview === "day"
              ? mtnCurrentTotalQty
              : mtnCurrentTotalQtyRange
          }
          yesterdayPieces={mtnComparedTotalQty && mtnComparedTotalQty}
        />
      </div>
    </div>
  );
};

export default PaymentsInfo;
