import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DatePicker, DatePickerProps, Skeleton, Tag, Table, Badge } from "antd";
import { Box } from "@mui/material";
import { AiOutlineUnorderedList } from "react-icons/ai";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { useSelector, useDispatch } from "react-redux";
import { getAllSubdeliverynotesAction } from "../../../../store/subscription/deliverynote/actions";
import { searchValue } from "../../../../utils/setColor";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import SubscriptiondeliveryNoteFilter from "../../../../components/filters/SubscriptionDeliveryNoteFilter";
import { ColumnsType } from "antd/es/table";
// import { CSVLink } from "react-csv";
import { TbGridDots } from "react-icons/tb";
import dayjs from "dayjs";
// import CsvDownloadButton from "../../../../components/buttons/CsvDownloadButton";
import FilesCard from "../../../../components/cards/NoSale/FilesCard";
import CSVExportButton from "../../../../components/cards/NoSale/CSVExportButton";
import { VscFilePdf } from "react-icons/vsc";
import { mySubscritionActions } from "../../../../store/subscription/deliverynote";
import { deliveryNoteIncludeItem } from "../../../../assets/data/includedItem";
interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const SubscriptionDeliveryNoteCard = (props: any) => {
  const navigate = useNavigate();
  const { auth, deliverynote } = useSelector((state: any) => state);
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const dispatch = useDispatch();
  const dataMapper: any[] = [];
  const [formData, setformData] = useState(dataMapper);
  const [limit, setLimit] = useState<any>(15);
  const [page, setPage] = useState<any>(1);
  const [activeDeliveryNote, setActiveDeliveryNote] = useState(
    "deliveryNote as grid"
  );
  const value = deliverynote?.all?.data;

  useEffect(() => {
    if (selectedStatus !== "") {
      auth?.token &&
        getAllSubdeliverynotesAction(
          auth?.token,
          `?status=${selectedStatus}&limit=${limit}&page=${page - 1
          }&field=${deliveryNoteIncludeItem}`
        )(dispatch);
    } else {
      auth?.token &&
        getAllSubdeliverynotesAction(
          auth?.token,
          `?status[]=delivered&status[]=in-transit&limit=${limit}&page=${page - 1
          }&field=${deliveryNoteIncludeItem}`
        )(dispatch);
    }
  }, [auth?.token, dispatch, limit, page, selectedStatus]);

  const goBack = () => {
    navigate(-1);
  };

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(dateString);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Customer Number",
      dataIndex: "customerNumber",
      width: 150,
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Time",
      dataIndex: "time",
    },
    {
      title: "Devices",
      dataIndex: "deviceNumber",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        return (
          <>
            <Tag
              color={searchValue(status)}
              className=" rounded-md w-18 py-[2px] px-6"
            >
              {status}
            </Tag>
          </>
        );
      },
    },
  ];
  useEffect(() => {
    value &&
      value.forEach((el: any) => {
        dataMapper.push({
          dataIndex: el._id,
          name: el?.account?.customerDetails?.name,
          customerNumber: el?.SDNID,
          deviceNumber: el?.list?.length,
          createdBy: el?.createdBy?.names,
          date: el?.createdAt?.substring(0, 10),
          time: el?.createdAt?.substring(11, 16),
          status: el?.status,
          data: el,
        });
      });

    setformData(dataMapper);
  }, [value]);

  const handleIconClick = (iconType: string) => {
    setActiveDeliveryNote(iconType);
  };

  const csvHeaders = [
    { label: "customer Number", key: "customerNumber" },
    { label: "created By", key: "createdBy" },
    { label: `Date `, key: "date" },
    { label: `time`, key: "time" },
    { label: `device Number `, key: "deviceNumber" },
    { label: `status`, key: "status" },
  ];

  useEffect(() => {
    dispatch(mySubscritionActions.setSubDeliveryNoteItem(null));
  }, []);
  return (
    <>
      <div className="text-[#0F0F47] my-4">
        <DetailsHeaderActionBar pageName="Subscription" title="Delivery Note" />
      </div>
      <div className="text-black scrollbar-hide h-full w-full bg-white pb-2">
        <div className="flex flex-wrap px-1 justify-between">
          <div className="flex flex-row items-center">
            <SubscriptiondeliveryNoteFilter
              setSelectedStatus={setSelectedStatus}
            />
          </div>
          <div className="flex flex-row items-center gap-4">
            <DatePicker onChange={onChange} className={"w-48"} />

            <div className="text-gray-500 flex flex-row px-4">
              <div
                style={{
                  color:
                    activeDeliveryNote === "deliveryNote as grid"
                      ? "#3b82f6"
                      : "gray",
                  cursor: "pointer",
                }}
              >
                <TbGridDots
                  size={30}
                  onClick={() => handleIconClick("deliveryNote as grid")}
                />
              </div>

              <AiOutlineUnorderedList
                size={30}
                onClick={() => handleIconClick("deliveryNote as table")}
                className={`cursor-pointer ${activeDeliveryNote === "deliveryNote as table"
                    ? "fill-blue-500"
                    : "fill-gray-500"
                  }`}
              />
              {activeDeliveryNote === "deliveryNote as table" &&
                formData.length > 0 && (
                  <>
                    {" "}
                    <div className="w-[3px] h-7 mx-2 bg-gray-300"></div>
                    <div className="flex justify-end gap-4 ">
                      <FilesCard
                        Icon={VscFilePdf}
                        tailwindTextColor="text-white"
                        tailwindBackgroundColor="bg-orange-400"
                        disabled
                      />
                      <CSVExportButton
                        csvHeaders={csvHeaders}
                        csvData={formData}
                        filename={`Delivery Note.csv`}
                      />
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
        {activeDeliveryNote === "deliveryNote as grid" && (
          <ScrollableFrame
            setLimit={setLimit}
            setPage={setPage}
            limit={limit}
            total={deliverynote?.all?.total}
            count={Math.ceil(deliverynote?.all?.total / limit)}
            customSize={300}
          >
            <div className="flex flex-wrap gap-6 bg-white w-full justify-start">
              {deliverynote?.isFetching ? (
                <div className="flex flex-wrap gap-6 ">
                  {Array.from({ length: 5 })?.map(() => (
                    <div className="bg-white w-[370px] h-[160px] border-t-[0.5px] border-t-[rgba(217,219,233,0.5)] rounded-[10px] shadow-[1px_3px_16px_0px_rgba(0,0,0,0.07)] p-6">
                      <Skeleton active paragraph={{ rows: 2 }} />
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  {deliverynote?.all?.data?.map((item: any) => (
                    <Badge.Ribbon
                      text={
                        <span
                          style={{
                            fontSize: "12px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            fontWeight: 400,
                            paddingTop: "1.5px",
                            color: "white",
                          }}
                        >
                          {item?.status}
                        </span>
                      }
                      color={searchValue(item?.status)}
                      style={{ width: "6rem", padding: "4px 1px" }}
                    >
                      <div
                        key={item?._id}
                        className="bg-white rounded-[10px] h-40 w-[370px] p-4 px-2 shadow-[1px_3px_16px_0px_rgba(0,0,0,0.07)] relative text-[#030229] font-normal"
                      >
                        <Link to={`/subscription/DeliveryNote/${item?._id}`}>
                          <div className="flex flex-col gap-1">
                            <h2 className="text-[14px] font-poppins capitalize">
                              {item?.deliverer?.name}
                            </h2>
                            <p className="opacity-70 text-[14px]">
                              {item?.deliverer?.phone}
                            </p>
                            <p className="text-[14px] text-[#605BFF] mt-2">
                              {item.SDNID}
                            </p>
                          </div>
                          <div className="text-[#0F0F47] pb-4">
                            {item.monthlyPayment}
                          </div>
                          <p className="text-sm  font-poppins absolute bottom-4 left-4 opacity-80">
                            {dayjs(item.createdAt).format("YYYY-MM-DD ")}
                          </p>
                          <p className="text-sm  font-poppins absolute bottom-4 right-4 opacity-80">
                            {`${item.list?.length} Devices`}
                          </p>
                        </Link>
                      </div>
                    </Badge.Ribbon>
                  ))}
                </>
              )}
            </div>
          </ScrollableFrame>
        )}
        {activeDeliveryNote === "deliveryNote as table" && (
          <ScrollableFrame
            setLimit={setLimit}
            setPage={setPage}
            limit={limit}
            total={deliverynote?.all?.total}
            count={Math.ceil(deliverynote?.all?.total / limit)}
            customSize={300}
          >
            <Table
              onRow={(record: any, _rowIndex: any) => {
                return {
                  onClick: (event: any) => {
                    navigate(`/subscription/DeliveryNote/${record?._id}`);
                  },
                };
              }}
              columns={columns}
              dataSource={formData}
              pagination={false}
              loading={deliverynote?.isFetching}
            />
          </ScrollableFrame>
        )}
      </div>
    </>
  );
};
export default SubscriptionDeliveryNoteCard;
