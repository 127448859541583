import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "files",
  initialState: {
    isFetching: false,
    all: { data: [] },
    updated: null,
    new: null,
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setUpdated(state, action) {
      state.updated = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
  },
});

export const myFiles = mySlice.actions;

export default mySlice.reducer;
