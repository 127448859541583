import React from "react";
import { Form, Input, Select, Space } from "antd";
import type { SearchProps } from "antd/es/input/Search";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import CustomButton from "../buttons/CustomButton";
import { ReactComponent as SaveIcon } from "../../assets/images/icons/save.svg";

dayjs.extend(customParseFormat);
const { Search } = Input;
const onSearch: SearchProps["onSearch"] = (value, _e, info) =>
  console.log(info?.source, value);
const AddCustomerForm = () => {
  return (
    <div>
      <Search
        placeholder="input search text"
        onSearch={onSearch}
        style={{ width: "200px", float: "right" }}
      />
      <Form
        name="wrap"
        colon={false}
        style={{ width: "100%", padding: "1rem" }}
      >
        <div className="flex gap-8 mt-10">
          <div className="w-[30rem]">
            <div className="flex gap-11">
              <span className="text-[12px] text-[#030229] pt-2">Type:</span>
              <Select
                showSearch
                style={{ width: 240, height: 40, marginBottom: "16px" }}
                placeholder="No Use"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={[
                  {
                    value: "1",
                    label: "No User",
                  },
                  {
                    value: "2",
                    label: "users",
                  },
                ]}
              />
            </div>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[1.9rem]">
                TIN:
              </span>
              name="code"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[15rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[.76rem]">
                Name:
              </span>
              name="code"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[15rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[1.26rem]">
                Bank:
              </span>
              name="code"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[15rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
                Address:
              </span>
              name="code"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[15rem]" />
            </Form.Item>
            <div className="flex gap-4">
              <span className="text-[12px] text-[#030229] pt-2 mr-[1.15rem]">
                Group:
              </span>
              <Select
                showSearch
                style={{ width: 240, height: 40 }}
                placeholder="No Use"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={[
                  {
                    value: "1",
                    label: "No User",
                  },
                  {
                    value: "2",
                    label: "users",
                  },
                ]}
              />
            </div>
          </div>

          <div className="w-[30rem]">
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[.4rem]">
                Delegator:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[14rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
                Nationality:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[14rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[1.55rem]">
                E-mail:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[14rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[.8rem]">
                Account:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[14rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[1rem]">
                Remark:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[14rem]" />
            </Form.Item>
          </div>
          <div className="w-[30rem]">
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[.8rem]">
                Phone 1:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[14rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[.68rem]">
                Phone 2:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[14rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[1.15rem]">
                Fax No:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[14rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
                Depositor:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[14rem]" />
            </Form.Item>
            <div className="flex gap-11">
              <span className="text-[12px] text-[#030229] pt-2 w-10">Use:</span>
              <Select
                showSearch
                style={{ width: 220, height: 40 }}
                placeholder="No Use"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={[
                  {
                    value: "1",
                    label: "No User",
                  },
                  {
                    value: "2",
                    label: "users",
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <Form.Item label=" " className="w-1/2 m-auto mt-5">
          <CustomButton
            title={"Save"}
            bgColor="[#605BFF]"
            width="full"
            textColor="white"
            icon={<SaveIcon />}
          />
        </Form.Item>
      </Form>
    </div>
  );
};
export default AddCustomerForm;
