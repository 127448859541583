import { notification } from "antd";
import { mySubscritionActions } from ".";
import {
  addOneItemServiceOrder,
  createOrderService,
  createSubOrderItemService,
  getAllSubOrder,
  getOneSubOrderService,
  updateSubOrderService,
  updateSubsOrderStatusService,
  getOrderStatusService,
  deleteSubOrderService,
  deleteSubOrderItemService,
  getBusinessProductService,
} from "./services";

export const createOrderAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await createOrderService(data, token);
      if (res?.status === 201) {
        dispatch(mySubscritionActions.setNew(res));
        dispatch(mySubscritionActions.setIsFetching(false));
        return { type: true, data: res?.data };
      }
      dispatch(mySubscritionActions.setIsFetching(false));
      return { type: false, data: {} };
    } catch (err) {
      console.log(err);
    }
  };
};

export const createSubOrderItemAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setNewOrderIsFetching(true));
      const res = await createSubOrderItemService(data, token);
      if (res?.status === 201) {
        dispatch(mySubscritionActions.setSubOrderItem(res));
        dispatch(mySubscritionActions.setNewOrderIsFetching(false));
      }
      dispatch(mySubscritionActions.setNewOrderIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllSubscriptionOrdersAction = (token: string, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await getAllSubOrder(token, query);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setAll(res));
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneSubsOrderAction = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setNewOrderIsFetching(true));
      const res = await getOneSubOrderService(id, token);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setSelected(res?.data));
        dispatch(mySubscritionActions.setNewOrderIsFetching(false));
      }
      dispatch(mySubscritionActions.setNewOrderIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const submitSubOrdersAction = (
  itemId: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setCreateOderIsFetching(true));
      const res = await updateSubOrderService(itemId, data, token);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setUpdateSubOrderItem(res?.data));
        dispatch(mySubscritionActions.setCreateOderIsFetching(false));
        return true;
      }
      dispatch(mySubscritionActions.setCreateOderIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const addItemToSelectedOrderAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await addOneItemServiceOrder(token, data);

      if (res?.status === 200) {
        const resSelected: any = await getAllSubOrder(token);
        dispatch(mySubscritionActions.setSelected(resSelected?.data[0]));
        dispatch(mySubscritionActions.setIsFetching(false));
      } else if (res?.status === 200 && res?.data.length !== 1) {
        console.log(res);
        notification.error({ message: "RFQ ID Is Not Exist!" });
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteSubsorderAction = (token: string, deleteDataId: string) => {
  return async (dispatch: any) => {
    // console.log(deleteDataId,"ssssssssssssssssssssssssssssss")
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await deleteSubOrderService(deleteDataId, token);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setOrderDeleted(res?.data));
        // dispatch(mySubscritionActions.setAll(res));
        dispatch(mySubscritionActions.setIsFetching(false));
        notification.success({ message: "Order deleted succesfully" });
        return true;
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateSubsorderStatusAction = (
  orderId: string,
  status: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await updateSubsOrderStatusService(orderId, status, token);
      if (res?.status === 200) {
        notification.success({ message: "Accepted" });
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getOrderStatusAction = (token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await getOrderStatusService(token);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setOrderStatus(res));
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteSubsorderItemAction = (
  token: string,
  deleteDataId: string,
  orderId?: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setOrderItemIsFetching(true));
      const res = await deleteSubOrderItemService(deleteDataId, token);
      if (res?.status === 200) {
        const resAll = await getOneSubOrderService(orderId, token);
        dispatch(mySubscritionActions.setSelected(resAll?.data));
        dispatch(mySubscritionActions.setOrderItemIsFetching(false));
        notification.success({ message: "Removed succesfully" });
        return true;
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getBusinessProductAction = (token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await getBusinessProductService(token);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setBusinessProduct(res));
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
