import React, { useEffect, useState } from "react";
import { Table, Modal, Carousel, Spin, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useDispatch, useSelector } from "react-redux";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import { getAllSubdeliverynotesItemAction } from "../../../../store/subscription/deliverynote/actions";
import FilesCard from "../../../../components/cards/NoSale/FilesCard";
import CSVExportButton from "../../../../components/cards/NoSale/CSVExportButton";
import { VscFilePdf } from "react-icons/vsc";
import { SoldDeviceHeaders } from "../../../../components/csvHeaders/Headers";
import { getSoldIMEIs_CSVAction } from "../../../../store/csvDownload/actions";

interface DataType {
  key: string;
  type: string;
  brand: string;
  model: any;
  specs: any;
  imei: number;
  tags: string[];
}

const DeviceTable: React.FC = () => {
  const columns: ColumnsType<DataType> = [
    {
      dataIndex: "type",
      title: "Type",
      key: "type",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      dataIndex: "model",
      title: "Model",
      key: "model",
    },
    {
      dataIndex: "specs",
      title: "Specs",
      key: "specs",
      width: 200,
    },
    {
      dataIndex: "imei",
      title: "IMEI",
      key: "imei",
    },
    {
      key: "status",
      title: "status",
      dataIndex: "status",
      render: (status) => {
        return (
          <>
            <button
              className={`h-[1.5rem] w-[3.5rem] rounded-md  ${
                status === "Active"
                  ? "bg-[#0FA958]"
                  : ["sold", "Inactive"]?.includes(status)
                  ? "bg-[#F1595C]"
                  : "bg-yellow-300"
              }`}
            >
              <Typography
                style={{ fontSize: 14, fontWeight: 500, color: "#fff" }}
                className="capitalize"
              >
                {status}
              </Typography>
            </button>
          </>
        );
      },
    },
  ];
  const { account, auth, deliverynote, layout, CSV } = useSelector(
    (state: any) => state
  );
  const [selectedDevice, setSelectedDevice] = useState<any>({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);

  const dataToDisplay = deliverynote?.subDeliveryNoteItem?.data?.map(
    (el: any) => {
      return {
        key: el._id,
        type: el?.wareHouseItem?.product?.type,
        brand: el?.wareHouseItem?.product?.brand,
        model: el?.wareHouseItem?.product?.model,
        specs: el?.wareHouseItem?.quotationItem?.specification
          ?.slice(2, 6)
          ?.map((d: any) => d[1] && `${d[0]}:${d[1]}`)
          ?.join(", "),
        imei: el?.wareHouseItem?.serialNumber,
        status: el?.wareHouseItem?.status,
        data: el,
      };
    }
  );

  useEffect(() => {
    if (account?.selected?.deliveryNote?._id && auth?.token) {
      getAllSubdeliverynotesItemAction(
        auth?.token,
        `?deliveryNote=${
          account?.selected?.deliveryNote?._id
        }&limit=${limit}&page=${page - 1}`
      )(dispatch);
    }
  }, [auth?.token, account?.selected?.deliveryNote, dispatch, limit, page]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (value: any) => {
    setSelectedDevice(value);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedDevice({});
  };
  const [isLoading, setIsLoading] = useState(true);
  const handleImageLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    if (layout?.openCSVButton && auth?.token)
      getSoldIMEIs_CSVAction(
        auth?.token,
        `?deliveryNote=${account?.selected?.deliveryNote?._id}&limit=${deliverynote?.subDeliveryNoteItem?.total}&page=0`
      )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  const csvData = CSV?.csvDownloadedData?.data?.map((el: any) => {
    return {
      date: el?.createdAt?.slice(0, 10),
      customer: account?.selected?.customerDetails?.name,
      custTel: account?.selected?.customerDetails?.tel,
      type: el?.wareHouseItem?.product?.type,
      brand: el?.wareHouseItem?.product?.brand,
      model: el?.wareHouseItem?.product?.model,
      imei1: el?.wareHouseItem?.serialNumber,
      imei2: el?.wareHouseItem?.imei2 ?? "N/A",
      storage: el?.wareHouseItem?.quotationItem?.specification
        ?.filter((item: any) => item[0]?.toLowerCase() === "capacity (rom)")
        ?.map((item: any) => item[1]),
      memory: el?.wareHouseItem?.quotationItem?.specification
        ?.filter((item: any) => item[0]?.toLowerCase() === "memory (ram)")
        ?.map((item: any) => item[1]),
      amount: el?.payment?.amount?.toLocaleString(),
    };
  });

  return (
    <div>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        footer={null}
        onCancel={handleCancel}
        width={600}
      >
        <div className="w-[35%] m-auto pb-10">
          <Carousel autoplay>
            {isLoading && (
              <Spin tip="Image is Loading" className="mt-20">
                <div className="content" />
              </Spin>
            )}
            <img
              src={selectedDevice?.data?.wareHouseItem?.product?.images.map(
                (img: any) => img
              )}
              alt=""
              onLoad={handleImageLoad}
              style={{ display: isLoading ? "none" : "block" }}
            />
          </Carousel>
        </div>
        <div className="w-full">
          <span className="flex gap-32 pt-5">
            <p className="font-bold ">Type</p>
            <p className="">{selectedDevice?.type}</p>
          </span>
          <span className="flex gap-[7.5rem] pt-5">
            <p className="font-bold ">Brand</p>
            <p className="">{selectedDevice?.brand}</p>
          </span>
          <span className="flex gap-[7.5rem] pt-5">
            {" "}
            <p className="font-bold">Model</p>{" "}
            <p className="">{selectedDevice?.model}</p>
          </span>
          <span className="flex gap-12 pt-5">
            <p className="font-bold">Specifications</p>
            <p className="">{selectedDevice?.specs}</p>
          </span>
          <span className="flex gap-[8.5rem] pt-5">
            <p className="font-bold ">IMEI</p>
            <p className="">{selectedDevice?.imei}</p>
          </span>
        </div>
      </Modal>
      <div className="flex justify-end gap-4 py-4">
        <FilesCard
          Icon={VscFilePdf}
          tailwindTextColor="text-white"
          tailwindBackgroundColor="bg-orange-400"
          disabled
        />
        <CSVExportButton
          csvHeaders={SoldDeviceHeaders}
          csvData={csvData}
          filename={`${account?.selected?.customerDetails?.name} subscription.csv`}
        />
      </div>
      <div className="bg-[#F4F6FA] rounded">
        <ScrollableFrame
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
          total={deliverynote?.subDeliveryNoteItem?.total}
          count={Math.ceil(deliverynote?.subDeliveryNoteItem?.total / limit)}
        >
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={dataToDisplay}
            pagination={false}
            loading={
              account?.isFetching ||
              deliverynote?.isFetching ||
              deliverynote?.deliveryIsFetching
            }
          />
        </ScrollableFrame>
      </div>
    </div>
  );
};
export default DeviceTable;
