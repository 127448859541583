import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getallpaymentsAction,
  updateOnepaymentsActions,
} from "../../../../store/setting/payments/actions";
import {
  Card,
  Skeleton,
  Modal,
  Typography,
  Divider,
  Form,
  Input,
  Switch as AntSwitch,
  Button,
  List,
  Table 
} from "antd";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { CiCircleMinus } from "react-icons/ci"; // Import for minus icon

const { Title, Text } = Typography;

interface PaymentOption {
  createdBy: any;
  updatedAt: string | number | Date;
  createdAt: string | number | Date;
  subPaymentType: any;
  requirFile: any;
  description:string;
  isDiscountAllowed: any;
  _id: string;
  name: string;
  isActive: boolean;
}

interface PaymentGroup {
  name: string;
}

interface PaymentCategory {
  paymentGroup: PaymentGroup;
  payments: PaymentOption[];
}

interface PaymentOptionsComponentProps {
  searchQuery: string;
}

interface SwitchProps {
  isOn: boolean;
  onToggle: () => void;
}

const Switch: React.FC<SwitchProps> = ({ isOn, onToggle }) => (
  <div
    onClick={(e) => {
      e.stopPropagation();
      onToggle();
    }}
    className={`relative inline-flex h-5 w-10 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 cursor-pointer ${
      isOn ? "bg-indigo-600" : "bg-gray-200"
    }`}
  >
    <span
      className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
        isOn ? "translate-x-6" : "translate-x-1"
      }`}
    />
  </div>
);

interface PaymentOptionCardProps {
  title: string;
  options: PaymentOption[];
  onToggle: (id: string, isActive: boolean) => void;
  onCardClick: (option: PaymentOption) => void;
}

const PaymentOptionCard: React.FC<PaymentOptionCardProps> = ({
  title,
  options,
  onToggle,
  onCardClick,
}) => (
  <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-4 w-[327px]">
    <h3 className="text-lg font-poppins mb-4">{title}</h3>
    {options.map((option) => (
      <div
        key={option._id}
        className="flex justify-between items-center mb-3 last:mb-0 border border-gray-200 p-2 rounded-md cursor-pointer"
        onClick={() => onCardClick(option)}
      >
        <span className="text-sm">{option?.name}</span>
        <div className="flex flex-row items-center gap-4 border border-gray-200 rounded-md p-2">
          <span className="text-sm">{option.isActive ? "On" : "Off"}</span>
          <Switch
            isOn={option.isActive}
            onToggle={() => onToggle(option._id, option.isActive)}
          />
        </div>
      </div>
    ))}
  </div>
);

interface PaymentOptionModalProps {
  isOpen: boolean;
  onClose: () => void;
  paymentOption: PaymentOption | null;
  onToggle: (id: string, isActive: boolean) => void;
  onSave: (updatedPayment: PaymentOption) => void;
}

const PaymentOptionModal: React.FC<PaymentOptionModalProps> = ({
  isOpen,
  onClose,
  paymentOption,
  onToggle,
  onSave,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();
  const [paymentTypeInput, setPaymentTypeInput] = useState<string>("");
  const [paymentTypes, setPaymentTypes] = useState<string[]>([]);

  useEffect(() => {
    if (paymentOption) {
      form.setFieldsValue({
        name: paymentOption.name,
        isActive: paymentOption.isActive,
        description:paymentOption?.description,
        isDiscountAllowed: paymentOption.isDiscountAllowed,
        requirFile: paymentOption.requirFile,
      });
      // Set initial subPaymentType
      setPaymentTypes(paymentOption.subPaymentType || []);
    }
  }, [paymentOption, form]);

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        const updatedPayment: any = {
          ...paymentOption!,
          ...values,
          subPaymentType: paymentTypes, // Save updated payment types
        };
        onSave(updatedPayment);
        setIsEditMode(false);
      })
      .catch((errorInfo) => {
        console.log("Validation Failed:", errorInfo);
      });
  };

  const handleAddPaymentType = () => {
    if (paymentTypeInput.trim()) {
      setPaymentTypes([...paymentTypes, paymentTypeInput]);
      setPaymentTypeInput("");
    }
  };

  const handleRemovePaymentType = (index: number) => {
    const updatedPaymentTypes = paymentTypes.filter((_, i) => i !== index);
    setPaymentTypes(updatedPaymentTypes);
  };

  const columns = [
    {
      title: "",
      dataIndex: "type",
      key: "type",
    },
  ];

  const subPaymentData = paymentTypes.map((item, index) => ({
    key: index,
    type: item,
  }));

  if (!paymentOption) return null;

  return (
    <Modal
      title="Payment Details"
      open={isOpen}
      onCancel={onClose}
      footer={
        isEditMode ? (
          <div className="flex flex-row gap-1">
            <Button onClick={() => setIsEditMode(false)}>Cancel</Button>
            <Button type="primary" onClick={handleSave}>
              Update
            </Button>
          </div>
        ) : null
      }
      width={450}
    >
      {isEditMode ? (
        <Form layout="vertical" form={form}>
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Active" name="isActive" valuePropName="checked">
            <AntSwitch />
          </Form.Item>
          <Form.Item label="Description" name="description" rules={[{ required: false }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Discount Allowed"
            name="isDiscountAllowed"
            valuePropName="checked"
          >
            <AntSwitch />
          </Form.Item>
          <Form.Item
            label="Requires File"
            name="requirFile"
            valuePropName="checked"
          >
            <AntSwitch />
          </Form.Item>

          {/* Add Payment Type */}
          <Form.Item label="Add Payment Type">
            <Input
              value={paymentTypeInput}
              onChange={(e) => setPaymentTypeInput(e.target.value)}
              placeholder="Enter payment type"
              onPressEnter={handleAddPaymentType}
            />
            <Button
              type="primary"
              onClick={handleAddPaymentType}
              style={{ marginTop: "10px" }}
            >
              Add more
            </Button>
          </Form.Item>

          {paymentTypes.length > 0 && (
            <Form.Item>
              <div className="max-h-[200px] overflow-y-auto">
                <List
                  bordered
                  dataSource={paymentTypes}
                  renderItem={(item, index) => (
                    <List.Item
                      actions={[
                        <Button
                          type="link"
                          onClick={() => handleRemovePaymentType(index)}
                        >
                          <CiCircleMinus size={20} />
                        </Button>,
                      ]}
                    >
                      {item}
                    </List.Item>
                  )}
                />
              </div>
            </Form.Item>
          )}
        </Form>
      ) : (
        <>


          <div className="flex flex-row items-center justify-between gap-2">
            <Title level={5}>{paymentOption.name}</Title>
            <div
              className="cursor-pointer text-lg text-gray-400"
              onClick={() => setIsEditMode(true)}
            >
              <MdOutlineModeEditOutline />
            </div>
          </div>

          <div className="mt-2 flex flex-row items-center gap-1">
            <Title level={5}>Status: </Title>
            <Text className="ml-2">
              {paymentOption?.isActive ? "Active" : "Inactive"}
            </Text>
          </div>

          <div className="mt-2">
            <Title level={5}>SubPayment Type:</Title>
            <Table
              columns={columns}
              dataSource={subPaymentData}
              pagination={false}
              size="small"
            />
          </div>

          <div className="mt-2 flex flex-row items-center gap-1">
            <Title level={5}>Discount Allowed: </Title>
            <Text>{paymentOption?.isDiscountAllowed ? "Yes" : "No"}</Text>
          </div>
          <div className="mt-2 flex flex-row items-center gap-1">
            <Title level={5}>Description: </Title>
            <Text>{paymentOption?.description}</Text>
          </div>

          <div className="mt-2 flex flex-row items-center gap-1">
            <Text strong>Requires File: </Text>
            <Text>{paymentOption?.requirFile ? "Yes" : "No"}</Text>
          </div>
        </>
      )}
    </Modal>
  );
};




const PaymentOptionsComponent: React.FC<PaymentOptionsComponentProps> = ({
  searchQuery,
}) => {
  const { auth, payments, channel } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPaymentOption, setSelectedPaymentOption] =
    useState<PaymentOption | null>(null);

  useEffect(() => {
    const fetchPayments = async () => {
      setLoading(true);
      await getallpaymentsAction(
        auth.token,
        `?channel=${channel?.selected?._id}`
      )(dispatch);
      setLoading(false);
    };
    fetchPayments();
  }, [dispatch, auth.token, channel?.selected?._id]);

  const handleToggle = async (paymentId: string, isActive: boolean) => {
    setLoading(true);
    await updateOnepaymentsActions(
      paymentId,
      {
        isActive: !isActive,
      },
      auth?.token
    )(dispatch);
    await getallpaymentsAction(
      auth.token,
      `?channel=${channel?.selected?._id}`
    )(dispatch);
    setLoading(false);
  };

  const handleSave = async (updatedPayment: PaymentOption) => {
    setLoading(true);
    await updateOnepaymentsActions(
      updatedPayment._id,
      updatedPayment,
      auth?.token
    )(dispatch);
    await getallpaymentsAction(
      auth.token,
      `?channel=${channel?.selected?._id}`
    )(dispatch);
    setLoading(false);
    setIsModalOpen(false);
    setSelectedPaymentOption(null);
  };

  const handleCardClick = (option: PaymentOption) => {
    setSelectedPaymentOption(option);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPaymentOption(null);
  };

  return (
    <>
      {loading ? (
        <div className="flex flex-wrap gap-4">
          {Array(4)
            .fill(0)
            .map((_, index) => (
              <>
                <Card style={{ width: "300px", marginTop: 16 }}>
                  <Skeleton active></Skeleton>
                </Card>
                <Card style={{ width: "300px", marginTop: 16 }}>
                  <Skeleton active></Skeleton>
                </Card>
              </>
            ))}
        </div>
      ) : (
        <div className="flex flex-wrap gap-4">
          {payments?.allPayments?.data?.map((category: PaymentCategory) => (
            <PaymentOptionCard
              key={category?.paymentGroup?.name}
              title={category?.paymentGroup?.name}
              options={category?.payments}
              onToggle={handleToggle}
              onCardClick={handleCardClick}
            />
          ))}
        </div>
      )}
      {selectedPaymentOption && (
        <PaymentOptionModal
          isOpen={isModalOpen}
          onClose={closeModal}
          paymentOption={selectedPaymentOption}
          onToggle={handleToggle}
          onSave={handleSave}
        />
      )}
    </>
  );
};

export default PaymentOptionsComponent;
