import React from "react";
import { Table, Tag } from "antd";
import HoverDropDown from "../DropDown/HoverDropDown";
import type { MenuProps } from "antd";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getNameByCode } from "../../utils/ConvertCodeToName";

const ImportTable = (props: any) => {
  const { auth, ebmImport, ebmGeneral } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const createMenuItems = (record: any): MenuProps["items"] => [
    // {
    //   label: (
    //     <div className="flex gap-2">
    //       <SlEye size={20} className="pt-1" />
    //       <span className="text-sm pt-1">View Details</span>
    //     </div>
    //   ),
    //   key: "0",
    //   onClick: () => console.log("record", record)
    // },
    {
      label: (
        <div className="flex gap-3" onClick={() => props?.openModals(record)}>
          <FiEdit size={20} className="pt-1 opacity-80" />
          <span className="text-sm pt-1">Modify</span>
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div className="flex gap-2">
          <MdDeleteOutline size={22} className="pt-1 opacity-80" />
          <span className="text-sm pt-1">Delete</span>
        </div>
      ),
      key: "1",
    },
  ];
  const columns = [
    {
      title: "Declared Date",
      dataIndex: "date",
    },
    {
      title: "Op. Code",
      dataIndex: "opCode",
    },
    {
      title: "Item Seq",
      dataIndex: "item",
    },
    {
      title: "Orgin",
      dataIndex: "orgin",
      render: (text: any) => (
        <>
          <p>
            {ebmGeneral?.selectOption?.Cuntry &&
              getNameByCode(ebmGeneral?.selectOption?.Cuntry, text)}
          </p>
        </>
      ),
    },
    {
      title: "Export Country",
      key: "country",
      dataIndex: "country",
      render: (text: any) => (
        <>
          <p>
            {ebmGeneral?.selectOption?.Cuntry &&
              getNameByCode(ebmGeneral?.selectOption?.Cuntry, text)}
          </p>
        </>
      ),
    },
    {
      title: "Qty ",
      dataIndex: "qauntity",
    },
    {
      title: "Gross WT",
      dataIndex: "gross",
    },
    {
      title: "Net WT",
      dataIndex: "net",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (record: any) => {
        const value = ebmGeneral?.selectOption
          ? getNameByCode(
            ebmGeneral?.selectOption["Import Item Status"],
            record
          )
          : "";
        return (
          <>
            <Tag
              className={`w-[9rem] text-center ${value === "Approved"
                ? "text-[#0FA91E] border border-[#0FA91E40] bg-[#0FA91E0D]"
                : value === "Waiting"
                  ? "text-[#030229B2] border border-[#03022940] bg-[#0302290D]"
                  : value === "Cancelled"
                    ? "text-[#FA3659] border border-[#FA3659]"
                    : value === "Unsent"
                      ? "text-[#FB923C] border border-[#FB923C40] bg-[#FB923C0D]"
                      : ""
                } rounded-full py-2 font-normal text-[12px]`}
            >
              {value}
            </Tag>
          </>
        );
      },
    },
    {
      title: " ",
      dataIndex: "data",
      render: (record: any) => (
        <>
          <HoverDropDown items={createMenuItems(record)} />
        </>
      ),
    },
  ];
  const data =
    ebmImport?.all?.map((el: any, index: any) => {
      let year = el?.dclDe?.substring(0, 4);
      let month = el?.dclDe?.substring(4, 6) - 1;
      let day = el?.dclDe?.substring(6, 8);
      let date = new Date(year, month, day);
      let formattedDate = date.toISOString().split("T")[0];
      return {
        key: index,
        item: el?.itemSeq,
        country: el?.exptNatCd,
        orgin: el?.orgnNatCd,
        net: el?.netWt,
        date: formattedDate,
        qauntity: el?.qty,
        gross: el?.totWt,
        opCode: el?.taskCd,
        vat: "0",
        status: el?.imptItemsttsCd,
        data: el,
      };
    }) || [];
  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        size="middle"
        loading={ebmImport?.isFetching}
      />
    </>
  );
};
export default ImportTable;
