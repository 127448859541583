import React from "react";
import { Table, Space, Tooltip } from "antd";
import { IconButton, Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CancelIcon from "@mui/icons-material/Cancel";
import type { ColumnsType, TableProps } from "antd/es/table";
import * as convert from "../../assets/data/productExport";

interface DataType {
  createdAt: String;
  listOfProducts: [];
  listOfSuppliers: [];
  status: String;
  createdBy: String;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Date",
    dataIndex: "createdAt",

    // onFilter: (value: string, record) => record.address.indexOf(value) === 0,
  },
  {
    title: "Quotation ID",
    dataIndex: "quotationId",

    // onFilter: (value: string, record) => record.address.indexOf(value) === 0,
  },
  {
    title: "Number Of Products",
    dataIndex: "listOfProducts",
    render: (_, record) => <h1>{record.listOfProducts.length}</h1>,

    // onFilter: (value: string, record) => record.address.indexOf(value) === 0,
  },
  {
    title: "Number Of Suppliers",
    dataIndex: "listOfSuppliers",
    render: (_, record) => <h1>{record.listOfSuppliers.length}</h1>,
    // specify the condition of filtering result
    // here is that finding the name started with `value`
    // onFilter: (value: string, record:any) => record.name.indexOf(value) === 0,

    // sorter: (a, b) => a.model.length - b.model.length,
    // sortOrder: sortedInfo.columnKey === 'address' ? sortedInfo.order : null,
    // sortDirections: ["descend"],
  },
  {
    title: "Status",
    dataIndex: "status",
    // defaultSortOrder: "descend",
    // sorter: (a, b) => a.age - b.age,
  },
  // {
  //   title: "Color",
  //   dataIndex: "colors",

  //   // onFilter: (value: string, record) => record.address.indexOf(value) === 0,
  // },
  // {
  //   title: "Status",
  //   dataIndex: "status",

  //   // onFilter: (value: string, record) => record.address.indexOf(value) === 0,
  // },
  {
    title: "Action",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <Button aria-label="delete" startIcon={<VisibilityIcon />}>
          View
        </Button>
        <Button aria-label="delete" color="error" startIcon={<CancelIcon />}>
          Cancel
        </Button>
      </Space>
    ),
  },
];

const onChange: TableProps<DataType>["onChange"] = (
  pagination,
  filters,
  sorter,
  extra
) => {
  console.log("params", pagination, filters, sorter, extra);
};

const App = (props: any) => {
  const data = props?.data?.map((d: any) =>
    convert.createExportQuotationData(
      d?.quotationId,
      d?.createdAt,
      d?.listOfProducts,
      d?.listOfSuppliers,
      d?.status,
      d?.createdBy
    )
  );

  return <Table columns={columns} dataSource={data} onChange={onChange} />;
};

export default App;
