import { notification } from "antd";
import { mySubscritionActions } from ".";
// import { mySubscritionActionsOrder } from "../order";
import {
  getAllSubdeliverynote,
  getAllSubdeliverynoteItem,
  getOneSubdeliveryNoteService,
  createsubsDeliveryNoteItemService,
  updateSubsDeliveryNoteService,
  updateSubsDeliveryNoteItemService,
  generateB2bInvoiceService,
  printPDFService,
  deleteSubsDeliveryItemService,
} from "./services";
import { getOneSubOrderService } from "../order/services";

export const getAllSubdeliverynotesAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await getAllSubdeliverynote(token, query);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setAll(res));
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOnesubDeliveryNoteAction = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await getOneSubdeliveryNoteService(id, token);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setSingleDeliveryNote(res?.data));
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllSubdeliverynotesItemAction = (
  token: string,
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setDeliveryIsFetching(true));
      const res = await getAllSubdeliverynoteItem(token, query);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setSubDeliveryNoteItem(res));
        dispatch(mySubscritionActions.setInvoiceResult(res));
        dispatch(mySubscritionActions.setDeliveryIsFetching(false));
      }
      dispatch(mySubscritionActions.setDeliveryIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const createsubsDeliveryNoteItemAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await createsubsDeliveryNoteItemService(data, token);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setNew(res));
        notification.success({
          message: res?.message,
          duration: 3, // Notification will close after 3 seconds
          placement: "topRight",
        });
      } else {
        notification.error({
          message: "Error",
          description: res?.response?.data?.error || "An error occurred",
          placement: "topRight",
        });
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log("werrere", err);
    }
  };
};

export const updateSubsDeliveryNoteAction = (
  deliveryNoteId: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await updateSubsDeliveryNoteService(
        deliveryNoteId,
        data,
        token
      );
      if (res?.status === 200) {
        // dispatch(mySubscritionActions.setUpdateSubOrderItem(res?.data));
        // dispatch(mySubscritionActions.setIsFetching(false));
        // dispatch(mySubscritionActions.setIsFetching(false));
        return true;
      }
      dispatch(mySubscritionActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateSubsDeliveryNoteItemAction = (
  deliveryNoteId: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await updateSubsDeliveryNoteItemService(
        deliveryNoteId,
        data,
        token
      );
      if (res?.status === 200) {
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const generateFirstInvoiceAction = (token: string, orderId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await generateB2bInvoiceService(orderId, token);
      if (res?.status === 201) {
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const printPDFAction = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await printPDFService(id, token);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setDeliveryNotepdf(res));
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteSubsDeliveryItemAction = (
  token: string,
  deleteDataId: string,
  query?: any,
  orderId?: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setSubDeliveryItemIsFetching(true));
      const res = await deleteSubsDeliveryItemService(deleteDataId, token);
      if (res?.status === 200) {
        const resAll = await getAllSubdeliverynoteItem(token, query);
        const selectedPickList = await getOneSubOrderService(orderId, token);
        dispatch(mySubscritionActions.setSelected(selectedPickList?.data));
        dispatch(mySubscritionActions.setSubDeliveryNoteItem(resAll));
        dispatch(mySubscritionActions.setSubDeliveryItemIsFetching(false));
        notification.success({ message: "Removed succesfully" });
        return true;
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
