import React from "react";
import { Col, Divider, Row } from "antd";

interface DescriptionItemProps {
  title: string;
  content?: React.ReactNode;
}

const DescriptionItem = ({ title, content }: DescriptionItemProps) => (
  <div className="flex items-center justify-between w-full">
    <p className="font-semibold text-sm w-2/5">{title}</p>
    <span className="w-3/5">{content}</span>
  </div>
);

const DelivererOut: React.FC<{
  data: any;
}> = ({ data }) => {
  return (
    <div>
      <p
        className="site-description-item-profile-p font-bold text-lg"
        style={{ marginBottom: 10 }}
      >
        Deliverer Out
      </p>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Name" content={data?.name} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Company" content={data?.company} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Telephone" content={data?.phone} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Email" content={data?.email} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="date" content={data?.date?.slice(0, 10)} />
        </Col>
      </Row>
      <Divider />
    </div>
  );
};

export default DelivererOut;
