import React from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector } from "react-redux";

interface DataType {
  [x: string]: any;
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const NosaleReasonTable = (_props: any) => {
  const { pos, dashboard } = useSelector((state: any) => state);
  const data = dashboard?.nosaleSpecificBrandStats?.data?.current;
  const combineNoReason = (data: any) => {
    return data?.shops?.flatMap((item: any) => item.result);
  };

  const results = combineNoReason(data);

  const columns: ColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Shop",
      dataIndex: "shop",
      key: "shop",
    },
    {
      title: "Reason",
      dataIndex: "comments",
      key: "comments",
      render: (text, record) => <p>{text || "N/A"}</p>,
    },
  ];

  const dataToShow = results?.map((el: any) => {
    return {
      date: el?.createdAt?.slice(0, 10),
      shop: el?.shop?.name,
      comments: el?.comment,
      data: el,
    };
  });

  return (
    <div className="relative bg-white p-2 rounded-lg border border-gray-300 mt-4 xl:h-80">
      <p className="text-lg pb-2">No Sale Reason</p>
      <Table
        columns={columns}
        dataSource={dataToShow}
        // pagination={false}
        loading={pos?.isFetching}
        scroll={{ y: 200 }}
      />
    </div>
  );
};

export default NosaleReasonTable;
