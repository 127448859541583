export const roles = [
  "admin",
  "finance",
  "finance-manager",
  "inventory",
  "technician",
  "sales-manager",
  "sales-supervisor",
  "sales-agent",
  "logistic-manager",
  "hr",
  "dev",
  "partner",
  "franchisee",
  "driver",
  "cleaner",
  "b2b-relation",
];

export {};
