import React from "react";
import { Button, Form, Input, Spin } from "antd";
import {
  createDelivererAction,
  createDeliveryActivityAction,
  getDelivererAction,
} from "../../../../store/delivery/actions";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";

const CreateDelivererForm = (props: any) => {
  const { auth, tracking } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const onFinish = async (values: any) => {
    await createDelivererAction(auth?.token, {
      ...values,
    })(dispatch);
    await getDelivererAction(auth?.token)(dispatch);
    props?.onOk();
  };
  return (
    <Form
      name="basic"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: "100%" }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        name="name"
        label={<h1 className="text-[#030229B2] text-base">Name</h1>}
        //   initialValue={deliverer?.name}
        rules={[
          {
            required: true,
            message: "Name is required!",
            whitespace: true,
          },
        ]}
      >
        <Input className="h-12" />
      </Form.Item>
      <Form.Item
        name="phone"
        label={<h1 className="text-[#030229B2] text-base">Phone</h1>}
        //   initialValue={deliverer?.phone}
        rules={[
          {
            required: true,
            message: "Phone is required!",
            whitespace: true,
          },
        ]}
      >
     <PhoneInput
                country={"rw"}
                inputStyle={{
                  width: "100%",
                  height: "3rem",
                  color: "black",
                }}
                containerClass="phone-container"
                placeholder="+250 780 000 000"
              />
      </Form.Item>
      <Form.Item
        name="company"
        label={<h1 className="text-[#030229B2] text-base">Company</h1>}
        //   initialValue={deliverer?.company}
        rules={[
          {
            required: true,
            message: "Company name is required!",
            whitespace: true,
          },
        ]}
      >
        <Input className="h-12"/>
      </Form.Item>
      <Form.Item
        name="email"
        label={<h1 className="text-[#030229B2] text-base">Email</h1>}
        //   initialValue={deliverer?.email}
        rules={[
          {
            required: true,
            message: "Email is required!",
            whitespace: true,
          },
        ]}
      >
        <Input className="h-12" />
      </Form.Item>

      <Form.Item className="w-full" wrapperCol={{ offset: 8 }}>
        <Button type="primary" htmlType="submit" className="w-1/2 h-12">
          {tracking?.isFetching ? (
            <Spin indicator={<LoadingOutlined spin/>} size="small" />
          ) : (
            " Submit"
          )}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateDelivererForm;
