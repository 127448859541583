import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Table, Typography, Progress } from "antd";
import { Select } from "antd";
import { calculatePercentageChange } from "../../utils/converter";
import CsvDownloadButton from "../buttons/CsvDownloadButton";
import moment from "moment";
import { getDefaultCurrencyCode } from "../../utils/helper";
import { getAllRegionAction } from "../../store/channel/actions";

const { Option } = Select;

const { Text } = Typography;
const Shops: React.FC<{
  title: string;
  setSelecteShopRegionKey: any;
  selectedShopRegionKey: any;
  enteredDate: any;
  compareDate: any;
}> = ({
  title,
  setSelecteShopRegionKey,
  enteredDate,
  compareDate,
  selectedShopRegionKey,
}) => {
  const { dashboard, layout, company, channel, auth } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const formattedDate = new Date().toISOString().slice(0, 10);
  let today = moment();
  let previousDate = today.subtract(1, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");
  const data = dashboard.shopStats?.data?.map((d: any) => {
    return {
      name: d?.shopName,
      revenue: d?.currentDateRevenue,
      comparedRevenue: d?.comparedDateRevenue,
      quantity: d?.currentDateQuantity,
      comparedQuantity: d?.comparedDateQuantity,
      perc:
        (d?.currentDateRevenue * 100) /
        (d?.currentDateRevenue + d?.comparedDateRevenue),
      dashPreview: layout?.dashboardPreview,
    };
  });

  const totalCurrentRevenue = dashboard.shopStats?.data?.reduce(
    (total: any, item: any) => total + item.currentDateRevenue,
    0
  );

  const totalComparedRevenue = dashboard.shopStats?.data?.reduce(
    (total: any, item: any) => total + item.comparedDateRevenue,
    0
  );

  const columns = [
    {
      title: "",
      dataIndex: "perc",
      key: "perc",
      width: 30,
      render: (text: any) => (
        <div className="">
          <Progress type="dashboard" percent={text.toFixed(1)} size={20} />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string) => <div className="">{text}</div>,
    },
    {
      title:
        layout?.dashboardPreview === "range"
          ? ""
          : `${compareDate} Revenue(${getDefaultCurrencyCode(company)})`,
      dataIndex: "comparedRevenue",
      key: "comparedRevenue",
      render: (text: string, record: any) =>
        record?.dashPreview === "day" && (
          <div className=" w-full truncate">{text?.toLocaleString()}</div>
        ),
    },
    {
      title:
        layout?.dashboardPreview === "range"
          ? `${compareDate} - ${enteredDate} Revenue(${getDefaultCurrencyCode(
              company
            )})`
          : `${enteredDate} Revenue(${getDefaultCurrencyCode(company)})`,
      dataIndex: "revenue",
      key: "revenue",
      render: (text: string) => (
        <div className=" w-full truncate font-bold">
          {text?.toLocaleString()}
        </div>
      ),
    },
    {
      title:
        layout?.dashboardPreview === "range" ? "" : `${compareDate} Quantities`,
      dataIndex: "comparedQuantity",
      key: "comparedQuantity",
      render: (text: string, record: any) =>
        record?.dashPreview === "day" && (
          <div className=" w-full truncate">{text?.toLocaleString()}</div>
        ),
    },
    {
      title:
        layout?.dashboardPreview === "range"
          ? `${compareDate} - ${enteredDate} Quantities`
          : `${enteredDate} Quantities`,
      dataIndex: "quantity",
      key: "quantity",
      render: (text: any) => <div className="font-bold">{text}</div>,
    },
  ];

  const handleSelectShopRegion = (value: any) => {
    setSelecteShopRegionKey(value);
  };

  useEffect(() => {
    auth?.token && getAllRegionAction(auth?.token, ``)(dispatch);
  }, [dispatch, auth?.token]);

  const percentageChange = calculatePercentageChange(
    totalCurrentRevenue,
    totalComparedRevenue
  );

  const csvHeaders = [
    { label: "Region", key: "region" },
    { label: "Shops", key: "shops" },
    { label: `Revenue (${enteredDate})`, key: "todayRevenue" },
    { label: `Quantity (${enteredDate})`, key: "todaysQty" },
    { label: `Revenue (${compareDate})`, key: "comparedRevenue" },
    { label: `Quantity (${compareDate})`, key: "comparedQty" },
  ];

  const csvData = dashboard.shopStats?.data?.map((record: any) => ({
    region: selectedShopRegionKey || "-",
    shops: record?.shopName,
    todayRevenue: record?.currentDateRevenue?.toLocaleString(),
    todaysQty: record?.currentDateQuantity?.toLocaleString(),
    comparedRevenue: record?.comparedDateRevenue?.toLocaleString(),
    comparedQty: record?.comparedDateQuantity?.toLocaleString(),
  }));

  return (
    <div className="bg-white mt-5 p-4 rounded-lg lg:w-[49%]">
      <div className="sm:flex justify-between items-center w-full">
        <div className="flex items-center justify-between sm:space-x-2">
          <h2 className="text-xl capitalize">{title}</h2>
          <Select
            placeholder="Filter by Region"
            allowClear
            style={{ width: 150 }}
            onChange={(value) => {
              if (value === undefined) {
                handleSelectShopRegion("");
              } else {
                handleSelectShopRegion(value);
              }
            }}
          >
            {channel?.allRegion?.data?.map((d: any, index: any) => (
              <Option value={d?.name} key={index}>
                {d?.name}
              </Option>
            ))}
          </Select>
        </div>
        <div
          className={`${
            percentageChange?.type === "increase"
              ? "bg-green-200"
              : percentageChange?.type === "decrease"
              ? "bg-red-200"
              : "bg-blue-200"
          } rounded-lg px-3 py-1 sm:float-left float-right sm:mt-0 mt-4`}
        >
          <span
            className={`${
              percentageChange?.type === "increase"
                ? "text-green-500"
                : percentageChange?.type === "decrease"
                ? "text-red-500"
                : "text-blue-900"
            }
             flex items-center py-0.5`}
          >
            {percentageChange?.type === "increase" ? (
              <ArrowUpwardIcon />
            ) : percentageChange?.type === "decrease" ? (
              <ArrowDownwardIcon />
            ) : (
              ""
            )}

            {`${percentageChange?.percentage}%`}
          </span>
        </div>
      </div>
      <div className="mt-2 sm:flex items-center justify-between">
        <div className="">
          <span className="mr-4 ">SC: Service Center</span>
          <span className="block mt-2 sm:inline-block sm:mt-0">
            CS: Connect Shop
          </span>
        </div>
        <div className="flex sm:mt-0 mt-4 items-center">
          <div className="h-3 w-3 bg-blue-500 mr-1.5"></div>
          <span className="mr-4">Today</span>
          <div className="h-3 w-3 bg-gray-400 mr-1.5"></div>{" "}
          <span>Yesterday</span>
          {/* {!["partner"]?.includes(auth?.user?.role) && (  for future use */}
          <div className="ml-2">
            <CsvDownloadButton
              csvHeaders={csvHeaders}
              csvData={csvData}
              filename={`Shops Statistics.csv`}
            />
          </div>
          {/* )} */}
        </div>
      </div>

      <div className="flex-1 mt-4">
        <div className="h-fit overflow-y-auto">
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            loading={dashboard?.shopStatsIsFetching}
            className="table-auto"
            sticky
            scroll={{ y: 400 }}
            summary={(pageData) => {
              let totalRevenues = 0;
              let totalQty = 0;
              let compTRevenue = 0;
              let compTQty = 0;

              pageData.forEach(
                ({ revenue, quantity, comparedRevenue, comparedQuantity }) => {
                  totalRevenues += revenue;
                  totalQty += quantity;
                  compTRevenue += comparedRevenue;
                  compTQty += comparedQuantity;
                }
              );

              return (
                <>
                  <Table.Summary.Row className="!text-[#2E6C8E] bg-white sticky bottom-0">
                    <Table.Summary.Cell index={0} colSpan={2}>
                      <Text style={{ fontWeight: "bolder" }}>Total</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={0}>
                      {layout?.dashboardPreview === "day" && (
                        <Text>{compTRevenue.toLocaleString()}</Text>
                      )}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={0}>
                      <Text style={{ fontWeight: "bolder" }}>
                        {totalRevenues.toLocaleString()}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={0}>
                      {layout?.dashboardPreview === "day" && (
                        <Text>{compTQty.toLocaleString()}</Text>
                      )}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={0}>
                      <Text style={{ fontWeight: "bolder" }}>
                        {totalQty.toLocaleString()}
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Shops;
