import { notification } from "antd";
import { mySubScritionPriceList } from ".";
import {
  createSubscriptionPriceListService,
  getNetPricelistService,
  updateSubscriptionPriceListService,
} from "./services";

export const createSubscriptionPriceListAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubScritionPriceList.setIsFetching(true));
      const res = await createSubscriptionPriceListService(data, token);
      console.log("RES", res);
      if (res?.status === 201) {
        dispatch(mySubScritionPriceList.setNew(res));
        dispatch(mySubScritionPriceList.setIsFetching(false));
      }
      dispatch(mySubScritionPriceList.setIsFetching(false));
      notification.success({ message: "Created Successfully" });
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateSubscriptionPriceListAction = (
  data: any,
  token: string,
  itemId?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubScritionPriceList.setIsFetching(true));
      const res = await updateSubscriptionPriceListService(data, token);
      if (res?.status === 200) {
        dispatch(mySubScritionPriceList.setUpdated(res));
        dispatch(mySubScritionPriceList.setIsFetching(false));
        dispatch(mySubScritionPriceList.setIsFetching(false));
      }
      dispatch(mySubScritionPriceList.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllNetPricelistAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubScritionPriceList.setIsFetching(true));
      const res = await getNetPricelistService(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(mySubScritionPriceList.setNetPricelist(res));
        dispatch(mySubScritionPriceList.setIsFetching(false));
      }
      dispatch(mySubScritionPriceList.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
