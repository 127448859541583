import React, { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Form, Select, Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getAllChannelAction } from "../../store/channel/actions";
import { updateUserActions } from "../../store/setting/actions";

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const AssignChannelForm = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // const [userId, setUserId] = useState(props?.agentId);
  const { channel, auth } = useSelector((state: any) => state);

  useEffect(() => {
    auth?.token && getAllChannelAction(auth?.token)(dispatch);
  }, [auth?.token, dispatch]);

  const onFinish = async (values: any) => {
    auth?.token &&
      props?.userId &&
      (
        await updateUserActions(
          props?.userId,
          { channel: [values?.channel] },
          auth?.token
        )
      )(dispatch);
    form.resetFields();
    props?.onCancel();
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={props?.data || {}}
      scrollToFirstError
    >
      <div className="w-[30rem] pt-4">
        <Row gutter={[16, 16]}>
          <Col span={18}>
            <Form.Item
              name="channel"
              label="Channel"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Channel is required!",
                  whitespace: true,
                },
              ]}
            >
              <Select>
                {channel?.all?.data?.map((el: any) => (
                  <Option value={el?._id} className="capitalize">
                    {el?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...tailFormItemLayout}>
              <LoadingButton
                type="submit"
                variant="contained"
                sx={{ minWidth: "50%" }}
                loading={channel.isFetching}
              >
                Save
              </LoadingButton>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default AssignChannelForm;
