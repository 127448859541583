import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const DetailsTabs = (props: any) => {
  return (
    <Box className="xbg-white w-full rounded-md">
      <Box className="flex justify-center">
        <Tabs
          value={props?.activeTab}
          onChange={props?.handleChange}
          sx={{
            backgroundColor: "#fff",
            borderRadius: 2,
            width: "100%",
          }}
        >
          {props?.tabs?.map((tab: any, index: number) => (
            <Tab
              label={tab?.title}
              sx={{
                textTransform: "none",
                fontSize: 15,
                borderRadius: 1,
                paddingBottom: 3,
                bgcolor: props.activeTab === index ? "#F4F6FA" : "initial",
              }}
            />
          ))}
        </Tabs>
      </Box>
      {props?.tabs?.map((tab: any, index: number) => (
        <TabPanel value={props?.activeTab} index={index}>
          {tab?.component}
        </TabPanel>
      ))}
    </Box>
  );
};
export default DetailsTabs;
