import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import DangerButton from "../../../components/buttons/DangerButton";
import SinglePurchaseOrderTable from "../../../components/tables/SinglePurchaseOrderTable";
import {
  getSinglePurchaseOrderAction,
  sendPurchaseOrderEmailAction,
  updatePurchaseOrderAction,
} from "../../../store/purchase/actions";
import { useDispatch, useSelector } from "react-redux";
import { searchValue } from "../../../utils/setColor";
import { Badge, Space, Spin, Tag } from "antd";
import CircularProgress from "@mui/material/CircularProgress";
import PurchaseOrderDetailsSkeleton from "../../../components/skeleton/PurchaseOrderDetailsSkeleton";
import { Skeleton } from "@mui/material";
import Logo from "../../../assets/icons/dashboard/Samphone_Logo.svg";

const SinglePurchaseOrderDetails = (props: any) => {
  const { auth, purchase, company } = useSelector((state: any) => state);
  const { po_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const purchaseData = purchase?.selected?.data;

  // Conditions
  const isInTransit = ["fully-in-transit", "partial-in-transit"].includes(
    purchaseData?.status?.toLowerCase()
  );

  const isInFinance = ["finance"].includes(purchaseData?.status?.toLowerCase());
  const isCompleted = ["completed"].includes(
    purchaseData?.status?.toLowerCase()
  );
  const isDraft = ["draft"].includes(purchaseData?.status?.toLowerCase());
  const isSent = ["sent"].includes(purchaseData?.status?.toLowerCase());
  const showSkeleton = true;

  React.useEffect(() => {
    auth?.token &&
      getSinglePurchaseOrderAction(auth?.token, po_id as string)(dispatch);
  }, [auth?.token, dispatch, po_id]);
  return (
    <div className="text-[#030229] p-2  h-full overflow-y-auto scrollbar-hide">
      <DetailsHeaderActionBar
        pageName={props?.isSales ? "Purchase Order" : "Finance"}
        title={
          purchase?.isFetching ? (
            <Skeleton animation="wave" width={100} />
          ) : (
            purchase?.selected?.data?.supplier?.name
          )
        }
      />
      {!purchaseData && (
        <div className="p-8 bg-white rounded-lg space-y-6">
          <PurchaseOrderDetailsSkeleton />
        </div>
      )}
      {purchaseData && (
        <Badge.Ribbon
          text={<span
            style={{
              fontSize: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              fontWeight: 400,
              paddingTop: "1.5px",
            }}
          >
            {purchase?.isFetching ? (
              <Spin size="small" />
            ) : (
              purchase?.selected?.data?.status
            )}
          </span>}
          color={purchase?.isFetching ? '#F5F6FA' : searchValue(purchase?.selected?.data?.status)}
          style={{
            width: "11rem",
            padding: "5px 1px",
            marginLeft: "3rem",
            marginTop: "1rem",
            transform: "rotate(20deg)",
            transformOrigin: "top-left",
          }}
        >
          <div className="p-8 px-16 bg-white rounded-lg space-y-6 relative text-[#030229]">
            <div className="mt-6 w-[14rem] h-[5rem]">
              <img src={company?.selected?.logo} alt="samphoneLogo" width={150} />
            </div>
            <div className="flex flex-col gap-[.1rem] text-[.83rem] font-medium">
              <p className="text-sm">{company?.selected?.name?.toUpperCase()}</p>
              <p>
                {company?.selected?.address?.provence?.toUpperCase()} -{" "}
                {company?.selected?.address?.country?.toUpperCase()}
              </p>
              <p>{company?.selected?.address?.city?.toUpperCase()}</p>
              <p>TIN - 112620773</p>
            </div>
            <div className="absolute right-[25rem] top-6 ">
              <h1 className="font-[500] text-[23px]">PURCHASE ORDER</h1>
              <div className="flex flex-col mt-3 gap-[.1rem]">
                <Space>
                  <p className="font-medium text-[.8rem]">PO : </p>
                  <p className="font-normal text-[.8rem]">
                    {purchase?.selected?.data?.pOrderID}
                  </p>
                </Space>
                <Space>
                  <p className="font-medium text-[.8rem]">Date - </p>
                  <p className="font-normal text-[.8rem]">
                    {purchase?.selected?.data?.createdAt?.slice(0, 10)}
                  </p>
                </Space>
                <Space>
                  <p className="font-medium text-[.8rem]">Supplier ID - </p>
                  <p className="font-normal text-[.8rem]">-</p>
                </Space>
                <Space>
                  <p className="font-medium text-[.8rem]">Buyer- </p>
                  <p className="font-normal text-[.8rem] capitalize">
                    {purchase?.selected?.data?.quotation?.createdBy?.names}
                  </p>
                </Space>
              </div>
            </div>
            <div className="flex gap-3 flex-wrap justify-between mt-4">
              <div>
                <div className="w-[550px] h-[3.5rem] bg-[#F5F6FA] font-semibold pt-4 pl-4 text-[16px] rounded-sm mb-4">
                  Vender
                </div>
                <div className="flex flex-col gap-[.1rem] text-[.83rem] font-medium">
                  <p className="text-sm">{purchase?.selected?.data?.supplier?.name?.toUpperCase()}</p>
                  <p>
                    {purchase?.selected?.data?.supplier?.address?.toUpperCase() || '-'}
                  </p>
                  <p>TIN/VAT Reg : {purchase?.selected?.data?.supplier?.tin}</p>
                  <p> Email : {purchase?.selected?.data?.supplier?.email}</p>
                  <p> Tel : {purchase?.selected?.data?.supplier?.phone}</p>
                </div>
              </div>
              <div>
                <div className="w-[550px] h-[3.5rem] bg-[#F5F6FA] font-semibold pt-4 pl-4 text-[16px] rounded-sm mb-4">
                  Ship To
                </div>
                <div className="flex flex-col gap-[.1rem] text-[.83rem] font-medium">
                  <p className="text-sm">{company?.selected?.name?.toUpperCase()}</p>
                  <p>
                    {company?.selected?.address?.provence?.toUpperCase()} -{" "}
                    {company?.selected?.address?.country?.toUpperCase()}
                  </p>
                  <p>{company?.selected?.address?.city?.toUpperCase()}</p>
                  <p>TIN - {company?.selected?.tin || 'N/A'}</p>
                </div>
              </div>
            </div>

            <div className="flex justify-between font-semibold text-lg space-y-2">
            </div>
            <div className="flex flex-col lg:flex-row justify-start lg:justify-between lg:items-center pt-5">
              <div className="grid grid-cols-2 gap-x-10">
                <p className="font-bold">Delivery Deadline</p>
                <p>{purchase?.selected?.data?.deliveryDate ?? " "}</p>
              </div>
            </div>
            {purchase.isFetching && (
              <div className="flex justify-center h-fit">
                <CircularProgress />
              </div>
            )}
            {!purchase.isFetching && (
              <div>
                <SinglePurchaseOrderTable />
                {purchase?.selected?.data.status !== "Cancelled" && (
                  <div className="space-x-2 mt-4">
                    <PrimaryButton
                      name="Print"
                      onClick={() => console.log("Printing...")} />
                    {purchase?.selected?.data.status === "Fully-In-Transit" && (
                      <PrimaryButton
                        name="Completed"
                        onClick={async () => {
                          if (auth?.token) {
                            const res = await updatePurchaseOrderAction(
                              auth?.token,
                              po_id as string,
                              {
                                status: "Completed",
                              }
                            )(dispatch);
                            res && navigate("/purchase/po");
                          }
                        }}
                        isLoading={purchase.isFetching} />
                    )}
                    {["sent", "partial-in-transit"].includes(
                      purchase?.selected?.data?.status?.toLowerCase()
                    ) && (
                        <Link
                          to={`/delivery/note/${purchase?.selected?.data?._id}`}
                          target="_blank"
                        >
                          <PrimaryButton
                            // onClick={async () => ()}
                            name="Manual Reply " />
                        </Link>
                      )}
                    {props?.isFinance && !isInTransit && (
                      <PrimaryButton
                        name={`${purchaseData?.status?.toLowerCase() === "sent"
                          ? "Re-send"
                          : "Send"} by Email`}
                        onClick={async () => {
                          if (auth?.token) {
                            const res = await sendPurchaseOrderEmailAction(
                              auth?.token,
                              {
                                purchaseOrderID: po_id,
                                isNotify: true,
                                notification: {
                                  action: "PO Sent to Supplier",
                                  role: ["admin", "finance", "finance-manager"],
                                  message: `PO With ID: ${purchase?.selected?.data?.pOrderID} has been Sent to ${purchase?.selected?.data?.supplier?.name}`,
                                  title: `Purchase Order Sent to ${purchase?.selected?.data?.supplier?.name}`,
                                },
                              }
                            )(dispatch);

                            res && navigate("/finance/po");
                          }
                        }}
                        isLoading={purchase.isFetching} />
                    )}

                    {props?.isSales &&
                      !isInFinance &&
                      !isInTransit &&
                      !isCompleted &&
                      !isSent && (
                        <PrimaryButton
                          name="Request Finance Approval"
                          onClick={() => {
                            auth?.token &&
                              updatePurchaseOrderAction(
                                auth?.token,
                                po_id as string,
                                {
                                  status: "Finance",
                                  isNotify: true,
                                  notification: {
                                    action: "PO Waiting for approval",
                                    role: ["admin", "finance", "finance-manager"],
                                    message: `Purchase Order ${purchase?.selected?.data?.pOrderID} is Waiting For Approval`,
                                    title: "Purchase Order",
                                  },
                                }
                              )(dispatch);
                          }}
                          isLoading={purchase?.isFetching} />
                      )}
                    {(!isInTransit || isInFinance || isDraft) &&
                      !isSent &&
                      !isCompleted && (
                        <DangerButton
                          name="Cancel"
                          onClick={async () => {
                            if (auth?.token) {
                              const res = await updatePurchaseOrderAction(
                                auth?.token,
                                po_id as string,
                                {
                                  status: "Cancelled",
                                }
                              )(dispatch);
                              res && navigate("/purchase/po");
                            }
                          }}
                          isLoading={purchase.isFetching} />
                      )}
                  </div>
                )}
              </div>
            )}
          </div>
        </Badge.Ribbon>
      )
      }
    </div >
  );
};

export default SinglePurchaseOrderDetails;
