import React from "react";
import { Stack } from "@mui/material";
import { Divider, Tag } from "antd";
import { Table } from "antd";
import { Mycolumns } from "../../../../assets/data/data";
import { searchValue } from "../../../../utils/setColor";
import { useSelector } from "react-redux";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import { getDefaultCurrencyCode } from "../../../../utils/helper";

const Invoice = (props: any) => {
  const { company, order } = useSelector((state: any) => state);

  const [_page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(5);

  const productData = order?.selected?.list?.map((el: any) => {
    return {
      key: el._id,
      brand: el?.product?.product?.brand,
      model: el?.product?.product?.model,
      specs: el?.product?.specification
        ?.slice(2, 6)
        ?.map((d: any) => d[1] && `${d[0]}:${d[1]}`)
        ?.join(", "),
      quantity: el?.quantity,
      unitPrice: el?.amount,
      amountPaid: (Number(el?.amount) * Number(el?.quantity)).toLocaleString(),
    };
  });

  return (
    <div className="text-black h-screen px-10">
      <Stack
        direction="row"
        alignItems={"start"}
        justifyContent="space-between"
      >
        {" "}
        <div className="text-xl font-semibold">
          <h2>INVOICE</h2>{" "}
        </div>
        <Tag
          color={searchValue(props?.dataToDisplay?.data?.status)}
          className="capitalize p-2 px-3 text-base"
        >
          {props?.dataToDisplay?.data?.status ?? props?.dataToDisplay?.status}
        </Tag>
        <div className="flex flex-col float-right mb-4 w-[15rem] text-[13px]">
          <div className="flex justify-between">
            <p className="font-medium text-[#000] ">Invoice Number</p>
            <p className="font-light  text-gray-500 ">
              {props?.dataToDisplay?.bill ?? props?.dataToDisplay?.transaction}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="font-medium text-[#000]">Invoice Date</p>
            <p className="font-light text-gray-500">
              {props?.dataToDisplay?.data?.date?.split("T")[0] ??
                props?.dataToDisplay?.date?.split("T")[0]}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="font-medium  text-[#000]">Due Date</p>
            <p className="font-light text-gray-500">
              {props?.dataToDisplay?.data?.dueDate?.split("T")[0] ??
                props?.dataToDisplay?.dueDate?.split("T")[0]}
            </p>
          </div>
        </div>
      </Stack>
      <Divider />
      <div className="flex flex-row justify-between pt-2">
        <div className="w-[16rem]">
          <h2 className="text-base font-semibold mb-4">BILLING INFORMATION</h2>
          <div className="flex flex-col gap-3">
            <p className="text-sm font-medium">
              {" "}
              Company : {company?.selected?.name}
            </p>
            <p className="text-sm font-medium">
              Address :{" "}
              {`${company?.selected?.address?.provence}, ${company?.selected?.address?.city}, ${company?.selected?.address?.country}`}
            </p>
            <p className="text-sm font-medium">
              Email : {company?.selected?.email}
            </p>
            <p className="text-sm font-medium">
              Contact : {company?.selected?.phone}
            </p>
          </div>
        </div>

        <div className="w-[16rem] ">
          <h2 className="text-base font-semibold mb-4 float-right mr-4">
            SHIPPING INFORMATION
          </h2>
          <div className="flex flex-col gap-4 float-right">
            <p className="text-sm font-medium">
              {(props?.dataToDisplay?.data?.account?.type ||
                props?.dataToDisplay?.account?.type) === "b2c"
                ? "Individual"
                : "Company"}{" "}
              :{" "}
              {props?.dataToDisplay?.data?.account?.customerDetails?.name ??
                props?.dataToDisplay?.account?.customerDetails?.name}
            </p>
            <p className="text-sm font-medium">
              Address :{" "}
              {props?.dataToDisplay?.data?.account?.customerDetails?.country ??
                props?.dataToDisplay?.account?.customerDetails?.country}{" "}
              -{" "}
              {props?.dataToDisplay?.data?.account?.customerDetails?.district ??
                props?.dataToDisplay?.account?.customerDetails?.district}
            </p>
            <p className="text-sm font-medium">
              Email :{" "}
              {props?.dataToDisplay?.data?.account?.customerDetails?.email ??
                props?.dataToDisplay?.account?.customerDetails?.email}
            </p>

            <p className="text-sm font-medium">
              Contact : +{" "}
              {props?.dataToDisplay?.data?.account?.customerDetails?.tel ??
                props?.dataToDisplay?.account?.customerDetails?.tel}
            </p>
          </div>
        </div>
      </div>
      <Divider dashed />

      <div className="text-base font-semibold mb-1">
        <h2>Products</h2>{" "}
      </div>

      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={order?.selected?.list?.length}
        count={Math.ceil(order?.selected?.list?.length / limit)}
      >
        <div className=" w-full">
          <Table
            columns={Mycolumns}
            dataSource={productData}
            pagination={false}
            loading={order?.isFetching}
          />
        </div>
      </ScrollableFrame>
      <div className="flex flex-col mt-5 mb-2  float-right gap-2 w-[14rem]">
        <div className="flex  justify-between">
          <p className="text-[.9rem] font-light">Subtotal</p>
          <h2 className="text-[.9rem] font-medium ">
            {`${
              props?.dataToDisplay?.value?.toLocaleString() ??
              props?.dataToDisplay?.amountPaid?.toLocaleString()
            } ${getDefaultCurrencyCode(company)}`}
          </h2>
        </div>
        <div className="flex justify-between">
          <p className="text-[.9rem] font-light">Taxes</p>
          <h2 className="text-[.9rem] font-medium ">0%</h2>
        </div>
        <div className="flex mt-2 justify-between ">
          <p className="text-base font-medium">Total Amount Due</p>
          <h2 className="text-base font-medium ">
            {`${
              props?.dataToDisplay?.value?.toLocaleString() ??
              props?.dataToDisplay?.amountPaid?.toLocaleString()
            } ${getDefaultCurrencyCode(company)}`}
          </h2>
        </div>
      </div>
      <Divider dashed />
      <div className="flex gap-3 pb-3">
        <h2 className="text-base min-w-36">Additional Notes</h2>
        <p className="text-gray-500 text-sm">
          {props?.dataToDisplay?.data?.comments ?? "No Additional Notes"}
        </p>
      </div>
    </div>
  );
};
export default Invoice;
