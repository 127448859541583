import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const createCompanyService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/settings/company`, data, token);
};

export const getAllCompanyService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/settings/company${query}`, token);
};

export const getOneCompanyService = async (itemId: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/settings/company/one/${itemId}`, token);
};
export const campanyResizedLogoService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/product/upload`, data, token);
};
export const updateCompanyService = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/settings/company/one/${itemId}`,
    data,
    token
  );
};
export const createBusinesService = async (data: any, token: string) => {
  return await HttpRequest.post(
    `${SERVER_URL}/settings/businessProduct`,
    data,
    token
  );
};
export const getAllBusinesService = async (token: string, query?: string) => {
  return HttpRequest.get(
    `${SERVER_URL}/settings/businessProduct${query}`,
    token
  );
};
export const getOneBusinesService = async (itemId: string, token: any) => {
  return HttpRequest.get(
    `${SERVER_URL}/settings/businessProduct/one/${itemId}`,
    token
  );
};
export const updateBusinesService = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/settings/businessProduct/one/${itemId}`,
    data,
    token
  );
};
export const addBussinessProductToChannelService = async (
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/settings/businessProduct/addToChannel`,
    data,
    token
  );
};
export const removerBussinessProductToChannelService = async (
  data: string,
  token: string
) => {
  // return await HttpRequest.delete(`${SERVER_URL}/agent/one/${itemId}`, token);
  return await HttpRequest.delete(
    `${SERVER_URL}/settings/businessProduct/addToChannel`,
    token,
    data
  );
};
export const deleteBusinesService = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/settings/businessProduct/one/${itemId}`,
    data,
    token
  );
};

export const createTrackingService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/trType`, data, token);
};

export const getTrackingTypeService = async (token: string) => {
  return HttpRequest.get(`${SERVER_URL}/trType`, token);
};

export const updateTrackingTypeService = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/trType/one/${itemId}`,
    data,
    token
  );
};

export const getOnetrackingTypeService = async (
  itemId: string,
  token: string
) => {
  return HttpRequest.get(
    `${SERVER_URL}/settings/businessProduct/one/${itemId}`,
    token
  );
};

export const CustomFormService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/customerForm`, data, token);
};

export const updateCustomFormService = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/customerForm/one/${itemId}`,
    data,
    token
  );
};

export const getCustomFormService = async (query: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/customerForm${query}`, token);
};

export const getOneCustomFormService = async (
  itemId: string,
  token: string
) => {
  return HttpRequest.get(`${SERVER_URL}/customerForm/one/${itemId}`, token);
};
