import React, { useEffect, useState } from "react";
import { Steps, Skeleton, List, Avatar, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllTrackActivitiesAction,
  getOnePackageTrackAction,
  getPackageTrackIdAction,
} from "../../../../store/delivery/actions";
import PendingDeliveries from "../../../../components/cards/pendingDeliveries";
import MapSection from "../map/MapSection";
import { IoMdTime } from "react-icons/io";
import { MdOutlineSocialDistance } from "react-icons/md";
import StepsBar from "./StepsBar";
import ItemList from "./ItemList";
import ShippingCard from "../../../../components/cards/ShippingCard";
import MTNRwandacellHeader from "../../../../components/cards/MTNRwandacellHeader";
import { getOneShopStockAction } from "../../../../store/pos/actions";
import ReviewSummary from "./ReviewSummary";
import NoDeliveryOrderSelected from "./NoDeliveryOrderSelected";
import FrameIcon1 from "../assets/Frame 27.png";
import ParkingDetailsCard from "./ParkageDetailsCard";
import { useParams } from "react-router-dom";

const Track = () => {
  const { auth, tracking, pos } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [trackingId, setTrackingId] = useState<any>();
  const [hasValidLocations, setHasValidLocations] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [isFromMe, setIsFromMe] = useState<any>(true);
  const [showNoTracking, setShowNoTracking] = useState(true);
const {ParId}=useParams();
  const handleTrackChange = async (id: any) => {
    setIsLoading(true);
    setTrackingId(id);
    setHasValidLocations(true);

    if (auth.token) {
      getPackageTrackIdAction(
        auth.token,
        `?packageId=${id}`
      )(dispatch)
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (auth.token) {
      getAllTrackActivitiesAction(auth.token)(dispatch);
    }
  }, [auth.token, dispatch]);

  useEffect(() => {
    if (tracking.all?.data) {
      setIsLoading(false);
    }
  }, [tracking.all?.data]);

  const trackingHistory = tracking?.all?.data;

  const totalDistance =
    trackingHistory?.reduce((acc: number, item: any) => {
      const distanceValue = parseFloat(
        item?.distance?.replace("km", "").trim()
      );
      return acc + (isNaN(distanceValue) ? 0 : distanceValue);
    }, 0) || 0;

  const trackinggg = tracking?.all?.data?.filter(
    (item: any) => item?.packageId === trackingId
  );

  const stepsData = trackinggg[0]?.trackingType?.steps || [];

  console.log(trackinggg[0]?.trackingType?.steps);

  useEffect(() => {
    if (stepsData.length > 0) {
      const currentStepIndex = stepsData.findIndex(
        (step: any) => step.status === "error"
      );
      setCurrentStep(currentStepIndex !== -1 ? currentStepIndex : 0);
    }
  }, [stepsData]);

  const trackingActivityForOneistory = tracking?.trActivities?.data.filter(
    (item: any) => item?.trackId?.packageId === trackingId
  );
  const filterTrackingId = tracking?.all?.data.filter(
    (item: any) => item?.packageId === trackingId
  );

  const handleSelectTrackingId = (selectedId: string) => {
    setTrackingId(selectedId);
    setHasValidLocations(true);
    setShowNoTracking(false);
  };

  const fullLink = filterTrackingId[0]?.deliveryNote?.getLink;
  const id = fullLink?.split("/").pop();

  useEffect(() => {
    if (auth.token && id) {
      getOneShopStockAction(auth.token, id)(dispatch);
    }
  }, [auth.token, id, dispatch]);

  console.log("display id from params here",ParId)
  const packageDetails = tracking?.trActivities?.data.filter(
    (item: any) => item?.trackId?.packageId === trackingId
  );

  const data = tracking?.trActivities?.data?.filter(
    (item: any) => item?.trackId?.packageId === trackingId
  );
  return (
    <div className="px-4 text-black flex flex-col md:flex-row h-screen">
      <div className="w-full md:w-1/3 p-4">
        <PendingDeliveries
          onSelectDelivery={handleTrackChange}
          onSelectTrackingId={handleSelectTrackingId}
          isFromMe={isFromMe}
          setIsFromMe={setIsFromMe}
        />
      </div>

      {showNoTracking ? (
        <div className="w-full md:w-2/3 p-4 h-full">
          <NoDeliveryOrderSelected />
        </div>
      ) : (
        <div className="w-full md:w-2/3 p-4 h-full">
          <div>
            <MTNRwandacellHeader
              trackingActivityForOneistory={trackingActivityForOneistory}
              totalDistance={totalDistance}
              filterTrackingId={filterTrackingId}
              isFromMe={isFromMe}
            />
          </div>
          <div className="h-auto">
            <div className="flex flex-row justify-between w-full pt-1 rounded-md bg-white border-solid border-2 border-gray-400/10">
              <div className="w-[35%]">
                <div className="p-3">
                  <span className="text-gray-400">ORDER ID: </span>
                  <span>{pos?.shopStock?.data?.stockRequest?.requestId}</span>
                </div>
                <div className="flex flex-row items-center justify-between p-3">
                  <div className="flex flex-col align-center gap-2">
                    <div className="mt-4 text-bse w-full text-[#030229]">
                      Est. Delivery Time
                    </div>
                    <span className="flex flex-row items-center gap-2">
                      <IoMdTime className="text-[#605BFF]" />
                      <p className="text-[#030229B2] font-medium text-[12px]">N/A</p>
                    </span>
                  </div>
                  <div className="flex flex-col align-center gap-2">
                    {tracking.isFetching ? (
                      <Skeleton active paragraph={{ rows: 3 }} />
                    ) : (
                      <>
                        <span className="mt-4 text-base text-[#030229]">
                          Total Distance
                        </span>
                        <span className="flex flex-row items-center gap-2">
                          <MdOutlineSocialDistance className="text-[#605BFF]" />
                          <p className="text-[#030229B2] font-medium text-[12px]">{totalDistance.toFixed(2)} km</p>
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="p-4 flex flex-col align-enter mt-4 w-[65%]">
                {tracking.isFetching ? (
                  <Skeleton active paragraph={{ rows: 3 }} />
                ) : (
                  <StepsBar
                    stepsData={stepsData}
                    currentStep={`${trackinggg[0]?.trackingStatus}`}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-5 mt-5">
              <div className="flex-1">
                <ReviewSummary
                  trackingActivityForOneistory={trackingActivityForOneistory}
                />
              </div>
              <div className="flex-1">
                <ItemList shopStockId={id} />
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-between h-[450px] mt-5 gap-3">
              <div className="flex-1 border-solid border-2 border-gray-400/10 p-3 h-auto">
                <h3 className="text-[#030229] text-lg pb-4">Package Details</h3>

                {tracking.isFetching ? (
                  <div className="space-y-4">
                    <Skeleton active paragraph={{ rows: 3 }} />
                    <Skeleton active paragraph={{ rows: 2 }} />
                  </div>
                ) : trackingActivityForOneistory?.length ? (
                  <div className="h-[400px] foverflow-y-auto pt-3 pb-3">
                    <div>
                      <ShippingCard
                        trackingActivityForOneistory={
                          trackingActivityForOneistory
                        }
                      />
                    </div>
                    {data?.length > 0 && (
                      <ParkingDetailsCard notes={data} />
                    )}
                  </div>
                ) : (
                  <p className="pt-10 text-gray-400">Package not found</p>
                )}
              </div>
              <div className="flex-1 h-auto">
                {tracking.isFetching ? (
                  <div className="space-y-4">
                    <Skeleton active paragraph={{ rows: 3 }} />
                    <Skeleton active paragraph={{ rows: 2 }} />
                  </div>
                ) : (
                  <MapSection
                    trackingId={trackingId}
                    hasValidLocations={hasValidLocations}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Track;
