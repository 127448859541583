import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Form, Input } from "antd";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector, useDispatch } from "react-redux";
import UploadComponent from "./Upload";
import {
  createCustomerAction,
  getOneCustomerAction,
  updateCustomerAction,
} from "../../store/customer/actions";

const App = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { customer, auth } = useSelector((state: any) => state);
  const [pictures, setPictures] = useState([]);
  const isUpdate = props?.action === "update";

  useEffect(() => {
    form.setFieldsValue({
      firstName: props?.dataToUpdate?.name?.split(" ")[0],
      lastName: props?.dataToUpdate?.name?.split(" ")[2],
      address: props?.dataToUpdate?.address,
      tinNumber: props?.dataToUpdate?.tinNumber,
      businessName: props?.dataToUpdate?.businessName,
    });
  }, [props?.dataToUpdate]);

  const onFinish = async (values: any) => {
    const images = pictures.map((pic: any) => pic?.response?.data?.secure_url);
    const name = `${values?.firstName}  ${values?.lastName}`;
    delete values.firstName;
    delete values.lastName;

    if (isUpdate) {
      auth?.token &&
        (await updateCustomerAction(auth?.token, props?.dataToUpdate?._id, {
          ...values,
          name,
          picture: images[0],
        })(dispatch));
      auth?.token &&
        props?.customerId &&
        getOneCustomerAction(auth?.token, props?.customerId)(dispatch);
    } else {
      auth?.token &&
        createCustomerAction(auth?.token, {
          ...values,
          name,
          picture: images[0],
        })(dispatch);
    }
    props?.onCancel();
    form.resetFields();
  };

  return (
    <Form
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={props?.dataToUpdate}
      style={{ maxWidth: "100%" }}
      scrollToFirstError
      layout="vertical"
    >
      <div className="flex justify-center items-center my-4">
        <UploadComponent
          setPictures={setPictures}
          limit={1}
          default={
            (props?.data || props?.dataToUpdate) && [
              {
                status: "done",
                url: props?.data?.picture ?? props?.dataToUpdate?.picture,
              },
            ]
          }
        />
      </div>
      <div className="grid grid-cols-2 gap-4 w-full px-6">
        <Form.Item
          name="firstName"
          label="First Name"
          tooltip="Please enter customer's first name"
          initialValue={props?.dataToUpdate?.firstName}
          rules={[
            {
              required: true,
              message: "First Name is required!",
              whitespace: true,
            },
          ]}
          getValueFromEvent={(e) =>
            e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
          }
        >
          <Input className="h-12" />
        </Form.Item>

        <Form.Item
          name="lastName"
          label="Last Name"
          tooltip="Please enter customer's last name"
          initialValue={props?.dataToUpdate?.lastName}
          rules={[
            {
              required: true,
              message: "Last Name is required!",
              whitespace: true,
            },
          ]}
          getValueFromEvent={(e) =>
            e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
          }
        >
          <Input className="h-12" />
        </Form.Item>
        <Form.Item
          name="address"
          label="Address"
          initialValue={props?.dataToUpdate?.email}
          rules={[
            {
              required: true,
              message: "Address is required!",
            },
          ]}
        >
          <Input className="h-12" />
        </Form.Item>

        <Form.Item
          name="businessName"
          label="Business Name"
          tooltip="Please enter customer names?"
          initialValue={props?.dataToUpdate?.businessName}
        >
          <Input className="h-12" />
        </Form.Item>

        <Form.Item
          name="tinNumber"
          label="TIN Number"
          tooltip="Please enter TIN number"
          initialValue={props?.dataToUpdate?.tinNumber}
        >
          <Input className="h-12" />
        </Form.Item>

        <Form.Item
          name="nid"
          label="National ID"
          tooltip="Please enter NID?"
          initialValue={props?.dataToUpdate?.nid}
          rules={[
            {
              required: true,
              message: "NID is required!",
              whitespace: true,
            },
          ]}
        >
          <Input className="h-12" />
        </Form.Item>

        <Form.Item
          label="Telephone"
          name="phone"
          initialValue={props?.dataToUpdate?.phone}
          rules={[
            {
              required: true,
              message: "Phone Number is required!",
              max: 18,
            },
            // {
            //   //this phone number is rwandan
            //   pattern:
            //     /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{6}$/im,

            //   message: "Phone Number is required!",
            // },
          ]}
          hasFeedback
        >
          <PhoneInput
            country={"bw"}
            inputStyle={{
              width: "100%",
              height: "50px",
            }}
            containerClass="phone-container"
            placeholder="+250 780 000 000"
          />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          tooltip="Please enter customer email?"
          initialValue={props?.dataToUpdate?.email}
          rules={[
            {
              required: true,
              message: "Email is required!",
            },
          ]}
        >
          <Input className="h-12" />
        </Form.Item>
      </div>
      <div className="flex items-center justify-center">
        <Form.Item>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ height: "40px" }}
            loading={customer?.isFetching}
            className="w-64"
          >
            {isUpdate ? "Update" : "Save"}
          </LoadingButton>
        </Form.Item>
      </div>
    </Form>
  );
};

export default App;
