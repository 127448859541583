import React, { useEffect, useState } from "react";
import SettingButton from "../SettingButton";
import { HiOutlinePlus } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import BusinessProductModal from "../../../../components/Modals/NewBussiProductModal";
import NewBusinessProductForm from "../../../../components/forms/AddBusinessForm";
import { ReactComponent as DeleteSvg } from "../../../../assets/icons/deleteicon.svg";
import { ReactComponent as EditSvg } from "../../../../assets/icons/editicon.svg";
import DeleteModal from "../../../../components/Modals/DeleteModal";
import {
  deleteBusinessActions,
  getAllBusinessAction,
  getOneCompanyAction,
} from "../../../../store/setting/company/actions";
import { Card, Skeleton } from "antd";
import { useNavigate } from "react-router-dom";
import { COMPANY_PROFILE_ID } from "../../../../utils/constants";

const AllBusinessProducts = () => {
  const { auth, layout, company } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [businessToDelete, seBusinessToDelete] = useState<any>("");
  const [businessToUpdate, seBusinessToUpdate] = useState<any>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const companyId = COMPANY_PROFILE_ID;

  useEffect(() => {
    auth?.token && getAllBusinessAction(auth?.token, "?")(dispatch);
  }, [auth.token, dispatch]);

  useEffect(() => {
    auth?.token &&
      getOneCompanyAction(companyId as string, auth.token)(dispatch);
  }, [auth.token, dispatch, companyId]);

  const handleOpenProductModal = (values: any) => {
    setIsModalOpen(true);
    seBusinessToUpdate(values);
  };
  const handleCloseProductModal = () => {
    setIsModalOpen(false);
  };
  const handleClickDeleteModal = (values: any) => {
    setOpenDeleteModal(true);
    seBusinessToDelete(values);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const deleteBusinessProduct = async () => {
    auth?.token &&
      (await deleteBusinessActions(
        businessToDelete,
        {
          isDeleted: "true",
        },
        auth?.token
      )(dispatch));
    setOpenDeleteModal(false);
  };
  const BusinessProduct = company?.allBusiness?.data;

  return (
    <div>
      <div className="bg-white mt-6 rounded-md text-[#0F0F47]">
        <div className="flex justify-between mb-6">
          <h1 className="text-lg font-[600] py-4 px-2"> Business Products</h1>
          <div className=" my-4 mr-6 float-right">
            <SettingButton
              btnName="New Business Product"
              btnBgColor="[#605BFF]"
              textColor="white"
              icon={<HiOutlinePlus size={20} />}
              onClick={handleOpenProductModal}
            />
          </div>
        </div>
        <div
          className={`flex flex-wrap ${
            layout?.isSideNavOpen ? "gap-3" : "gap-8"
          }`}
        >
          {company?.isFetching ? (
            <>
              {" "}
              {[1, 2, 3, 4]?.map((el: any) => (
                <Card style={{ width: 380, marginTop: 16 }}>
                  <Skeleton loading={loading} avatar active></Skeleton>
                </Card>
              ))}
            </>
          ) : (
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-3 mr-3">
              {BusinessProduct?.map((data: any, _index: any) => (
                <div className="h-[11rem]  border border-[#030229] border-opacity-10 rounded-[10px] p-3 relative overflow-x-auto">
                  <h1
                    className="text-[16px] font-medium cursor-pointer"
                    onClick={() =>
                      navigate(`/setting/bunsiness-product/${data?._id}`)
                    }
                  >
                    {data?.name}
                  </h1>
                  <div className="flex gap-4 absolute top-4 right-6">
                    <EditSvg
                      onClick={() => handleOpenProductModal(data)}
                      className="cursor-pointer"
                    />
                    <DeleteSvg
                      onClick={() => handleClickDeleteModal(data?._id)}
                      className="cursor-pointer"
                    />
                  </div>
                  <div className=" mt-4 cursor-pointer">
                    <p
                      className="text-[#0F0F4780] font-normal text-[14px]"
                      onClick={() =>
                        navigate(`/setting/bunsiness-product/${data?._id}`)
                      }
                    >
                      {data?.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <BusinessProductModal
        cancel={handleCloseProductModal}
        isModalOpen={isModalOpen}
        content={
          <NewBusinessProductForm
            businessToUpdate={businessToUpdate}
            onCancel={handleCloseProductModal}
          />
        }
      />
      <DeleteModal
        visible={openDeleteModal}
        onOk={deleteBusinessProduct}
        isLoading={company?.isFetching}
        onCancel={handleCloseDeleteModal}
        itemName="Business product"
      />
    </div>
  );
};
export default AllBusinessProducts;
