import { LoadingOutlined } from '@ant-design/icons';
import { Spin, notification } from 'antd';
import React from 'react';

import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";

import styles from "../styles/Home.module.css";
import { ReactComponent as ForceLogo } from "../assets/icons/dashboard/Samphone_Logo.svg";
import { Button, Checkbox, Form, Input } from 'antd';
import PrimaryButton from "../components/buttons/PrimaryButton";
import { useNavigate, useParams } from "react-router-dom";
import {createResetPasswordLinkAction } from "../store/auth/authActions";


export default function App() {
  const { auth } = useSelector((state: any) => state);
  const dispatch=useDispatch()
 
  const [form] = Form.useForm();
  const onFinish = async (values: any) => {
      (await createResetPasswordLinkAction( { ...values})(
        dispatch
      ));
     notification.success({ message: "Email Sent Check your Inbox" });
    form.resetFields();
  };

  const navigate=useNavigate();
  const styles ={
    shadow:{
      boxShadow: "1px 4px 12px 0px rgba(0, 0, 0, 0.05)"
    }
  }
  
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    
    <div className="w-full bg-white ">
        <div className="w-[35rem] h-fit m-auto  mt-20  p-5 ">
        <ForceLogo className="w-36 text-sm  absolute top-10 ml-44"/>
        <div className="mt-10 w-full">
        <p className="text-[#03022980] text-center">Reset your Password</p>
        <div className="w-full bosrder bosrder-[#0F0F4740] p-12 mt-5 rounded-md bg-white" style={styles.shadow}>
          <h1 className="text-[#030229CC] text-[13px]">Enter your user account's verified email address and we will send you a password reset link.</h1>
        <Form
        form={form}
        name="validateOnly"
        layout="vertical"
        autoComplete="off"
        style={{ maxWidth: "100%" , marginTop:"20px"}}
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          label={
            <span className="text-[#030229] pl-1 text-sm font-normal">
              Email
            </span>
          }
          rules={[
            { required: true, message: "Please email is required" },
            {
              pattern:/^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message:
                "Please input valid email.",
            },
          ]}
          className="w-full"
        >
          <Input className="pl-4 h-[42px] w-[100%] text-base"  />
       </Form.Item>
       
       <a
                href="/login"
                className="text-[#030229CC] text-sm"
              >
                Remember Password?? Login.
              </a>
              <Form.Item>
              <button type="submit" className="bg-[#605BFF] text-sm rounded-md mt-5 text-center w-full p-3 text-white"> {auth?.isFetching? <Spin indicator={antIcon} />:"Send Reset password email"} </button>
              </Form.Item>
   
      </Form> 
          </div>
          </div>
        </div>
        </div>
  );
}
