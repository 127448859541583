import { notification } from "antd";
import { myShopActions } from ".";
import {
  getOneShop,
  createShopRequest,
  updateQntyApprovedRequest,
  deleteRequestStockItem,
  updateAdjustStockService,
  revertRequestStockItem,
  shopEligibleToproductService,
  getShopsStatService,
  getShopOverview,
} from "./services";
import { error } from "console";

export const getOneShopAction = (
  itemId: string,
  token: string,
  isMyShop?: boolean
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myShopActions.setIsFetching(true));
      const res = await getOneShop(itemId, token);
      if (res?.status === 200) {
        dispatch(myShopActions.setSelected(res?.data));
        isMyShop && dispatch(myShopActions.setMyShop(res?.data));
        dispatch(myShopActions.setIsFetching(false));
        return true;
      }
      dispatch(myShopActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const createRequestStockAction = (
  token: string,
  data: {},
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myShopActions.setIsFetching(true));
      const res = await createShopRequest(data, token);

      if (res?.status === 201) {
        dispatch(myShopActions.setSelectedRequestedStock(res?.data));
        dispatch(myShopActions.setIsFetching(false));
        return true;
      }
      dispatch(myShopActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const quantityApprovedAction = (
  id: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myShopActions.setIsFetching(true));
      const res = await updateQntyApprovedRequest(id, data, token);
      if (res?.status === 200) {
        dispatch(myShopActions.setSelectedRequestedStock(res?.data));
        dispatch(myShopActions.setIsFetching(false));
        return true;
      }
      dispatch(myShopActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteStockRequestItemAction = (id: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myShopActions.setIsFetching(true));
      const res = await deleteRequestStockItem(id, token);
      if (res?.status === 200) {
        dispatch(myShopActions.setSelectedRequestedStock(res?.data));
        dispatch(myShopActions.setIsFetching(false));
        return true;
      }
      dispatch(myShopActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const adjustStockAction = (
  // id: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myShopActions.setIsFetching(true));
      const res = await updateAdjustStockService(data, token);
      console.log(">>>>>>>:: update>>", res);
      if (res?.status === 200) {
        dispatch(myShopActions.setAdjustStock(res));
        dispatch(myShopActions.setIsFetching(false));
        return true;
      }
      dispatch(myShopActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const revertStockAction = (itemId: string, data: any, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myShopActions.setIsFetching(true));
      const res = await revertRequestStockItem(itemId, data, token);
      console.log(">>>>>>>:: update>>", res);
      if (res?.status === 200) {
        dispatch(myShopActions.setIsFetching(false));
        return true;
      }
      dispatch(myShopActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const shopTransferFromAction = (
  id: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myShopActions.setIsFetching(true));
      const res = await updateQntyApprovedRequest(id, data, token);
      if (res?.status === 200) {
        dispatch(myShopActions.setShopTransferFrom(res?.data));
        dispatch(myShopActions.setIsFetching(false));
        return true;
      }
      dispatch(myShopActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllshopEligibleToproductAction = (token: any, id: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myShopActions.setIsFetching(true));
      const res = await shopEligibleToproductService(token, id);
      if (res?.status === 200) {
        dispatch(myShopActions.setShopEligible(res));
        dispatch(myShopActions.setIsFetching(false));
        return true;
      }
      if (res?.response?.status === 400) {
        dispatch(myShopActions.setIsFetching(false));
        notification.error({
          message: "Error",
          description: res?.response?.data?.error,
        });
        return false;
      }
      // dispatch(myShopActions.setIsFetching(false));
      // return false;
    } catch (err) {
      console.log(err);
      notification.error({
        message: "Error",
        description: "An error occurred. Please try again later.",
      });
    }
  };
};

export const getShopsStatAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myShopActions.setIsFetching(true));
      const res = await getShopsStatService(token, query);
      if (res?.status === 200) {
        dispatch(myShopActions.setShopStat(res));
        dispatch(myShopActions.setIsFetching(false));
      }
      dispatch(myShopActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getShopOverviewAction = (
  itemId: string,
  token: string,
  isMyShop?: boolean
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myShopActions.setIsOverviewFetching(true));
      const res = await getShopOverview(itemId, token);
      if (res?.status === 200) {
        dispatch(myShopActions.setShopOverview(res?.data));
        dispatch(myShopActions.setIsOverviewFetching(false));
        return true;
      }
      dispatch(myShopActions.setIsOverviewFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};




// ------------ Regions --------------------------------

