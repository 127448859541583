import { LoadingButton } from "@mui/lab";
import { Form, DatePicker, notification, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import type { DatePickerProps } from "antd";
import dayjs from "dayjs";
import { toIsoDate } from "../../utils/converter";
import {
  getAllTransactionInRangeAction,
  revertSalesAction,
  updatePaidOnAction,
} from "../../store/pos/actions";

const PaidOnForm: React.FC<{
  cartId: any;
  dataToUpdate?: any;
  selectedDate: any;
  selectedEndDate: any;
  onCancel?: any;
  action?: any;
}> = ({
  cartId,
  dataToUpdate,
  onCancel,
  selectedDate,
  selectedEndDate,
  action,
}) => {
  const { auth, pos } = useSelector((state: any) => state);
  const [confirmationDate, setConfirmationDate] = useState<string | string[]>(
    dayjs().format("DD/MM/YYYY")
  );

  const dispatch = useDispatch();
  const handlerRevertSale = async (comments: any) => {
    auth?.token &&
      (await revertSalesAction(
        auth?.token,
        `?cartItemId=${dataToUpdate?._id}&serialNumber=${dataToUpdate?.warehouseProduct?.serialNumber}`,
        { ...comments }
      )(dispatch));
    onCancel();
    await getAllTransactionInRangeAction(
      auth?.token,
      `${`?limit=15&page=0&startDate=${selectedDate}&endDate=${selectedEndDate}`}`
    )(dispatch);
  };

  const handlerPainOn = async (payload: any) => {
    if (cartId && auth?.token) {
      await updatePaidOnAction(auth?.token, cartId, { ...payload })(dispatch);
      onCancel();
      await getAllTransactionInRangeAction(
        auth?.token,
        `${`?limit=15&page=0&startDate=${selectedDate}&endDate=${selectedEndDate}`}`
      )(dispatch);
    } else {
      notification.error({ message: "Something went wrong" });
    }
  };

  const onFinish = async (values: any) => {
    const revertComments = {
      comments: {
        description: `Revert Sale >> ${values?.comment}`,
        createdBy: auth?.user?._id,
      },
    };

    const payload = {
      paidOn: toIsoDate(confirmationDate),
      comments: [
        {
          description: `Paid on >> ${values?.comment}`,
          createdBy: auth?.user?._id,
        },
      ],
    };

    if (action === "paidOn") {
      await handlerPainOn(payload);
      await form.resetFields();
    } else {
      handlerRevertSale(revertComments);
    }
  };
  const [form] = Form.useForm();

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    setConfirmationDate(dateString);
  };

  return (
    <div className="relative">
      <Form
        form={form}
        name="register"
        onFinish={onFinish}
        initialValues={{}}
        style={{ maxWidth: "100%" }}
        scrollToFirstError
        layout="vertical"
      >
        <h1 className="pb-4 font-semibold text-xl">
          {action === "paidOn" ? "Update Paid On" : "Revert Sales"}
        </h1>
        {action === "paidOn" && (
          <Form.Item
            name="paidOn"
            label="Paid On"
            rules={[
              {
                required: confirmationDate === "" ? true : false,
                message: "Date is required!",
              },
            ]}
          >
            <DatePicker
              defaultValue={dayjs(confirmationDate.toString(), "DD/MM/YYYY")}
              format="DD/MM/YYYY"
              onChange={onChange}
              className="w-full h-10"
            />
          </Form.Item>
        )}
        <Form.Item
          name="comment"
          label="Reason"
          rules={[
            {
              required: true,
              message: "Reason is required!",
            },
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ minWidth: "60%" }}
            loading={pos?.isFetching}
          >
            {"update"}
          </LoadingButton>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PaidOnForm;
