import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import DashLayout from "../components/layout/Layout";
import store from "store";
import TopNav from "../components/navitems/TopNavItems";
import { listManages } from "../assets/data/pages";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllChannelAction,
  getOneChanneltByNameAction,
} from "../store/channel/actions";

export const channelDropDownRoutes = [
  {
    caption: "Overview",
    path: "",
  },
  {
    caption: "Shops",
    path: "",
  },
  {
    caption: "Agents",
    path: "",
  },

  {
    caption: "Price List",
    path: "",
  },

  {
    caption: "All Shop Request",
    path: "",
  },

  // {
  //   caption: "Transfer",
  //   path: `/channel/${channelName}/transfer`,
  //   onClick: () => {
  //     console.log("price list:00");
  //   },
  // },
];

const DashRoutes = (props: any) => {
  const { channelName } = useParams();
  const { auth } = useSelector((state: any) => state);
  const token = store.get("authToken");
  store.get("channelId");

  const allowedRoles = [
    "admin",
    "dev",
    "sales-manager",
    "sales-supervisor",
    "inventory",
    "finance",
    "finance-manager",
    "b2b-relation",
  ];
  const isAuthenticated = auth.token || token;
  const userRole = auth.user?.role;

  const location = useLocation();

  const isSubsPage = () => {
    return ["/sub/"].some((path) => location.pathname.includes(path));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    auth?.token && getAllChannelAction(auth?.token)(dispatch);
    auth?.token &&
      channelName &&
      getOneChanneltByNameAction(channelName, auth?.token)(dispatch);
  }, [auth?.token, channelName, dispatch]);
  let authent = { token: true };
  const pagesArray = [
    {
      caption: "Overview",
      path: `/channel/${channelName}/pos/overview`,
      onClick: () => {
        console.log("Overview:00");
      },
    },
    {
      caption: "Shops",
      path: `/channel/${channelName}/pos/shops`,
      onClick: () => {
        console.log("shops:00");
      },
    },
    {
      caption: "Agents",
      path: `/channel/${channelName}/pos/agents`,
      onClick: () => {
        console.log("agents:00");
      },
    },
    {
      caption: "Plans",
      path: `/channel/${channelName}/pos/prices`,
      onClick: () => {
        console.log("price list:00");
      },
    },
    {
      caption: "All Shop Request",
      path: `/channel/${channelName}/pos/request-stock`,
      skip: ["sales-supervisor"].includes(auth?.user?.role),
      onClick: () => {
        console.log("price list:00");
      },
    },
    {
      caption: "Commision List",
      path: `/channel/${channelName}/pos/commissions`,
      onClick: () => {
        console.log("commission list:00");
      },
    },
  ];

  const SubscriptionsPagesArray = [
    {
      caption: "Overview",
      path: `/channel/${channelName}/sub/overview`,
      onClick: () => {
        console.log("Overview:00");
      },
    },

    {
      caption: "Price List",
      path: `/channel/${channelName}/sub/prices`,
      onClick: () => {
        console.log("price list:00");
      },
    },
  ];

  if (isAuthenticated && allowedRoles.includes(userRole)) {
    return (
      <DashLayout
        allowSearch={true}
        nav={
          <TopNav
            isChannel={true}
            nav={listManages?.find(
              (item) => item.title.toLowerCase() === "Channel".toLowerCase()
            )}
            pages={
              !props?.isNotTab
                ? isSubsPage()
                  ? SubscriptionsPagesArray
                  : pagesArray
                : []
            }
          />
        }
        navSelected
        selectedNav={6}
      >
        <Outlet />
      </DashLayout>
    );
  } else {
    return <Navigate to="/dashboard" />;
  }
};

export default DashRoutes;
