import React from "react";
import { Table, Space, Tooltip } from "antd";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import type { ColumnsType, TableProps } from "antd/es/table";
import * as convert from "../../assets/data/productExport";

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Type",
    dataIndex: "type",

    // onFilter: (value: string, record) => record.address.indexOf(value) === 0,
  },
  {
    title: "Brand",
    dataIndex: "brand",
  },
  {
    title: "Model",
    dataIndex: "model",
  },
  {
    title: "Storage",
    dataIndex: "storage",
    defaultSortOrder: "descend",
  },
  {
    title: "Color",
    dataIndex: "colors",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Action",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <IconButton aria-label="delete" color="success">
          <RemoveRedEyeIcon />
        </IconButton>
        <IconButton aria-label="delete" color="secondary">
          <BorderColorIcon />
        </IconButton>
        <Tooltip placement="bottomLeft" title={"Delete"}>
          <IconButton aria-label="delete" color="error">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Space>
    ),
  },
];

const onChange: TableProps<DataType>["onChange"] = (
  pagination,
  filters,
  sorter,
  extra
) => {
  console.log("params", pagination, filters, sorter, extra);
};

const App = (props: any) => {
  const data = props?.data?.map((d: any) =>
    convert.createExportData(
      d._id,
      d.type,
      d.brand,
      d.model,
      d.storage.join(", "),
      d.colors.join(", "),
      d.status
    )
  );

  return <Table columns={columns} dataSource={data} onChange={onChange} />;
};

export default App;
