import React, { useEffect, useState } from "react";
import { Button, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import { searchValue } from "../../../utils/setColor";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import B2CIssuesDrower from "../pages/B2CIssuesDrower";
import { getAllCartsAction } from "../../../store/pos/actions";
import { myEmployeeActions } from "../../../store/setting";

interface DataType {
  [x: string]: any;
  key: any;
  name: string;
  age: number;
  address: string;
}

const B2CIssuesTable = () => {
  const { auth, pos } = useSelector((state: any) => state);
  const [dataToUpdate, setDataToUpdate] = useState<any>(null);
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const showDrawer = (d: any) => {
    setOpen(true);
    setDataToUpdate(d);
  };

  const onClose = () => {
    setOpen(false);
    setDataToUpdate(null);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "date_time",
      key: "date_time",
    },
    {
      title: "Shop",
      dataIndex: "shop",
      key: "shop",
    },
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: any, _record: any) => (
        <Tag color={searchValue(text)} className="capitalize">
          {text}
        </Tag>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, record) => {
        return (
          <div className="flex items-end justify-center space-x-1">
            <Button
              className="capitalize min-w-[50px] text-center outline-none"
              onClick={() => {
                showDrawer(record);
              }}
            >
              View
            </Button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(myEmployeeActions.setSelected(null));
    if (auth?.token) {
      getAllCartsAction(
        auth?.token,
        `?hasIssue=true&limit=${limit}&page=${page - 1}`
      )(dispatch);
    }
  }, [auth?.token, dispatch, limit, page]);

  const dataToDisplay = pos?.carts?.data?.map((el: any) => {
    return {
      key: el._id,
      date_time: el?.createdAt?.slice(0, 10),
      shop: el?.list?.at(0)?.shopStockItem?.shop?.name,
      agent: el?.createdBy?.names,
      model: el?.list?.at(0)?.priceProduct?.product?.model,
      imei: el?.list?.at(0)?.warehouseProduct?.serialNumber,
      customer: el?.customer?.name,
      status: el?.status,
      data: el,
    };
  });

  return (
    <>
      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={pos?.carts?.total}
        count={Math.ceil(pos?.carts?.total / limit)}
      >
        <Table
          columns={columns}
          dataSource={dataToDisplay}
          pagination={false}
          loading={pos?.isFetching}
        />
      </ScrollableFrame>
      <B2CIssuesDrower open={open} onClose={onClose} data={dataToUpdate} />
    </>
  );
};

export default B2CIssuesTable;
