import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "csv",
  initialState: {
    isFetching: false,
    isFileFetching: false,
    csvDownloadedData: null,
    allowcsvDownload: false,
    isFileDownload: false,
    fileDownloadedData: null,
    fileToExport: "csv",
  },
  reducers: {
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setIsFileFetching(state, action) {
      state.isFileFetching = action.payload;
    },
    setCSVDownloadedData(state, action) {
      state.csvDownloadedData = action.payload;
    },
    setAllowCSVDownload(state, action) {
      state.allowcsvDownload = action.payload;
    },
    setIsFileDownload(state, action) {
      state.isFileDownload = action.payload;
    },
    setFileDownloadedData(state, action) {
      state.fileDownloadedData = action.payload;
    },
    setFileToExport(state, action) {
      state.fileToExport = action.payload;
    },
  },
});

export const myCSVAction = mySlice.actions;

export default mySlice.reducer;
