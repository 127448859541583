import { Navigate, Outlet } from "react-router-dom";
import DashLayout from "../components/layout/Layout";
import store from "store";
import TopNav from "../components/navitems/TopNavItems";
import { listManages } from "../assets/data/pages";
import React from "react";
import { useSelector } from "react-redux";

const SalesRepRoutes = () => {
  const { auth } = useSelector((state: any) => state);

  const pagesArray = [{}];
  const token = store.get("authToken");
  return auth.token || token ? (
    <DashLayout
      allowSearch={true}
      nav={
        <TopNav
          nav={listManages?.find(
            (item) => item.title.toLowerCase() === "SalesRep".toLowerCase()
          )}
          pages={pagesArray}
        />
      }
      selectedNav={0}
    >
      <Outlet />
    </DashLayout>
  ) : (
    <Navigate to="/dashboard" />
  );
};

export default SalesRepRoutes;
