import React, { useState } from 'react';
import { List, Button, Card, Modal, Form, Input, DatePicker, TimePicker } from 'antd';
import actionIcon from '../department/Group 73.png';
import moment from 'moment';

const initialData = [
  {
    day: 'Monday 10th',
    event: 'Interview',
    time: '9:00 am - 11:30 am',
  }
];

const ScheduleList = () => {
  const [data, setData] = useState(initialData);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleNewScheduleClick = () => {
    setIsModalVisible(true);
  };

  const handleOk = (values:any) => {
    const newSchedule = {
      day: values.date.format('dddd Do'),
      event: values.event,
      time: `${values.startTime.format('h:mm a')} - ${values.endTime.format('h:mm a')}`,
    };
    setData([...data, newSchedule]);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const styles = {
    border: {
      border: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)"
    },
    shadow: {
      boxShadow: "0px 4px 26px 0px rgba(111, 111, 114, 0.12)"
      
    }
  };
  return (
    <div className='w-[100%] lg:w-[50%] h-317px bg-[#ffffffea] rounded-md'>
      <div className='flex md:flex-row justify-between px-3 py-4'>
        <h2 className="text-lg font-poppins mb-4 text-[#030229]">Schedules</h2>
        <Button type="primary" className="mb-4 mt-5 md:mt-0" onClick={handleNewScheduleClick}>
          + New Schedule
        </Button>
      </div>
      <div className="divide-y divide-[#8f8f8f13] p-3">
      <div className='flex justify-between px-2'>
      <div className='flex gap-5'>
        <div className='w-[60px] h-50px] bg-white rounded-md px-5 py-1' style={styles?.shadow}>
          <h1 className='text-[#605BFF] text-sm text-center'>Mon</h1>
          <p className='text-[#333333] text-sm text-center'>10</p>
        </div>
        <div className="items-center">
          <h1 className='text-[#333333] text-sm'>Interview</h1>
          <p className='text-[#8F8F8F] text-sm'>9:00 am - 11:30 am</p>
        </div>

      </div>
      <div className="mb-4 rounded-md bg-[#e6e5f1] text-[#605BFF] text-md shadow-sm cursor-pointer">
              <img src={actionIcon} alt="Action Icon" className="cursor-pointer"/>
            </div>
      </div>
      <div className='flex justify-between p-2 items-center divide-y'>
      <div className='flex gap-5'>
        <div className='w-[50px] h-40px] bg-white rounded-md px-2 py-1' style={styles?.shadow}>
          <h1 className='text-[#605BFF] text-sm text-center'>Thu</h1>
          <p className='text-[#333333] text-sm text-center'>10</p>
        </div>
        <div>
          <h1 className='text-[#333333] text-sm'>Organizational meeting</h1>
          <p className='text-[#8F8F8F] text-sm'>9:00 am - 11:30 am</p>
        </div>

      </div>
      <div className="mb-4 rounded-md bg-[#e6e5f1] text-[#605BFF] text-md shadow-sm cursor-pointer">
              <img src={actionIcon} alt="Action Icon" className="cursor-pointer" />
            </div>
      </div>
      <div className='flex justify-between p-2 items-center divide-y'>
      <div className='flex gap-5'>
        <div className='w-[50px] h-40px] bg-white rounded-md px-2 py-1' style={styles?.shadow}>
          <h1 className='text-[#605BFF] text-sm text-center'>Fri</h1>
          <p className='text-[#333333] text-sm text-center'>08</p>
        </div>
        <div>
          <h1 className='text-[#333333] text-sm'>Meeting with the manager</h1>
          <p className='text-[#8F8F8F] text-sm'>9:00 am - 11:30 am</p>
        </div>

      </div>
      <div className="mb-4 rounded-md bg-[#e6e5f1] text-[#605BFF] text-md shadow-sm cursor-pointer">
              <img src={actionIcon} alt="Action Icon" className="cursor-pointer" />
            </div>
      </div>
      <div className='flex justify-between p-2 items-center divide-y'>
      <div className='flex gap-5'>
        <div className='w-[50px] h-40px] bg-white rounded-md px-2 py-1' style={styles?.shadow}>
          <h1 className='text-[#605BFF] text-sm text-center'>Thu</h1>
          <p className='text-[#333333] text-sm text-center'>12</p>
        </div>
        <div>
          <h1 className='text-[#333333] text-sm'>Organizational meeting</h1>
          <p className='text-[#8F8F8F] text-sm'>9:00 am - 11:30 am</p>
        </div>

      </div>
      <div className="mb-4 rounded-md bg-[#e6e5f1] text-[#605BFF] text-md shadow-sm cursor-pointer">
              <img src={actionIcon} alt="Action Icon" className="cursor-pointer" />
            </div>
      </div></div>
      
      {/* <List
        className='px-3'
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta
              title={`${item.event}`}
              description={`${item.day}, ${item.time}`}
            />
            
          </List.Item>
        )}
      /> */}

      <Modal title="New Schedule" visible={isModalVisible} onCancel={handleCancel} footer={null}>
        <Form onFinish={handleOk}>
          <Form.Item
            name="date"
            rules={[{ required: true, message: 'Please select a date!' }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name="event"
            rules={[{ required: true, message: 'Please input the event!' }]}
          >
            <Input placeholder="Event" />
          </Form.Item>
          <Form.Item
            name="startTime"
            rules={[{ required: true, message: 'Please select a start time!' }]}
          >
            <TimePicker use12Hours format="h:mm a" />
          </Form.Item>
          <Form.Item
            name="endTime"
            rules={[{ required: true, message: 'Please select an end time!' }]}
          >
            <TimePicker use12Hours format="h:mm a" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Schedule
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ScheduleList;
