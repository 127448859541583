import { Button, Form } from "antd";
import { InputNumber } from "antd/lib";
import React from "react";
import { reloadDeviceAction } from "../../../../store/vault/actions";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const ReloadDevices = (props: any) => {
  const { auth, vault } = useSelector((state: any) => state);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onReloadDevice = async (values?: any) => {
    auth?.token &&
      (await reloadDeviceAction(auth?.token, {
        ...values,
        deviceUid: [props?.deviceData],
      })(dispatch));
    props?.onClose();
    form.resetFields();
  };
  return (
    <>
      <div>
        <Form
          name="basic"
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onReloadDevice}
          autoComplete="off"
        >
          <div className="text-[#030229] pl-1 text-sm font-medium mb-2">
            Days
          </div>
          <Form.Item
            name="reloadDays"
            className="w-full "
            rules={[{ required: true, message: "Please enter days" }]}
          >
            <InputNumber
              defaultValue={30}
              className="capitalize rounded-md h-[52px] w-[100%] "
            />
          </Form.Item>
          <Form.Item className="w-full">
            <div className="flex gap-3 ml-[11rem] mt-4">
              <Button
                className="w-32 border border-red-400"
                onClick={props?.onClose}
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                className="w-32  border border-[#605BFF]"
              >
                {vault?.isFetching ? (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 24 }} spin />
                    }
                  />
                ) : (
                  "Send"
                )}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
export default ReloadDevices;
