import { PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Button, Stack } from "@mui/material";
import {
  Divider,
  Form,
  Input,
  Space,
  Select,
  InputNumber,
  Tooltip,
  Radio,
} from "antd";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector, useDispatch } from "react-redux";

import { getAllCustomersAction } from "../../store/customer/actions";
import {
  createNosaleAction,
  getAllNosaleAction,
  getOnePriceListItemAction,
  getPricelistAction,
} from "../../store/pos/actions";
import { getActiveShop } from "../../utils/converter";
import { getAllProductsAction } from "../../store/transfer/actions";
import { myProductActions } from "../../store/product";
import NoSaleFilter from "../filters/NosaleFilter";
import { getPricelistItemAction } from "../../store/channel/actions";
import { myPosActions } from "../../store/pos";
const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const App: React.FC<{ onCancel?: any }> = ({ onCancel }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [productId, setProductId] = useState();
  const [selectedRadioButton, setSelectedRadioButton] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const { product, auth, customer, channel, shop, pos } = useSelector(
    (state: any) => state
  );
  const [selectedModelId, setSelectedModelId] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);

  const selectedShopId =
    getActiveShop(auth?.user?.shop?.assigned)[0]?.shop?._id ??
    shop?.selected?._id;

  const onFinish = async (values: any) => {
    const val = {
      ...values,
      shopId: selectedShopId,
      productId: productId,
      // specification: [
      //   ["Type", product?.selected?.type],
      //   ["Brand", product?.selected?.brand],
      //   ...Object.entries(values.specification),
      // ],
    };
    if (auth?.token) {
      await createNosaleAction(auth?.token, val)(dispatch);
      await getAllNosaleAction(
        auth?.token,
        `?shopId=${selectedShopId}`
      )(dispatch);
      form.resetFields();
      onCancel();
      dispatch(myPosActions.setOnePriceListItem(null));
    }
  };

  useEffect(() => {
    (auth?.user?.shop?.channel || auth?.user?.shop?.channelId) &&
      auth?.token &&
      getPricelistItemAction(
        auth?.token,
        `?channel=${
          auth?.user?.shop?.channel ?? auth?.user?.shop?.channelId
        }&limit=15&type=pos`
      )(dispatch);
  }, [
    auth?.token,
    auth?.user?.shop?.channel,
    auth?.user?.shop?.channelId,
    dispatch,
  ]);

  useEffect(() => {
    auth?.token &&
      getAllCustomersAction(auth?.token, `?sk=${searchValue}`)(dispatch);
  }, [auth.token, dispatch, searchValue]);

  useEffect(() => {
    auth?.token &&
      productId &&
      getAllProductsAction(
        auth?.token,
        `?shop=${
          getActiveShop(auth?.user?.shop?.assigned)[0]?.shop?._id
        }&product=${productId}`
      )(dispatch);
  }, [auth?.token, dispatch, productId]);

  const handleRadioChange = (e: any) => {
    const value = e.target.value;
    setSelectedRadioButton((prevValue) => (prevValue === value ? null : value));
  };
  const handleSearch = (value: any) => {
    const filtered = customer?.all?.data.filter((d: any) =>
      d?.name?.toLowerCase()?.includes(value.toLowerCase())
    );
    setFilteredOptions(filtered);
    setSearchValue(value);
  };

  useEffect(() => {
    dispatch(myProductActions.setSelected(null));
    auth?.token &&
      selectedModelId &&
      getOnePriceListItemAction(auth?.token, selectedModelId)(dispatch);
    // }
  }, [auth?.token, dispatch, selectedModelId]);

  console.log("selectedModelId", selectedModelId);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      style={{ maxWidth: "100%" }}
      scrollToFirstError
    >
      <div className="flex items-center justify-between">
        {!selectedRadioButton && (
          <NoSaleFilter setProductId={setProductId} productId={productId} />
        )}
        <Radio.Group value={selectedRadioButton}>
          <Radio value="option1" onClick={handleRadioChange}>
            New Product?
          </Radio>
        </Radio.Group>
      </div>

      <Stack
        direction={"row"}
        spacing={2}
        alignItems="flex-start"
        justifyContent={"start"}
        sx={{ width: "100%" }}
      >
        <Stack sx={{ width: "50%" }}>
          {selectedRadioButton ? (
            <Form.Item
              name="newProduct"
              label="Product Model"
              tooltip="Please select modal of the product!"
            >
              <Input style={{ fontSize: "14px" }} />
            </Form.Item>
          ) : (
            <>
              <Form.Item
                name="priceListItemId"
                label="Product Model"
                tooltip="Please select modal of the product!"
              >
                <Select
                  defaultValue={""}
                  onChange={(value: any) => setSelectedModelId(value)}
                >
                  {channel?.priceListItems?.data?.map(
                    (item: any, index: any) => (
                      <Option key={index} value={item?._id}>
                        <Tooltip
                          title={`${item?.specification
                            ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
                            ?.join(", ")}`}
                        >
                          {item?.product?.model + "~" + item?.product?.type}
                        </Tooltip>
                      </Option>
                    )
                  )}
                </Select>
              </Form.Item>
              {pos?.onePriceListItem?.data && (
                <div className="ml-[10.5rem] mb-1">
                  <p>
                    {pos?.onePriceListItem?.data?.specification
                      ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
                      ?.join(", ")}
                  </p>
                </div>
              )}
            </>
          )}

          {selectedRadioButton && (
            <Form.Item name="specification" label="Specifications">
              <Input style={{ fontSize: "14px" }} />
            </Form.Item>
          )}

          <Form.Item name="customerId" label="Customer">
            <Select
              showSearch
              filterOption={false}
              placeholder="Select/Add Customer"
              onSearch={handleSearch}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    {/* <Link to="/customers"> */}
                    <Button variant="contained">
                      {" "}
                      <PlusOutlined /> {" Customer"}
                    </Button>
                    {/* </Link> */}
                  </Space>
                </>
              )}
            >
              {filteredOptions.length > 0
                ? filteredOptions.map((option: any) => (
                    <Select.Option key={option.value} value={option._id}>
                      {option.name}
                    </Select.Option>
                  ))
                : customer?.all?.data.map((option: any) => (
                    <Select.Option key={option.value} value={option._id}>
                      {option.name}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>

          <Form.Item name="comment" label="Coments">
            <TextArea
              placeholder="Coments"
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ minWidth: "100%" }}
              loading={product?.isFetching}
            >
              Save
            </LoadingButton>
          </Form.Item>
        </Stack>
        <Stack
          direction={"column"}
          alignItems="center"
          justifyContent={"center"}
        >
          <Form.Item name="quantity" label="Quantity" style={{ width: 300 }}>
            <InputNumber
              type="number"
              placeholder="quantity"
              style={{ width: 120 }}
            />
          </Form.Item>
          {/* <Radio>New</Radio> */}
        </Stack>
      </Stack>
    </Form>
  );
};

export default App;
