import React from "react";
import PhoneInput from "react-phone-input-2";
import { FaRegUserCircle } from "react-icons/fa";
import { getOneDelivererOutAction } from "../../../store/delivery/actions";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { CiEdit } from "react-icons/ci";
const DeliverDetails = (props: any) => {
  const { tracking } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const onFinish = async (value: any) => {
    const res = getOneDelivererOutAction(`?phone=${value?.phone}`)(dispatch);
    //  if(await res){
    // notification.success({ message: "successfully verfied" })
    //  }
  };
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <div className="w-full h-full md:px-20 py-5 relative">
      <div>
        <h1 className="text-[#030229] text-lg font-semibold py-3 md:pb-6 text-center">
          Your phone number
        </h1>
        <p className="text-[#030229B2] text-lg text-center">
          Enter deliverer phone number
        </p>
        <div className="w-full xl:w-[45%] m-auto mt-10 ">
          <Form
            className="mt-5"
            name="basic"
            //   labelCol={{ span: 8 }}
            //   wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item className="w-full"
              label={<p className="pt-5 text-lg">Phone number</p>}
              name="phone"
              rules={[{ required: true, message: "Please input your phone!" }]}
            >
              <PhoneInput
                country={"rw"}
                inputStyle={{
                  width: "100%",
                  height: "70px",
                  color: "black",
                }}
                containerClass="phone-container"
                placeholder="+250 780 000 000"
              />
            </Form.Item>
            {tracking?.selectedDriver ? (
              ""
            ) : (
              <Form.Item className="w-1/2 m-auto bg-wred-400 ">
                <Button
                  htmlType="submit"
                  className="text-center bg-[#605BFF] text-white w-full py-4 m-auto rounded-md ml-10"
                >
                  {tracking?.isFetching ? (
                    <div className="flex h-fit ">
                      <Spin indicator={antIcon} />
                    </div>
                  ) : (
                    "Send"
                  )}
                </Button>
              </Form.Item>
            )}
          </Form>
          {tracking?.selectedDriver && (
            <div className="border border-[#0302291A] p-4 mt-5 rounded-md">
              <div className="flex justify-between">
                <h1 className="text-[#030229] text-base font-medium py-3 pb-6 text-center">
                  Deliver Preview Details
                </h1>
              </div>
              <div className="border text-black border-[#E3E5F1] bg-[#e3e5f1c0] p-4 mt-5 rounded-lg flex gap-2">
                <FaRegUserCircle size={32} color="#605BFF" />
                <div>
                  <p className="text-[#605BFFB2]">Courier</p>
                  <p>{tracking?.selectedDriver?.name}</p>
                </div>
              </div>
              <div className="flex w-full gap-10 mt-6">
                <p className="text-[#03022980]  text-sm">Email</p>
                <p className="text-[#030229] text-sm">
                  {tracking?.selectedDriver?.email}
                </p>
              </div>
              <div className="flex w-full gap-5 mt-3">
                <p className="text-[#03022980]  text-sm">Phone Number</p>
                <p className="text-[#030229] text-sm">
                  {tracking?.selectedDriver?.phone}
                </p>
              </div>
              <div className="flex w-full gap-10 mt-3">
                <p className="text-[#03022980]  text-sm">Company</p>
                <p className="text-[#030229] text-sm">
                  {tracking?.selectedDriver?.company}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeliverDetails;
