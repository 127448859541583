import { Badge, Card, Modal, Skeleton, Space } from "antd";
import React, { useState } from "react";
import { ReactComponent as DeleteSvg } from "../../../../assets/icons/deleteicon.svg";
import { ReactComponent as EditSvg } from "../../../../assets/icons/editicon.svg";
import { useDispatch, useSelector } from "react-redux";
import SearchInput from "../../../../components/buttons/SearchButton";
import {
  deleteShopAction,
  getAllShopAction,
} from "../../../../store/channel/actions";
import { searchValue } from "../../../../utils/setColor";
import DeleteModal from "../../../../components/Modals/DeleteModal";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import NewShopForm from "../../../../components/forms/NewShopForm";
import SettingButton from "../SettingButton";
import { HiOutlinePlus } from "react-icons/hi";

const ShopProfile = () => {
  const { auth, channel, layout } = useSelector((state: any) => state);
  const [searchKey, setSearchKey] = React.useState("");
  const dispatch = useDispatch();
  const [deleteId, setDeleteId] = React.useState("false");
  const [visible, setVisible] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [data, setData] = React.useState<any>("");

  const channelId = layout?.channelInfo?._id;

  React.useEffect(() => {
    auth?.token &&
      channelId &&
      getAllShopAction(
        auth?.token,
        `channel=${channelId}&limit=${limit}&page=${page - 1}`
      )(dispatch);
    //
  }, [dispatch, channelId, auth?.token, limit, page]);

  const handleSearch = () => {
    auth?.token &&
      channelId &&
      getAllShopAction(
        auth?.token,
        `channel=${channelId}&limit=${limit}&page=${page - 1}&sk=${searchKey}`
      )(dispatch);
  };
  const handleDelete = async () => {
    auth?.token &&
      (await deleteShopAction(deleteId, auth?.token, {
        isDeleted: "true",
      })(dispatch));
    setVisible(false);
  };
  const deleteModal = (itemId: any) => {
    setDeleteId(itemId);
    setVisible(true);
  };
  const handleCancels = () => {
    setVisible(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const CancelUpdateModal = () => {
    setUpdateModalOpen(false);
  };
  const updateModelOpen = (value: any) => {
    setUpdateModalOpen(true);
    setData(value);
  };
  return (
    <>
      <div className="text-[#030229] ">
        <div className="flex justify-between mb-6">
          <div></div>
          <Space>
            <SearchInput
              onSearch={handleSearch}
              onChange={(e: any) => {
                e.preventDefault();
                setSearchKey(e.target.value);
              }}
            />
            <SettingButton
              btnName="New Shop"
              btnBgColor="[#605BFF]"
              textColor="white"
              icon={<HiOutlinePlus size={20} />}
              onClick={updateModelOpen}
            />
          </Space>
        </div>
        <ScrollableFrame
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
          total={channel?.allShop?.total}
          count={Math.ceil(channel?.allShop?.total / limit)}
        >
          <div className="flex flex-wrap gap-4">
            {channel?.isFetching ? (
              <>
                {" "}
                {[1, 2, 3, 4]?.map((el: any) => (
                  <Card style={{ width: 380, height: 120, marginTop: 12 }}>
                    <Skeleton active paragraph={{ rows: 2 }}></Skeleton>
                  </Card>
                ))}
              </>
            ) : (
              channel?.allShop?.data?.map((shop: any, _index: any) => (
                <Badge.Ribbon
                  text={
                    <span style={{ fontSize: "11px" }}>{shop?.status}</span>
                  }
                  color={searchValue(shop?.status)}
                  style={{ padding: "0rem 1rem " }}
                >
                  <div className="w-[22rem] h-[9.5rem] border border-black-300 rounded-lg p-2 px-4">
                    <div>
                      <h1 className="text-base font-medium">{shop?.name}</h1>
                      <p className="text-[.7rem] font-light text-[#605BFF] capitalize">
                        {shop?.region || "N/A"} - {shop?.address || "N/A"}
                      </p>
                    </div>
                    <div className="mt-4 text-[.7rem] opacity-80">
                      <p>MSIDN : {shop?.msisdn}</p>
                      <p>Momo Code : {shop?.momocode} </p>
                    </div>
                    <div className="flex justify-between mt-3">
                      <Space>
                        <button className="px-2 py-[.5px] bg-[#FFCB05] bg-opacity-10 rounded-md border border-gray-300 text-[.7rem] text-[#030229]">
                          {shop?.type}
                        </button>
                        {shop?.shopLocation?.district && (
                          <button className="px-2 py-[.5px] bg-[#030229] bg-opacity-5 rounded-md border border-gray-300 text-[.7rem] text-[#030229]">
                            {shop?.shopLocation?.district}
                          </button>
                        )}
                        {shop?.isCommissionEnabled && (
                          <button className="px-2 py-[.5px] bg-[#605BFF] bg-opacity-5 rounded-md border border-gray-300 text-[.7rem]">
                            Commissioned
                          </button>
                        )}
                      </Space>
                      <Space>
                        <EditSvg
                          className="cursor-pointer"
                          fontSize={12}
                          onClick={() => updateModelOpen(shop)}
                        />
                        <DeleteSvg
                          className="cursor-pointer"
                          onClick={() => deleteModal(shop?._id)}
                        />
                      </Space>
                    </div>
                  </div>
                </Badge.Ribbon>
              ))
            )}
          </div>
        </ScrollableFrame>
      </div>
      <DeleteModal
        visible={visible}
        onOk={handleDelete}
        isLoading={channel.isFetching}
        onCancel={handleCancels}
        itemName="shop"
      />
      <Modal
        title={""}
        open={updateModalOpen}
        onCancel={CancelUpdateModal}
        footer={null}
        className="min-w-min"
      >
        <div>
          <NewShopForm data={data} onCancel={CancelUpdateModal} />
        </div>
      </Modal>
    </>
  );
};
export default ShopProfile;
