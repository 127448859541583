import { Form } from "antd";
import SearchBar from "../../../components/SearchBar";
import { useDispatch, useSelector } from "react-redux";

import BarcodeScanner from "../../../components/BarcodeScanner";
import { useState } from "react";
import { getSearchBySerialNumberActions } from "../../../store/inventory/actions";

const InventoryItemSearch = (props: any) => {
  const { auth, inventory } = useSelector((state: any) => state);
  const [scannedCode, setScannedCode] = useState<string>();
  const [newSearch, setNewSearch] = useState<boolean>(false);

  const dispatch = useDispatch();
  const onFinish = async (values: any) => {
    console.log("Values: ", values);
    // decodeStringToObject(values?.query); //checking National ID Data
    auth?.token && 
      getSearchBySerialNumberActions(
        auth?.token,
        `serialNumber=${values?.query}`
      )(dispatch);
  };
  function decodeStringToObject(encodedString: any) {
    const stringWithoutSpaces = encodedString?.replace(/\s/g, "");
    const splitString = stringWithoutSpaces?.split(/[^a-zA-Z0-9]+/);

    const id = splitString[0]?.match(/\d{16}/)[0];
    const names = splitString[0]
      ?.replace(/\d/g, "")
      ?.replace(/([A-Z])/g, " $1")
      ?.replace(/ (?=\b\w\b)/g, "");

    console.log({ id, names });
    console.log(splitString[0]);

    return;
    // return {
    //   id,
    //   firstName,
    //   lastName
    // };
  }
  const getCode = (code: string) => {
    console.log("CODE", code);

    onFinish({ query: code });
    setScannedCode(code);
  };
  const handleChange = () => {
    setNewSearch(true);
  };

  return (
    <div className="bg-white rounded-lg text-black pl-4 pr-4 pt-6 leading-none">
      <div className="flex justify-between items-center">
        <div>
          <div className="flex">
            <Form onFinish={onFinish}>
              <Form.Item
                name="query"
                label="Enter serial number"
                initialValue={scannedCode}
              >
                <SearchBar
                  isLoading={inventory.isFetching}
                  scannedCode={newSearch ? "" : scannedCode}
                  onChange={handleChange}
                />
              </Form.Item>
              {inventory?.searchBySn?.data.length === 0 && (
                <div className="pb-2">
                  <p className="text-red-300">Product Not Found</p>
                </div>
              )}
            </Form>
            <BarcodeScanner getCode={getCode} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryItemSearch;
