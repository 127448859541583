import React from "react";
import { Select, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { GrFormView } from "react-icons/gr";

interface DataType {
  key: string;
  shop: string;
  transaction: string;
  commission: string;
  amount: string;
  balance: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Shop",
    dataIndex: "shop",
    key: "shop",
    width: 100,
  },
  {
    title: "Transaction",
    dataIndex: "transaction",
    key: "transaction",
    width: 200,
    // render: (text: any, record: any) => (
    //   <>
    //     <div className="flex items-center space-x-2">
    //       <img src={Inactive} alt="" className="w-6 h-6" /> <h1>{text}</h1>
    //     </div>
    //   </>
    // ),
  },
  {
    title: "Total Commission",
    dataIndex: "commission",
    key: "commission",
  },
  {
    title: "Paid Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Balance",
    dataIndex: "balance",
    key: "balance",
  },
  {
    title: "action",
    key: "data",
    dataIndex: "data",
    render: (data:any) => (
      <>
      <div><GrFormView size={25} color="#030229B2"/></div>
      </>
    ),
  },
];

const CommissionOverview = () => {
  const data: DataType[] = [
    {
      key: '1',
      shop: 'Nyamirambo_CS',
      transaction: '34',
      commission: '$10',
      amount: '$10',
      balance: 'N/A',
    },
    {
      key: '2',
      shop: 'Nyamirambo_CS',
      transaction: '34',
      commission: '$10',
      amount: '$10',
      balance: 'N/A',
    },
    {
      key: '3',
      shop: 'Nyamirambo_CS',
      transaction: '34',
      commission: '$10',
      amount: '$10',
      balance: 'N/A',
    },
  ];
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };
  return (
    <div className="w-full bg-[#fff] h-[50vh] overflow-y-auto p-5">
     <div className="text-[#030229] text-lg flex justify-between">
        <p>Commission Overview</p>
        <Select
          defaultValue="region"
          style={{ width: 120 }}
          onChange={handleChange}
          options={[{ value: "shop", label: "Shop" }]}
        />
      </div>
          <div className="flex gap-10 my-3">
            <p className="text-[#0F0F47] font-light text-base pt-3">SC:Service Center</p>
          
            <p className="text-[#0F0F47] font-light text-base pt-3">CS:Connect Shop</p>
          </div>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </div>
  );
};

export default CommissionOverview;
