import { PlusOutlined } from "@ant-design/icons";
import { AutoComplete, Input, Spin } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchCustomerByPhoneNumberAction } from "../../../../../store/customer/actions";
import {
  handleFoundCustomerByPhone,
  handleNewCustomer,
} from "../../../../../store/layout/actions";
import ImagePlaceholder from "../../../../../assets/images/phone-number-image.png";

const SearchCustomer = (props: any) => {
  const { auth, customer } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const notFoundContent = customer?.isFetching ? (
    <Spin size="small" />
  ) : customer?.customerByPhonenNumber?.data.length === 0 ? (
    "No Data"
  ) : null;

  const handleSearch = (value: string) => {
    if (value && auth?.token) {
      searchCustomerByPhoneNumberAction(auth?.token, `?q=${value}`)(dispatch);
    }
  };

  const handleSelect = (value: string) => {
    const user = customer?.customerByPhonenNumber?.data.find(
      (user: any) => user.name === value
    );
    handleFoundCustomerByPhone(user)(dispatch);
  };

  const handleCreateNewCustomer = () => {
    handleNewCustomer(true)(dispatch);
    handleFoundCustomerByPhone(null)(dispatch);
  };
  return (
    <div>
      <div className="mx-20 flex items-center space-x-4">
        <AutoComplete
          options={customer?.customerByPhonenNumber?.data?.map(
            (option: any) => ({
              label: `${option.name} ~ ${option.phone}`,
              value: option.name,
              data: option,
            })
          )}
          onSearch={handleSearch}
          onSelect={handleSelect}
          allowClear
          fieldNames={{ label: "label", value: "value" }}
          notFoundContent={notFoundContent}
        >
          <Input
            style={{ width: 500, height: 45 }}
            type="number"
            className="w-full shadow-md"
          />
        </AutoComplete>
        <div
          className="bg-[#605bff] p-3 rounded-md text-white cursor-pointer"
          onClick={handleCreateNewCustomer}
        >
          <PlusOutlined />
        </div>
      </div>
      <div>
        <img src={ImagePlaceholder} alt="" />
      </div>
    </div>
  );
};

export default SearchCustomer;
