import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import React from "react";
import { calculatePercentageChange } from "../../utils/converter";
import { useSelector } from "react-redux";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { getDefaultCurrencyCode } from "../../utils/helper";

const DashboardIkosoraSalesCard: React.FC<{
  title: string;
  height?: string;
}> = ({ title, height }) => {
  const { dashboard, layout, company } = useSelector((state: any) => state);

  const todayRevenue =
    dashboard?.ikosoraStats?.data &&
    dashboard?.ikosoraStats?.data?.currentDate?.at(0)?.revenue;
  const yesterdayRevenue =
    dashboard?.ikosoraStats?.data &&
    dashboard?.ikosoraStats?.data?.comparedDate?.at(0)?.revenue;

  const currentTotalQty =
    dashboard?.ikosoraStats?.data &&
    dashboard?.ikosoraStats?.data?.currentDate?.at(0)?.quantity;

  const comparedTotalQty =
    dashboard?.ikosoraStats?.data &&
    dashboard?.ikosoraStats?.data?.comparedDate?.at(0)?.quantity;

  const percentageChange = calculatePercentageChange(
    todayRevenue ?? 0,
    yesterdayRevenue ?? 0
  );

  return (
    <div
      className={`relative flex flex-col ${
        height ? height : "max-h-[256px]"
      } p-4 bg-white rounded-md`}
    >
      <p className="text-gray-400 capitalize mb-2">{title}</p>
      <p className="text-2xl font-semibold">
        {getDefaultCurrencyCode(company) +
          (todayRevenue?.toLocaleString() || 0)}{" "}
        /<span className="text-xs">{`${currentTotalQty || 0} Pieces`}</span>
      </p>
      {layout?.dashboardPreview === "day" && (
        <p className="text-gray-400 capitalize">
          {getDefaultCurrencyCode(company) +
            (yesterdayRevenue?.toLocaleString() || 0)}{" "}
          /<span className="text-xs">{`${comparedTotalQty || 0} Pieces`}</span>
        </p>
      )}
      {percentageChange && (
        <div
          className={`${
            layout?.dashboardPreview === "day" ? "absolute" : "hidden"
          } top-2 right-2 ${
            percentageChange.type === "increase"
              ? "bg-green-200"
              : percentageChange.type === "decrease"
              ? "bg-[#FEE6E9]"
              : "bg-blue-200"
          }  rounded-lg px-3 py-1`}
        >
          <span
            className={`${
              percentageChange.type === "increase"
                ? "text-green-500"
                : percentageChange.type === "decrease"
                ? "text-red-500"
                : "text-blue-900"
            } flex items-center py-0.5`}
          >
            {percentageChange.type === "increase" ? (
              <ArrowUpwardIcon />
            ) : percentageChange.type === "decrease" ? (
              <ArrowDownwardIcon />
            ) : (
              ""
            )}
            {percentageChange.percentage + "%"}
          </span>
        </div>
      )}
      {/* <LineChart
        data={data}
        prefix={`${getDefaultCurrencyCode(company)}`}
        thousands=","
        xtitle="Hours"
        height={"100%"}
        loading="Loading..."
      /> */}
      {/* <div className="flex divide-x w-full text-sm text-gray-400 absolute bottom-1">
        <p className="flex-1 p-1">{`${"6"} pieces`}</p>
      </div> */}
    </div>
  );
};

export default DashboardIkosoraSalesCard;
