import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Skeleton,
  Space,
  Popover,
  Drawer,
  Table,
  Tabs,
  Button,
} from "antd";
import { HiOutlinePlus } from "react-icons/hi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { ReactComponent as DeleteSvg } from "../../../../assets/icons/deleteicon.svg";
import { ReactComponent as EditSvg } from "../../../../assets/icons/editicon.svg";
import SettingButton from "../SettingButton";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import BusinessProductModal from "../../../../components/Modals/NewBussiProductModal";
import DeleteModal from "../../../../components/Modals/DeleteModal";
import { CreateNewDiscount } from "../../../../components/forms/createNewDiscount";
import {
  getAllDiscountAction,
  updateDiscountAction,
} from "../../../../store/discount/actions";
import { ReactComponent as AgentCount } from "../../../../assets/icons/Icon.svg";
import {
  getAllShopAction,
  getOneChanneltAction,
  getPricelistItemAction,
} from "../../../../store/channel/actions";
import { GrView } from "react-icons/gr";
import TabPane from "antd/es/tabs/TabPane";
import { getDefaultCurrencyCode } from "../../../../utils/helper";
import { getActivePrice } from "../../../../utils/converter";

interface Shop {
  _id: string;
  name: string;
  regionId: {
    name: string;
  };
}

interface DiscountData {
  isAllowedToAll: boolean;
  _id: string;
  name: string;
  percentage: number;
  description: any;
  amount: any;
  productPlan: Array<{
    product: {
      model: string;
    };
  }>;
  shop: Shop[];
}

interface RootState {
  auth: {
    token: string;
  };
  discount: {
    isFetching: boolean;
    selected: {
      total: number;
      data: DiscountData[];
    };
  };
  channel: {
    selected: {
      _id: string;
    };
  };
  layout: {
    channelInfo: {
      _id: string;
    };
  };
}

const Discount: React.FC = () => {
  const { auth, discount, channel, layout, company, allShop } = useSelector(
    (state: any) => state
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [dataToUpdate, setDataToUpdate] = useState<DiscountData | null>(null);
  const [dataToDelete, setDataToDelete] = useState<DiscountData | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(15);
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
  const [selectedDiscount, setSelectedDiscount] = useState<any>(null);
  const [popoverVisible, setPopoverVisible] = useState<{
    [key: string]: boolean;
  }>({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth?.token) {
      getOneChanneltAction(layout?.channelInfo?._id, auth?.token)(dispatch);
      getPricelistItemAction(
        auth?.token,
        `?page=${page - 1}&limit=${limit}&type=pos${
          channel?.selected?._id ? `&channel=${channel?.selected?._id}` : ""
        }`
      )(dispatch);
      getAllDiscountAction(
        auth?.token,
        `?channel=${channel?.selected?._id}&limit=${limit}&page=${page - 1}`
      )(dispatch);
    }
  }, [
    auth?.token,
    dispatch,
    channel?.selected?._id,
    limit,
    page,
    layout?.channelInfo?._id,
  ]);

  useEffect(() => {
    if (auth?.token) {
      getAllShopAction(
        auth.token,
        `channel=${channel?.selected?._id}`
      )(dispatch);
    }
  }, [auth?.token, dispatch, channel?.selected?._id]);

  const handlePopoverVisibleChange = (visible: boolean, discountId: string) => {
    setPopoverVisible((prev) => ({ ...prev, [discountId]: visible }));
  };


  const handleCloseModal = () => {
    setIsModalOpen(false);
    setDataToUpdate(null);
  };

  const handleOpenDeleteModal = (values: DiscountData) => {
    setDataToDelete(values);
    setOpenDeleteModal(true);
    setPopoverVisible((prev) => ({ ...prev, [values._id]: false }));
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleDelete = async () => {
    if (dataToDelete?._id) {
      const res = await updateDiscountAction(
        dataToDelete._id,
        auth?.token,
        { isDeleted: true },
        `?`
      )(dispatch);

      if (res) {
        setOpenDeleteModal(false);
        getAllDiscountAction(
          auth?.token,
          `?channel=${channel?.selected?._id}`
        )(dispatch);
      }
    }
  };

 
  const handleOpenModal = (discount: DiscountData | null = null) => {
    setSelectedDiscount(discount);
    setIsModalOpen(true);
    setDataToUpdate(discount);
    setPopoverVisible((prev) => ({ ...prev, [discount?._id || ""]: false }));
  };

  const handleViewDetails = (discount: DiscountData) => {
    setSelectedDiscount(discount);
    setIsDrawerVisible(true);
    setPopoverVisible((prev) => ({ ...prev, [discount._id]: false }));
  };

  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
    setSelectedDiscount(null);
  };

  const productPlanColumns = [
    {
      title: "Model",
      dataIndex: ["product", "model"],
      key: "model",
    },
    {
      title: "Specification",
      dataIndex: ["product", "description"],
      key: "specification",
      render: () => {
        const ram = selectedDiscount?.productPlan.flatMap((product: any) =>
          product?.specification?.find((spec: any) =>
            ["memory (ram)", "ram", "memory"].includes(spec[0].toLowerCase())
          )
        );

        const rom = selectedDiscount?.productPlan.flatMap((product: any) =>
          product?.specification?.find((spec: any) =>
            ["capacity (rom)", "rom", "capacity"].includes(
              spec[0].toLowerCase()
            )
          )
        );

        const ramAndRom =
          ram && rom
            ? `${ram[1]} RAM / ${rom[1]} ROM`
            : ram
            ? `${ram[1]} RAM`
            : rom
            ? `${rom[1]} ROM`
            : "";

        return ramAndRom || "N/A";
      },
    },
    {
      title: (
        <span className="text-poppins text-xs">{`Price  (${getDefaultCurrencyCode(
          company
        )})`}</span>
      ),
      dataIndex: ["product", "price"],
      key: "price",
      render: (text: any, record: any) => getActivePrice(record?.prices),
    },
    {
      title: "Percent",
      dataIndex: "discountPercentage",
      key: "discount",
      render: () => `${selectedDiscount?.percentage || 0}%`,
    },
    {
      title: (
        <span className="text-poppins text-xs">{`Amount  (${getDefaultCurrencyCode(
          company
        )})`}</span>
      ),
      dataIndex: ["product", "price"],
      key: "amount",
      render: (record: any) => {
        return <div>{selectedDiscount?.amount}</div>;
      },
    },
  ];

  const shopColumns = [
    {
      title: "Shop Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Region",
      dataIndex: ["regionId", "name"],
      key: "region",
    },
    {
      title: "Address",
      dataIndex: ["regionId", "address"],
      key: "address",
    }
  ];

  const AllshopColumns = [
    {
      title: "Shop Name",
      dataIndex: "name",
      key: "name",
      render: (_: any, record: any) => {
        return <div>{record.name}</div>;
      },
    },
    {
      title: "Region",
      dataIndex: ["regionId", "name"],
      key: "region",
    },
    {
      title: "Address",
      dataIndex: ["regionId", "address"],
      key: "address",
    },
    {
      title: "Channel Name",
      dataIndex: ["regionId", "channel", "name"],
      key: "channelName",
    },
  ];

  const getAllShopsTableData = () => {
    if (channel?.allShop?.data) {
      return channel.allShop.data.map((shop: any) => ({
        key: shop._id,
        name: shop.name,
        regionId: shop.regionId || {},
      }));
    }
    return [];
  };

  const renderPopoverContent = (discount: any) => (
    <div className="align-start flex flex-col gap-2">
      <div
        className="flex flex-row items-center gap-4 cursor-pointer hover:text-blue-600 w-full"
        onClick={() => handleOpenModal(discount)}
      >
        <div>
          <EditSvg className="text-sm" />
        </div>
        <div className=" text-sm">Edit</div>
      </div>
      <div
        className="flex flex-row items-center gap-4 cursor-pointer hover:text-blue-600 w-full"
        onClick={() => handleOpenDeleteModal(discount)}
      >
        <div>
          <DeleteSvg className="text-sm" />
        </div>
        <div className=" text-sm">Delete</div>
      </div>
      <div
        className="flex flex-row items-center gap-4 cursor-pointer hover:text-blue-600 w-full"
        onClick={() => handleViewDetails(discount)}
      >
        <div>
          <GrView className="text-sm text-gray-500" />
        </div>
        <div className=" text-sm">View</div>
      </div>
    </div>
  );


  return (
    <div className="text-[#030229]">
      <div className="flex justify-between mb-3 mr-4">
        <div></div>
        <Space>
          <SettingButton
            btnName="New Discount"
            btnBgColor="[#605BFF]"
            textColor="white"
            icon={<HiOutlinePlus size={20} />}
            onClick={() => handleOpenModal()}
          />
        </Space>
      </div>
      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={discount?.selected?.total}
        count={Math.ceil(discount?.selected?.total / limit)}
      >
        <div className="flex flex-wrap gap-4">
          {discount?.isFetching ? (
            <>
              <Card style={{ width: "300px", marginTop: 16 }}>
                <Skeleton loading={loading} active></Skeleton>
              </Card>
              <Card style={{ width: "300px", marginTop: 16 }}>
                <Skeleton loading={loading} active></Skeleton>
              </Card>
            </>
          ) : (
            discount?.selected?.data?.map((el: any) => (
              <Card
                key={el._id}
                className="shadow-sm hover:shadow-md transition-shadow duration-300  font-poppins overflow-x-auto w-[350px]  h-[10rem]"
              >
                <div className="flex flex-col">
                  <div className="flex justify-between items-center ">
                    <h2 className="text-lg font-poppins">{el.name}</h2>
                    <div>
                      <Popover
                        content={() => renderPopoverContent(el)}
                        trigger="click"
                        placement="bottomRight"
                        visible={popoverVisible[el._id]}
                        onVisibleChange={(visible) =>
                          handlePopoverVisibleChange(visible, el._id)
                        }
                      >
                        <BsThreeDotsVertical className="text-[#030229] cursor-pointer" />
                      </Popover>
                    </div>
                  </div>
                  <div className="text-sm font-poppins  pt-3">
                    <span className="text-gray-600">Description:</span>{" "}
                    {el?.description}
                  </div>
                  <div className="text-sm font-poppins  pt-3">
                    <span className="text-gray-600">Discount:</span>
                    {el.discountType === "percent" ? (
                      <>{el?.percentage}%</>
                    ) : (
                      <>{`${getDefaultCurrencyCode(company)}${el?.amount}`}</>
                    )}
                  </div>
                </div>
              </Card>
            ))
          )}
        </div>
      </ScrollableFrame>

      <BusinessProductModal
        width={1000}
        cancel={handleCloseModal}
        isModalOpen={isModalOpen}
        content={
          <CreateNewDiscount
            onCancel={handleCloseModal}
            dataToUpdate={dataToUpdate}
            isModalOpen={isModalOpen}
          />
        }
        setDataToUpdate={setDataToUpdate}
      />
      <DeleteModal
        visible={openDeleteModal}
        onOk={handleDelete}
        onCancel={handleCloseDeleteModal}
        itemName={dataToDelete?.name}
        isLoading={discount?.isFetching}
      />

      <Drawer
        placement="right"
        width={720}
        visible={isDrawerVisible}
        onClose={handleDrawerClose}
        closable={false}
        headerStyle={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        title={
          <div className="flex justify-between items-center w-full">
            <span className="text-lg font-semibold">Discount Details</span>
            <span
              className="cursor-pointer text-lg"
              onClick={handleDrawerClose}
            >
              ×
            </span>
          </div>
        }
      >
        {selectedDiscount && (
          <div className="flex flex-col h-full">
            <div className="shadow-sm p-4 rounded-lg mb-4 h-[130px]">
              <div className="flex flex-row justify-between w-full">
                <h2 className="text-lg font-poppins mb-2">
                  {selectedDiscount?.name}
                </h2>
                <p className="text-md font-poppins  mb-2">
                  {selectedDiscount?.percentage}%
                </p>
              </div>
              <div className="text-lg font-poppins mb-2">Description: </div>
              <p className="text-md text-gray-600">
                {selectedDiscount?.description}
              </p>
            </div>
            <div className="shadow-sm p-4 rounded-lg mb-4 h-[130px] flex flex-col items-center justify-between">
              <div className="flex flex-row justify-between w-full">
                <div className="flex flex-row items-center">
                  <div className="rounded-full w-[2.6rem] h-[2.6rem] pl-2 pt-2 mt-2">
                    <AgentCount />
                  </div>
                  <div className="ml-4 pt-4">
                    <p className="text-sm text-gray-700">
                      Total Customer Purchased by Discount
                    </p>
                  </div>
                </div>
                <div>
                  <Button className="text-[#605BFF]  text-sm cursor-pointer pt-4 flex flex-col">
                    <div className="pb-4 pt-4">View Reports</div>
                    <div></div>
                  </Button>
                </div>
              </div>
              <div className="flex flex-row justify-between w-full">
                <div className="flex flex-row items-center">
                  <div className="rounded-full w-[2.6rem] h-[2.6rem] pl-2  mt-2"></div>
                  <div className="ml-4">
                    <div className="text-lg">35</div>
                    <div className="text-sm text-gray-600 text-md">
                      Increased By
                      <span className="text-[#57C38A] text-md"> 2.56%</span>
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Plans" key="1">
                <Table
                  dataSource={selectedDiscount?.productPlan}
                  columns={productPlanColumns}
                  rowKey="_id"
                  pagination={false}
                />
              </TabPane>
              <TabPane tab="Shops" key="2">
                <Table
                  dataSource={
                    selectedDiscount?.isAllowedToAll
                      ? getAllShopsTableData()
                      : selectedDiscount?.shop
                  }
                  columns={
                    selectedDiscount?.isAllowedToAll
                      ? AllshopColumns
                      : shopColumns
                  }
                  rowKey="_id"
                  pagination={false}
                />
              </TabPane>
            </Tabs>
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default Discount;
