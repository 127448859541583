import React, { useEffect } from "react";
import { Card, Table, Skeleton } from "antd"; // Import Skeleton
import { getOneShopStockAction } from "../../../../store/pos/actions";
import { useDispatch, useSelector } from "react-redux";

const ItemList = (props: any) => {
  const { pos, tracking } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const columns = [
    {
      title: "Items",
      dataIndex: "item",
      key: "item",
    },
    {
      title: "QTY",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
    },
  ];

  const data =
    tracking.selected?.data?.productList?.flatMap(
      (stockItem: any, index: number) => ({
        key: index.toString(),
        item: stockItem?.product?.product?.model,
        quantity: stockItem?.quantity,
        weight: "N/A",
      })
    ) || [];

  return (
    <Card className="p-6 rounded-lg shadow-sm w-full  h-full">
      <h3 className="text-[#030229] text-lg pb-4">Item List</h3>

      {pos.isFetching ? (
        <Skeleton active paragraph={{ rows: 4 }} />
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          bordered={false}
          className="w-full"
          loading={tracking.isFetcching}
        />
      )}
    </Card>
  );
};

export default ItemList;
