import React from "react";
import { useState, useEffect } from "react";
import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { Button } from "antd";
import { DraftEditor } from "../../../../apps/knowledge/components/DraftEditor";
import {
  createConditionsAction,
  updateConditionsAction,
} from "../../../../store/terms_conditons/actions";
import { useDispatch, useSelector } from "react-redux";

const CreateTerms = (props: any) => {
  const { auth, conditions } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [propTextState, setPropTextState] = useState(EditorState.createEmpty());
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const resetForm = () => {
    setTitle("");
    setContent("");
    setPropTextState(EditorState.createEmpty());
  };

  const submitTermsAndConditions = async () => {
    if (props?.updateData) {
      await updateConditionsAction(auth.token, props?.updateData?._id, {
        title: title,
        descriptions: content,
      })(dispatch);
    } else {
      await createConditionsAction(auth.token, {
        title: title,
        descriptions: content,
      })(dispatch);
    }
    props.onCancel();
    resetForm();
  };
  useEffect(() => {
    if (props.updateData) {
      let text = convertFromHTML(props.updateData.descriptions);
      setPropTextState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(text.contentBlocks, text.entityMap)
        )
      );
      setContent(props.updateData.descriptions);
      setTitle(props.updateData.title);
    }
  }, [props.updateData]);

  return (
    <div className="xflex xflex-col xjustify-center xitems-center">
      <div className="flex flex-col justify-center items-center w-[100%]">
        <div className="mb-4 w-[90%]">
          <label className="text-base font-medium text-gray-900 mb-2">
            Title
          </label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            id="title"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none block w-full p-2.5"
            placeholder="Add Title ..."
            required
          />
        </div>
        <div className="mb-4 w-[90%]">
          <label className="text-base font-medium text-gray-900 mb-2">
            Body
          </label>
          <DraftEditor
            placeholder={content}
            editorState={propTextState}
            setEditorState={setPropTextState}
            setContent={setContent}
          />
        </div>
        <Button
          onClick={submitTermsAndConditions}
          loading={conditions?.isFetching}
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm sm:w-auto px-5 my-3 text-center"
        >
          {`${props?.updateData ? "Update" : "Submit"}`}
        </Button>
      </div>
    </div>
  );
};
export default CreateTerms;
