import { notification } from "antd";
import { ebmActions } from ".";
import { useSelector } from "react-redux";
import {
  addItemService,
  getAllCustomerService,
  getAllEBMItemsService,
  getAllItemsService,
  getAllProductAttributesService,
  getAllProducts,
  getAllStockStatuService,
  getEbmOverviewService,
  getEbmSelectOptionServices,
  getImportSumaryService,
  getSalesSummaryService,
} from "./service";

export const getEbmOverviewAction = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(ebmActions.setIsFetching(true));
      const res = await getEbmOverviewService(token, query);
      if (res?.status === 200) {
        dispatch(ebmActions.setEbmOverview(res?.data));
        dispatch(ebmActions.setIsFetching(false));
      }
      dispatch(ebmActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      return null;
    }
  };
};
export const getAllItemsAction = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(ebmActions.setIsFetching(true));
      const res = await getAllItemsService(token, query);
      if (res?.status === 200) {
        dispatch(ebmActions.setAllItems(res));
        dispatch(ebmActions.setIsFetching(false));
      }
      dispatch(ebmActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      return null;
    }
  };
};
export const getAllEbmItemsAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(ebmActions.setIsFetching(true));
      const res = await getAllEBMItemsService(token, query);
      if (res?.status === 200) {
        dispatch(ebmActions.seEbmItem(res?.data));
        dispatch(ebmActions.setIsFetching(false));
      }
      dispatch(ebmActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getEbmSelectOptionAction = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(ebmActions.setIsFetching(true));
      const res = await getEbmSelectOptionServices(token, query);
      if (res?.status === 200) {
        dispatch(ebmActions.setSelectOption(res?.data));
        dispatch(ebmActions.setIsFetching(false));
      }
      dispatch(ebmActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      return null;
    }
  };
};
export const getAllProductsAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(ebmActions.setIsFetching(true));
      const res = await getAllProducts(token, query);
      if (res?.status === 200) {
        dispatch(ebmActions.setAllProducts(res));
        dispatch(ebmActions.setIsFetching(false));
      }
      dispatch(ebmActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getAllProductsAttributesAction = (
  token: string,
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(ebmActions.setIsFetching(true));
      const res = await getAllProductAttributesService(token, query);
      if (res?.status === 200) {
        dispatch(ebmActions.setAttributes(res?.data));
        dispatch(ebmActions.setIsFetching(false));
      }
      dispatch(ebmActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const addItemAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(ebmActions.setIsFetching(true));
      const res = await addItemService(data, token);
      const resAll = await getAllEBMItemsService(token, "");
      if (res?.status === 201) {
        dispatch(ebmActions.setNewItem(res));
        dispatch(ebmActions.seEbmItem(resAll));
        dispatch(ebmActions.setIsFetching(false));
        notification.success({ message: "Item Added Successfully" });
      }
      dispatch(ebmActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getAllStockStatusAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(ebmActions.setIsFetching(true));
      const res = await getAllStockStatuService(token, query);
      if (res?.status === 200) {
        dispatch(ebmActions.setStockStatus(res));
        dispatch(ebmActions.setIsFetching(false));
      }
      dispatch(ebmActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getAllCustomerAction = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(ebmActions.setIsFetching(true));
      const res = await getAllCustomerService(token, query);
      if (res?.status === 200) {
        dispatch(ebmActions.setAllCustomer(res?.data));
        dispatch(ebmActions.setIsFetching(false));
      }
      dispatch(ebmActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      return null;
    }
  };
};
export const getImportSumaryAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(ebmActions.setIsFetching(true));
      const res = await getImportSumaryService(token, query);
      if (res?.status === 200) {
        dispatch(ebmActions.setImportSummary(res));
        dispatch(ebmActions.setIsFetching(false));
      }
      dispatch(ebmActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getSaleSumaryAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(ebmActions.setIsFetching(true));
      const res = await getSalesSummaryService(token, query);
      if (res?.status === 200) {
        dispatch(ebmActions.setSaleStats(res));
        dispatch(ebmActions.setIsFetching(false));
      }
      dispatch(ebmActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
