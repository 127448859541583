import React, { useState } from "react";
import { Col, Divider, Drawer, Row, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateRepairStatusAction } from "../../../store/repair/actions";
import { useNavigate } from "react-router-dom";
import RepairModal from "../../../components/Modals/RepairModel";
import DelivererIn from "../components/DelivererIn";
import DelivererOut from "../components/DelivererOut";
import DeviceIssues from "../components/DeviceIssues";

interface DescriptionItemProps {
  title: string;
  content?: React.ReactNode;
}

const DescriptionItem = ({ title, content }: DescriptionItemProps) => (
  <div className="flex items-center justify-between w-full">
    <p className="font-semibold text-sm w-2/5">{title}</p>
    <span className="w-3/5">{content}</span>
  </div>
);

const RepairDrower: React.FC<{
  open: boolean;
  onClose: any;
  data: any;
  dataToUpdate: any;
  setDataToUpdate: any;
}> = ({ open, onClose, data, setDataToUpdate, dataToUpdate }) => {
  const { auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openModelOnSite, setOpenModelOnSite] = useState(false);
  const [openOfficeModel, setOpenOfficeModel] = useState(false);
  const [checked, setChecked] = useState<any>([]);

  const dataId = data?.key || data?._id;

  const showModalOnSite = () => {
    setOpenModelOnSite(true);
  };

  const showModalOffice = () => {
    setOpenOfficeModel(true);
  };

  const handleCancelOnSite = () => {
    setOpenModelOnSite(false);
  };

  const handleCancelOffice = () => {
    setOpenOfficeModel(false);
  };

  const handlerFixedOnSite = async () => {
    await updateRepairStatusAction(auth.token, dataId, {
      status: "repairing",
      repairedAt: "shop",
    })(dispatch);
    setOpenModelOnSite(false);
    onClose();
    navigate("/repairs");
  };

  const handlerFixedOnOffice = async () => {
    await updateRepairStatusAction(auth.token, dataId, {
      status: "send-to-office",
      repairedAt: "office",
    })(dispatch);
    setOpenOfficeModel(false);
    onClose();
    navigate("/repairs");
  };

  return (
    <>
      <Drawer
        width={500}
        placement="right"
        closable={false}
        onClose={() => {
          onClose();
          setChecked([]);
        }}
        open={open}
        style={{ color: "black" }}
      >
        <p className="font-bold text-lg" style={{ marginBottom: 10 }}>
          Customer
        </p>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Name"
              content={data?.customer ?? data?.cartItemId?.cart?.customer?.name}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="National ID"
              content={
                data?.data?.cartItemId?.cart?.customer?.nid ??
                data?.cartItemId?.cart?.customer?.nid
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Telephone"
              content={
                data?.data?.cartItemId?.cart?.customer?.phone ??
                data?.cartItemId?.cart?.customer?.phone
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Email"
              content={
                data?.data?.cartItemId?.cart?.customer?.email ??
                data?.cartItemId?.cart?.customer?.email
              }
            />
          </Col>
        </Row>
        <Divider />
        <p
          className="site-description-item-profile-p font-bold text-lg"
          style={{ marginBottom: 10 }}
        >
          Device
        </p>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Type"
              content={
                data?.data?.cartItemId?.shopStockItem?.requestedItem?.product
                  ?.product?.type ??
                data?.cartItemId?.shopStockItem?.requestedItem?.product?.product
                  ?.type
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Brand"
              content={
                data?.data?.cartItemId?.shopStockItem?.requestedItem?.product
                  ?.product?.brand ??
                data?.cartItemId?.shopStockItem?.requestedItem?.product?.product
                  ?.brand
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Model"
              content={
                data?.model ??
                data?.cartItemId?.shopStockItem?.requestedItem?.product?.product
                  ?.model
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Specification"
              content={
                data?.specifications ??
                data?.cartItemId?.shopStockItem?.requestedItem?.product?.specification
                  ?.map((d: any) => d[1] && `${d[0]}:${d[1]}`)
                  ?.join(", ")
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="IMEI"
              content={
                data?.data?.cartItemId?.warehouseProduct?.serialNumber ??
                data?.cartItemId?.warehouseProduct?.serialNumber
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Price"
              content={
                data?.data?.cartItemId?.payment?.amount?.toLocaleString() ??
                data?.cartItemId?.payment?.amount?.toLocaleString()
              }
            />
          </Col>
        </Row>
        {data?.data?.cartItemId?.payment?.extendedWarranty > 0 && (
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="Extended Warranty"
                content={data?.data?.cartItemId?.payment?.extendedWarranty}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Service Center"
              content={
                data?.cartItemId?.shopStockItem?.shop?.name ?? data?.shop
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Agent"
              content={
                data?.data?.cartItemId?.cart?.createdBy?.names ??
                data?.cartItemId?.createdBy?.names
              }
            />
          </Col>
        </Row>
        <Divider />
        <div className="bg-red-50 p-3">
          <p
            className="site-description-item-profile-p font-bold text-lg"
            style={{ marginBottom: 10 }}
          >
            Issues
          </p>
          <Row>
            <Col span={24}>
              <div className="flex flex-col">
                <DeviceIssues
                  issues={data?.data}
                  setDataToUpdate={setDataToUpdate}
                  dataToUpdate={dataToUpdate}
                  setChecked={setChecked}
                  checked={checked}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="Comment"
                content={data?.data?.comment ?? data?.comment}
              />
            </Col>
          </Row>
        </div>

        <Divider />
        {["done", "completed", "transit-in", "transit-out"]?.includes(
          data?.status
        ) && <DelivererIn data={data?.data?.delivererIn} />}

        {["done", "completed", "transit-out"]?.includes(data?.status) && (
          <DelivererOut data={data?.data?.delivererOut} />
        )}

        {data?.status === "pending" && (
          <div className="space-x-4">
            <Button
              style={{ color: "white" }}
              className="bg-gray-400 border-none outline-none"
              onClick={showModalOnSite}
            >
              Fixed on Site
            </Button>
            <Button
              style={{ color: "#212121" }}
              className="bg-orange-300 border-none outline-none"
              onClick={showModalOffice}
            >
              Fixed on Office
            </Button>
          </div>
        )}
      </Drawer>
      <RepairModal
        title="On-Site Repair"
        openModel={openModelOnSite}
        onOk={handlerFixedOnSite}
        onCancel={handleCancelOnSite}
      />
      <RepairModal
        title="Repaired At Office"
        openModel={openOfficeModel}
        onOk={handlerFixedOnOffice}
        onCancel={handleCancelOffice}
      />
    </>
  );
};

export default RepairDrower;
