import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Badge, Space } from "antd";
import { searchValue } from "../../utils/setColor";
import DeleteModal from "../Modals/DeleteModal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteQuotToSupplierAction,
  deleteQuotationAction,
} from "../../store/quotation/actions";
import { ReactComponent as DeleteIcon } from '../../assets/icons/deleteicon.svg'

const Component = (props: any) => {
  const [visible, setVisible] = useState(false);
  const [isHasCancelled, setIsHasCancelled] = useState(false);
  const [itemToDelete, setItemToDelete] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { quotation, auth } = useSelector((state: any) => state);

  const deleteModal = (value: any) => {
    if (value?.quotation?.status?.toLowerCase() === "cancelled") {
      setIsHasCancelled(true);
    }
    setItemToDelete(value?._id);
    setVisible(true);
  };
  const handleCancel = () => {
    setVisible(false);
  };

  const deleteRequest = async () => {
    if (isHasCancelled) {
      await deleteQuotToSupplierAction(
        auth?.token,
        itemToDelete,
        {
          isDeleted: true,
        },
        "?limit=15&page=0"
      )(dispatch);
      setItemToDelete("");
      setIsHasCancelled(false);
    } else {
      await deleteQuotationAction(
        auth?.token,
        props?.data?.quotation?._id,
        `?isDraft=true&status=draft`
      )(dispatch);
    }
    setVisible(false);
  };

  const data = {
    name: props?.data?.supplier?.name || "New RFQ",
    status: props.data?.status || props.data?.quotation?.status,
    id: props.data?.quotation?.quotationId,
    item: props.data?.quotation?.listOfProducts?.length,
    createdBy:
      props.data?.createdBy?.names || props.data?.quotation?.createdBy?.names,
    createdAt: props.data?.createdAt || props.data?.quotation?.createdAt,
  };
  return (
    <div className="relative text-[#030229]">
      <Badge.Ribbon
        text={
          <span
            style={{
              fontSize: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              fontWeight: 400,
              paddingTop: "1.5px",
            }}
          >
            {data?.status}
          </span>
        }
        color={searchValue(data?.status)}
        style={{ width: "6rem", padding: "4px 1px" }}
      >
        <div
          onClick={() => {
            navigate(
              `/${props?.route === "purchase" ? "purchase" : "inventory"}/rfq/${data.id
              }${props?.data?._id ? `/${props?.data?._id}` : ""}`
            );
          }}
          className="bg-white relative w-[360px] h-[154px] border-t-[0.5px] border-t-[rgba(217,219,233,0.5)] rounded-[10px] shadow-[1px_3px_16px_0px_rgba(0,0,0,0.07)] text-[#030229] p-4 px-2"
        >
          <div className="flex flex-col gap-1">
            <h1 className="font-normal text-[14px]  w-[18.3rem]">
              {data?.name?.toUpperCase()}
            </h1>
            <p className="text-[13px] font-normal text-[#605BFF]">
              {data?.id || "N/A"}
            </p>
          </div>
          <div className="flex flex-col gap-[.15px] absolute bottom-3 left-4">
            <Space>
              <p className="opacity-50 font-normal text-[13px]">Done By :</p>
              <p className=" font-normal text-[13px] capitalize">
                {data?.createdBy}
              </p>
            </Space>
            <Space>
              <p className="opacity-50 font-normal text-[13px]">Date :</p>
              <p className=" font-normal text-[13px] capitalize">
                {data?.createdAt.slice(0, 10)}
              </p>
            </Space>
          </div>
        </div>
        <div className="absolute bottom-3 right-4">
          <Space>
            <p className=" font-normal text-[13px] capitalize">
              {data?.item} Items
            </p>
            {["draft", "cancelled"].includes(
              props?.data?.quotation?.status?.toLowerCase()
            ) && (
                <div className="gabsolute hbottom-1 gright-3">
                  <IconButton
                    aria-label="delete"
                    size="small"
                  >
                    <DeleteIcon
                      fontSize="inherit"
                      onClick={() => {
                        // setRowToDelete(record.key);
                        deleteModal(props?.data);
                      }}
                    />
                  </IconButton>
                  <DeleteModal
                    visible={visible}
                    onOk={deleteRequest}
                    isLoading={quotation?.isFetching}
                    onCancel={handleCancel}
                    itemName="Stock Item"
                  />
                </div>
              )}
          </Space>
        </div>
      </Badge.Ribbon>
    </div>
  );
};
export default Component;
