import { Stack, Skeleton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { useDispatch, useSelector } from "react-redux";
import NosaleProductGrid from "../../../../components/grids/POS/nosale/NosaleProductGrid";
// import { PaginationControlled } from "../../../../components/filters/Paginate";
import AddNoSaleProduct from "../../../../components/forms/NosaleProductForm";
import { getAllNosaleAction } from "../../../../store/pos/actions";
import { getActiveShop } from "../../../../utils/converter";

const PageView = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { auth, pos, shop } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [page, setPage] = useState(1);
  // const [limit, setLimit] = useState(15);

  const selectedShopId =
    getActiveShop(auth?.user?.shop?.assigned)[0]?.shop?._id ??
    shop?.selected?._id;

  useEffect(() => {
    auth?.token &&
      getAllNosaleAction(auth?.token, `?shopId=${selectedShopId}`)(dispatch);
  }, [auth?.token, dispatch]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const goBack = () => {
    navigate(-1);
  };
  return (
    <Stack spacing={1}>
      <div className="text-black pt-2 pb-2">
        <DetailsHeaderActionBar
          pageName="Pos"
          title="No Sale"
          goBack={goBack}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleCancel={handleCancel}
          item="No Sale"
          modelTitle="Add No Sale"
          showButton
          modalComponent={<AddNoSaleProduct onCancel={handleCancel} />}
        />
      </div>
      {/* <ProductFilter /> */}

      <Stack spacing={1}>
        {pos?.isFetching && (
          <div className="flex flex-wrap gap-4 justify-start items-start">
            {[1, 2, 3, 4, 1, 2, 3, 4, 2, 3, 4, 1, 2, 3, 4].map((d: any) => (
              <div className="w-80">
                <Skeleton animation="wave" />
                <Skeleton variant="rectangular" height={150} />
              </div>
            ))}
          </div>
        )}
        {!pos.isFetching && (
          <div className="flex flex-wrap gap-4 justify-start items-start mt-5">
            {pos?.nosale?.status === 200 && <NosaleProductGrid />}
          </div>
        )}
      </Stack>
    </Stack>
  );
};

export default PageView;
