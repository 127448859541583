import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { Button } from "@mui/material";
import PurchaseOrderConfirmTable from "../../../components/tables/PurchaseOrderConfirmTable";
import { useDispatch, useSelector } from "react-redux";
import { getAllSelectedProformasAction } from "../../../store/proforma/actions";
import { getAllQuotationsAction } from "../../../store/quotation/actions";
import { createPurchaseOrderAction } from "../../../store/purchase/actions";
import { LoadingButton } from "@mui/lab";

const PurchaseOrderConfirm = () => {
  const { po_id } = useParams();
  const { quotation, auth, purchase } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    auth?.token && getAllSelectedProformasAction(auth?.token, po_id)(dispatch);
    auth?.token &&
      getAllQuotationsAction(auth?.token, `?_id=${po_id}`)(dispatch);
  }, [auth?.token, dispatch, po_id]);

  const handleConfirm = async () => {
    auth?.token &&
      (await createPurchaseOrderAction(auth?.token, po_id as string, {
        isNotify: true,
        notification: {
          action: "PO Created",
          role: ["admin", "finance", "finance-manager", "inventory"],
          message: `New Purchase Order ${quotation?.all?.data[0]?.quotationId} has been created`,
          title: "Purchase Order",
        },
      })(dispatch));
    navigate("/purchase/po");
  };

  return (
    <div className="text-[#030229] p-5 mt-3 space-y-2">
      <DetailsHeaderActionBar
        pageName="Orders"
        title={"Purchase Order | RFQ " + quotation?.all?.data[0]?.quotationId}
      />
      <div className="p-5 bg-white rounded-lg space-y-6">
        <div className="flex justify-between font-medium space-y-2">
          <div>
            <p className="text-[1.3rem] my-2">Purchase Order</p>
            <p className="text-[#605BFF] text-lg font-normal">
              {quotation?.all?.data[0]?.quotationId}
            </p>
          </div>
          <div>
            <div className="flex items-center justify-center text-xs w-fit p-2 bg-slate-200 rounded-md">
              <p className="px-2">Status</p>
              <span className="py-1 px-3 text-white text-xs bg-gray-400 rounded-sm">
                {"P.O Draft"}
              </span>
            </div>
          </div>
        </div>
        <PurchaseOrderConfirmTable />
        <div className="space-x-2">
          <LoadingButton
            style={{
              backgroundColor: "transparent",
              color: "green",
              borderBlockColor: "green",
              textTransform: "capitalize",
              padding: "2px 6px",
              border: "1px solid green",
            }}
            variant="contained"
            onClick={handleConfirm}
            loading={quotation?.isFetching || purchase?.isFetching}
            disabled={quotation?.isFetching}
          >
            Confirm PO
          </LoadingButton>
          <Button
            style={{
              backgroundColor: "transparent",
              color: "red",
              borderBlockColor: "blue",
              textTransform: "capitalize",
              padding: "2px",
              border: "1px solid red",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
            onClick={() => navigate("/purchase/po")}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PurchaseOrderConfirm;
