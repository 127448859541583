import React from 'react';
import noDeliveryImage from './gggg.png'; 

const NoDeliveryOrderSelected = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-50">
      <div className="w-64 h-64 mb-6">
        <img 
          src={noDeliveryImage} 
          alt="No delivery order" 
          className="w-full h-full object-contain"
        />
      </div>
      <h2 className="text-xl font-semibold text-gray-800 mb-2">No Delivery Order Selected</h2>
      <p className="text-sm text-gray-500">Select one of the delivery order to see more details</p>
    </div>
  );
};

export default NoDeliveryOrderSelected;
