import { Form, Input, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import {
  getAllCommissionTransactionAction,
  updateAllCommissionTransactionAction
} from "../../store/channel/actions";

const PayCommissionForm = (props: any) => {
  const { channel, auth } = useSelector((state: any) => state);
  const [form] = Form.useForm();
  const [totalAmount, setTotalAmount] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const transactionId = props?.data?.map((el: any) => el?._id);
  useEffect(() => {
    const newTotalAmount = props?.data?.reduce(
      (accumulator: any, item: any) => {
        return accumulator + item?.cartItem?.commissionFee;
      },
      0
    );
    setTotalAmount(newTotalAmount);
  }, [props.data]);
  const dispatch = useDispatch();
  useEffect(() => {
    form.setFieldsValue({
      totalAmount: totalAmount,
      accountNumber:
        channel?.allCommissionTransaction?.data?.[0]?.commissionProfile?.account
    });
  }, [totalAmount, channel?.allCommissionTransaction?.data[0]?.commissionProfile
  ?.account, form]);
  const onFinish = async (values: any) => {
    auth?.token &&
      (await updateAllCommissionTransactionAction(
        { ...values, commissionTransactions: transactionId, status: "paid" },
        auth?.token
      )(dispatch));
    auth?.token &&
      (await getAllCommissionTransactionAction(
        auth?.token,
        `?shop=${props?.info?.data?.data?._id}&commissionProfile=${
          props?.info?.data?.data?.key
        }&status=pending&page=${page - 1}&limit=${limit}`
      )(dispatch));
    form.resetFields();
    props?.onCancel();
  };
  return (
    <div>
      <Form
        form={form}
        name="basic"
        className="w-full"
        initialValues={{
          accountNumber:
            channel?.allCommissionTransaction?.data[0]?.commissionProfile
              ?.account,
          totalAmount: totalAmount
        }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          name="accountNumber"
          initialValue={ channel?.allCommissionTransaction?.data[0]?.commissionProfile
            ?.accounts}
          label={
            <span className="text-[#030229] pl-1 text-sm font-normal">
              Account Number
            </span>
          }
          className="w-full"
        >
          <Input
            type="number"
            className="pl-4 h-[42px] w-[100%] text-base"
            disabled
          />
        </Form.Item>
        <Form.Item
          name="totalAmount"
          label={
            <span className="text-[#030229] pl-1 text-sm font-normal">
              Amount due
            </span>
          }
          className="w-full"
        >
          <Input
            type="number"
            value={totalAmount}
            className="pl-4 h-[42px] w-[100%] text-base"
            disabled
          />
        </Form.Item>

        <Form.Item>
          <div>
            <button
              type="submit"
              className={` border w-full bg-[#605BFF] py-2 text-white text-center font-medium text-base  rounded-md `}
            >
              {channel.isFetching ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 24, color: "white" }}
                      color="white"
                      spin
                    />
                  }
                />
              ) : (
                "Comfirm Payment"
              )}
            </button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PayCommissionForm;
