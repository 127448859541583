import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Radio, Tooltip } from "antd";
import LoadingButton from "@mui/lab/LoadingButton";
import { Select, Form, InputNumber, Divider } from "antd";
import {
  createSubOrderItemAction,
  getOneSubsOrderAction,
} from "../../../../store/subscription/order/actions";
import {
  getAllChannelAction,
  getOneChanneltAction,
  getSubscriptionPlansAction,
} from "../../../../store/channel/actions";
import { updateOneAccountAction } from "../../../../store/account/actions";
import { handleSetSubscriptionDealSteps } from "../../../../store/layout/actions";
import DealFiltering from "../../../../components/filters/DealFiltering";
import CurstomeCardSkeleton from "../../../../components/skeleton/CurstomeCardSkeleton";
import { useParams } from "react-router-dom";

import { borderColors } from "../../../../utils/setColor";
import { PlanCard } from "./component/ProductCard";
import { WarningOutlined } from "@ant-design/icons";
import { getActivePrice } from "../../../../utils/converter";

const { Option } = Select;

const PageView = (props: any) => {
  const [form] = Form.useForm();
  const { auth, orders, channel, account } = useSelector((state: any) => state);

  const dispatch = useDispatch();
  const { dealId } = useParams();

  const [isOrderAdded, setIsOrderAdded] = useState(false);
  const [selectePlan, setSelectePlan] = useState(
    orders?.selected?.list?.at(0)?.planInfo?.planType || "monthly"
  );
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [selectedItem, setSelectedItem] = useState<any | null>(null);
  const [selectedPriceList, setSelectedPriceList] = useState(null);

  const isItemAlreadySelected = (arr: any[], id: string) => {
    if (!Array?.isArray(arr) || arr?.length === 0) {
      return false;
    }
    return arr?.some((item) => item?.planInfo?.reference === id);
  };

  const hasAdded = isItemAlreadySelected(
    orders?.selected?.list,
    selectedItem?._id
  );

  const orderId = orders?.new?.data?._id ?? orders?.selected?._id;

  function convertArrayToString(data: any) {
    return data.map((item: any) => item.join(": ")).join(", ");
  }
  const onFinish = async (values: any) => {
    const payment =
      orders?.selected?.account?.billingType === "net"
        ? getActivePrice(selectedItem?.pricelistItem?.prices)
        : selectePlan === "dayly"
        ? selectedItem?.dailyInstalment
        : selectePlan === "weekly"
        ? selectedItem?.weeklyInstalment
        : selectedItem?.monthlyInstalment;

    const allowance = `Call: ${
      selectedItem?.call?.length > 0 ? selectedItem?.call.join(", ") : ""
    }, 
  Bundle: ${
    selectedItem?.bundle?.length > 0 ? selectedItem?.bundle.join(", ") : ""
  }, 
  SMS: ${selectedItem?.sms?.length > 0 ? selectedItem?.sms.join(", ") : ""}`;

    const planInfo = {
      model: selectedItem?.pricelistItem?.product?.model,
      specification: convertArrayToString(
        selectedItem?.pricelistItem?.specification
      ),
      allowance,
      reference: selectedItem?._id,
      amount: payment,
      planType:
        orders?.selected?.account?.billingType !== "net"
          ? selectePlan
          : "dayly",
      initialPayment: selectedItem?.initialPayment,
    };

    if (auth?.token) {
      await createSubOrderItemAction(auth.token, {
        ...values,
        order: dealId,
        product: selectedItem?.pricelistItem?._id,
        amount: payment,
        planInfo,
      })(dispatch);
      await updateOneAccountAction(
        auth?.token,
        orders?.selected?.account?._id,
        {
          duration:
            orders?.selected?.account?.billingType === "net"
              ? selectedItem?.numberOfDays
              : selectedItem?.duration,
          planType:
            orders?.selected?.account?.billingType !== "net"
              ? selectePlan
              : "dayly",
        }
      )(dispatch);
      await getOneSubsOrderAction(auth?.token, orderId)(dispatch);
      form?.resetFields();
      setSelectedItem(null);
      setSelectedIndex(null);
      setIsOrderAdded(true);
    }
  };

  useEffect(() => {
    if (auth?.token) {
      getAllChannelAction(auth?.token)(dispatch);
    }
  }, [auth?.token, dispatch]);

  const handleChangeField = async (field: string, values: any) => {
    if (values && orders?.selected?.account?._id && auth?.token) {
      await updateOneAccountAction(
        auth?.token,
        orders?.selected?.account?._id,
        {
          [field]: values,
        }
      )(dispatch);
      await getOneSubsOrderAction(auth?.token, orderId)(dispatch);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      chnnl: orders?.selected?.account?.channel?._id,
      bProduct: orders?.selected?.account?.businessProduct?._id,
      bllngType: orders?.selected?.account?.billingType,
    });
    setTimeout(() => setIsOrderAdded(false), 5000);
  }, [form, orders?.selected?.account, isOrderAdded]);

  useEffect(() => {
    if (orders?.selected?.account?.channel?._id && auth?.token) {
      getOneChanneltAction(
        orders?.selected?.account?.channel?._id,
        auth?.token
      )(dispatch);
    }
  }, [auth?.token, dispatch, orders?.selected?.account?.channel?._id]);

  const handlePrevious = () => {
    handleSetSubscriptionDealSteps(0)(dispatch);
  };
  const handleSelectedPlan = (value: any) => {
    setSelectePlan(value);
  };

  const handleItemClick = (item: any, index: number) => {
    setSelectedIndex(index);
    setSelectedItem(item);
  };

  useEffect(() => {
    if (
      auth?.token &&
      selectedPriceList &&
      orders?.selected?.account?.businessProduct?.name &&
      orders?.selected?.account?.billingType
    ) {
      getSubscriptionPlansAction(
        auth?.token,
        `?businessProductType=${
          orders?.selected?.account?.businessProduct?.name
        }&pricelistItem=${selectedPriceList}&paymentType=${
          orders?.selected?.account?.billingType
        }${
          orders?.selected?.account?.duration &&
          orders?.selected?.account?.billingType !== "net"
            ? `&duration=${orders?.selected?.account?.duration}`
            : ""
        }`
      )(dispatch);
    }
  }, [
    auth?.token,
    dispatch,
    orders?.selected?.account?.billingType,
    orders?.selected?.account?.businessProduct,
    orders?.selected?.account?.businessProduct?.name,
    selectedPriceList,
    orders?.selected?.account?.duration,
  ]);

  const handleSelectPriceList = (value: any) => {
    setSelectedPriceList(value);
    setSelectedItem(null);
    setSelectedIndex(null);
  };

  return (
    <div className="h-full">
      <Form
        form={form}
        name="register"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{}}
        style={{ maxWidth: "100%" }}
        scrollToFirstError
        className="h-full"
      >
        <div className="h-[calc(100%-45px)] overflow-y-auto">
          <div className="flex-1 h-full">
            <div className="gap-x-4 grid grid-cols-3">
              <Form.Item
                name="chnnl"
                label={<span className="font-medium text-sm">Channel</span>}
                style={{ marginBottom: "30px" }}
              >
                <Select
                  showSearch
                  allowClear
                  loading={channel.isFetching || orders?.newOrderIsFetching}
                  disabled={
                    channel.isFetching ||
                    orders?.selected?.list?.length > 0 ||
                    orders?.newOrderIsFetching
                  }
                  style={{ height: 45 }}
                  placeholder="Channels"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").toString().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toString()
                      .toLowerCase()
                      .localeCompare(
                        (optionB?.label ?? "").toString().toLowerCase()
                      )
                  }
                  onChange={(value) => handleChangeField("channel", value)}
                >
                  {channel?.all?.data?.map((channel: any) => {
                    return (
                      <Option key={channel._id} value={channel._id}>
                        {channel?.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name="bProduct"
                label={
                  <span className="font-medium text-sm">Business Product</span>
                }
              >
                <Select
                  showSearch
                  allowClear
                  loading={
                    account.isFetching ||
                    channel.isFetching ||
                    orders?.newOrderIsFetching
                  }
                  disabled={
                    account.isFetching ||
                    channel.isFetching ||
                    orders?.selected?.list?.length > 0 ||
                    orders?.newOrderIsFetching
                  }
                  style={{ height: 45 }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.name ?? "").toString().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.name ?? "")
                      .toString()
                      .toLowerCase()
                      .localeCompare(
                        (optionB?.name ?? "").toString().toLowerCase()
                      )
                  }
                  onChange={(value) =>
                    handleChangeField("businessProduct", value)
                  }
                >
                  {channel?.selected?.services?.map(
                    (item: any, index: number) => {
                      return (
                        <Option
                          key={index}
                          value={item?.businessProduct?._id}
                          className="capitalize"
                        >
                          {item?.businessProduct?.name}
                        </Option>
                      );
                    }
                  )}
                </Select>
              </Form.Item>
              <Form.Item
                name="bllngType"
                label={
                  <span className="font-medium text-sm">Billing Type</span>
                }
              >
                <Select
                  showSearch
                  allowClear
                  loading={
                    account.isFetching ||
                    channel.isFetching ||
                    orders?.newOrderIsFetching
                  }
                  disabled={
                    account.isFetching ||
                    channel.isFetching ||
                    orders?.selected?.list?.length > 0 ||
                    orders?.newOrderIsFetching
                  }
                  style={{ height: 45 }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").toString().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toString()
                      .toLowerCase()
                      .localeCompare(
                        (optionB?.label ?? "").toString().toLowerCase()
                      )
                  }
                  onChange={(value) => handleChangeField("billingType", value)}
                >
                  {["subscription"]?.map((plan: any, index: number) => {
                    return (
                      <Option key={index} value={plan} className="capitalize">
                        {plan}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
            <Divider orientation="center">Select Device</Divider>
            <DealFiltering onSelectedModel={setSelectedModel} />

            <div className="flex flex-col items-end gap-y-2 justify-end pr-1.5">
              <Select
                loading={channel.isFetching}
                disabled={
                  channel.isFetching ||
                  !orders?.selected?.account?.businessProduct?.name ||
                  !orders?.selected?.account?.billingType
                }
                style={{ height: 40, width: 260 }}
                showSearch
                placeholder="Select Product"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")

                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                onChange={handleSelectPriceList}
              >
                {channel?.priceListItems?.data?.map((el: any) => (
                  <Option value={el?._id}>
                    <Tooltip
                      title={el?.specification
                        ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
                        ?.join(", ")}
                    >
                      {el?.product?.model}
                    </Tooltip>
                  </Option>
                ))}
              </Select>
            </div>
            {channel?.subPlans?.data?.length > 0 && (
              <div className="flex items-center justify-between pr-2 py-2 ">
                <span className="font-semibold">Matching Plans</span>
                {orders?.selected?.account?.billingType !== "net" && (
                  <Radio.Group
                    defaultValue={selectePlan}
                    buttonStyle="solid"
                    onChange={(e: any) => handleSelectedPlan(e.target.value)}
                    disabled={orders?.selected?.list?.length > 0}
                  >
                    <Radio.Button value="dayly">Daily</Radio.Button>
                    <Radio.Button value="weekly">Weekly</Radio.Button>
                    <Radio.Button value="monthly">Monthly</Radio.Button>
                  </Radio.Group>
                )}
              </div>
            )}
            {channel?.subPlans?.data?.length > 0 && !channel?.isFetching && (
              <>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pt-4">
                  {channel?.subPlans?.data?.map((plan: any, index: number) => {
                    return (
                      <div
                        className={` relative p-6 cursor-pointer rounded-lg shadow ${
                          selectedIndex === index ? "bg-[#fdeee8]" : ""
                        }`}
                        style={{
                          borderLeftWidth: "4px",
                          borderColor:
                            borderColors[index % borderColors.length],
                        }}
                        onClick={() => handleItemClick(plan, index)}
                      >
                        <PlanCard
                          call={plan?.call?.at(0)}
                          sms={plan?.sms?.at(0)}
                          bundle={plan?.bundle?.at(0)}
                          key={index}
                          model={
                            plan?.name || plan?.pricelistItem?.product?.model
                          }
                          brand={plan?.pricelistItem?.product?.brand}
                          billingType={orders?.selected?.account?.billingType}
                          plan={
                            orders?.selected?.account?.billingType === "net"
                              ? "Overall"
                              : selectePlan
                          }
                          price={
                            orders?.selected?.account?.billingType === "net"
                              ? plan?.initialPayment * plan?.numberOfDays
                              : selectePlan === "dayly"
                              ? plan.dailyInstalment
                              : selectePlan === "weekly"
                              ? plan.weeklyInstalment
                              : plan.monthlyInstalment
                          }
                          upfront={plan.initialPayment}
                          duration={
                            orders?.selected?.account?.billingType === "net"
                              ? `${plan?.numberOfDays ?? "N/A"} Days`
                              : `${plan.duration} Months`
                          }
                          images={plan?.pricelistItem?.product?.images}
                          specification={plan.pricelistItem?.specification}
                        />
                        {hasAdded && (
                          <div className="absolute top-0 left-0 w-full h-full bg-gray-300 bg-opacity-70">
                            <div className="flex flex-col items-center justify-center pt-20">
                              <WarningOutlined className="text-red-600 text-6xl" />
                              <span className="text-gray-950 text-xl font-bold mt-4 capitalize">{`${selectedItem?.pricelistItem?.product?.model} Already added`}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>

                {selectedItem !== null && !hasAdded && (
                  <Form.Item
                    name="quantity"
                    label={
                      <span className="font-medium text-sm">Quantity</span>
                    }
                    style={{ marginBottom: "30px" }}
                    rules={[
                      {
                        required: true,
                        message: "Quantity is required!",
                      },
                    ]}
                    className="mt-3"
                  >
                    <InputNumber
                      min={1}
                      max={10000000}
                      style={{ width: 240, height: 45 }}
                      // onChange={onChange}
                    />
                  </Form.Item>
                )}
              </>
            )}
            {channel?.isFetching && <CurstomeCardSkeleton />}
          </div>
        </div>

        <div className="flex items-center justify-between pb-3">
          <LoadingButton
            variant="outlined"
            className="w-32"
            loading={orders?.newOrderIsFetching}
            onClick={handlePrevious}
          >
            Previous
          </LoadingButton>
          <LoadingButton
            type="submit"
            variant="contained"
            className="w-32"
            loading={orders?.newOrderIsFetching || account?.isFetching}
            disabled={selectedItem === null || hasAdded}
          >
            Add Item
          </LoadingButton>
        </div>
      </Form>
    </div>
  );
};

export default PageView;
