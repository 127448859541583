import { Carousel } from "antd";
import React from "react";
import { useSelector } from "react-redux";

const CustomerPreview = () => {
  const { orders } = useSelector((state: any) => state);
  return (
    <div className="flex flex-col md:flex-row pt-4 gap-4 mt-2">
      <div className="bg-white border rounded-lg p-6 w-full md:flex-1">
        <h2 className="text-lg font-semibold mb-4 ">Customer Details</h2>
        <div className="grid grid-cols-2 gap-2">
          <div>
            <div className="mb-2 flex flex-col">
              <span>Customer Names:</span>{" "}
              <strong>
                {orders?.selected?.account?.customerDetails?.name}
              </strong>
            </div>

            <div className="mb-2 flex flex-col">
              <span>Country:</span>{" "}
              <strong>
                {orders?.selected?.account?.customerDetails?.country}
              </strong>
            </div>
            <div className="mb-2 flex flex-col">
              <span>Email:</span>{" "}
              <strong>
                {orders?.selected?.account?.customerDetails?.email}
              </strong>
            </div>
          </div>
          <div>
            <div className="mb-2 flex flex-col">
              <span>Phone Number:</span>{" "}
              <strong>
                {" "}
                {orders?.selected?.account?.customerDetails?.tel}
              </strong>
            </div>

            <div className="mb-2 flex flex-col">
              <span>Address:</span>{" "}
              <strong>
                {orders?.selected?.account?.customerDetails?.address}
              </strong>
            </div>

            <div className="mb-2 flex flex-col">
              <span>Customer Type:</span> <strong>Individual</strong>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white border rounded-lg p-6 w-full md:w-[35%]">
        <h2 className="text-lg font-semibold mb-4">{`Contact Details (${orders?.selected?.account?.contacts?.length})`}</h2>
        {orders?.selected?.account?.contacts?.length > 0 && (
          <Carousel autoplay arrows>
            {orders?.selected?.account?.contacts?.map((el: any) => (
              <div>
                <div className="mb-2 flex flex-col">
                  <span>Names:</span> <strong>{el?.name}</strong>
                </div>

                <div className="mb-2 flex flex-col">
                  <span>Phone Number:</span> <strong>{el?.phone}</strong>
                </div>

                <div className="mb-2 flex flex-col">
                  <span>Email:</span> <strong>{el?.email}</strong>
                </div>
              </div>
            ))}
          </Carousel>
        )}
      </div>
    </div>
  );
};

export default CustomerPreview;
