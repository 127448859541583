export const dataProduct = [
  {
    _id: "63f35ccfcdc2fd8ea0ed77e7",
    type: "Smartphone",
    brand: "Apple",
    model: "iPhone 14 Pro Max",
    storage: "256GB/512GB/1TB",
    colors: "Deep Purple/Black",
    images:["https://assets.stickpng.com/images/61d2f85b92b57c0004c64745.png"],
    status: "Active",
    createdAt: "2023-02-20T11:43:11.629Z",
    updatedAt: "2023-02-20T11:43:11.629Z",
    __v: 0,
  },
  {
    _id: "63f35ccfcdc2fd8ea0ed77e7",
    type: "Smartphone",
    brand: "Apple",
    images:["https://assets.stickpng.com/images/61d2f85b92b57c0004c64745.png"],
    model: "iPhone 12 Pro Max",
    storage: "256GB/512GB/1TB",
    colors: "Deep Purple/Black",
    status: "Active",
    createdAt: "2023-02-20T11:43:11.629Z",
    updatedAt: "2023-02-20T11:43:11.629Z",
    __v: 0,
  },
];

export const dataSuppliers = [
  {
    _id: "63f5edf876deaed5131d5cbb",
    name: "Redington Ltd",
    phone: "0787082320",
    email: "Redington@gmail.com",
    tin: "102004958",
    type: "International",
    image:"https://assets.stickpng.com/images/61d2f85b92b57c0004c64745.png",
    employees: [
      {
        names: "BENJAMIN IYAMUREMYE",
        position: "HR",
        contact: "0788888888888",
      },
    ],
  },
  {
    _id: "63f5edf876deaed5131d0cbb",
    name: "CHICK Ltd",
    phone: "0788888888",
    email: "chick@gmail.com",
    tin: "100001000",
    type: "Local",
    image:"https://assets.stickpng.com/images/61d2f85b92b57c0004c64745.png",
    employees: [
      {
        names: "BENJAMIN IYAMUREMYE",
        position: "HR",
        contact: "0788888888888",
      },
    ],
  },
];

export const dataQuotations = [
  {
    _id: "63f5edf876deaed5131d0cbb",
    quotationId: "QID000001",
    listOfProducts: [
      {
        _id: "63f5e2b0d38f49880e38be4a",
        product: {
          brand: "Apple",
          model: "iPhone 14 Pro Max",
          storage: ["256GB"],
        },
        specification: "All Size Min/Max",
        quantity: 30,
      },
    ],
    listOfSuppliers: [
      {
        _id: "63f5ed3fb2e93d031c92982d",
        name: "Redington LTD",
        contact: 788205982,
        email: "orders@redington.co",
        address: "1034 Hikoi St. Japan",
        type: "Internationl",
      },
    ],
    status: "Draft",
    createdBy: {
      names: "Test",
      role: "Admin",
    },
    createdAt: "2023-02-22T10:27:04.312Z",
    updatedAt: "2023-02-22T10:27:04.312Z",
    __v: 0,
  },
];

export const refreshPage = () => {
  window.location.reload(false);
}
