import { LoadingOutlined } from "@ant-design/icons";
import { Form, Input, Spin, Switch } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeUserPasswordActions,
  createResetPasswordLinkAction,
} from "../../store/auth/authActions";
const ResetPassword = ({
  userData,
  onCancel,
}: {
  userData: any;
  onCancel: any;
}) => {
  const { auth } = useSelector((state: any) => state);
  const [changePassword, setChangePassword] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const onFinish = async (values: any) => {
    if (changePassword) {
      await changeUserPasswordActions({ ...values }, auth?.token)(dispatch);
    } else {
      await createResetPasswordLinkAction({ ...values })(dispatch);
    }
    form.resetFields();
    onCancel();
  };

  return (
    <>
      <div className="py-5 relative">
        <div className="flex items-center mx-auto flex-col">
          <h1 className="font-medium text-lg">{`${
            changePassword ? "Change Password" : "Send Reset Email"
          }`}</h1>
          {changePassword && (
            <span className="font-medium text-lg">{userData?.names}</span>
          )}
        </div>
        {["admin", "dev"]?.includes(auth?.user?.role?.toLowerCase()) && (
          <div className="absolute left-2 top-0 w-32">
            <span className="pr-2">Change Password</span>
            <Switch
              onChange={(val) => setChangePassword(val)}
              style={{ backgroundColor: `${changePassword ? "blue" : "gray"}` }}
            />
          </div>
        )}

        <div className="ml-5 mt-8">
          <Form
            form={form}
            name="validateOnly"
            layout="vertical"
            autoComplete="off"
            style={{ maxWidth: "100%", marginTop: "20px" }}
            onFinish={onFinish}
          >
            <Form.Item
              name="email"
              label={
                <span className="text-[#030229] pl-1 text-sm font-normal">
                  Email
                </span>
              }
              initialValue={userData?.email}
              className="w-full"
            >
              <Input className="pl-4 h-[42px] w-[100%] text-[12px]" disabled />
            </Form.Item>
            {changePassword && (
              <Form.Item
                name="newPassword"
                label={
                  <span className="text-[#030229] pl-1 text-sm font-normal">
                    New Password
                  </span>
                }
                className="w-full"
              >
                <Input className="pl-4 h-[42px] w-[100%] text-[12px]" />
              </Form.Item>
            )}
            <Form.Item>
              <button
                type="submit"
                className="bg-[#605BFF] text-sm rounded-md mt-5 text-center w-full p-3 text-white"
              >
                {" "}
                {auth?.isFetching ? <Spin indicator={antIcon} /> : "Send"}{" "}
              </button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};
export default ResetPassword;
