import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const AddNewDiscountService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/discount`, data, token);
};
export const getAllDiscountServices = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/discount${query}`, token);
};
export const getAllModelServices = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/product/model${query}`, token);
};

export const updateDiscountService = async (
  itemId: string,
  token: string,
  data: any
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/discount/one/${itemId}`,
    data,
    token
  );
};
