import { useState } from "react";
import { Stack } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { Badge, Carousel, Switch } from "antd";
import ProductDetailsModal from "../../Modals/ProductDetailsModal";
import UpdateProduct from "../../forms/NewProductForm";
import DeleteModal from "../../Modals/DeleteModal";
import {
  removeCartItemAction,
  updateShopStockCartItemDetailsAction,
} from "../../../store/wareHouse/actions";
import { useParams } from "react-router-dom";
import { getDefaultCurrencyCode } from "../../../utils/helper";

const Component = (props: any) => {
  const { auth, wareHouse, company } = useSelector((state: any) => state);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { cartId } = useParams();
  const [checked, setChecked] = useState(
    props?.data?.payment?.extendedWarranty
  );

  const itemPrice = props?.data?.payment?.amount;

  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  const handleClickDelete = async () => {
    setVisible(true);
  };

  const handleOnClickViewIcon = (e: any) => {
    e.preventDefault();
    // setisEdit(false);
    setIsModalOpen(true);
  };

  const handleDeleteModalCancels = () => {
    setVisible(false);
  };
  const handleOnCancel = () => {
    setIsModalOpen(false);
  };

  const handleSwitchChange = async (checked: boolean) => {
    setChecked(checked);
    console.log(">>>>>>>^^^^^^^^^^^^", checked);

    auth?.token &&
      updateShopStockCartItemDetailsAction(auth?.token, props?.data?._id, {
        payment: {
          ...props?.data?.payment,
          extendedWarranty: checked
            ? props?.data?.shopStockItem?.requestedItem?.product
                ?.extendedWarranty
            : 0,
        },
      })(dispatch);
  };

  const deleteProduct = async () => {
    console.log("delete");
    auth?.token &&
      (await removeCartItemAction(auth?.token, cartId, {
        item: props?.data?._id,
      })(dispatch));
  };

  console.log(props?.data?._id);

  return (
    <>
      <Badge.Ribbon
        color="green"
        text={`${
          itemPrice +
          (props?.data?.payment?.amount *
            props?.data?.payment?.extendedWarranty) /
            100
        } ${getDefaultCurrencyCode(company)}`}
      >
        <div className="relative w-80 h-fit p-2 pb-5 pt-10 bg-white text-black rounded-md">
          <div className="flex w-fit">
            <div style={{ width: "7rem" }}>
              <Carousel autoplay>
                {props?.data?.shopStockItem?.requestedItem?.product?.product?.images?.map(
                  (im: any) => (
                    <div>
                      <img src={im} width={"100%"} alt="" />
                    </div>
                  )
                )}
              </Carousel>
            </div>
            <div className="flex flex-col flex-wrap pl-2">
              <h1 className="font-bold">{props?.data?.product?.model}</h1>
              <div className=" gap-1">
                <Stack spacing={1}>
                  {props?.data &&
                    props?.data?.shopStockItem?.requestedItem?.product?.specification
                      ?.slice(0, 2)
                      ?.map((data: any, _index: number) => (
                        <div>
                          <p className="text-xs">
                            <b>{data[0]}</b>
                          </p>
                          <p className="text-xs">{data[1]}</p>
                        </div>
                      ))}
                  <div>
                    <p className="text-xs">
                      <b>Extended Warranty: </b>
                      {
                        props?.data?.shopStockItem?.requestedItem?.product
                          ?.extendedWarranty
                      }
                      %
                    </p>
                  </div>
                </Stack>
              </div>
            </div>
          </div>
          <div className="absolute bottom-1 right-2 flex justify-end items-center w-full py-2 space-x-2">
            <div>
              <span>Extended warranty: </span>
              <Switch
                size="small"
                checked={checked}
                onChange={handleSwitchChange}
              />
            </div>
            <button
              aria-label="delete"
              className="text-gray-400"
              onClick={handleClickDelete}
            >
              <DeleteIcon fontSize="small" className="h-2" />
            </button>
          </div>
        </div>
      </Badge.Ribbon>
      {/* product modal here */}

      <ProductDetailsModal
        component={
          <UpdateProduct
            dataToUpdate={props?.data}
            action={"update"}
            onCancel={handleOnCancel}
          />
        }
        isModalOpen={isModalOpen}
        handleOnClickViewIcon={handleOnClickViewIcon}
        handleOnCancel={handleOnCancel}
      />

      <DeleteModal
        visible={visible}
        onOk={deleteProduct}
        isLoading={wareHouse.isFetching}
        onCancel={handleDeleteModalCancels}
        itemName="Product"
      />
    </>
  );
};

export default Component;
