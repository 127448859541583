import { Card, Skeleton, Switch } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addBussinessProductToChannelAction,
  getAllBusinessAction,
  removeBussinessProductToChannelAction,
} from "../../../../store/setting/company/actions";
import { getOneChanneltAction } from "../../../../store/channel/actions";

const BusinessProoducts = () => {
  const { auth, layout, company, channel } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [switchState, setSwitchState] = useState<any>({});
  const [productIdSwitched, setProductIdSwiched] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    auth?.token && getAllBusinessAction(auth?.token, "?")(dispatch);
  }, [auth.token, dispatch]);

  const handleSwitchChange = async (
    checked: boolean,
    name: string,
    id: string
  ) => {
    const newSwitchState = {
      ...switchState,
      [name]: { name, id, isEnabled: checked },
    };

    setSwitchState(newSwitchState);
    if (checked) {
      auth?.token &&
        (await addBussinessProductToChannelAction(
          channel?.selected?._id,
          {
            channelId: channel?.selected?._id,
            businessProductId: id,
          },
          auth?.token
        )(dispatch));
      auth?.token &&
        (await getOneChanneltAction(
          layout?.channelInfo?._id,
          auth?.token
        )(dispatch));
    } else {
      auth?.token &&
        (await removeBussinessProductToChannelAction(
          channel?.selected?._id,
          {
            channelId: channel?.selected?._id,
            businessProductId: id,
          },
          auth?.token
        )(dispatch));
      auth?.token &&
        (await getOneChanneltAction(
          layout?.channelInfo?._id,
          auth?.token
        )(dispatch));
    }
  };

  const businessProduct = company?.allBusiness?.data;

  function checkBusinessProductExists(array: any, businessProductId: any) {
    return array?.some(
      (obj: any) => obj.businessProduct?._id === businessProductId
    );
  }
  return (
    <div className="text-[#030229] bg-white p-4 h-[82vh] overflow-y-auto">
      <div className="flex justify-between mb-6"></div>
      <div
        className={`flex flex-wrap ${
          layout?.isSideNavOpen ? "gap-3" : "gap-8"
        }`}
      >
        {company?.isFetching ? (
          <>
            <Card style={{ width: 400, marginTop: 16 }}>
              <Skeleton loading={company?.isFetching} active></Skeleton>
            </Card>
            <Card style={{ width: 400, marginTop: 16 }}>
              <Skeleton loading={company?.isFetching} active></Skeleton>
            </Card>
          </>
        ) : (
          businessProduct?.map((data: any, _index: any) => {
            return (
              <div
                className={` ${
                  layout?.isSideNavOpen
                    ? "w-[23rem] h-[11rem]"
                    : "w-[23rem] h-[11rem]"
                } border border-[#030229] border-opacity-10 rounded-[10px] p-3 overflow-x-auto`}
              >
                <div className="flex justify-between">
                  <h1 className="text-[16px] font-medium">{data?.name}</h1>
                  <div className="border border-[#030229] border-opacity-10 px-4 py-1 rounded-md text-sm font-medium">
                    {checkBusinessProductExists(
                      channel?.selected?.services,
                      data?._id
                    )
                      ? "on"
                      : "off"}
                    <Switch
                      onChange={(checked: boolean) => (
                        handleSwitchChange(checked, data?.name, data?._id),
                        setProductIdSwiched(
                          checkBusinessProductExists(
                            channel?.selected?.services,
                            data?._id
                          ) ?? false
                        )
                      )}
                      style={{ marginLeft: "1px" }}
                      checked={
                        checkBusinessProductExists(
                          channel?.selected?.services,
                          data?._id
                        ) || false
                      }
                      disabled={company?.isUpdatingFetching}
                      size="small"
                    />
                  </div>
                </div>
                <div className=" mt-6">
                  <p className="text-[#0F0F4780] font-normal text-[14px]">
                    {data?.description}
                  </p>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};
export default BusinessProoducts;
