import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "deliveryNoteLibrary",
  initialState: {
    isFetching: false,
    subDeliveryItemIsFetching: false,
    all: { data: [] },
    selected: null,
    attributes: null,
    new: null,
    updated: null,
    query: null,
    selectedProduct: null,
    productStore: null,
    singleDeliveryNote: null,
    ebmitem: [],
    subDeliveryNoteItem: null,
    deliveryNotepdf: null,
    deliveryIsFetching: null,
    invoiceResult: null,
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },

    seEbmItem(state, action) {
      state.ebmitem = action.payload;
    },
    setAttributes(state, action) {
      state.attributes = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setSubDeliveryItemIsFetching(state, action) {
      state.subDeliveryItemIsFetching = action.payload;
    },
    setDeliveryIsFetching(state, action) {
      state.deliveryIsFetching = action.payload;
    },
    setSelectedProduct(state, action) {
      state.selectedProduct = action.payload;
    },
    setProductStore(state, action) {
      state.productStore = action.payload;
    },
    setSingleDeliveryNote(state, action) {
      state.singleDeliveryNote = action.payload;
    },
    setSubDeliveryNoteItem(state, action) {
      state.subDeliveryNoteItem = action.payload;
    },
    setDeliveryNotepdf(state, action) {
      state.deliveryNotepdf = action.payload;
    },
    setInvoiceResult(state, action) {
      state.invoiceResult = action.payload;
    },
  },
});

export const mySubscritionActions = mySlice.actions;

export default mySlice.reducer;
