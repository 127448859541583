import ShopsDataTable from "../Shops";
import AgentsDataTable from "../Agents";
import IkosoraStock from "../IkosoraStock";
import IkosoraNoSale from "../IkosoraNoSale";
import IkosoraSales from "../IkosoraSales";
import NosaleReasonTable from "../../tables/NosaleReasonTable";

const RegionalInfomation: React.FC<{
  setSelecteAgentRegionKey: any;
  setSelecteShopRegionKey: any;
  enteredDate: any;
  compareDate: any;
  selectedAgentRegionKey: any;
  selectedShopRegionKey: any;
  setSelecteBrandedRegionKey: any;
  hasIkosora: any;
}> = ({
  setSelecteAgentRegionKey,
  setSelecteShopRegionKey,
  selectedAgentRegionKey,
  selectedShopRegionKey,
  setSelecteBrandedRegionKey,
  enteredDate,
  compareDate,
  hasIkosora,
}) => {
  return (
    <>
      {!hasIkosora ? (
        <div className="lg:flex lg:gap-4 w-full">
          <ShopsDataTable
            title="Shops"
            setSelecteShopRegionKey={setSelecteShopRegionKey}
            enteredDate={enteredDate}
            compareDate={compareDate}
            selectedShopRegionKey={selectedShopRegionKey}
          />
          <AgentsDataTable
            title="Agents"
            setSelecteAgentRegionKey={setSelecteAgentRegionKey}
            selectedAgentRegionKey={selectedAgentRegionKey}
            enteredDate={enteredDate}
            compareDate={compareDate}
          />
        </div>
      ) : (
        <div className="bg-white mt-4 rounded-md pb-4">
          <div className="flex flex-col 2xl:flex-row  xspace-x-2 pl-4 max-w-full">
            <div className="w-full 2xl:w-[35%] pr-3 py-4">
              <IkosoraNoSale />
              <NosaleReasonTable />
            </div>
            <div className="flex-1 2xl:max-w-[65%]">
              <IkosoraStock
                title="Stock"
                setSelecteShopRegionKey={setSelecteShopRegionKey}
                enteredDate={enteredDate}
                compareDate={compareDate}
                setSelecteBrandedRegionKey={setSelecteBrandedRegionKey}
              />
              <IkosoraSales
                title="Sales"
                setSelecteShopRegionKey={setSelecteShopRegionKey}
                enteredDate={enteredDate}
                compareDate={compareDate}
                selectedShopRegionKey={selectedShopRegionKey}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RegionalInfomation;
