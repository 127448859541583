import React from "react";

const CustomSkeleton = () => {
  return (
    <div role="status" className="max-w-sm animate-pulse">
      <div className="h-3 bg-gray-200 rounded w-28 mb-2"></div>
    </div>
  );
};

export default CustomSkeleton;
