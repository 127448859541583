import React, { useEffect, useState } from "react";
import { DatePicker, Select, DatePickerProps } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import TwoIconsCard from "../components/cards/TwoIconsCard";
import { ReactComponent as AmountIcons } from "../assets/images/icons/Group 1000002860.svg";
import { ReactComponent as CustomerAmountIcons } from "../assets/images/icons/Group 1000002860_1.svg";
import { ReactComponent as PurchaseIcons } from "../assets/images/icons/Group 8936 (1).svg";
import { ReactComponent as CartIcons } from "../assets/images/icons/Group 1000002857 (1).svg";
import { ReactComponent as PIcons } from "../assets/images/icons/Group 8936 (5).svg";
import { ReactComponent as StockIcons } from "../assets/images/icons/Group 8936.svg";
import { ReactComponent as RRAIcons } from "../assets/images/icons/RRA logo 1.svg";
import OverviewChart from "../components/OverviewChart";
import OverViewPieChart from "../components/OverviewPieChart";
import Timer from "../components/Timer";
import { useDispatch, useSelector } from "react-redux";
import {
  getEbmOverviewAction,
  getEbmSelectOptionAction,
} from "../store/action";
import LoadingCard from "../components/LoadingCard";
dayjs.extend(customParseFormat);

const dateFormat = "YYYY-MM-DD";

const Overview = () => {
  const { auth, ebmGeneral, ebmLayout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const formattedDate = new Date().toISOString().slice(0, 10);
  const [selectedDate, setSelectedDate] = useState<any>(formattedDate);
  const [selectedDate2, setSelectedDate2] = useState<any>(formattedDate);
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(dateString);
    setSelectedDate(dateString);
  };
  const onChangeDate: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(dateString);
    setSelectedDate2(dateString);
  };
  useEffect(() => {
    auth?.token && getEbmOverviewAction(auth.token, "")(dispatch);
  }, [auth?.token, dispatch]);
  useEffect(() => {
    auth?.token && getEbmSelectOptionAction(auth?.token, "")(dispatch);
  }, [dispatch, auth]);

  return (
    <div>
      {/* <div className="lg:flex justify-between">
        <div className="flex gap-6 item-center justify-center">
          <RRAIcons />
          <Timer />
        </div>
        <div className="flex gap-8 h-10 mt-5">
          <h1 className="pt-1">From</h1>
          <DatePicker defaultValue={dayjs(formattedDate)} onChange={onChange} />
          <h1 className="pt-1">To</h1>
          <DatePicker
            defaultValue={dayjs(formattedDate)}
            onChange={onChangeDate}
          />
        </div>
      </div> */}
      <div
        className={`grid ${ebmLayout?.isSideNavOpen
          ? "grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4"
          : "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5"
          } gap-5 mt-7 `}
      >
        {ebmGeneral?.isFetching ? (
          <LoadingCard />
        ) : (
          <TwoIconsCard
            amount={ebmGeneral?.ebmOverview?.totalItem ?? 0}
            title={"Total Items"}
            iconTwo={<CartIcons />}
          />
        )}
        {ebmGeneral?.isFetching ? (
          <LoadingCard />
        ) : (
          <TwoIconsCard
            amount={ebmGeneral?.ebmOverview?.totalCustomers ?? 0}
            title={"Total Customer"}
            iconTwo={<CustomerAmountIcons />}
          />
        )}
        {ebmGeneral?.isFetching ? (
          <LoadingCard />
        ) : (
          <TwoIconsCard
            amount={ebmGeneral?.ebmOverview?.totalSales ?? 0}
            title={"Total Sales"}
            iconTwo={<AmountIcons />}
          />
        )}
        {ebmGeneral?.isFetching ? (
          <LoadingCard />
        ) : (
          <TwoIconsCard
            amount={ebmGeneral?.ebmOverview?.totalPurchase ?? 0}
            title={"Total Purchase"}
            iconTwo={<PIcons />}
          />
        )}
        {ebmGeneral?.isFetching ? (
          <LoadingCard />
        ) : (
          <TwoIconsCard
            amount={ebmGeneral?.ebmOverview?.totalImports ?? 0}
            title={"Total Import"}
            iconTwo={<PurchaseIcons />}
          />
        )}
        {ebmGeneral?.isFetching ? (
          <LoadingCard />
        ) : (
          <TwoIconsCard
            amount={ebmGeneral?.ebmOverview?.totalStock ?? 0}
            title={"Total in Stock"}
            iconTwo={<StockIcons />}
          />
        )}
      </div>
      <div className="flex mt-8 flex-wrap  gap-6">
        <div className="w-[57%] bg-white p-10 rounded-md">
          <div className="flex justify-between w-full my-3">
            <h1 className="text-[#030229] opacity-90 text-lg font-medium mb-4">
              Sales and Purchase Management
            </h1>
            <div className="flex gap-5">
              <div className="flex gap-2  ">
                <p className="text-[#030229] text-[12px] mt-1 font-light flex justify-center items-center bg-[#AFADFF] w-4 h-4 rounded-full ">
                  {" "}
                </p>
                <p>Sales</p>
              </div>
              <div className="flex gap-2">
                <p className="text-[#030229] text-[12px] mt-1 font-light flex justify-center items-center bg-[#DEDEDE] w-4 h-4 rounded-full ">
                  {" "}
                </p>
                <p >Purchase</p>
              </div>
            </div>
          </div>
          <OverviewChart />
        </div>
        <div className="w-[40%]  bg-white p-4 mt-5 2xl:mt-0 my-3">
          <h1 className="text-[#030229] text-lg pl-6 p-2 font-medium">Import Summary</h1>
          <div className="mt-8 relative right-[16rem]">
            <OverViewPieChart />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Overview;
