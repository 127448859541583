import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "../channels/styles.css";
import { enUS } from "date-fns/locale";
import { useDispatch, useSelector } from "react-redux";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Dot,
  ResponsiveContainer,
} from "recharts";
import { getVaultStatsAction } from "../../../store/vault/actions";
import "./vault.css"

const CustomDot = ({
  cx,
  cy,
  stroke,
  strokeWidth,
  r,
  payload,
}: {
  cx?: number;
  cy?: number;
  stroke?: string;
  strokeWidth?: number;
  r?: number;
  payload?: any;
}) => {
  if (payload && payload.active) {
    return (
      <Dot
        cx={cx}
        cy={cy}
        r={r}
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill="#FA365980"
      />
    );
  }
  return null;
};

export function CustomChart() {
  const { auth, vault, layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const getLastWeekDate = () => {
    const today = new Date();
    const lastWeek = new Date(today);
    lastWeek.setDate(lastWeek.getDate() - 6);
    lastWeek.setHours(0, 0, 0, 0);
    return lastWeek;
  };

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: getLastWeekDate(),
    endDate: new Date(),
    key: "selection",
  });

  const formatDate = (date: any) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const startDate = formatDate(selectedDateRange.startDate);
  const endDate = formatDate(selectedDateRange.endDate);

  const handleSelect = (ranges: any) => {
    setSelectedDateRange(ranges.selection);
  };

  useEffect(() => {
    if (auth?.token && startDate && endDate) {
      getVaultStatsAction(
        auth?.token,
        `?startDate=${startDate}&endDate=${endDate}`
      )(dispatch);
    }
  }, [auth?.token, dispatch, endDate, startDate]);

  const statistics = vault?.stats?.data?.map((i: any) => {
    return {
      active: i?.Active,
      locked: i?.Locked,
      released: i?.Released,
      name: i?.day,
    };
  });
  return (
    <div className="gap-8 flex flex-col 2xl:flex-row  w-full mt-6">
      <div
        className='bg-white w-full 2xl:w-[60%] rounded-md p-8' style={{ boxShadow: '1px 12px 24px 0px rgba(3, 2, 41, 0.06)' }}>
        <div className="flex flex-row justify-between my-6 mx-6 mb-6">
          <h1 className="text-[#030229] font-medium text-[16px]">Statistics</h1>
          <div className="flex gap-4 text-sm">
            <div className="flex gap-2">
              {" "}
              <div className="w-2 h-2 mt-[6px] rounded-full bg-[#FA3659]"></div>{" "}
              <p className="text-[#FA3659]"> Locked</p>
            </div>
            <div className="flex gap-2">
              {" "}
              <div className="w-2 h-2 mt-[6px] rounded-full bg-[#0FA91E80]"></div>{" "}
              <p className="text-[#030229]">Active </p>
            </div>
            <div className="flex gap-2">
              {" "}
              <div className="w-2 h-2 mt-[6px] rounded-full bg-[#605BFF80]"></div>{" "}
              <p className="text-[#030229]"> Released</p>
            </div>
          </div>
        </div>
        <div className="h-[20rem]">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart width={500} height={300} data={statistics}>
              <CartesianGrid
                vertical={false}
                horizontal={true}
                strokeDasharray="0.3 0.3"
              />
              <XAxis dataKey="name" axisLine={false} />
              <YAxis axisLine={false} domain={["auto", "auto"]} />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="active"
                stroke="#82ca9d"
                activeDot={<CustomDot />}
                dot={false}
              />
              <Line
                type="monotone"
                dataKey="locked"
                stroke="red"
                activeDot={<CustomDot />}
                dot={false}
              />
              <Line
                type="monotone"
                dataKey="released"
                stroke="#8884d8"
                activeDot={<CustomDot />}
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div
        className='bg-white w-full lg:w-[38rem]' style={{ boxShadow: '1px 12px 24px 0px rgba(3, 2, 41, 0.06)' }}
      >
        <DateRangePicker
          className=" mt-4 xl:mt-0 p-4 text-[#030229] "
          onChange={handleSelect}
          showPreview
          months={1}
          ranges={[selectedDateRange]}
          direction="vertical"
          locale={enUS}
        />
      </div>
    </div>
  );
}
