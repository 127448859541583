import React from "react";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import { useLocation } from "react-router-dom";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import SubscriptionRequestTable from "../../../components/tables/SubscriptionRequestTable";

const SubscriptionRequest = (_props: any) => {
  const location = useLocation();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="text-black  py-6 scrollbar-hide overflow-y-auto h-screen">
      <div className="flex flex-row items-center text-[#0F0F47] pb-4">
        <DetailsHeaderActionBar
          pageName={location.pathname.slice(1)}
          goBack={goBack}
          title=" "
        />
      </div>
      <Stack
        spacing={3}
        className="flex-grow flex flex-col justify-between bg-white scrollbar-hide"
      >
        <SubscriptionRequestTable />
      </Stack>
    </div>
  );
};

export default SubscriptionRequest;
