/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Table, Typography, Progress } from "antd";
import { Select } from "antd";
import { calculatePercentageChange } from "../../utils/converter";
import CsvDownloadButton from "../buttons/CsvDownloadButton";
import { Link } from "react-router-dom";
import { getDefaultCurrencyCode } from "../../utils/helper";
import { getAllRegionAction } from "../../store/channel/actions";

const { Option } = Select;

const { Text } = Typography;
const Agents: React.FC<{
  title: string;
  setSelecteAgentRegionKey: any;
  selectedAgentRegionKey: any;
  enteredDate: any;
  compareDate: any;
}> = ({
  title,
  setSelecteAgentRegionKey,
  enteredDate,
  compareDate,
  selectedAgentRegionKey,
}) => {
  const { dashboard, company, auth, channel } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();

  const data = dashboard.agentStats?.data?.map((d: any) => {
    return {
      name: d?.agentName,
      revenue: d?.currentDateRevenue,
      quantity: d?.currentDateQuantity,
      channel: d?.channelName,
      agentId: d?.agentId,
      perc:
        (d?.currentDateRevenue * 100) /
        (d?.currentDateRevenue + d?.comparedDateRevenue),
    };
  });
  const handleSelectRegion = (value: any) => {
    setSelecteAgentRegionKey(value);
  };

  const columns = [
    {
      title: "",
      dataIndex: "perc",
      key: "perc",
      width: 50,
      render: (text: any) => (
        <div className="">
          <Progress type="dashboard" percent={text.toFixed(1)} size={20} />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string) => <div className="">{text}</div>,
    },
    {
      title: `Revenue(${getDefaultCurrencyCode(company)})`,
      dataIndex: "revenue",
      key: "revenue",
      render: (text: string) => (
        <p className=" w-full truncate">{text?.toLocaleString()}</p>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text: string) => (
        <p className="dashed-border-cell">{text?.toLocaleString()}</p>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 60,
      render: (_text: string, record: any) => (
        <Link to={`/channel/${record?.channel}/pos/agents/${record?.agentId}`}>
          <a className="dashed-border-cell text-sm text-blue-700 hover:underline hover:text-blue-700">
            View
          </a>
        </Link>
      ),
    },
  ];

  const totalCurrentRevenue = dashboard.agentStats?.data?.reduce(
    (total: any, item: any) => total + item.currentDateRevenue,
    0
  );

  const totalComparedRevenue = dashboard.agentStats?.data?.reduce(
    (total: any, item: any) => total + item.comparedDateRevenue,
    0
  );

  const percentageChange = calculatePercentageChange(
    totalCurrentRevenue,
    totalComparedRevenue
  );
  useEffect(() => {
    auth?.token && getAllRegionAction(auth?.token, ``)(dispatch);
  }, [dispatch, auth?.token]);

  const csvHeaders = [
    { label: "Region", key: "region" },
    { label: "Agent", key: "agentName" },
    { label: `Revenue (${enteredDate})`, key: "todayRevenue" },
    { label: `Quantity (${enteredDate})`, key: "todaysQty" },
    { label: `Revenue (${compareDate})`, key: "comparedRevenue" },
    { label: `Quantity (${compareDate})`, key: "comparedQty" },
  ];

  const csvData = dashboard.agentStats?.data?.map((record: any) => ({
    region: selectedAgentRegionKey || "-",
    agentName: record?.agentName,
    todayRevenue: record?.currentDateRevenue?.toLocaleString(),
    todaysQty: record?.currentDateQuantity?.toLocaleString(),
    comparedRevenue: record?.comparedDateRevenue?.toLocaleString(),
    comparedQty: record?.comparedDateQuantity?.toLocaleString(),
  }));

  return (
    <div className="bg-white mt-5 p-4 rounded-lg lg:w-[50%]">
      <div className="sm:flex justify-between items-center w-full">
        <div className="flex items-center justify-between sm:space-x-2">
          <h2 className="text-xl capitalize">{title}</h2>
          <Select
            placeholder="Filter by Region"
            allowClear
            style={{ width: 150 }}
            onChange={(value) => {
              if (value === undefined) {
                handleSelectRegion("");
              } else {
                handleSelectRegion(value);
              }
            }}
          >
            {channel?.allRegion?.data?.map((d: any, index: any) => (
              <Option value={d?.name} key={index}>
                {d?.name}
              </Option>
            ))}
          </Select>
        </div>
        <div
          className={`${
            percentageChange?.type === "increase"
              ? "bg-green-200"
              : percentageChange?.type === "decrease"
              ? "bg-red-200"
              : "bg-blue-200"
          } rounded-lg px-3 py-1 sm:float-left float-right sm:mt-0 mt-4`}
        >
          <span
            className={`${
              percentageChange?.type === "increase"
                ? "text-green-500"
                : percentageChange?.type === "decrease"
                ? "text-red-500"
                : "text-blue-900"
            }
             flex items-center py-0.5 `}
          >
            {percentageChange?.type === "increase" ? (
              <ArrowUpwardIcon />
            ) : percentageChange?.type === "decrease" ? (
              <ArrowDownwardIcon />
            ) : (
              ""
            )}

            {`${percentageChange?.percentage}%`}
          </span>
        </div>
      </div>
      <div className="mt-2 sm:flex items-center justify-between">
        <div className="">
          <span className="mr-4">SC: Service Center</span>
          <span className="block sm:inline-block sm:mt-0 mt-2">
            CS: Connect Shop
          </span>
        </div>
        <div className="flex sm:mt-0 mt-4 items-center">
          <div className="h-3 w-3 bg-blue-500 mr-1.5"></div>
          <span className="mr-4">Today</span>
          <div className="h-3 w-3 bg-gray-400 mr-1.5"></div>{" "}
          <span>Yesterday</span>
          {/* {!["partner"]?.includes(auth?.user?.role) && (  for future use */}
          <div className="ml-2">
            <CsvDownloadButton
              csvHeaders={csvHeaders}
              csvData={csvData}
              filename={`Agent Statistics.csv`}
            />
          </div>
          {/* )} */}
        </div>
      </div>

      <div className="flex-1 mt-4">
        <div className="h-fit overflow-y-auto">
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            loading={dashboard?.agentStatsIsFetching}
            className="table-auto"
            scroll={{ y: 400 }}
            summary={(pageData) => {
              let totalInShops = 0;
              let totalInWarehouse = 0;

              pageData.forEach(({ revenue, quantity }) => {
                totalInShops += revenue;
                totalInWarehouse += quantity;
              });

              return (
                <>
                  <Table.Summary.Row className="!text-[#2E6C8E] bg-white sticky bottom-0">
                    <Table.Summary.Cell index={0} colSpan={2}>
                      <Text style={{ fontWeight: "bolder" }}>Total</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={0}>
                      <Text style={{ fontWeight: "bolder" }}>
                        {totalInShops?.toLocaleString()}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={0}>
                      <Text style={{ fontWeight: "bolder" }}>
                        {totalInWarehouse?.toLocaleString()}
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Agents;
