import React from "react";
import { Table, Space, Tooltip, Tag, Typography } from "antd";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { searchValue } from "../../../../../utils/setColor";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import type { ColumnsType, TableProps } from "antd/es/table";

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}
const { Paragraph } = Typography;
const columns: ColumnsType<DataType> = [
  {
    title: "Created On",
    dataIndex: "createdAt",

    // onFilter: (value: string, record) => record.address.indexOf(value) === 0,
  },
  {
    title: "Done By",
    dataIndex: "doneBy",
  },
  {
    title: "ID",
    dataIndex: "id",
    render: (_, record: any) => <Paragraph copyable>{record?.id}</Paragraph>,
  },
  {
    title: "Items",
    dataIndex: "items",
  },
  {
    title: "T Qty",
    dataIndex: "qty",
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (_, record: any) => (
      <Tag color={searchValue(record?.status)}>{record?.status}</Tag>
    ),
  },
  {
    title: "Actions",
    key: "action",
    render: (_, record: any) => (
      <Space size="middle">
        <IconButton
          aria-label="delete"
          color="success"
          onClick={() =>
            record.navigate(
              `/inventory/rfq/${record?.data?.quotation?.quotationId}/${record?.data?._id}`
            )
          }
        >
          <RemoveRedEyeIcon />
        </IconButton>
      </Space>
    ),
  },
];

const onChange: TableProps<DataType>["onChange"] = (
  pagination,
  filters,
  sorter,
  extra
) => {
  console.log("params", pagination, filters, sorter, extra);
};

const App = (props: any) => {
  const navigate = useNavigate();
  const data = props?.data?.map((d: any) => ({
    createdAt: d?.createdAt.split("T")[0],
    doneBy: d?.createdBy?.names?.split(" ")[0],
    id: d?.quotation?.quotationId,
    items: d?.quotation?.listOfProducts?.length,
    qty: d?.quotation?.listOfProducts?.reduce(
      (sum: number, a: any) => (sum = sum + a?.quantity),
      0
    ),
    status: d?.status,
    data: d,
    navigate: navigate,
  }));

  return <Table columns={columns} dataSource={data} onChange={onChange} />;
};

export default App;
