import React, { useEffect, useState } from "react";
import { Avatar, Card, List, Skeleton, Statistic, Tag } from "antd";
import { ArrowUpOutlined, UserOutlined } from "@ant-design/icons";
import TwoIconsCard from "../../../apps/EBM/components/cards/TwoIconsCard";
import { FaArrowTrendUp } from "react-icons/fa6";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import makerIcons from "./makericon.png";
import FrameIcon1 from "./assets/Frame 27.png";
import FrameIcon2 from "./assets/Frame 27 (2).png";
import TrackingForm from "./map/TrackingForm";
import MapSection from "./map/MapSection";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllTrackActivitiesAction,
  gettrackingStasAction,
} from "../../../store/delivery/actions";
import MapComp from "./map/MapComp";
// import MapComponent from "./map/MapComponent";

interface Stat {
  label: string;
  value: number;
  change: string;
  text: string;
  icon: JSX.Element;
  color: string;
}

const DeliverypageView: React.FC = () => {
  const { auth, tracking ,layout} = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [trackingId, setTrackingId] = useState<any>();

  const [hasValidLocations, setHasValidLocations] = useState(false);

  const handleTrackChange = (id: string, isValid: boolean) => {
    setTrackingId(id);
    setHasValidLocations(isValid);
  };

  useEffect(() => {
    if (auth.token) {
      getAllTrackActivitiesAction(auth.token)(dispatch);
      gettrackingStasAction(auth.token)(dispatch);
    }
  }, [auth.token, dispatch]);

  const data = tracking?.trActivities?.data;

  const stats: any = tracking?.trackingStas?.data;

  return (
    <div className="p-4 h-screesn">
      <div className={`grid  ${layout?.isSideNavOpen?"md:grid-cols-1":"md:grid-cols-2"} md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6  gap-5 mb-6 mt-5 `}>
        {stats?.map((stat: any, index: any) => (
          <div className="bg-white shadow-md rounded-lg p-4 w-d64 flex flex-col items-center justify-center">
            <div className="text-gray-600 text-lg  w-full">{stat?.status}</div>
            <div className="flex flex-row items-center w-full mt-1">
              <div className="text-xl font-semibold text-[#030229]">
                {stat?.count}
              </div>
              <div className="text-4xl font-bold text-black"></div>
            </div>
          </div>
        ))}
      </div>

      <div className="grid grid-cols-2 gap-5">
        <Card className="h-[580px] overflow-y-auto">
          <h1 className="text-sm font-semibold mb-4 text-[#030229]">
            Recent Activities
          </h1>
          <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item: any, index: number) => {
              const avatarSrc = index % 2 === 0 ? FrameIcon1 : FrameIcon2;

              return (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar src={avatarSrc} />}
                    title={
                      <h1 className="text-[#030229] text-[12px] font-semibold">
                        {item?.name}
                      </h1>
                    }
                    description={
                      <p className="text-[#030229] text-[12px]">
                        {item?.details}
                      </p>
                    }
                  />
                  <div className="flex flex-col align-center w-32 ">
                    <p
                      // color="blue"
                      className={`text-center text-[12px] w-28 ${
                        item?.status === "Delivered"
                          ? "text-[#0FA958]"
                          : item?.status === "In-Transit"
                          ? "text-[#FFC727]"
                          : item.status === "Ready For Pickup"
                          ? "text-[#605BFF]"
                          : item.status === "Pickup"
                          ? "text-[#C53939]": "text-[#030229]"
                      }`}
                    >
                      {item?.status}
                    </p>
                    <div className="pt-3 pl-3">{item?.createdAt.slice(0, 10)}</div>
                  </div>
                </List.Item>
              );
            }}
          />
        </Card>

        <div className="relative">
          <div className="w-[100%] flex flex-row items-center justify-between text-black">
            <div className="absolute top-0 right-0 text-red-500 w-[50%] z-10">
              <TrackingForm onTrackChange={handleTrackChange} />
            </div>
          </div>
          {tracking.isFetching ? (
            <div className="space-y-4">
              <Skeleton active paragraph={{ rows: 3 }} />
              <Skeleton active paragraph={{ rows: 2 }} />
            </div>
          ) : (
            <MapComp
              trackingId={trackingId}
              hasValidLocations={hasValidLocations}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DeliverypageView;
