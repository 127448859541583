import React from "react";
import { useNavigate } from "react-router-dom";
import { Tag, Badge } from "antd";
import { searchValue } from "../../utils/setColor";

const Component = (props: any) => {
  const navigate = useNavigate();

  const storedStatus =
    props?.data?.data?.listOfItems &&
    props?.data?.data?.listOfItems?.map(
      (item: any, _index: number) => item?.status
    );

  const checkReceiveAndStoredStatus =
    props?.data?.status &&
    storedStatus?.every((status: any) => status === "Stored-Complete");

  return (
    <>
      <Badge.Ribbon
        text={
          <span
            style={{
              fontSize: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              fontWeight: 400,
              paddingTop: "1.5px",
            }}
          >
            {["In-Transit", "Verified-Incomplete", "Stored"]?.includes(
              props?.data?.status
            )
              ? props?.data?.status
              : checkReceiveAndStoredStatus
              ? props?.data?.status
              : "Verified - Not Stored"}
          </span>
        }
        color={searchValue(
          ["In-Transit", "Verified-Incomplete", "Stored"]?.includes(
            props?.data?.status
          )
            ? props?.data?.status
            : checkReceiveAndStoredStatus
            ? props?.data?.status
            : "Verified - Not Stored"
        )}
        style={{ padding: "4px 4px" }}
      >
        <div
          className="min-h-[150px] bg-white p-2 rounded-md hover:border-black relative shadow-[1px_3px_16px_0px_rgba(0,0,0,0.07)] text-[#030229] "
          onClick={() => {
            navigate(`/inventory/ws/in/${props.data.id}`);
          }}
        >
          <div className="py-2 px-1">
            <div className="text-base space-y-2">
              <h4 className="font-normal text-[14px] leading-3 text-[#030229] font-poppins">
                {props?.data?.status === "In-Transit"
                  ? props?.data?.deliveryID
                  : props?.data?.warehouseID}
              </h4>
              <p className="opacity-70 text-[12px] font-normal leading-normal">
                {props?.data?.pOrderID}
              </p>
              <p
                className="w-56 text-[13px] font-normal"
                style={{ color: "#605BFF" }}
              >
                {props?.data?.data?.pOrderID?.supplier?.name}
              </p>
              <div className="absolute right-6 top-10">
                {props?.data?.data?.ebmRegistered && (
                  <Tag
                    color={"blue"}
                    className="text-center px-4 py-1 rounded-2xl text-xs"
                  >
                    SAVED IN EBM
                  </Tag>
                )}
              </div>
            </div>
            <hr className="mt-4 pb-2" />
            <div className="bottom-0 right-2 flex justify-between items-end w-full">
              <div>
                <p className="opacity-70 text-[14px] font-normal leading-normal">
                  {props?.data?.data?.updatedAt.split("T")[0]}
                </p>
              </div>
              <p className="text-[14px] font-medium leading-normal pr-3">
                {props?.data?.qnt} Items
              </p>
            </div>
          </div>
        </div>
      </Badge.Ribbon>
    </>
  );
};

export default Component;
