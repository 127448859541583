import { Form, Input } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import {
    createRegionAction,
    updateRegionAction,
} from "../../store/channel/actions";

const NewRegionForm = (props: any) => {
    const { auth, channel, layout } = useSelector((state: any) => state);
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const initialData = {
        ...(props?.data || {}),
    };
    const isUpdate = !!props?.data?._id;

    const channelId = layout?.channelInfo?._id;

    const onFinish = async (values: any) => {
        auth?.token &&
            !props?.data?._id &&
            (await createRegionAction(
                auth?.token,
                {
                    channel: channelId,
                    ...values,
                },
                `channel=${channelId}`
            )(dispatch));
        if (props?.data?._id && auth?.token) {
            await updateRegionAction(
                props?.data?._id,
                {
                    ...values,
                },
                auth?.token
            )(dispatch);
        }
        form.resetFields();
        props?.onCancel();
    };
    useEffect(() => {
        form.setFieldsValue(props?.data);
    }, [props?.data]);
    return (
        <div className="mt-8 w-full px-8">
            <h1 className="text-base font-medium text-center mb-4">
                {isUpdate ? "Update Region" : "Create New Region"}
            </h1>
            <Form
                layout="vertical"
                form={form}
                name="register"
                onFinish={onFinish}
                initialValues={initialData}
            >
                <div>
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{ required: true, message: "Please input region name!" }]}
                    >
                        <Input className="w-full" />
                    </Form.Item>
                    <Form.Item
                        label="Address"
                        name="address"
                        rules={[{ required: true, message: "Please region address!" }]}
                        style={{ width: "100%" }}
                    >
                        <Input className="w-full" />
                    </Form.Item>
                </div>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <LoadingButton
                        sx={{ textTransform: "none", minWidth: "50%" }}
                        type="submit"
                        variant="contained"
                        loading={channel?.isFetching}
                    >
                        {isUpdate ? "Update" : "Save"}
                    </LoadingButton>
                </Form.Item>
            </Form>
        </div>
    );
};
export default NewRegionForm;
