import { Progress, Table } from "antd";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from "recharts";
import { useSelector } from "react-redux";
import {
  calculatePercentageChange,
  convertObjectToArray,
  getActivePrice,
} from "../../utils/converter";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link } from "react-router-dom";
import { getDefaultCurrencyCode } from "../../utils/helper";

const data01 = [{ value: 1 }];
const colors = [
  "#0D0D77",
  "#4848FA",
  "#3786FD",
  "#6969EC",
  "#8884d8",
  "#8dd1e1",
  "#0000FF",
];

const NoSale = () => {
  const { dashboard, auth, company } = useSelector((state: any) => state);

  const currentTotal = dashboard?.noSaleStats?.data[0]?.date1totalAmount;
  const comparedTotal = dashboard?.noSaleStats?.data[1]?.date2totalAmount;

  const percentage = calculatePercentageChange(currentTotal, comparedTotal);

  const pieCartData =
    dashboard?.noSaleStats?.data[0]?.type &&
    convertObjectToArray(dashboard?.noSaleStats?.data[0]?.type);
  const datas = pieCartData?.map((d: any) => {
    return {
      name: d[0],
      value: d[1],
    };
  });

  const columns = [
    {
      title: "",
      dataIndex: "perc",
      key: "perc",
      width: 50,
      render: (text: any) => (
        <div className="">
          <Progress type="dashboard" percent={text.toFixed(1)} size={20} />
        </div>
      ),
    },
    {
      title: "Shop",
      dataIndex: "shop",
      key: "shop",
      render: (text: string, record: any) => (
        <div className=" w-full truncate">
          {text +
            " - " +
            (record?.data?.shop?.type === "connect-shop"
              ? "CS"
              : record?.data?.shop?.type === "service-center"
              ? "SC"
              : "")}
        </div>
      ),
    },
    {
      title: "Device",
      dataIndex: "device",
      key: "device",
      render: (text: string, record: any) => (
        <div className=" w-full truncate">
          {record?.data?.priceList?.product?.model ||
            record?.data?.productId?.model ||
            record?.data?.newProduct}
        </div>
      ),
    },
    {
      title: `Price (${getDefaultCurrencyCode(company)})`,
      dataIndex: "price",
      key: "price",
      render: (text: string, record: any) => (
        <p className="dashed-border-cell">
          {getActivePrice(record?.data?.priceList?.prices)?.toLocaleString() ??
            "-"}
        </p>
      ),
    },
    {
      title: "Number of Requests",
      dataIndex: "noRequest",
      key: "noRequest",
    },
    // {
    //   title: "",
    //   dataIndex: "percentage",
    //   key: "percentage",
    //   render: (text: string, record: any) => {
    //     return (
    //       <span
    //         className={`flex ${
    //           record?.percentage?.type === "increase"
    //             ? "text-green-500"
    //             : record?.percentage?.type === "decrease"
    //             ? "text-red-500"
    //             : "text-blue-900"
    //         } text-sm`}
    //       >
    //         {record?.percentage?.type === "increase" ? (
    //           <ArrowUpwardIcon />
    //         ) : record?.percentage?.type === "decrease" ? (
    //           <ArrowDownwardIcon />
    //         ) : (
    //           ""
    //         )}{" "}
    //         {`${record?.percentage?.percentage}%`}
    //       </span>
    //     );
    //   },
    // },
  ];
  const formData = dashboard?.noSaleStats?.data[0]?.date1noSales?.map(
    (d: any) => {
      return {
        value:
          currentTotal !== 0 &&
          parseInt(
            (
              (parseFloat(d?.currentTotalAmount) / currentTotal) *
              100
            )?.toString()
          ),
        name: d?.productId?.type,
        noRequest: d?.numberOfRequests,
        shop: d?.shop?.name,
        percentage: calculatePercentageChange(
          d?.currentTotalAmount,
          d?.comparedTotalAmount
        ),
        perc:
          (d?.currentTotalAmount * 100) /
          (d?.currentTotalAmount + d?.comparedTotalAmount),
        data: d,
      };
    }
  );

  return (
    <div className="relative w-full mt-4 2xl:mt-0 2xl:w-[30%] min-h-[340px] bg-white p-2  rounded-md">
      <div className="flex space-x-4">
        <p className="text-gray-400 capitalize xmb-2">No Sales</p>
        {!["partner"]?.includes(auth?.user?.role) && (
          <Link to="/sales/nosales">
            {/* <Link to="/mahama/overview"> */}
            <OpenInNewIcon />
          </Link>
        )}
      </div>
      <p className="text-2xl font-semibold">
        {`${getDefaultCurrencyCode(company)} ${
          currentTotal?.toLocaleString() ?? "0"
        }`}
      </p>
      <p className="text-gray-400 capitalize xmb-2">{`${getDefaultCurrencyCode(
        company
      )} ${comparedTotal?.toLocaleString() ?? "0"}`}</p>
      <div
        className={` ${
          percentage?.type === "increase"
            ? "bg-green-200"
            : percentage?.type === "decrease"
            ? "bg-red-200"
            : "bg-blue-200"
        } absolute top-2 right-2 rounded-lg px-3 py-1`}
      >
        <span
          className={`${
            percentage?.type === "increase"
              ? "text-green-500"
              : percentage?.type === "decrease"
              ? "text-red-500"
              : "text-blue-900"
          }`}
        >
          {percentage?.type === "increase" ? (
            <ArrowUpwardIcon />
          ) : percentage?.type === "decrease" ? (
            <ArrowDownwardIcon />
          ) : (
            ""
          )}

          {`${percentage?.percentage}%`}
        </span>
      </div>
      <div className="h-80">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={500} height={700}>
            <Pie
              data={data01}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={70}
              fill="#8884d8"
              legendType="none"
            />
            <Pie
              data={datas}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius={70}
              outerRadius={90}
              //   fill="#82ca9d"
              label
              legendType="rect"
            >
              {datas?.map((_entry: any, index: any) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index % colors.length]}
                />
              ))}
            </Pie>
            <Legend align="center" width={400} />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <Table
        columns={columns}
        dataSource={formData}
        pagination={false}
        scroll={{ y: 200 }}
        rowClassName={(record, index) => {
          if (index === formData.length - 1) {
            return "x!border-b !border-dashed";
          } else if (index === 0) {
            return "dashed-top-border";
          }
          return "";
        }}
      />
    </div>
  );
};

export default NoSale;
