import React from "react";
import { Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import { searchValue } from "../../../../utils/setColor";
import { useParams } from "react-router-dom";
import { getOneProductStoreAction } from "../../../../store/product/actions";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import CsvDownloadButton from "../../../../components/buttons/CsvDownloadButton";
import { ProductInfoStockHeaders } from "../../../../components/csvHeaders/Headers";

interface DataType {
  [x: string]: any;
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Date",
    dataIndex: "price",
    render: (text, record) => (
      <p className="min-w-[80px] text-left">
        {record?.data?.updatedAt?.split("T")[0]}
      </p>
    ),
  },
  {
    title: "Descriptions",
    dataIndex: "specification",
  },
  {
    title: "SN/Bar Code",
    dataIndex: "barCode",
  },
  {
    title: "IMEI",
    dataIndex: "imei",
    render: (text, record) => (
      <>
        <p>{record?.data?.imei1 || "N/A"}</p>
        <p>{record?.data?.imei2}</p>
      </>
    ),
  },
  {
    title: "Supplier",
    dataIndex: "type",
    render: (text: any, record: any) => (
      <p>{record?.data?.warehouseItem?.po?.supplier?.name ?? "N/A"}</p>
    ),
  },
  {
    title: "Station",
    dataIndex: "station",
    render: (text, record) => (
      <p>
        {record?.data?.shopStock
          ? record?.data?.shopStock?.channel?.name +
            "~" +
            record?.data?.shopStock?.name
          : "In Warehouse"}
      </p>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (text, record) => (
      <Tag
        color={searchValue(record?.data?.status)}
        className="capitalize min-w-[50px] text-center"
      >
        {record?.data?.status}{" "}
      </Tag>
    ),
  },
];

const POSStockTable = (props: any) => {
  const dispatch = useDispatch();
  const { product, auth } = useSelector((state: any) => state);
  const dataMapper: any[] = [];
  const { productId } = useParams();
  const [formData, setformData] = React.useState(dataMapper);
  const value = product?.productStore;
  // console.log("::::::", value);

  React.useEffect(() => {
    const fetchOnProduct = async () => {
      if (productId) {
        auth?.token &&
          (await getOneProductStoreAction(auth?.token, productId)(dispatch));
      }
    };
    fetchOnProduct();
  }, [productId, auth, dispatch]);

  React.useEffect(() => {
    value &&
      value.forEach((el: any) => {
        dataMapper.push({
          specification: `${el?.quotationItem?.specification
            ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
            ?.join(", ")}`,
          status: el?.status,
          barCode: el?.serialNumber,
          data: el,
        });
      });

    setformData(dataMapper);
  }, [product?.productStore]);

  const csvData = formData?.map((record: any) => ({
    date: record?.data?.updatedAt?.slice(1, 10),
    supplier: record?.data?.warehouseItem?.po?.supplier?.name,
    product: product?.selected?.model,
    serialNumber: record?.barCode,
    specification: record?.specification,
    station: record?.data?.shopStock
      ? record?.data?.shopStock?.channel?.name +
        "-" +
        record?.data?.shopStock?.name
      : "In Warehouse",
    status:
      record?.status === "out"
        ? "In Shop"
        : record?.status === "in"
        ? " In Warehouse"
        : record?.status,
  }));

  return (
    <>
      <div className="absolute right-2 -top-14">
        <CsvDownloadButton
          csvHeaders={ProductInfoStockHeaders}
          csvData={csvData}
          filename={`${product?.selected?.model} Statistics.csv`}
        />
      </div>
      <ScrollableFrame hidePagination>
        <Table
          columns={columns}
          dataSource={formData}
          loading={product?.isFetching}
        />
      </ScrollableFrame>
    </>
  );
};

export default POSStockTable;
