import React from "react";
import { PieChart, Pie, Cell } from "recharts";
interface pieProp {
  data?: any;
  colors?: any;
  totalLabel?: any;
  totalValue?: number;
  pieWidth?: number;
  pieHeight?: number;
  innerRad?: number;
  outerRad?: number;
  top?: any;
  left?: any;
  height?: any;
  contentWidth?: any;
}
export default function CustomizedPieChart({
  data,
  colors,
  totalLabel,
  totalValue,
  pieWidth,
  pieHeight,
  innerRad,
  outerRad,
  top,
  left,
  height,
  contentWidth,
}: pieProp) {
  return (
    <div>
      <div
        style={{
          position: "relative",
          width: "400px",
          height: height,
          margin: "0 auto",
        }}
      >
        <PieChart width={pieWidth} height={pieHeight}>
          <Pie
            data={data}
            innerRadius={innerRad}
            outerRadius={outerRad}
            fill="#605BFF"
            dataKey="value"
          >
            {data
              ? data?.map((entry: any, index: any) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colors[index % colors?.length]}
                  />
                ))
              : []}
          </Pie>
        </PieChart>
        <div
          style={{
            position: "absolute",
            top: top,
            left: left,
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            marginTop: "6px",
          }}
        >
          <p className="text-center font-medium text-base text-[#030229]">
            {totalValue}
          </p>
          <p className="text-center text-[#030229]">{totalLabel}</p>
        </div>
      </div>

      <div
        className={`${contentWidth} m-auto flex flex-wrap gap-2 mt-[10px] items-center content-center justify-center`}
      >
        {data
          ? data?.map((entry: any, index: any) => (
              <div
                key={`legend-${index}`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0 10px",
                }}
              >
                <div
                  style={{
                    width: "15px",
                    height: "15px",
                    backgroundColor: colors[index % colors?.length],
                    marginRight: "8px",
                  }}
                />
                <span style={{ fontSize: "14px", color: "#030229" }}>
                  {entry.name} <span className="pl-5">{entry?.value}</span>
                </span>
              </div>
            ))
          : []}
      </div>
    </div>
  );
}
