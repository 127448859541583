import React, { useState } from "react";
import { Table, Tag, Tooltip } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { searchValue } from "../../utils/setColor";
import { getAgentTransactionAction } from "../../store/setting/actions";
import { useMediaQuery } from "@mui/material";
import { getDefaultCurrencyCode } from "../../utils/helper";
interface DataType {
  [x: string]: any;
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const onChange: TableProps<DataType>["onChange"] = (
  pagination,
  filters,
  sorter,
  extra
) => {
  console.log("params", pagination, filters, sorter, extra);
};

const AgentTranctionsTable = (props: any) => {
  const { auth, setting, layout, company } = useSelector((state: any) => state);
  const dataMapper: any[] = [];
  const [formData, setformData] = React.useState(dataMapper);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  // const [id, setId] = useState("6427fa65fe1c3e7076833f7d");
  const columns: ColumnsType<DataType> = [
    {
      title: "Time",
      dataIndex: "date_time",
      key: "date_time",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      render: (text, record) => <p>{text || "N/A"}</p>,
    },
    {
      title: "Shop",
      dataIndex: "shop",
      key: "shop",
      render: (text, record) => <p>{text || "N/A"}</p>,
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      render: (text, record) => (
        <Tooltip title={`${record.specification}`}>
          <p>{text}</p>
        </Tooltip>
      ),
    },
    {
      title: "SN/Bar Code",
      dataIndex: "barCode",
      key: "barCode",
    },
    {
      title: `Price (${getDefaultCurrencyCode(company)})`,
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <p>{(record?.data?.payment?.amount).toLocaleString()} </p>
      ),
    },
    {
      title: "Extended Warranty",
      dataIndex: "data",
      key: "data",
      render: (text, record) => (
        <p>{record?.data?.payment?.extendedWarranty} %</p>
      ),
    },
    {
      title: "Discount",
      dataIndex: "data",
      key: "data",
      render: (text, record) => (
        <p>{record?.data?.cart?.discount?.discountPerc} %</p>
      ),
    },
    {
      title: "Total Amount",
      dataIndex: "data",
      key: "data",
      render: (text, record) => (
        <p className="font-bold">
          {(
            record?.data?.payment?.amount +
            (record?.data?.payment?.amount *
              record?.data?.payment?.extendedWarranty) /
              100 -
            (record?.data?.payment?.amount *
              record?.data?.cart?.discount?.discountPerc) /
              100
          ).toLocaleString()}
          {getDefaultCurrencyCode(company)}
        </p>
      ),
    },
    {
      title: "Transaction Id",
      dataIndex: "transactionId",
      key: "transactionId",
      render: (text, record: any) => (
        <p
          className="text-sm cursor-pointer text-blue-700"
          onClick={() => navigate(`/pos/transactions/${record.data.cart._id}`)}
        >
          {text}
        </p>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Tag
          color={searchValue(record?.data?.status)}
          className="capitalize min-w-[50px] text-center"
        >
          {record?.data?.status}{" "}
        </Tag>
      ),
    },
  ];

  React.useEffect(() => {
    layout?.viewUser &&
      auth?.token &&
      getAgentTransactionAction(
        auth?.token,
        `?createdBy=${layout?.userInfo?._id}`
      )(dispatch);
  }, [layout?.viewUser, auth, dispatch, layout?.userInfo?._id]);
  const value = setting?.agentTransaction?.data;

  React.useEffect(() => {
    value &&
      value.forEach((el: any) => {
        dataMapper.push({
          date_time: el?.updatedAt?.split("T")[0],
          shop: el?.shopStockItem?.shop?.name,
          model:
            el?.shopStockItem?.requestedItem?.product?.product?.model +
            "~" +
            el?.shopStockItem?.requestedItem?.product?.product?.type,
          specification: `${el?.shopStockItem?.requestedItem?.product?.specification
            ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
            ?.join(", ")}`,
          amount: el?.payment?.amount,
          status: el?.status,
          barCode: el?.warehouseProduct?.serialNumber,
          transactionId: el?.cart?.cartId,
          customer: el?.cart?.customer?.name,
          data: el,
        });
      });

    setformData(dataMapper);
  }, [setting.agentTransaction]);
  const smallDevices = useMediaQuery("(max-width: 1350px)");
  return (
    <Table
      columns={columns}
      dataSource={formData}
      onChange={onChange}
      loading={setting?.isFetching}
      scroll={smallDevices ? { x: 1300 } : undefined}
    />
  );
};

export default AgentTranctionsTable;
