import { Stack } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import TransferGrid from "../../../../components/grids/transfer/TransferGrid";
import { Spin } from "antd";
import React from "react";

import { useSelector } from "react-redux";
import { Box } from "@mui/system";

const PageView = () => {
  const { channel } = useSelector((state: any) => state);

  return (
    <Stack spacing={1}>
      <Box>
        <span className=" text-black font-medium">
          Transfer Requested:
          {/* {channel.isFetching ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          ) : (
            channel?.reqStock?.result
          )} */}
        </span>
        <div className="flex flex-wrap gap-5 justify-start items-start">
          {/* <TransferGrid data={channel?.selectedRfs?.data} /> */}
        </div>
      </Box>
    </Stack>
  );
};

export default PageView;
