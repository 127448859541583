import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SettingButton from "../SettingButton";
import { HiOutlinePlus } from "react-icons/hi";
import { ReactComponent as DeleteSvg } from "../../../../assets/icons/deleteicon.svg";
import { ReactComponent as EditSvg } from "../../../../assets/icons/editicon.svg";
import BusinessProductModal from "../../../../components/Modals/NewBussiProductModal";
import DeleteModal from "../../../../components/Modals/DeleteModal";
import NewRegionForm from "../../../../components/forms/NewRegionsForm";
import {
    deleteRegionAction,
    getAllRegionAction,
} from "../../../../store/channel/actions";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import { Card, Skeleton, Space } from "antd";

const ChannelRegion = () => {
    const { channel, layout, auth } = useSelector((state: any) => state);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [data, setData] = useState<any>("");
    const [dataToDelete, setDataToDelete] = useState<any>("");
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(15);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    const handleOpenModal = (values: any) => {
        setIsModalOpen(true);
        setData(values);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const handleOpenDeleteModal = (values: any) => {
        setDataToDelete(values);
        setOpenDeleteModal(true);
    };
    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
    };
    const deleteRegions = async () => {
        auth?.token &&
            (await deleteRegionAction(dataToDelete, auth?.token, {
                isDeleted: "true",
            })(dispatch));
        setOpenDeleteModal(false);
    };

    const channelId = layout?.channelInfo?._id;

    React.useEffect(() => {
        auth?.token &&
            channelId &&
            getAllRegionAction(
                auth?.token,
                `channel=${channelId}&limit=${limit}&page=${page - 1}`
            )(dispatch);
        //
    }, [dispatch, auth?.token, limit, page, channelId]);
    return (
        <>
            <div className="text-[#030229]">
                <div className="flex justify-between mb-6 mr-4">
                    <div></div>
                    <Space>
                        <SettingButton
                            btnName="New Region"
                            btnBgColor="[#605BFF]"
                            textColor="white"
                            icon={<HiOutlinePlus size={20} />}
                            onClick={handleOpenModal}
                        />
                    </Space>
                </div>
                <ScrollableFrame
                    setPage={setPage}
                    setLimit={setLimit}
                    limit={limit}
                    total={channel?.allRegion?.total}
                    count={Math.ceil(channel?.allRegion?.total / limit)}
                >
                    <div className="flex flex-wrap gap-5">
                        {channel?.isFetching ? (
                            <>
                                {" "}
                                {[1, 2, 3, 4]?.map((el: any) => (
                                    <Card style={{ width: 320, marginTop: 12 }}>
                                        <Skeleton loading={loading} active paragraph={{ rows: 2 }}></Skeleton>
                                    </Card>
                                ))}
                            </>
                        ) : (
                            channel?.allRegion?.data?.map((el: any) => (
                                <div className="w-[20rem] h-[9rem] border border-[#030229] border-opacity-10 rounded-[10px] p-4  relative">
                                    <>
                                        <div>
                                            <div className="my-2">
                                                <p className="text-[.8rem] text-[#030229] opacity-60 font-medium ">
                                                    Name :{" "}
                                                </p>
                                                <h1 className="text-sm text-[#030229] capitalize">
                                                    {el?.name}
                                                </h1>
                                            </div>
                                            <div className="my-2">
                                                <p className="text-[.8rem] text-[#030229] opacity-60 font-medium ">
                                                    Address :{" "}
                                                </p>
                                                <h1 className="text-sm text-[#030229] capitalize">
                                                    {el?.address}
                                                </h1>
                                            </div>
                                        </div>

                                        <div className="flex gap-4 absolute right-4 bottom-4">
                                            <EditSvg
                                                onClick={() => handleOpenModal(el)}
                                                className="cursor-pointer"
                                            />
                                            <DeleteSvg
                                                onClick={() => handleOpenDeleteModal(el?._id)}
                                                className="cursor-pointer"
                                            />
                                        </div>
                                    </>
                                </div>
                            ))
                        )}
                    </div>
                </ScrollableFrame>
            </div>
            <BusinessProductModal
                cancel={handleCloseModal}
                isModalOpen={isModalOpen}
                content={<NewRegionForm data={data} onCancel={handleCloseModal} />}
            />
            <DeleteModal
                visible={openDeleteModal}
                onOk={deleteRegions}
                isLoading={channel?.isFetching}
                onCancel={handleCloseDeleteModal}
                itemName="Region"
            />
        </>
    );
};

export default ChannelRegion;
