import React from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import AgentNosalesTable from "../../../../components/tables/AgentNosalesTable";
import AgentShopsTable from "../../../../components/tables/AgentShopsTable";
import AgentTransactionTable from "../../../../components/tables/AgentTranctionsTable";
import { useSelector } from "react-redux";
import ActivityLogsTable from "../../../../components/tables/ActivityLogs";

const UserDetailsTabs = (props: any) => {
  const { auth, layout, setting } = useSelector((state: any) => state);
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <h1 className="text-base font-medium">
          {" "}
          {["sales-agent", "franchisee", "mtn-sales-agent"]?.includes(
            setting?.selected?.role?.toLowerCase()
          )
            ? "Sales"
            : "Activity Logs"}{" "}
        </h1>
      ),
      children: ["sales-agent", "franchisee", "mtn-sales-agent"]?.includes(
        setting?.selected?.role?.toLowerCase()
      ) ? (
        <AgentTransactionTable />
      ) : (
        <ActivityLogsTable />
      ),
    },
    {
      key: "2",
      label: (
        <h1 className="text-base font-medium">
          {["sales-agent", "franchisee", "mtn-sales-agent"]?.includes(
            setting?.selected?.role?.toLowerCase()
          ) && "Shop"}{" "}
        </h1>
      ),
      children: <AgentShopsTable />,
    },
    {
      key: "3",
      label: (
        <h1 className="text-base font-medium">
          {" "}
          {["sales-agent", "franchisee", "mtn-sales-agent"]?.includes(
            setting?.selected?.role?.toLowerCase()
          ) && "No Sales"}{" "}
        </h1>
      ),
      children: <AgentNosalesTable />,
    },
    {
      key: "4",
      label: (
        <h1 className="text-base font-medium">
          {" "}
          {["sales-agent", "franchisee", "mtn-sales-agent"]?.includes(
            setting?.selected?.role?.toLowerCase()
          ) && "Activity Logs"}{" "}
        </h1>
      ),
      children: <ActivityLogsTable />,
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
};

export default UserDetailsTabs;
