import React from 'react'
interface levelProps{
    name?:any,
    width?:any,
    amount?:any
}
const FinancingLevel = ({name,width,amount}:levelProps) => {
  return (
  
    <div className="flex gap-5 my-3">
    <span className="text-sm font-medium text-[#030229B2] w-[30%]">
      {name}
    </span>
    <div className="w-[50%] bg-[#EFEFFF] rounded-full h-4 mt-1">
    <div className="bg-gradient-to-l from-[#605BFF]  h-4 rounded-fulld" style={{width:width}}></div>
  </div>
    <span className="text-sm font-medium text-[#030229B2] w-[15%] pt-1">
      {amount}
    </span>
  </div>
  )
}

export default FinancingLevel