import { useState } from "react";
import { Paper } from "@mui/material";
import { generatePath, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Badge, Divider, Modal, Space, Tag } from "antd";
import { deleteSupplierActions } from "./../../store/supplier/actions";
import ProfileAvatal from "../buttons/ProfileAvatal";
import SupplierForm from "../../components/forms/NewSupplierForm";
import SupplierDetailsCard from "../cards/SupplierDetailsCard";
import DeleteModal from "../Modals/DeleteModal";
import { searchValue } from "../../utils/setColor";

const Component = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [id, setId] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [data, setData] = useState<any>("");
  const [showData, setShowData] = useState<any>("");
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  const deleteModel = (itemId: any) => {
    setVisible(true);
    setId(itemId);
  };

  const updateModelOpen = (data: any, id: any) => {
    setIsModalOpen(true);
    setIsInfoModalOpen(false);
    setData(data);
    setSupplierId(id);
  };

  const handleCancels = () => {
    setVisible(false);
  };

  const showModelOpen = (items: any) => {
    const Id = items.id;
    const path = generatePath(`/purchase/supplier/${items._id}`, { Id });
    navigate(path);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleInfoCancel = () => {
    setIsInfoModalOpen(false);
  };

  const { itemId, auth, supplier } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const style = {
    width: "100vh",
    color: "black",
    p: 2,
  };

  const handleDelete = (e: any) => {
    e.preventDefault();
    auth?.token &&
      id &&
      deleteSupplierActions(id, auth?.token || "?")(dispatch);
    setVisible(false);
  };
  return (
    <>
      <Badge.Ribbon
        text={
          <span
            style={{
              fontSize: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              fontWeight: 400,
              paddingTop: "1.5px",
            }}
          >
            {props?.data?.data?.status}
          </span>
        }
        color={searchValue(props?.data?.data?.status)}
        style={{ width: "5rem", padding: "3px 1px" }}
      >
        <div
          className="w-[384px] h-[165px] text-[#030229] bg-white border-[.5px] border-[#D9DBE930] rounded-[10px] shadow-[1px_3px_16px_0px_rgba(0,0,0,0.07)] p-6 font-normal"
          onClick={() => showModelOpen(props?.data?.data)}
        >
          <div className="flex gap-6">
            <div style={{ width: "2rem" }}>
              <ProfileAvatal
                picture={props?.data?.data?.logo}
                name={props?.data?.name}
                noActiveBadge={true}
              />
            </div>
            <div className="flex flex-col">
              <h1 className="text-[14px]">{props.data?.name?.toUpperCase()}</h1>
              <p className="text-[14px] text-[#605BFF]">{props.data?.type}</p>
            </div>
          </div>
          <Divider />
          <div className="flex flex-col text-[13px] ">
            <Space>
              <p className="opacity-50 ">Email : </p>
              <p className="opacity-70">{props.data?.email}</p>
            </Space>
            <Space>
              <p className="opacity-50 ">Phone : </p>
              <p className="opacity-70">{props.data?.data.phone}</p>
            </Space>
          </div>
        </div>
      </Badge.Ribbon>
      <DeleteModal
        visible={visible}
        onOk={handleDelete}
        isLoading={supplier.isFetching}
        onCancel={handleCancels}
        itemName="Supplier"
      />

      <Modal
        title={"Update Supplier"}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        className="min-w-min"
      // icon={props?.icon || <HomeIcon />}
      >
        <div>
          <Paper sx={style}>
            <SupplierForm
              data={data}
              supplierId={supplierId}
              onCancel={handleCancel}
            />
          </Paper>
        </div>
      </Modal>
      <Modal
        title={"Supplier Info"}
        open={isInfoModalOpen}
        onCancel={handleInfoCancel}
        footer={null}
        className="min-w-min"
      // icon={props?.icon || <HomeIcon />}
      >
        <div>
          <Paper style={{ width: "100%" }}>
            <div className="flex flex-col pl-4">
              <SupplierDetailsCard showData={showData} />
            </div>
          </Paper>
        </div>
      </Modal>
    </>
  );
};
export default Component;
