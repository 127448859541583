import React, { useEffect } from "react";
import { Spin } from "antd";
import FilesCard from "../cards/NoSale/FilesCard";
import { BiUpload } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { uploadFileAction } from "../../store/file/actions";
import { useParams } from "react-router";
import { getOneDeliveryNoteAction } from "../../store/wareHouse/actions";

const FileUpload = (props: any) => {
  const { auth, file } = useSelector((state: any) => state);
  const { deliveryNoteId } = useParams();
  const dispatch = useDispatch();
  const onCancel = props?.onCancel;

  const onUploadFile = async () => {
    const payload = new FormData();
    payload.append("file", props?.files);
    payload.append(
      "product",
      props?.data?.data?.purchaseItem?.quotationItem?.product?._id
    );
    payload.append("quotationItem", props?.data?.quotationItemId);
    payload.append("warehouseItem", props?.data?._id);

    await uploadFileAction(auth?.token, payload, onCancel)(dispatch);
    await getOneDeliveryNoteAction(
      auth?.token,
      deliveryNoteId as string
    )(dispatch);
  };

  const onChange = (e: any) => {
    props?.setFiles(e.target.files[0]);
  };

  useEffect(() => {
    if (props?.files !== null) {
      onUploadFile();
    }
  }, [props?.files]);

  return (
    <>
      <label
        className="block mb-2 text-sm font-medium cursor-pointer"
        htmlFor="file_input"
      >
        {file?.isFetching ? (
          <Spin />
        ) : (
          <FilesCard
            Icon={BiUpload}
            tailwindTextColor="text-white-400"
            tailwindBackgroundColor="bg-[#605BFF]"
          />
        )}
      </label>
      <input
        className="w-full text-sm rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
        style={{ display: "none" }}
        aria-describedby="file_input_help"
        id="file_input"
        type="file"
        accept=".xlsx, .xls"
        onChange={onChange}
      />
    </>
  );
};

export default FileUpload;
