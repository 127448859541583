import { Dropdown, MenuProps } from "antd";
import React from "react";
import { AiTwotoneEdit } from "react-icons/ai";
import { MdOutlineAssignmentInd } from "react-icons/md";
import * as bsIcons from "react-icons/bs";

const BsThreeDotsDropdown = (props: any) => {
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <label className=" border border-blue-500 p-1 rounded-md">
          <span
            className="text-gray-600 text-[12px] font-normal pt-[5px]"
            onClick={() => props?.onChangePassword()}
          >
              Reset Password
          </span>
        </label>
      ),
    },
    {
      key: "2",
      label: props?.userData?.role?.toLowerCase() === "sales-agent" && (
        <label className="flex gap-2 border border-green-900 rounded-md p-1">
          <MdOutlineAssignmentInd className="pt-1 fill-gray-600" size={20} />{" "}
          <span
            className="text-gray-700 text-[12px] font-normal pt-[5px]"
            onClick={() => props.onAssignShop()}
          >
            {props?.userData?.reference ? "Re-Assign shop" : "Assign shop"}
          </span>
        </label>
      ),
    },
    {
      key: "3",
      label: props?.userData?.role?.toLowerCase() === "partner" && (
        <label className="flex gap-2 border border-green-900 rounded-md p-1">
          <MdOutlineAssignmentInd className="pt-1 fill-gray-600" size={20} />{" "}
          <span
            className="xtext-red-500 text-[12px] font-normal pt-[5px]"
            onClick={() => props.onAssignChannel()}
          >
            {props?.userData?.channel && props?.userData?.channel?.length > 0
              ? "Re-Assign Channel"
              : "Assign Channel"}
          </span>
        </label>
      ),
    },
  ];
  return (
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      className=" float-right mt-3 absolute bottom-4 right-6"
    >
      <bsIcons.BsThreeDots />
    </Dropdown>
  );
};

export default BsThreeDotsDropdown;
