import React from 'react'
interface customProps{
    title?:any;
    icon?:any;
    onClick?:any;
    bgColor?:string;
    width?:string;
    textSize?:string;
    border?:string;
    textColor?:string,
    hoverText?:string,
    hoverbgColor?:string,
}
const CustomButton = ({title,icon,onClick,bgColor,textSize,border,textColor,width,hoverText,hoverbgColor}:customProps) => {
  
  return (
    <button type="submit"  className={`flex gap-2 justify-center items-center border hover:bgs-[#605BFF1A] cursor-pointer border-${border} px-4 py-2 rounded-md bg-${bgColor} w-${width}`} onClick={onClick}>
       <div className='mt-[2dpx]'>{icon}</div> 
       <h1 className={`text-${textColor} text-${textSize} hover:text-${hoverText}`}>{title}</h1> 
        </button>
  )
}

export default CustomButton