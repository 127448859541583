import React, { useState } from "react";
import { employees, Employee } from "./emmployee";
import { Table, Tag } from "antd";
import {
  ManOutlined,
  ToolOutlined,
  UserOutlined,
  WomanOutlined,
} from "@ant-design/icons";
import { ReactComponent as ImportIcons } from "../../../EBM/assets/images/icons/vscode-icons_file-type-excel2.svg";
import { PieChart } from "@mui/x-charts/PieChart";
import actionIcon from "./Group 73.png";
import ArrowIcon from "./Vector.svg";
import { Input, Form } from "antd";
import type { SearchProps } from "antd/es/input/Search";
import DepartmentFiltering from "./DepartmentFiltering";
import { styled } from "@mui/material/styles";
import { GoPlus } from "react-icons/go";
import { ReactComponent as InactiveIcons } from "../../assets/Inactive.svg";
import TwoIconsCard from "../../../EBM/components/cards/TwoIconsCard";
import { ReactComponent as CartIcons } from "../../../EBM/assets/images/icons/Group 1000002857 (1).svg";
import EbmButton from "../../../EBM/components/EbmButton";
import GeneralModal from "../../components/Modal/GeneralModal";
import CustomButton from "../../components/Buttons/CustomButton";
import { FiPlus } from "react-icons/fi";
import AddEmployeeForm from "../../components/Forms/AddEmployeeForm";
const { Search } = Input;

const stats = [
  {
    label: "Active",
    value: 360,
    change: <img src={ArrowIcon} alt="Action Icon" className="xs" />,
    text: "Less than last month",
    icon: <UserOutlined />,
    color: "text-green-500 bg-[#97CE71] text-xs ",
  },
  {
    label: "Inactive",
    value: 30,
    change: (
      <img src={ArrowIcon} alt="Action Icon" className="cursor-pointer" />
    ),
    text: "Increase than last month",
    icon: <InactiveIcons />,
    color: "text-[#605BFF] bg-violet-300 text-xs ",
  },
  {
    label: "Male",
    value: 112,
    change: (
      <img src={ArrowIcon} alt="Action Icon" className="cursor-pointer" />
    ),
    text: "Increase than last month",
    icon: <ManOutlined />,
    color: "text-green-500 bg-[#97CE71] text-xs ",
  },
  {
    label: "Female",
    value: 60,
    change: (
      <img src={ArrowIcon} alt="Action Icon" className="cursor-pointer" />
    ),
    text: "Increase than last month",
    icon: <WomanOutlined />,
    color: "text-green-500 bg-[#97CE71] text-xs",
  },
  {
    label: "Permanent",
    value: 60,
    change: (
      <img src={ArrowIcon} alt="Action Icon" className="cursor-pointer" />
    ),
    text: "Less than last month",
    icon: <UserOutlined />,
    color: "text-green-500 bg-[#97CE71] text-xs ",
  },
  {
    label: "Contract",
    value: 100,
    change: (
      <img src={ArrowIcon} alt="Action Icon" className="cursor-pointer" />
    ),
    text: "Increase than last month",
    icon: <UserOutlined />,
    color: "text-green-500 bg-[#97CE71] text-xs ",
  },
  {
    label: "Interns",
    value: 2,
    change: (
      <img src={ArrowIcon} alt="Action Icon" className="cursor-pointer" />
    ),
    text: "Increase than last month",
    icon: <UserOutlined />,
    color: "text-green-500 bg-[#97CE71] text-xs ",
  },
  {
    label: "With Equipments & Tools",
    value: 72,
    change: (
      <img src={ArrowIcon} alt="Action Icon" className="cursor-pointer" />
    ),
    text: "Increase than last month",
    icon: <ToolOutlined />,
    color: "text-green-500 bg-[#97CE71] text-xs ",
  },
];

const data = [
  { name: "Finance", value: 7, color: "#6C5DD3" },
  { name: "Dev Team", value: 10, color: "#807CFF" },
  { name: "Sales", value: 30, color: "#A09DFF" },
  { name: "Agents", value: 100, color: "#BFBDFF" },
  { name: "Inventory", value: 5, color: "#DFDEFF" },
];

const CenteredLabel = styled("div")({
  position: "absolute",
  top: "50%",
  left: "80%",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
  color: "#030229",
});

const BoldText = styled("p")({
  fontSize: "18px",
  fontWeight: "bold",
  margin: 0,
});

const NormalText = styled("p")({
  fontSize: "18px",
  fontWeight: "normal",
  margin: 0,
});

const Department: React.FC = () => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Employment Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Starting Date",
      dataIndex: "startingDate",
      key: "startingDate",
    },
    {
      title: "Equipments & Tools",
      dataIndex: "equipment",
      key: "equipment",
    },
    {
      title: "Status",
      key: "active",
      render: (_: any, record: Employee) => (
        <Tag color={record.active ? "green" : "volcano"} className="rounded-md">
          {record.active ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: Employee) => (
        <img src={actionIcon} alt="Action Icon" className="cursor-pointer" />
      ),
    },
  ];

  const onSearch: SearchProps["onSearch"] = (value, _e, info) =>
    console.log(info?.source, value);

  const colors = ["#3930A8", "#807CFF", "#78CFFF", "#BBD9FC", "#030229"];
  const size = {
    width: 400,
    height: 200,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (data: any) => {
    // setSelectedData(data)
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="p-4">
      <div className="flex justify-between py-3">
        <h1 className="text-[#030229] text-lg">Department Management</h1>
        <div className="flex gap-5">
          <EbmButton
            btnName={"Import"}
            icon={<ImportIcons />}
            btnBgColor="white"
          />
          <EbmButton
            btnName={"Export"}
            icon={<ImportIcons />}
            btnBgColor="white"
          />
          <CustomButton
            title={"Add New Employee"}
            bgColor="[#605BFF]"
            textColor="white"
            icon={<FiPlus color="white" />}
            onClick={showModal}
          />
        </div>
      </div>
      <GeneralModal
        openModal={isModalOpen}
        closeModal={handleOk}
        title={
          <h1 className="text-[#030229] font-semibold text-lg text-center pt-2">
            Add Employee
          </h1>
        }
        component={
          <div className="px-4 h-full w-full">
              <AddEmployeeForm/>
          </div>
        }
        width={800}
      />
      <div className="flex flex-col xl:flex-row items-stretch mb-4 gap-5">
        <div className="bg-white p-4 mt-6 lg:w-[60%] xl:w-[50%] 2xl:w-[35%] h-[335px] rounded-md relative">
          <h2 className="text-lg font-poppins mb-4 text-black">Departments</h2>
          <div className="flex flex-row items-center justify-between">
            <div className="w-[40%] text-black relative">
              <PieChart
                series={[{ data, innerRadius: 65 }]}
                colors={colors}
                {...size}
              />
              <CenteredLabel>
                <BoldText>5</BoldText>
                <NormalText>Total Employee</NormalText>
              </CenteredLabel>
            </div>
            <div className="text-center mt-6 align-center">
              <ul className="list-none p-0 flex flex-col items-start">
                {data.map((entry, index) => (
                  <li
                    key={index}
                    className="flex items-center justify-start mb-2 text-gray-700"
                  >
                    <span
                      className="inline-block w-3 h-3 rounded-full mr-2"
                      style={{ backgroundColor: entry.color }}
                    ></span>
                    {entry.name} ({entry.value})
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="grid sm:grid-cols-2 2xl:grid-cols-3 gap-5 mt-6 w-full">
          {stats.map((stat, index) => (
            <TwoIconsCard
              amount={`${stat.value}`}
              title={`${stat.label}`}
              name={`${stat.text}`}
              icon={<GoPlus color="#43900C" />}
              iconTwo={<CartIcons />}
            />
          ))}
        </div>
      </div>
      <div className="bg-white rounded-md w-full overflow-scroll">
        <div className="flex flex-col md:flex-row  items-center justify-between pb-3 px-3">
          <h2 className="text-black text-lg">Employees</h2>
          <div className="flex flex-row items-center">
            <Search
              placeholder="input search text"
              onSearch={onSearch}
              enterButton
            />
            <DepartmentFiltering />
          </div>
        </div>
        <Table columns={columns} dataSource={employees} rowKey="name" />
      </div>
    </div>
  );
};

export default Department;
