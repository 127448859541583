import React, { useEffect, useState } from "react";
import { Space, Switch, Table, Tag } from "antd";
import type { TableProps } from "antd";
import HeaderComponent from "../../../../components/HeaderChannel";
import DiscountForm from "../../../../components/forms/DiscountForm";
import {
  getAllDiscountAction,
  updateDiscountAction,
} from "../../../../store/discount/actions";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import ViewDetailsDrower from "./ViewDetailsDrower";
import DeleteModal from "../../../../components/Modals/DeleteModal";

interface DataType {
  key: any;
  name: string;
  percentage: number;
  shop: string;
  model: string;
  productModel: string;
  _id: string;
  isAllowedToAll: boolean;
  isInUse: boolean;
}

const Discount = () => {
  const { auth, discount } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [openDrower, setOpenDrower] = useState(false);
  const [isDeleteModel, setIsDeleteModel] = useState(false);
  const [isUpdateModel, setIsUpdateModel] = useState(false);
  const [dataToShow, setDataToShow] = useState(null);
  const [dataToUpdate, setDataToUpdate] = useState(null);
  const [dataToDelete, setDataToDelete] = useState<any>(null);

  const showDrawer = (value: any) => {
    setDataToShow(value);
    setOpenDrower(true);
  };
  const handleCloseDrawer = () => {
    setOpenDrower(false);
    setDataToShow(null);
  };
  const handleOpenDeleteModel = (val: any) => {
    setIsDeleteModel(true);
    setDataToDelete(val);
  };
  const handleCancelDelete = () => {
    setIsDeleteModel(false);
  };

  const handleOpenUpdateModel = (value: any) => {
    setIsUpdateModel(true);
    setDataToUpdate(value);
  };

  const onChangeAvailability = (checked: boolean, key: any) => {
    updateDiscountAction(key, auth?.token, { isInUse: checked }, `?`)(dispatch);
  };

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Percentage (%)",
      dataIndex: "percentage",
      key: "percentage",
    },
    {
      title: "Model",
      key: "tags",
      dataIndex: "tags",
      render: (_, { model }) => <Tag color={"green"}>{model}</Tag>,
    },
    {
      title: "Shops",
      key: "tags",
      dataIndex: "tags",
      render: (_, { shop }) => <Tag color={"blue"}>{shop}</Tag>,
    },
    {
      title: "Active",
      key: "inUse",
      dataIndex: "inUse",
      render: (text, record) => (
        <Switch
          className="pt-[4.3px]"
          checkedChildren="Yes"
          unCheckedChildren="No"
          defaultChecked={text}
          onChange={(checked) => onChangeAvailability(checked, record?.key)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      width: 150,
      render: (_, record: any) => (
        <Space size="middle">
          {(record?.data?.productModel?.length !== 0 ||
            !record?.data?.isAllowedToAll) && (
            <EyeOutlined
              className="text-green-500"
              onClick={() => showDrawer(record)}
            />
          )}
          <EditOutlined
            className="text-blue-500"
            onClick={() => handleOpenUpdateModel(record)}
          />
          <DeleteOutlined
            className="text-red-500"
            onClick={() => handleOpenDeleteModel(record)}
          />
        </Space>
      ),
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
    setIsUpdateModel(false);
    setDataToUpdate(null);
  };

  useEffect(() => {
    if (auth?.token) {
      getAllDiscountAction(auth?.token, `?`)(dispatch);
    }
  }, [auth?.token, dispatch]);



  const dataToDisplay = discount?.all?.data?.map((el: DataType) => {
    return {
      key: el?._id,
      name: el?.name,
      percentage: el?.percentage,
      inUse: el?.isInUse,
      model:
        el?.productModel?.length === 0
          ? "All Models"
          : el?.productModel?.length,
      shop: el?.isAllowedToAll ? "All Shops" : el?.shop?.length,
      data: el,
    };
  });

  const handleDelete = async () => {
    const res = await updateDiscountAction(
      dataToDelete?.key,
      auth?.token,
      { isDeleted: true },
      `?`
    )(dispatch);
    if (res) {
      handleCancelDelete();
    }
  };

  return (
    <>
      <div className="mt-10">
        <HeaderComponent
          item="Discount"
          // filterCard={<ChannelFilter />}
          isNotCollapse={true}
          ModelComponent={
            <DiscountForm onCancel={handleCancel} dataToUpdate={dataToUpdate} />
          }
          isModalOpen={isModalOpen || isUpdateModel}
          setIsModalOpen={setIsModalOpen}
          setDataToUpdate={setDataToUpdate}
          handleCancel={handleCancel}
        />
        <Table
          columns={columns}
          dataSource={dataToDisplay}
          loading={discount?.isFetching}
        />
      </div>
      <ViewDetailsDrower
        isOpen={openDrower}
        onClose={handleCloseDrawer}
        data={dataToShow}
      />
      <DeleteModal
        visible={isDeleteModel}
        onOk={handleDelete}
        onCancel={handleCancelDelete}
        itemName={dataToDelete?.name}
        isLoading={discount?.isFetching}
      />
    </>
  );
};

export default Discount;
