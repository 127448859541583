import { useState } from "react";
import DeliverDetails from "./deliverDetails";
import DeliverySideNav from "./DeliverySideNav";
import QRCode from "./QRCode";
import PreviewData from "./PreviewData";
import DeliverySteps from "./DeliverySteps";
const CompleteDeliverySteps = () => {
  const [showScan, setShowScan] = useState(false);
  const [viewDetailsData, setViewDetailsData] = useState(false);
  const [currentStep,setCurrentStep]=useState(0)
  const styles = {
    customShadow: {
      boxShadow: "0px 2px 24px 0px rgba(15, 15, 71, 0.07)",
    },
  };
  return (
    <>
      <div className="relative">
        <div className="bg-white w-full h-[100vh] ">
          <div className="w-10/12 flex gap-2 m-auto py-10 h-full">
            <div className="w-[25%] h-full rounded-md hidden xl:block">
              <DeliverySideNav currentStep={currentStep}/>
            </div>
            <div
              className="rounded-md w-full xl::w-[72%] h-full"
            >
              {/* {showScan && !viewDetailsData && <QRCode />}
              {!showScan && <DeliverDetails setShowScan={setShowScan} />}
              {viewDetailsData && <PreviewData />} */}
              <DeliverySteps setCurrentStep={setCurrentStep}/>
            </div>
          </div>
        </div>
        {/* <div className="absolute bottom-20 left-5 md:left-20">
          {showScan ? (
            <button
              className={`bg-[#605BFF] text-white pl-32 border w-[20rem] border-[#605BFF] font-normal text-base text-center rounded-md py-2 px-6 flex`}
              onClick={() => setViewDetailsData(true)}
            >
              Continue
            </button>
          ) : (
            <button
              className={`bg-[#605BFF] text-white pl-32 border w-[20rem] border-[#605BFF] font-normal text-base text-center rounded-md py-2 px-6 flex`}
              onClick={() => setShowScan(true)}
            >
              Continue
            </button>
          )}
        </div> */}
      </div>
    </>
  );
};
export default CompleteDeliverySteps;
