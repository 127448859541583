import { Form, Input } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  CreateBusinessAction,
  getAllBusinessAction,
  updateBusinesAction,
} from "../../store/setting/company/actions";
import TextArea from "antd/es/input/TextArea";

const NewBusinessProductForm = (props: any) => {
  const { auth, company } = useSelector((state: any) => state);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const initialData = {
    ...(props?.businessToUpdate || {}),
  };
  // Check if updating an existing business
  const isUpdate = !!props?.businessToUpdate?._id;

  const onFinish = async (values: any) => {
    auth?.token &&
      !props?.businessToUpdate?._id &&
      (await CreateBusinessAction(auth?.token, {
        ...values,
        termsAndCondition: {
          details: values?.details,
        },
      })(dispatch));
    if (props?.businessToUpdate?._id && auth?.token) {
      await updateBusinesAction(
        props?.businessToUpdate?._id,
        {
          ...values,
          termsAndCondition: {
            details:
              values?.details ??
              props?.businessToUpdate?.termsAndCondition?.at(0)?.details
          },
        },
        auth?.token
      )(dispatch);
      getAllBusinessAction(auth?.token, "?")(dispatch);
    }
    form.resetFields();
    props?.onCancel();
  };
  useEffect(() => {
    form.setFieldsValue(props?.businessToUpdate);
  }, [props?.businessToUpdate]);
  return (
    <div className="mt-8 w-[35rem] px-8 ">
      <h1 className="text-base font-medium text-center mb-4">
        {isUpdate ? "Update Business Product" : "Create Business Product"}
      </h1>
      <Form
        layout="vertical"
        form={form}
        name="register"
        onFinish={onFinish}
        initialValues={initialData}
      >
        <div>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input name!" }]}
          >
            <Input className="w-full" />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: "Please description!" }]}
            style={{ width: "100%" }}
          >
            <TextArea
              placeholder="Description"
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
          </Form.Item>
          <Form.Item
            label="Terms & Conditions"
            name="details"
            style={{ width: "100%" }}
          >
            <TextArea
              placeholder="Terms and Conditions"
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
          </Form.Item>
        </div>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <LoadingButton
            sx={{ textTransform: "none", minWidth: "50%" }}
            type="submit"
            variant="contained"
            loading={company?.isFetching}
          >
            {isUpdate ? "Update" : "Save"}
          </LoadingButton>
        </Form.Item>
      </Form>
    </div>
  );
};
export default NewBusinessProductForm;
