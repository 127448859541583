import { useNavigate } from "react-router-dom";
import { Badge, Divider } from "antd";
import { searchValue } from "../../../utils/setColor";

const Component = (props: any) => {
  const navigate = useNavigate();

  return (
    <>
      <Badge.Ribbon
        text={
          <span
            style={{
              fontSize: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              fontWeight: 400,
              paddingTop: "1.5px",
            }}
            className="capitalize"
          >
            {props?.data?.status}
          </span>
        }
        style={{ padding: "4px 4px" }}
        color={searchValue(props?.data?.status)}
      >
        <div
          className="min-h-[195px] bg-white p-2 rounded-md hover:border-black relative shadow-[1px_3px_16px_0px_rgba(0,0,0,0.07)] text-[#030229] "
          onClick={() => navigate(`/inventory/ws/out/req/${props.data.id}`)}
        >
          <div className="pt-2 px-1">
            <div className="text-base space-y-2">
              <h4 className="font-normal text-[14px] leading-3 text-[#030229] font-poppins">
                {props?.data?.requestId}
              </h4>
              <p className="w-60 text-[13px] font-normal text-blue-400">
                {props?.data?.shopName}
              </p>
            </div>
          </div>
          <Divider className="" />
          <div className="flex -mt-2 justify-between items-end w-full">
            <div>
              <p className="opacity-80 text-[12px] font-normal leading-normal">
                Approved As:{" "}
                <span className="font-semibold text-xs">
                  {props?.data?.data?.isTransferred
                    ? "Transfer"
                    : "Warehouse Out"}
                </span>
              </p>
              <p className="opacity-80 text-[12px] font-normal leading-normal">
                Approved By:{" "}
                <span className="font-semibold text-xs">
                  {props?.data?.data?.approvedBy?.names}
                </span>
              </p>
              <p className="opacity-80 text-[12px] font-normal leading-normal">
                Approved On:{" "}
                <span className="font-semibold text-xs">
                  {props?.data?.data?.updatedAt?.split("T")[0]}
                </span>
              </p>
              <p className="opacity-80 text-[12px] font-normal leading-normal">
                Created On:{" "}
                <span className="font-semibold text-xs">
                  {props?.data?.date}
                </span>
              </p>
            </div>
            <p className="text-[14px] font-medium leading-normal pr-3">
              {props?.data?.numberReq} Items
            </p>
          </div>
        </div>
      </Badge.Ribbon>
    </>
  );
};

export default Component;
