import React, { useEffect } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getebmopenAndclosingStockAction } from "../../../../store/ebm/actions";


const OpeningClosingTable = (props: any) => {
    const { auth, ebm } = useSelector((state: any) => state);
    const dispatch = useDispatch();

    const columns = [
        {
            title: "Item Code",
            dataIndex: "code",
        },
        {
            title: "Item Name",
            dataIndex: "name",
        },
        {
            title: "Open Stock",
            dataIndex: "open",
        },
        {
            title: "Close Stock",
            dataIndex: "close",
        },
        {
            title: "Current Stock",
            dataIndex: "current",
        },
        {
            title: "Unit Price",
            dataIndex: "unit",
        },
        {
            title: "Total Amount",
            dataIndex: "amount",
        },
    ];

    const dataSource =
        ebm?.openAndClosingStock?.data?.map((item: any) => ({
            key: item._id,
            code: item.itemCode,
            name: item.itemName,
            open: item.openigStock,
            close: item.closingStock,
            current: item.currentStock,
            unit: item.unitPrice?.toLocaleString(),
            amount: (item.currentStock * item.unitPrice)?.toLocaleString(),
        })) || [];

    return (
        <>
            <Table
                columns={columns}
                dataSource={dataSource}
                size="middle"
                loading={ebm.isFetching}
            />
        </>
    );
};

export default OpeningClosingTable;



