import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

const CustomerVaultSkeleton = () => {
  return (
    <Stack>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Skeleton variant="text" sx={{ width: 50 }} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" sx={{ width: 50 }} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" sx={{ width: 50 }} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" sx={{ width: 50 }} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(6)].map((_, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Skeleton variant="text" sx={{ width: 250 }} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" sx={{ width: 250 }} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" sx={{ width: 250 }} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" sx={{ width: 250 }} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
export default CustomerVaultSkeleton;
