import React from "react";
import type { MenuProps } from "antd";
import { Button, Dropdown, Popconfirm } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { updateRepairStatusAction } from "../../../store/repair/actions";

const ThreeDotDropdown = (props: any) => {
  const { auth, repairs } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  let items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Button
          type="primary"
          style={{ color: "white", background: "blue", width: 135 }}
          onClick={() => props?.onShowDetails()}
        >
          View
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          className="capitalize min-w-[50px] bg-green-500 hover:bg-green-500 text-white text-center border-none outline-none"
          style={{ color: "white", width: 135 }}
          onClick={() =>
            props?.record.repairedAt === "shop"
              ? updateRepairStatusAction(auth?.token, props?.record?.key, {
                  status: "completed",
                })(dispatch)
              : props?.onCompleteModel()
          }
          disabled={[
            "done",
            "completed",
            "transit-out",
            "pending",
            "cancelled",
            "send-to-office",
            "swap",
          ]?.includes(props?.record?.status)}
          loading={repairs?.isFetching}
        >
          complete
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <>
          {props?.record?.status === "pending" ? (
            <Popconfirm
              title="Reject request"
              description={`Are you sure you want to deny the repair request for IMEI ${props?.record?.imei}?`}
              okButtonProps={{
                loading: repairs?.isFetching,
                className: "bg-blue-500",
              }}
              onConfirm={props?.handleRejectReq}
              okText="Yes"
              cancelText="No"
              disabled={props?.record?.status !== "pending"}
            >
              <Button
                danger
                disabled={props?.record?.status !== "pending"}
                onClick={() => props?.onReject()}
                style={{ width: 135 }}
              >
                {" "}
                Reject
              </Button>
            </Popconfirm>
          ) : (
            <Popconfirm
              title="In-Repairing"
              description="Are you sure this request has arrived?"
              okButtonProps={{
                loading: repairs?.isFetching,
                className: "bg-blue-500",
              }}
              onConfirm={props?.handleRepairingReq}
              okText="Yes"
              cancelText="No"
              disabled={props?.record?.status !== "transit-in"}
            >
              <Button
                style={{
                  width: 135,
                  color: "white",
                  backgroundColor: `${
                    props?.record?.status === "transit-in" ? "blue" : "#dce0dc"
                  } `,
                }}
                disabled={props?.record?.status !== "transit-in"}
                onClick={() => props?.onArrival()}
              >
                {" "}
                Arrived
              </Button>
            </Popconfirm>
          )}
        </>
      ),
    },
    {
      key: "4",
      label: (
        <Button
          style={{
            color: "white",
            backgroundColor: `${
              ![
                "done",
                "completed",
                "transit-out",
                "cancelled",
                "swap",
              ]?.includes(props?.record?.status)
                ? "blue"
                : "#dce0dc"
            } `,
          }}
          disabled={[
            "done",
            "completed",
            "transit-out",
            "cancelled",
            "swap",
          ]?.includes(props?.record?.status)}
          onClick={() => props?.onSwap()}
        >
          {" "}
          Swap Device
        </Button>
      ),
    },
  ];

  return (
    <Dropdown menu={{ items }} placement="bottom">
      <BsThreeDotsVertical color="black" />
    </Dropdown>
  );
};

export default ThreeDotDropdown;
