import React, { useEffect, useState } from "react";
import { Form, Input, notification } from "antd";
import {
  createPermissionAction,
  updatePermissionAction,
} from "../../store/setting/roleAndPermissions/actions";
import { useDispatch, useSelector } from "react-redux";
import { WithContext as ReactTags } from "react-tag-input";
import { LoadingButton } from "@mui/lab";
import "../../styles/permissionStyles.css";
import "react-dual-listbox/lib/react-dual-listbox.css";

const PermissionForm = (props: any) => {
  const { auth, roleAndPermission } = useSelector((state: any) => state);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [tags, setTags] = useState<any>([]);

  const accessKeyToUpdates =
    props?.dataToUpdate &&
    props?.dataToUpdate?.access_keys?.map((d: any) => ({
      id: d,
      text: d,
    }));

  const initializeFormValues = () => {
    form.setFieldsValue({
      name: props?.dataToUpdate?.permission || "",
      description: props?.dataToUpdate?.content || "",
    });

    // Set access keys if dataToUpdate is available
    if (props?.dataToUpdate?.access_keys) {
      const accessKeyToUpdates = props?.dataToUpdate?.access_keys.map(
        (d: any) => ({
          id: d,
          text: d,
        })
      );
      setTags([...accessKeyToUpdates]);
    }
  };

  useEffect(() => {
    initializeFormValues();
  }, [props.dataToUpdate]);

  const accessKeys = tags?.map((el: any) => {
    return {
      key: el?.text,
    };
  });
  const onFinish = async (values: any) => {
    if (accessKeys?.length === 0) {
      return notification.error({ message: "Access shouldn't be Empty" });
    }

    if (auth?.token) {
      if (props?.dataToUpdate) {
        await updatePermissionAction(auth?.token, props?.dataToUpdate?.key, {
          ...values,
          access_keys: accessKeys,
        })(dispatch);
      } else {
        await createPermissionAction(auth?.token, {
          ...values,
          access_keys: accessKeys,
        })(dispatch);
      }
    }
    form.resetFields();
    props?.onCancel();
  };

  const handleDelete = (i: any) => {
    setTags(tags.filter((tag: any, index: any) => index !== i));
  };
  
  const handleAddition = (tag: any) => {
    const updatedTag = { ...tag, text: tag.text.replace(/\s/g, "_") };
    setTags([...tags, updatedTag]);
  };

  return (
    <>
      <Form
        form={form}
        name="validateOnly"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        style={{ maxWidth: "100%", margin: "30px" }}
      >
        <Form.Item
          name="name"
          label={
            <span className="text-[#030229] pl-1 text-sm font-normal">
              Permission Name
            </span>
          }
          className="w-full"
          rules={[
            {
              required: props?.dataToUpdate?.permission ? false : true,
              message: "Permission Name is required!",
            },
          ]}
        >
          <Input className="pl-4 h-[42px] w-[100%] " />
        </Form.Item>
        <Form.Item
          name="description"
          label={
            <span className="text-[#030229] pl-1 text-sm font-normal">
              Description
            </span>
          }
          className="w-full"
        >
          <Input className="pl-4 h-[42px] w-[100%] " />
        </Form.Item>
        <Form.Item
          label={
            <span className="text-[#030229] pl-1 text-sm font-normal">
              Access
            </span>
          }
          className="w-full"
        >
          <ReactTags
            tags={tags}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            inputFieldPosition="bottom"
            placeholder="Press Enter to add new Access Key"
            autocomplete
            // classNames={{
            //   tags: "my-custom-tags",
            //   tagInput: "my-custom-tag-input",
            //   tagInputField: "my-custom-tag-input-field",
            //   selected: "my-custom-selected",
            //   tag: "my-custom-tag",
            //   remove: "my-custom-remove",
            //   suggestions: "my-custom-suggestions",
            //   activeSuggestion: "my-custom-active-suggestion",
            // }}
          />
        </Form.Item>
        <Form.Item>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ minWidth: "100%" }}
            loading={roleAndPermission?.isFetching}
          >
            {props?.dataToUpdate ? "Update" : "Save"}
          </LoadingButton>
        </Form.Item>
      </Form>
    </>
  );
};
export default PermissionForm;
