import React, { useEffect } from "react";
import { Table } from "antd";
import type { MenuProps } from "antd";
import { GrFormView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getAllStockStatusAction } from "../../store/action";
const StockStatusTable = (props: any) => {
  const { auth, ebmGeneral } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const items: MenuProps["items"] = [
    {
      label: (
        <div className="flex gap-2">
          <GrFormView size={25} />
          <span className="text-sm pt-1">View Details</span>
        </div>
      ),
      key: "0",
      onClick: () => props?.open(),
    },
    {
      label: (
        <div className="flex gap-3">
          <FiEdit size={22} />
          <span className="text-sm pt-1">Modify</span>
        </div>
      ),
      key: "1",
      onClick: () => props?.modalOpen(),
    },
    {
      label: (
        <div className="flex gap-2">
          <MdDeleteOutline size={25} />
          <span className="text-sm pt-1">Delete</span>
        </div>
      ),
      key: "3",
    },
  ];
  const columns = [
    {
      title: "Item Code",
      dataIndex: "code",
    },
    {
      title: "Item Name",
      dataIndex: "name",
    },
    {
      title: "Finished Product",
      dataIndex: "finished",
    },
    {
      title: "Expiration Date",
      dataIndex: "date",
    },
    {
      title: "Raw Material",
      dataIndex: "material",
    },
    {
      title: "Current stock",
      dataIndex: "current",
    },
    {
      title: "",
      dataIndex: "status",
      render: (text: any) => (
        <>
          <button className="border border-[#605BFF] px-3 py-1  rounded-lg text-[#605BFF]">
            Adjust Stock
          </button>
        </>
      ),
    },
  ];
  useEffect(() => {
    auth?.token && getAllStockStatusAction(auth?.token, "")(dispatch);
  }, [dispatch, auth]);
  const data = ebmGeneral?.stockStatus?.data?.map((el: any) => {
    return {
      key: el?._id,
      code: el?.itemCode,
      name: el?.itemName,
      finished: (el?.itemType === "2" && el?.qty) || 0,
      date: el?.updatedAt?.slice(0, 10),
      material: (el?.itemType === "1" && el?.qty) || 0,
      current: el?.qty,
    };
  });
  return (
    <>
      <Table columns={columns} dataSource={data} size="middle" pagination={false} />
    </>
  );
};
export default StockStatusTable;