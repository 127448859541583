import DashLayout from "../../components/layout/Layout";
const PageView = (props: any) => {
  return (
    <DashLayout>
      <h1>B2B</h1>
    </DashLayout>
  );
};

export default PageView;
