import React, { useEffect } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import { updatePoAction } from "../../store/product/actions";
import { Link } from "react-router-dom";

const columns: ColumnsType<any> = [
  {
    title: "Models",
    dataIndex: "model",
    key: "model",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Description",
    dataIndex: "specification",
    key: "specification",
  },
  {
    title: "Quantity",
    dataIndex: "qty",
    key: "qty",
  },
  {
    title: "QTY Left To Deliver",
    dataIndex: "quantityLeft",
    key: "quantityLeft",
  },
  {
    title: "Price/Unit",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "Total Cost",
    dataIndex: "totalCost",
    key: "totalCost",
  },
];

const App = (props: any) => {
  const dispatch = useDispatch();
  const { product, proforma } = useSelector(
    (state: any) => state
  );

  const dataMapper: any[] = [];
  const value = product?.selected?.listOfProducts;

  const [data, setData] = React.useState(dataMapper);

  const handleProductsToUpdateStatus = () => {
    props.id && updatePoAction("", props.id, { status: "Accepted" })(dispatch);
  };

  useEffect(() => {
    value &&
      value.forEach((el: any) => {
        el &&
          dataMapper.push({
            model: el.quotationItem.product.model,
            specification: el.quotationItem?.specification
              ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
              ?.join(", "),
            qty: el.quantity,
            quantityLeft: el.quantityLeft,
            validityDate: "",
            price: (+el.priceUnit).toLocaleString(),
            poItem: el._id,
            totalCost: (el.quantity * el.priceUnit).toLocaleString(),
          });
      });

    setData(dataMapper);
  }, [product.selected]);

  return (
    <Stack alignItems="end" spacing={2} sx={{ width: "100%" }}>
      <Table columns={columns} dataSource={data} style={{ width: "100%" }} />
      {product?.selected?.status !== "Accepted" &&
      product?.selected?.status !== "Completed" ? (
        <LoadingButton
          onClick={handleProductsToUpdateStatus}
          variant="contained"
          // endIcon={<SendIcon />}
          loading={proforma.isFetching}
        >
          Accept
        </LoadingButton>
      ) : (
        <LoadingButton variant="contained" loading={proforma.isFetching}>
          <Link to={`/delivery/note/${props.id}`}>Make Delivery Note</Link>
        </LoadingButton>
      )}
    </Stack>
  );
};

export default App;
