import React, { useEffect, useState } from "react";
import DevicesSummary from "./devicesSummary";
import { ReactComponent as ReacdyForUseDevice } from "../../../../assets/images/vault/icons/gift.svg";
import { ReactComponent as Released } from "../../../../assets/images/vault/icons/streamline_travel-map-navigation-arrow-compass-arrow-map-bearing-navigation-maps-heading-gps.svg";
import { ReactComponent as Locked } from "../../../../assets/images/vault/icons/lock.svg";
import { ReactComponent as Active } from "../../../../assets/images/vault/icons/tabler_activity-heartbeat.svg";
import { Space, Table, Tag, Select } from "antd";
import type { ColumnsType } from "antd/es/table";
import SearchInput from "../../../../components/buttons/SearchButton";
import * as piIcons from "react-icons/pi";
import DetailsHeaderActionBar from "../../../../components/details/supplier/DetailsPage/DetailsHeaderActionBar";
import { useDispatch, useSelector } from "react-redux";
import {
  getDevicesStatusAction,
  getVaultDeviceAction,
} from "../../../../store/vault/actions";
import DeviceDetails from "./deviceDetails";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";

interface DataType {
  key: string;
  imei: string;
  brand: string;
  model: string;
  lastChange: string;
  lastCheckIn: string;
  status: any;
}

const PostPaidDevices = () => {
  const { auth, vault } = useSelector((state: any) => state);
  const [searchKey, setSearchKey] = useState<string>();
  const dispatch = useDispatch();
  const [viewDetail, setViewDetails] = useState(false);
  const [stateInfo, setStateInfo] = useState("");
  const [data, setData] = useState<any>();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);

  useEffect(() => {
    if (auth?.token) {
      getVaultDeviceAction(
        auth?.token,
        `?limit=${limit}&page=${page - 1
        }&serviceDetails=POSTPAID&stateInfo=${stateInfo}`
      )(dispatch);
      getDevicesStatusAction(
        auth?.token,
        `?&serviceDetails=POSTPAID`
      )(dispatch);
    }
  }, [auth?.token, limit, page, stateInfo, dispatch]);

  const handleChange = (values: any) => {
    setStateInfo(values);
  };

  const dataPostPaid =
    vault?.allDevice?.data?.map((el: any, index: number) => {
      return {
        key: index,
        imei: el?.deviceUniqueId,
        brand: el?.deviceManufacturer,
        model: el.deviceModel,
        lastChange: el?.lastChanged?.slice(0, 10),
        lastCheckIn: el?.lastCheckIn?.slice(0, 10),
        status: el?.stateInfo,
        data: el,
      };
    }) || [];

  const styles: any = {
    boxShadow: "1px 4px 24px 0px rgba(3, 2, 41, 0.06)",
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "BRAND",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "MODEL",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "CURRENT STATUS",
      key: "status",
      dataIndex: "status",
      render: (_, { status }) => (
        <>
          <Tag
            key={status}
            className={`py-1 w-24 text-center rounded-full ${status === "Active"
                ? "text-[#0FA91E] border border-[#0FA91E80]"
                : status === "Locked"
                  ? "text-[#FA3659] border border-[#FA365980]"
                  : status === "Released"
                    ? "text-[#2943D6] border border-[#2943d622]"
                    : status === "Ready For Use"
                      ? "text-[#F19F1D] border border-[#2943d622]"
                      : "text-[#2943D6] border border-[#2943d622]"
              } `}
          >
            {status}
          </Tag>
        </>
      ),
    },
    {
      title: "LAST CHANGE",
      dataIndex: "lastChange",
      key: "lastChange",
    },
    {
      title: "LAST CHECK-IN",
      dataIndex: "lastCheckIn",
      key: "lastCheckIn",
    },
    {
      title: "ACTION",
      key: "action",
      render: (_, record) => (
        <Space>
          <piIcons.PiEyeLight
            size={20}
            className="cursor-pointer"
            onClick={() => {
              setData(record);
              setViewDetails(true);
              return {};
            }}
          />
        </Space>
      ),
    },
  ];

  const handleSearch = () => {
    if (searchKey && auth?.token) {
      getVaultDeviceAction(
        auth?.token,
        `?serviceDetails=POSTPAID&imei=${searchKey}`
      )(dispatch);
    }
  };
  return (
    <>
      {!viewDetail ? (
        <div>
          <DetailsHeaderActionBar
            pageName={"Manage devices"}
            title="PostPaid Devices"
          />
          <div className="w-full h-[119px] bg-white" style={styles}>
            <div className="flex gap-5 divide-x pt-3">
              <DevicesSummary
                status={"Ready for use"}
                value={
                  vault?.isFetching ? (
                    <Spin indicator={<LoadingOutlined spin />} size="small" />
                  ) : vault?.statusCount?.data?.some((i: any) =>
                    i.hasOwnProperty("Ready For Use")
                  ) ? (
                    vault?.statusCount?.data?.find((i: any) =>
                      i.hasOwnProperty("Ready For Use")
                    )["Ready For Use"]
                  ) : (
                    0
                  )
                }
                customeIcon={<ReacdyForUseDevice />}
                border={"[#2943d622]"}
              />
              <DevicesSummary
                status={"Active"}
                value={
                  vault?.isFetching ? (
                    <Spin indicator={<LoadingOutlined spin />} size="small" />
                  ) : vault?.statusCount?.data?.some((i: any) =>
                    i.hasOwnProperty("Active")
                  ) ? (
                    vault?.statusCount?.data?.find((i: any) =>
                      i.hasOwnProperty("Active")
                    )["Active"]
                  ) : (
                    0
                  )
                }
                customeIcon={<Active />}
                border={"[#0FA91E]"}
              />
              <DevicesSummary
                status={"Locked"}
                value={
                  vault?.isFetching ? (
                    <Spin indicator={<LoadingOutlined spin />} size="small" />
                  ) : vault?.statusCount?.data?.some((i: any) =>
                    i.hasOwnProperty("Locked")
                  ) ? (
                    vault?.statusCount?.data?.find((i: any) =>
                      i.hasOwnProperty("Locked")
                    )["Locked"]
                  ) : (
                    0
                  )
                }
                customeIcon={<Locked />}
                border={"[#FA3659]"}
              />
              <DevicesSummary
                status={"Released"}
                value={
                  vault?.isFetching ? (
                    <Spin indicator={<LoadingOutlined spin />} size="small" />
                  ) : vault?.statusCount?.data?.some((i: any) =>
                    i.hasOwnProperty("Released")
                  ) ? (
                    vault?.statusCount?.data?.find((i: any) =>
                      i.hasOwnProperty("Released")
                    )["Released"]
                  ) : (
                    0
                  )
                }
                customeIcon={<Released />}
                border={"[#2943D6]"}
              />
            </div>
            <div className="flex justify-between my-8">
              <Select
                defaultValue="Filter By All"
                style={{ width: 300 }}
                onChange={handleChange}
                options={[
                  { value: " ", label: "Filter By All" },
                  { value: "Ready For Use", label: "Ready for use" },
                  { value: "Active", label: "Active" },
                  { value: "Locked", label: "Locked" },
                  { value: "Released", label: "Released" },
                ]}
              />
              <SearchInput
                onSearch={handleSearch}
                onChange={(e: any) => {
                  e.preventDefault();
                  setSearchKey(e.target.value);
                }}
              />
            </div>
            <div>
              <ScrollableFrame
                setPage={setPage}
                setLimit={setLimit}
                limit={limit}
                total={vault?.allDevice?.result}
                count={Math.ceil(vault?.allDevice?.result / limit)}
              >
                <Table
                  columns={columns}
                  loading={vault?.isFetching}
                  dataSource={dataPostPaid}
                  pagination={false}
                />
              </ScrollableFrame>
            </div>
          </div>
        </div>
      ) : (
        <DeviceDetails
          viewDetail={viewDetail}
          setViewDetails={setViewDetails}
          deviceData={data}
        />
      )}
    </>
  );
};
export default PostPaidDevices;
