import { DatePicker } from 'antd'
import dayjs from 'dayjs';
import React from 'react'
interface dateProps{
    onChangeDate:any,
    disabledDate?:any,
    enteredDate:any
}
const DatePickerCustom = ({onChangeDate,disabledDate,enteredDate}:dateProps) => {
    const dateFormat = "YYYY/MM/DD";
  return (
    <div>
          <DatePicker
                onChange={onChangeDate}
                defaultValue={dayjs(enteredDate, dateFormat)}
                className={`w-[140px]}`}
                // style={{ width: 120 }}
                disabledDate={disabledDate}
              />
    </div>
  )
}

export default DatePickerCustom