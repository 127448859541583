import React from "react";
import { Card, Skeleton, Steps } from "antd";
import { TbPointFilled } from "react-icons/tb";
import { FaLocationDot } from "react-icons/fa6";
import { useSelector } from "react-redux";

const { Step } = Steps;

interface ReviewSummaryProps {
  trackingActivityForOneistory: Array<any>;
}

const ReviewSummary: React.FC<ReviewSummaryProps> = ({
  trackingActivityForOneistory,
}) => {
  const { tracking } = useSelector((state: any) => state);


  return (
    <Card className="p-6 rounded-lg shadow-sm w-full  h-full">
      {tracking.isFetching ? (
        <div className="w-[100px]">
          <Skeleton active paragraph={{ rows: 4 }} />
        </div>
      ) : (
        <>
          <h3 className="text-[#030229] text-lg pb-4">Review Summary</h3>
          <div className="pb-4">
            <Steps current={1} direction="horizontal">
              <Step
                icon={
                  <div className="flex items-center justify-center w-6 h-6 rounded-full border border-gray-200">
                    <TbPointFilled className="text-green-500" />
                  </div>
                }
              />
              <Step
                icon={
                  <div className="flex items-center justify-center w-6 h-6 rounded-full border border-gray-300">
                    <FaLocationDot className="h-4 w-3" />
                  </div>
                }
              />
            </Steps>
          </div>
          <div className="flex justify-between items-center overflow-y-auto">
            <div>
              <div className="font-poppins text-sm text-[#9A9A9A] pb-2">From:</div>
              <p className="pb-2 text-[#030229] text-base">
                {(tracking?.selected?.data &&
                  tracking?.selected?.data.locationFrom?.name) ||
                  "N/A"}
              </p>
              <div className="font-poppins text-sm text-[#9A9A9A] pb-2">
                Deliverer:
              </div>
              <p className="pb-2 text-[#030229] text-base">
                {(tracking?.selected?.data &&
                  tracking?.selected?.data?.deliverer?.name) ||
                  "N/A"}
              </p>
              <div className="font-poppins text-sm text-[#9A9A9A] pb-2">
                Phone Number:{" "}
              </div>
              <p className="pb-2 text-[#030229] text-base">
                {(tracking?.selected?.data &&
                  tracking?.selected?.data?.deliverer?.phone) ||
                  "N/A"}
              </p>
              <div className="font-poppins text-sm text-[#9A9A9A]">Email: </div>
              <p className="pb-2 text-[#030229] text-base">
                {(tracking?.selected?.data &&
                  tracking?.selected?.data.deliverer?.email) ||
                  "N/A"}
              </p>
            </div>

            <div>
              <h2 className="font-poppins text-sm text-[#9A9A9A] pb-2">To:</h2>
              <p className="pb-2 text-[#030229] text-base">
                {(tracking?.selected?.data &&
                  tracking?.selected?.data.locationTo?.name) ||
                  "N/A"}
              </p>
              <div className="font-poppins text-sm text-[#9A9A9A]">Receiver: </div>
              <p className="pb-2 text-[#030229] text-base">
                {(tracking?.selected?.data &&
                  tracking?.selected?.data?.receiver?.name) ||
                  "N/A"}
              </p>
              <div className="font-poppins text-sm text-[#9A9A9A] ">
                Phone Number:{" "}
              </div>
              <p className="pb-2 text-[#030229] text-base">
                {" "}
                {(tracking?.selected?.data &&
                  tracking?.selected?.data?.receiver?.phone) ||
                  "N/A"}
              </p>
              <div className="font-poppins text-sm text-[#9A9A9A]">Email: </div>
              <p className="text-[#030229] text-base">
                {(tracking?.selected?.data &&
                  tracking?.selected?.data?.receiver?.email) ||
                  "N/A"}
              </p>
            </div>
          </div>
        </>
      )}
    </Card>
  );
};

export default ReviewSummary;
