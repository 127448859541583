import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getAllServiceTransaction = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/cartItem${query}`, token);
};

export const getAllTransactionInRangeService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(
    `${SERVER_URL}/cartItem/transactionRange${query}`,
    token
  );
};

export const getAllServiceStock = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/shopstockitem/stock${query}`, token);
};

export const getAllServiceStockByStatus = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/shopstockitem${query}`, token);
};

export const getAllServiceByTransactionId = async (
  itemId: any,
  token: string
) => {
  return HttpRequest.get(`${SERVER_URL}/cart/one/${itemId}`, token);
};
export const getAllCarts = async (token: any, query: any) => {
  return HttpRequest.get(`${SERVER_URL}/cart${query}`, token);
};

export const checkoutCart = async (
  token: string,
  cartId: string,
  data: any
) => {
  return HttpRequest.update(
    `${SERVER_URL}/cart/checkout/${cartId}`,
    data,
    token
  );
};

export const deleteCart = async (token: any, cartId: string) => {
  return HttpRequest.delete(`${SERVER_URL}/cart/one/${cartId}`, token);
};

export const getPriceLists = async (token: string, query: any) => {
  return HttpRequest.get(`${SERVER_URL}/pricelistitem${query}`, token);
};

export const getRecentPosService = async (token: string, query: any) => {
  return HttpRequest.get(`${SERVER_URL}/shopstock${query || ""}`, token);
};

export const getRecentPosServicea = async (token: string) => {
  return HttpRequest.get(`${SERVER_URL}/purchase/ready/po`, token);
};

export const createNosaleService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/noSale`, data, token);
};

export const getAllNosaleService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/noSale${query}`, token);
};

export const deleteNosales = async (
  token: string,
  itemId: string,
  data: any
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/noSale/one/${itemId}`,
    data,
    token
  );
};

export const updateReceivedProductStatus = async (
  token: string,
  itemId: string,
  data: any
) => {
  return HttpRequest.update(
    `${SERVER_URL}/shopstock/one/${itemId}`,
    data,
    token
  );
};



export const getOneShopStockService = async (
  token: string,
  itemId: string,
) => {
  return HttpRequest.get(
    `${SERVER_URL}/shopstock/one/${itemId}`,
    token
  );
};





export const getshopstockitemService = async (
  token: string,
  itemId: string,
) => {
  return HttpRequest.get(
    `${SERVER_URL}/shopstockitem/${itemId}`,
    token
  );
};

export const getOnePriceListItem = async (token: any, itemId: string) => {
  return HttpRequest.get(`${SERVER_URL}/pricelistitem/one/${itemId}`, token);
};

export const deletePendingTransactionService = async (
  token: any,
  itemId: string
) => {
  return HttpRequest.delete(`${SERVER_URL}/cartItem/one/${itemId}`, token);
};

export const getAllShopsAgentsService = async (itemId: any, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/assign/agentToshop/${itemId}`, token);
};

export const updatePaidOnService = async (
  token: string,
  cartId: string,
  data: any
) => {
  return HttpRequest.update(`${SERVER_URL}/cart/paidon/${cartId}`, data, token);
};

export const updatePaymentAndAmountService = async (
  token: string,
  cartId: string,
  data: any
) => {
  return HttpRequest.update(`${SERVER_URL}/cart/one/${cartId}`, data, token);
};

export const revertSalesService = async (
  token: string,
  query: any,
  data: any
) => {
  return HttpRequest.update(
    `${SERVER_URL}/cartItem/revert${query}`,
    data,
    token
  );
};
