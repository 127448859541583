import React, { useState } from "react";
import { useSelector } from "react-redux";
import SimpleCartItems from "../SimpleCartItems";
import { getTotalPaidAmount, getTotalPrice } from "../../../../utils/converter";
import { DatePicker, DatePickerProps, Table } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { ReactComponent as SamphoneLogo } from "../../../../assets/icons/logo.svg";
import CartItemsTable from "../../../../components/tables/pos/CartItemsTable";
import { getDefaultCurrencyCode } from "../../../../utils/helper";

const ConfirmCheckout = (props: any) => {
  const { wareHouse, shop, company } = useSelector((state: any) => state);
  const itemsList = wareHouse?.createdCart?.data?.list;
  const discount = wareHouse?.createdCart?.data?.discount;

  return (
    <div className="bg-white p-2 rounded-lg">
      <div className="grid grid-cols-2">
        <div className="space-y-5">
          <p>Transaction invoice</p>
          <div
            className="flex flex-col text-[#0F0F47;
]"
          >
            <span>{wareHouse?.createdCart?.data?.customer?.name}</span>
            <span>{wareHouse?.createdCart?.data?.customer?.phone}</span>
            <span>{wareHouse?.createdCart?.data?.customer?.email}</span>
          </div>
          <div>
            <div className="grid grid-cols-2 w-fit text-black">
              <p className="font-semibold text-gray-400">Invoice</p>
              <span>{wareHouse?.createdCart?.data?.cartId}</span>
              <p className="font-semibold text-gray-400 ">Date</p>
              <span>{wareHouse?.createdCart?.data?.paidOn?.slice(0, 10)}</span>
              <p className="font-semibold text-gray-400 ">Paid By</p>
              <span>{wareHouse?.createdCart?.data?.customer?.name}</span>
              <p className="font-semibold text-gray-400 ">Shop</p>
              <span>{shop?.myShop?.name}</span>
            </div>
          </div>
        </div>
        <div className="text-slate-800">
          <SamphoneLogo />
          <div className="py-2 pl-6">
            <p className="font-semibold capitalize">Samphone rwanda ltd</p>
            <div>TCB BUILDING. 3rd FLOOR . NYARUGENGE DISTRICT</div>
            <div className=" text-slate-600">
              <span className="font-semibold text-slate-800">TEL: </span>
              0792573848
            </div>
            <div className=" text-slate-600">
              <span className="font-semibold">Email:</span> invoice@samphone.co
            </div>
            <div className=" text-slate-600">
              <span className="font-semibold">TIN:</span> 112620773
            </div>
            <div className=" text-slate-600">
              <span className="font-semibold">CASHIER:</span> SAMPHONE RWANDA
              Ltd(112620773)
            </div>
          </div>

          {/* <SimpleCartItems
            itemsList={wareHouse?.createdCart?.data?.list}
            discount={wareHouse?.createdCart?.data?.discount}
          /> */}
        </div>
      </div>
      <div className="space-y-2 mt-10">
        <CartItemsTable />
        <div className="flex justify-between space-x-2">
          <div>
            <DatePicker
              defaultValue={dayjs(
                props?.confirmationDate.toString(),
                "DD/MM/YYYY"
              )}
              format="DD/MM/YYYY"
              onChange={props?.onChangeDate}
              disabled={
                wareHouse?.createdCart?.data?.status?.toLowerCase() === "paid"
              }
            />
          </div>
          <div className="grid grid-cols-2 gap-x-2 text-right text-black font-semibold">
            <p>Untaxed Amount:</p>
            <p>{`${getTotalPrice(
              itemsList
            )?.toLocaleString()} ${getDefaultCurrencyCode(company)}`}</p>
            <p>Discount {discount?.discountPerc}%:</p>
            <p>{`${(
              getTotalPrice(itemsList) *
              (discount?.discountPerc / 100)
            )?.toLocaleString()} ${getDefaultCurrencyCode(company)}`}</p>
            <p>VAT 0%:</p>
            <p>{`0.00 ${getDefaultCurrencyCode(company)}`}</p>
            <p>Total:</p>
            <p>{`${(
              getTotalPrice(itemsList) -
              getTotalPrice(itemsList) * (discount?.discountPerc / 100)
            )?.toLocaleString()} ${getDefaultCurrencyCode(company)}`}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmCheckout;
