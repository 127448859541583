import React from "react";
import { Button, Table, Tag, Tooltip } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getActivePrice } from "../../../utils/converter";
import { getShopStockCartAction } from "../../../store/wareHouse/actions";
import { getDefaultCurrencyCode } from "../../../utils/helper";

interface DataType {
  [x: string]: any;
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const onChange: TableProps<DataType>["onChange"] = (
  pagination,
  filters,
  sorter,
  extra
) => {
  console.log("params", pagination, filters, sorter, extra);
};

const CartItemsTable = (props: any) => {
  const { auth, wareHouse, company } = useSelector((state: any) => state);
  const dataMapper: any[] = [];
  const [formData, setformData] = React.useState(dataMapper);
  console.log("formData", formData);
  const { cartId } = useParams();
  const dispatch = useDispatch();

  React.useEffect(() => {
    auth?.token && getShopStockCartAction(auth?.token, cartId)(dispatch);
  }, [auth?.token, cartId, dispatch]);

  const columns: ColumnsType<DataType> = [
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantitiy",
    },
    {
      title: "Extended Warranty",
      dataIndex: "extendedWarranty",
      key: "extendedWarranty",
      render: (text: any) => {
        return <p>{text + " %"}</p>;
      },
    },
    {
      title: "Price/Unit",
      dataIndex: "unitPrice",
      key: "unitPrice",
      render: (text, record) => (
        <b>
          {getActivePrice(
            record?.data?.shopStockItem?.requestedItem?.product?.prices
          )?.toLocaleString() + getDefaultCurrencyCode(company)}
        </b>
      ),
    },
    {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
      render: (text: any) => {
        return <p>{text + " %"}</p>;
      },
    },
  ];

  const value = wareHouse?.createdCart?.data?.list;

  React.useEffect(() => {
    value &&
      value?.forEach((el: any) => {
        dataMapper.push({
          id: el?._id,
          product: el?.shopStockItem?.requestedItem?.product?.product?.model,
          description: `${el?.shopStockItem?.requestedItem?.product?.specification
            ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
            ?.join(", ")}`,
          quantity: "1",
          extendedWarranty:
            el?.shopStockItem?.requestedItem?.product?.extendedWarranty,
          unitPrice: el?.startDate,
          tax: "0",
          data: el,
        });
      });

    setformData(dataMapper);
  }, [wareHouse.createdCart]);

  return (
    <Table
      columns={columns}
      dataSource={formData}
      onChange={onChange}
      loading={wareHouse?.isFetching}
    />
  );
};

export default CartItemsTable;
