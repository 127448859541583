import * as React from "react";
import { Radio } from "antd";

export default function DisabledTabs(props: any) {
  return (
    <Radio.Group
      defaultValue=""
      buttonStyle="solid"
      onChange={(e: any) => props?.setSelectedType(e?.target?.value)}
    >
      <Radio.Button value={""}>All Accounts</Radio.Button>
      <Radio.Button value={"b2b"}>B2B Accounts</Radio.Button>
      <Radio.Button value={"b2c"}>B2C Accounts</Radio.Button>
    </Radio.Group>
  );
}
