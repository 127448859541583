import React, { useState } from "react";
import { Link } from "react-router-dom";
import RemoveRedEye from "@mui/icons-material/RemoveRedEye";
import { Carousel, Space } from "antd";
import { getColorByValue, searchValue } from "../../utils/setColor";
import ProductDetailsModal from "../Modals/ProductDetailsModal";
import ProductDetailsCard from "../cards/ProductDetailsCard";
import UpdateProduct from "../../components/forms/NewProductForm";
import DetailsModel from "../Modals/DetailsModel";
import defaultPic from "../../assets/nophoto.png";

const Component = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const handleOnClickViewIcon = (e: any) => {
    e.preventDefault();
    setisEdit(false);
    setIsModalOpen(true);
  };
  const handleOnCancel = () => {
    setIsModalOpen(false);
  };

  const component = isEdit ? (
    <UpdateProduct
      dataToUpdate={props?.data}
      action={"update"}
      onCancel={handleOnCancel}
    />
  ) : (
    <ProductDetailsCard data={props?.data} />
  );
  return (
    <>
      <div className="relative w-[335px] h-[172px] p-4 bg-white text-[#030229] rounded-[10px] border-[.1px] border-[#D9DBE9] border-opacity-50 hover:border-gray-300 duration-300">
        <Link to={`/inventory/pr/${props?.data?._id}`}>
          <div className="flex gap-4">
            <div className="w-[94px] h-[100px] bg-[#F4F6FA] rounded-[10px] p-3 border-[.1px] border-[#D9DBE9] border-opacity-20">
              {props?.data?.images?.length === 0 && (
                <img src={defaultPic} width={"100%"} alt="" />
              )}
              {props?.data?.images?.length > 0 && (
                <Carousel autoplay>
                  {props?.data?.images?.map((im: any) => (
                    <div className="block w-[59px] h-[87px]">
                      <img src={im} alt="" className="w-full h-full object-cover" />
                    </div>
                  ))}
                </Carousel>
              )}
            </div>
            <div>
              <div className="flex flex-col">
                <h1 className="text-sm font-medium mb-3">{props?.data.model?.toUpperCase()}</h1>
                {props?.data &&
                  props?.data.specs
                    ?.slice(0, 2)
                    ?.map((data: any, _index: number) => (
                      <>
                        <Space>
                          <p className="text-[.7rem]">
                            {data?.label?.toUpperCase()} :
                          </p>
                          <p className="text-[.7rem] opacity-70">
                            {data?.value?.join(", ")}
                          </p>
                        </Space>
                      </>
                    ))}

                <div className="flex gap-4 absolute bottom-2 right-5 w-[73%]">
                  <div className="w-fit h-fit rounded-[5px] border-[.1px] px-3 font-medium">
                    <Space>
                      <div
                        className="w-[10px] h-[10px] rounded-sm capitalize bg-[#605BFF]"
                        style={{ background: searchValue(props?.data.type) }}
                      ></div>
                      <p className="w-[71px] text-[.7rem] capitalize truncate">
                        {props?.data.type}
                      </p>
                    </Space>
                  </div>
                  <div className="w-fit h-fit rounded-[5px] border-[.1px] px-3 font-medium">
                    <Space>
                      <div
                        className="w-[10px] h-[10px] rounded-sm capitalize "
                        style={{
                          background: getColorByValue(
                            props?.brands,
                            props?.data?.brand
                          ),
                        }}
                      ></div>
                      <p className=" w-[50px] text-[.7rem] capitalize truncate ">
                        {props?.data?.brand}
                      </p>
                    </Space>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
        </Link>
        <div className="absolute bottom-1 right-2 flex justify-end items-center w-full py-2 space-x-2 ">
          <button
            aria-label="delete"
            className="text-gray-400"
            onClick={handleOnClickViewIcon}
          >
            <RemoveRedEye fontSize="small" className="h-2" />
          </button>
        </div>
      </div>
      {/* product modal here */}
      {isEdit && (
        <ProductDetailsModal
          component={component}
          isModalOpen={isModalOpen}
          handleOnClickViewIcon={handleOnClickViewIcon}
          handleOnCancel={handleOnCancel}
        />
      )}
      {!isEdit && (
        <DetailsModel
          component={component}
          isModalOpen={isModalOpen}
          handleOnCancel={handleOnCancel}
          handleOnClickViewIcon={handleOnClickViewIcon}
        />
      )}
    </>
  );
};
export default Component;
