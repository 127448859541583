const ProductDetailsCard = (props: any) => {
  return (
    <div className="py-10">
      <figure className="flex justify-center h-fit">
        <img
          className="h-60 rounded-lg"
          src={props?.data?.images[0]}
          alt={props?.data?.model}
        />
      </figure>
      <div className="grid grid-cols-2 gap-y-2 mt-3">
        <div className="font-semibold">Type</div>
        <div>{props?.data.type}</div>
        <div className="font-semibold">Brand</div>
        <div>{props?.data.brand}</div>
        <div className="font-semibold">Model</div>
        <div>{props?.data.model}</div>
        {props?.data?.specs?.map((item: any, _index: number) => (
          <>
            <div className="font-semibold">{item?.label}</div>
            <div>{item?.value?.join(", ")}</div>
          </>
        ))}
      </div>
    </div>
  );
};
export default ProductDetailsCard;
