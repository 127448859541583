import React, { useEffect, useRef, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Divider, Form, Input, InputRef, Select, Space } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  createShopAction,
  createShopTypeAction,
  getAllRegionAction,
  getAllShopAction,
  getOneRegionAction,
  getShopTypeAction,
  updateShopAction,
} from "../../store/channel/actions";
import AddressDetailsForm from "./AddressDetailsForm";
import { PlusOutlined } from "@ant-design/icons";
import { getOneCompanyAction } from "../../store/setting/company/actions";
import { COMPANY_PROFILE_ID } from "../../utils/constants";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const App = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { channel, auth, layout, company } = useSelector((state: any) => state);
  const { Option } = Select;
  const channelId = layout?.channelInfo?._id ?? props?.id;
  const inputRef = useRef<InputRef>(null);
  const [temporaryShopTypes, setTemporaryShopTypes] = useState<any[]>([]);

  useEffect(() => {
    auth.token &&
      getShopTypeAction(
        auth.token,
        `?channel=${channel?.selected?._id}`
      )(dispatch);
  }, [dispatch, auth?.token, channel?.selected?._id]);

  useEffect(() => {
    auth?.token &&
      channelId &&
      getAllRegionAction(auth?.token, `channel=${channelId}`)(dispatch);
  }, [dispatch, auth?.token, channelId]);

  const companyId = COMPANY_PROFILE_ID;

  useEffect(() => {
    auth?.token &&
      getOneCompanyAction(companyId as string, auth.token)(dispatch);
  }, [auth.token, dispatch, companyId]);

  const isUpdate = !!props?.data?._id;

  const onChange = async (value: any) => {
    await getOneRegionAction(value as string, auth?.token)(dispatch);
  };

  const onFinish = async (values: any) => {
    if (auth?.token) {
      const shopData = {
        ...values,
        region: channel?.selectedRegion?.name,
        regionId: values.region,
        channel: layout?.channelInfo?._id ?? props?.id,
        shopLocation: {
          province: values?.province,
          district: values?.district,
          sector: values?.sector,
          cell: values?.cell,
          village: values?.village,
          coordinate: { lat: values?.lat, lng: values?.lng },
        },
      };

      if (isUpdate) {
        await updateShopAction(
          props?.data?._id,
          shopData,
          auth?.token
        )(dispatch);
        await getAllShopAction(auth?.token, `channel=${channelId}`)(dispatch);
      } else {
        await createShopAction(auth?.token, shopData)(dispatch);
      }

      form.resetFields();
      props?.onCancel();
    }
  };

  useEffect(() => {
    form.setFieldsValue(props?.data);
  }, [props?.data]);

  const options = [
    ...channel?.allRegion?.data?.map((d: any) => ({
      value: d?._id,
      label: d?.name,
    })),
  ];

  const handleAddShopType = async () => {
    const name = inputRef.current?.input?.value?.trim();
    if (name && auth.token) {
      const tempId = `temp_${Date.now()}`; // Temporary ID to identify the newly added item
      const newShopType = { _id: tempId, name };

      setTemporaryShopTypes([...temporaryShopTypes, newShopType]);

      try {
        const res: any = await createShopTypeAction(auth.token, {
          name,
          channel: channel?.selected?._id,
        })(dispatch);

        if (res?.status === 201) {
          const createdShopType = res.data;

          // Update form field value with the new shop type
          form.setFieldsValue({ type: createdShopType._id });

          // Fetch the shop types again from the backend to get the new list
          await getShopTypeAction(auth.token)(dispatch);

          // Remove the temporary shop type
          setTemporaryShopTypes((prev) =>
            prev.filter((type) => type._id !== tempId)
          );
        }
      } catch (error) {
        console.error("Error creating shop type:", error);

        // Remove the temporary shop type if backend fails
        setTemporaryShopTypes((prev) =>
          prev.filter((type) => type._id !== tempId)
        );
      }

      // Clear the input field
      if (inputRef.current?.input) {
        inputRef.current.input.value = ""; // <-- This line clears the input field
      }
    }
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={props.data || {}}
      style={{ minWidth: 600 }}
      scrollToFirstError
      layout="vertical"
    >
      <h1 className="font-semibold text-sm mb-8 text-center ">{`${
        isUpdate ? "Update Shop Information" : "Add New Shop"
      }`}</h1>
      <div className="grid grid-cols-2 space-x-2 lg:max-2xl:gap-2">
        <Form.Item
          name="name"
          label="Name"
          tooltip="Please enter name of the Shop?"
          rules={[
            { required: true, message: "Shop is required!", whitespace: true },
          ]}
        >
          <Input style={{ width: 250 }} />
        </Form.Item>

        <Form.Item
          name="type"
          label="Type"
          tooltip="Please select type of the Shop"
          rules={[
            {
              required: true,
              message: "Shop type is required!",
              whitespace: true,
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: 250 }}
            placeholder="Select shop Type"
            className="capitalize"
            optionFilterProp="children"
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ padding: "0 8px 4px" }}>
                  <Input
                    placeholder="Add Type"
                    ref={inputRef}
                    onPressEnter={handleAddShopType}
                  />
                  <Button onClick={handleAddShopType}>
                    <PlusOutlined />
                  </Button>
                </Space>
              </>
            )}
          >
            {channel?.shopType?.data
              ?.concat(temporaryShopTypes)
              ?.map((type: any) => (
                <Option
                  key={type?._id}
                  value={type?.name}
                  className="capitalize"
                >
                  {type?.name}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item name="msisdn" label="MSISDN" tooltip="">
          <Input style={{ width: 250 }} />
        </Form.Item>

        <Form.Item name="momocode" label="MoMo Code" tooltip="">
          <Input style={{ width: 250 }} />
        </Form.Item>
        <Form.Item
          name="region"
          label="Region"
          tooltip="Please select region of the Shop?"
          rules={[
            {
              required: true,
              message: "Shop region is required!",
              whitespace: true,
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: 250 }}
            placeholder="Select region"
            className="capitalize"
            onChange={onChange}
            options={options}
          />
        </Form.Item>
        <Form.Item
          name="address"
          label="Address"
          tooltip="Where our Shop is located"
        >
          <Input style={{ width: 250 }} />
        </Form.Item>
      </div>
      <Divider />

      <h2 className="font-semibold text-sm">Location</h2>
      <AddressDetailsForm dataToUpdate={props?.data} />

      <div className="flex w-[100%] mx-3">
        <Form.Item
          label="Latitude"
          name="lat"
          rules={[{ required: true, message: "Please input latitude!" }]}
          style={{ width: "100%" }}
        >
          <Input className="w-[78%]" />
        </Form.Item>
        <Form.Item
          label="Longitude"
          name="lng"
          rules={[{ required: true, message: "Please input longitude!" }]}
          style={{ width: "100%" }}
        >
          <Input className="w-[78%]" />
        </Form.Item>
      </div>
      <Divider />
      <Form.Item {...tailFormItemLayout}>
        <LoadingButton type="submit" className="bg-primary" variant="contained">
          {isUpdate ? "Update" : "Add"}
        </LoadingButton>
      </Form.Item>
    </Form>
  );
};

export default App;
