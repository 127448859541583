import React from "react";
const CustomerDetails = (props: any) => {
  return (
    <div className="text-black px-4">
      <div className="border border-[#03022940] rounded-lg p-3 pl-5 mt-2 ">
        <div>
          <p className="text-[#030229B2] text-sm mt-4">Customer ID:</p>
          <p className="text-[#030229] text-sm pt-1">
            {props?.data?.customer?.nid}
          </p>
          <p className="text-[#030229B2] text-sm mt-4">Type:</p>
          <p className="text-[#030229] text-sm pt-1">
            {props?.data?.customer?.subscriptionInfo?.customerType || "N/A"}
          </p>
          <p className="text-[#030229B2] text-sm mt-4">Name:</p>
          <p className="text-[#030229] text-sm pt-1">
            {props?.data?.customer?.name}
          </p>
          <p className="text-[#030229B2] text-sm mt-4">Email:</p>
          <p className="text-[#030229] text-sm pt-1">
            {props?.data?.customer?.email}
          </p>
          <p className="text-[#030229B2] text-sm mt-4">Phone 1:</p>
          <p className="text-[#030229] text-sm pt-1">
            {props?.data?.customer?.phone}
          </p>
          <p className="text-[#030229B2] text-sm mt-4">Phone 2:</p>
          <p className="text-[#030229] text-sm pt-1">N/A</p>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;
