import React, { useEffect } from "react";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { useDispatch, useSelector } from "react-redux";
import { getOneconditionAction } from "../../../../store/terms_conditons/actions";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";

const ViewTermsConditions = () => {
  const { auth, termsandConditions } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { termsId } = useParams();

  useEffect(() => {
    if (auth?.token) {
      getOneconditionAction(auth?.token, termsId as string)(dispatch);
    }
  }, [auth.token, termsId, dispatch]);

  return (
    <>
      <div className="my-3 text-black">
        <DetailsHeaderActionBar
          pageName="Settings"
          title="Terms & Conditions"
        />
      </div>
      <div className="w-[100%] h-screen bg-white overflow-y-auto">
        <div className="text-[12px] sm:text-[1.6rem] text-black text-center font-poppins font-normal sm:font-medium mt-10">
          {termsandConditions?.selected?.title}
        </div>
        <div className="text-[1.2rem] text-black p-6 text-center font-poppins mt-2">
          {parse(termsandConditions?.selected?.descriptions.toString() ?? "")}
        </div>
      </div>
    </>
  );
};
export default ViewTermsConditions;
