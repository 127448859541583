import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { Skeleton } from "antd";
import "./vault.css"
interface DataPoint {
  values: number;
  label: string;
}
interface PieChartWithCenterLabelProps {
  data?: Record<string, number | Record<string, number>>;
  isLoading: boolean;
}

const PieChartWithCenterLabel: React.FC<PieChartWithCenterLabelProps> = (
  props
) => {
  const { data, isLoading } = props;

  if (isLoading) {
    return (
      <Skeleton
        style={{
          padding: "40px",
          background: "#fff",
          marginTop: "12px",
          paddingTop: "6rem",
          height: "15.4rem",
          width: "37rem",
        }}
        active
        paragraph={{ rows: 2 }}
      />
    );
  }

  if (!data) {
    return <div>No data available to display</div>;
  }

  const chartData: DataPoint[] = Object.entries(data).map(([key, value]) => {
    let numericalValue = 0;
    let label = "";

    if (typeof value === "object") {
      const labels = Object.keys(value);
      const values = Object.values(value);

      if (labels.length > 0 && values.length > 0) {
        label = labels[0];
        numericalValue = values[0];
      }
    } else {
      label = key;
      numericalValue = value;
    }
    return {
      values: numericalValue,
      label,
    };
  });

  const colors = ["#3930A8", "#3762D0", "#78CFFF", "#BBD9FC", "#030229"];
  return (
    <div>
      <PieChart
        series={[
          {
            data: chartData?.map(({ values, label }) => ({
              value: values,
              label: label,
            })),
            innerRadius: 60,
            arcLabelMinAngle: 65,
            arcLabelRadius: "60%",
            cx: 110,
            cy: 110,
          },
        ]}
        colors={colors}
        width={600}
        height={220}
      />
    </div>
  );
};
export default PieChartWithCenterLabel;

