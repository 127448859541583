import { Button, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { releaseDeviceAction } from "../../../../store/vault/actions";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const ReleaseDevices = (props: any) => {
  const { auth, vault } = useSelector((state: any) => state);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onReleaseDevice = async (values?: any) => {
    auth?.token &&
      (await releaseDeviceAction(auth?.token, {
        ...values,
        deviceUid: [props?.deviceData],
      })(dispatch));
    props?.onClose();
    form.resetFields();
  };
  return (
    <>
      <div>
        <Form
          name="basic"
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onReleaseDevice}
          autoComplete="off"
        >
          <div className="text-[#030229] pl-1 text-sm font-medium pt-4 mb-2">
            Reason
          </div>
          <Form.Item
            name="reason"
            className="w-2/3- "
            rules={[{ required: true, message: "Please enter days" }]}
          >
            <Input className="rounded-md h-[52px] w-[100%]" />
          </Form.Item>
          <Form.Item className="w-full">
            <div className="flex gap-3 ml-[11rem] mt-10">
              <Button
                className="w-32 border border-red-400"
                onClick={props?.onClose}
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                className="w-32  border border-[#605BFF]"
              >
                {vault?.isFetching ? (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 24 }} spin />
                    }
                  />
                ) : (
                  "Send"
                )}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
export default ReleaseDevices;
