import React, { useEffect, useState } from "react";
import SettingButton from "../../../pages/dashboard/setting/SettingButton";
import { BiPlus } from "react-icons/bi";
import { ReactComponent as ImportIcons } from "../assets/images/icons/vscode-icons_file-type-excel2.svg";
import EbmButton from "../components/EbmButton";
import EbmCard from "../components/EbmCard";
import { Input } from "antd";
import type { SearchProps } from "antd/es/input/Search";
import ManageItemTable from "../components/tables/ManageItemTable";
import GenearalModal from "../components/modals/GeneralModal";
import BargeCard from "../components/cards/BargeCard";
import AddItemForm from "../components/forms/AddItemForm";
import GeneralDrawer from "../components/GeneralDrawer";
import ItemDetails from "../components/ItemDetails";
import { useDispatch, useSelector } from "react-redux";
import { getAllItemsAction, getEbmSelectOptionAction } from "../store/action";
import FilterPriceList from "../components/FilterPriceList";
import {
  getPricelistAction,
  getPricelistItemAction,
} from "../store/ItemManagement/actions";
import LoadingCard from "../components/LoadingCard";
import { getAllEbmItem_CSVAction } from "../../../store/csvDownload/actions";
import { EbmItemHeaders } from "../../../components/csvHeaders/Headers";
import EbmExportButton from "../components/EbmExportButton";
import { getNameByCode } from "../utils/ConvertCodeToName";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
const ItemManagement = () => {
  const { auth, channel, ebmItem, ebmGeneral, layout, CSV } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [brandData, setBrandData] = useState<any>(null);
  const [itemForm, setItemForm] = useState(false);
  const [limit, setLimit] = useState<any>(15);
  const [page, setPage] = useState<any>(1);
  const [searchKey, setSearchKey] = useState<string>("");

  const handleShoFilter = () => {
    setShowFilter(true);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showItemForm = () => {
    setItemForm(true);
  };

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };
  const { Search } = Input;
  useEffect(() => {
    auth?.token && getEbmSelectOptionAction(auth?.token, "?")(dispatch);
  }, [dispatch, auth]);

  useEffect(() => {
    auth?.token && getEbmSelectOptionAction(auth?.token, "?")(dispatch);
    getAllItemsAction(
      auth?.token,
      `?limit=${limit}&page=${page - 1}`
    )(dispatch);
  }, [dispatch, auth, limit, page]);

  useEffect(() => {
    auth?.token &&
      getPricelistAction(
        auth?.token,
        `?${channel?.selected?._id ? `channel=${channel?.selected?._id}` : ""}`
      )(dispatch);
  }, [auth?.token, channel?.selected, dispatch]);
  useEffect(() => {
    if (auth?.token) {
      getPricelistItemAction(auth?.token, ``)(dispatch);
      getEbmSelectOptionAction(auth?.token, "?")(dispatch);
    }
  }, [auth?.token, dispatch]);

  useEffect(() => {
    if (layout?.openCSVButton)
      auth?.token &&
        getAllEbmItem_CSVAction(
          auth?.token,
          `?limit=${ebmGeneral?.allItems?.length}`
        )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);
  const handleSearch = () => {
    if (searchKey && auth?.token) {
      getEbmSelectOptionAction(auth?.token, "?")(dispatch);
      getAllItemsAction(auth?.token, `?sq=${searchKey}`)(dispatch);
    }
  };

  const csvData = CSV?.csvDownloadedData?.data?.map((el: any) => {
    return {
      code: el?.itemCd,
      name: el?.itemNm,
      park:
        ebmGeneral?.selectOption["Packing Unit"] &&
        getNameByCode(ebmGeneral?.selectOption["Packing Unit"], el?.pkgUnitCd),
      country:
        ebmGeneral?.selectOption?.Cuntry &&
        getNameByCode(
          ebmGeneral?.selectOption?.Cuntry,
          el?.orgnNatCd
        )?.toUpperCase(),
      type:
        ebmGeneral?.selectOption["Item Type"] &&
        getNameByCode(ebmGeneral?.selectOption["Item Type"], el?.itemTyCd),
      unit:
        ebmGeneral?.selectOption["Quantity Unit"] &&
        getNameByCode(ebmGeneral?.selectOption["Quantity Unit"], el?.qtyUnitCd),
      purchase: "y",
      sale: el?.dftPrc,
      stock: el?.sftyQty,
    };
  });

  return (
    <div>
      <GeneralDrawer
        openDrawer={openDrawer}
        closeDrawer={onClose}
        width={550}
        title={
          <h1 className="text-base font-medium text-[#030229]">Item Details</h1>
        }
        component={
          <div>
            <ItemDetails />
          </div>
        }
      />
      <div className="flex justify-between">
        <h1 className="text-[#030229] text-lg">Items Management</h1>
        <div className="flex gap-5">
          <EbmButton
            btnName={"Import"}
            icon={<ImportIcons />}
            btnBgColor="white"
          />
          <EbmExportButton
            btnName={"Export"}
            icon={<ImportIcons />}
            btnBgColor="white"
            csvHeaders={EbmItemHeaders}
            csvData={csvData}
            filename={`Ebm Items.csv`}
          />
          <SettingButton
            btnName={"New"}
            icon={<BiPlus color="white" size={20} />}
            btnBgColor="[#605BFF]"
            textColor="white"
            onClick={showModal}
          />
        </div>
      </div>
      <div className="flex gap-5 mt-5 justify-between  items-end">
        {ebmGeneral?.isFetching ? (
          <LoadingCard />
        ) : (
          <EbmCard title={"Total Items"} total={ebmGeneral?.allItems?.total} />
        )}

        <div className="flex gap-5 justify-end">
          <Search
            onSearch={handleSearch}
            onChange={(e: any) => {
              e.preventDefault();
              setSearchKey(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="bg-white p-3 rounded-md mt-5">
        <div className="flex justify-between">
          <GenearalModal
            openModal={isModalOpen}
            closeModal={handleOk}
            title={
              <h1 className="text-[#030229] font-semibold text-lg text-center py-2">
                Add New Item
              </h1>
            }
            component={
              <div className="px-4 h-[78vh] w-full overflow-y-auto">
                <div className="flex gap-6 px-5">
                  <FilterPriceList
                    open={isModalOpen}
                    setBrandData={setBrandData}
                    setItemForm={setItemForm}
                  />
                </div>
                {ebmItem?.isFetching ? (
                  <LoadingCard />
                ) : (
                  <div className="mt-10 w-full">
                    {!itemForm && brandData && (
                      <div className="flex flex-wrap">
                        <BargeCard
                          data={ebmItem?.priceListItems?.data}
                          open={showItemForm}
                        />
                      </div>
                    )}

                    {itemForm && brandData && (
                      <AddItemForm onClose={handleOk} />
                    )}
                  </div>
                )}
              </div>
            }
            width={1400}
          />
        </div>
        <ScrollableFrame
          setLimit={setLimit}
          setPage={setPage}
          limit={limit}
          total={ebmGeneral?.allItems?.total}
          count={Math.ceil(ebmGeneral?.allItems?.total / limit)}
        >
          <ManageItemTable open={showDrawer} />
        </ScrollableFrame>
      </div>
    </div>
  );
};

export default ItemManagement;
