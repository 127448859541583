import React from "react";
import { Select, Table, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector } from "react-redux";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import CustomizedPieChart from "./CustomizedPieChart";

interface DataType {
    key: any;
    shop: any;
    soldyesterday: any;
    soldtoday: any;
    earningyesterday: any;
    earningtoday: any;
}

export const SoldDevices = () => {
    const columns: ColumnsType<DataType> = [
        {
            title: "Shops",
            dataIndex: "shop",
            key: "shop",
        },
        {
            title: "Quantity Sold (Yesterday)",
            dataIndex: "soldyesterday",
            key: "soldyesterday",
        },
        {
            title: "uantity Sold (Today)",
            dataIndex: "soldtoday",
            key: "soldtoday",
        },
        {
            title: "Earnings (Yesterday)",
            dataIndex: "earningyesterday",
            key: "earningyesterday",
        },
        {
            title: "Earnings (Today)",
            dataIndex: "earningtoday",
            key: "earningtoday",
        },
    ];
    const { Text } = Typography;
    const data: DataType[] = [
        {
            key: "1",
            shop: "Nyamirambo_CS",
            soldyesterday: "343",
            soldtoday: "56",
            earningyesterday: "$4000",
            earningtoday: "S6000",
        },
        {
            key: "2",
            shop: "Chick_CS",
            soldyesterday: "343",
            soldtoday: "56",
            earningyesterday: "$4000",
            earningtoday: "S6000",
        },
        {
            key: "3",
            shop: "Rubavu_SCS",
            soldyesterday: "343",
            soldtoday: "56",
            earningyesterday: "$4000",
            earningtoday: "S6000",
        },
    ];
    const handleChange = (value: string) => {
        console.log(`selected ${value}`);
    };
    return (
        <div className="w-full bg-[#fff] rounded-md h-[50vh] overflow-y-auto p-8">
            <div className="text-[#030229] text-lg flex justify-between">
                <div>
                    <p className="text-[#030229] text-lg mr-4">Devices Sold</p>
                </div>
                <Select
                    defaultValue="By Region"
                    style={{ width: 120 }}
                    onChange={handleChange}
                    options={[{ value: "shop", label: "Shop" }]}
                />
            </div>
            <div className="flex gap-10 my-3 text-sm">
                <p className="text-[#03022980] font-light text-base pt-3">
                    SC:Service Center
                </p>

                <p className="text-[#03022980] font-light text-base pt-3">
                    CS:Connect Shop
                </p>
            </div>
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                summary={(pageData) => {
                    let totalInShops = 0;
                    let totalInWarehouse = 0;

                    // pageData.forEach(({ revenue:any, quantity }) => {
                    //   totalInShops += revenue;
                    //   totalInWarehouse += quantity;
                    // });

                    return (
                        <>
                            <Table.Summary.Row className="!text-[#2E6C8E] bg-white sticky bottom-0">
                                <Table.Summary.Cell index={0} colSpan={2}>
                                    <Text style={{ fontWeight: "bolder" }}>TOTAL</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={0}>
                                    <Text style={{ fontWeight: "bolder" }}>
                                        {totalInShops?.toLocaleString()}
                                    </Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={0}>
                                    <Text style={{ fontWeight: "bolder" }}>
                                        {totalInWarehouse?.toLocaleString()}
                                    </Text>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );
                }}
            />
        </div>
    );
};

export const PlansOverview = () => {
    const { overView } = useSelector((state: any) => state);

    const datas = [
        { name: "12 Months Plan", value: (80) },
        { name: "6 Months Plan", value: 55 },
        { name: "3 Months Plan", value: 15 },
    ];

    const COLORS = [
        "#605BFF",
        "#BFBDFF", 
       ' #5F00FFB2'

    ];
    return (
        <div className="w-full bg-[#fff] h-[f28rem] overflowg-y-auto p-5 mt-5 rounded-md">
            <div className="text-[#030229] text-lg flex justify-between">
                <p>Plans Overview</p><FaArrowUpRightFromSquare color={'#03022980'} /></div>
            <div className="mb-4">
                <CustomizedPieChart
                    data={datas}
                    colors={COLORS}
                    totalLabel={<h1 className="text-[12px]">Total Plans</h1>}
                    innerRad={43}
                    height={'180px'}
                    contentWidth={"w-full"}
                    top={"50%"}
                    left={"37%"}
                    outerRad={60}
                    totalValue={5}
                    pieHeight={200}
                    pieWidth={300}
                />
            </div>
        </div>
    );
};
