import { Stack } from "@mui/material";
import { Tag, notification, Spin } from "antd";
import React from "react";
import { searchValue } from "../../utils/setColor";
import { useDispatch, useSelector } from "react-redux";
import { revertStockAction } from "../../store/shop/actions";
import { useNavigate } from "react-router-dom";
import { myInventoryActions } from "../../store/inventory";
import { LoadingOutlined } from "@ant-design/icons";

const SearchCard = (props: any) => {
  const { auth, shop } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRevert = async () => {
    await revertStockAction(props?.data?._id, {}, auth.token)(dispatch);
    notification.success({ message: "IMEI Reverted Successfully" });
    navigate(-1);
    dispatch(myInventoryActions.setSearchBySn(null));
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;
  return (
    <div className="relative bg-white text-black border border-white hover:border-gray-200 min-h-[126px] rounded-md ">
      <div className="p-2 pl-5 space-y-3 h-full">
        <div>
          <div className="flex justify-between w-full">
            <div>
              <div>
                <h1 className="font-semibold text-lg">
                  {props?.data?.quotationItem?.product?.model}
                </h1>
                <p className="text-xs" style={{ color: "blue" }}>
                  {"S/N: " + props?.data?.serialNumber}
                </p>
                <div className="my-4">
                  <div className="grid grid-cols-2 gap-2">
                    {props?.data?.quotationItem?.specification?.map(
                      (item: string, _index: number) => (
                        <>
                          <span>{item[1] && item[0]}</span>
                          <span>{item[1]}</span>
                        </>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Tag
                color={searchValue(props?.data?.status)}
                style={{ minWidth: "4rem", textAlign: "center" }}
                className="capitalize"
              >
                {props?.data?.status}
              </Tag>
            </div>
          </div>
        </div>
        <div>
          <div className="absolute bottom-2">
            <Stack alignItems={"center"} spacing={0} direction={"row"}>
              {props?.data?.shopStock?.channel?.name && (
                <Tag className="capitalize " color={"cyan"}>
                  {props?.data?.shopStock?.channel?.name?.split("-")?.join(" ")}
                </Tag>
              )}
              {props?.data?.shopStock?.name && (
                <Tag className="capitalize">
                  {props?.data?.shopStock?.type === "connect-shop"
                    ? `${props?.data?.shopStock?.name}~CS`
                    : props?.data?.shopStock?.type === "service-center"
                    ? `${props?.data?.shopStock?.name}~SC`
                    : `${props?.data?.shopStock?.name}`}
                </Tag>
              )}
              {props?.data?.status === "idle" && (
                <Tag
                  color={props?.data?.type === "connect-shop" ? "cyan" : "lime"}
                  className="capitalize text-sm text-white bg-blue-500 cursor-pointer"
                  onClick={handleRevert}
                  onLoad={shop?.isFetching}
                >
                  {shop?.isFetching ? <Spin indicator={antIcon} /> : "Revert"}
                </Tag>
              )}
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchCard;
