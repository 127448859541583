import { notification } from "antd";
import { myEbmActions } from ".";
import {
  createItemsService,
  getAllItemsService,
  getAllOptionsService,
  createPurchaseItemsService,
  getEbmWaitingImportServices,
  getEbmWaitingPurchaseServices,
  getEbmSelectOptionServices,
  creatEbmMergedService,
  updateEbmImport,
  getebmopenAndclosingStockService,
} from "./services";

export const createItemsAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEbmActions.setIsFetching(true));
      const res = await createItemsService(token, data);

      if (res?.status === 200) {
 
        dispatch(myEbmActions.setNew(res));
     
        dispatch(myEbmActions.setIsFetching(false));
      }
      dispatch(myEbmActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllOptionsAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEbmActions.setIsFetching(true));
      const res = await getAllOptionsService(token, query);
      // console.log(">>>>>>>:: channels", res);
      if (res?.status === 200) {
        dispatch(myEbmActions.setOptions(res));
        dispatch(myEbmActions.setIsFetching(false));
      }
      dispatch(myEbmActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getebmopenAndclosingStockAction = (
  token: string,
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEbmActions.setIsFetching(true));
      const res = await getebmopenAndclosingStockService(token, query);
      if (res?.status === 200) {
        dispatch(myEbmActions.setOpenAndClosingStock(res));
        dispatch(myEbmActions.setIsFetching(false));
      }
      dispatch(myEbmActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllItemsAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEbmActions.setIsFetching(true));
      const res = await getAllItemsService(token, query);
      // console.log(">>>>>>>:: channels", res);
      if (res?.status === 200) {
        dispatch(myEbmActions.setManageItem(res));
        dispatch(myEbmActions.setIsFetching(false));
        notification.success({ message: "Updated SuccesFully" });
      }
      dispatch(myEbmActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const createPurchaseItemsAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEbmActions.setIsFetching(true));
      const res = await createPurchaseItemsService(token, data);
      // console.log(">>>>>>>:: channels", res);
      if (res?.status === 201) {
        // const resAll = await getAllItemsService(token, "?");
        dispatch(myEbmActions.setNew(res));
        // dispatch(myEbmActions.setManageItem(resAll));
        dispatch(myEbmActions.setIsFetching(false));
        return true;
      } else if (res?.status === 409) {
        notification.error({ message: res?.error });
      }
      dispatch(myEbmActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
      return false;
    }
  };
};
export const getEbmWaitingImportActions = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEbmActions.setIsFetching(true));
      const res = await getEbmWaitingImportServices(token, query);
      // console.log(">>>>>>>:: channels", res);
      if (res?.status === 200) {
        dispatch(myEbmActions.setEbmWaitingImport(res));
        dispatch(myEbmActions.setIsFetching(false));
      }
      dispatch(myEbmActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getEbmWaitingPurchaseActions = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEbmActions.setIsFetching(true));
      const res = await getEbmWaitingPurchaseServices(token, query);
      // console.log(">>>>>>>:: channels", res);
      if (res?.status === 200) {
        dispatch(myEbmActions.setEbmWaitingPurchase(res));
        dispatch(myEbmActions.setIsFetching(false));
      }
      dispatch(myEbmActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getEbmSelectOptionAction = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEbmActions.setIsFetching(true));
      const res = await getEbmSelectOptionServices(token, query);
      if (res?.status === 200) {
        dispatch(myEbmActions.setEbmSelectOption(res?.data));
        dispatch(myEbmActions.setIsFetching(false));
      }
      dispatch(myEbmActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      return null;
    }
  };
};

export const createMergePurchaseAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEbmActions.setIsFetching(true));
      const res = await creatEbmMergedService(token, data);
      if (res?.status === 201) {
        dispatch(myEbmActions.setEbmMergedData(res));
        dispatch(myEbmActions.setIsFetching(false));
        return true;
      }
      dispatch(myEbmActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
      return false;
    }
  };
};
export const updateEbmImportAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEbmActions.setIsFetching(true));
      const res = await updateEbmImport(token, data);
      if (res?.status === 200) {
        console.log(">>>>res?.status", res?.status);
        // const resAll = await getAllItemsService(token, "?");
        dispatch(myEbmActions.setUpdated(res));
        // dispatch(myEbmActions.setManageItem(resAll));
        dispatch(myEbmActions.setIsFetching(false));
        notification.success({
          message: "Successfully",
        });
        return true;
      }
      dispatch(myEbmActions.setIsFetching(false));
      notification.error({
        message: "EBM Import Failed",
      });
      return false;
    } catch (err) {
      console.log(err);
      return false;
    }
  };
};
