import React from "react";
interface propCard {
  title?: any,
  amount?: any,
  name?: any,
  icon?: any,
  iconTwo?: any
}
const TwoIconsCard = ({ title, amount, name, icon, iconTwo }: propCard) => {
  const styles = {
    border: {
      border: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)"
    },
    shadow: {
      boxShadow: "1px 4px 24px 0px rgba(3, 2, 41, 0.06)"
    }
  };
  return (
    <div className="bg-white p-6 rounded-[15px] py-5" style={styles.shadow}>
      <div className="flex justify-between">
        <h1 className="text-[#030229] font-medium text-2xl">{amount}</h1>
        {iconTwo}
      </div>
      <p className="text-[#030229] text-xl font-light">{title}</p>
      <div>
        <div></div>
        <div className="flex gap-3 mt-2">
          {icon &&
            <p className="text-[#030229] opacity-[30%] text-[12px] font-light flex justify-center items-center bg-[#97CE71] w-4 h-4 rounded-full ">

              {icon}

            </p>}
          <p className="text-[#030229] font-light text-[12px]">{name}</p>
        </div>
      </div>
    </div>
  );
};

export default TwoIconsCard;



