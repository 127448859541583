import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "Transaction-Library",
  initialState: {
    isFetching: false,
    all: { data: [] },
    selected: null,
    attributes: null,
    new: null,
    updated: null,
    query: null,
    stock: null,
    transaction: null,
    selectedTransaction: null,
    carts: null,
    isCartOk: false,
    priceList: null,
    stockByStatus: null,
    recent: null,
    nosale: null,
    onePriceListItem: null,
    salesReport: null,
    shopAgent: null,
    transationRange: null,
    shopStock: null,
    shopStockItem:null
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setAttributes(state, action) {
      state.attributes = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setUpdated(state, action) {
      state.updated = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setStock(state, action) {
      state.stock = action.payload;
    },
    setStockByStatus(state, action) {
      state.stockByStatus = action.payload;
    },
    setTransation(state, action) {
      state.transaction = action.payload;
    },
    setSelectedTransation(state, action) {
      state.selectedTransaction = action.payload;
    },
    setCarts(state, action) {
      state.carts = action.payload;
    },
    setIsCartok(state, action) {
      state.isCartOk = action.payload;
    },
    setPriceList(state, action) {
      state.priceList = action.payload;
    },
    setRecent(state, action) {
      state.recent = action.payload;
    },
    setNosale(state, action) {
      state.nosale = action.payload;
    },
    setOnePriceListItem(state, action) {
      state.onePriceListItem = action.payload;
    },
    setSalesReport(state, action) {
      state.salesReport = action.payload;
    },
    setShopAgent(state, action) {
      state.shopAgent = action.payload;
    },
    setTransationRange(state, action) {
      state.transationRange = action.payload;
    },
    setShopStock(state, action) {
      state.shopStock = action.payload;
    },
    setShopStockItem(state, action){
      state.shopStockItem = action.payload;
    }
  },
});

export const myPosActions = mySlice.actions;

export default mySlice.reducer;
