import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "overViewLibrary",
  initialState: {
    isFetching: false,
    allPaymentHistory: { data: [] },
    noSale: null,
    totalRevenue: null,
    quantityRevenue: null,
  },
  reducers: {
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setAllPaymentHistory(state, action) {
      state.allPaymentHistory = action.payload;
    },
    setNoSale(state, action) {
      state.noSale = action.payload;
    },
    setTotalRevenue(state, action) {
      state.totalRevenue = action.payload;
    },
    setQuantityRevenue(state, action) {
      state.quantityRevenue = action.payload;
    },
  },
});

export const mySubscritionActions = mySlice.actions;

export default mySlice.reducer;
