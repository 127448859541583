import HeaderComponent from "../../../components/HeaderComponent";
import { Paper, Stack, Divider, IconButton, Tooltip } from "@mui/material";
import QuotationTable from "../../../components/tables/QuotationTable";
import QuotationGrid from "../../../components/grids/QuotationGrid";
import { Select, Spin, Skeleton, Button, Space } from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddNewQuotation from "../../../components/forms/NewQuotationForm";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllQuotationSupplierAction,
  getAllQuotationsAction,
  createQuotationAction,
} from "../../../store/quotation/actions";
import { myQuotationActions } from "../../../store/quotation";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import { rfqIncludeItem } from "../../../assets/data/includedItem";
import { IoFilterOutline } from "react-icons/io5";

const QuotationFilter = () => {
  const { quotation } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState(false);
  const [type, setType] = useState("");
  const [supplier, setSupplier] = useState("");

  dispatch(
    myQuotationActions.setQuery(
      `?${type && `status=${type}&`}${supplier && `supplier=${supplier}&`}`
    )
  );
  const resetFilter = () => {
    if (selectedFilters) {
      setType("");
      setSupplier("");
    }
  };

  return (
    <Paper elevation={0} className="pl-4 pr-4 pt-2 pb-2">
      <Stack
        spacing={1}
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Button
          style={{
            backgroundColor: selectedFilters ? "#F5F6FA" : " ",
          }}
          onClick={() => {
            setSelectedFilters(!selectedFilters);
            resetFilter();
          }}
        >
          <Space>
            <IoFilterOutline
              style={{
                color: selectedFilters ? "#605BFF" : " ",
              }}
              size={20}
              className="text-[#030229] opacity-70 font-medium"
            />
            <p
              style={{
                color: selectedFilters ? "#605BFF" : " ",
              }}
              className="text-[.8rem] text-[#030229] font-medium opacity-70"
            >
              {" "}
              Filter
            </p>
          </Space>
        </Button>

        {selectedFilters && (
          <Stack
            spacing={1}
            direction="row"
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              allowClear
              placeholder="Filter By Type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").toString().includes(input)
              }
              loading={quotation.isFetching}
              disabled={quotation.isFetching}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")

                  .toString()
                  .toLowerCase()
                  .localeCompare(
                    (optionB?.label ?? "").toString().toLowerCase()
                  )
              }
              options={quotation?.filters?.status.map((stat: any) => ({
                label: stat.status.toUpperCase(),
                value: stat.status,
              }))}
              onChange={(value: any) => {
                !value && setType("");
                value && setType(value);
              }}
            />
            {quotation?.filters?.suppliers && (
              <Select
                showSearch
                style={{ width: 200 }}
                allowClear
                placeholder="Filter By Supplier"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input)
                }
                loading={quotation.isFetching}
                disabled={quotation.isFetching}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")

                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                options={quotation?.filters?.suppliers.map((stat: any) => ({
                  label: stat?.name.toString().toUpperCase(),
                  value: stat._id,
                }))}
                onChange={(value: any) => {
                  !value && setSupplier("");
                  value && setSupplier(value);
                }}
              />
            )}
          </Stack>
        )}
        <h1 className="text-gray-400">
          Result:{" "}
          {quotation?.isFetching ? (
            <Spin size="small" className="ml-4" />
          ) : (
            quotation?.allRfqSupplier?.result
          )}
        </h1>
      </Stack>
    </Paper>
  );
};

const AddNewQuotationButton = (props: any) => {
  const navigate = useNavigate();
  const [showDrafted, setShowDrafted] = useState(false);
  const { auth, quotation } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const handleAddNewQuotation = () => {
    auth?.token &&
      createQuotationAction(auth?.token, {
        isNotify: true,
        notification: {
          action: "RFQ Created",
          role: ["admin", "finance", "finance-manager", "inventory"],
          message: `New RFQ has been Created`,
          title: "Request for Quotation",
        },
      })(dispatch);
    console.log("clicked");
  };
  useEffect(() => {
    quotation?.new &&
      navigate(
        `/${props?.route === "purchase" ? "purchase" : "inventory"}/rfq/${
          quotation?.new?.data.quotationId
        }`
      );
  }, [navigate, props?.route, quotation?.new]);

  return (
    <Stack
      direction={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      spacing={2}
      divider={<Divider orientation="vertical" flexItem />}
    >
      <Tooltip title={showDrafted ? "Hidde Drafted RFQs" : "Show Drafted RFQs"}>
        <IconButton
          sx={{ p: 1 }}
          aria-label="directions"
          onClick={() => {
            setShowDrafted(!showDrafted);
            props.setShowDrafted(!showDrafted);
          }}
        >
          {showDrafted ? <VisibilityIcon /> : <VisibilityOutlinedIcon />}
        </IconButton>
      </Tooltip>
      <LoadingButton
        variant="contained"
        startIcon={<AddCircleIcon />}
        onClick={() => handleAddNewQuotation()}
        loading={quotation?.isFetching}
      >
        New RFQ
      </LoadingButton>
    </Stack>
  );
};
const PageView = (props: any) => {
  const [isGridView, setIsGridView] = useState(true);
  const [showDrafted, setShowDrafted] = useState(true);

  const { auth, quotation } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [limit, setLimit] = useState(24);
  const [page, setPage] = useState(1);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatch(myQuotationActions.setSelected(null));
    dispatch(myQuotationActions.setNewRfq(null));
    auth?.token &&
      getAllQuotationSupplierAction(
        auth?.token,
        quotation?.query +
          `limit=${limit}&page=${page - 1}&field=${rfqIncludeItem}`
      )(dispatch);
  }, [auth?.token, dispatch, quotation?.query, page, limit]);

  useEffect(() => {
    auth?.token &&
      getAllQuotationsAction(
        auth?.token,
        `?isDraft=true&status=draft`
      )(dispatch);
  }, [auth?.token, quotation?.query, quotation.allRfqSupplier.data, dispatch]);
  const goBack = () => {
    navigate(-1);
  };

  return (
    <Stack spacing={1}>
      <div className="text-[#030229]  opacity-80  font-normal pt-2 pb-2">
        <DetailsHeaderActionBar
          pageName="Request For Quotation"
          title=" "
          goBack={goBack}
        />
      </div>
      <HeaderComponent
        // title="Manage Quotations"
        item="Quotation"
        modelTitle="Add Quotation"
        isNotCollapse={true}
        isNotAddButton={true}
        filterCard={<QuotationFilter />}
        ModelComponent={<AddNewQuotation />}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleCancel={handleCancel}
        setIsGridView={setIsGridView}
        goForward="/purchase/rfq/new"
        OtherButton={<AddNewQuotationButton setShowDrafted={setShowDrafted} />}
      />
      {isGridView && (
        <Stack spacing={1}>
          {quotation?.isFetching && quotation?.all?.data?.length === 0 ? (
            <div className="flex flex-wrap gap-8 justify-start items-start">
              {[1, 2, 3, 4, 1, 2, 3, 4, 5, 6, 7, 8]?.map(() => (
                <div className="bg-white w-[360px] h-[154px] border-t-[0.5px] border-t-[rgba(217,219,233,0.5)] rounded-[10px] shadow-[1px_3px_16px_0px_rgba(0,0,0,0.07)] p-6">
                  <Skeleton active paragraph={{ rows: 2 }} />
                </div>
              ))}
            </div>
          ) : (
            <ScrollableFrame
              setPage={setPage}
              setLimit={setLimit}
              limit={limit}
              total={quotation?.allRfqSupplier?.total}
              count={Math.ceil(quotation?.allRfqSupplier?.total / limit)}
            >
              <Box>
                <div className="flex flex-wrap gap-8 justify-start items-start">
                  <QuotationGrid data={quotation?.allRfqSupplier?.data} />
                </div>
              </Box>
              {showDrafted && (
                <Box sx={{ pt: 4, pb: 3 }}>
                  <Stack direction="column" spacing={1}>
                    <span className="space-x-1 text-[#030229] font-normal">
                      RFQs Drafted :
                      {quotation.isFetching ? (
                        <Spin size="small" className="ml-4" />
                      ) : (
                        quotation?.all?.result
                      )}
                    </span>
                    <div className="flex flex-wrap gap-8 justify-start items-start">
                      <QuotationGrid data={quotation?.all?.data} draft={true} />
                    </div>
                  </Stack>
                </Box>
              )}
            </ScrollableFrame>
          )}
        </Stack>
      )}
      {!isGridView && (
        <Paper elevation={2}>
          <Stack spacing={1}>
            <QuotationTable data={quotation?.all?.data} />
          </Stack>
        </Paper>
      )}
    </Stack>
  );
};
export default PageView;
