import { useState, useEffect } from "react";
import { Box, Stack, Button, Input, TextField } from "@mui/material";
import { Table } from "./../components/reUsableTable/Table";
import { data } from "../assets/data/dummyDataTable";
import { ColumnDef } from "@tanstack/react-table";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getOneSupplierQuotationRfq } from "../store/quotation/actions";
import { myQuotationActions } from "../store/quotation";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { supQuotColumns } from "../components/reUsableTable/colmns";
import Logo from "../assets/icons/logo.svg";

import QuotationToSupplierTable from "../components/tables/QuotationToSupplierTable";

const LabelValue = (props: any) => (
  <Stack direction="row" spacing={2}>
    <span className="font-bold">{props?.label}</span>
    <span> {props?.value}</span>
  </Stack>
);

const PageView = () => {
  const { id } = useParams();
  const { quotation, auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(myQuotationActions.setSelected(null));
    id && getOneSupplierQuotationRfq(id)(dispatch);
  }, [id]);

  const value = quotation?.selectedRfq?.quotation.listOfProducts;

  return (
    <>
      <Box className="landScreen min-h-screen text-gray-900 flex justify-center font-serif">
        <Box className="w-[80%] max-h-full bg-white p-8 flex flex-col gap-4">
          <div className="grid grid-cols-2 gap-2">
            <div className="pl-2">
              {quotation?.selectedRfq?.supplier?.logo ? (
                <img
                  src={quotation?.selectedRfq?.supplier?.logo}
                  className="w-12 h-12 rounded-full"
                  alt="logo"
                />
              ) : (
                <div className="border-2 border-gray-500 w-fit p-0.5">
                  <p className="font-bold">
                    {" "}
                    <span className="bg-black text-white p-0.5 uppercase">
                      {quotation?.selectedRfq?.supplier?.name?.split(" ")[0]}
                    </span>{" "}
                    {quotation?.selectedRfq?.supplier?.name?.split(" ")[1]}
                  </p>
                </div>
              )}
              <div className="mt-5">
                <h1 className="font-bold mb-1 uppercase">
                  {quotation?.selectedRfq?.supplier?.name}
                </h1>
                <p>
                  TIN:
                  <span className="text-gray-900 pl-2">
                    {quotation?.selectedRfq?.supplier?.tin}
                  </span>
                </p>
                <p>
                  <span className="text-gray-900">
                    {quotation?.selectedRfq?.supplier?.address}
                  </span>
                </p>
                <p>
                  <span className="text-gray-900">USA</span>
                </p>
                <p>
                  <span className="text-gray-900">
                    {quotation?.selectedRfq?.supplier?.email}
                  </span>
                </p>
              </div>
            </div>
            <div className="ml-44">
              <img className="h-8 object-cover" src={Logo} alt=""/>
              <div className="mt-5 ml-4">
                <h1 className="font-bold mb-1">SAMPHONE RWANDA LTD</h1>
                <p>
                  <span className="text-gray-900">
                    TCB Building, 3rd Floor B, Kiyovu Cell, Nyarugenge Sector
                  </span>
                </p>
                <p>
                  <span className="text-gray-900">
                    Kigali, City of Kigali 0000
                  </span>
                </p>
                <p>
                  <span className="text-gray-900">Rw</span>
                </p>
                <p>
                  <span className="text-gray-900">www.samphone.com</span>
                </p>
                <p>
                  <span className="text-gray-900">
                    VAT Agency Registration no: 112620773
                  </span>
                </p>
                <p>
                  <span className="text-gray-900">Govt. UID 112620773</span>
                </p>
              </div>
            </div>
          </div>
          <div className="pl-2 mb-5">
            <h1 className="font-bold">Request For Quotation</h1>
            <span className="text-blue-500 font-semibold text-lg">
              {quotation?.selectedRfq?.quotation?.quotationId}
            </span>
          </div>
          <QuotationToSupplierTable />
        </Box>
      </Box>
    </>
  );
};

export default PageView;
