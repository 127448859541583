import react, { ReactNode } from "react";
interface buttonProps {
  btnName?: any;
  btnBgColor?: any;
  btnWidth?: string;
  textColor?: string;
  icon?: ReactNode;
  onClick?: any;
}
const EbmButton = ({
  btnName,
  btnBgColor,
  btnWidth,
  textColor,
  icon,
  onClick,
}: buttonProps) => {
  const styles = {
    border: {
      border: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)",
    },
    shadow: {
      boxShadow: "1px 4px 24px 0px rgba(3, 2, 41, 0.06)",
    },
  };
  return (
    <>
      <button
        className={`bg-${btnBgColor}  text-${textColor} w-${btnWidth} font-medium text-sm  rounded-md py-2 px-6 flex justify-center items-center text-black`}
        onClick={onClick}
        style={styles.shadow}
      >
        {icon && <span className="pr-2 pt-[3px]">{icon}</span>}
        {btnName}
      </button>
    </>
  );
};
export default EbmButton;
