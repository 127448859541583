import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getAllPaymentHistoryService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/statistics/history/${query}`, token);
};
export const getAllNoSaleService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/statistics/nosale${query}`, token);
};
export const getTotalRevenueService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/statistics/revenue${query}`, token);
};
export const getRevenueQuantityService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(
    `${SERVER_URL}/statistics/revenueQuantity${query}`,
    token
  );
};
