import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeliveryNoteGrid from "../../../components/grids/WareHouseInGrid";
import { getAllDeliveryNoteAction } from "../../../store/wareHouse/actions";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import { useMediaQuery } from "@mui/material";

const FinanceDeliveryNote = (props: any) => {
  const { wareHouse, auth, layout } = useSelector((state: any) => state);
  const values = wareHouse?.all?.data;
  const isTablet = useMediaQuery("(min-width: 771px)");
  const isOnTablet = useMediaQuery("(max-width: 960px)");
  const dispatch = useDispatch();
  useEffect(() => {
    auth?.token && getAllDeliveryNoteAction(auth?.token, "?")(dispatch);
  }, [auth?.token, dispatch]);
  const data: {
    id: any;
    deliveryID: any;
    data: any;
    pOrderID: any;
    arrivalDate: any;
    status: any;
    qnt: any;
  }[] = [];

  values &&
    values?.forEach((el: any) => {
      data?.push({
        id: el._id,
        deliveryID: el.deliveryID,
        pOrderID: el.pOrderID.pOrderID,
        arrivalDate: el.arrivalDate,
        status: el?.status,
        data: el,
        qnt: el?.listOfItems?.reduce(
          (sum: number, a: any) => (sum = sum + a?.qtySent),
          0
        ),
      });
    });
  return (
    <div className="text-black py-5 mt-3 space-y-2 ">
      <ScrollableFrame hidePagination>
        <div
          className={` grid sm:grid-cols-2 md:${
            isOnTablet ? "grid-cols-2" : "grid-cols-3"
          } xl:grid-cols-4 2xl:grid-cols-5 gap-5 ${
            layout?.isSideNavOpen ? "" : " "
          }`}
        >
          <DeliveryNoteGrid data={data} />
        </div>
      </ScrollableFrame>
    </div>
  );
};

export default FinanceDeliveryNote;
