import React, { useEffect, useState } from "react";
import { DatePicker, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import { InventoryTransferReportHeader } from "../../csvHeaders/Headers";
import { VscFilePdf } from "react-icons/vsc";
import FilesCard from "../../cards/NoSale/FilesCard";
import CSVExportButton from "../../cards/NoSale/CSVExportButton";
import ReportFiltering from "../../filters/ReportFiltering";
import { getAllTransferReportAction } from "../../../store/report/actions";
import { getAllTransferReport_CSVAction } from "../../../store/csvDownload/actions";
import ScrollableFrame from "../../layout/ScrollableFrame";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import type { TimeRangePickerProps } from "antd";

interface DataType {
  [x: string]: any;
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const { RangePicker } = DatePicker;

const TransferReportTable = () => {
  const { auth, CSV, report, layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const formattedDate = new Date().toISOString().slice(0, 10);
  let today = moment();
  let previousDate = today.subtract(1, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState<any>(formattedPreviousDate);
  const [endDate, setEndDate] = useState<any>(formattedDate);

  const [selectedShop, setSelectedShop] = useState("");
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);

  const columns: ColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Transfered by (Names)",
      dataIndex: "transferedBy",
      key: "transferedBy",
      render: (text: any, _) => <p className="capitalize">{text}</p>,
    },
    {
      title: "brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "ROM",
      dataIndex: "capacity",
      key: "capacity",
    },
    {
      title: "RAM",
      dataIndex: "memory",
      key: "memory",
    },
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
    },
    {
      title: "From Shop",
      dataIndex: "fromShop",
      key: "fromShop",
    },
    {
      title: "To Shop",
      dataIndex: "toShop",
      key: "toShop",
    },
  ];

  useEffect(() => {
    auth?.token &&
      getAllTransferReportAction(
        auth?.token,
        `?startingDate=${startDate}&endingDate=${endDate}&limit=${limit}&page=${
          page - 1
        }${selectedShop ? `&fromShop=${selectedShop}` : ""}`
      )(dispatch);
  }, [auth?.token, dispatch, selectedShop, limit, page, startDate, endDate]);

  useEffect(() => {
    if (layout?.openCSVButton)
      auth?.token &&
        getAllTransferReport_CSVAction(
          auth?.token,
          `?startingDate=${startDate}&endingDate=${endDate}&limit=${
            report?.transerReport?.total
          }&page=0${selectedShop ? `&fromShop=${selectedShop}` : ""}`
        )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  const dataToDisplay = report?.transerReport?.data?.map(
    (el: any, index: any) => {
      return {
        key: index,
        date: el?.date?.slice(0, 10),
        brand: el?.brand,
        model: el?.model,
        imei: el?.imei,
        fromShop: el?.fromShopName?.name,
        toShop: el?.toShopName?.name,
        transferedBy: el?.transferedBy,
        capacity: el?.storage
          ?.filter((item: any) =>
            ["capacity (rom)", "capacity(rom)", "rom", "capacity"]?.includes(
              item[0]?.toLowerCase()
            )
          )
          ?.map((item: any) => item[1]),
        memory: el?.storage
          ?.filter((item: any) =>
            ["memory (ram)", "memory (ram)", "ram", "memory"]?.includes(
              item[0]?.toLowerCase()
            )
          )
          ?.map((item: any) => item[1]),
        data: el,
      };
    }
  );

  const csvData = CSV?.csvDownloadedData?.data?.map((el: any) => {
    return {
      date: el?.date?.slice(0, 10),
      brand: el?.brand,
      model: el?.model,
      imei: el?.imei,
      fromShop: el?.fromShopName?.name,
      toShop: el?.toShopName?.name,
      transferedBy: el?.transferedBy,
      capacity: el?.storage
        ?.filter((item: any) =>
          ["capacity (rom)", "capacity(rom)", "rom", "capacity"]?.includes(
            item[0]?.toLowerCase()
          )
        )
        ?.map((item: any) => item[1]),
      memory: el?.storage
        ?.filter((item: any) =>
          ["memory (ram)", "memory (ram)", "ram", "memory"]?.includes(
            item[0]?.toLowerCase()
          )
        )
        ?.map((item: any) => item[1]),
    };
  });

  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
    { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
    { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
    { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
  ];

  const dateFormat = "YYYY/MM/DD";
  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      setStartDate(dateStrings[0]);
      setEndDate(dateStrings[1]);
    }
  };

  return (
    <div className="relative text-black mt-4">
      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={report?.transerReportt?.total}
        count={Math.ceil(report?.transerReport?.total / limit)}
      >
        <div className="flex items-center justify-between">
          <ReportFiltering
            setSelectedShop={setSelectedShop}
            selectedShop={selectedShop}
            isStockReport={false}
          />
          <div className="flex justify-end gap-4 py-4">
            <RangePicker
              presets={rangePresets}
              onChange={onRangeChange}
              defaultValue={[
                dayjs(startDate, dateFormat),
                dayjs(endDate, dateFormat),
              ]}
            />
            <div className="flex items-center space-x-2">
              <FilesCard
                Icon={VscFilePdf}
                tailwindTextColor="text-white"
                tailwindBackgroundColor="bg-orange-400"
                disabled
              />
              <CSVExportButton
                csvHeaders={InventoryTransferReportHeader}
                csvData={csvData}
                filename={`Transfer Report.csv`}
              />
            </div>
          </div>
        </div>

        <Table
          columns={columns}
          dataSource={dataToDisplay}
          loading={report?.isFetching}
          pagination={false}
        />
      </ScrollableFrame>
    </div>
  );
};

export default TransferReportTable;
