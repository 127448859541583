import React from "react";
import type { TableProps } from "antd/es/table";
import * as convert from "../../../assets/data/productExport";
import EmployeeCard from "./EmployeeGridCard";

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const onChange: TableProps<DataType>["onChange"] = (
  pagination,
  filters,
  sorter,
  extra
) => {
  console.log("params", pagination, filters, sorter, extra);
};

const App = (props: any) => {
  const data = props?.data?.map((d: any) =>
    convert.createExportUserData(
      d.bio,
      d.names,
      d.phone,
      d.email,
      d.password,
      d?.role,
      d.nid,
      d.picture,
      d
    )
  );

  return data.map((d: any) => <EmployeeCard data={d} />);
};

export default App;
