import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import POSSteps from "./POSSteps";
import AddToCart from "./steps/AddToCart";
import UserInfoAndPaymentMethod from "./steps/UserInfoAndPaymentMethod";
import { useParams } from "react-router";
import {
  getShopStockCartAction,
  updateShopStockCartAction,
  updateShopStockCartDetailsAction,
} from "../../../store/wareHouse/actions";
import ConfirmCheckout from "./steps/ConfirmCheckout";
import {
  getActivePrice,
  getTotalPaidAmount,
  getTotalPrice,
} from "../../../utils/converter";
import { checkoutCartAction } from "../../../store/pos/actions";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import { myWareHouseActions } from "../../../store/wareHouse";
import { Box, Skeleton, Stack } from "@mui/material";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { DatePickerProps } from "antd";
import dayjs from "dayjs";

dayjs.extend(utc);
dayjs.extend(timezone);

const SaleWithPOS = () => {
  const { auth, wareHouse, customer, pos } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isNewItemAdded, setIsNewItemAdded] = useState<any>(false);
  const { cartId } = useParams();
  const [confirmationDate, setConfirmationDate] = useState<string | string[]>(
    dayjs().format("DD/MM/YYYY")
  );
  const onChangeDate: DatePickerProps["onChange"] = async (
    date,
    dateString
  ) => {
    console.log(date);
    setConfirmationDate(dateString);
    // setConfirmationDate(dateString);
  };
  console.log();
  useEffect(() => {
    auth?.token && getShopStockCartAction(auth?.token, cartId)(dispatch);
  }, [auth?.token, cartId, dispatch]);

  useEffect(() => {
    if (customer?.searchResult?.length > 0) {
      auth?.token &&
        updateShopStockCartDetailsAction(auth?.token, cartId, {
          customer: customer?.searchResult[0]?._id,
        })(dispatch);
    }
  }, [customer?.searchResult, cartId, dispatch, auth?.token]);

  useEffect(() => {
    if (pos?.isCartOk) navigate("/pos/sale");
  }, [navigate, pos?.isCartOk]);

  const addToCart = async () => {
    const product = wareHouse?.searchResult?.data?.product;
    console.log(product);
    const payload = {
      cart: cartId,
      shopStockItem: product?._id,
      warehouseProduct: product?.wareHouseItem?._id,
      channel: product?.shop?.channel?._id,
      shop: product?.shop?._id,
      priceProduct: product?.requestedItem?.product?._id,
      payment: {
        amount: +getActivePrice(product?.requestedItem?.product?.prices),
        extendedWarranty: 0,
      },
    };

    auth?.token &&
      (await updateShopStockCartAction(auth?.token, cartId, payload)(dispatch));
    dispatch(myWareHouseActions.setSearchResult(null));
  };

  const handleCheckout = async () => {
    const discountPerc = wareHouse?.createdCart?.data?.discount?.discountPerc;

    const totalPrice = getTotalPrice(wareHouse?.createdCart?.data?.list);
    const payment = getTotalPaidAmount(wareHouse?.createdCart?.data?.payment);

    const finalAmount = totalPrice - totalPrice * (discountPerc / 100);
    if (payment === undefined) {
      notification.warning({ message: "Please provide payments!" });
    }
    // if (finalAmount === payment) {
      if (auth?.token) {
        const res = await checkoutCartAction(auth?.token, cartId as string, {
          status: "paid",
          paidOn: confirmationDate,
        })(dispatch);

        if (res) {
          return;
        } else {
          return notification.error({
            message: "Paid amount must be equal to total amount!",
          });
        }
      }
    // }
  };

  const steps = [
    {
      title: "Add to cart",
      content: (
        <AddToCart
          isNewItemAdded={isNewItemAdded}
          setIsNewItemAdded={setIsNewItemAdded}
          addToCart={addToCart}
        />
      ),
    },
    {
      title: "Customer and Payment",
      content: <UserInfoAndPaymentMethod cartId={cartId} />,
    },
    {
      title: "Checkout",
      content: (
        <ConfirmCheckout
          confirmationDate={confirmationDate}
          onChangeDate={onChangeDate}
        />
      ),
    },
  ];

  return (
    <div className="text-black">
      <DetailsHeaderActionBar pageName="POS" title="Sale" />
      {wareHouse?.isFetching && !wareHouse?.createdCart && (
        <Box>
          <Skeleton sx={{ height: "200px", margin: 0 }} />
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Skeleton sx={{ height: "15rem", width: "30%", margin: 0 }} />
            <Skeleton sx={{ height: "15rem", width: "30%", margin: 0 }} />
            <Skeleton sx={{ height: "15rem", width: "30%", margin: 0 }} />
          </Stack>
        </Box>
      )}
      {(!wareHouse?.isFetching || wareHouse?.createdCart) && (
        <POSSteps
          steps={steps}
          handleFinish={handleCheckout}
          status={wareHouse?.createdCart?.data?.status}
        />
      )}
      <div className=""></div>
    </div>
  );
};

export default SaleWithPOS;
