import React from 'react';
import {Modal } from 'antd';

interface modalProps{
    title?:any,
    component?:any,
    openModal?:any,
    closeModal?:any,
    width?:any,
}
const GeneralModal = ({title,component,openModal,closeModal,width}:modalProps) => {
  return (
    <>
      <Modal title={title} footer={null} open={openModal} onOk={closeModal} onCancel={closeModal} width={width}  centered>
        {component}
      </Modal>
    </>
  );
};

export default GeneralModal;