import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "Track",
  initialState: {
    isFetching: false,
    signatureLoading:false,
    sendOtpIsFetching: false,
    all: { data: [] },
    selected: null,
    query: null,
    trActivities: null,
    updated: null,
    newDeliverer: null,
    allDeliverer: null,
    trackingType: null,
    deliveryActivity: null,
    selectedDeliverer: null,
    selectedDriver: null,
    newOtp: null,
    otpValue: null,
    trackHistory: null,
    trackingStas:null,
    // deliveryPdf:null
    checkParckage: null,
    recieverSignature:null,
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    settrActivities(state, action) {
      state.trActivities = action.payload;
    },
    setUpdated(state, action) {
      state.updated = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setNewDeliverer(state, action) {
      state.newDeliverer = action.payload;
    },
    setAllDeliverer(state, action) {
      state.allDeliverer = action.payload;
    },
    setTrackingType(state, action) {
      state.trackingType = action.payload;
    },
    setDeliveryActivity(state, action) {
      state.deliveryActivity = action.payload;
    },
    setSelectedDeliverer(state, action) {
      state.selectedDeliverer = action.payload;
    },
    setSelectedDriver(state, action) {
      state.selectedDriver = action.payload;
    },
    setNewOtp(state, action) {
      state.newOtp = action.payload;
    },
    setOtpValue(state, action) {
      state.otpValue = action.payload;
    },
    setTrackHistory(state, action) {
      state.trackHistory = action.payload;
    },
    setSignatureLoading(state, action) {
      state.signatureLoading = action.payload;
    },
    setSendOtpIsFetching(state, action) {
      state.sendOtpIsFetching = action.payload;
    },
    setDeliveryPdf(state,action){
      state.trackHistory = action.payload;
    },
    setTrackingStas(state,action){
      state.trackingStas = action.payload;
    },
    setReceiverSignatue(state, action) {
      state.recieverSignature = action.payload;
    },
    setCheckParkage(state, action) {
      state.checkParckage = action.payload;
    },
  },
});

export const myTrackActions = mySlice.actions;

export default mySlice.reducer;


