import React, { useEffect } from "react";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import WarehouseStockTable from "../../../../components/tables/WarehouseStockTable";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getOneProductAction } from "../../../../store/product/actions";
import { getAllShopStockAction } from "../../../../store/inventory/actions";
import ShopStockTopGraph from "./ShopStockTopGraph";
import ExportCSVFile from "../../../../components/buttons/ExportCSVFile";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";

const ProductModelUnitsList = () => {
  const dataMapper: any[] = [];
  const [formData, setformData] = React.useState(dataMapper);
  const { auth, product, inventory } = useSelector((state: any) => state);
  const { productType } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchOnProduct = async () => {
      if (productType) {
        auth?.token &&
          (await getOneProductAction(auth?.token, productType)(dispatch));
      }
    };
    fetchOnProduct();
  }, [productType, auth, dispatch]);

  useEffect(() => {
    const fetchOnProduct = async () => {
      if (productType) {
        auth?.token &&
          (await getAllShopStockAction(
            auth?.token,
            `${productType}`
          )(dispatch));
      }
    };
    fetchOnProduct();
  }, [productType, auth, dispatch]);

  const value = inventory?.allShopStock?.data || false;

  React.useEffect(() => {
    value &&
      value?.forEach((el: any) => {
        dataMapper.push({
          data: el,
          key: el?.shop?._id,
          shop: el?.shop?.name,
        });
      });

    dataMapper.sort((a, b) => a.shop.localeCompare(b.shop));
    setformData(dataMapper);
  }, [inventory?.allShopStock]);

  return (
    <div className="text-black space-y-5 mt-4">
      <DetailsHeaderActionBar
        pageName="Warehouse"
        title={product?.selected?.model}
      />
      <ScrollableFrame hidePagination>
        <div className="space-y-5">
          <ShopStockTopGraph />
          <div className="bg-white p-2 rounded-lg">
            <WarehouseStockTable data={formData} />
          </div>
        </div>
      </ScrollableFrame>
    </div>
  );
};

export default ProductModelUnitsList;
