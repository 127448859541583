import React, { useEffect, useState } from "react";
import SettingButton from "../../../pages/dashboard/setting/SettingButton";
import { BiPlus } from "react-icons/bi";
import { ReactComponent as ImportIcons } from "../assets/images/icons/vscode-icons_file-type-excel2.svg";
import EbmButton from "../components/EbmButton";
import { Input } from "antd";
import type { SearchProps } from "antd/es/input/Search";
import GenearalModal from "../components/modals/GeneralModal";
import CustomButton from "../components/buttons/CustomButton";
import GeneralDrawer from "../components/GeneralDrawer";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import TwoIconsCard from "../components/cards/TwoIconsCard";
import { ReactComponent as NewIcons } from "../assets/images/icons/Group 1000002857.svg";
import { ReactComponent as AmountIcons } from "../assets/images/icons/Group 1000002860.svg";
import { ReactComponent as CustomerAmountIcons } from "../assets/images/icons/Group 1000002860_1.svg";
import type { DatePickerProps } from "antd";
import PurchaseDetails from "../components/PuchaseDetails";
import ImportTable from "../components/tables/ImportTable";
import RegisterImport from "../components/forms/RegisterImport";
import { useDispatch, useSelector } from "react-redux";
import { getEbmImportAction } from "../store/ImportManagement/action";
import { getAllItemsAction, getEbmSelectOptionAction } from "../store/action";
import EbmFilter from "../components/filter/ebmFilter";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";

const dateFormat = "YYYYMMDDHHmmss";

function formatDate(date: any) {
  const pad = (num: any) => String(num).padStart(2, "0");
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());
  return `${year}${month}${day}${hours}${minutes}${seconds}`;
}

const ImportManagement = () => {
  const { auth, ebmGeneral, ebmImport } = useSelector((state: any) => state);
  const formattedDate = new Date().toISOString().slice(0, 10);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [limit, setLimit] = useState<any>(15);
  const [page, setPage] = useState<any>(1);
  const now = dayjs();
  const formattedNow = formatDate(now.toDate());
  const [date, setDate] = useState<string>(formattedNow);

  const showModal = (data: any) => {
    setSelectedData(data);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const onChange = (date: any) => {
    if (date) {
      const formattedDate = formatDate(date.toDate());
      setDate(formattedDate);
    }
  };

  const onClose = () => {
    setOpenDrawer(false);
  };
  const { Search } = Input;
  const onSearch: SearchProps["onSearch"] = (value, _e, info) =>
    console.log(info?.source, value);

  useEffect(() => {
    auth?.token &&
      getEbmImportAction(
        auth?.token,
        `?limit=${limit}&page=${page - 1}&date=${date}`
      )(dispatch);
  }, [auth?.token, date, dispatch, limit, page]);
  useEffect(() => {
    auth?.token && getAllItemsAction(auth?.token, "")(dispatch);
  }, [dispatch, auth]);
  useEffect(() => {
    auth?.token && getEbmSelectOptionAction(auth?.token, "")(dispatch);
  }, [auth?.token, dispatch]);
  return (
    <div>
      <GeneralDrawer
        openDrawer={openDrawer}
        closeDrawer={onClose}
        width={650}
        title={
          <h1 className="text-base font-medium text-[#030229]">
            Import Information Details
          </h1>
        }
        component={
          <div>
            <div className="flex justify-between ">
              <div>
                <div className="bg-[#F4F6FA] flex justify-center items-center gap-2">
                  {" "}
                  <h1 className="text-base text-black pl-2">CurrentStatus:</h1>
                  <CustomButton
                    title={"Wait for Approval"}
                    textColor="white"
                    bgColor="green-500"
                    textSize="base"
                  />
                </div>
              </div>
              <EbmButton
                textColor="black"
                btnName={"Export"}
                icon={<ImportIcons />}
                btnBgColor="gray-50"
              />
            </div>
            <div className="mt-5">
              <PurchaseDetails />
            </div>
          </div>
        }
      />
      <div className="flex justify-between">
        <h1 className="text-[#030229] text-lg">Import Management</h1>
        <div className="flex gap-5">
          <EbmButton
            btnName={"Import"}
            icon={<ImportIcons />}
            btnBgColor="white"
          />
          <EbmButton
            btnName={"Export"}
            icon={<ImportIcons />}
            btnBgColor="white"
          />
          <SettingButton
            btnName={"New"}
            icon={<BiPlus color="white" size={20} />}
            btnBgColor="[#605BFF]"
            textColor="white"
            onClick={showModal}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-5 mt-7">
        <TwoIconsCard
          amount={"650"}
          title={"Total Items"}
          iconTwo={<NewIcons />}
        />
        <TwoIconsCard
          amount={"600"}
          title={"United Arab"}
          iconTwo={<AmountIcons />}
        />
        <TwoIconsCard
          amount={"50"}
          title={"Canada"}
          iconTwo={<CustomerAmountIcons />}
        />
      </div>
      <div className="bg-white p-3 rounded-md mt-5">
        <div className="flex justify-between">
          <EbmFilter
            setSelectedStatus={setSelectedStatus}
            data={
              ebmGeneral?.selectOption &&
              ebmGeneral?.selectOption["Import Item Status"]
            }
          />
          <div className="flex gap-6 h-10">
            <Search
              placeholder="Search by supplier name"
              onSearch={onSearch}
              style={{ width: "200px", marginTop: "3px", padding: "2px" }}
            />
            <DatePicker
              style={{ width: "250px" }}
              onChange={onChange}
              allowClear={false}
              defaultValue={now}
            />
          </div>
        </div>
        <div className="flex justify-between">
          <GenearalModal
            openModal={isModalOpen}
            closeModal={handleOk}
            title={
              <h1 className="text-[#030229] font-semibold text-lg text-center py-2">
                Import Registration
              </h1>
            }
            component={
              <div className="px-4 h-full w-full">
                <div className="mt-10 w-full">
                  <RegisterImport
                    selectedData={selectedData}
                    onClose={handleOk}
                  />
                </div>
              </div>
            }
            width={1100}
          />
        </div>
        <ScrollableFrame
          setLimit={setLimit}
          setPage={setPage}
          limit={limit}
          total={ebmImport?.all?.total}
          count={Math.ceil(ebmImport?.all?.total / limit)}
        >
          <ImportTable open={showDrawer} openModals={showModal} />
        </ScrollableFrame>
      </div>
    </div>
  );
};
export default ImportManagement;
