import react from "react";
import { Select } from "antd";
import { useSelector } from "react-redux";


const EmployeeFilter = (props: any) => {
    const { setting } = useSelector((state: any) => state);
    return (
        <>
            <Select
                showSearch
                style={{ width: 200 }}
                allowClear
                placeholder="Filter By Role"
                optionFilterProp="children"
                filterOption={(input, option) =>
                    (option?.label ?? "").toString().includes(input)
                }
                loading={setting.isFetching}
                disabled={setting.isFetching}
                filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")

                        .toString()
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toString().toLowerCase())
                }
                options={[
                    { label: "Admin", value: "admin" },
                    { label: "Sales-Agent", value: "sales-agent" },
                    { label: "Supervisor", value: "sales-supervisor" },
                    { label: "sales Manager", value: "sales-manager" },
                    { label: "Finance", value: "finance-manager" },
                    { label: "Inventory", value: "inventory" },
                    { label: "Technician", value: "technician" },
                    { label: "Dev", value: "dev" },
                    { label: "Partner", value: "partner" },
                ]}
                onChange={(value: any) => {
                    !value && props?.setRole("");
                    value && props?.setRole(value);
                }}
            />
            {props?.role === 'sales-agent' &&
                <Select
                    showSearch
                    style={{ width: 200 }}
                    allowClear
                    placeholder="Filter by company"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option?.label ?? "").toString().includes(input)
                    }
                    loading={setting.isFetching}
                    disabled={setting.isFetching}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")

                            .toString()
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toString().toLowerCase())
                    }
                    options={[
                        { label: "MTN", value: "MTN" },
                        { label: "Samphone", value: "samphone" },
                    ]}
                    onChange={(value: any) => {
                        !value && props?.setTags("");
                        value && props?.setTags(value);
                    }}
                />}
        </>
    );
};
export default EmployeeFilter;