import { Stack } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import RequestGrid from "../../../components/grids/RequestGrid";
import { Spin } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/system";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";

const PageView = (props: any) => {
  const { channel, layout } = useSelector((state: any) => state);

  return (
    <div>
      <Box>
        <div className="mt-4">
          <span className=" text-black font-medium pt-10">
            Stock Requested:
            {channel.gettingStockReq ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              channel?.selectedRfs?.result
            )}
          </span>
        </div>

        <ScrollableFrame
          setLimit={props?.setLimit}
          setPage={props?.setPage}
          limit={props?.limit}
          total={channel?.selectedRfs?.total}
          count={Math.ceil(channel?.selectedRfs?.total / props?.limit)}
        >
          <div
            className={`grid ${
              layout?.isSideNavOpen
                ? "sm:grid-cols-1 lg:grid-cols-2"
                : "sm:grid-cols-2 lg:grid-cols-3"
            } xl:grid-cols-3 2xl:grid-cols-4 gap-4`}
          >
            <RequestGrid data={channel?.selectedRfs?.data} />
          </div>
        </ScrollableFrame>
      </Box>
    </div>
  );
};

export default PageView;
