import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const createServiceSupplier = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/supplier`, data, token);
};
export const getAllServiceSupplier = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/supplier${query}`, token);
};

export const getAllSupplierByBrandService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/supplier/brand${query}`, token);
};

export const getOneServiceSupplier = async (query: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/supplier${query}`, token);
};

export const getOneServiceSupplierById = async (
  itemId: string,
  token: string
) => {
  return HttpRequest.get(`${SERVER_URL}/supplier/one/${itemId}`, token);
};

export const updateServiceSupplier = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/supplier/one/${itemId}`,
    data,
    token
  );
};

export const deleteServiceSupplier = async (itemId: string, token: string) => {
  return await HttpRequest.delete(
    `${SERVER_URL}/supplier/one/${itemId}`,
    token
  );
};

// ------------------->> Get Other related data <<-------------------

export const getAllOtherServicesSupplier = async (
  token: string,
  path: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/${path}${query}`, token);
};
