import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Stack } from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
import { Select, Form, InputNumber, Tooltip, Switch } from "antd";
import AddIcon from "@mui/icons-material/Add";
import RfsFiltering from "../../../components/filters/RfsFiltering";
import {
  getAllProductsAction,
  getOneProductAction,
} from "../../../store/product/actions";
import { myProductActions } from "../../../store/product";
import {
  addItemToSelectedRequestAction,
  getPricelistAction,
  getPricelistItemAction,
} from "../../../store/channel/actions";
import { searchFuction } from "../../../utils/converter";
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const PageView = (props: any) => {
  const [form] = Form.useForm();
  const { product, auth, quotation, channel, shop } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();

  const onFinish = async (values: any) => {
    const val = {
      ...values,
    };
    auth?.token &&
      (await addItemToSelectedRequestAction(
        channel?.selectedRfs?._id,
        val,
        auth.token
      )(dispatch));
    form.resetFields();
  };

  const [selectedModelId, setSelectedModelId] = useState("");

  const channelId = channel?.selected?._id || auth?.user?.shop?.channel;

  useEffect(() => {
    if (auth?.token && channelId) {
      getPricelistAction(auth?.token, `?channel=${channelId}`);
    }
  }, [auth?.token, channelId, dispatch]);

  React.useEffect(() => {
    auth?.token &&
      channelId &&
      getPricelistItemAction(
        auth?.token,
        `?channel=${channelId}${
          selectedModelId && `&product=${selectedModelId}`
        }&type=pos`
      )(dispatch);
  }, [auth?.token, channelId, selectedModelId, dispatch]);

  useEffect(() => {
    auth?.token &&
      getAllProductsAction(auth?.token, product?.query || "?")(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth?.token,
    dispatch,
    quotation?.query,
    quotation?.allRfqSupplier?.data,
  ]);

  useEffect(() => {
    dispatch(myProductActions.setSelected(null));
    auth?.token &&
      selectedModelId &&
      getOneProductAction(auth?.token, selectedModelId)(dispatch);
  }, [selectedModelId, dispatch, auth?.token]);

  const [productFilter, setProductFilter] = useState([]);

  const onChange = (checked: boolean) => {
    props?.onConfirmChannel(checked);
  };

  return (
    <Stack spacing={0}>
      <div className="flex items-center justify-between pr-4">
        <RfsFiltering limit={200} />{" "}
        {channel?.selectedRfs?.status === "review" &&
          props?.isTranfer &&
          ["dev"]?.includes(auth?.user?.role) && (
            <div>
              <span
                className="text-xs pr-2 font-semibold
          "
              >
                From Different Channel?
              </span>
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                style={{ paddingTop: "3px" }}
                onChange={onChange}
              />
            </div>
          )}
      </div>
      <Paper elevation={0}>
        <Stack spacing={0}>
          <Paper elevation={0} className="pl-4 pr-4 pt-2 pb-2">
            <Form
              {...formItemLayout}
              form={form}
              name="register"
              onFinish={onFinish}
              initialValues={{}}
              style={{ maxWidth: "100%" }}
              scrollToFirstError
            >
              <Stack
                spacing={0}
                direction="row"
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Stack direction={"column"} spacing={0} alignItems="start">
                  <Form.Item
                    name="product"
                    label="Product Model"
                    tooltip="Please select modal of the product!"
                    rules={[
                      {
                        required: true,
                        message: "Model is required!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      onSearch={(value: any) =>
                        searchFuction(
                          value,
                          channel?.priceListItems?.data,
                          (option: any, val) =>
                            option?.product?.model
                              ?.toLowerCase()
                              .includes(val.toLowerCase()),
                          setProductFilter
                        )
                      }
                      loading={product.isFetching}
                      disabled={product.isFetching}
                      style={{ width: 400 }}
                      value={selectedModelId === "" ? null : selectedModelId}
                      placeholder="Select By Model"
                      optionFilterProp="children"
                      filterOption={false}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .localeCompare(
                            (optionB?.label ?? "").toString().toLowerCase()
                          )
                      }
                      onChange={(value: any) => {
                        !value && setSelectedModelId("");
                        value && setSelectedModelId(value);
                      }}
                    >
                      {productFilter.length > 0
                        ? productFilter.map((pro: any) => (
                            <Option key={pro._id} value={pro._id}>
                              {pro?.product?.model} ~ {pro?.product?.type}
                            </Option>
                          ))
                        : channel?.priceListItems?.data?.map((pro: any) => (
                            <Select.Option key={pro._id} value={pro._id}>
                              <Tooltip
                                placement="rightTop"
                                title={pro?.specification
                                  .map(
                                    (spec: any) => spec[1] && spec.join(": ")
                                  )
                                  .join(", ")}
                              >
                                <span className="w-full">
                                  {pro?.product?.model} ~ {pro?.product?.type}{" "}
                                </span>
                              </Tooltip>
                            </Select.Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Stack>
                <Stack direction={"column"} spacing={0}>
                  <Form.Item
                    name="qtyRequested"
                    label="Quantity"
                    tooltip="Please enter number of quantity of the product you selected!"
                    rules={[
                      {
                        required: true,
                        message: "Quantity is required!",
                      },
                    ]}
                  >
                    <InputNumber
                      min={1}
                      max={100000}
                      style={{ width: "100%" }}
                      // onChange={onChange}
                    />
                  </Form.Item>

                  <LoadingButton
                    type="submit"
                    variant="contained"
                    onClick={() => {
                      props?.onClickAdd();
                      setSelectedModelId("");
                    }}
                    startIcon={<AddIcon />}
                    sx={{ minWidth: "100%" }}
                    loading={channel?.isFetching || shop?.isFetching}
                  >
                    Add Product
                  </LoadingButton>
                </Stack>
              </Stack>
            </Form>
          </Paper>
        </Stack>
      </Paper>
    </Stack>
  );
};
export default PageView;
