import * as React from "react";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";

export const InventoryCard: React.FC<{ data: any; total: number }> = ({
  data,
  total,
}) => {
  const [size, setSize] = React.useState({
    width: 450,
    height: 200,
  });

  const generateColors = (numColors: number): string[] => {
    const baseColor = "#0000FF";
    const colors = ["#605BFF", "#807BFF", "#A09DFF", "#BFBDFF", "#DFDEFF"];
    const step = 255 / (numColors + 1);
    const baseColorInt = parseInt(baseColor.substring(1), 16);
    const generatedColors = [];
    for (let i = 0; i < numColors; i++) {
      const newColorInt = baseColorInt + Math.round(step * (i + 1));
      generatedColors?.push(`#${newColorInt.toString(16)?.padStart(6, "0")}`);
    }
    return [...colors, ...generatedColors];
  };

  const colors =
    data && data?.length > 0 ? generateColors(data?.length) : generateColors(6);

  React.useEffect(() => {
    const updateSize = () => {
      const newWidth = Math.min(window.innerWidth * 0.9, 450); // Set max width limit
      const newHeight = newWidth * 0.5; // Keep aspect ratio at 2:1
      setSize({
        width: newWidth,
        height: newHeight,
      });
    };

    // Initial update and resize listener
    updateSize();
    window.addEventListener("resize", updateSize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <div
      style={{
        position: "relative",
        width: size.width,
        height: size.height,
      }}
    >
      <PieChart
        series={[
          {
            innerRadius: 80,
            arcLabelMinAngle: 65,
            arcLabelRadius: "60%",
            data,
            cx: 110,
            cy: 110,
          },
        ]}
        sx={{
          [`& .${pieArcLabelClasses.root}`]: {
            fontWeight: "bold",
          },
        }}
        {...size}
        colors={colors}
      />

      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "25%",
          transform: "translate(-50%, -50%)",
          fontWeight: "bold",
          fontSize: "1.2em",
        }}
      >
        <div className="flex xjustify-center flex-col items-center">
          <p className="text-xcenter font-medium text-base text-[#78787c]">
            Total
          </p>
          <p className="text-base font-poppins font-medium text-black">
            {total}
          </p>
        </div>
      </div>
    </div>
  );
};
