import { Navigate, Outlet } from "react-router-dom";
import DashLayout from "../components/layout/Layout";
import { ArrowLeftOutlined } from "@ant-design/icons";
import store from "store";
import TopNav from "../components/navitems/TopNavItems";
import { listManages } from "../assets/data/pages";
import { useSelector } from "react-redux";

export const inventoryDropdownRoutes = [
  {
    caption: "Overview",
    path: "/inventory",
  },
  {
    caption: "Devices",
    path: "/inventory/pr",
    skip: ["b2b-relation", "sales-manager"],
  },
  {
    caption: "Search Device",
    path: "/inventory/pr/product",
  },
  // {
  //   caption: "Search",
  //   path: "/inventory/pr-search",
  // },
  {
    caption: "Warehouse In",
    path: "/inventory/ws/in",
  },
  {
    caption: "Warehouse Out",
    path: "/inventory/ws/out",
  },
  {
    caption: "Stock",
    path: "/inventory/ws/stock",
  },
  {
    caption: "Request Stock?",
    path: "/inventory/rfq",
    skip: ["b2b-relation", "sales-manager"],
  },
  {
    caption: "Pick List Order",
    path: "/inventory/PickList",
    skip: ["b2b-relation", "sales-manager"],
  },
];
const DashRoutes = () => {
  const { auth } = useSelector((state: any) => state);
  const token = store.get("authToken");

  const allowedRoles = [
    "admin",
    "inventory",
    "finance",
    "finance-manager",
    "sales-manager",
    "dev",
    "b2b-relation",
  ];
  const isAuthenticated = auth.token || token;
  const userRole = auth.user?.role;

  const skip = !["finance-manager", "dev", "admin", "inventory"].includes(
    auth?.user?.role?.toLowerCase()
  );

  const pagesArray = [
    {
      caption: "Overview",
      path: "/inventory",
      onClick: () => {
        console.log("Overview:00");
      },
    },
    {
      caption: "Product",
      path: "/inventory/pr",
      onClick: () => {
        console.log("Overview:00");
      },
    },
    {
      caption: "Search",
      path: "/inventory/pr-search",
      skip: ![
        "dev",
        "admin",
        "inventory",
        "sales-manager",
        "b2b-relation",
      ].includes(auth?.user?.role?.toLowerCase()),
      onClick: () => {
        console.log("Overview:00");
      },
    },
    {
      caption: "Warehouse",
      // path: "/inventory/wr",
      onClick: () => {
        console.log("Overview:00");
      },

      sub: [
        {
          caption: "Warehouse In",
          path: "/inventory/ws/in",
          skip,
          onClick: () => {
            console.log("Overview:00");
          },
          bread: [
            {
              href: "/purchase",
              title: (
                <>
                  <ArrowLeftOutlined />
                  <span>Orders</span>
                </>
              ),
            },
            { title: "RFQ" },
          ],
        },
        {
          caption: "Warehouse Out",
          path: "/inventory/ws/out",
          skip,
          onClick: () => {
            console.log("Overview:00");
          },
        },
        {
          caption: "Stock",
          path: "/inventory/ws/stock",
          onClick: () => {
            console.log("Overview:00");
          },
        },
      ],
    },
    {
      caption: "Request Stock",
      path: "/inventory/rfq",
      skip,
      onClick: () => {
        console.log("Overview:00");
      },
    },
  ];
  if (isAuthenticated && allowedRoles.includes(userRole)) {
    return (
      <DashLayout
        allowSearch={true}
        nav={
          <TopNav
            nav={listManages?.find(
              (item) => item.title.toLowerCase() === "Inventory".toLowerCase()
            )}
            pages={pagesArray}
          />
        }
        navSelected
        selectedNav={1}
      >
        <Outlet />
      </DashLayout>
    );
  } else {
    return <Navigate to="/dashboard" />;
  }
};

export default DashRoutes;
