import React, { useState } from "react";
import { notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getPackageTrackIdAction } from "../../../../store/delivery/actions";

interface TrackingFormProps {
  onTrackChange: (id: string, hasValidLocations: boolean) => void;
}

const TrackingForm: React.FC<TrackingFormProps> = ({ onTrackChange }) => {
  const [trackingId, setTrackingId] = useState<string>("");
  const dispatch = useDispatch();
  const { auth, tracking } = useSelector((state: any) => state);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTrackingId(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (trackingId) {
      try {
        await getPackageTrackIdAction(auth?.token, trackingId)(dispatch);
        const trackingData = tracking.selected?.data;
        const locationFrom = trackingData?.locationFrom?.coordinate;
        const locationTo = trackingData?.locationTo?.coordinate;

        if (!locationFrom?.lat || !locationTo?.lat) {
          notification.warning({
            message: "Device Not Ready for Transit",
            description:
              "The device is not yet ready for transit. Please check the location data.",
          });
          onTrackChange(trackingId, false);
        } else {
          onTrackChange(trackingId, true);
        }
      } catch (error) {
        console.error("Error fetching tracking data:", error);
        notification.error({
          message: "Error",
          description: "Failed to fetch tracking data. Please try again.",
        });
      }
    } else {
      console.error("No Tracking ID provided");
    }
  };

  return (
    <div className="bg-white bg-opacity-90 p-4 rounded-lg shadow-md m-4">
      <h2 className="text-lg text-black font-semibold mb-2">
        Track your Package
      </h2>
      <p className="text-sm text-gray-600 mb-2">
        Track your package by entering your tracking ID or number
      </p>
      <form onSubmit={handleSubmit} className="flex ">
        <input
          type="text"
          placeholder="Enter your tracking number"
          value={trackingId}
          onChange={handleChange}
          className="flex-grow  border text-black border-gray-300 bg-white rounded-l-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          type="submit"
          className="bg-[#605BFF] text-white px-4 py-2 rounded-r-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </form>
    </div>
  );
};

export default TrackingForm;
