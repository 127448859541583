import { Stack } from "@mui/material";
import { Modal, Button, Typography, message } from "antd";
import "./modal.css";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const { Text } = Typography;

const RepairModal = ({
  openModel,
  onOk,
  onCancel,
  title,
}: {
  openModel: any;
  onOk: any;
  onCancel: any;
  title: string;
}) => {
  return (
    <Modal
      title={title}
      open={openModel}
      onOk={onOk}
      okText="Confirm"
      okButtonProps={{ style: { backgroundColor: "green" } }}
      onCancel={onCancel}
    >
      {/* <p>sdfghjkl</p> */}
    </Modal>
  );
};

export default RepairModal;
