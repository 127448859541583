import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import StockReportTable from "../../../components/tables/report/StockReportTable";
import { Divider } from "@mui/material";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { useNavigate } from "react-router-dom";
import TransferReportTable from "../../../components/tables/report/TransferReportTable";

const InventoryReport = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="text-gray-900 mt-4">
      <DetailsHeaderActionBar
        pageName="Inventory"
        goBack={goBack}
        title="Report"
      />
      <div>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          <Tab label="Stock Report" />
          <Tab label="Transfer Report" />
        </Tabs>
        <Divider sx={{ mb: 1 }} />
        {value === 0 ? <StockReportTable /> : <TransferReportTable />}
      </div>
    </div>
  );
};

export default InventoryReport;
