import HeaderComponent from "../../../components/HeaderComponent";
import { Stack, Button, Box, Skeleton } from "@mui/material";
import EmployeeGrid from "../../../components/grids/setting/EmployeeGrid";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Select } from "antd";
import React, { useState, useLayoutEffect, useEffect } from "react";
import AddNewEmployee from "../../../components/forms/NewEmployeeForm";
import SearchInput from "../../../components/buttons/SearchButton";
import { useDispatch, useSelector } from "react-redux";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { useNavigate } from "react-router-dom";
import { getAllUsersAction } from "../../../store/setting/actions";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";

const EmployeeFilter = (props: any) => {
  const { setting } = useSelector((state: any) => state);
  const [selectedFilters, setSelectedFilters] = useState(false);

  const resetFilter = () => {
    if (selectedFilters) {
      props?.setRole("");
    }
  };
  return (
    <>
      <Stack
        spacing={1}
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Button
          startIcon={
            selectedFilters ? <FilterAltIcon /> : <FilterAltOutlinedIcon />
          }
          onClick={() => {
            setSelectedFilters(!selectedFilters);
            resetFilter();
          }}
        >
          Filter
        </Button>

        {selectedFilters && (
          <Stack
            spacing={1}
            direction="row"
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              allowClear
              placeholder="Filter By Role"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").toString().includes(input)
              }
              loading={setting.isFetching}
              disabled={setting.isFetching}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")

                  .toString()
                  .toLowerCase()
                  .localeCompare(
                    (optionB?.label ?? "").toString().toLowerCase()
                  )
              }
              options={[
                { label: "Admin", value: "admin" },
                { label: "Agent", value: "sales-agent" },
                { label: "Supervisor", value: "sales-supervisor" },
                { label: "sales Manager", value: "sales-manager" },
                { label: "Finance", value: "finance-manager" },
                { label: "Inventory", value: "inventory" },
                { label: "Technician", value: "technician" },
                { label: "Dev", value: "dev" },
                { label: "Partner", value: "partner" },
              ]}
              onChange={(value: any) => {
                !value && props?.setRole("");
                value && props?.setRole(value);
              }}
            />
          </Stack>
        )}
      </Stack>
    </>
  );
};

const PageView = (props: any) => {
  const [isGridView, setIsGridView] = useState(true);
  const { auth, setting } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  const [searchKey, setSearchKey] = React.useState();
  const [role, setRole] = useState("");

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    // Changed from useEffect to useLayoutEffect
    if (role) {
      auth?.token &&
        getAllUsersAction(
          auth?.token,
          `?page=${page - 1}&limit=${limit}&role=${role}`
        )(dispatch);
    } else {
      auth?.token &&
        getAllUsersAction(
          auth?.token,
          `?page=${page - 1}&limit=${limit}`
        )(dispatch);
    }
  }, [auth?.token, dispatch, limit, page, role]);

  useEffect(() => {
    if (searchKey === "") {
      auth?.token &&
        getAllUsersAction(
          auth?.token,
          `?page=${page - 1}&limit=${limit}`
        )(dispatch);
    }
  }, [searchKey]);

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const handleSearch = () => {
    if (role && searchKey) {
      auth?.token &&
        getAllUsersAction(
          auth?.token,
          `?page=${page - 1}&limit=${limit}&sk=${searchKey}&role=${role}`
        )(dispatch);
    } else {
      auth?.token &&
        getAllUsersAction(
          auth?.token,
          `?page=${page - 1}&limit=${limit}&sk=${searchKey}`
        )(dispatch);
    }
  };

  return (
    <>
      <div className="text-black sm:flex items-center justify-between mt-7 ml-0 sm:ml-5 lg:mr-8 2xl:mr-24">
        <DetailsHeaderActionBar pageName="Users" title=" " goBack={goBack} />
        <HeaderComponent
          // title="Manage Employee"
          item="User"
          modelTitle="Add New User"
          isNotCollapse={true}
          ModelComponent={<AddNewEmployee onCancel={handleCancel} />}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleCancel={handleCancel}
          setIsGridView={setIsGridView}
        />
      </div>
      <div className="flex items-center justify-between pt-4  lg:mr-8 2xl:mr-24 ml-4">
        <EmployeeFilter setRole={setRole} />
        <SearchInput
          onSearch={handleSearch}
          onChange={(e: any) => {
            e.preventDefault();
            setSearchKey(e.target.value);
          }}
        />
      </div>
      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={setting?.all?.total}
        count={Math.ceil(setting?.all?.total / limit)}
      >
        {isGridView && (
          <Box sx={{ pt: 2 }}>
            <div className="flex flex-wrap  gap-5 justify-start items-start">
              {setting.isFetching && (
                <>
                  {[1, 2, 3].map(() => (
                    <Box sx={{ width: 250, p: 1 }}>
                      <Stack>
                        <Stack
                          direction={"row"}
                          spacing={1}
                          justifyContent="center"
                          alignItems={"center"}
                        >
                          <Skeleton
                            animation="wave"
                            variant="circular"
                            width={40}
                            height={40}
                          />

                          <Box sx={{ width: "100%" }}>
                            <Stack>
                              <Skeleton
                                animation="wave"
                                height={10}
                                width="100%"
                                style={{ marginBottom: 6 }}
                              />
                              <Skeleton
                                animation="wave"
                                height={10}
                                width="100%"
                                style={{ marginBottom: 6 }}
                              />
                            </Stack>
                          </Box>
                        </Stack>
                        <Skeleton animation="wave" height={120} width="100%" />
                      </Stack>
                    </Box>
                  ))}
                </>
              )}
              {!setting.isFetching && (
                // <div className=" h-[50vh]">
                <EmployeeGrid data={setting?.all?.data} />
                // </div>
              )}
            </div>
          </Box>
        )}
      </ScrollableFrame>
    </>
  );
};

export default PageView;
