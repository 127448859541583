import React, { useState, useEffect } from 'react';
import { AlertCircle, CheckCircle, Info, AlertTriangle, X } from 'lucide-react';

interface MessagePopupProps {
  message: string;
  type: 'error' | 'success' | 'warning' | 'info';
  duration?: number;
  onClose?: () => void;
}

const MessagePopup: React.FC<MessagePopupProps> = ({ message, type, duration = 5000, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      if (onClose) onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  if (!isVisible) return null;

  const getTypeStyles = () => {
    switch (type) {
      case 'error':
        return 'from-red-200/80 to-red-100/80 text-red-800';
      case 'success':
        return 'from-green-200/80 to-green-100/80 text-green-800';
      case 'warning':
        return 'from-yellow-200/80 to-yellow-100/80 text-yellow-800';
      case 'info':
        return 'from-blue-200/80 to-blue-100/80 text-blue-800';
      default:
        return 'from-gray-200/80 to-gray-100/80 text-gray-800';
    }
  };

  const getIcon = () => {
    const iconClass = "h-6 w-6";
    switch (type) {
      case 'error':
        return <AlertCircle className={`${iconClass} text-red-500`} />;
      case 'success':
        return <CheckCircle className={`${iconClass} text-green-500`} />;
      case 'warning':
        return <AlertTriangle className={`${iconClass} text-yellow-500`} />;
      case 'info':
        return <Info className={`${iconClass} text-blue-500`} />;
      default:
        return <AlertCircle className={`${iconClass} text-gray-500`} />;
    }
  };

  return (
    <div 
      className={`fixed z-50 top-4 right-4 max-w-sm w-full overflow-hidden rounded-2xl bg-gradient-to-b ${getTypeStyles()} shadow-lg backdrop-blur-md`}
      style={{
        animation: 'slideIn 0.5s ease-out forwards',
      }}
    >
      <div className="p-4">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            {getIcon()}
          </div>
          <div className="ml-3 flex-1">
            <p className="text-sm font-semibold">{message}</p>
          </div>
          <div className="ml-4 flex-shrink-0">
            <button
              className="rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => {
                setIsVisible(false);
                if (onClose) onClose();
              }}
            >
              <span className="sr-only">Close</span>
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 w-full h-1 bg-opacity-20" style={{
        backgroundColor: type === 'error' ? '#F87171' :
                         type === 'success' ? '#34D399' :
                         type === 'warning' ? '#FBBF24' :
                         type === 'info' ? '#60A5FA' : '#9CA3AF'
      }}>
        <div
          className="h-full transition-all duration-300 ease-out"
          style={{
            width: '100%',
            backgroundColor: type === 'error' ? '#DC2626' :
                             type === 'success' ? '#059669' :
                             type === 'warning' ? '#D97706' :
                             type === 'info' ? '#2563EB' : '#4B5563',
            animation: `shrink ${duration}ms linear forwards`
          }}
        />
      </div>
    </div>
  );
};

export default MessagePopup;

const keyframes = `
@keyframes shrink {
  from { width: 100%; }
  to { width: 0%; }
}

@keyframes slideIn {
  from { transform: translateX(100%); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}
`;

const styleElement = document.createElement('style');
styleElement.textContent = keyframes;
document.head.appendChild(styleElement);