import { useState } from "react";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { Badge, Carousel, Modal } from "antd";
import AddItem from "../../../pages/dashboard/channels/priceList/AddItems";
import { useNavigate } from "react-router";
import { getDefaultCurrencyCode } from "../../../utils/helper";

const Component = (props: any) => {
  const { channel, company } = useSelector((state: any) => state);
  const navigate = useNavigate();

  const itemPrice = props?.data?.prices
    ?.find((price: any) => price.isActive)
    ?.value?.toLocaleString();

  const [showEdit, setShowEdit] = useState(false);

  const handleCancel = () => {
    setShowEdit(false);
  };

  return (
    <>
      <div className="rounded-md relative border border-white hover:border-gray-300 duration-500">
        <Badge.Ribbon
          color="green"
          text={`${itemPrice} ${getDefaultCurrencyCode(company)}`}
        >
          <div
            className="relative cursor-pointer w-[27rem] h-52 p-2 pt-10 bg-white text-black rounded-md"
            onClick={() => {
              navigate(
                `/channel/${channel?.selected?.name}/pos/prices/${props?.data?._id}`
              );
            }}
          >
            <div className="flex w-fit">
              <div style={{ width: "5rem" }}>
                <Carousel autoplay>
                  {props?.data?.product?.images?.map((im: any) => (
                    <div>
                      <img src={im} width={"100%"} alt="" />
                    </div>
                  ))}
                </Carousel>
              </div>
              <Badge className="site-badge-count-10 text-sm p-2 h-1">
                <div className="flex flex-col pl-2 gap-1">
                  <h1 className="font-bold text-lg">
                    {props?.data?.product?.model}
                  </h1>
                  <div className=" gap-1">
                    {props?.data &&
                      props?.data?.specification?.map(
                        (data: any, _index: number) =>
                          data[1] && (
                            <Stack>
                              <p className="text-xs">
                                <b>{data[0]}:</b>
                                {` ${data[1]}`}
                              </p>
                            </Stack>
                          )
                      )}
                  </div>
                </div>
              </Badge>
            </div>
          </div>
          <div className="absolute bottom-0 right-0 xflex xjustify-end xw-full py-2">
            <div className="items-center max-w-96 flex justify-end xoverflow-hidden">
              <div className="xmax-w-full flex items-center justify-end">
                <div className="bg-[#faad14] rounded-md px-2 py-1 text-xs ">
                  {props?.data?.extendedWarranty
                    ? `+ Ext-Warranty ${props?.data?.extendedWarranty}%`
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </Badge.Ribbon>
      </div>

      <Modal
        title={"Edit Pricelist Item"}
        open={showEdit}
        onCancel={handleCancel}
        footer={null}
        className="min-w-min"
      >
        <div>
          <div className="w-[100vh]">
            {
              <AddItem
                selectedModelId={props?.data?._id}
                onCancel={handleCancel}
              />
            }
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Component;
