import { Stack, Skeleton } from "@mui/material";
import HeaderComponent from "../../../../components/HeaderChannel";
import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import AddItem from "../subscription/AddItems";
import { useDispatch, useSelector } from "react-redux";
import {
  getPricelistItemAction,
  getSubscriptionPricelistAction,
} from "../../../../store/channel/actions";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import SubProductGrid from "../../../../components/grids/subscriptionPriceList/SubProductGrid";
import { mySubScritionPriceList } from "../../../../store/subscription/priceList";
import { Radio } from "antd";
import { handleSubscriptionPlanType } from "../../../../store/layout/actions";

const SubscriptionPriceList = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
    dispatch(mySubScritionPriceList.setUpdated(null));
  };
  const { auth, channel, layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { subChannelId, priceId } = useParams();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  const [selectedBusinessProductType, setSelectedBusinessProductType] =
    useState("");

  const location = useLocation();
  const isSubsPage = () => {
    return ["/sub/"].some((path) => location.pathname.includes(path));
  };
  const isSubs = isSubsPage();

  const hasNotChannel = () => {
    return ["/subscription/devices"].some((path) =>
      location.pathname.includes(path)
    );
  };
  const isChannelNotExist = hasNotChannel();

  const handleSelectType = (value: any) => {
    setSelectedBusinessProductType(value.target.value);
    handleSubscriptionPlanType(value.target.value)(dispatch);
  };
  React.useEffect(() => {
    if (auth?.token && (channel?.selected?._id || subChannelId)) {
      getPricelistItemAction(
        auth?.token,
        `?channel=${channel?.selected?._id ?? subChannelId}`
      )(dispatch);
    }
  }, [auth?.token, channel?.selected, dispatch, page, limit, subChannelId]);

  React.useEffect(() => {
    if (
      auth?.token &&
      (channel?.selected?._id || subChannelId) &&
      props.isActive
    ) {
      getSubscriptionPricelistAction(
        auth?.token,
        `?channel=${channel?.selected?._id ?? subChannelId}&page=${
          page - 1
        }&limit=${limit}&type=${
          selectedBusinessProductType === "all"
            ? ``
            : selectedBusinessProductType
        }&product=${priceId}&paymentType=${"subscription"}`
      )(dispatch);
    }
  }, [
    auth?.token,
    channel?.selected,
    dispatch,
    page,
    limit,
    isSubs,
    isChannelNotExist,
    subChannelId,
    selectedBusinessProductType,
    layout?.subscriptionType,
    priceId,
    props.isActive,
  ]);

  return (
    <Stack spacing={1} style={{ marginTop: "25px" }}>
      <div className="text-black items-center justify-between mt-2 my-auto flex px-4">
        {/* <DetailsHeaderActionBar pageName="Plan" goBack={goBack} title=" " /> */}

        <HeaderComponent
          item="Subscription Plan"
          modelTitle="Add New Subscription Plan"
          isNotCollapse={true}
          isNotAddButton={true} //remove add button
          ModelComponent={
            ["admin", "finance", "finance-manager", "dev"]?.includes(
              auth?.user?.role?.toLowerCase()
            ) ? (
              <AddItem
                setSelectedModelId={priceId}
                onCancel={handleCancel}
                selectePlanPlanType={selectedBusinessProductType}
              />
            ) : (
              <></>
            )
          }
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleCancel={handleCancel}
          filterCard={
            <div className="space-x-2 ">
              <Radio.Group
                defaultValue={"all"}
                buttonStyle="solid"
                onChange={handleSelectType}
              >
                <Radio.Button value="all">All</Radio.Button>
                {channel?.selected?.services?.map((d: any) => d.isActive && (
                  <Radio.Button value={d?.businessProduct?.name}>{d?.businessProduct?.name}</Radio.Button>
                ))}
              </Radio.Group>
            </div>
          }
        />
      </div>
      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={channel?.subPriceList?.total}
        count={Math.ceil(channel?.subPriceList?.total / limit)}
      >
        {channel?.isFetching && (
          <div className="flex flex-wrap gap-4 justify-start items-start">
            {Array.from({ length: 15 }).map((d: any) => (
              <div className="w-80">
                <Skeleton animation="wave" />
                <Skeleton variant="rectangular" height={150} />
              </div>
            ))}
          </div>
        )}
        {!channel.isFetching && (
          <div
            className={`flex flex-wrap gap-x-2 ${
              layout.isSideNavOpen ? "gap-y-2" : "gap-y-4"
            } items-start mt-5`}
          >
            <SubProductGrid data={channel?.subPlans?.data} />
          </div>
        )}
      </ScrollableFrame>
    </Stack>
  );
};

export default SubscriptionPriceList;
