import React, { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Form, Select, Row, Col, DatePicker, DatePickerProps } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  reAssignAgentToShopAction,
} from "../../store/channel/actions";

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const App = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [userId,setUserId]= useState(props?.data)
  const [startDate, setstartDate] = useState("");
  const { channel, auth } = useSelector((state: any) => state);

  const onChangeDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setstartDate(dateString);
  };

  const onFinish = async (values: any) => {
    auth?.token &&
      (await reAssignAgentToShopAction(
        userId,
        { ...values, startDate: startDate },
        auth?.token
      )(dispatch));
    form.resetFields();
    props?.onCancel();
  };
  return (
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={{}}
      style={{ minWidth: 600 }}
      scrollToFirstError
    >
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            name="channel"
            label="Channel"
            rules={[
              {
                required: true,
                message: "Channel is required!",
                whitespace: true,
              },
            ]}
          >
            <Select>
              <Option value={channel?.selected._id}>
                {channel?.selected.name}
              </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="shop"
            label="Shop"
            rules={[
              {
                required: true,
                message: "Shop is required!",
                whitespace: true,
              },
            ]}
          >
            <Select>
              {channel?.allShop?.data?.map((el: any) => (
                <Option value={el?._id}>{el?.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="startDate"
            label="Start Date"
            tooltip="Start Date"
            rules={[{ type: "date" }]}
          >
            <DatePicker style={{ width: 210 }} onChange={onChangeDate} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item {...tailFormItemLayout}>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ minWidth: "60%" }}
              loading={channel.isFetching}
            >
              Save
            </LoadingButton>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default App;
