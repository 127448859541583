import { Table, MenuProps, Space, Dropdown, Avatar, Modal } from "antd";
import { ColumnsType } from "antd/es/table";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { Typography } from "@mui/material";
import { AiTwotoneEdit } from "react-icons/ai";
import { MdDelete, MdOutlineAssignmentInd } from "react-icons/md";
import * as grIcon from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import AssignShopForm from "../../../../components/forms/AssignShopForm";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import { useState } from "react";
import ChangePasswordModal from "../../../../components/Modals/ChangePasswordModal";
import { deleteUserActions } from "../../../../store/setting/actions";
import AssignChannelForm from "../../../../components/forms/AssignChannelForm";
import { viewUserDetails } from "../../../../store/layout/actions";
import ResetPassword from "../../../../components/forms/ResetPassword";
import DeleteModal from "../../../../components/Modals/DeleteModal";
import mtnLogo from "../../../../assets/MTN Logo.svg";
import ForceLogo from "../../../../assets/icons/dashboard/Samphone_Logo.svg";

const UsersTable = (props: any) => {
  const { setting, auth } = useSelector((state: any) => state);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPasswordModalOpen, setPasswordIsModalOpen] = useState(false);
  const [isChannelModalOpen, setIsChannelModalOpen] = useState(false);
  const [userIdData, setUserIdData] = useState<any>([]);
  const dispatch = useDispatch();
  const [data, setData] = useState<any>([]);
  const [changePassword, setChangePassword] = useState(false);
  const [userData, setUserData] = useState<any>("");
  const [userInfo, setUserInfo] = useState<any>("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState<any>("");

  const OpenChangePassword = (value: any) => {
    setChangePassword(!changePassword);
    setUserData(value);
  };

  const deleteUser = async () => {
    if (auth?.token) {
      const res = await deleteUserActions(
        userToDelete.key,
        { isDeleted: "true" },
        auth?.token
      )(dispatch);
      setOpenDeleteModal(false);
    }
  };

  const handleClickDeleteModal = (values: any) => {
    setOpenDeleteModal(true);
    setUserToDelete(values);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleViewUser = (value: any) => {
    // dispatch(myLayoutActions.setViewUser(true));
    viewUserDetails(true, value)(dispatch);
    setUserInfo(value);
  };
  const AssignShopModelOpen = (value: any) => {
    setIsModalOpen(true);
    setData(value);
  };
  const AssignChannelModelOpen = (value: any) => {
    setIsChannelModalOpen(true);
    setUserIdData(value);
  };

  const handleChannelCancel = () => {
    setIsChannelModalOpen(false);
    setUserIdData(null);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setData(null);
  };
  const handleChangePasswordModel = () => {
    setPasswordIsModalOpen(false);
  };
  const handleOpenChangePasswordModel = () => {
    setPasswordIsModalOpen(true);
  };
  const AllUsers = setting?.all?.data;
  interface DataType {
    key: React.Key;
    userName: string;
    email: string;
    telephone: string;
    profile: string;
    role: string;
    status: string;
  }
  const columns: ColumnsType<DataType> = [
    {
      key: "userName",
      title: "UserName",
      dataIndex: "userName",
      render: (text: any, record: any) => {
        return (
          <>
            <Space className="ml-2">
              <Avatar src={record?.data.picture} />
              <div className="flex flex-col">
                <h1 className="text-sm font-normal text-[#0F0F47] capitalize">
                  {text}
                </h1>
                {record?.channel && (
                  <h1 className="text-sm font-semibold text-[#0F0F47] capitalize">
                    {record?.channel}
                  </h1>
                )}
              </div>
            </Space>
          </>
        );
      },
      width: 300,
    },
    {
      key: "email",
      title: "  Email",
      dataIndex: "email",
      width: 250,
    },
    {
      key: "telephone",
      title: "Phone",
      dataIndex: "telephone",
      width: 200,
    },
    {
      key: "company",
      title: "Company",
      dataIndex: "company",
      render: (text: any) => {
        return (
          <>
            {text === "MTN" && <img src={mtnLogo} alt="MTN logo" />}
            {text === "samphone" && (
              <img src={ForceLogo} width={70} alt="Samphone logo" />
            )}
          </>
        );
      },
    },
    {
      key: "role",
      title: "Role",
      dataIndex: "role",
      render: (text,record) => <p className="capitalize">{record?.profile ?? text}</p>,
    },
    // {
    //   key: "profile",
    //   title: "Profile",
    //   dataIndex: "profile",
    //   render: (text) => <p className="capitalize">{text}</p>,
    // },
    {
      key: "status",
      title: "status",
      dataIndex: "status",
      render: (status) => {
        return (
          <>
            <button
              className={`h-[1.5rem] w-[3.5rem] rounded-md  ${
                status === "Active"
                  ? "bg-[#0FA9580D]"
                  : status === "Inactive"
                  ? "bg-[#F1595C]"
                  : "bg-[gray]"
              } `}
            >
              <Typography
                sx={{
                  fontSize: 10,
                  fontWeight: 400,
                  //   color: "#0FA958",
                }}
                className={`${
                  status === "Active" ? " text-[#0FA958]" : " text-white"
                }`}
              >
                {status}
              </Typography>
            </button>
          </>
        );
      },
    },
    {
      key: "action",
      title: "Action",
      dataIndex: "action",
      className: "disable-navigation",
      render: (_, record: any) => {
        const items: MenuProps["items"] = [
          {
            key: "1",
            label: (
              <label
                onClick={() => handleViewUser(record?.data)}
                className="flex gap-2 bg-[#0f0f4712] p-1 rounded-md cursor-pointer"
              >
                <grIcon.GrFormView color="#0F0F47" size={20} />{" "}
                <span className="text-[#0F0F47] text-[10px] font-normal pt-[5px]">
                  {" "}
                  View User Activity
                </span>
              </label>
            ),
          },
          {
            key: "2",
            label: (
              <label
                onClick={() => OpenChangePassword(record)}
                className="flex gap-2 bg-[#2943d612] p-1 rounded-md"
              >
                <AiTwotoneEdit className=" fill-[#2943d692]" size={18} />{" "}
                <span className="text-[#2943d692] text-[10px] font-normal pt-[5px]">
                  Reset Password
                </span>
              </label>
            ),
          },
          {
            key: "3",
            label: (
              <label
                onClick={(e) => handleClickDeleteModal(record)}
                className="flex gap-2 bg-red-200 rounded-md p-1"
              >
                <MdDelete className="pt-1 fill-red-500" size={20} />{" "}
                <span className="text-red-500 text-[12px] font-normal pt-[5px]">
                  Delete
                </span>
              </label>
            ),
          },
          {
            key: "4",
            label: [
              "sales agent",
              "franchisee",
              "mtn sales agent",
              "freelancer",
              "team-lead",
              "sales-agent",
            ]?.includes(record?.role?.toLowerCase()) && (
              <label
                className="flex gap-2  bg-gray-200 rounded-md p-1"
                onClick={(e: any) => AssignShopModelOpen(record)}
              >
                <MdOutlineAssignmentInd
                  className="mt-1 fill-gray-500"
                  size={20}
                />
                <span className=" text-[12px] font-normal ">
                  <div className="text-xs  bg-transparent  py-1.5  rounded-md ">
                    <button>
                      {record?.data?.reference
                        ? "Re-Assign Shop"
                        : "Assign Shop"}
                    </button>
                  </div>
                </span>
              </label>
            ),
          },
          {
            key: "4",
            label: record?.role?.toLowerCase() === "partner" && (
              <label
                className="flex gap-2  bg-gray-200 rounded-md p-1"
                onClick={(e: any) => AssignChannelModelOpen(record)}
              >
                <MdOutlineAssignmentInd
                  className="mt-1 fill-gray-500"
                  size={20}
                />
                <span className=" text-[12px] font-normal ">
                  <div className="text-xs  bg-transparent  py-1.5  rounded-md ">
                    <button>
                      {record?.channel && record?.channel?.length > 0
                        ? "Re-Assign Channel"
                        : "Assign Channel"}
                    </button>
                  </div>
                </span>
              </label>
            ),
          },
        ];
        return (
          <>
            <div className="flex gap-2">
              <Space direction="vertical">
                <Dropdown menu={{ items }} placement="bottom">
                  <BiDotsHorizontalRounded size={25} />
                </Dropdown>
              </Space>
            </div>
          </>
        );
      },
    },
  ];
  let updatedColumns = [...columns];
  if (props?.role !== "sales-agent") {
    updatedColumns = columns.filter((column) => column.key !== "company");
  }
  const Data = AllUsers?.map((el: any) => {
    return {
      key: el._id,
      userName: el?.names,
      email: el?.email,
      telephone: el?.phone,
      company: el?.tag,
      profile: el?.profile,
      role:el?.role,// ['sales-agent'].includes() && el?.profile ? el?.profile : el?.role?.split("-")?.join(" "),
      data: el,
      status: el?.isActive === true ? "Active" : "Inactive",
      channel: el?.channel && el?.channel?.at(0)?.name,
    };
  });

  const style = {
    width: "100vh",
    color: "black",
    p: 2,
  };

  const handleClose = () => {
    setChangePassword(false);
  };
  //
  return (
    <>
      <Modal
        title={`Assign Shop To ${data?.userName}`}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        className="min-w-min"
      >
        <div>
          <div style={style}>
            <AssignShopForm onCancel={handleCancel} agentId={data?.key} />
          </div>
        </div>
      </Modal>
      {changePassword && (
        <ChangePasswordModal
          handleOpenChangePasswordModel={handleOpenChangePasswordModel}
          handleChangePasswordModel={OpenChangePassword}
          content={
            <ResetPassword userData={userData?.data} onCancel={handleClose} />
          }
        />
      )}
      <ScrollableFrame
        setPage={props?.setPage}
        setLimit={props?.setLimit}
        limit={props.limit}
        total={setting?.all?.total}
        count={Math.ceil(setting?.all?.total / props?.limit)}
      >
        <Table
          columns={updatedColumns}
          dataSource={Data}
          pagination={false}
          loading={setting?.isFetching}
        />
      </ScrollableFrame>

      <Modal
        title={`Assign Channel To ${userIdData?.userName}`}
        open={isChannelModalOpen}
        onCancel={handleChannelCancel}
        footer={null}
        className="50vh"
      >
        <div>
          <div style={style}>
            <AssignChannelForm
              onCancel={handleChannelCancel}
              userId={userIdData?.key}
            />
          </div>
        </div>
      </Modal>
      <DeleteModal
        visible={openDeleteModal}
        onOk={deleteUser}
        isLoading={setting.isFetching}
        onCancel={handleCloseDeleteModal}
        itemName="user"
      />
    </>
  );
};
export default UsersTable;
