import React from "react";
import { useSelector } from "react-redux";
import CustomSkeleton from "../../../components/skeleton/CustomSkeleton";
type btnProps = {
  cardIcon?: any;
  value?: any;
  title?: any;
  percentage?: any;
};
const Card = ({ cardIcon, value, title, percentage }: btnProps) => {
  const { shop } = useSelector((state: any) => state);
  return (
    <div className="bg-white flex gap-5 w-[315px] p-6 rounded-md ml-4">
      <div className="border border-[#615bff25] rounded-full w-[2.6rem] h-[2.6rem] pl-2 pt-2 mt-2">
        <img src={cardIcon} alt="*images" />
      </div>
      <div>
        <h1 className="text-[#030229] font-semibold text-lg">
          {" "}
          {shop.isOverviewFetching ? <CustomSkeleton /> : value}
        </h1>
        <p className="text-[#030229] text-ellipsis font-thin text-base opacity-90">
          {title}
        </p>
      </div>
    </div>
  );
};

export default Card;
