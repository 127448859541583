import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { getSaleSumaryAction } from "../store/action";

export default function OverviewChart() {
  const [opacity, setOpacity] = useState({
    sales: 1,
    purchase: 1,
  });
  const { auth, ebmGeneral } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  React.useEffect(() => {
    auth?.token && getSaleSumaryAction(auth?.token, "?")(dispatch);
  }, [dispatch, auth]);

  const data = ebmGeneral?.saleStats?.data?.map((el: any) => {
    return {
      name: el?.month,
      sales: el?.sales,
      purchase: el?.purchase,
    }
  })
  return (
    <div style={{ width: '100%', height: '400px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={800
          }
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 10,
            left: 40,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="purchase"
            strokeOpacity={opacity.purchase}
            stroke="#DEDEDE"
            activeDot={{ r: 8 }}
          />
          <Line
            type="monotone"
            dataKey="sales"
            strokeOpacity={opacity.sales}
            stroke="#AFADFF"
          />
        </LineChart>
      </ResponsiveContainer >
    </div>

  );
}
