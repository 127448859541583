import React, { useRef, useEffect } from "react";

function BarcodeScanner(): JSX.Element {
  const inputRef1 = useRef<HTMLInputElement>(null);
  const inputRef2 = useRef<HTMLInputElement>(null);

  useEffect(() => {
    document.addEventListener("keydown", handleScan);
    return () => {
      document.removeEventListener("keydown", handleScan);
    };
  }, []);

  const handleScan = (event: KeyboardEvent) => {
    const value = (event.target as HTMLInputElement).value;
    if (value) {
      if (event.keyCode === 13) {
        // Enter key
        inputRef2.current?.focus();
      } else {
        inputRef1.current!.value = value;
      }
    }
  };

  return (
    <div>
      <input type="text" ref={inputRef1} />
      <input type="text" ref={inputRef2} />
    </div>
  );
}

export default BarcodeScanner;
