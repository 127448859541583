import React, { useEffect, useState } from "react";
import CompanyInformation from "./company/CompanyInformation";
import Integrations from "./Integrations";
import CreatePersonalProfile from "./createPersonalProfile";
import UserManagement from "./manage users/UserManagement";
import Channels from "./channel/Channels";
import Security from "./security/Security";
import Preferences from "./preferences/Preferences";
import Notifications from "./Notifications";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsersAction } from "../../../store/setting/actions";
import { useLocation, useNavigate } from "react-router-dom";
import { viewUserDetails } from "../../../store/layout/actions";
import SystemLogs from "./systemLogs";

const PageView = (props: any) => {
  const { pathname } = useLocation();

  const { auth, layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  const [searchKey, setSearchKey] = React.useState();
  const [role, setRole] = useState("");
  const navigate = useNavigate();

  const activeTab = layout?.settingActiveSidebarTab;

  useEffect(() => {
    if (activeTab !== "users") {
      viewUserDetails(false, null)(dispatch);
    }
  }, [activeTab]);

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
    activeTab && navigate(`#${activeTab}`);
  }, [activeTab]);

  useEffect(() => {
    if (pathname !== "/setting") {
      localStorage.removeItem("activeTab");
    }
  }, [pathname]);

  useEffect(() => {
    // Changed from useEffect to useLayoutEffect
    if (role) {
      auth?.token &&
        getAllUsersAction(
          auth?.token,
          `?page=${page - 1}&limit=${limit}&role=${role}`
        )(dispatch);
    } else {
      auth?.token &&
        getAllUsersAction(
          auth?.token,
          `?page=${page - 1}&limit=${limit}`
        )(dispatch);
    }
  }, [auth?.token, dispatch, limit, page, role]);

  useEffect(() => {
    if (searchKey === "") {
      auth?.token &&
        getAllUsersAction(
          auth?.token,
          `?page=${page - 1}&limit=${limit}`
        )(dispatch);
    }
  }, [searchKey]);

  return (
    <div className="text-black mt-8">
      <div
        className={`ml-[16rem] ${
          layout?.isSettingSidebarHovered ? "xl:ml-[22rem] " : "xl:ml-[7rem] "
        } transition-all ease-in-out duration-700`}
      >
        {activeTab === "profile" && <CreatePersonalProfile />}
        {activeTab === "company" && <CompanyInformation />}
        {activeTab === "integration" && <Integrations />}
        {activeTab === "users" && <UserManagement />}
        {activeTab === "channel" && <Channels />}
        {activeTab === "security" && <Security />}
        {activeTab === "preferences" && <Preferences />}
        {activeTab === "notification" && <Notifications />}
        {activeTab === "systemLogs" && <SystemLogs />}
      </div>
    </div>
  );
};

export default PageView;
