import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router";
import { createShopStockCartAction } from "../../../store/wareHouse/actions";
import { LoadingButton } from "@mui/lab";
import CartGridPOS from "../../../components/grids/POS/sale/CartGridPOS";
import { getAllCartsAction } from "../../../store/pos/actions";
import { getActiveShop } from "../../../utils/converter";
import { myWareHouseActions } from "../../../store/wareHouse";
import { myCustomerActions } from "../../../store/customer";

const POSSale = () => {
  const { auth, wareHouse } = useSelector((state: any) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCreateSale = async () => {
    auth?.token &&
      (await createShopStockCartAction(auth?.token, {
        shop:
          getActiveShop(auth?.user?.shop?.assigned)[0]?.shop?._id ??
          auth?.user?.shop?.shopId,
      })(dispatch));
  };

  React.useEffect(() => {
    const activeShop = getActiveShop(auth?.user?.shop?.assigned);
    // if (activeShop !== undefined && activeShop.length > 0) {
    auth?.token &&
      getAllCartsAction(
        auth?.token,
        `?shop=${
          activeShop[0]?.shop?._id ?? auth?.user?.shop?.shopId
        }&status=pending`
      )(dispatch);
    // }
  }, [
    auth?.token,
    auth?.user?.shop?.assigned,
    auth?.user?.shop?.shopId,
    dispatch,
  ]);

  React.useEffect(() => {
    if (wareHouse?.createdCart?.data?._id) {
      if (wareHouse?.isCartCreated) {
        navigate(`${wareHouse?.createdCart?.data?._id}`);
      }
    }
  }, [navigate, wareHouse?.createdCart?.data?._id, wareHouse?.isCartCreated]);

  React.useEffect(() => {
    dispatch(myWareHouseActions.setCreatedCart(null));
    dispatch(myCustomerActions.setSelected(null));
  }, [dispatch]);

  return (
    <div className="text-black">
      <DetailsHeaderActionBar pageName="POS" title="Sale" />
      <div className="flex justify-end rounded-md pl-4 pr-4 pt-2 pb-2 bg-white">
        <div>
          <LoadingButton
            variant="contained"
            startIcon={<AddCircleIcon />}
            onClick={handleCreateSale}
            sx={{ minWidth: 250 }}
            loading={wareHouse?.isFetching}
          >
            Create Sale
          </LoadingButton>
        </div>
      </div>
      <div className="flex flex-wrap flex-col gap-4 justify-start items-start py-2 rounded-lg">
        <div className="flex flex-wrap gap-2">{<CartGridPOS />}</div>
      </div>
    </div>
  );
};

export default POSSale;
