import React, { useEffect, useState } from "react";
import { Drawer, Form, Table } from "antd";
import { IoEyeOutline } from "react-icons/io5";
import { getCommissionTransactionByShopAction } from "../../store/channel/actions";
import { useDispatch, useSelector } from "react-redux";
import CommissionDetail from "../../pages/dashboard/finance/commission/commissionDetail";

const ManageCommissionDetailsTable = (props: any) => {
  const { auth, channel } = useSelector((state: any) => state);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [commissionDetails, setCommisssionDetails] = useState(false);
  const [profileData, setProfileData] = useState<any>("");
  const handleShowCommissionDetails = (data: any) => {
    setCommisssionDetails(true);
    setProfileData(data);
  };
  const onClose = () => {
    setCommisssionDetails(false);
    auth?.token &&
      getCommissionTransactionByShopAction(
        auth?.token,
        `?account=${props.data.account}`
      )(dispatch);
  };
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  useEffect(() => {
    auth?.token &&
      getCommissionTransactionByShopAction(
        auth?.token,
        `?account=${props.data.account}`
      )(dispatch);
  }, [auth?.token, dispatch]);

  const columns = [
    {
      title: "Shop",
      dataIndex: "shop",
      key: "shop"
    },
    {
      title: "Shop Type",
      dataIndex: "type",
      key: "type"
    },
    {
      title: "Transaction",
      dataIndex: "totalTransaction",
      key: "totalTransaction",
      width:100,
      render: (text: any) => (
        <p className="text-sm text-center w-full h-s10 capitalize  border border-gray-400 rounded-full p-2">
          {text}
        </p>
      )
    },
    {
      title: "Total Commission",
      dataIndex: "TotalAmount",
      key: "TotalAmount"
    },
    {
      title: "Paid Amount",
      dataIndex: "AmountPaid",
      key: "AmountPaid"
    },
    {
      title: "Balance",
      dataIndex: "owed",
      key: "owed"
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text: any, record: any) => {
        return (
          <div>
            <IoEyeOutline
              fill="green"
              size={23}
              onClick={() => handleShowCommissionDetails(record)}
              className="cursor-pointer"
            />
          </div>
        );
      }
    }
  ];
  const data = channel?.commissionTransactionByShop?.data?.map((el: any) => {
    return {
      _id: el?.shop?._id,
      key: el?.commissionProfile?._id,
      totalTransaction: el?.totalTransactions,
      TotalAmount: el?.totalCommission,
      AmountPaid: el?.paidAmount?.toLocaleString(),
      owed: el?.unPaidAmount?.toLocaleString(),
      shop: el?.shop?.name,
      type: el?.shop?.type
    };
  });

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        loading={channel?.isFetching}
      />

      <Drawer
        title={
          <h1 className="text-[#030229] text-lg font-medium text-center">
            Transaction Details
          </h1>
        }
        width={1200}
        onClose={onClose}
        open={commissionDetails}
      >
        <CommissionDetail data={profileData} />
      </Drawer>
    </>
  );
};

export default ManageCommissionDetailsTable;
