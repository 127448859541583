import react, { useState } from "react";
import { ReactComponent as SlackIcon } from "../../../assets/icons/Slack.svg";
import { ReactComponent as ConnectIcon } from "../../../assets/icons/bi_plug.svg";
import { useSelector, useDispatch } from "react-redux";
import { Card, Modal, Skeleton } from "antd";
import { updateIntegrationAction } from "../../../store/setting/integrations/actions";
import { getIntegrationAction } from "../../../store/setting/actions";
import * as aiIcon from "react-icons/ai";
import CreateIntegrationsForm from "../../../components/forms/CreateIntegration";
import Avatar from "@mui/material/Avatar";

const IntegrationCards = (props: any) => {
  const { auth, setting } = useSelector((state: any) => state);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyToUpdate, setCompanyToUpdate] = useState<any>("");

  const handleIntergrationDetails = (values: any) => {
    props?.setShowDetails(true);
    props?.setIntegrationData(values);
  };
  const showModal = (values: any) => {
    setIsModalOpen(true);
    setCompanyToUpdate(values);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleConnect = async (id: string, isConnected: boolean) => {
    auth?.token &&
      (await updateIntegrationAction(auth?.token, id, { isConnected })(
        dispatch
      ));
    auth?.token && (await getIntegrationAction(auth?.token, "?")(dispatch));
  };

  const styles = {
    custom: {
      boxShadow: "0px 2px 2px 0px rgba(15, 15, 71, 0.04)",
      border: "1px solid rgba(15, 15, 71, 0.10)",
    },
  };
  const btnStyles = {
    btnCustom: {
      boxShadow: "0px 2px 2px 0px rgba(15, 15, 71, 0.05)",
      border: "0.5px solid rgba(15, 15, 71, 0.10)",
    },
  };
  const allIntegrations = setting?.integration?.data;
  return (
    <>
      <div className="flex gap-5 flex-wrap w-full">
        {setting?.isFetching ? (
          <>
            <Card style={{ width: 400, marginTop: 16 }}>
              <Skeleton loading={loading} avatar active></Skeleton>
            </Card>
            <Card style={{ width: 400, marginTop: 16 }}>
              <Skeleton loading={loading} avatar active></Skeleton>
            </Card>
          </>
        ) : (
          allIntegrations?.map((el: any) => (
            <>
              <div
                style={styles.custom}
                className="w-[400px] relative bg-white h-[200px] rounded-[10px] cursor-pointer "
              >
                <button
                  className="bg-[#E5E5E5] h-7 rounded-[5px] px-2 flex gap-2 pt-[.1rem] justify-between absolute top-3 right-4 hover:bg-gray-100"
                  style={btnStyles.btnCustom}
                  onClick={() => {
                    showModal(el);
                  }}
                >
                  <aiIcon.AiOutlineEdit size={19} color="#0F0F4780" />
                  <span className="text-[#0F0F4780] text-[12px] pt-1 font-normal">
                    Edit
                  </span>
                </button>
                <div onClick={() => handleIntergrationDetails(el)}>
                  <div className="p-2 align-center">
                    <div className="w-[80%] h-10 rounded-full flex  gap-3">
                      {" "}
                      <Avatar
                        src={el?.logo}
                        sx={{ width: 30, height: 30 }}
                        className="mt-2"
                      />
                      <h1 className="text-[#0F0F47] font-medium text-base mt-3">
                        {el?.name}
                      </h1>
                    </div>
                  </div>
                  <div className="h-[190px]">
                    <p className="pl-6 pr-8 text-[#0F0F4780] font-normal text-sm">
                      {el?.description}{" "}
                    </p>
                  </div>
                </div>
                <div className="flex justify-between right-5 rounded-md absolute bottom-2">
                  {el?.isConnected && (
                    <button
                      className="flex gap-2 bg-[#605BFF] rounded-full pt-2 pb-1 px-3 text-[#0F0F47] font-medium text-sm "
                      style={btnStyles.btnCustom}
                      onClick={() => handleConnect(el?._id, !el?.isConnected)}
                    >
                      <ConnectIcon color="white" />{" "}
                      <span className="-pt-2 text-white">Connected</span>{" "}
                    </button>
                  )}
                  {!el?.isConnected && (
                    <button
                      className="flex gap-2 rounded-full bg-white pt-2 pb-1 px-3 text-[#0F0F47] font-medium text-sm "
                      style={btnStyles.btnCustom}
                      onClick={() => handleConnect(el?._id, !el?.isConnected)}
                    >
                      <ConnectIcon />{" "}
                      <span className="-pt-2">Disconnected</span>{" "}
                    </button>
                  )}
                </div>
              </div>
            </>
          ))
        )}
      </div>
      <Modal
        width={700}
        footer={null}
        title={"Update Integrations"}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <CreateIntegrationsForm
          companyToUpdate={companyToUpdate}
          onCancel={handleCancel}
        />
      </Modal>
    </>
  );
};
export default IntegrationCards;
