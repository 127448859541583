import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: " Payment Library",
  initialState: {
    allPaymentCategories: [],
    allPayments: [],
    isFetching: false,
    newPayment: null,
    newPaymentMethod: null,
    updateType: null,
    paymentoption: null,
  },
  reducers: {
    setAllPaymentCategories(state, action) {
      state.allPaymentCategories = action.payload;
    },
    setAllPayments(state, action) {
      state.allPayments = action.payload;
    },

    setNewPayment(state, action) {
      state.newPayment = action.payload;
    },

    setNewPaymentOption(state, action) {
      state.paymentoption = action.payload;
    },

    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setNewPaymentMethod(state, action) {
      state.isFetching = action.payload;
    },
    setUpdateType(state, action) {
      state.updateType = action.payload;
    },
  },
});

export const myPaymentActions = mySlice.actions;

export default mySlice.reducer;
