import { notification } from "antd";
import { ebmSalesActions } from ".";
import {
  addSalesService,
  getAllEbmSalesServices,
  getEbmCartItemServices,
  getEbmSale,
  getEbmTotalSalesServices,
} from "./services";

export const getEbmSalesAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(ebmSalesActions.setIsFetching(true));
      const res = await getEbmSale(token, query);
      if (res?.status === 200) {
        dispatch(ebmSalesActions.setSales(res));
        dispatch(ebmSalesActions.setIsFetching(false));
      }
      dispatch(ebmSalesActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getEbmCartItemAction = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(ebmSalesActions.setIsFetching(true));
      const res = await getEbmCartItemServices(token, id);
      if (res?.status === 200) {
        dispatch(ebmSalesActions.setEbmCartItem(res));
        dispatch(ebmSalesActions.setIsFetching(false));
      }
      dispatch(ebmSalesActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getAllEbmSalesAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(ebmSalesActions.setIsFetching(true));
      const res = await getAllEbmSalesServices(token, query);
      if (res?.status === 200) {
        dispatch(ebmSalesActions.setAllSales(res));
        dispatch(ebmSalesActions.setIsFetching(false));
      }
      dispatch(ebmSalesActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const addEbmSalesAction = (token: string, data: {}, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(ebmSalesActions.setIsFetching(true));
      const res = await addSalesService(data, token);
      if (res?.status === 201) {
        dispatch(ebmSalesActions.setNewSale(res));
        dispatch(ebmSalesActions.setIsFetching(false));
        notification.success({ message: "Sale Registered Successfully" });
      }
      dispatch(ebmSalesActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      notification.error({ message: "Failed" });
    }
  };
};
export const getEbmTotalSalesAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(ebmSalesActions.setIsFetching(true));
      const res = await getEbmTotalSalesServices(token, query);
      if (res?.status === 200) {
        dispatch(ebmSalesActions.setTotalSales(res));
        dispatch(ebmSalesActions.setIsFetching(false));
      }
      dispatch(ebmSalesActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
