import React from "react";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import { Select } from "antd";

const MetricsCard = (props: any) => {
  return (
    <div className="relative shadow w-s[400px] bg-white rounded-lg p-4 text-black space-y-3 h-48">
      <div className="flex justify-between">
        <span className="text-gray-400 text-lg font-[400]">{props?.title}</span>
        {props.type && (
          <Select
            defaultValue={props.type}
            showSearch
            style={{
              width: 110,
            }}
            // options={[
            //   { value: "Nokia", label: "Nokia" },
            //   { value: "Samsung", label: "Samsung" },
            // ]}
          />
        )}
      </div>
      <div className="flex justify-between">
        <div className="flex gap-x-2 space-x-2s">
          {props?.icon && (
            <div className="flex w-fit h-fit rounded-md">{props?.icon}</div>
          )}
          <div className="flex flex-col flex-1">
            {/* <span className="text-gray-400 text-lg font-[400]">
            {props?.title}
          </span> */}
            <span className=" text-2xl font-bold">
              {props?.number?.toLocaleString()}
            </span>
          </div>
        </div>
        {props?.percent && (
          <div
            className={`flex items-center w-fit min-w-[70px] min-h-[30px] text-lg ${
              props?.percent?.type === "increase"
                ? "text-[#0FA958]"
                : props?.percent?.type === "decrease"
                ? "text-red-500"
                : "text-blue-900"
            } rounded-xl px-2`}
          >
            {props?.percent?.type === "increase" ? (
              <ArrowUpwardOutlinedIcon className="" />
            ) : props?.percent?.type === "decrease" ? (
              <ArrowDownwardOutlinedIcon className="" />
            ) : (
              ""
            )}
            {`${props?.percent?.percentage}%`}
          </div>
        )}
      </div>
      {props?.compareDate && (
        <p className="absolute bottom-2.5 right-6 text-[#6F767E]">
          Compared to {props?.compareDate}
        </p>
      )}
    </div>
  );
};
export default MetricsCard;
