import React from "react";
import { AiOutlineEye } from "react-icons/ai";
import { Typography } from "@mui/material";

const ViewAction = (props: any) => {
  return (
    <div
      style={{ display: "flex", gap: 10, cursor: "pointer" }}
      onClick={() => props?.onOpenViewModel()}
      className=" p-2"
    >
      <AiOutlineEye size={20} color="#2943D6" spacing={5} />
      <Typography sx={{ fontSize: 14 }}>View</Typography>
    </div>
  );
};
export default ViewAction;
