import React from "react";
import { Link, useParams } from "react-router-dom";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { Select } from "antd";
import LightBlueBatton from "../../../components/buttons/LightBlueBatton";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllQuotationsAction,
  getOneQuotationItemAction,
} from "../../../store/quotation/actions";
import {
  getProformaItemAction,
  updateProformaAction,
} from "../../../store/proforma/actions";
import PurchaseOrderTable from "../../../components/tables/PurchaseOrderTable";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const PurchaseOrderDetails = () => {
  const { po_id } = useParams();
  const { auth, quotation } = useSelector((state: any) => state);
  const [selectedProduct, setSelectedProduct] = React.useState<any>("");
  const [refresh, setRefresh] = React.useState(true);
  const [isComplete, setIsComplete] = React.useState(false);
  const dispatch = useDispatch();
  const listOfProducts = quotation?.all?.data[0]?.listOfProducts;

  React.useEffect(() => {
    auth?.token &&
      getAllQuotationsAction(auth?.token, `?quotationId=${po_id}`)(dispatch);

    listOfProducts?.filter((item: any) => item?.isAddedToPO === true)?.length >
      0 && setIsComplete(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.token, quotation?.query, refresh]);

  React.useEffect(() => {
    auth?.token &&
      getOneQuotationItemAction(auth?.token, selectedProduct)(dispatch);
    auth?.token &&
      getProformaItemAction(auth?.token, selectedProduct)(dispatch);

    listOfProducts?.filter((item: any) => item?.isAddedToPO === true)?.length >
      0 && setIsComplete(true);
  }, [auth?.token, selectedProduct]);

  const CreatePOButton = () => {
    return (
      <Link to={`/purchase/po/confirm/${quotation?.all?.data[0]?._id}`}>
        <LightBlueBatton
          isLoading={false}
          name="Create P.O"
          isDisabled={
            listOfProducts?.filter((item: any) => item?.isAddedToPO === true)
              ?.length !== listOfProducts?.length
          }
        />
      </Link>
    );
  };

  const handleSetSelected = (product_id: string) => {
    setSelectedProduct(product_id);
  };

  const handleAddPOToList = async (rowData: any) => {
    auth?.token &&
      (await updateProformaAction(
        rowData?._id,
        { isSelected: true, quotId: selectedProduct },
        auth?.token
      )(dispatch));
    setRefresh(!refresh);
  };

  console.log(isComplete);

  return (
    <div className="text-[#030229] p-4 mt-3 space-y-2 h-full overflow-y-auto scrollbar-hide">
      <DetailsHeaderActionBar
        pageName="Orders"
        title={"Purchase Order | RFQ " + po_id}
        buttonsComponent={<CreatePOButton />}
      />
      <div className="p-5 bg-white rounded-lg space-y-6">
        <div className="font-medium text-[1.3rem] space-y-2">
          <p>Purchase Order</p>
          <p className="text-[#605BFF] text-lg">{po_id}</p>
        </div>
        <div className="flex gap-20 min-w-[400px]">
          <p className="w-fit font-medium text-lg">Model name</p>
          <div className="w-max">
            <div className="flex gap-5 text-gray-300">
              <Select
                style={{ width: "100%" }}
                placeholder="Choose Product Model"
                onChange={(product_id: any) => {
                  handleSetSelected(product_id);
                }}
                options={quotation?.all?.data[0]?.listOfProducts.map(
                  (item: any, _index: number) => ({
                    label:
                      `${item.isAddedToPO ? "✔️ " : ""}  ` +
                      item?.product?.model,
                    value: item?._id,
                  })
                )}
              />
              {quotation.isFetching && (
                <div className="flex h-fit ">
                  <Spin indicator={antIcon} />
                </div>
              )}
              {!quotation?.all?.data[0] && !quotation.isFetching && (
                <div className="flex items-center">
                  <DoNotDisturbIcon />
                </div>
              )}
              {!quotation.isFetching && quotation?.all?.data[0] && (
                <span>{`${listOfProducts?.filter(
                  (item: any) => item?.isAddedToPO === true
                )?.length
                  }/${listOfProducts?.length}`}</span>
              )}
            </div>
            {
              <div className="grid grid-cols-2 min-w-[288px] bg-slate-100 mt-2 rounded-md p-3">
                {quotation?.selected && (
                  <>
                    {quotation?.selected?.specification?.map(
                      (spec: any, _index: number) =>
                        spec[1] && (
                          <>
                            <span className="font-semibold">{spec[0]}</span>
                            <span>{spec[1]}</span>
                          </>
                        )
                    )}
                    <span className="font-semibold">Quantity</span>
                    <span>
                      {quotation?.selected?.quantity?.toLocaleString("en-RW")}
                    </span>
                  </>
                )}
              </div>
            }
          </div>
        </div>
        <PurchaseOrderTable handleAddPOToList={handleAddPOToList} />
      </div>
    </div>
  );
};

export default PurchaseOrderDetails;
