import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import React from "react";
import { calculatePercentageChange } from "../../utils/converter";
import { useSelector } from "react-redux";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CustomSkeleton from "../skeleton/CustomSkeleton";
import { getDefaultCurrencyCode } from "../../utils/helper";

const DashboardCommissionSalesCard: React.FC<{
  title: string;
  height?: string;
}> = ({ title, height }) => {
  const { dashboard, layout, company } = useSelector((state: any) => state);

  const todayRevenue =
    dashboard?.commissionStatistics?.data &&
    dashboard?.commissionStatistics?.data?.currentDate?.totalRevenue;
  const yesterdayRevenue =
    dashboard?.commissionStatistics?.data &&
    dashboard?.commissionStatistics?.data?.comparedDate?.totalRevenue;

  const currentTotalQty =
    dashboard?.commissionStatistics?.data &&
    dashboard?.commissionStatistics?.data?.currentDate?.totalQuantity;

  const comparedTotalQty =
    dashboard?.commissionStatistics?.data &&
    dashboard?.commissionStatistics?.data?.comparedDate?.totalQuantity;

  const percentageChange = calculatePercentageChange(
    todayRevenue ?? 0,
    yesterdayRevenue ?? 0
  );

  const totalCommision = dashboard?.franchiseeCommission?.data?.reduce(
    (sum: any, item: any) => sum + item?.commissionFee,
    0
  );
  //
  return (
    <div
      className={`relative flex flex-col ${
        height ? height : "max-h-[256px]"
      } p-4 bg-white rounded-md`}
    >
      <p className="text-gray-400 capitalize mb-2">{title}</p>
      {layout?.isFranchiseeDash ? (
        <>
          {!dashboard?.isFranchaseeFetching ? (
            <p className="text-2xl font-semibold">
              {getDefaultCurrencyCode(company) +
                (totalCommision?.toLocaleString() || 0)}
            </p>
          ) : (
            <CustomSkeleton />
          )}
        </>
      ) : (
        <>
          <p className="text-2xl font-semibold">
            {getDefaultCurrencyCode(company) +
              (todayRevenue?.toLocaleString() || 0)}{" "}
            /<span className="text-xs">{`${currentTotalQty || 0} Pieces`}</span>
          </p>
          {layout?.dashboardPreview === "day" && (
            <p className="text-gray-400 capitalize">
              {getDefaultCurrencyCode(company) +
                (yesterdayRevenue?.toLocaleString() || 0)}{" "}
              /
              <span className="text-xs">{`${
                comparedTotalQty || 0
              } Pieces`}</span>
            </p>
          )}
          {percentageChange && (
            <div
              className={`${
                layout?.dashboardPreview === "day" ? "absolute" : "hidden"
              } top-2 right-2 ${
                percentageChange.type === "increase"
                  ? "bg-green-200"
                  : percentageChange.type === "decrease"
                  ? "bg-[#FEE6E9]"
                  : "bg-blue-200"
              }  rounded-lg px-3 py-1`}
            >
              <span
                className={`${
                  percentageChange.type === "increase"
                    ? "text-green-500"
                    : percentageChange.type === "decrease"
                    ? "text-red-500"
                    : "text-blue-900"
                } flex items-center py-0.5`}
              >
                {percentageChange.type === "increase" ? (
                  <ArrowUpwardIcon />
                ) : percentageChange.type === "decrease" ? (
                  <ArrowDownwardIcon />
                ) : (
                  ""
                )}
                {percentageChange.percentage + "%"}
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DashboardCommissionSalesCard;
