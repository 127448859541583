import { Button, Select, Form, Input } from "antd";
import React, { useState } from "react";
import { sendMessageAction } from "../../../../store/vault/actions";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

// Define the possible keys for messageMapping
type MessageKey = "Payment Due In 7 Days" | "Payment Due In 3 Days" | "Payment Due In 1 Days" | "Device is restricted for non-Payment";

const SendMessage = (props: any) => {
  const { auth, vault } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [message, setMessage] = useState("");

  // Mapping between the titles and corresponding messages
  const messageMapping: Record<MessageKey, string> = {
    "Payment Due In 7 Days":
      "This is a reminder that your device is part of a financing plan. If you do not make a payment in 7 days, your handset will be restricted. Please pay your outstanding balance.",
    "Payment Due In 3 Days":
      "This is a reminder that your device is part of a financing plan. If you do not make a payment in 3 days, your handset will be restricted. Please pay your outstanding balance.",
    "Payment Due In 1 Days":
      "This is a reminder that your device is part of a financing plan. If you do not make a payment in 1 day, your handset will be restricted. Please pay your outstanding balance.",
    "Device is restricted for non-Payment":
      "You have an overdue balance on your financing plan. Your handset has been restricted for non-payment. Please use the payment button below to pay your outstanding balance.",
  };

  const handleChange = (value: MessageKey) => {
    const selectedMessage = messageMapping[value];
    setMessage(selectedMessage);
    form.setFieldsValue({
      notificationTitle: value,
      notificationContent: selectedMessage,
    });
  };

  const onSendMessage = async (values: any) => {
    console.log(">>>>>>>values", values);
    if (auth?.token) {
      await sendMessageAction(auth?.token, {
        ...values,
        deviceUid: [props?.deviceData],
        notificationType: "HEADSUP",
      })(dispatch);
    }
    props?.onClose();
    form.resetFields();
  };

  return (
    <div>
      <Form
        name="basic"
        form={form}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onSendMessage}
        autoComplete="off"
      >
        <div className="text-[#030229] pl-1 text-sm font-normal mb-2">
          Message Title
        </div>
        <Form.Item name="notificationTitle" className="w-full">
          <Select
            style={{ width: "100%" }}
            onChange={handleChange}
            placeholder="Select  Message"
            options={[
              {
                value: "Payment Due In 7 Days",
                label: "Payment Due In 7 Days",
              },
              {
                value: "Payment Due In 3 Days",
                label: "Payment Due In 3 Days",
              },
              {
                value: "Payment Due In 1 Days",
                label: "Payment Due In 1 Days",
              },
              {
                value: "Device is restricted for non-Payment",
                label: "Device is restricted for non-Payment",
              },
            ]}
          />
        </Form.Item>

        <div className="text-[#030229] pl-1 text-sm font-normal mb-2">
          Message Content
        </div>
        <Form.Item name="notificationContent" className="w-full">
          <Input.TextArea
            value={message}
            rows={4}
            disabled
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item className="w-full">
          <div className="flex gap-3 ml-[11rem] mt-6">
            <Button className="w-32 border border-red-400">Cancel</Button>
            <Button htmlType="submit" className="w-32 border border-[#605BFF]">
              {vault?.isFetching ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : (
                "Send"
              )}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
export default SendMessage;
