import React, { useState, useEffect, useMemo } from "react";
import {
  GoogleMap,
  DirectionsRenderer,
  Marker,
  Polyline,
} from "@react-google-maps/api";
import { useSelector } from "react-redux";
import { Loader } from "@googlemaps/js-api-loader";
import MakerIcon from "../makericon.png";
import MessagePopup from "../../../../components/notifications/Popup";
import { message } from "antd";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = { lat: -1.94407, lng: 30.0619 };

interface Coordinates {
  lat: number;
  lng: number;
}

interface MapSectionProps {
  trackingId: string;
  hasValidLocations: boolean;
}

export const MapSection: React.FC<MapSectionProps> = ({
  trackingId,
  hasValidLocations,
}) => {
  const { tracking } = useSelector((state: any) => state);
  const [directionsResponse, setDirectionsResponse] =
    useState<google.maps.DirectionsResult | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [busPosition, setBusPosition] = useState<Coordinates | null>(null);
  const [routePath, setRoutePath] = useState<Coordinates[]>([]);
  const [busRotation, setBusRotation] = useState<number>(0);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const apiKey = process.env?.REACT_APP_GOOGLE_MAPS_API_KEY;

  useEffect(() => {
    if (!apiKey) {
      setShowErrorMessage(true);
      return;
    }

    const loader = new Loader({
      apiKey: apiKey,
      version: "weekly",
      libraries: ["places"],
    });

    loader.load().then(() => {
      if (map) {
        handleMapLoad(map);
      }
    }).catch(() => {
      message.error("Failed to load Google Maps API.");
    });
  }, [apiKey, map]);

  const handleMapLoad = (map: google.maps.Map) => {
    setMap(map);

    if (!hasValidLocations || !tracking?.selected?.data) {
      message.error("Invalid data or locations.");
      return;
    }

    const locationFrom =
      tracking?.selected && tracking?.selected.data?.locationFrom?.coordinate;
    const locationTo =
      tracking?.selected && tracking?.selected.data?.locationTo?.coordinate;

    if (!locationFrom?.lat || !locationTo?.lat) {
      return;
    }

    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin: new google.maps.LatLng(locationFrom?.lat, locationFrom?.lng),
        destination: new google.maps.LatLng(locationTo?.lat, locationTo?.lng),
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (
        result: google.maps.DirectionsResult | null,
        status: google.maps.DirectionsStatus
      ) => {
        if (status === google.maps.DirectionsStatus.OK && result) {
          setDirectionsResponse(result);
          const path = result.routes[0].overview_path.map(
            (point: google.maps.LatLng) => ({
              lat: point.lat(),
              lng: point.lng(),
            })
          );
          setRoutePath(path);
          setBusPosition(path[path?.length - 1]);
        } else {
          message.error(`Error fetching directions: ${status}`);
        }
      }
    );
  };

  useEffect(() => {
    if (routePath.length === 0) return;

    let busAnimationTimeout: NodeJS.Timeout;
    let index = routePath.length - 1;

    const animateBus = () => {
      if (index >= 0) {
        const prevIndex = index - 1;
        if (prevIndex >= 0) {
          const angle = calculateAngle(routePath[index], routePath[prevIndex]);
          setBusRotation(angle + 360);
        }
        setBusPosition(routePath[index]);
        index--;
        busAnimationTimeout = setTimeout(animateBus, 1000);
      }
    };

    animateBus();

    return () => clearTimeout(busAnimationTimeout);
  }, [routePath]);

  const calculateAngle = (start: Coordinates, end: Coordinates) => {
    const latDiff = end.lat - start.lat;
    const lngDiff = end.lng - start.lng;
    return (Math.atan2(latDiff, lngDiff) * 180) / Math.PI;
  };

  const memoizedData = useMemo(() => {
    return routePath.length > 0 ? "Some memoized value" : "Default value";
  }, [routePath]);

  return (
    <div className="relative w-full h-full">
      {apiKey ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={13}
          onLoad={handleMapLoad}
        >
          {directionsResponse && (
            <DirectionsRenderer directions={directionsResponse} />
          )}
          {busPosition && (
            <Marker
              position={busPosition}
              icon={{
                url: MakerIcon,
                scaledSize: new google.maps.Size(50, 50),
                anchor: new google.maps.Point(25, 25),
                rotation: busRotation,
              }}
            />
          )}
          {routePath.length > 0 && <Polyline path={routePath} />}
        </GoogleMap>
      ) : (
        <MessagePopup
          message="Google Maps API key is missing. Please add it to the .env file."
          type="error"
          onClose={() => setShowErrorMessage(false)}
        />
      )}
    </div>
  );
};

export default MapSection;
