import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "ebmSales",
  initialState: {
    isFetching: false,
    allSales: null,
    newSale: null,
    sale: null,
    ebmCartItem: null,
    totalSales: null,
  },
  reducers: {
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setAllSales(state, action) {
      state.allSales = action.payload;
    },
    setSales(state, action) {
      state.sale = action.payload;
    },
    setEbmCartItem(state, action) {
      state.ebmCartItem = action.payload;
    },
    setNewSale(state, action) {
      state.newSale = action.payload;
    },
    setTotalSales(state, action) {
      state.totalSales = action.payload;
    },
  },
});

export const ebmSalesActions = mySlice.actions;

export default mySlice.reducer;
