import React from "react";
import TimeComponent from "./TimeComponent";
import { UserOutlined } from "@ant-design/icons";
import AttendanceChart from "../attendance/AttendanceChart";
import CustomAttendanceChart from "./customAttendanceChart";
import { GoPlus } from "react-icons/go";
import TwoIconsCard from "../../../EBM/components/cards/TwoIconsCard";
import { ReactComponent as CartIcons } from "../../../EBM/assets/images/icons/Group 1000002857 (1).svg";

import { FaArrowTrendUp } from "react-icons/fa6";
import PayrollSummaryTable from "../../components/payrollSummaryTable";
import EmployeePerfomanceTable from "../../components/employeePerfomanceTable";
import { Select } from "antd";

const stats = [
  {
    label: "Total Employees",
    value: 360,
    change: "+",
    text: "2 new employees added!",
    icon: <UserOutlined />,
    color: "text-green-500 bg-[#97CE71] text-xs ",
  },
  {
    label: "On Time",
    value: 30,
    change: "+",
    text: "2 new employees added!",
    icon: <UserOutlined />,
    color: "text-[#605BFF] bg-violet-300 text-xs ",
  },
  {
    label: "Absent",
    value: 112,
    change: "+",
    text: "2 new employees added!",
    icon: <UserOutlined />,
    color: "text-green-500 bg-[#97CE71] text-xs ",
  },
  {
    label: "Early Departures",
    value: 60,
    change: "+",
    text: "2 new employees added!",
    icon: <UserOutlined />,
    color: "text-green-500 bg-[#97CE71] text-xs",
  },
  {
    label: "Time-off",
    value: 100,
    change: "+",
    text: "2 new employees added!",
    icon: <UserOutlined />,
    color: "text-green-500 bg-[#97CE71] text-xs ",
  },
  {
    label: "Total Pay-Checks",
    value: 2,
    change: "+",
    text: "2 new employees added!",
    icon: <UserOutlined />,
    color: "text-green-500 bg-[#97CE71] text-xs ",
  },
  {
    label: "Opportunities",
    value: 72,
    change: "+",
    text: "2 new employees added!",
    icon: <UserOutlined />,
    color: "text-green-500 bg-[#97CE71] text-xs ",
  },
  {
    label: "Opening Jobs",
    value: 30,
    change: "+",
    text: "+3% Increase than yesterday",
    icon: <UserOutlined />,
    color: "text-green-500 bg-[#97CE71] text-xs ",
  },
];
const HROverview = (props: any) => {
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };
  return (
    <div className="p-4">
      <div className="lg:flex flex-col lg:flex-row items-stretch mb-4 gap-5">
        <div className="p-4  w-full lg:w-[300px] py-8  mt-6 h-[292px] bg-white rounded-md">
          <TimeComponent />
        </div>
        <div className="grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5 mt-6 w-full">
          {stats.map((stat, index) => (
            <TwoIconsCard
              amount={`${stat.value}`}
              title={`${stat.label}`}
              name={`${stat.text}`}
              icon={
                <FaArrowTrendUp
                  color="green"
                  style={{
                    backgroundColor: "#97CE71",
                    borderRadius: "20px",
                    fontSize: "15px",
                    padding: "2",
                  }}
                />
              }
              iconTwo={<CartIcons />}
            />
          ))}
        </div>
      </div>
      <div className="flex flex-col xl:flex-row  mt-6 gap-3">
        <div className="p-4 w-full xl:w-[60%]  h-auto rounded-md bg-white">
          <CustomAttendanceChart />
        </div>
        <div className=" w-[100%] xl:w-[40%] text-black  p-3 h-auto bg-white">
          <div className="font-poppins text-base font-normal leading-6 text-left">
            <div className="text-lg font-poppins mb-4  px-5">
              Weekly Attendance
            </div>
          </div>
          <AttendanceChart />
        </div>
      </div>
      <div className="flex flex-col xl:flex-row  mt-6 gap-3">
        <div className=" w-[100%] xl:w-[50%] text-black  p-3 h-auto bg-white mt-5">
          <div className="font-poppins text-base font-normal leading-6 text-left flex justify-between">
            <div className="text-base font-poppins mb-4  px-5 text-[#030229]">
              Payroll Summary
            </div>
            <Select
              defaultValue="last month"
              style={{ width: 120 }}
              onChange={handleChange}
              options={[
                { value: "jan", label: "Jan" },
                { value: "feb", label: "Feb" },
                { value: "mar", label: "Mar" },
                { value: "apr", label: "Apr" },
                { value: "may", label: "May" },
                { value: "jun", label: "June" },
                { value: "july", label: "July" },
                { value: "aug", label: "Aug" },
                { value: "sept", label: "Sept" },
                { value: "act", label: "Oct" },
                { value: "nov", label: "Nov" },
                { value: "dec", label: "Dec" },
              ]}
            />
          </div>
          <div className="mt-5 overflow-x-auto">

          <PayrollSummaryTable />
          </div>
        </div>
        <div className=" w-[100%] xl:w-[50%] text-black  p-3 h-auto bg-white mt-5">
          <div className="font-poppins text-base font-normal leading-6 text-left flex justify-between">
            <div className="text-base font-poppins mb-4  px-5 text-[#030229]">
              Employee Perfomance
            </div>
            <Select
              defaultValue="last month"
              style={{ width: 120 }}
              onChange={handleChange}
              options={[
                { value: "jan", label: "Jan" },
                { value: "feb", label: "Feb" },
                { value: "mar", label: "Mar" },
                { value: "apr", label: "Apr" },
                { value: "may", label: "May" },
                { value: "jun", label: "June" },
                { value: "july", label: "July" },
                { value: "aug", label: "Aug" },
                { value: "sept", label: "Sept" },
                { value: "act", label: "Oct" },
                { value: "nov", label: "Nov" },
                { value: "dec", label: "Dec" },
              ]}
            />
          </div>
          <div className="mt-5 overflow-x-auto">

          <EmployeePerfomanceTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HROverview;
