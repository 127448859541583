import { Form, Input, Select } from "antd";
import React, { useState } from "react";
import VaultButton from "../buttons/vaultButton";
import "../../pages/dashboard/vaults/vault.css";
import { addDeviceAction } from "../../store/vault/actions";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
interface formProps {
  inputName?: any;
  selectName?: any;
  placeholder?: any;
  selectPlaceholder?: any;
}
const AddDevices = ({
  inputName,
  selectName,
  placeholder,
  selectPlaceholder,
}: formProps) => {
  const [form] = Form.useForm();
  const { auth, vault } = useSelector((state: any) => state);
  const [selectedMethod, setselectedMethod] = useState("");
  const dispatch = useDispatch();
  const addDevice = async (values: any) => {
    console.log(values, "356616851823685");
    auth?.token &&
      addDeviceAction(auth?.token, {
        ...values,
        deviceUid: [values?.deviceUid],
      })(dispatch);
    // await getDeviceAction(auth?.token, "?")(dispatch);
    form.resetFields();
  };
  const handleChangeMethode = (value: string) => {
    console.log(`selected ${value}`);
    setselectedMethod(value);
  };
  return (
    <>
      <Form
        form={form}
        name="validateOnly"
        layout="vertical"
        autoComplete="off"
        style={{ maxWidth: "100%", marginTop: "20px" }}
        onFinish={addDevice}
      >
        <Form.Item
          name="deviceUid"
          label={
            <span className="text-[#030229] pl-1 text-sm font-normal">
              Enter Device IMEI
            </span>
          }
          rules={[{ required: true, message: "Please enter device Id" }]}
          className="w-full"
        >
          <Input
            className="pl-4 h-[42px] w-[100%] text-base"
            placeholder="00000000"
          />
        </Form.Item>
        <Form.Item
          name="paymentMethod"
          label={
            <span className="text-[#030229b2] pl-1 pt-1 text-sm">
              Assign to service
            </span>
          }
          rules={[{ required: true, message: "Please select payment methord" }]}
          className="w-full h-[52px] vault-select"
        >
          <Select
            showSearch
            placeholder="Inventory"
            className=" capitalize rounded-md h-[52px] w-[100%] vault-select  lg:max-2xl:w-[190px] lg:max-2xl:h-[42px] bcg-[#EFF0F6] border-none"
            onChange={handleChangeMethode}
          >
            <option value={"Inventory"}>
              {" "}
              <span>
                Inventory{" "}
                <p className="text-[#030229b2] text-[12px] font-thin">
                  Inventory &gt; Idle
                </p>{" "}
              </span>{" "}
            </option>
            <option value={"prepaid"}>
              {" "}
              <span>
                Device Financing (Prepaid){" "}
                <p className="text-[#030229b2] text-[12px] font-thin">
                  Prepaid &gt; Ready for Use
                </p>{" "}
              </span>{" "}
            </option>
            <option value={"postpaid"}>
              {" "}
              <span>
                Device Financing (PostPaid){" "}
                <p className="text-[#030229b2] text-[12px] font-thin">
                  PostPaid &gt; Ready for Use
                </p>{" "}
              </span>{" "}
            </option>
          </Select>
        </Form.Item>
        <Form.Item className="mt-10 flex justify-center ">
          <VaultButton
            btnName={
              vault?.isFetching ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : (
                "Save"
              )
            }
          />
        </Form.Item>
      </Form>
    </>
  );
};
export default AddDevices;
