import React from "react";

const BillingCard: React.FC<{ value: any; name: string }> = ({
  value,
  name,
}) => {
  return (
    <div className="bg-white w-[100%] h-[105px] border border-[#EBEFF2] px-5 py-6 rounded-md space-y-2">
      <div className="text-gray-500 font-medium">{name}</div>
      <div className="text-[#030229] font-poppins font-semibold text-14 leading-21">
        {value}
      </div>
    </div>
  );
};

export default BillingCard;
