import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Form, Space } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  updateCompanyActions,
  getOneCompanyAction,
} from "../../store/setting/company/actions";
import { CgDanger } from "react-icons/cg";
import { Button } from "@mui/material";
import { COMPANY_PROFILE_ID } from "../../utils/constants";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const DeactivateWarehouseForm = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { auth, company } = useSelector((state: any) => state);

  const companyId = COMPANY_PROFILE_ID;
  const onFinish = async () => {
    if (auth?.token && auth?.user?._id) {
      const existingWarehouses = company?.selected?.warehouse || [];
      const updatedWarehouses = existingWarehouses?.map((warehouse: any) =>
        warehouse?._id === props?.lock
          ? {
              ...warehouse,
              isActive: false,
            }
          : warehouse
      );

      await updateCompanyActions(
        companyId as string,
        { warehouse: updatedWarehouses },
        auth?.token
      )(dispatch);

      await getOneCompanyAction(companyId as string, auth.token)(dispatch);
      form.resetFields();
      props?.onCancel();
      // navigate(-1);
    }
  };

  return (
    <>
      <div className="w-4/5">
        <Form
          {...formItemLayout}
          form={form}
          name="deactivateWarehouse"
          onFinish={onFinish}
          initialValues={{}}
          style={{ minWidth: 900 }}
          scrollToFirstError
        >
          <div className="">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h1 className="font-semibold text-base  text-gray-900 mb-2 ml-[8rem] opacity-80">
                <Space>
                  <CgDanger size={26} color="red" />
                  Deactivate Warehouse
                </Space>
              </h1>
              <p className="font-normal text-sm text-gray-900 pb-4 ml-6">
                Are you sure you want to deactivate this Company Warehouse?
              </p>
              <Space className=" gap-2 ml-[12rem]">
                <LoadingButton
                  sx={{ textTransform: "none" }}
                  type="submit"
                  variant="outlined"
                  color="error"
                  loading={company?.isFetching}
                >
                  Deactivate
                </LoadingButton>
                <Button
                  sx={{ textTransform: "none" }}
                  onClick={() => props?.onCancel()}
                  variant="outlined"
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default DeactivateWarehouseForm;
