import React from "react";
import OverViewCard from "./OverViewCard";
import { HiOutlineUsers } from "react-icons/hi";
import CustomButton from "../../../../components/buttons/CustomButton";
import { FaLongArrowAltDown } from "react-icons/fa";

export const CustomerScored = () => {
  return (
    <OverViewCard
      title={"Total Customer Scored"}
      amount={500}
      icon={<HiOutlineUsers color="605BFF" size={24} />}
      comparedAmount={400}
      parcent={<p className="text-[#F1595C]">2.47%</p>}
      textColor="[#57C38A]"
      icon2={<FaLongArrowAltDown color="#F1595C" size={15} />}
      compareText={"Decreased"}
      description={"All customer assessed for device financing eligibility"}
      btn={
        <CustomButton
          btnName={<p className="text-[10px] font-normal"> View Reports</p>}
          textColor={"[#605BFF]"}
          borderColor={"[#605BFF40]"}
        />
      }
    />
  );
};

export const EligibleCustomer = () => {
  return (
    <OverViewCard
      title={"Total Eligible Customers"}
      amount={500}
      icon={<HiOutlineUsers color="605BFF" size={24} />}
      comparedAmount={400}
      parcent={<p className="text-[#F1595C]">2.47%</p>}
      textColor="[#57C38A]"
      icon2={<FaLongArrowAltDown color="#F1595C" size={15} />}
      compareText={"Decreased"}
      description={"Number of customers eligible for device financing"}
      btn={
        <CustomButton
          btnName={<p className="text-[10px] font-normal"> View Reports</p>}
          textColor={"[#605BFF]"}
          borderColor={"[#605BFF40]"}
        />
      }
    />
  );
};
export const NotEligibleCustomer = () => {
  return (
    <OverViewCard
      title={"Total Not Eligible Customers"}
      amount={500}
      icon={<HiOutlineUsers color="605BFF" size={24} />}
      comparedAmount={400}
      parcent={<p className="text-[#F1595C]">2.47%</p>}
      textColor="[#57C38A]"
      icon2={<FaLongArrowAltDown color="#F1595C" size={15} />}
      compareText={"Decreased"}
      description={"Number of customers not eligible for device financing"}
      btn={
        <CustomButton
          btnName={<p className="text-[10px] font-normal"> View Reports</p>}
          textColor={"[#605BFF]"}
          borderColor={"[#605BFF40]"}
        />
      }
    />
  );
};
