import React, { useState } from "react";
import { Button, Drawer, Space } from "antd";
import Filter from "../../../../components/filters/TransferFiltering";
import TransferProductTable from "../../../../components/tables/TransferProductTable";
import { useDispatch, useSelector } from "react-redux";
import ProductStockTable from "../../../../components/tables/ProductStockTable";
import { getStockInItemsActions } from "../../../../store/inventory/actions";

const ShopFromDrower = (props: {
  showDrawer: any;
  open: boolean;
  onClose: any;
}) => {
  const [productId, setProductId] = useState("");
  const { auth, inventory } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const dataMapper: any[] = [];
  const [formData, setformData] = React.useState(dataMapper);

  const handleResetProId = () => {
    setProductId("");
  };

  React.useEffect(() => {
    const fetchOnProduct = async () => {
      if (productId) {
        auth?.token &&
          (await getStockInItemsActions(
            auth?.token,
            `status=out&product=${productId}`
          )(dispatch));
      }
    };
    fetchOnProduct();
  }, [productId, auth?.token, dispatch]);

  const value = inventory?.stockItems?.data || false;

  React.useEffect(() => {
    value &&
      value?.forEach((el: any) => {
        dataMapper.push({
          data: el,
          key: el?._id,
        });
      });

    setformData(dataMapper);
  }, [inventory?.stockItems]);

  return (
    <>
      <Space>
        <Button
          style={{
            backgroundColor: "blue",
            color: "white",
          }}
          onClick={props?.showDrawer}
        >
          Check Serial Numbers
        </Button>
      </Space>
      <Drawer
        width={750}
        onClose={props?.onClose}
        open={props?.open}
        style={{ color: "black" }}
        extra={
          <Space align="start">
            <Filter />
          </Space>
        }
      >
        {!productId ? (
          <ProductStockTable setProductId={setProductId} />
        ) : (
          <TransferProductTable reset={handleResetProId} data={formData} />
        )}
      </Drawer>
    </>
  );
};

export default ShopFromDrower;
