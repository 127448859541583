import React, { useEffect, useState } from "react";
import SettingButton from "../../../pages/dashboard/setting/SettingButton";
import { BiPlus } from "react-icons/bi";
import { ReactComponent as ImportIcons } from "../assets/images/icons/vscode-icons_file-type-excel2.svg";
import EbmButton from "../components/EbmButton";
import { Input, DatePicker } from "antd";
import type { SearchProps } from "antd/es/input/Search";
import GenearalModal from "../components/modals/GeneralModal";
import CustomButton from "../components/buttons/CustomButton";
import GeneralDrawer from "../components/GeneralDrawer";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import TwoIconsCard from "../components/cards/TwoIconsCard";
import { ReactComponent as NewIcons } from "../assets/images/icons/box.svg";
import { ReactComponent as AmountIcons } from "../assets/images/icons/money.svg";
import type { DatePickerProps, RadioChangeEvent, SelectProps } from "antd";
import PurchaseDetails from "../components/PuchaseDetails";
import AddPurchaseForm from "../components/forms/AddPurchaseForm";
import { getDefaultCurrencyCode } from "../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { getebmopenAndclosingStockAction } from "../../../store/ebm/actions";
import OpeningClosingTable from "../components/tables/openingClosingTable";
import LoadingCard from "../components/LoadingCard";

dayjs.extend(customParseFormat);

const dateFormat = "YYYY-MM-DD";
type SelectCommonPlacement = SelectProps["placement"];
const OpeningClosingStock = () => {
  const { auth, company, ebm } = useSelector((state: any) => state);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [placement, SetPlacement] = useState<SelectCommonPlacement>("topLeft");
  const formattedDate = new Date().toISOString().slice(0, 10);
  const [selectedDate, setSelectedDate] = useState<any>(formattedDate);
  const dispatch = useDispatch();

  const placementChange = (e: RadioChangeEvent) => {
    SetPlacement(e.target.value);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };

  const { Search } = Input;

  useEffect(() => {
    if (auth?.token && selectedDate) {
      getebmopenAndclosingStockAction(
        auth?.token,
        `?date=${selectedDate}`
      )(dispatch);
    }
  }, [dispatch, auth, selectedDate]);

  const onSearch: SearchProps["onSearch"] = (value, _e, info) =>
    console.log(info?.source, value);

  const onChange: DatePickerProps["onChange"] = (date, dateString: any) => {
    setSelectedDate(dateString);
  };

  const openingClosingStockData = ebm?.openAndClosingStock?.data || [];

  const totalOpeningStock = openingClosingStockData?.reduce(
    (total: any, item: { openigStock: any }) => total + item?.openigStock,
    0
  );

  const totalClosingStock = openingClosingStockData?.reduce(
    (total: any, item: { closingStock: any }) => total + item?.closingStock,
    0
  );

  const totalCurrentStock = openingClosingStockData?.reduce(
    (total: any, item: { currentStock: any }) => total + item?.currentStock,
    0
  );

  const totalAmount = openingClosingStockData?.reduce(
    (total: number, item: { currentStock: number; unitPrice: number }) =>
      total + item?.currentStock * item?.unitPrice,
    0
  );

  return (
    <div>
      <GeneralDrawer
        openDrawer={openDrawer}
        closeDrawer={onClose}
        width={650}
        title={
          <h1 className="text-base font-medium text-[#030229]">
            Purchase Information Details
          </h1>
        }
        component={
          <div>
            <div className="flex justify-between ">
              <div>
                <div className="bg-[#F4F6FA] flex justify-center items-center gap-2 px-[.25rem] py-[.25rem] rounded-md">
                  {" "}
                  <h1 className="text-[.9rem] text-black pl-2">
                    Current status:
                  </h1>
                  <CustomButton
                    title={"Wait for Approval"}
                    textColor="white"
                    bgColor="green-500"
                    textSize="base"
                  />
                </div>
              </div>
              <EbmButton
                textColor="black"
                btnName={"Export"}
                icon={<ImportIcons />}
                btnBgColor="gray-50"
              />
            </div>
            <div className="mt-5">
              <PurchaseDetails />
            </div>
          </div>
        }
      />
      <div className="flex justify-between my-4">
        <h1 className="text-[#030229] text-lg">
          Opening/Closing Stock Management
        </h1>
        <div className="flex gap-5">
          <div className="flex gap-5">
            <h1 className="mt-2">Date</h1>
            <DatePicker
              style={{ width: '250px' }}
              onChange={onChange}
              defaultValue={dayjs(selectedDate, dateFormat)}
            />
          </div>
          <EbmButton
            btnName={"Export"}
            icon={<ImportIcons />}
            btnBgColor="white"
          />
          <SettingButton
            btnName={"New"}
            icon={<BiPlus color="white" size={20} />}
            btnBgColor="[#605BFF]"
            textColor="white"
          />
        </div>
      </div>
      <div className="grid grid-cols-4 gap-5 my-10 ">
        {ebm?.isFetching ? (
          <LoadingCard />
        ) : (
          <TwoIconsCard
            amount={totalOpeningStock.toString()}
            title={"Opening Stock"}
            iconTwo={<NewIcons />}
          />
        )}
        {ebm?.isFetching ? (
          <LoadingCard />
        ) : (
          <TwoIconsCard
            amount={totalClosingStock.toString()}
            title={"Closing Stock"}
            iconTwo={<NewIcons />}
          />
        )}

        {ebm?.isFetching ? (
          <LoadingCard />
        ) : (
          <TwoIconsCard
            amount={totalCurrentStock.toString()}
            title={"Current Stock"}
            iconTwo={<NewIcons />}
          />
        )}
        {ebm?.isFetching ? (
          <LoadingCard />
        ) : (
          <TwoIconsCard
            amount={`${getDefaultCurrencyCode(
              company
            )} ${totalAmount.toLocaleString()}`}
            title={"Total Amount"}
            iconTwo={<AmountIcons />}
          />
        )}
      </div>
      <div className="bg-white p-3 rounded-md mt-5">
        <div className="flex justify-between">
          <div></div>
          <Search
            placeholder="Search by item code or name"
            onSearch={onSearch}
            style={{ width: "250px" }}
          />
        </div>
        <div className="flex justify-between">
          <GenearalModal
            openModal={isModalOpen}
            closeModal={handleOk}
            title={
              <h1 className="text-[#030229] font-semibold text-lg text-center py-2">
                Purchase Registration
              </h1>
            }
            component={
              <div className="h-full w-full">
                <div className="mt-8 w-full">
                  <AddPurchaseForm />
                </div>
              </div>
            }
            width={1100}
          />
        </div>
        <div className="mt-5">
          <OpeningClosingTable open={showDrawer} modalOpen={showModal} />
        </div>
      </div>
    </div>
  );
};
export default OpeningClosingStock;
