import React, { useState, useEffect } from "react";
import { Table } from "antd";
// import { IconButton } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
// import BorderColorIcon from "@mui/icons-material/BorderColor";
// import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import type { ColumnsType, TableProps } from "antd/es/table";
import { useDispatch, useSelector } from "react-redux";
import { getAllItemsAction } from "../../../store/ebm/actions";
// import * as convert from "../../assets/data/productExport";

interface DataType {
  id: number;
  code: string;
  name: string;
  type: string;
  origin: string;
  unit: string;
  qty: string;
  price: number;
  sale: string;
  stock: number;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Item Code",
    dataIndex: "code",
    render: (_, record: any) => <p>{record?.data?.itemCd}</p>,
  },
  {
    title: "Item Name",
    dataIndex: "name",
    render: (_, record: any) => <p>{record?.data?.itemNm}</p>,
  },
  {
    title: "Item Type",
    dataIndex: "type",
    render: (_, record: any) => <p>{record?.data?.itemTyCd}</p>,
  },
  {
    title: "Origin",
    dataIndex: "origin",
    render: (_, record: any) => <p>{record?.data?.orgnNatCd}</p>,
  },
  {
    title: "Pack Unit",
    dataIndex: "unit",
    render: (_, record: any) => <p>{record?.data?.pkgUnitCd}</p>,
  },
  {
    title: "Qty Unit",
    dataIndex: "qty",
    render: (_, record: any) => <p>{record?.data?.qtyUnitCd}</p>,
  },
  {
    title: "Purchase Price",
    dataIndex: "price",
    render: (_, record: any) => <p>0</p>,
  },
  {
    title: "Sale Price",
    dataIndex: "sale",
    render: (_, record: any) => <p>0</p>,
  },
  {
    title: "Begining Stock",
    dataIndex: "stock",
    render: (_, record: any) => <p>0</p>,
  },
  // {
  //   title: "Action",
  //   key: "action",
  //   render: (_, record) => (
  //     <Space size="middle">
  //       <IconButton aria-label="delete" color="success">
  //         <RemoveRedEyeIcon />
  //       </IconButton>
  //       <IconButton aria-label="delete" color="secondary">
  //         <BorderColorIcon />
  //       </IconButton>
  //       <Tooltip placement="bottomLeft" title={"Delete"}>
  //         <IconButton aria-label="delete" color="error">
  //           <DeleteIcon />
  //         </IconButton>
  //       </Tooltip>
  //     </Space>
  //   ),
  // },
];

const onChange: TableProps<DataType>["onChange"] = (
  pagination,
  filters,
  sorter,
  extra
) => {
  console.log("params", pagination, filters, sorter, extra);
};

const EBMItemsTable = (props: any) => {
  const { auth, ebm } = useSelector((state: any) => state);
  const dataMapper: any[] = [];
  const [formData, setformData] = useState(dataMapper);

  const dispatch = useDispatch();
  useEffect(() => {
    if (auth?.token) {
      getAllItemsAction(auth?.token, "?")(dispatch);
    }
  }, [auth?.token, dispatch]);

  const value = ebm?.manageItem?.data;

  useEffect(() => {
    value &&
      value.forEach((el: any) => {
        dataMapper.push({
          id: el.tin,
          data: el,
        });
      });

    setformData(dataMapper);
  }, [ebm?.manageItem]);

  return (
    <Table
      columns={columns}
      dataSource={formData}
      onChange={onChange}
      loading={ebm.isFetching}
    />
  );
};

export default EBMItemsTable;
