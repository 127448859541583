import { Radio } from "antd";

const EbmSalesFilter = (props: any) => {
    return (
        <div className="flex items-center space-x-2 pt-4">
            <Radio.Group
                defaultValue=""
                buttonStyle="solid"
                onChange={(e: any) => props.setSelectedStatus(e.target.value)}
            >
                <Radio.Button
                    value=""
                    className="capitalize text-xs py-2 h-10"
                >{`ALL Transactions`}</Radio.Button>
                <Radio.Button className="capitalize text-xs py-2 h-10" value="approved">Approved</Radio.Button>
                <Radio.Button className="capitalize text-xs py-2 h-10" value="WaittingApproval">Wait for Approval</Radio.Button>
                <Radio.Button className="capitalize text-xs py-2 h-10" value="Refunded">Refunded</Radio.Button>
                <Radio.Button className="capitalize text-xs py-2 h-10" value="canceled">Canceled</Radio.Button>
            </Radio.Group>
        </div>
    );
};
export default EbmSalesFilter;
