import React, { useEffect, useState } from "react";
import DevicesSummary from "./devicesSummary";
import { ReactComponent as VectorIcon } from "../../../../assets/icons/dashboard/vaults/Vector.svg";
import { Space, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import SearchInput from "../../../../components/buttons/SearchButton";
import * as piIcons from "react-icons/pi";
import DetailsHeaderActionBar from "../../../../components/details/supplier/DetailsPage/DetailsHeaderActionBar";
import { useDispatch, useSelector } from "react-redux";
import {
  getDevicesStatusAction,
  getVaultDeviceAction,
} from "../../../../store/vault/actions";
import DeviceDetails from "./deviceDetails";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
interface DataType {
  key: string;
  imei: string;
  brand: string;
  model: string;
  lastChange: string;
  lastCheckIn: string;
  status: any;
}
const IdleDevices = () => {
  const { auth, vault } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [viewDetail, setViewDetails] = useState(false);
  const [searchKey, setSearchKey] = useState();
  const [data, setData] = useState(null);
  const [stateInfo, setStateInfo] = useState("");
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);

  useEffect(() => {
    auth?.token &&
      getVaultDeviceAction(
        auth?.token,
        `?limit=${limit}&page=${page - 1
        }&serviceDetails=INVENTORY&stateInfo=${stateInfo}`
      )(dispatch);
  }, [auth?.token, limit, page, dispatch, stateInfo]);

  useEffect(() => {
    auth?.token &&
      getDevicesStatusAction(
        auth?.token,
        `?stateInfo=${stateInfo}&serviceDetails=INVENTORY`
      )(dispatch);
  }, [auth?.token, dispatch, stateInfo]);

  const styles = {
    boxShadow: "1px 4px 24px 0px rgba(3, 2, 41, 0.06)",
  };

  const IdleDeviceData = vault?.allDevice?.data?.map((el: any, index: any) => {
    return {
      key: index,
      imei: el?.deviceUniqueId,
      brand: el?.deviceManufacturer,
      model: el.deviceModel,
      lastChange: el?.lastChanged?.slice(0, 10),
      lastCheckIn: el?.lastCheckIn?.slice(0, 10),
      status: el?.stateInfo,
      data: el,
    };
  });

  const columns: ColumnsType<DataType> = [
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "BRAND",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "MODEL",
      dataIndex: "model",
      key: "model",
    },
    {
      title: " CURRENT STATUS",
      key: "status",
      dataIndex: "status",
      render: (_, { status }) => (
        <>
          <Tag
            key={status}
            className={`py-1 w-24 text-center rounded-full ${status === "active"
                ? "text-[#0FA91E] border border-[#0FA91E80]"
                : status === "locked"
                  ? "text-[#FA3659] border border-[#FA365980]"
                  : status === "released"
                    ? "text-[#2943D6] border border-[#2943d622]"
                    : status === "ready for use"
                      ? "text-[#F19F1D] border border-[#2943d622]"
                      : "text-[#2943D6] border border-[#2943d622]"
              } `}
          >
            {status}
          </Tag>
        </>
      ),
    },
    {
      title: "LAST CHANGE",
      dataIndex: "lastChange",
      key: "lastChange",
    },
    {
      title: "LAST CHECK-IN",
      dataIndex: "lastCheckIn",
      key: "lastCheckIn",
    },
    {
      title: "ACTION",
      key: "action",
      render: (record: any) => (
        <Space>
          <piIcons.PiEyeLight
            size={20}
            className="cursor-pointer"
            onClick={() => {
              setData(record);
              setViewDetails(true);
              return {};
            }}
          />
        </Space>
      ),
    },
  ];

  const handleSearch = () => {
    if (searchKey && auth?.token) {
      getVaultDeviceAction(
        auth?.token,
        `?serviceDetails=INVENTORY&imei=${searchKey}`
      )(dispatch);
    }
  };

  return (
    <>
      {!viewDetail ? (
        <div>
          <DetailsHeaderActionBar
            pageName={"Manage devices"}
            title="Idle Devices"
          />
          <div className="w-full h-[119px] bg-white" style={styles}>
            <div className="flex gap-5 divide-x pt-3">
              <DevicesSummary
                status={"Idle"}
                value={vault?.allDevice?.data?.length}
                customeIcon={<VectorIcon />}
                border={"[#2943d622]"}
              />
            </div>
            <div className="flex justify-between my-8">
              <SearchInput
                onSearch={handleSearch}
                onChange={(e: any) => {
                  e.preventDefault();
                  setSearchKey(e.target.value);
                }}
              />
            </div>
            <div>
              <ScrollableFrame
                setPage={setPage}
                setLimit={setLimit}
                limit={limit}
                total={vault?.allDevice?.result}
                count={Math.ceil(vault?.allDevice?.result / limit)}
              >
                <Table
                  columns={columns}
                  loading={vault?.isFetching}
                  dataSource={IdleDeviceData}
                  pagination={false}
                />
              </ScrollableFrame>
            </div>
          </div>
        </div>
      ) : (
        <DeviceDetails
          viewDetail={viewDetail}
          setViewDetails={setViewDetails}
          deviceData={data}
        />
      )}
    </>
  );
};

export default IdleDevices;
