import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { getSelectedRequestSentAction } from "../../../../store/wareHouseOut/actions";
import { Stack } from "@mui/material";
import WareHouseConfirm from "./WsConfirm";
import PurchaseOrderDetailsSkeleton from "../../../../components/skeleton/PurchaseOrderDetailsSkeleton";
const WarehouseOutCreate = (props: any) => {
  const { wareHouseOut, auth, wareHouse } = useSelector((state: any) => state);
  const { rfsId } = useParams();
  const dispatch = useDispatch();
  const deliveryNote = wareHouseOut?.selectedDeliveryNote?.data;
  React.useEffect(() => {
    auth?.token &&
      rfsId &&
      getSelectedRequestSentAction(
        auth?.token,
        `?requestId=${rfsId}`
      )(dispatch);
  }, [auth?.token, dispatch, rfsId]);

  return (
    <>
      {wareHouse?.isFetching && <PurchaseOrderDetailsSkeleton />}
      {!wareHouse?.isFetching && (
        <div className="text-black p-5 mt-3 space-y-2">
          <DetailsHeaderActionBar pageName={props?.pageName} title={rfsId} />
          <div className="p-5 bg-white rounded-lg space-y-6">
            <div className="flex justify-between font-semibold text-lg space-y-2">
              <Stack
                direction="row"
                justifyContent={"space-between"}
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <div>
                  <p>Warehouse Out</p>
                  <p className="text-blue-500 text-lg">
                    {wareHouseOut?.selectedRequest?.data[0]?.requestId}
                  </p>
                </div>
                <span>{wareHouseOut?.selectedRequest?.data[0]?.status}</span>
              </Stack>
            </div>
            <div className="flex justify-between items-start text-sm  ">
              <div className="grid grid-cols-1 gap-x-10">
                <p className="font-bold capitalize">
                  Channel:{" "}
                  <span>
                    {
                      wareHouseOut?.selectedRequest?.data[0]?.shopId?.channel
                        ?.name
                    }
                  </span>
                </p>
                <p className="font-bold capitalize">
                  Shop:{" "}
                  <span>
                    {wareHouseOut?.selectedRequest?.data[0]?.shopId?.name}
                  </span>
                </p>
                <p className="font-bold">
                  {deliveryNote?.pOrderID?.supplier?.name}
                </p>
                <p>{deliveryNote?.pOrderID?.supplier?.address}</p>
                <p>{deliveryNote?.pOrderID?.supplier?.phone}</p>
                <p>{deliveryNote?.pOrderID?.supplier?.email}</p>
              </div>
              <div className="grid grid-cols-2 gap-x-6 text-sm  text-right">
                <p className="font-bold">Approved By:</p>
                <p>
                  {wareHouseOut?.selectedRequest?.data[0]?.approvedBy?.names ||
                    "N/A"}{" "}
                </p>
                <p className="font-bold">Role : </p>
                <p>
                  {wareHouseOut?.selectedRequest?.data[0]?.approvedBy?.role ||
                    "N/A"}{" "}
                </p>
              </div>
            </div>
            <WareHouseConfirm />
          </div>
        </div>
      )}
    </>
  );
};

export default WarehouseOutCreate;
