import { notification } from "antd";
import dayjs from "dayjs";

export const convertObjectToArray = (data: any) => {
  return Object.entries(data);
};

export const getActivePrice = (prices: any) => {
  return prices?.find((price: any) => price.isActive)?.value;
};

export const getTotalPrice = (list: any) => {
  return list
    ?.map(
      (item: any, _index: number) =>
        item?.payment?.amount +
        (item?.payment?.amount * item?.payment?.extendedWarranty) / 100
    )
    ?.reduce((total: any, price: any) => (total += parseInt(price)), 0);
};

export const getTotalPaidAmount = (list: any) => {
  if (list?.length > 0)
    return list
      ?.map((item: any, _index: number) => item.amount)
      ?.reduce((total: number, amount: number) => (total += amount));
};

export const getActiveShop = (shops: any) => {
  if (!shops) {
    return false;
  }
  return shops?.filter((el: any) => el?.isActive === true);
};

/**
 * Calculates the number of days between two dates.
 *
 * @param dates - An array of two ISO-formatted date strings (e.g. "2023-05-02").
 * @returns The number of days between the two dates.
 */
export const getDaysBetweenDates = (dates: [string, string]): number => {
  const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
  const startDate = new Date(dates[0]);
  const endDate = new Date(dates[1]);
  const diffDays = Math.round(
    Math.abs((startDate.getTime() - endDate.getTime()) / oneDay)
  );
  return diffDays;
};

export const getMatchingPayments = (paymentMethods: any, ids: any) => {
  const filteredPayments = paymentMethods?.filter((payment: any) =>
    ids?.includes(payment?._id)
  );
  return filteredPayments;
};
export const getPercentage = (paymentMethods: any, id: any, total: any) => {
  const amount = paymentMethods?.find((payment: any) => payment._id === id);
  if (amount?.totalPayment > 0) {
    return ((amount?.totalPayment * 100) / total)?.toFixed(1);
  }
};

export const convertArrayToObject = (data: any) =>
  data?.reduce(
    (result: any, item: any) => ({
      ...result,
      [item?.shopName || item?.name || item?.agentName || item?.hour]: parseInt(
        item?.totalPayment ||
          item?.soldItems ||
          item?.totalQuantity ||
          item?.revenue
      ),
    }),
    {}
  );

export const convertArrayToObjects = (data: any) =>
  data?.reduce(
    (result: any, item: any) => ({
      ...result,
      [item?.brand || item?.model || item?.type]: parseInt(item?.quantity),
    }),
    {}
  );

export const convertArrayToObjectNew = (data: any) =>
  data?.reduce(
    (result: any, item: any) => ({
      ...result,
      [item?.hour]: parseInt(item?.quantity || item.revenue),
    }),
    {}
  );

export const convertResultsRevenueToObject = (results: any) => {
  const resultObject: any = {};
  results?.forEach((item: any) => {
    resultObject[item.date] = item.totalRevenue;
  });
  return resultObject;
};

export const convertResultsQntyToObject = (results: any) => {
  const resultObject: any = {};
  results?.forEach((item: any) => {
    resultObject[item.date] = item.totalQuantity;
  });
  return resultObject;
};

export const sortObjectAlphabetically = (obj: any) => {
  return Object.fromEntries(
    Object?.entries(obj)?.sort(([keyA], [keyB]) => keyA?.localeCompare(keyB))
  );
};

export const calculatePercentageChange = (v1: any, v2: any) => {
  if (v1 === 0 && v2 === 0) {
    return {
      type: "none",
      percentage: "0",
    };
  }
  // const percentageChange =
  //   Math.abs(
  //     (v1 - v2) / (v1 + v2)
  //   ) * 100;

  if (v1 > v2) {
    const percentageChange = Math.abs((v1 - v2) / v1) * 100;
    return {
      type: "increase",
      percentage: percentageChange.toFixed(1),
    };
  } else if (v1 < v2) {
    const percentageChange = Math.abs((v1 - v2) / v2) * 100;
    return {
      type: "decrease",
      percentage: Math.abs(percentageChange).toFixed(1),
    };
  } else {
    return {
      type: "none",
      percentage: "0",
    };
  }
};

interface Entry {
  paymentMode: string;
  mode: string;
  revenue: any;
  quantity: any;
}

export const separatePaymentsByMode = (
  data: Entry[],
  mode: string
): Entry[] => {
  const separatedData: Entry[] = [];
  data?.forEach((entry) => {
    if (entry?.mode === mode) {
      separatedData?.push(entry);
    }
  });
  return separatedData;
};

export const separatePaymentsByModeByRange = (
  data: any[],
  mode: string
): any[] => {
  const separatedDataByRange: any[] = [];
  data?.forEach((entry) => {
    if (entry?.paymentMode === mode) {
      separatedDataByRange?.push(entry);
    }
  });
  return separatedDataByRange;
};

export const calculatePaymentTotalRevenue = (data: any, dateKey: string) => {
  let totalRevenue = 0;
  data?.forEach((entry: any) => {
    if (
      entry?.revenue &&
      entry?.revenue[dateKey] &&
      entry?.revenue[dateKey]?.revenueTotal
    ) {
      totalRevenue += entry?.revenue[dateKey]?.revenueTotal;
    }
  });
  return totalRevenue;
};

export const calculatePaymentTotalRevenueRange = (dataEntries: any) => {
  let totalRevenue = 0;
  dataEntries?.forEach((entry: any) => {
    totalRevenue += entry?.result?.allTotalRevenue;
  });
  return totalRevenue;
};

export const calculatePaymentTotalQntyRange = (dataEntries: any) => {
  let totalQnty = 0;
  dataEntries?.forEach((entry: any) => {
    totalQnty += entry?.result?.allTotalQuantity;
  });
  return totalQnty;
};

export const calculatePaymentTotalQty = (data: any, dateKey: string) => {
  let totalQty = 0;
  data?.forEach((entry: any) => {
    if (
      entry?.revenue &&
      entry?.revenue[dateKey] &&
      entry?.revenue[dateKey]?.quantityTotal
    ) {
      totalQty += entry?.revenue[dateKey]?.quantityTotal;
    }
  });
  return totalQty;
};

export const updatePriceListSpecs = (data: any, removedLabels: any) => {
  return data?.filter((item: any) => {
    return !removedLabels?.some((labelData: any) => {
      const label = labelData?.label;
      const value = labelData?.value;
      return item?.label === label && item?.value?.includes(value);
    });
  });
};

export const convertSpec = (spec: any) => {
  const convertedSpec = [];

  for (const item of spec) {
    const label = item[0];
    const value = item[1];
    convertedSpec.push({ label, value });
  }

  return convertedSpec;
};

export const generateDurationArray = () => {
  const numbers = [];
  for (let i = 1; i <= 24; i++) {
    numbers.push(i.toString());
  }
  return numbers;
};

export const toIsoDate = (date: string | string[]) => {
  const currentTime = dayjs().utcOffset(4).format("HH:mm:ss");
  return dayjs(`${date} ${currentTime}`, "DD/MM/YYYY HH:mm:ss").toISOString();
};

export const calculateTotalAmountPaid = (arr: any) => {
  let totalAmountPaid = 0;
  for (let i = 0; i < arr?.length; i++) {
    if (arr[i]?.status === "paid") {
      totalAmountPaid += arr[i]?.amountPaid;
    }
  }
  return totalAmountPaid;
};

export const getUser = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

type Option = {
  product?: {
    model?: string;
  };
  label?: string;
};

type SetFilteredOptions = React.Dispatch<React.SetStateAction<Option[] | any>>;

export const searchFuction = (
  value: string,
  data: any,
  filterFunction: (option: Option, value: string) => boolean,
  setFilteredOptions: SetFilteredOptions
) => {
  const filtered = data?.filter((option: any) => filterFunction(option, value));
  setFilteredOptions(filtered);
};

export const findAccessKeysForPermissions = (
  permissionIds: any,
  roleAndPermission: any
) => {
  const allPermissions = roleAndPermission?.all?.data;
  let allAccessKeys: any[] = [];
  permissionIds.forEach((permissionId: any) => {
    const foundPermission = allPermissions.find(
      (permission: any) => permission._id === permissionId
    );
    if (foundPermission && foundPermission.access_keys) {
      allAccessKeys = allAccessKeys.concat(foundPermission.access_keys);
    }
  });

  return allAccessKeys;
};

export const addMonthsToDate = (inputDate: Date, monthsToAdd: number) => {
  const givenDate = new Date(inputDate);
  givenDate?.setMonth(givenDate?.getMonth() + monthsToAdd);

  const year = givenDate?.getFullYear();
  const month = (givenDate?.getMonth() + 1)?.toString()?.padStart(2, "0");
  const day = givenDate?.getDate()?.toString()?.padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const downloadPdf = async (fileUrl: any, deliveryNoteId: string) => {
  try {
    const response = await fetch(fileUrl);
    if (response.ok) {
      const filename = `delivery_note_${deliveryNoteId?.slice(-4)}`;
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } else {
      notification.error({ message: "Something went wrong" });
    }
  } catch (error) {
    console.error("File download error:", error);
  }
};

export const formatDateToYYYYMMDD = (date: any) => {
  const year = date?.getFullYear();
  const month = (date?.getMonth() + 1)?.toString()?.padStart(2, "0");
  const day = date?.getDate().toString()?.padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const handleClearAllState = (dispatch: any, actions: any) => {
  actions?.forEach((action: any) => {
    dispatch(action(null));
  });
};

export const checkAndSetPageVisit = (pageKey: string, isLoading?: boolean) => {
  if (isLoading) return false;
  const visited = sessionStorage.getItem(pageKey);
  if (!visited) {
    sessionStorage.setItem(pageKey, "true");
    return false;
  }
  return true;
};

export const haveVisitedPages = (keys: string[]): boolean => {
  return keys.some((key) => sessionStorage.getItem(key) === "true");
};

export const filterByStatus = (data: any[], statuses: string[]) => {
  return data?.filter((item: any) => statuses?.includes(item?.status));
};

export const determineSearchFrom = (pathname: string) => {
  switch (true) {
    case pathname?.includes("/subscription/account"):
      return "account";
    case pathname?.includes("/subscription/order"):
      return "order";
    default:
      return null;
  }
};

export const getTotalPeriodFromStart = (
  label: string,
  months: number,
  accoutType: string
) => {
  const today = new Date();
  const currentMonth = today.getMonth();
  const startYear = today.getFullYear();
  let totalDays = 0;

  for (let i = 0; i < months; i++) {
    const month = (currentMonth + i) % 12;
    const adjustYear = startYear + Math.floor((currentMonth + i) / 12);
    totalDays += new Date(adjustYear, month + 1, 0).getDate();
  }

  if (label === "day" && accoutType !== "net") {
    return totalDays;
  } else if (label === "week" && accoutType !== "net") {
    const totalWeeks = Math.ceil(totalDays / 7);
    return totalWeeks;
  } else {
    return months;
  }
};

export const getNameByCode = (array: any, enteredValue: any) => {
  const foundObject =
    array && array.find((item: any) => item.value === enteredValue);
  return foundObject ? foundObject.label : "";
};

export const getLastPaymentStatus = (autoPayments: any) => {
  if (autoPayments?.length > 0) {
    const lastPayment = autoPayments[autoPayments?.length - 1];
    return lastPayment?.status?.toLowerCase();
  }
};

export const getSubFieldsByCategory = (data: any, category: any) => {
  if (!data || !data?.fields) return null;

  const fieldCategory = data?.fields?.find(
    (field: any) => field.category === category
  );
  return fieldCategory ? fieldCategory?.subFields : null;
};
