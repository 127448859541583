import React, { useState } from "react";
import {
  Modal,
} from "antd";

const ChangeUserPassowrd = (props: any) => {
  return (
    <>
      <Modal
        title={props.modalTittle}
        keyboard={false}
        onCancel={props.handleChangePasswordModel}
        footer={null}
        open={props.handleOpenChangePasswordModel}
      >
        {props.content}
      </Modal>
    </>
  );
};
export default ChangeUserPassowrd;
