import React from "react";
import { useSelector } from "react-redux";
import SubProductCardGrid from "./SubProductCardGrid";
import NetProductCardGrid from "./NetProductCardGrid";

const SubProductGrid = (props: any) => {
  const { product, channel } = useSelector((state: any) => state);

  return channel?.subPriceList?.data?.map((d: any, index: any) => {
    if (props.selectedPaymentType === "net") {
      return (
        <NetProductCardGrid
          data={d}
          index={index}
          handleSelected={props?.handleSelected}
          types={product?.attributes?.types}
          brands={product?.attributes?.brands}
        ></NetProductCardGrid>
      );
    } else {
      return (
        <SubProductCardGrid
          data={d}
          index={index}
          handleSelected={props?.handleSelected}
          types={product?.attributes?.types}
          brands={product?.attributes?.brands}
        ></SubProductCardGrid>
      );
    }
  });
};

export default SubProductGrid;
