import React from "react";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { Badge } from "antd";
import { Avatar } from "@mui/material";

const IntergrationDeatails = (props: any) => {
  return (
    <>
      <IoArrowBackCircleOutline
        size={24}
        className="opacity-60 cursor-pointer"
        onClick={() => props?.setShowDetails(false)}
      />
      <Badge.Ribbon
        text={<span style={{ fontSize: "12px" }}>{props?.data?.isConnected ? "connected" : "disconnected"}</span>}
        color={props?.data?.isConnected ? ('[#605BFF]') : ('red')}
        style={{ padding: ".3rem 1rem " }}
      >
        <div className="mt-8 w-full h-[25vh] text-[#0F0F47] border border-gray-200 rounded-md p-4 relative bg-white">
          <div className="flex gap-6">
            <Avatar
              alt="logo"
              src={props?.data?.logo}
              sx={{ width: 50, height: 50 }}
            />
            <div>
              <h1 className="font-medium text-lg mt-2">{props?.data?.name}</h1>

              <p className="font-normal text-sm mt-4">
                {props?.data?.description}
              </p>
            </div>
          </div>
        </div>
      </Badge.Ribbon>
      <h1 className="my-6 font-medium text-lg px-2">Users</h1>
      <div className="flex flex-wrap gap-4 mt-4">
        {props?.data?.users?.map((user: any) => (
          <div className="w-[22rem] h-[10rem]  border border-[#030229] border-opacity-10 rounded-[10px] p-4 opacity-90">
            <h1 className="font-medium text-base">{user?.name}</h1>
            <p className="font-light text-sm mt-3">{user?.description}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default IntergrationDeatails;
