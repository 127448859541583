import React from "react";
import WareHouseInGridCard from "./WareHouseInGridCard";

const App = (props: any) => {
  return props?.data?.map((d: any) => {
    return <WareHouseInGridCard data={d} />;
  });
};

export default App;
