import { notification } from "antd";
import { myInventoryActions } from ".";
import {
  getAllShopStockService,
  getProductHistoryService,
  getPurchasedItemsService,
  getRevertItem,
  getStockItemsStats,
  getStockRequestOverViewService,
  getTopSellingProductService,
  getAllStatisticsService,
  getAllStatisticsSummaryService,
  getRecentActivityService
} from "./services";




export const getAllStatisticsSummaryAction = (token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await getAllStatisticsSummaryService(token);
      if (res?.status === 200) {
        dispatch(myInventoryActions.setInventorySummary(res));
        dispatch(myInventoryActions.setIsFetching(false));
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      return null;
    }
  };
};
export const getAllStatisticsAction = (token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await getAllStatisticsService(token);
      // console.log(">>>>>>>:: shop stock>>>...", res);
      if (res?.status === 200) {
        dispatch(myInventoryActions.setInventoryStats(res));
        dispatch(myInventoryActions.setIsFetching(false));
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      return null;
    }
  };
};

export const getAllShopStockAction = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await getAllShopStockService(token, id);
      // console.log(">>>>>>>:: shop stock>>>...", res);
      if (res?.status === 200) {
        dispatch(myInventoryActions.setAllShopStock(res));
        dispatch(myInventoryActions.setIsFetching(false));
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      return null;
    }
  };
};

export const getStockInItemsActions = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await getStockItemsStats(token, query);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myInventoryActions.setStockItems(res));
        dispatch(myInventoryActions.setIsFetching(false));
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getSearchBySerialNumberActions = (
  token: string,
  query: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await getStockItemsStats(token, query);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myInventoryActions.setSearchBySn(res));
        dispatch(myInventoryActions.setIsFetching(false));
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const revertItemAction = (itemId: string, data: any, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await getRevertItem(itemId, data, token);
      // console.log("<<<<Assigned Agent>>>>", res);
      if (res?.status === 200) {
        dispatch(myInventoryActions.setSearchBySn(null));
        dispatch(myInventoryActions.setIsFetching(false));
        notification.success({ message: "Updated Succesfully" });
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getPurchasedProductActions = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await getPurchasedItemsService(token, query);
      if (res?.status === 200) {
        dispatch(myInventoryActions.setPurchase(res));
        dispatch(myInventoryActions.setIsFetching(false));
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getProductHistoryActions = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await getProductHistoryService(token, query);
      if (res?.status === 200) {
        dispatch(myInventoryActions.setProductHistory(res));
        dispatch(myInventoryActions.setIsFetching(false));
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  }
};



export const getStockRequestOverViewActions = (token: string, query: string) => {

  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await getStockRequestOverViewService(token, query);
      if (res?.status === 200) {
        dispatch(myInventoryActions.setStockrequest(res));
        dispatch(myInventoryActions.setIsFetching(false));
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getTopSellingProductActions = (token: string, query: string) => {

  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await getTopSellingProductService(token, query);
      if (res?.status === 200) {
        dispatch(myInventoryActions.setTopSelling(res));
        dispatch(myInventoryActions.setIsFetching(false));
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getRecentActivityctions = (token: string, query?: string) => {

  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await getRecentActivityService(token, query);
      if (res?.status === 200) {
        dispatch(myInventoryActions.setRecentActivity(res));
        dispatch(myInventoryActions.setIsFetching(false));
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
