import React, { useState, useEffect } from "react";
import { Paper, Stack } from "@mui/material";
import { Table } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getStockStatsAction } from "../../store/wareHouse/actions";
import "../../styles/table.css";

const ProductStockTable = (props: any) => {
  const { auth, wareHouse } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const dataMapper: any[] = [];
  const [formData, setformData] = React.useState(dataMapper);

  useEffect(() => {
    auth?.token && getStockStatsAction(auth?.token, "")(dispatch);
  }, [auth?.token, dispatch]);

  const value = wareHouse?.allStockStats?.data || false;

  useEffect(() => {
    value &&
      value?.forEach((el: any) => {
        dataMapper.push({
          data: el,
          key: el?._id,
        });
      });

    setformData(dataMapper);
  }, [wareHouse?.allStockStats]);

  const [pagination, setPagination] = useState({
    pageSize: 10,
    current: 1,
    total: formData.length,
    showSizeChanger: true,
  });

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };
  const columns = [
    {
      title: "Model",
      dataIndex: "data",
      key: "data",
      render: (text: any, record: any) => <p>{record?.data?.model}</p>,
    },
    {
      title: "Brand",
      dataIndex: "data",
      key: "data",
      render: (text: any, record: any) => <p>{record?.data?.brand}</p>,
    },
    {
      title: "Warehouse",
      dataIndex: "data",
      key: "data",
      render: (text: any, record: any) => (
        <p>
          {record?.data?.statuses?.map(
            (d: any) => d.status?.toLowerCase() === "in" && d.count
          )}
        </p>
      ),
    },
    {
      title: "Shop",
      dataIndex: "data",
      key: "data",
      render: (text: any, record: any) => (
        <p>
          {record?.data?.statuses?.map(
            (d: any) => d.status?.toLowerCase() === "out" && d.count
          )}
        </p>
      ),
    },
    {
      title: "Sold",
      dataIndex: "data",
      key: "data",
      render: (text: any, record: any) => (
        <p>
          {record?.data?.statuses?.map(
            (d: any) => d.status?.toLowerCase() === "sold" && d.count
          )}
        </p>
      ),
    },
  ];

  const rowClassName = () => "clickable-row";

  return (
    <Stack spacing={1}>
      <Paper elevation={0} style={{ marginBottom: "20px" }}>
        <Stack spacing={0}>
          <Table
            columns={columns}
            dataSource={formData}
            pagination={pagination}
            onChange={handleTableChange}
            onRow={(record) => ({
              onClick: () => props?.setProductId(record?.key),
            })}
            rowClassName={rowClassName}
            loading={wareHouse?.isFetching}
          />
        </Stack>
      </Paper>
    </Stack>
  );
};

export default ProductStockTable;
