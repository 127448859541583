import { notification } from "antd";
import { myFiles } from ".";
import { uploadFileService } from "./services";

export const uploadFileAction = (token: string, data: any, onCancel?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myFiles.setIsFetching(true));
      const res = await uploadFileService(data, token);
      if (res?.status === 200) {
        dispatch(myFiles.setNew(res));
        dispatch(myFiles.setIsFetching(false));
        notification.success({ message: "Uploaded Successfully" });
        onCancel();
      }
      dispatch(myFiles.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
