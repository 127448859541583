import React, { useEffect, useState } from "react";
import { Skeleton, Modal, Tag } from "antd";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { useDispatch, useSelector } from "react-redux";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import { AiOutlineDelete, AiOutlineEye, AiOutlineEdit } from "react-icons/ai";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import { searchValue } from "../../../../utils/setColor";
import ViewTermsConditions from "./viewTerms";
import {
  deleteOneConditionsAction,
  getAllConditionsAction,
} from "../../../../store/terms_conditons/actions";
import { Link } from "react-router-dom";
import DeleteModal from "../../../../components/Modals/DeleteModal";
import CreateTerms from "./createTerms";
import { useMediaQuery } from "@mui/material";

const IndexPage = (props: any) => {
  const { auth, termsandConditions } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [updateData, setUpdateData] = useState<any>("");
  const [deleteDataId, setDeleteDataId] = useState<any>("");
  const [limit, setLimit] = useState<any>(15);
  const [page, setPage] = useState<any>(1);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (auth?.token) {
      getAllConditionsAction(auth?.token, "?")(dispatch);
    }
  }, [auth?.token, dispatch]);

  /*create Terms & Condition Modal*/
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  /*View Terms & Condition Modal*/
  const handleViewOk = () => {
    setIsViewOpen(false);
  };
  const handleViewCancel = () => {
    setIsViewOpen(false);
  };

  /*Delete Terms & Condition Modal*/
  const openDeleteModel = (value: any) => {
    setDeleteDataId(value);
    setIsDeleteModalOpen(true);
  };
  const onCancelDeleteModel = () => {
    setDeleteDataId("");
    setIsDeleteModalOpen(false);
  };
  const deleteaccount = async () => {
    await deleteOneConditionsAction(
      auth?.token,
      {
        isDeleted: true,
      },
      deleteDataId
    )(dispatch);
    onCancelDeleteModel();
  };

  /*Update Terms & Condition Modal*/
  const openUpdateModel = (value: any) => {
    setUpdateData(value);
    setIsUpdateModalOpen(true);
  };
  const onCancelUpdateModel = () => {
    setUpdateData("");
    setIsUpdateModalOpen(false);
  };
  const smallDevice = useMediaQuery("(max-width:768px)");
  return (
    <>
      <div className="my-3 text-black">
        <DetailsHeaderActionBar
          pageName="Settings"
          title="Terms & Conditions"
        />
      </div>
      <div className=" h-full w-full bg-white text-gray-900">
        <ScrollableFrame
          setLimit={setLimit}
          setPage={setPage}
          limit={limit}
          // total={account?.all?.total}
          // count={Math.ceil(account?.all?.total / limit)}
        >
          <div className="float-right">
            <PrimaryButton name="Create" onClick={showModal} />
          </div>
          <div className="flex flex-wrap mt-10 gap-3">
            {termsandConditions?.all?.data.map((terms: any) => (
              <>
                {termsandConditions?.isFetching ? (
                  <div className="w-[380px]  sm:min-h-[175px] p-[16px] rounded-lg shadow-md border border-gray-300">
                    <Skeleton />
                  </div>
                ) : (
                  <div className=" bg-white rounded-lg mt-5 sm:mt-0 w-[330px] sm:h-[195px] p-[16px] shadow-md border border-gray-300">
                    <div className="flex justify-between ">
                      <div className="font-poppins font-semibold text-sm md:text-base text-gray-900 ">
                        {terms.title}
                      </div>
                      <div className=" right-2 pt-0 md:top-2">
                        <Tag color={searchValue("B2C")}>B2C</Tag>
                      </div>
                    </div>
                    <div className="text-[1.07rem] text-[#0F0F47] font-poppins mt-5 ">
                      Created on: 2023/09/23
                    </div>
                    <div className="flex gap-2 float-right mt-4 sm:mt-0 cursor-pointer">
                      <Link to={`${terms?._id}`}>
                        <AiOutlineEye
                          size={smallDevice ? 20 : 23}
                          color="#0F0F47"
                        />
                      </Link>
                      <AiOutlineEdit
                        size={smallDevice ? 20 : 23}
                        color="2943D6"
                        onClick={() => openUpdateModel(terms)}
                      />
                      <AiOutlineDelete
                        size={smallDevice ? 18 : 23}
                        color="FA3659"
                        onClick={() => openDeleteModel(terms?._id)}
                      />
                    </div>
                  </div>
                )}
              </>
            ))}
          </div>
        </ScrollableFrame>
      </div>
      <Modal
        title="Create Terms & Conditions"
        centered
        width={1000}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <CreateTerms onCancel={handleCancel} />
      </Modal>

      <Modal
        centered
        width={1000}
        open={isViewOpen}
        onOk={handleViewOk}
        onCancel={handleViewCancel}
        footer={null}
      >
        <ViewTermsConditions />
      </Modal>

      <Modal
        title="Update Terms & Conditions"
        centered
        width={1000}
        open={isUpdateModalOpen}
        onCancel={onCancelUpdateModel}
        footer={null}
      >
        <CreateTerms updateData={updateData} onCancel={onCancelUpdateModel} />
      </Modal>

      <DeleteModal
        visible={isDeleteModalOpen}
        onOk={deleteaccount}
        onCancel={onCancelDeleteModel}
        itemName={"Terms & Conditions"}
        isLoading={termsandConditions?.isFetching}
      />
    </>
  );
};
export default IndexPage;
