import axios from "axios";
import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const loginService = async (data: any) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${SERVER_URL}/user/login`,
      data,
    });
    return res;
  } catch (err: any) {
    return err?.response;
  }
};

export const profileService = async (token: any) => {
  return HttpRequest.get(`${SERVER_URL}/user/profile`, token);
};

export const userProfileBasicService = async (email: String) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${SERVER_URL}/user/userInfo/${email}`,
    });
    return res;
  } catch (err: any) {
    return err?.response;
  }
};
export const updatePasswordService = async (
  // query: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(`${SERVER_URL}/user/password`, data, token);
};
export const updatePinService = async (
  // query: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(`${SERVER_URL}/user/reset/pin`, data, token);
};
// export const createResetEmailService = async (data: any) => {
//   return await HttpRequest.post(`${SERVER_URL}/user/reset/password-link`, data);
// };
export const createResetPasswordLinkService = async (data: any) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${SERVER_URL}/user/reset/password-link/`,
      data,
    });
    return res;
  } catch (err: any) {
    return err?.response;
  }
};
export const createResetPasswordService = async (
  data: any,
  resetToken: string
) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${SERVER_URL}/user/reset/password/${resetToken}`,
      data,
    });
    return res;
  } catch (err: any) {
    return err?.response;
  }
};
export const getResetPasswordTokenStatus = async (query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/user/reset/check-link/${query}`);
};
