import React, { useEffect } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getNameByCode } from "../../utils/ConvertCodeToName";
import {
  getAllItemsAction,
  getEbmSelectOptionAction,
} from "../../store/action";

const ManageItemTable = (props: any) => {
  const { auth, ebmGeneral } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    auth?.token && getEbmSelectOptionAction(auth?.token, "?")(dispatch);
  }, [dispatch, auth]);

  useEffect(() => {
    if (auth?.token) {
      getAllItemsAction(auth?.token, `?`)(dispatch);
    }
  }, [auth?.token, dispatch]);

  const columns = [
    {
      title: "Item Code",
      dataIndex: "code",
    },
    {
      title: "Item Name",
      dataIndex: "name",
    },
    {
      title: "Origin",
      dataIndex: "country",
    },
    {
      title: "Item Type",
      dataIndex: "type",
      render: (text: any) => (
        <>
          <p>
            {ebmGeneral?.selectOption["Item Type"] &&
              getNameByCode(ebmGeneral?.selectOption["Item Type"], text)}
          </p>
        </>
      ),
    },
    {
      title: "Pack Unit",
      dataIndex: "park",
      render: (text: any) => (
        <>
          <p>
            {ebmGeneral?.selectOption["Packing Unit"] &&
              getNameByCode(ebmGeneral?.selectOption["Packing Unit"], text)}
          </p>
        </>
      ),
    },
    {
      title: "Qty Unit",
      dataIndex: "unit",
      render: (text: any) => (
        <>
          <p>
            {ebmGeneral?.selectOption["Quantity Unit"] &&
              getNameByCode(ebmGeneral?.selectOption["Quantity Unit"], text)}
          </p>
        </>
      ),
    },
    {
      title: "Purchase Price",
      dataIndex: "purchase",
    },
    {
      title: "Sale Price",
      dataIndex: "sale",
    },
    {
      title: "Beginning Stock",
      dataIndex: "stock",
    },
    {
      title: " ",
      dataIndex: "sale",
      render: (text: any) => <></>,
    },
  ];
  const data =
    ebmGeneral?.allItems?.data?.map((el: any, index: any) => {
      return {
        key: index,
        code: el?.itemCd,
        name: el?.itemName,
        park: el?.product?.ebm?.pkgUnitCd,
        country: el?.country,
        type: el?.itemType,
        unit: el?.product?.ebm?.qtyUnitCd,
        purchase: "y",
        sale: parseInt(el?.salePrice, 10)?.toLocaleString(),
        stock: el?.beginningStock,
      };
    }) || [];
  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        size="middle"
        loading={ebmGeneral?.isFetching}
        pagination={false}
      />
    </>
  );
};
export default ManageItemTable;
