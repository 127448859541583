import React, { useEffect, useState } from "react";
import DetailsHeaderActionBar from "../../details/supplier/DetailsPage/DetailsHeaderActionBar";
import { Carousel, Modal, Tabs, Tag } from "antd";
import {
  deletePriceListItemAction,
  getAllShopAction,
  getSinglePricelistItemsAction,
} from "../../../store/channel/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getColorByValue, searchValue } from "../../../utils/setColor";
import { MdDelete } from "react-icons/md";
import { GoPlus } from "react-icons/go";
import AssignPriceListCommissionForm from "../../forms/assignPriceListCommissionForm";
import UpdateVariantForm from "../../forms/UpdateVariantForm";
import DeleteModal from "../../Modals/DeleteModal";
import PricePopover from "../../Modals/PricePopover";
import UpdatePriceForm from "../../forms/UpdatePriceForm";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import UpdateOriginForm from "../../forms/UpdateOriginForm";
import RRA_Logo from "../../../assets/icons/RRA_Logo.svg";
import PriceListDevicesWithCommission from "../../tables/shop/priceListDevicesCommissionTable";
import EBMCountryCodeTable from "../../tables/EBM/EBMCountryCodeTable";
import { getAllEbmItemsAction } from "../../../store/product/actions";
import { getDefaultCurrencyCode } from "../../../utils/helper";
import SubscriptionPriceList from "../../../pages/dashboard/channels/subscription/SubscriptionPriceList";
import NetPlanPriceList from "../../../pages/dashboard/channels/subscription/NetPlanPriceList";
import AddItem from "../../../pages/dashboard/channels/subscription/AddItems";
import { mySubScritionPriceList } from "../../../store/subscription/priceList";
import { Button } from "@mui/material";

const PriceListDetails = (props: any) => {
  const { auth, channel, product, company } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { priceId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [priceListData, setPriceListId] = useState(null);
  const [prodDataId, setProdDataId] = useState(null);
  const [visible, setVisible] = useState(false);
  const [originOpen, setOriginOpen] = useState(false);
  const [openUpdatePopover, setOpenUpdatePopover] = useState(false);
  const [activeKey, setActiveKey] = useState("1");
  const [isPlanModalOpen, setIsPlanModalOpen] = useState(false);

  const handleTabChange = (key: any) => {
    setActiveKey(key);
  };

  const goBack = () => {
    navigate(-1);
  };

  const handlePlanModalCancel = () => {
    setIsPlanModalOpen(false);
    dispatch(mySubScritionPriceList.setUpdated(null));
  };

  const itemPrice = channel?.selectedPriceListItems?.data?.prices
    ?.find((price: any) => price.isActive)
    ?.value?.toLocaleString();
  const handleOpenUpdatePopover = (newOpen: boolean) => {
    setOpenUpdatePopover(newOpen);
  };
  const handleOpenChange = (newOpen: boolean) => {
    setOriginOpen(newOpen);
  };
  const handleClickDelete = async () => {
    setVisible(true);
  };
  const handleDeleteModalCancels = () => {
    setVisible(false);
  };

  const deleteProduct = async () => {
    auth?.token &&
      (await deletePriceListItemAction(
        auth?.token,
        channel?.selectedPriceListItems?.data?._id,
        `?channel=${channel.priceList?.data[0]?.channel?._id}`
      )(dispatch));
    navigate(`/channel/${channel?.selected?.name}/pos/prices`);
  };

  const showModal = () => {
    auth?.token &&
      channel?.selected?._id &&
      getAllShopAction(
        auth?.token,
        `channel=${channel?.selected?._id}`
      )(dispatch);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOnClickView = (value: any, e: any) => {
    e.preventDefault();
    setIsViewModalOpen(true);
    if (value) {
      setPriceListId(value);
      setProdDataId(value?.product?._id);
    }
  };

  useEffect(() => {
    if (!isViewModalOpen) {
      setPriceListId(null);
      setProdDataId(null);
    }
  }, [isViewModalOpen]);

  const handleOnCancel = () => {
    setIsViewModalOpen(false);
  };

  const showPlanModal = () => {
    setIsPlanModalOpen(true);
    //  props?.setDataToUpdate && props?.setDataToUpdate(null);
    // props.goForward && navigate(props.goForward);
  };

  const styles = {
    border: "1px solid rgba(3, 2, 41, 0.10)",
  };

  useEffect(() => {
    if (auth?.token && priceId) {
      getSinglePricelistItemsAction(auth?.token, priceId as string)(dispatch);
    }
  }, [auth?.token, priceId, dispatch]);

  useEffect(() => {
    if (auth?.token && channel?.selectedPriceListItems?.data?.product?._id)
      getAllEbmItemsAction(
        auth?.token,
        `?product=${channel?.selectedPriceListItems?.data?.product?._id}`
      )(dispatch);
  }, [
    auth?.token,
    dispatch,
    channel?.selectedPriceListItems?.data?.product?._id,
  ]);

  return (
    <div className="mt-10">
      <div className="text-black items-center justify-between mt-7 my-auto flex ml-5 mr-20">
        <DetailsHeaderActionBar
          pageName={channel?.selectedPriceListItems?.data?.product?.model}
          title={"Details &  Plans"}
          goBack={goBack}
        />
      </div>
      <div className="flex gap-10">
        <div
          className={`flex gap-5 w-[1000px]
                    }  h-full rounded-md py-9 px-8`}
          style={styles}
        >
          <div className="bg-[#E0E0E0] w-[106px] p-2 h-[120px] rounded-md">
            <Carousel autoplay>
              {channel?.selectedPriceListItems?.data?.product.images?.map(
                (img: any) => (
                  <img src={img} alt="" />
                )
              )}
            </Carousel>
          </div>
          <div className="flex justify-between  w-full">
            <div>
              <h1 className="text-[#030229] font-medium text-lg">
                {channel?.selectedPriceListItems?.data?.product?.model}
              </h1>
              <p className="mt-4">
                <span className="text-[#030229] text-base font-medium">
                  Type:
                </span>
                <span className="text-[#03022976] text-sm  pl-3">
                  <Tag
                    color={getColorByValue(
                      channel?.selectedPriceListItems?.data?.product?.type,
                      channel?.selectedPriceListItems?.data?.product?.type
                    )}
                  >
                    {channel?.selectedPriceListItems?.data?.product?.type}
                  </Tag>
                </span>
              </p>
              <p className="mt-3">
                <span className="text-[#030229] text-base font-medium">
                  Brand:
                </span>
                <span className="text-[#03022976] text-sm  pl-3">
                  <Tag
                    color={getColorByValue(
                      channel?.selectedPriceListItems?.data?.product?.brand,
                      channel?.selectedPriceListItems?.data?.product?.brand
                    )}
                  >
                    {channel?.selectedPriceListItems?.data?.product?.brand}
                  </Tag>
                </span>
              </p>
              <p className="mt-3">
                <span className="text-[#030229] text-base font-medium">
                  Specifications:
                </span>
                {channel?.selectedPriceListItems?.data?.specification?.map(
                  (d: any) => (
                    <span className="text-black">
                      <span className="font-bold text-xs pl-3">{d[0]}: </span>
                      <span className=" text-sm pl-2">{d[1]}</span>
                    </span>
                  )
                )}
              </p>
            </div>
            <div className="ml-10">
              <Tag
                color={searchValue(
                  channel?.selectedPriceListItems?.data?.isActive
                    ? "Active"
                    : "Inactive"
                )}
                className="rounded-full"
              >
                {channel?.selectedPriceListItems?.data?.isActive
                  ? "Active"
                  : "Inactive"}
              </Tag>
            </div>
          </div>
        </div>
        <div
          className={`w-[600px] h-[208px] rounded-md py-7 px-8`}
          style={styles}
        >
          <p className="">
            <span className="text-[#030229] text-base font-medium">
              Default Cash Price:
            </span>
            <span className="text-[#03022976] text-sm  pl-3">
              {itemPrice} {getDefaultCurrencyCode(company)}
            </span>
          </p>
          <p className="mt-3">
            <span className="text-[#030229] text-base font-medium">
              Extended Warranty
            </span>
            <span className="text-[#03022976] text-sm  pl-3">
              {channel?.selectedPriceListItems?.data?.extendedWarranty}%
            </span>
          </p>
          <div className="mt-10 flex gap-5">
            {["admin", "finance", "finance-manager", "dev"].includes(
              auth?.user?.role?.toLowerCase()
            ) && (
              <>
                <button
                  className="border border-[#605BFF] px-3 text-[#030229] py-1 rounded-md text-sm"
                  onClick={showModal}
                >
                  Create Commission
                </button>
                <div
                  className="flex gap-2 border border-gray-300 rounded-md justify-center items-center px-6 cursor-pointer"
                  onClick={(e: any) =>
                    handleOnClickView(channel?.selectedPriceListItems?.data, e)
                  }
                >
                  <GoPlus fill="green" size={20} />
                  <p className="text-[#030229] text-sm">Model</p>
                </div>
                <PricePopover
                  title="Update price"
                  handleOpen={handleOpenUpdatePopover}
                  open={openUpdatePopover}
                  content={
                    <UpdatePriceForm
                      handleOpenUpdatePopover={handleOpenUpdatePopover}
                      priceListItemId={
                        channel?.selectedPriceListItems?.data?._id
                      }
                      dataToUpdate={{
                        value: parseInt(itemPrice?.split(",")?.join("")),
                        extendedWarranty:
                          channel?.selectedPriceListItems?.data
                            ?.extendedWarranty,
                      }}
                    />
                  }
                  icon={<AttachMoneyIcon fontSize="small" />}
                />

                <PricePopover
                  title="Update Origin"
                  handleOpen={handleOpenChange}
                  open={originOpen}
                  content={
                    <UpdateOriginForm
                      handleOpenUpdate={handleOpenChange}
                      priceListItemId={
                        channel?.selectedPriceListItems?.data?._id
                      }
                      productId={
                        channel?.selectedPriceListItems?.data?.product?._id
                      }
                    />
                  }
                  icon={<img src={RRA_Logo} className="w-6 h-6" alt="" />}
                />
                <MdDelete
                  fill="red"
                  size={20}
                  className="mt-1 cursor-pointer"
                  onClick={handleClickDelete}
                />
              </>
            )}
          </div>
        </div>
        <Modal
          title="Assign Commission"
          footer={null}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <AssignPriceListCommissionForm
            data={channel}
            onCancel={handleCancel}
          />
        </Modal>
        {["admin", "dev"].includes(auth?.user?.role?.toLowerCase()) && (
          <Modal
            open={isViewModalOpen}
            onCancel={handleOnCancel}
            key={isViewModalOpen ? "modal-open" : "modal-closed"}
            footer={null}
            className="min-w-max"
          >
            <UpdateVariantForm
              prodDataId={prodDataId}
              priceListData={priceListData}
              handleOnCancel={handleOnCancel}
            />
          </Modal>
        )}

        {["admin", "finance", "finance-manager", "dev"].includes(
          auth?.user?.role?.toLowerCase()
        ) && (
          <>
            <DeleteModal
              visible={visible}
              onOk={deleteProduct}
              isLoading={channel.isFetching}
              onCancel={handleDeleteModalCancels}
              itemName="Product"
            />
          </>
        )}
      </div>
      <div className="flex relative justify-end">
        <Button
          variant="contained"
          onClick={showPlanModal}
          sx={{ minWidth: 150 }}
        >
          Add New Subscription Plan
        </Button>
      </div>
      <Tabs
        defaultActiveKey="1"
        onChange={handleTabChange}
        items={[
          {
            label: (
              <h1 className="text-base">{`Subscription Plans (${product?.ebmitem?.length})`}</h1>
            ),
            key: "1",
            children: <SubscriptionPriceList isActive={activeKey === "1"} />,
          },
          {
            label: (
              <h1 className="text-base">{`Net Plans (${product?.ebmitem?.length})`}</h1>
            ),
            key: "2",
            children: (
              <NetPlanPriceList
                isActive={activeKey === "2"}
                productId={channel?.selectedPriceListItems?.data?.product?._id}
              />
            ),
          },
          {
            label: <h1 className="text-base">Commission</h1>,
            key: "3",
            children: (
              <PriceListDevicesWithCommission isActive={activeKey === "3"} />
            ),
          },
          {
            label: (
              <h1 className="text-base">{`RRA Details (${product?.ebmitem?.length})`}</h1>
            ),
            key: "4",
            children: (
              <EBMCountryCodeTable
                isActive={activeKey === "4"}
                productId={channel?.selectedPriceListItems?.data?.product?._id}
              />
            ),
          },
        ]}
      />

      <Modal
        title={"Add New Subscription Plan"}
        open={isPlanModalOpen}
        onCancel={handlePlanModalCancel}
        footer={null}
        className="min-w-min !max-h-[80vh] overflow-auto"
        // icon={props?.icon || <HomeIcon />}
      >
        <div>
          <div className="w-[100vh]">
            {["admin", "finance", "finance-manager", "dev"]?.includes(
              auth?.user?.role?.toLowerCase()
            ) ? (
              <AddItem
                setSelectedModelId={priceId}
                onCancel={handlePlanModalCancel}
                selectePlanPlanType={activeKey === "1" ? "subscription" : "net"}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PriceListDetails;
