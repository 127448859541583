import React from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getPricelistItemByProductAction } from "../../store/product/actions";
import CsvDownloadButton from "../buttons/CsvDownloadButton";
import { ProductInfoPricesHeaders } from "../csvHeaders/Headers";

interface DataType {
  [x: string]: any;
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const ProductDetailsTable = (props: any) => {
  const { auth, product } = useSelector((state: any) => state);
  const dataMapper: any[] = [];
  const { productId } = useParams();
  const [formData, setformData] = React.useState(dataMapper);
  const dispatch = useDispatch();

  React.useEffect(() => {
    auth?.token &&
      getPricelistItemByProductAction(
        auth?.token,
        `?product=${productId}`
      )(dispatch);
  }, [auth?.token, dispatch, productId]);

  const columns: ColumnsType<DataType> = [
    {
      title: "Channel",
      dataIndex: "channel",
      key: "channel",
    },

    {
      title: "Specifications",
      dataIndex: "specification",
      key: "specification",
    },

    {
      title: "Prices (Rwf)",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Extended Warranty",
      dataIndex: "extendedWarranty",
      key: "extendedWarranty",
      render: (text: any, _record: any) => <p>{`${text}%`}</p>,
    },
  ];

  const value = product?.selectedProduct?.data;
  React.useEffect(() => {
    value &&
      value.forEach((el: any) => {
        dataMapper.push({
          channel: el?.channel?.name,
          price: el?.prices
            ?.filter((d: any) => d.isActive === true)
            ?.map((d: any) => d.value)
            .toLocaleString(),
          extendedWarranty: el?.extendedWarranty,
          specification: `${el?.specification
            ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
            ?.join(", ")}`,
          data: el,
        });
      });

    setformData(dataMapper);
  }, [product?.selectedProduct]);

  const csvData = formData?.map((record: any) => ({
    channel: record?.channel,
    type: record?.data?.product?.type,
    brand: record?.data?.product?.brand,
    model: record?.data?.product?.model,
    specification: record?.specification,
    prices: record?.price,
    exWarranty: record?.extendedWarranty + "%",
  }));

  return (
    <>
      <div className="absolute right-2 -top-14">
        <CsvDownloadButton
          csvHeaders={ProductInfoPricesHeaders}
          csvData={csvData}
          filename={`${product?.selected?.model} Prices.csv`}
        />
      </div>
      <Table
        columns={columns}
        dataSource={formData}
        loading={product?.isFetching}
      />
    </>
  );
};

export default ProductDetailsTable;
