import React from "react";
import { Stack, Button, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { InputNumber, notification, TableColumnsType } from "antd";
import { ColumnDef } from "@tanstack/react-table";
import type { ColumnsType } from "antd/es/table";
import {
  deleteStockRequestItemAction,
  quantityApprovedAction,
} from "../../store/shop/actions";
import DeleteModal from "../Modals/DeleteModal";
import {
  deleteSelectedQuotationItemAction,
  updateSelectedQuotationItemAction,
} from "../../store/quotation/actions";
import { getActivePrice } from "../../utils/converter";

interface DataType {
  data?: any;
  key: any;
  type: any;
  brand: any;
  model: any;
  auth: any;
  qtyApproved: any;
  specification: any;
  quantity: any;
  dispatch: any;
  status: any;
  visible: boolean;
  setVisible: (state: boolean) => void;
  token: string;
  isLoading: boolean;
  rowToDelete: string;
  setRowToDelete: (state: string) => void;
}

export const quotationColumns: ColumnsType<any> = [
  {
    dataIndex: "type",
    title: "Type",
    key: "type",
    onHeaderCell: () => ({
      style: { backgroundColor: "#F5F6FA" },
    }),
  },
  {
    dataIndex: "brand",
    title: "Brand",
    key: "brand",
    onHeaderCell: () => ({
      style: { backgroundColor: "#F5F6FA" },
    }),
  },
  {
    dataIndex: "model",
    title: "Models",
    key: "model",
    onHeaderCell: () => ({
      style: { backgroundColor: "#F5F6FA" },
    }),
  },
  {
    dataIndex: "specification",
    title: "Description",
    key: "specification",
    onHeaderCell: () => ({
      style: { backgroundColor: "#F5F6FA" },
    }),
  },
  {
    dataIndex: "quantity",
    title: "Quantity",
    key: "quantity",
    onHeaderCell: () => ({
      style: { backgroundColor: "#F5F6FA" },
    }),
    render: (text, record) => {
      return (
        <InputNumber
          min={0}
          disabled={!["draft", "review"].includes(record.status?.toLowerCase())}
          defaultValue={parseInt(text)}
          onChange={(value: any) => {
            record.quantity = value;
          }}
          onBlur={async () => {
            console.log("...................._>", record);
            await updateSelectedQuotationItemAction(
              record.token,
              record.key,
              {
                quantity: record.quantity,
              },
              `?quotationId=${record.quotId}`
            )(record.dispatch);
          }}
        />
      );
    },
  },
  {
    title: "Actions",
    onHeaderCell: () => ({
      style: { backgroundColor: "#F5F6FA" },
    }),
    render: (text, record) => {
      const deleteModal = () => {
        record.setVisible(true);
      };
      const handleCancel = () => {
        record.setVisible(false);
      };
      const deleteRequest = async () => {
        await deleteSelectedQuotationItemAction(
          record.token,
          record.rowToDelete,
          `?quotationId=${record.quotId}`
        )(record.dispatch);
        record.setVisible(false);
      };
      if (!["draft", "review"].includes(record.status?.toLowerCase()))
        return null;
      return (
        <>
          <IconButton aria-label="delete" size="small">
            <DeleteIcon
              fontSize="inherit"
              onClick={() => {
                console.log("#1", record);
                record.setRowToDelete(record.key);
                deleteModal();
              }}
            />
          </IconButton>
          <DeleteModal
            visible={record.visible}
            onOk={deleteRequest}
            isLoading={record.isLoading}
            onCancel={handleCancel}
            itemName="Stock Item"
          />
        </>
      );
    },
  },
];

export const reqStockColumns: TableColumnsType<DataType> = [
  {
    dataIndex: "type",
    title: "Type",
    key: "type",
  },
  {
    dataIndex: "brand",
    title: "Brand",
    key: "brand",
  },
  {
    dataIndex: "model",
    title: "Models",
    key: "model",
  },
  {
    dataIndex: "specification",
    title: "Description",
    key: "specification",
  },
  {
    dataIndex: "specification",
    title: "Price/Product",
    key: "price",
    render: (text, record) => (
      <b>{getActivePrice(record?.data?.product?.prices)?.toLocaleString()}</b>
    ),
  },
  {
    dataIndex: "quantity",
    title: "Quantity",
    key: "quantity",
    render: (text, record) => {
      return (
        <InputNumber
          min={0}
          disabled={["review", "done", "approved"].includes(record.status)}
          defaultValue={parseInt(text)}
          onChange={(value: any) => {
            record.quantity = value;
          }}
          onBlur={async () => {
            const res = await quantityApprovedAction(
              record.key,
              { qtyRequested: record.quantity },
              record.auth.token
            )(record.dispatch);
            res && notification.success({ message: "Quantity Updated!" });
            !res &&
              notification.error({ message: "Quantity Failed tobe Updated!" });
          }}
        />
      );
    },
  },
  {
    title: "Quantity Approved",
    dataIndex: "qtyApproved",
    key: "qtyApproved",
    render: (text, record) => {
      if (
        record.status !== "review" ||
        record?.auth?.user?.role?.toLowerCase() === "sales-agent"
      ) {
        return text || "-";
      }
      return (
        <InputNumber
          min={0}
          // disabled={record.status !== "review"}
          defaultValue={parseInt(text)}
          onChange={(value: any) => {
            record.qtyApproved = value;
          }}
          onBlur={async () => {
            const res = await quantityApprovedAction(
              record.key,
              { qtyApproved: record.qtyApproved },
              record.auth.token
            )(record.dispatch);
            res && notification.success({ message: "Quantity Updated!" });
            !res &&
              notification.error({ message: "Quantity Failed tobe Updated!" });
          }}
        />
      );
    },
  },
  {
    title: "Actions",
    render: (text, record) => {
      const deleteModal = () => {
        record.setVisible(true);
      };
      const handleCancel = () => {
        record.setVisible(false);
      };
      const deleteRequest = async () => {
        record?.token &&
          (await deleteStockRequestItemAction(
            record.rowToDelete,
            record.token
          )(record.dispatch));
        record.setVisible(false);
      };
      if (
        !["draft", "review", "request"].includes(record.status?.toLowerCase())
      )
        return null;
      return (
        <>
          <IconButton aria-label="delete" size="small">
            <DeleteIcon
              fontSize="inherit"
              onClick={() => {
                record.setRowToDelete(record.key);
                deleteModal();
              }}
            />
          </IconButton>
          <DeleteModal
            visible={record.visible}
            onOk={deleteRequest}
            isLoading={record.isLoading}
            onCancel={handleCancel}
            itemName="Stock Item"
          />
        </>
      );
    },
  },
];

export const supQuotColumns: ColumnDef<any, any>[] = [
  { header: "Models", accessorKey: "model" },
  { header: "Description", accessorKey: "specification" },
  { header: "Qty", accessorKey: "quantity" },
  {
    accessorKey: "quantityAvailable",
    header: "Qty Available",
    cell: (row: any) => {
      return (
        <Stack spacing={1} direction="row">
          <div>
            <input
              type="text"
              id="small-input"
              placeholder="Qty"
              className="block w-14 px-2 py-1 text-gray-900 border border-gray-300 rounded bg-gray-50 sm:text-xs focus:outline-0"
            ></input>
          </div>
        </Stack>
      );
    },
  },
  {
    accessorKey: "price",
    header: "Price/Unit",
    cell: (row: any) => {
      return (
        <Stack spacing={1} direction="row">
          <div>
            <input
              type="text"
              id="small-input"
              placeholder="Price"
              className="block w-14 px-2 py-1 text-gray-900 border border-gray-300 rounded bg-gray-50 sm:text-xs focus:outline-0"
            ></input>
          </div>
        </Stack>
      );
    },
  },
  { header: "Total Cost", accessorKey: "totalCost" },
  {
    accessorKey: "deliveryDate",
    header: "Delivery Date",
    cell: (row: any) => {
      return (
        <Stack spacing={1} direction="row">
          {/* <DatePicker selected={startDate} onChange={(date:any) => setStartDate(date)} /> */}
        </Stack>
      );
    },
  },
  {
    accessorKey: "action",
    header: "",
    cell: (row: any) => {
      return (
        <Stack spacing={1} direction="row">
          <Button
            style={{
              backgroundColor: "transparent",
              color: "green",
              borderBlockColor: "green",
              textTransform: "capitalize",
              padding: "2px",
              border: "1px solid green",
            }}
          >
            Accept
          </Button>
          <Button
            style={{
              backgroundColor: "transparent",
              color: "red",
              borderBlockColor: "green",
              textTransform: "capitalize",
              padding: "2px",
              border: "1px solid red",
            }}
          >
            Deny
          </Button>
        </Stack>
      );
    },
  },
];
