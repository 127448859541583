import { LoadingOutlined } from "@ant-design/icons";
import { Form, Input, Spin } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCommussionProfileAction, getCommissionProfilelAction, updateCommissionProfileAction } from "../../store/channel/actions";
const CommussionForm = (props: any) => {
  const { auth, setting, channel,shop } = useSelector((state: any) => state);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const onFinish = async (values: any) => {
    auth?.token &&
      !props?.data._id &&
      (await createCommussionProfileAction(auth?.token, { ...values, channel: channel?.selected?._id, shop: shop?.selected?._id })(
        dispatch
      ));
      props?.data._id && (await updateCommissionProfileAction( props?.data._id, { ...values, channel: channel?.selected?._id, shop: shop?.selected?._id }, auth?.token,`shop=${shop?.selected?._id}`)(dispatch));
      auth?.token && await getCommissionProfilelAction(auth?.token,`shop=${shop?.selected?._id}`)(dispatch);
    form.resetFields();
    props?.onCancel();
  };
  return (
    <>
      <Form
        form={form}
        name="validateOnly"
        layout="vertical"
        autoComplete="off"
        initialValues={props.data || {}}
        style={{ maxWidth: "100%" }}
        onFinish={onFinish}
      >
        <Form.Item
          name="accountName"
          label={
            <span className="text-[#030229] pl-1 text-sm font-normal">
              Name
            </span>
          }
          className="w-full"
          rules={[
            { required: true, message: "Please input account name!" },
          ]}
        >
          <Input className="pl-4 h-[42px] w-[100%] text-base" />
        </Form.Item>
        <Form.Item
          name="account"
          label={
            <span className="text-[#030229] pl-1 text-sm font-normal">
              Account Number
            </span>
          }
          rules={[
            { required: true, message: "Please input account number" },
          ]}
          className="w-full"
        >
          <Input
            placeholder="Please enter account number"
            className="pl-4 h-[42px] w-[100%] "
          />
        </Form.Item>
        <Form.Item
          name="address"
          label={
            <span className="text-[#030229] pl-1 text-sm font-normal">
              Address
            </span>
          }
          className="w-full"
          rules={[
            { required: true, message: "Please input address" },
          ]}
        >
          <Input />

        </Form.Item>


        <div>
          <button
            type="submit"
            className={` border w-full bg-[#605BFF] py-2 text-white text-center font-medium text-base  rounded-md `}
          >
            {channel.isFetching ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "white" }}
                    color="white"
                    spin
                  />
                }
              />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </Form>
    </>
  );
};
export default CommussionForm;
