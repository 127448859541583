import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  Switch,
  List,
  InputRef,
  Divider,
  Space,
  message,
} from "antd";
import {
  createpaymentmethodAction,
  getallpaymentcategoryAction,
  createpaymentcategoryAction,
  getallpaymentsAction,
} from "../../store/setting/payments/actions";
import { useDispatch, useSelector } from "react-redux";
import { CiCircleMinus } from "react-icons/ci";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

interface CreatePaymentMethodModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface PaymentCategory {
  _id: string;
  name: string;
}

interface RootState {
  auth: {
    token: string;
  };
  channel: {
    selected?: {
      _id: string;
    };
  };
  payments: {
    isFetching: boolean | { delay?: number | undefined } | undefined;
    allPaymentCategories: {
      data: PaymentCategory[];
    };
  };
}

const CreatePaymentMethodModal: React.FC<CreatePaymentMethodModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [form] = Form.useForm();
  const { auth, channel, payments } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [categories, setCategories] = useState<PaymentCategory[]>([]);
  const [paymentTypes, setPaymentTypes] = useState<string[]>([]);
  const [paymentTypeInput, setPaymentTypeInput] = useState<string>("");
  const [newPaymentGroup, setNewPaymentGroup] = useState<string>("");
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (isOpen) {
      getallpaymentcategoryAction(
        auth.token,
        `?channel=${channel?.selected?._id}`
      )(dispatch).then(() => {
        setCategories(payments?.allPaymentCategories?.data || []);
      });
    }
  }, [isOpen, auth.token, dispatch, payments?.allPaymentCategories?.data]);

  const handleAddPaymentType = () => {
    if (paymentTypeInput.trim()) {
      setPaymentTypes((prev) => [...prev, paymentTypeInput.trim()]);
      setPaymentTypeInput("");
    }
  };

  const handleRemovePaymentType = (index: number) => {
    setPaymentTypes((prev) => prev.filter((_, i) => i !== index));
  };

  const handleAddPaymentGroup = async () => {
    if (newPaymentGroup.trim() && auth.token) {
      const tempId = `temp_${Date.now()}`;

      const newCategory = { _id: tempId, name: newPaymentGroup.trim() };
      setCategories((prev) => [...prev, newCategory]);

      form.setFieldsValue({ paymentCategory: tempId });

      setNewPaymentGroup("");

      try {
        const res: any = await createpaymentcategoryAction(auth.token, {
          name: newCategory.name,
          channel: channel?.selected?._id,
        })(dispatch);

        if (res.status === 201) {
          const createdCategory = res.data;
          setCategories((prev) =>
            prev.map((cat) =>
              cat._id === tempId ? { ...cat, _id: createdCategory._id } : cat
            )
          );
          form.setFieldsValue({ paymentCategory: createdCategory._id });
          message.success("Payment category added successfully");
        }
      } catch (error) {
        console.error("Error adding payment category:", error);
        setCategories((prev) => prev.filter((cat) => cat._id !== tempId));
        form.setFieldsValue({ paymentCategory: undefined });
      
      }
    }
  };

  const handleSubmit = async (values: any) => {
    if (auth.token) {
      try {
        await createpaymentmethodAction(auth.token, {
          name: values?.name,
          isDiscountAllowed: values?.isDiscountAllowed,
          requireFile: values?.requireFile,
          channel: channel?.selected?._id,
          description: values?.description,
          paymentCategory: values?.paymentCategory,
          subPaymentType: paymentTypes,
        })(dispatch);

        await getallpaymentsAction(
          auth.token,
          `?channel=${channel?.selected?._id}`
        )(dispatch);
        message.success("Payment method created successfully");
        onClose();
        form.resetFields();
        setPaymentTypes([]);
      } catch (error) {
        console.error("Error creating payment method:", error);
        message.error("Failed to create payment method");
      }
    }
  };

  return (
    <Modal
      title="Create New Payment Method"
      open={isOpen}
      onCancel={onClose}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="name"
          label="Payment Method Name"
          rules={[{ required: true, message: "Please enter a name" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="paymentCategory"
          label="Payment Group"
          rules={[{ required: true, message: "Please select a payment group" }]}
        >
          <Select
            showSearch
            placeholder="Select/Add Type"
            optionFilterProp="children"
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ padding: "0 8px 4px" }}>
                  <Input
                    placeholder="Add Type"
                    ref={inputRef}
                    name="name"
                    value={newPaymentGroup}
                    onChange={(e) => setNewPaymentGroup(e.target.value)}
                    onPressEnter={handleAddPaymentGroup}
                  />
                  <Button onClick={handleAddPaymentGroup}>
                    <PlusOutlined />
                  </Button>
                </Space>
              </>
            )}
          >
            {categories.map((payment) => (
              <Option key={payment._id} value={payment._id}>
                {payment.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

      

        <Form.Item label="Add Payment Type">
          <Input
            value={paymentTypeInput}
            onChange={(e) => setPaymentTypeInput(e.target.value)}
            placeholder="Enter payment type"
            onPressEnter={handleAddPaymentType}
          />
          <Button
            type="primary"
            onClick={handleAddPaymentType}
            style={{ marginTop: "10px" }}
          >
            Add more
          </Button>
        </Form.Item>

        {paymentTypes.length > 0 && (
          <Form.Item>
            <div className="max-h-[200px] overflow-y-auto">
              <List
                bordered
                dataSource={paymentTypes}
                renderItem={(item, index) => (
                  <List.Item
                    actions={[
                      <Button
                        type="link"
                        onClick={() => handleRemovePaymentType(index)}
                      >
                        <CiCircleMinus size={20} />
                      </Button>,
                    ]}
                  >
                    {item}
                  </List.Item>
                )}
              />
            </div>
          </Form.Item>
        )}

        <Form.Item
          name="requireFile"
          label="Require File"
          valuePropName="checked"
          initialValue={false}
        >
          <Switch />
        </Form.Item>

        <Form.Item
          name="isDiscountAllowed"
          label="Discount Allowed"
          valuePropName="checked"
          initialValue={false}
        >
          <Switch />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={payments.isFetching}
          >
            Create Payment Method
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreatePaymentMethodModal;
