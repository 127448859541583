import React, { useState } from "react";
import { Button, DatePicker, Form, Input, Select, TimePicker } from "antd";
import CustomButton from "../Buttons/CustomButton";
import UploadPhoto from "./Upload";
import dayjs from 'dayjs';
const AddAttendanceForm: React.FC = () => {
  const [pictures, setPictures] = useState([]);
  return (
    <Form
      layout="vertical"
      initialValues={{}}
      style={{ width: "100%", padding: "50px" }}
    >
        <div className="flex flex-col justify-center items-center ">
            <Form.Item>
            <UploadPhoto
          setPictures={setPictures}
          limit={1}
        //   default={}
        />
            </Form.Item>
          </div>
      <div className="flex gap-12 w-full">
        <Form.Item
          label={<p className="text-[#030229] text-sm">First Name</p>}
          className="w-1/2"
        >
          <Input className="h-12" />
        </Form.Item>
        <Form.Item
          label={<p className="text-[#030229] text-sm">Date</p>}
          className="w-1/2"
        >
          <DatePicker className="w-full h-12" />
        </Form.Item>
      </div>
      <div className="flex gap-12 w-full">
        <Form.Item
          label={<p className="text-[#030229] text-sm">Last Name</p>}
          className="w-1/2"
        >
          <Input className="h-12" />
        </Form.Item>
        <div className="flex gap-2 ml-2">
        <Form.Item
          label={<p className="text-[#030229] text-sm">Check-in</p>}
          className="w-1/2"
        >
          <TimePicker defaultValue={dayjs('12:08:23', 'HH:mm:ss')} size="large" />
        </Form.Item>
        <Form.Item
          label={<p className="text-[#030229] text-sm">Check-out</p>}
          className="w-1/2"
        >
          <TimePicker defaultValue={dayjs('12:08:23', 'HH:mm:ss')} size="large" />
        </Form.Item>
        </div>
      </div>
      <div className="flex gap-12 w-full">
        <Form.Item
          label={<p className="text-[#030229] text-sm">Department </p>}
          className="w-1/2"
        >
          <Select className="h-12">
            <Select.Option value="Dev">Dev</Select.Option>
            <Select.Option value="finance">Finance</Select.Option>
          </Select>
        </Form.Item>

        <div className="flex gap-2 ml-2">
        <Form.Item
          label={<p className="text-[#030229] text-sm">Break-in</p>}
          className="w-1/2"
        >
          <TimePicker defaultValue={dayjs('12:08:23', 'HH:mm:ss')} size="large" />
        </Form.Item>
        <Form.Item
          label={<p className="text-[#030229] text-sm">Break-out</p>}
          className="w-1/2"
        >
          <TimePicker defaultValue={dayjs('12:08:23', 'HH:mm:ss')} size="large" />
        </Form.Item>
        </div>
      </div>
      <div className="flex gap-5 w-full px-10">
        <Form.Item className="w-full">
          <CustomButton
            title={<h1 className="py-2">Discard</h1>}
            textColor="[#605BFF]"
            width="full"
            border="[#605BFF]"
          />
        </Form.Item>
        <Form.Item className="w-full">
          <CustomButton
            title={<h1 className="py-[8.5px] px-1">Save</h1>}
            bgColor="[#605BFF]"
            textColor="white"
            width="full"
          />
        </Form.Item>
      </div>
    </Form>
  );
};

export default AddAttendanceForm;
