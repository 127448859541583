import React from "react";
import FilesComponent from "./fileComponent";
import Fileimg from "../../../../assets/images/accountsimg/fileimg.png";

export const filesdata = [
  {
    title: "Proposal Documents",
    description: "5 Documents",
    image: Fileimg,
  },
  {
    title: "Quotation Documents",
    description: "5 Documents",
    image: Fileimg,
  },
  {
    title: "Proposal Documents",
    description: "5 Documents",
    image: Fileimg,
  },
  {
    title: "Delivery Notes",
    description: "5 Documents",
    image: Fileimg,
  },
  {
    title: "Quotation Documents",
    description: "5 Documents",
    image: Fileimg,
  },
  {
    title: "Delivery Notes",
    description: "5 Documents",
    image: Fileimg,
  },
];

const FilesCards = (props: any) => {
  return (
    <div className="flex flex-wrap gap-5">
      {props?.filesdata?.map((item: any) => (
        <FilesComponent
          title={item?.title}
          description={item?.description}
          imageSrc={item?.image}
        />
      ))}
    </div>
  );
};
export default FilesCards;
