import { Tag } from "antd";
import { searchValue } from "../../../utils/setColor";

const ProductDetailsCard = (props: any) => {
  const styles = {
    customElement: {
      boxShadow: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)",
    },
    customShadowCard: {
      boxShadow: "0px 4px 24px 0px rgba(194, 194, 194, 0.12)",
    },
    customBorderCard: {
      border: "0.5px solid rgba(3, 2, 41, 0.15)",
    },
  };

  return (
    <>
      <div
        className="shadow-sm py-3 px-5 w-[500px]  bg-white rounded-md"
        style={styles.customBorderCard}
      >
        <div className="flex justify-between">
          <h1 className="text-[#030229] font-medium text-xl">
            {props?.data?.product?.model}
          </h1>
          <p className=" py-1 rounded-[5px] text-sm ">
            Status:
            <Tag
              color={searchValue(props?.data?.status)}
              className=" w-32 py-[1px] text-center ml-3 text-base"
            >
              {props?.data?.status}
            </Tag>
          </p>
        </div>
        <div className="flex justify-between">
          <div>
            <p className="text-[#605BFF] text-sm py-1">
              S/N: {props?.data?.serialNumber}
            </p>
            {props?.data?.quotationItem?.specification?.map((d: any) => (
              <>
                {d[1] && (
                  <div className="flex gap-2">
                    <p className="text-[#030229B2] text-sm py-1">{d[0]}:</p>
                    <p className="text-[#030229B2] text-sm py-1">{d[1]}</p>
                  </div>
                )}
              </>
            ))}
          </div>
          <div className="mt-3">
            {props?.data?.shopStock?.channel?.name && (
              <div className="ml-5 mt-7 text-sm flex gap-2">
                <span className="pt-2">Channel: </span>
                <div className=" w-32 text-center bg-[#FFCB05]  px-2 py-1 rounded-[5px]">
                  <span className="text-[12px]">
                    {props?.data?.shopStock?.channel?.name}
                  </span>
                </div>{" "}
              </div>
            )}
            {props?.data?.shopStock?.name && (
              <div className="mt-5 ml-12 text-sm flex gap-2">
                <span className="pt-2">Shop: </span>{" "}
                <div className=" w-32 text-center bg-[#0302291b]  px-2 py-1 rounded-[5px]">
                  {" "}
                  <span className="text-[12px]">
                    {props?.data?.shopStock?.name}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default ProductDetailsCard;
