import React, { useState } from "react";
import { Typography } from "antd";
import { useSelector } from "react-redux";
// import Accordion from "@mui/material/Accordion";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NotesCard from "./components/NotesCard";
import "../../../../components/layout/sideNav.css";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
const HistoryNote = (props: any) => {
  const { account } = useSelector((state: any) => state);
  // const [isHovered, setIsHovered] = useState();
  console.log("account to history", account?.note?.data);

  return (
    <>
      {account?.note?.data?.length === 0 ? (
        <Typography>
          <p>currently there is no notes, use above field to create note</p>
        </Typography>
      ) : (
            <ScrollableFrame
              setPage={props.setPage} // Pass the setPage function to ScrollableFrame
              setLimit={props.setLimit} // Pass the setLimit function to ScrollableFrame
              limit={props.limit} // Pass the current limit
              total={account?.note?.total} // Pass the total number of notes
              count={Math.ceil(account?.note?.total / props.limit)} // Calculate the number of pages
            >
              <NotesCard  />
            </ScrollableFrame>
        
      )}
    </>
  );
};

export default HistoryNote;
