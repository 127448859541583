import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const createpaymentcategoryService = async (
  data: any,
  token: string
) => {
  return await HttpRequest.post(
    `${SERVER_URL}/settings/paymentcategory`,
    data,
    token
  );
};

export const createpaymentmethodService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/settings/payment`, data, token);
};

export const getAllpaymentcategoryService = async (token: string,query?:any) => {
  return await HttpRequest.get(`${SERVER_URL}/settings/paymentcategory${query}`, token);
};

export const getAllpaymentsService = async (token: string,query?:string) => {
  return await HttpRequest.get(`${SERVER_URL}/settings/payment/group${query}`, token);
};

export const updateOnepaymentsService = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/settings/payment/one/${itemId}`,
    data,
    token
  );
};



export const getOnepaymentsService = async (itemId: string, token: string) => {
  return HttpRequest.get(
    `${SERVER_URL}/settings/payment/one/${itemId}`,
    token
  );
};
