export const Options = [
  {
    value: "MoMo_Pay",
    label: "MoMo Pay",
  },
  {
    value: "Macye_Macye",
    label: "Macye Macye",
  },
  {
    value: "Intelligra_Insurance",
    label: "Intelligra Insurance",
  },
  {
    value: "Vuba_Vuba",
    label: "Vuba Vuba",
  },
  {
    value: "Samphone_POS",
    label: "Samphone POS",
  },
  {
    value: "MTN_POS",
    label: "MTN POS",
  },
  {
    value: "Samphone_Bank_Deposit",
    label: "Samphone Bank Deposit",
  },
  {
    value: "MTN_Bank_Deposit",
    label: "MTN Bank Deposit",
  },
  {
    value: "Corporate_Sales",
    label: "Corporate Sales",
  },
  {
    value: "MTN_Staff_Device_Requisition",
    label: "MTN Staff Device Requisition",
  },
  {
    value: "Yello_Payment",
    label: "Yello Payment",
  },
];
