import React from "react";
import { PaginationControlled } from "../filters/Paginate";
import { useLocation } from "react-router";
const ScrollableFrame: React.FC<{
  children: any;
  loading?: boolean;
  limit?: number;
  count?: number;
  total?: number;
  setLimit?: (limit: number) => void;
  setPage?: (page: number) => void;
  hidePagination?: boolean;
  customSize?: number;
}> = ({
  children,
  loading,
  limit,
  count,
  total,
  setLimit,
  setPage,
  hidePagination,
  customSize,
}) => {
  const path = useLocation().pathname;
  const size = customSize ?? 250;
  return (
    <div className="space-y-4 pb-4 ">
      <div
        className={`md:p-4 ${
          path === "/setting"
            ? "md:h-[calc(100vh-400px)]"
            : `md:h-[calc(100vh-${size}px)]`
        }  overflow-y-auto`}
      >
        {children}
      </div>
      <div className="">
        {!hidePagination && (
          <PaginationControlled
            loading={loading}
            setPage={setPage}
            setLimit={setLimit}
            limit={limit}
            total={total}
            count={count}
          />
        )}
      </div>
    </div>
  );
};

export default ScrollableFrame;
