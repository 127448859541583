import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import { Divider } from "antd";
import { Table } from "antd";
import { Mycolumns, Mydata } from "../../../assets/data/data";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { useDispatch, useSelector } from "react-redux";
import { getOneCustomerAction } from "../../../store/customer/actions";

const CustomerInvoiceDetails = () => {
  const navigate = useNavigate();
  const { customerId } = useParams();
  const dispatch = useDispatch();
  const { customer, auth } = useSelector((state: any) => state);

  const specificationdata = [
    { invonumber: "INV-0012", invodate: "12/5/2023", duedate: "12/7/2023" },
  ];
  const billingdata = [
    {
      company: "Samphone",
      address: "Kigali City - Nyarugenge",
      email: "samphone@gmail.com",
      contact: "+25078888888",
    },
  ];

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchOnProduct = async () => {
      if (customerId) {
        auth?.token &&
          (await getOneCustomerAction(auth?.token, customerId)(dispatch));
      }
    };
    fetchOnProduct();
  }, [customerId, auth?.token, dispatch]);

  console.log("customer", customer);

  return (
    <div className="text-black mt-8">
      <DetailsHeaderActionBar
        pageName="Customer"
        title={`${customer?.selected?.name} \\ Invoice`}
        goBack={goBack}
      />
      <div className="text-black min-h-screen px-44 bg-white mt-6 py-10 border border-gray-100">
        <div className="flex xitems-center justify-between">
          <div className="text-xl font-bold">
            <h2>INVOICE</h2>{" "}
          </div>

          {specificationdata.map((items) => (
            <div className="xfloat-right mb-4 grid grid-cols-2 gap-x-36">
              {/* <Stack direction="row" spacing={2}> */}
              <p className="font-semibold text-[#000] text-[15px]">
                Invoice Number
              </p>
              <p className="font-light  text-gray-500 text-[14px] ">
                {items?.invonumber}
              </p>
              <p className="font-semibold text-[#000] text-[15px]">
                Invoice Date
              </p>
              <p className="font-light text-[14px] text-gray-500">
                {items?.invodate}
              </p>
              <p className="font-semibold  text-[#000] text-[15px]">Due Date</p>
              <p className="font-light text-[14px] text-gray-500">
                {items?.duedate}
              </p>
            </div>
          ))}
        </div>
        <Divider />
        <div className="flex flex-row justify-between pt-2">
          <div className="w-[15rem]">
            <h2 className="text-base font-semibold mb-2">
              Billing Information
            </h2>
            {billingdata.map((item: any) => (
              <div className="flex flex-col gap-1">
                <Stack>
                  <p className="text-sm font-semibold">Company</p>
                  <p className="text-sm">{item?.company}</p>
                </Stack>
                <Stack>
                  <p className="text-sm font-semibold">Address</p>
                  <p className="text-sm">{item?.address}</p>
                </Stack>
                <Stack>
                  <p className="text-sm font-semibold">Email</p>
                  <p className="text-sm">{item?.email}</p>
                </Stack>
                <Stack>
                  <p className="text-sm font-semibold">Contact</p>
                  <p className="text-sm">{item?.contact}</p>
                </Stack>
              </div>
            ))}
          </div>

          <div className="xw-[14.7rem] pr-52">
            <h2 className="text-base font-semibold mb-2">
              Customer Information
            </h2>

            <div className="flex flex-col gap-1">
              <Stack>
                <p className="text-sm font-semibold">Names</p>
                <p className="text-sm">{customer?.selected?.name}</p>
              </Stack>
              <Stack>
                <p className="text-sm font-semibold">Address</p>
                <p className="text-sm">{"Kigali City - Nyarugenge"}</p>
              </Stack>
              <Stack>
                <p className="text-sm font-semibold">Email</p>
                <p className="text-sm">{customer?.selected?.email}</p>
              </Stack>
              <Stack>
                <p className="text-sm font-semibold">Contact</p>
                <p className="text-sm">{customer?.selected?.phone}</p>
              </Stack>
            </div>
          </div>
        </div>
        <Divider dashed />

        <div className="text-lg font-semibold mb-1">
          <h2>Products</h2>{" "}
        </div>

        <div className=" w-full  pt-2">
          <Table columns={Mycolumns} dataSource={Mydata} pagination={false} />
        </div>
        <div className="flex flex-col mt-5 mb-6  float-right">
          <div className="flex justify-between">
            <p className="text-[.9rem] font-light">Subtotal</p>
            <h2 className="text-[.9rem] font-semibold ">100,000</h2>
          </div>
          <div className="flex justify-between">
            <p className="text-[.9rem] font-light">Taxes</p>
            <h2 className="text-[.9rem] font-semibold ">0%</h2>
          </div>
          <div className="flex mt-2 justify-between gap-12">
            <p className="text-lg font-semibold">Total Amount Due</p>
            <h2 className="text-lg font-semibold float-right">100,000</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerInvoiceDetails;
