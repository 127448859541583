import HeaderComponent from "../../../components/HeaderComponent";
import { Stack } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import ProductGrid from "../../../components/grids/ProductGrid";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { Select, Spin, Button, Space } from "antd";
import React, { useState, useEffect } from "react";

import AddNewProduct from "../../../components/forms/NewProductForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProductsAction,
  getAllAttributesAction,
} from "../../../store/product/actions";
import { myProductActions } from "../../../store/product";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../../components/buttons/SearchButton";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import { ProductsHeaders } from "../../../components/csvHeaders/Headers";
import FilesCard from "../../../components/cards/NoSale/FilesCard";
import CSVExportButton from "../../../components/cards/NoSale/CSVExportButton";
import { VscFilePdf } from "react-icons/vsc";
import { getAllProducts_CSVAction } from "../../../store/csvDownload/actions";
import { productIncludeItem } from "../../../assets/data/includedItem";
import CurstomeCardSkeleton from "../../../components/skeleton/CurstomeCardSkeleton";
import { IoFilterOutline } from "react-icons/io5";

const ProductFilter = () => {
  const { product } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState(false);
  const [type, setType] = useState("");
  const [brand, setBrand] = useState("");

  dispatch(
    myProductActions.setQuery(
      `?${type && `type=${type}${brand && `&brand=${brand}`}&`}`
    )
  );
  const resetFilter = () => {
    if (selectedFilters) {
      setType("");
      setBrand("");
    }
  };
  return (
    <div>
      <Stack
        spacing={1}
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Button
          className="font-medium "
          style={{
            color: selectedFilters ? "#605BFF" : "",
            borderColor: selectedFilters ? "#605BFF" : "",
          }}
          onClick={() => {
            setSelectedFilters(!selectedFilters);
            resetFilter();
          }}
        >
          <Space>
            <IoFilterOutline size={18} /> <p>Filter</p>
          </Space>
        </Button>

        {selectedFilters && (
          <Stack
            spacing={1}
            direction="row"
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Select
              size="middle"
              showSearch
              style={{ width: 200, fontSize: "5px" }}
              allowClear
              placeholder="Filter by Type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").toString().includes(input)
              }
              loading={product.isFetching}
              disabled={product.isFetching}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")

                  .toString()
                  .toLowerCase()
                  .localeCompare(
                    (optionB?.label ?? "").toString().toLowerCase()
                  )
              }
              options={product?.attributes?.types}
              onChange={(value: any) => {
                console.log("::", value);
                !value && setType("");
                value && setType(value);
              }}
            />
            {type && (
              <Select
                size="middle"
                loading={product.isFetching}
                disabled={product.isFetching}
                showSearch
                allowClear
                style={{ width: 200 }}
                placeholder="Filter by Brand"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")

                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                options={product?.attributes?.brands}
                onChange={(value: any) => {
                  console.log("::", value);
                  !value && setBrand("");
                  value && setBrand(value);
                }}
              />
            )}
          </Stack>
        )}
      </Stack>
      <h1 className="text-[#030229] opacity-70 mt-6 ml-1">
        Available Devices :{" "}
        {product.isFetching ? <Spin size="small" /> : product?.all?.total}
      </h1>
    </div>
  );
};

const PageView = () => {
  const [isGridView, setIsGridView] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchKey, setSearchKey] = React.useState();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  const { product, auth, layout, CSV } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    if (auth?.token && product?.query) {
      getAllProductsAction(
        auth?.token,
        product?.query +
        `limit=${limit}&page=${page - 1}&field=${productIncludeItem}`
      )(dispatch);
      getAllAttributesAction(auth?.token, product?.query || "?")(dispatch);
    }
  }, [auth.token, product?.query, limit, page, dispatch]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [productsToDelete, setProductsToDelete] = useState<String[]>([]);
  const handleSelected = (e: any) => {
    const id: String = e.target.getAttribute("id");
    if (e.target.checked) {
      setProductsToDelete([...productsToDelete, id]);
    } else {
      setProductsToDelete(
        productsToDelete.filter((element) => {
          return element !== id;
        })
      );
    }
  };
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (searchKey === "") {
      auth?.token &&
        getAllProductsAction(
          auth?.token,
          product?.query +
          `limit=${limit}&page=${page - 1}&field=${productIncludeItem}`
        )(dispatch);
    }
  }, [searchKey]);

  const handleSearch = () => {
    auth?.token &&
      getAllProductsAction(
        auth?.token,
        product?.query +
        `limit=${limit}&page=${page - 1
        }&sk=${searchKey}&field=${productIncludeItem}`
      )(dispatch);
  };

  useEffect(() => {
    if (layout?.openCSVButton && auth?.token)
      getAllProducts_CSVAction(
        auth?.token,
        product?.query +
        `limit=${product?.all?.total}&page=0&field=${productIncludeItem}`
      )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  const csvData = CSV?.csvDownloadedData?.data?.map((record: any) => ({
    type: record.type,
    brand: record?.brand,
    model: record?.model,
    specification: `${record?.specs
      ?.map((d: any) => `${d?.label}: ${d?.value?.join(", ")}`)
      ?.join(", ")}`,
  }));

  return (
    <div>
      <div className="text-[#030229]  mt-7 ml-5 pb-2 mr-5">
        <DetailsHeaderActionBar
          pageName="Inventory"
          goBack={goBack}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleCancel={handleCancel}
          item="Device"
          modelTitle="Add New Device Product"
          title="Devices "
          showButton
          modalComponent={
            <AddNewProduct
              dataToUpdate={null}
              action={"add"}
              onCancel={handleCancel}
            />
          }
        />
      </div>
      <div className="flex items-center justify-between px-4">
        <HeaderComponent
          isNotAddButton={true}
          isNotCollapse={true}
          filterCard={<ProductFilter />}
          setIsGridView={setIsGridView}
        />
        <div className="flex gap-3">
          <div>
            <SearchInput
              onSearch={handleSearch}
              onChange={(e: any) => {
                e.preventDefault();
                setSearchKey(e.target.value);
              }}
            />
          </div>

          <div className="flex  gap-3 mb-8">
            <FilesCard
              Icon={VscFilePdf}
              tailwindTextColor="text-white"
              tailwindBackgroundColor="bg-orange-400"
              disabled
            />
            <CSVExportButton
              csvHeaders={ProductsHeaders}
              csvData={csvData}
              filename={`Products.csv`}
            />
          </div>
        </div>
      </div>

      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={product?.all?.total}
        count={Math.ceil(product?.all?.total / limit)}
      >
        {isGridView && (
          <>
            {product?.isFetching && product?.all?.data?.length === 0 ? (
              <div className="flex flex-wrap gap-4 justify-start items-start">
                {Array.from({ length: 8 }).map(() => (
                  <CurstomeCardSkeleton />
                ))}
              </div>
            ) : (
              <div className="flex flex-wrap gap-4 gap-y-4 justify-start items-start">
                {product?.all?.status === 200 && (
                  <ProductGrid
                    handleSelected={handleSelected}
                    data={product?.all?.data}
                  />
                )}
              </div>
            )}
          </>
        )}
      </ScrollableFrame>
    </div>
  );
};

export default PageView;
