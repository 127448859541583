import { Navigate, Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { authActions } from "../store/auth";
import store from "store";

const DashRoutes = () => {
  const dispatch = useDispatch();
  const token = store.get("authToken");
  const { auth, layout } = useSelector((state: any) => state);
  const storedRedirectPath = localStorage.getItem("prevPath");

  useEffect(() => {
    if (token) {
      dispatch(authActions.setToken({ token }));
    }
  }, [auth.token, dispatch, token]);

  useEffect(() => {
    if (auth?.user?.role === "sales-agent") {
      dispatch(authActions.logout());
    }
  }, [dispatch]);

  return !auth?.token || !token ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate
      to={
        ["sales-agent", "mtn-sales-agent"]?.includes(
          auth?.user?.role?.toLowerCase()
        )
          ? "/dashboard"
          : layout.redirectPath || storedRedirectPath
          ? `${storedRedirectPath}`
          : "/dashboard"
      }
    />
  );
};

export default DashRoutes;
