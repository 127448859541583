import React from "react";

interface cartProps{
    title:any,
    total:any,
}
const EbmCard = ({title,total}:cartProps) => {
  return (
    <div>
      <div className=" bg-white rounded-md p-5 border-l-2 border-[#605BFF80] w-[250px]">
        <h1 className="text-[#030229] text-base" >{title}</h1>
        <p className="text-[#030229] text-xl font-medium pt-3">{total}</p>
      </div>
    </div>
  );
};

export default EbmCard;
