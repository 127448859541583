import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getAllRepairsService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/repair${query}`, token);
};

export const getOneRepairService = async (itemId: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/repair/one/${itemId}`, token);
};

export const getUpdateService = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/repair/one/${itemId}`,
    data,
    token
  );
};
