import React, { useEffect } from "react";
import { Form, Input } from "antd";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { updateOneAccountAction } from "../../store/account/actions";

const UpdateAccountForm = (props: any) => {
  const [form] = Form.useForm();
  const { auth, account } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const initialData = {
    ...props?.dataToUpdate?.customerDetails,
  };

  const onFinish = async (values: any) => {
    auth?.token &&
      props?.dataToUpdate?._id &&
      (await updateOneAccountAction(auth?.token, props?.dataToUpdate?._id, {
        customerDetails: { ...values },
      })(dispatch));
    form.resetFields();
    props?.onCancel();
  };

  useEffect(() => {
    form.setFieldsValue(props?.dataToUpdate?.customerDetails);
  }, [props?.dataToUpdate?.customerDetails]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={initialData || {}}
    >
      <div>
        <div className="grid grid-cols-2 gap-x-10 pl-18">
          <Form.Item
            label="Account Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Name is required!",
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="tel"
            rules={[
              {
                required: true,
                message: "Phone is required!",
                whitespace: true,
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Country"
            name="country"
            rules={[
              {
                required: true,
                message: "Country is required!",
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="Please enter Country" />
          </Form.Item>
          <Form.Item
            label="Address"
            name="address"
            rules={[
              {
                required: true,
                message: "Address is required!",
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="Please enter address" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "email is required!", type: "email" },
            ]}
          >
            <Input placeholder="Please enter email" />
          </Form.Item>
        </div>
        <LoadingButton
          type="submit"
          variant="contained"
          sx={{ minWidth: 100 }}
          loading={account?.isFetching}
        >
          {props?.dataToUpdate ? "update" : "Save"}
        </LoadingButton>
      </div>
    </Form>
  );
};
export default UpdateAccountForm;
