import { Stack, Button } from "@mui/material";
import { Select } from "antd";
import React, { useState, useEffect } from "react";
import { IoFilterOutline } from "react-icons/io5";
const { Option } = Select;

const DepartmentFiltering = (props: any) => {
  const [selectedFilters, setSelectedFilters] = useState(false);
  const [type, setType] = useState("");
  const [brand, setBrand] = useState("");
  const [productId, setProductId] = useState("");
  const resetFilter = () => {
    if (selectedFilters) {
      setType("");
      setBrand("");
      setProductId("");
    }
  };

  return (
    <div className="px-4 py-2">
      <Stack
        spacing={1}
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack
          spacing={1}
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Button
            startIcon={
              selectedFilters ? <IoFilterOutline /> : <IoFilterOutline />}
            onClick={() => {
              setSelectedFilters(!selectedFilters);
              resetFilter();
            }}>
            Filter
          </Button>

          {/* {selectedFilters && (
            <Stack
              spacing={1}
              direction="row"
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Select
                showSearch
                style={{ width: 200 }}
                allowClear
                placeholder="Filter By Type"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input)
                }
                loading={pos.isFetching}
                disabled={pos.isFetching}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")

                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                options={product?.attributes?.types}
                onChange={(value: any) => {
                  !value && setType("");
                  !value && setBrand("");
                  !value && setProductId(" ");
                  value && setType(value);
                }}
              />
              {type && (
                <Select
                  loading={pos.isFetching}
                  disabled={pos.isFetching}
                  showSearch
                  allowClear
                  style={{ width: 200 }}
                  placeholder="Filter By Brand"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").toString().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")

                      .toString()
                      .toLowerCase()
                      .localeCompare(
                        (optionB?.label ?? "").toString().toLowerCase()
                      )
                  }
                  options={product?.attributes?.brands}
                  onChange={(value: any) => {
                    !value && setBrand("");
                    !value && setProductId(" ");
                    value && setBrand(value);
                  }}
                />
              )}
              {brand && props.isModalOpen === false && (
                <Select
                  loading={product.isFetching || channel.isFetching || pos.isFetching }
                  disabled={product.isFetching || channel.isFetching || pos.isFetching }
                  showSearch
                  placeholder="Filter By Product"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").toString().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")

                      .toString()
                      .toLowerCase()
                      .localeCompare(
                        (optionB?.label ?? "").toString().toLowerCase()
                      )
                  }
                  onChange={(value: any) => {
                    !value && setProductId(" ");
                    value && setProductId(value);
                  }}
                >
                  {product?.all?.data?.map((el: any) => (
                    <Option value={el?._id}>{el?.model}</Option>
                  ))}
                </Select>
              )}
            </Stack>
          )} */}
        </Stack>
      </Stack>
    </div>
  );
};

export default DepartmentFiltering;


