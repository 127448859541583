import React from "react";
import { Link } from "react-router-dom";
import { Tag } from "antd";
import { searchValue } from "../../../utils/setColor";
import { useNavigate } from "react-router-dom";
const OverviewPOCard = (props: any) => {
  const navigate = useNavigate();
  return (
    <div
      className="bg-white relative rounded p-4 max-w-md min-w-[418px] text-black space-y-3 h-fit border-l-4 border-[#0000FF] hover:-translate-y-2 duration-200"
      onClick={() => navigate(`/pos/${props?.item?.warehouseOutId}`)}
    >
      <div className="mb-8">
        <span className="text-[#0000FF] font-bold">
          {props?.item?.stockRequest?.requestId}
        </span>
        <p className="text-xs font-medium text-[#0F0F47] capitalize">
          Sent By:{" "}
          <span className="text-xs font-normal text-[#0F0F47]">
            {" "}
            {props?.item?.createdBy?.names}
          </span>
        </p>
      </div>
      <div className="absolute right-2 top-2">
        <Tag
          color={searchValue(props?.item?.status)}
          className="capitalize min-w-[50px] text-center"
        >
          {props?.item?.status}
        </Tag>
      </div>
      <div className="flex justify-between items-end">
        <div className="flex flex-col justify-center">
          <h1>Deliverer</h1>
          <p className="capitalize font-normal">
            name:
            <span className="font-light text-xs">
              {" "}
              {props?.item?.deliverer?.name}
            </span>
          </p>
          <p>
            Telephone:<span> {props?.item?.deliverer?.phone}</span>
          </p>
          <p className="capitalize truncate max-w-[220px]">
            Company:<span> {props?.item?.deliverer?.company}</span>
          </p>
        </div>
        <div className="grid grid-cols-2 w-max gap-x-4">
          <p className="font-semibold text-[#2E6C8E]">Qnty Sent</p>
          <p className="flex justify-end">
            {`${props?.item?.list?.length}`}{" "}
            items
          </p>
        </div>
      </div>
    </div>
  );
};

export default OverviewPOCard;
