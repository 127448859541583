import { Dropdown, MenuProps } from "antd";
import React from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import ExportCSVFile from "./ExportCSVFile";

const CsvDownloadButton: React.FC<{
  csvHeaders: any;
  csvData: any;
  filename: any;
}> = ({ csvHeaders, csvData, filename }) => {
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <ExportCSVFile
          csvHeaders={csvHeaders}
          csvData={csvData || []}
          filename={filename}
        />
      ),
    },
  ];

  return (
    <Dropdown menu={{ items }} placement="bottomLeft" arrow trigger={['click']}>
      <SettingsIcon className="cursor-pointer" />
    </Dropdown>
  );
};

export default CsvDownloadButton;
