import React from "react";
import { Divider, Table } from "antd";
import type { TableColumnsType } from "antd";
import { useSelector } from "react-redux";

interface DataType {
  key: React.Key;
  shop: string;
  device: string;
  number: number;
  price: string;
}

const NoSaleSubscriptionTable = (props: any) => {
  const { overView } = useSelector((state: any) => state);
  const columns: TableColumnsType<DataType> = [
    {
      title: "Shop",
      dataIndex: "shop",
    },
    {
      title: "Device",
      dataIndex: "device",
    },
    {
      title: "Price",
      dataIndex: "price",
    },
    {
      title: "Number Of Request",
      dataIndex: "number",
    },
  ];
  const noSaleData = props?.data?.slice(0, 7)?.map((noSale: any) => {
    return {
      key: noSale?._id,
      shop: noSale?.shop?.name,
      device: noSale?.priceList?.product?.model,
      price: noSale?.priceList?.prices
        ?.reduce((sum: number, price: any) => sum + price.value, 0)
        ?.toLocaleString(),
      number: noSale?.numberOfRequests,
    };
  });
  return (
    <>
      <Table<DataType>
        columns={columns}
        dataSource={noSaleData}
        size="small"
        pagination={false}
        loading={overView?.isFetching}
      />
    </>
  );
};

export default NoSaleSubscriptionTable;
