import React, { useEffect } from "react";
import DetailsHeaderActionBar from "../../../../components/details/supplier/DetailsPage/DetailsHeaderActionBar";
import AddDevicesForm from "../../../../components/forms/addDeviceForm";
import UploadSvcFile from "../../../../components/forms/uploadSvcFile";
import { useDispatch, useSelector } from "react-redux";
import { getVaultDeviceAction } from "../../../../store/vault/actions";

const AddDevices = () => {
  const { auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    auth?.token && getVaultDeviceAction(auth?.token, '?')(dispatch);
  }, [auth?.token, dispatch]);
  return (
    <>
      <div>
        <DetailsHeaderActionBar
          goBack={"goBack"}
          pageName={"Manage Devices"}
          title={"Add Devices"}
          data={""}
        />
      </div>

      <div className="flex justify-between w-full">
        <div className="w-[48%] bg-white p-8">
          <h1 className="text-[#030229] text-lg font-medium">
            Add new devices
          </h1>
          <AddDevicesForm />
        </div>
        <div className="w-[48%] bg-white p-8">
          <h1 className="text-[#030229] text-lg font-medium">
            Upload CSV file
          </h1>
          <UploadSvcFile />
        </div>
      </div>

    </>
  );
};
export default AddDevices;
