import { Form, Input } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  getOneCompanyAction,
  updateCompanyActions,
} from "../../store/setting/company/actions";
import { COMPANY_PROFILE_ID } from "../../utils/constants";

const AddCompanyCurrency = (props: any) => {
  const { auth, setting, company } = useSelector((state: any) => state);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const companyId = COMPANY_PROFILE_ID;

  const onFinish = async (values: any) => {
    if (auth?.token && auth?.user?._id) {
      const existingCurrency = company?.selected?.currency || [];
      const newCurrency = {
        country: values?.country,
        currencyCode: values?.currencyCode,
        exchangeRate: values?.exchangeRate,
      };

      const updatedCurrency = [...existingCurrency, newCurrency];

      await updateCompanyActions(
        companyId as string,
        { currency: updatedCurrency },
        auth?.token
      )(dispatch);

      await getOneCompanyAction(companyId as string, auth.token)(dispatch);
      form.resetFields();
      props?.onCancel();
    }
  };

  return (
    <div className="mt-8 w-[30rem] px-8">
      <Form layout="vertical" form={form} name="register" onFinish={onFinish}>
        <div>
          <Form.Item
            label="Country"
            name="country"
            rules={[{ required: true, message: "Please input name!" }]}
          >
            <Input className="w-full" />
          </Form.Item>
          <Form.Item
            label="Country Code"
            name="currencyCode"
            rules={[{ required: true, message: "Please input province!" }]}
            style={{ width: "100%" }}
          >
            <Input className="w-full" />
          </Form.Item>
          <Form.Item
            label="Exchange Rate"
            name="exchangeRate"
            rules={[{ required: true, message: "Please input district!" }]}
            style={{ width: "100%" }}
          >
            <Input className="w-full" />
          </Form.Item>
        </div>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <LoadingButton
            sx={{ textTransform: "none", minWidth: "50%" }}
            type="submit"
            variant="contained"
            loading={setting?.isFetching}
          >
            Save
          </LoadingButton>
        </Form.Item>
      </Form>
    </div>
  );
};
export default AddCompanyCurrency;
